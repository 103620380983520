import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import { AccountCircleBlack, AccountCircleWhite } from '../../styles/Images';

export const Button = styled.a`
  font-family: ${Fonts.fontFamily.Archivo};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  border-radius: 2px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
`;

export const SignupButton = styled(Button)`
  width: 120px;
  height: 43px;
  color: ${Colors.Primary};
  cursor: pointer;
  background-color: ${Colors.Orange};
  border: solid 1px ${Colors.Orange};
  border-radius: 5px;
  &:hover {
    color: ${Colors.Primary};
    text-decoration: none;
    background-color: ${Colors.DarkOrange}
  }
  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size14}px;
    margin: 0 0 1.875rem 0;
    width: 100%;
  }
`;

export const MobileLoginButton = styled(Button)`
  color: ${Colors.White} !important;
  border: solid 1px ${Colors.White};
  width: 120px;
  height: 43px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    color: ${Colors.Black} !important;
    background: ${Colors.White};
    text-decoration: none;
  }
  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size14}px;
    line-height: 1.6875rem;
    margin: 0 0 1.875rem 0;
    width: 100%;
  }
}
`;

export const LoginButton = styled(Button)`
  color: ${Colors.White} !important;
  border: solid 1px ${Colors.White};
  width: 120px;
  height: 43px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    color: ${Colors.Black} !important;
    background: ${Colors.White};
    text-decoration: none;
  }
  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size18}px;
    color: ${Colors.Primary} !important;
    border: solid 1px ${Colors.Primary};
    line-height: 1.6875rem;
    margin-bottom: 1rem;
    &:hover {
      color: ${Colors.Primary} !important;
      text-decoration: none;
    }
  }
`;

export const SignUpButton = styled.div`
  width: 119px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #0e3132;
  align-self: center;
  padding: 8px;
  text-align: center;
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size18}px;
  }
`;

export const MyAccount = styled(Button)`
  color: ${Colors.White} !important;
  width: auto;
  height: auto;
  padding: 4px;
  cursor: pointer;
  margin: 0;
  font-size:${Fonts.fontSize.Size14}px;
  border: 1px solid ${Colors.White};
  border-radius: 4px;
  &::before {
    content: '';
    background-image: url("${AccountCircleWhite}");
    background-repeat: no-repeat;
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    margin-right: 0.3125rem;
  }

  &:hover {
    color: ${Colors.Black} !important;
    background: ${Colors.White};
    text-decoration: none;
    &::before {
      content: '';
      background-image: url("${AccountCircleBlack}");
      background-repeat: no-repeat;
      width: 1.25rem;
      height: 1.25rem;
      display: inline-block;
      margin-right: 0.3125rem;
    }
  }
  @media (max-width: 768px) {
    color: ${Colors.White} !important;
    font-size: ${Fonts.fontSize.Size14}px;
  }
`;

export const Logout = styled(Button)`
  color: ${Colors.White} !important;
  width: auto;
  height: auto;
  padding: 4px;
  cursor: pointer;
  margin: 0;
  font-size:${Fonts.fontSize.Size14}px;
  border: none;

  &:hover {
    background: transparent;
  }
`;

export const PoweredBy = styled.p`
  font-size: ${Fonts.fontSize.Size14}px;
`;

export const Div = styled.div`
  font-family: ${Fonts.fontFamily.AvenirMedium} !important;
`;
