import React from 'react';
import Loader from '../Loader/Loader';
import { Col } from '../../styles/CommonStyles';

const LoadingCard = () => (
  <>
    <div className="row">
      <Col width="20">
        <Loader height="100%" />
      </Col>
      <Col width="80">
        <div className="row">
          <div className="col-5">
            <Loader height="24px" />
          </div>
          <div className="col-3" />
          <div className="col-4">
            <Loader height="24px" />
          </div>
        </div>
        <Loader height="20px" width="90%" />
        <Loader height="20px" width="90%" />
        <div className="row">
          <div className="col-3">
            <Loader height="16px" />
          </div>
          <div className="col-6" />
          <div className="col-3">
            <Loader height="16px" />
          </div>
        </div>
      </Col>
    </div>
  </>
);

export default LoadingCard;
