/**
 * Giggerl model which handles serializations.
 */

class Gigger {
  static fromJson() {
    return new Gigger({});
  }

  toJson = (data) => ({
    organization_number: data.orgNumber,
    first_name: data.firstName,
    last_name: data.lastName,
    full_name: `${data.firstName} ${data.lastName}`,
    company_name: data.companyName,
    email: data.email,
    phone: data.phone,
    password: data.password,
    password_confirmation: data.repeatPassword,
    personal_number: data.personalNumber,
    withoutBankID: data.withoutBankID,
    tax_doc: data.taxDoc,
    third_party_invoice: data.isInvoiceWithThirdPartyChecked,
  });
}
export default Gigger;
