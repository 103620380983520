import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ type }) => (type === 'checkbox' ? '5px 10px 5px 10px' : '0')};
  &:hover {
    background-color: ${({ type }) => (type === 'checkbox' ? Colors.DarkWhite : 'none')};
    color: #242f33;
  }
  display: -webkit-box;
`;
export const CheckBox = styled.div`
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  align-self: start;
  background-color: ${({ checked, bgcolor }) => (checked ? bgcolor || '#6b6887' : Colors.White)};
`;
export const RadioWrapper = styled.div`
  width: ${({ size }) => size || 20}px;
  height: ${({ size }) => size || 20}px;
  border: ${({ color }) => (color ? `2px solid ${color}` : `2px solid ${Colors.Black}`)};
  padding: 2px;
  display: flex;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-color: ${Colors.White};
`;
export const Radio = styled.span`
  width: ${({ size }) => size - 10 || 10}px;
  height: ${({ size }) => size - 10 || 10}px;
  background-color: ${({ checked }) => (checked ? Colors.Primary : Colors.White)};
  border-radius: 50%;
  margin: 1px;
`;
export const CheckboxLabel = styled.label`
  font-family: ${({ fontFamily }) => (fontFamily || Fonts.fontFamily.AvenirRegular)};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  margin: 0 0.5rem;
  cursor: pointer;
`;

export const TextDiv = styled.div`
  font-family: ${Fonts.fontFamily.Archivo};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #f03738;
  margin-top: 4px;
`;
export const Section = styled.div`
  width:  ${({ width }) => (width || '100%')};
  position: ${({ position }) => (position || 'static')};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`;
export const Label = styled.label`
  width: 100%;
  height: 21px;
  font-family: ${({ forFilter }) => (forFilter ? Fonts.fontFamily.AvenirBold : Fonts.fontFamily.AvenirRegular)};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
  flex: ${({ mandatory }) => (mandatory ? '0 0 50%' : '0 0 100%')};
  max-width: ${({ mandatory }) => (mandatory ? '50%' : '100%')};
`;
export const TextBoxLabel = styled(Label)`
  font-family: ${({ forFilter }) => (forFilter ? Fonts.fontFamily.AvenirMedium : Fonts.fontFamily.AvenirRegular)};
  font-weight: ${({ forFilter }) => (forFilter ? '500' : 'normal')};
  letter-spacing: ${({ forFilter }) => (forFilter ? 'normal' : '0.56px')};
  color: ${({ forFilter }) => (forFilter ? Colors.LightViolet : Colors.Black)};
`;
export const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const MandatoryLabel = styled(Label)`
  text-align: right;
`;
export const InputBox = styled.input`
  width: 100%;
  height: 37px;
  padding: 8px;
  font-size: ${Fonts.fontSize.Size16}px;
  line-height: 20px;
  background-color: ${Colors.White};
  outline: 0;
  border: none;
  color: #06041d;
  border-radius: 2px;
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;
export const InputArea = styled.textarea`
  width: 100%;
  height: ${({ customHeight }) => (customHeight ? '182px' : '143px')};
  padding: 8px 24px 8px 8px;
  font-size: ${Fonts.fontSize.Size16}px;
  line-height: 20px;
  background-color: ${Colors.White};
  outline: 0;
  border: none;
  color: #06041d;
  border-radius: 2px;
  resize: none;
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
    line-height: 18px;
    font-size: ${({ type }) => (type === 'summary' ? Fonts.fontSize.Size12 : Fonts.fontSize.Size16)}px;
  }
  &::-moz-placeholder {
    color: rgba(0, 0, 0, 0.5);
    line-height: 18px;
    font-size: ${({ type }) => (type === 'summary' ? Fonts.fontSize.Size12 : Fonts.fontSize.Size16)}px;
  }

`;
export const BorderBox = styled.div`
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.White};
  margin-bottom: 30px;
`;
export const BorderArea = styled(BorderBox)`
  height: ${({ mandatory }) => (mandatory ? '185px' : '145px')};
  position: relative;
`;
export const Divison = styled.div`
  position: relative;
`;

export const EndAdornment = styled.span`
  position: absolute;
  top: 2%;
  right: 0;
`;
export const SelctBorderBox = styled(BorderBox)`
  display: flex;
`;

export const SelectValue = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  width: 100%;
  height: inherit;
  padding: 8px;
  outline: 0;
  border: none;
  color: #06041d;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const SelectPlaceholder = styled(SelectValue)`
  opacity: 0.5;
`;
export const SelectedValue = styled(SelectPlaceholder)`
  opacity: 1;
  display: inline-block;
`;
export const DropDownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: 100%;
`;

export const DropDownList = styled.div`
  background: ${Colors.White};
  width: 100%;
  border: 1px solid #dddce4;
  border-radius: 0;
  max-height: 150px;
  overflow: auto;
`;

export const DropDownOption = styled.p`
  color: ${Colors.Primary};
  padding: 10px;
  font-weight: ${(props) => (props.isSelected ? '900' : '400')};
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size14}px;
  margin: 2px 0 4px;
  opacity: 0.9;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  &:hover {
    background-color: ${Colors.DarkWhite};
    color: #242f33;
  }
`;

export const DropDownListWrapper = styled.div`
  position: absolute;
  width: inherit;
  z-index: 3;
  box-shadow: 1px 5px 11px -1px rgba(0, 0, 0, 0.25);
`;

// slider
export const Slider = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 5px;
  background: ${Colors.Orange};
  border-radius: 5px;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 24px;
`;
export const MinSlider = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background: ${Colors.Orange};
  &:hover::before {
    opacity: 1;
  }
  &:hover::after {
    opacity: 1;
  }
  &:before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -45px;
    right: -23px;
    width: 45px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #274684;
  }
  &:after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`;

export const MinDrag = styled.div`
  position: absolute;
  right: -7.5px;
  top: -5px;

  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 3px solid #0e3132;
  background-color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  $:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
  }
`;

export const MaxSlider = styled.div`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 0;
  height: 5px;
  border-radius: 5px;
  background: ${Colors.Primary};
  &:hover:before {
    opacity: 1;
  }
  &:hover:after {
    opacity: 1;
  }
  &:before {
    opacity: 0;
    content: attr(data-content);
    display: block;
    position: absolute;
    top: -45px;
    right: -23px;
    width: 45px;
    padding: 3px;
    text-align: center;
    color: white;
    background: #274684;
  }
  &:after {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    right: -8px;
    border-top: 8px solid #274684;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
`;

export const MaxDrag = styled.div`
  position: absolute;
  right: -7.5px;
  top: -5px;

  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 3px solid #0e3132;
  background-color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  $:hover {
    box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
  }
`;
export const RangeLabel = styled.label`
  font-size: ${Fonts.fontSize.Size16}px;
  align-self: center;
  max-width: 30%;
  margin: 0;
  padding-keft: 5px;
`;
export const RangeInput = styled.input`
  margin: 0;
  max-width: 65%;
  font-size: ${Fonts.fontSize.Size14}px;
  padding: 5px;
  padding-left: 15px;
  border: solid 1px #dddce4;
  background-color: #ffffff;
  margin-right: 5%;
`;
export const SearchBox = styled.input`
  background-color: #ffffff;
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size20}px;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #06041d;
  border: none;
  outline: none;
  padding-left: 16px;
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &:focus {
    border: none;
    outline: none;
  }
`;
export const SearchButton = styled.button`
  height: ${({ wp }) => (wp ? '64px' : '40px')};
  background-color: #0e3132;
  color: #ffffff;
  font-size: ${({ wp }) => (wp ? '18px' : '16px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: ${Fonts.fontFamily.AvenirRegular};
  border-color: #0e3132;
  border: none !important;
  outline: none !important;
  padding-left: 14px;
  padding-right: 14px;
  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }
  @media (max-width: 768px) {
    width: ${({ wp }) => (wp ? '100%' : 'auto')};
    flex: ${({ wp }) => (wp ? '0 0 100%' : 'auto')};
    margin-top: ${({ wp }) => (wp ? '14px' : '')};
  }
`;

// autosuggest
export const AutoSuggestWrapper = styled.div`
  width: 100%;
  & .react-autosuggest__input {
    font-family: ${({ wp }) => (wp ? Fonts.fontFamily.AvenirRegular : Fonts.fontFamily.AvenirMedium)};
    font-size: ${({ wp }) => (wp ? '16px' : '20px')};
    width: 100%;
    height: ${({ wp }) => (wp ? '64px' : '40px')};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: ${({ wp }) => (wp ? '#0e3132' : '#06041d')};
    outline: none;
    padding: ${(props) => (props.wp ? '19px 0px 19px 32px' : props.searchBoxPadding ? '2px 16px' : '8px 32px')};
    border-radius: 2px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    &::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
  & .react-autosuggest__container--open{
    position: relative;
  }
  & .react-autosuggest__suggestions-container--open {
    position: absolute;
    z-index:10;
    background: ${Colors.White};
    width: 100%;
    border: 1px solid #dddce4;
    padding: 0px;
    border-radius: 0;
    & .react-autosuggest__suggestions-list {
      list-style: none;
      list-style: none;
      padding: 0;
      margin: 0;
      & .react-autosuggest__suggestion {
        color: #1d1d1d;
        &.hover {
          background-color: #ffffff;
          color: #242f33;
        }
      }
    }
  }
  & .react-autosuggest__suggestion {
    margin: 0px !important;
  }
  & .react-autosuggest__section-container{
    padding: 16px 32px 0;
  }
`;
export const AutoSuggest = styled(Autosuggest)``;
export const SearchBarWrapper = styled.div`
  display: flex;
  flex: 1 1;
  @media (max-width: 768px) {
    flex: ${({ wp }) => (wp ? '0 0 100%' : '100')};
  }
`;
export const SearchButtonWrapper = styled.div`
  @media (max-width: 768px) {
    width: ${({ wp }) => (wp ? '100%' : 'auto')};
    flex: ${({ wp }) => (wp ? '0 0 100%' : 'auto')};
  }
`;
export const SearchInputBar = styled.div`
  border-radius: 2px;
  border: 0.5px solid rgb(14, 49, 50, 0.5);
  height: 24px;
  width: 204px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
`;
export const SearchInputBox = styled.input`
  height: 22px;
  font-weight: 500;
  font-size: ${Fonts.fontSize.Size14}px;
  border: none;
  ouline: none;
  margin: 0px;
  padding: 2px 2px 2px 8px;
  width: 170px;
  color: #06041d;
  &::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
  border-radius: inherit;
`;
export const SearchInputButton = styled.div`
  height: 22px;
  display: flex;
  margin-left: -1px;
  align-self: center;
`;
export const ButtonSearch = styled.button`
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 0;
`;
export const AutosuggestListWrapper = styled.div`
  position: absolute;
  z-index: 3;
`;
export const SearchExample = styled.div`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${Colors.White};
`;
export const SectionTitle = styled.div`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size12}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.48px;
  text-align: left;
  color: ${Colors.Primary};
  margin: 0 0 2px;
  opacity: 0.9;

`;
