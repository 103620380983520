import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer, Container, DisplayCards, SectionLeft, SectionRight, Name, HeadingWrapper,
} from './styles';
import Card from '../../components/Card/Cards';
import Constants from '../../shared/constants';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import ReadMore from '../../components/RedirectLinks/ReadMore';
import Colors from '../../styles/Colors';

class MyAccounts extends Component {
  giggerService = new GiggerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      firstName: '',
      lastName: '',
      loading: false,
      profileImage: '',
      hasProfile: JSON.parse(localStorage.getItem('userData')).hasProfile || false,
      id: '',
      loggedIn: this.authService.isAuthenticated(),
      giggerProfile: {},
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchMyAccounts();
    window.scrollTo(0, 0);
  }

  /**
   * Redirects to either profile page or detail page
   */
  handleProfile = () => {
    const {
      giggerProfile, hasProfile, name, firstName, lastName, id,
    } = this.state;
    const { profileStatus = 'completed', status } = giggerProfile;
    const { history } = this.props;

    const mapToPreviewPage = [
      Constants.status.COMPLETED, Constants.status.APPROVED, Constants.status.PENDING,
    ];

    if (
      hasProfile
      && mapToPreviewPage.includes(status)
      && mapToPreviewPage.includes(profileStatus)
    ) {
      history.push(`/details/${name}-${id}`);
    } else {
      history.push(`/profile/${firstName}-${lastName}-${id}`);
    }
  };

  /**
   * Redirects to my giggs page
   */
  handleMyGigs = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerMyGiggs.url);
  };

  /**
   * Redirects to user details page
   */
  handleUserDetails = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerUserDetails.url);
  };

  /**
   * Redirects to company page
   */
  handleCompanyDetails = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerCompanyDetails.url);
  };

  /**
   * @async
   * @function fetchMyAccounts fetches account details
   */
  async fetchMyAccounts() {
    this.setState({ loading: true });
    try {
      const response = await this.giggerService.getDetails();
      const { data = {} } = response;
      if (data) {
        localStorage.setItem('userData', JSON.stringify(response.data));
      }
      const {
        _id,
        first_login: firstLogin,
        full_name: fullName = '',
        first_name: firstName = '',
        last_name: lastName = '',
        profile_pic: profilePic = {},
      } = data;
      let close = firstLogin ?? true;
      close = typeof close === 'object' ? JSON.stringify(close) : false;

      this.setState({
        name: fullName,
        firstName,
        lastName,
        loading: false,
        profileImage: profilePic && profilePic.url,
        // hasProfile: response.data.hasProfile,
        id: _id,
        giggerProfile: data,
      });
      localStorage.setItem('first_login', close);
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      name, loading, loggedIn, profileImage, id,
    } = this.state;

    if (!loggedIn) {
      return (
        <Redirect
          to={{
            pathName: Constants.routes.logIn.url,
          }}
        />
      );
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <HeadingWrapper className="m-0">
              {loading ? (
                <Loader width="200px" height="32px" marginTop="24px" />
              ) : (
                <>
                  <div className="col p-0">
                    <Name>
                      <FormattedMessage id="common_hi" defaultMessage="Hi" />
                      {' '}
                      {name}
                      ,
                    </Name>
                  </div>
                </>
              )}
            </HeadingWrapper>
            <DisplayCards className="m-0 d-flex justify-content-between" flexWrap="nowrap">
              <SectionLeft className="p-0">
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    icon
                    iconUrl={profileImage}
                    header={<FormattedMessage id="common_my_profile" defaultMessage="My profile" />}
                    name={name}
                    id={id}
                    // hasProfile={this.state.hasProfile}
                    description={(
                      <FormattedMessage
                        id="gigger_myaccount_complete_profile_and_get_gig_message"
                        defaultMessage="Complete your profile and get a gig"
                      />
                    )}
                    onClick={this.handleProfile}
                  />
                )}
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="common_my_gigs" defaultMessage="My gigs" />}
                    description={(
                      <FormattedMessage
                        id="gigger_myaccount_my_gigs_description"
                        defaultMessage="See companies youve hired by"
                      />
                    )}
                    onClick={this.handleMyGigs}
                  />
                )}
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="common_user_details" defaultMessage="User details" />}
                    description={(
                      <FormattedMessage
                        id="common_edit_user_details_description"
                        defaultMessage="Edit e-mail, password, delete account"
                      />
                    )}
                    onClick={this.handleUserDetails}
                  />
                )}
              </SectionLeft>
              {loading ? (
                <Loader width="340px" height="72px" marginTop="24px" />
              ) : (
                <SectionRight className="p-0">
                  <Card
                    reminder
                    pinkCard
                    removeDeleteButton
                    header={(
                      <>
                        <Name>
                          {`${Constants.language.common_hi} ${name} `}
                        </Name>
                      </>
                      )}
                    description={(
                      <FormattedMessage
                        id="gigger_welcome_message_description"
                        defaultMessage="We're glad you want to be a part of GigAssembly. Via your consulting profile, you can market yourself to corporate customers within Novare's broad network, completely free of charge.Once you've created your profile, you can sit back and wait for potential customers to contact you. All you have to do is keep your profile and availability calendar up to date. Want tips and advice to optimize your profile strength and searchability.For any questions or concerns, see the most common questions alt. contact us at hello@gigassembly.com or 073-523 93 45.Good luck! / Team GigAssembly."
                        values={{
                          ReadMore: <ReadMore color={Colors.Primary} />,
                          break: <br />,
                        }}
                      />
                      )}
                    onClick={this.handleWelcomeCard}
                  />
                </SectionRight>
              )}
            </DisplayCards>
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default MyAccounts;
