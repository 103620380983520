import React, { Component } from 'react';
import { TickCircle, CrossCircle } from '../../styles/Icons';
import { ToastContainer, ToastText } from './styles';
import Constants from '../../shared/constants';

export const MainContext = React.createContext(false);

export default class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: '',
    };
  }

  componentDidMount() {
    const { context } = this;
    const { showToast = false, onToastClose, toastData = {} } = context;
    const { delay = 3000 } = toastData;
    this.setState({
      show: showToast,
    });
    setTimeout(() => {
      this.setState({
        show: false,
      });
      if (onToastClose) onToastClose();
    }, delay);
  }

  componentWillUnmount() {
    this.setState({
      show: false,
    });
  }

  /**
   * Closes toast bar
   */
  close() {
    this.setState({ show: false });
  }

  render() {
    const { toastData = {} } = this.context;
    const { type, message } = toastData;
    const { position } = this.props;
    const { show } = this.state;
    return (
      <>
        {show && message && type && (
        <ToastContainer type={type} position={position}>
          {type === Constants.toast.SUCCESS && <TickCircle />}
          {type === Constants.toast.ERROR && <CrossCircle />}
          <ToastText>{message}</ToastText>
        </ToastContainer>
        )}
      </>
    );
  }
}
Toast.contextType = MainContext;
