import ApiService from './ApiService';
import Const from '../shared/constants';

class AuthService extends ApiService {
  userLogin = 'login';

  userDetail = 'details';

  userDelete = 'approve-delete';

  async signIn(loginCredentials) {
    const response = await this.login(this.baseURL + this.userLogin, loginCredentials);

    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      // Store the token
      localStorage.setItem(Const.storage.token, response.data.data.token);
      localStorage.setItem('userData', JSON.stringify(response.data.data.user));
      return response;
    }
  }

  async usersDetails() {
    const response = await this.post(this.baseURL + this.userDetail);
    if (response.status !== 200) {
      localStorage.setItem('userData', JSON.stringify(response.data.data));
      throw Error(response.statusText);
    } else {
      return response.data.data;
    }
  }

  async deleteAccount(id, data) {
    const response = await this.post(`${this.baseURL + this.userDelete}/${id}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  isAdminUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => obj === Const.role.Admin).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isGiggerUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => obj === Const.role.GIGGER).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isCustomerUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => (obj === Const.role.COMPANY
          || obj === Const.role.COMPANY_USER)).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isCvVerificationUser() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const json = this.parseJwt(token);
      if (json) {
        return json.scopes.filter((obj) => obj === Const.role.CV_VERIFICATION).length > 0;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  isAuthenticated() {
    const token = localStorage.getItem(Const.storage.token);
    try {
      const jwtPayload = this.parseJwt(token);
      if (jwtPayload) {
        return jwtPayload.exp > +new Date() / 1000;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  parseJwt = (token) => {
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
    return token;
  }
}

export default AuthService;
