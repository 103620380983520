import React from 'react';
import {
  Section,
  LabelWrapper,
  TextDiv,
  Label,
  MandatoryLabel,
  SelctBorderBox,
  Divison,
  SelectPlaceholder,
  SelectValue,
  DropDownWrapper,
  DropDownList,
  DropDownOption,
  DropDownListWrapper,
} from './styles';
import { ArrowDown } from '../../styles/Icons';
import TextBox from './TextBox';
import Constants from '../../shared/constants';

class Select extends React.Component {
  constructor(props) {
    super(props);
    const { id, options } = this.props;
    this.state = {
      dropDown: false,
      selectedValue: props.value ? props.value : '',
      newlyAdded: [],
      isValid: true,
      allOptions: options,
      options,
      showPlusIcon: false,
      searchString: '',
    };
    this.selectRef = [];
    this.selectRef[id] = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { options: currentOptions = [] } = this.props;
    if (prevProps.options !== currentOptions) {
      let selectedValue = '';
      let { options: nextOptions = [] } = this.props;
      nextOptions = nextOptions
        ? nextOptions.map((obj) => {
          let option = obj;
          option = {
            ...obj,
            isSelected: obj.isSelected ? (selectedValue = obj.label) : false,
          };
          return option;
        })
        : [];
      this.onOptionsUpdate(selectedValue, nextOptions);
    }
    const { searchString } = this.state;
    if (prevState.searchString !== searchString && searchString.length === 0) {
      this.onSearchUpdate();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Closes the dropdown on clicking outside the component.
   * @param {event} e captures onclick event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { id } = this.props;
    const { dropDown } = this.state;
    if (!this.selectRef[id].contains(e.target) && dropDown) {
      this.closeDropDown();
    }
  }

  /**
   * Updates the options on change in serch word.
   */
  onSearchUpdate = () => {
    const { allOptions } = this.state;
    const filteredOptions = this.filterUniqueOptions(allOptions);
    this.setState({ options: filteredOptions, allOptions: filteredOptions });
  };

  /**
   * Updates the selected value and options.
   * @param {*} selectedValue selected options
   * @param {object} options Options
   */
  onOptionsUpdate = (selectedValue, options) => {
    this.setState({
      options,
      allOptions: options,
      selectedValue,
    });
  };

  /**
   * Removes duplicate options
   * @param {object} options
   */
  filterUniqueOptions = (options) => {
    const map = {};
    return options.filter((option) => {
      const label = option.label.toLowerCase();
      if (!map[label]) {
        map[label] = label;
        return true;
      }
      return false;
    });
  };

  /**
   * Closes dropdown
   */
  closeDropDown = () => {
    const { dropDown } = this.state;
    this.setState((prevState) => ({ dropDown: !prevState.dropDown, searchString: '' }));
    if (dropDown) {
      this.validate();
    }
  };

  /**
   * Marks the selected option.
   * @param {object} obj selected option
   */
  optionSelected = (obj) => () => {
    const { onChange, name } = this.props;
    const { allOptions } = this.state;
    this.setState({ dropDown: false, selectedValue: obj.label, options: allOptions },
      this.validate);
    if (onChange) {
      onChange(name, obj.label, obj.value);
    }
  };

  /**
   * Validates the input element
   */
  validate = () => {
    const { selectedValue } = this.state;
    const { value, noEmptyError } = this.props;
    const isValid = selectedValue !== '' || value !== '' || noEmptyError;
    this.setState({ isValid });
  };

  /**
   * Handles search operation
   * @param {event} e
   */
  handleSearch = (e) => {
    let { value } = e.target;
    value = value.replace(value.substr(0, 1), value.substr(0, 1).toUpperCase());
    let options;
    const { allOptions } = this.state;
    if (value === '') {
      options = allOptions;
    } else {
      options = allOptions.filter(
        (column) => column.label.toLowerCase().includes(value.toLowerCase()),
      );
    }

    this.setState({ options, searchString: value, showPlusIcon: options.length < 1 });
  };

  /**
   * Handles key press on the input element.
   * @param {event} e
   */
  handleKeypress = (e) => {
    const { canAddNewOption } = this.props;
    if (e.key === 'Enter' && canAddNewOption) {
      let { value } = e.target;
      value = value.replace(value.substr(0, 1), value.substr(0, 1).toUpperCase());
      let options;
      const { allOptions, newlyAdded } = this.state;

      if (value === '') {
        options = allOptions;
      } else {
        options = allOptions.filter(
          (column) => column.label.toLowerCase().includes(value.toLowerCase()),
        );
        if (options.length === 0) {
          newlyAdded.push({ label: value, value, isSelected: false });
        }
      }
      this.setState(
        {
          allOptions,
          options,
          newlyAdded,
          showPlusIcon: false,
        },
        this.optionSelected({ label: value, value, isSelected: false }),
      );
    }
  };

  /**
   * Adds new option to the existing options on pressing enter.
   */
  handleAddNewOption = () => {
    const { searchString } = this.state;
    this.handleKeypress({ target: { value: searchString }, key: 'Enter' });
  };

  render() {
    const {
      label,
      id,
      disabled,
      value,
      mandatory,
      search,
      placeholder,
      classname,
      existError,
      errorMessage,
      existErrorMessage,
      showError,
      name,
      searchPlaceholder,
      errorMessageClass,
      canAddNewOption,
    } = this.props;
    const {
      dropDown, selectedValue, isValid, options, showPlusIcon, searchString,
    } = this.state;
    return (
      <Section
        title={value}
        ref={(selectRef) => {
          this.selectRef[id] = selectRef;
        }}
        position="relative"
        className={disabled ? 'disableOption' : ''}
      >
        {label && (
          <LabelWrapper>
            <Label mandatory={mandatory}>{label || ''}</Label>
            {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
          </LabelWrapper>
        )}

        <SelctBorderBox
          className={
            showError || !isValid || existError
              ? 'border-red row m-0 p-0'
              : classname
                ? 'border-red row m-0 p-0'
                : ' row m-0 p-0'
          }
        >
          <Divison className="col p-0" onClick={this.closeDropDown} role="button">
            {!(selectedValue || value) ? (
              <SelectPlaceholder>
                {placeholder
                || Constants.language.select_placeholder_choose}
              </SelectPlaceholder>
            ) : (
              <SelectValue>
                {selectedValue || value
                || Constants.language.select_placeholder_choose}
              </SelectValue>
            )}
          </Divison>
          <DropDownWrapper className="col-2 p-0 mr-3 justify-content-end" role="button" onClick={this.closeDropDown}>
            <ArrowDown width="20px" className={dropDown ? 'rotate-180' : ''} />
          </DropDownWrapper>
        </SelctBorderBox>
        {dropDown && !disabled && options && (
          <DropDownListWrapper>
            {search && name !== 'hourlyRate' && (
              <TextBox
                type="text"
                name="search"
                showPlusIcon={canAddNewOption && showPlusIcon}
                onAddNewOption={this.handleAddNewOption}
                value={searchString}
                placeholder={searchPlaceholder || Constants.language.common_search}
                onChange={this.handleSearch}
                onKeyPress={this.handleKeypress}
                disabletickMark={name === 'category' && !options.length}
              />
            )}
            {options.length > 0 ? (
              <DropDownList className="card">
                <>
                  {options.map((obj) => (
                    <DropDownOption
                      onClick={this.optionSelected(obj)}
                      key={obj.value}
                      role="button"
                      isSelected={obj.label === selectedValue}
                      className={`${obj.value === value ? 'font-weight-semibold' : ''} m-0 cursor-pointer`}
                    >
                      {obj.label}
                    </DropDownOption>
                  ))}
                </>
              </DropDownList>
            ) : name === 'category' && (
              <DropDownList className="card">
                <DropDownOption>
                  {Constants.language.common_no_search_results_found}
                </DropDownOption>
              </DropDownList>
            )}
          </DropDownListWrapper>
        )}
        {existError && isValid && (
          <TextDiv className={errorMessageClass || ''}>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{existErrorMessage}</span>
          </TextDiv>
        )}
        {(showError || !isValid) && (
          <TextDiv className={errorMessageClass || ''}>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{errorMessage}</span>
          </TextDiv>
        )}
      </Section>
    );
  }
}

export default Select;
