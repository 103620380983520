import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import {
  FlexContainer,
  Container,
  PageName,
  Header,
  Content,
  CurrentPage,
  SubHeader,
  HeaderName,
  Input,
} from './styles';
import Loader from '../../components/Loader/Loader';
import { UserDetailsWrapper, ButtonsWrapper } from '../gigger/styles';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import { RelativeContainer } from '../../styles/CommonStyles';
import TextBox from '../../components/Input/TextBox';
import Button from '../../components/Button/Button';

class CompanyDetails extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      orgNumber: '',
      companyName: '',
      address: '',
      zipcode: '',
      city: '',
      formValid: {
        zipcode_is_Valid: true,
      },
      zipcodeError: false,
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
    };
  }

  componentDidMount() {
    const { loggedIn, data = [] } = this.state;
    if (loggedIn) this.fetchCompanyDetails();
    this.onDataUpdation(data);
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    if (prevProps.data !== data) {
      this.onDataUpdation(data);
    }
  }

  /**
   * Updation of user data in form.
   * @param {object} data Data of the user.
   */
  onDataUpdation = (data = {}) => {
    const { invoiceDetails = {} } = data;
    const { address = '', zip: zipcode = '', city = '' } = invoiceDetails || {};
    this.setState({
      address: address || '',
      zipcode: zipcode || '',
      city: city || '',
    });
  };

  /**
   * Handles change in input element value.
   * @param {event} event
   */
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.validate);
  };

  /**
   * Handles on blur event of the input element
   */
  handleBlur = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.validate);
  };

  /**
   * handles on key press event of the input element.
   * @param {event} evt
   */
  handleKeyPress = (evt) => {
    const theEvent = evt || window.event;
    const { name, value } = theEvent.target;
    if (name === Constants.zipcode && value.length >= Constants.zipcodeLength) {
      theEvent.preventDefault();
    }
  };

  /**
   * @function handleSubmitDetails
   * Sends the user data to parent.
   */
  handleSubmitDetails = async () => {
    const { address, zipcode, city } = this.state;
    const data = {
      address: address || '',
      zip: zipcode || '',
      city: city || '',
    };
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.customerService.updateCompanyDetails(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_user_details_updated;
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Redirects to My accounts page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
    *  Redirects to search giggers page
    */
  handleSearchGiggers = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerList.url);
  };

  /**
   * @async
   * @function fetchCompanyDetails Fetches company details of the user
   */
  async fetchCompanyDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.customerService.companyDetails();
      const { data = {} } = response;
      this.setState({
        companyName: data.company_name,
        orgNumber: data.organization_number,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  /**
   * Validates user details form
   */
  validate() {
    const { formValid, zipcode } = this.state;
    let error = false;
    formValid.zipcode_is_Valid = zipcode && zipcode.length > 0 && zipcode.length < 5;
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  render() {
    const {
      orgNumber,
      companyName,
      isDisabled,
      loading,
      loggedIn,
      address,
      zipcode,
      city,
      zipcodeError,
      formValid,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <RelativeContainer>
        <FlexContainer>
          <Container>
            <Content className="row">
              <PageName className="opacity-7 " onClick={this.handleMyAccount} role="button">
                <FormattedMessage id="common_my_account" defaultMessage="My Account" />
                {' '}
                {' > '}
                {' '}
              </PageName>
              <span>&nbsp;</span>
              <CurrentPage>
                <FormattedMessage id="common_company_details" defaultMessage="Company Details" />
              </CurrentPage>
            </Content>
            <Content className="row">
              {loading ? (
                <Loader width="150px" />
              ) : (
                <Header>
                  <FormattedMessage id="common_company_details" defaultMessage="Company Details" />
                </Header>
              )}
              <div className="col-8 p-0 mr-lg-3 pr-lg-1">
                <ButtonsWrapper>
                  <Button
                    id="search"
                    type="update"
                    name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                    onClick={this.handleSearchGiggers}
                  />
                </ButtonsWrapper>
              </div>
            </Content>
            <Content className="row">
              <UserDetailsWrapper>
                <Content className="row">
                  {loading ? (
                    <Loader width="150px" />
                  ) : (
                    <SubHeader>
                      <FormattedMessage id="common_company_settings" defaultMessage="Company settings" />
                    </SubHeader>
                  )}
                  {' '}
                </Content>
                {' '}
                <>
                  <Input>
                    <Content className="row pl-0">
                      {loading ? (
                        <Loader width="150px" />
                      ) : (
                        <HeaderName className="mt-2 mb-0">
                          <FormattedMessage id="common_orginisation_number" defaultMessage="Org. number" />
                          :
                          {' '}
                          {orgNumber}
                        </HeaderName>
                      )}
                    </Content>
                    <Content className="row pr-0  ">
                      {loading ? (
                        <Loader width="150px" />
                      ) : (
                        <HeaderName className="mt-2 mb-0">
                          <FormattedMessage id="common_company_name" defaultMessage="Company name" />
                          :
                          {' '}
                          {companyName}
                        </HeaderName>
                      )}
                    </Content>
                    <Content className="row">
                      <Content className="col-12 p-0">
                        <HeaderName>
                          <FormattedMessage id="common_billing_address" defaultMessage="Billing reference" />
                        </HeaderName>
                      </Content>
                      <Content className="col-12 p-0">
                        <TextBox type="text" name="address" value={address} onChange={this.handleChange} noEmptyError />
                      </Content>
                      <Content className="col-12 p-0">
                        <HeaderName>
                          <FormattedMessage id="common_city" defaultMessage="City" />
                        </HeaderName>
                      </Content>
                      <Content className="col-12 p-0">
                        <TextBox type="text" name="city" value={city} onChange={this.handleChange} noEmptyError />
                      </Content>
                      <Content className="col-12 p-0">
                        <HeaderName>
                          <FormattedMessage id="common_zip_code" defaultMessage="Zipcode" />
                        </HeaderName>
                      </Content>
                      <Content className="col-12 p-0">
                        <TextBox
                          type="number"
                          name="zipcode"
                          value={zipcode}
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          existError={zipcodeError && !formValid.zipcode_is_Valid}
                          disabletickMark={!(zipcode && zipcode.length === Constants.zipcodeLength)}
                          onBlur={this.handleBlur}
                          existErrorMessage={
                            <FormattedMessage id="error_zip_code_message" defaultMessage="Enter a valid ZIP code" />
                          }
                          noEmptyError
                        />
                      </Content>
                    </Content>
                    <Content className="mt-3 mb-4">
                      <Button
                        type="update"
                        name={<FormattedMessage id="common_update_details" defaultMessage="Update details" />}
                        onClick={this.handleSubmitDetails}
                        disabled={isDisabled}
                      />
                    </Content>
                  </Input>
                </>
              </UserDetailsWrapper>
            </Content>
          </Container>
        </FlexContainer>
      </RelativeContainer>
    );
  }
}

export default CompanyDetails;
