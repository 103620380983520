import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import FilterPageService from '../../../../services/AdminServices/FilterPageService';
import Config from '../../../../config';
import Constants from '../../../../shared/constants';
import { getFormattedCSVData } from '../../../../shared/utils';
import { FilterListWrapper } from '../styles';
import { Border } from '../../users/styles';
import LoadingCard from '../../LoadingCard';
import Modal from '../Modal';
import { WhiteCard } from '../../../gigger/styles';
import Pagination from '../../../../components/Pagination/Pagination';
import ListView from '../ListView/ListView';
import ListViewHeader from '../ListView/ListViewHeader';
import ToolBar from '../../ToolBar';

const SkillsTab = ({ loading, handleToast }) => {
  const [skillsTabPageLoading, setSkillsTabPageLoading] = useState(false);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 15,
    pages: 1,
  });
  const [skillData, setSkillData] = useState([]);
  const [data, setData] = useState([]);
  const [searchbar, setSearchBar] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortAscending, setSortAscending] = useState(false);
  const [csvDownloadData, setCsvDownloadData] = useState([]);
  const csvExportFileds = ['name', 'type', 'added_by', 'created_at', 'status'];
  const filterPageService = new FilterPageService(Config.apiBase);
  const [isApproveSkillModalOpen, setIsApproveSkillModalOpen] = useState(false);
  const [isDeleteSkillModalOpen, setIsDeleteSkillModalOpen] = useState(false);
  const openSkillsModal = useRef(null);
  const skillsCsv = useRef(null);

  /**
   *
   * @async
   * @function fetchSkills Fetches job title filters
   * @param {String} searchInput search text
   *
   */
  const fetchSkills = (searchInput) => {
    setSkillsTabPageLoading(true);
    const queryParams = [
      ['pageNumber', searchInput ? 1 : meta.pageNumber],
      ['pageSize', meta.pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['search', searchInput],
      ['type', Constants.filter.SKILLS],
    ];
    try {
      filterPageService.getSkillsList(queryParams).then((response) => {
        const { meta: srcMetaData = {} } = response;
        const {
          current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
        } = srcMetaData;
        const metaData = {
          pageNumber: currentPage,
          pageSize: perPage,
          pages,
          totalCount,
        };
        setSkillsTabPageLoading(false);
        setSkillData(response.data);
        setMeta(metaData);
      });
    } catch (e) {
      setSkillsTabPageLoading(false);
    }
  };

  /**
   *
     * Calls fetchSkills function when the below values changes
     * [isDeleteModalOpen,isApproveModalOpen, csvDownloadData]
     *
     */
  useEffect(() => {
    if (isDeleteSkillModalOpen || isApproveSkillModalOpen) {
      openSkillsModal.current.click();
    }
  }, [isDeleteSkillModalOpen, isApproveSkillModalOpen]);

  /**
   *
     * Calls fetchSkills function when the below values changes
     * [loading, meta.pageNumber, showToast, sortAscending, sortedBy]
     *
     */
  useEffect(() => {
    fetchSkills();
  }, [loading, sortAscending, sortedBy]);

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePaginationInSkillsTab = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 15;
    setMeta(meta);
    fetchSkills();
  };

  /**
   *
   * Handles search functionality
   * @param {String} value search text
   *
   */
  const handlesearchSkills = (value) => {
    setSearchQuery(value);
    setSearchBar(value.length <= 0);
    fetchSkills(value);
  };

  /**
   *
   * Handles change in search value
   * @param {Event} event search text
   *
   */
  const handleSearchOnChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  /**
   *
   * Downloads data in the form of csv file
   *
   */
  const handleDownloadCsv = async () => {
    setSkillsTabPageLoading(true);
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
      ['type', Constants.filter.SKILLS],
    ];
    try {
      const response = await filterPageService.getSkillsList(queryParams);
      setCsvDownloadData(getFormattedCSVData(response.data));
      setSkillsTabPageLoading(false);
      skillsCsv.current.link.click();
    } catch (e) {
      setSkillsTabPageLoading(false);
    }
  };

  /**
   *
   * Opens delete modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleDelete = (deleteData) => () => {
    setIsDeleteSkillModalOpen(true);
    setData(deleteData);
  };

  /**
   *
   * Opens approve modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleApprove = (approveData) => () => {
    setIsApproveSkillModalOpen(true);
    setData(approveData);
  };

  /**
   *
   * Closes the opened modal
   *
   */

  const handleModalClose = () => {
    setIsApproveSkillModalOpen(false);
    setIsDeleteSkillModalOpen(false);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSorting = (name) => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };

  /**
   *
   * Opens out search bar
   *
   */
  const openSearchBar = () => {
    setSearchBar(false);
  };

  return (
    <>
      <CSVLink data={csvDownloadData} filename="skills.csv" className="d-none" ref={skillsCsv}>
        click
      </CSVLink>
      <FilterListWrapper>
        <ToolBar
          searchbar={searchbar}
          handleSearch={handlesearchSkills}
          handleSearchOnChange={handleSearchOnChange}
          searchQuery={searchQuery}
          handleDownloadCsv={handleDownloadCsv}
          openSearchBar={openSearchBar}
          data={skillData}
        />
        {skillsTabPageLoading && (
        <FilterListWrapper>
          <LoadingCard />
        </FilterListWrapper>
        )}
        {!skillsTabPageLoading && (
        <>
          <ListViewHeader
            handleSorting={handleSorting}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
          />
          {skillData.length > 0 ? (
            <>
              <ListView
                data={skillData}
                handleApprove={handleApprove}
                handleDelete={handleDelete}
                type={<FormattedMessage id="common_skill" defaultMessage="Skill" />}
              />
              <a
                href
                data-backdrop="static"
                data-keyboard="false"
                data-toggle="modal"
                data-target="#FilterModal"
                ref={openSkillsModal}
              />
              {isApproveSkillModalOpen && (
              <Modal
                approve={isApproveSkillModalOpen}
                id={data._id}
                type={Constants.filter.SKILLS}
                ModalClose={handleModalClose}
                handleToast={handleToast}
                fetchData={fetchSkills}
              />
              )}
              {isDeleteSkillModalOpen && (
              <Modal
                remove={isDeleteSkillModalOpen}
                id={data._id}
                type={Constants.filter.SKILLS}
                ModalClose={handleModalClose}
                handleToast={handleToast}
                fetchData={fetchSkills}
              />
              )}
            </>
          ) : (
            <>
              <Border />
              <WhiteCard className="my-3">
                {searchQuery.length > 0 ? (
                  <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
                ) : (
                  <FormattedMessage id="not_found_filter_skills" defaultMessage="No skills found" />
                )}
              </WhiteCard>
            </>
          )}
        </>
        )}
      </FilterListWrapper>
      {!skillsTabPageLoading && meta.pages > 1 && (
      <FilterListWrapper style={{ backgroundColor: 'transparent' }}>
        <Pagination cursor={meta} gotoPage={handlePaginationInSkillsTab} />
      </FilterListWrapper>
      )}
    </>
  );
};

export default SkillsTab;
