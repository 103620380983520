import React from 'react';
import { FormattedMessage } from 'react-intl';
import Config from '../../config';
import { Link, Line } from '../../pages/SignUp/styles';
import Constants from '../../shared/constants';
import { getLocaleFromURL } from '../../shared/utils';

const currentLanguage = getLocaleFromURL() || Config.language;
const ReadMore = ({ color }) => (
  <Link href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].optimizeyourprofile}`} color={color}>
    <Line role="button">
      <FormattedMessage id="label_read_more_here" defaultMessage="Read more here" />
    </Line>
  </Link>
);
export default ReadMore;
