import ApiService from '../ApiService';

class UserService extends ApiService {
  endPoint = {
    users: 'admin/users',
    createUser: 'admin/users/create',
    editUser: 'admin/users/edit/',
    deleteUser: 'admin/users/',
  };

  async getUsersList(queryParam) {
    const response = await this.get(this.baseURL
      + this.endPoint.users + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async addUser(data) {
    const response = await this.post(this.baseURL + this.endPoint.createUser, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }

  async editUser(id, data) {
    const response = await this.put(this.baseURL + this.endPoint.editUser + id, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }

  async removeUser(id) {
    const response = await this.delete(this.baseURL + this.endPoint.deleteUser + id);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }
}
export default UserService;
