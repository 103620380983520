import React, { Component } from 'react';
import { TabContainer, TabName } from './styles';

class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tabs, currentTab, selectedTab } = this.props;
    return (
      <>
        <TabContainer className="row m-0 tab-head">
          {tabs.map((obj, index) => (
            <TabName
              onClick={() => currentTab(obj)}
              className={selectedTab === obj ? 'tabActive' : ''}
              left={index}
            >
              {obj}
            </TabName>
          ))}
        </TabContainer>
      </>
    );
  }
}

export default Tab;
