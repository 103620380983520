import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
} from '../../../components/Modals/styles';
import CheckBox from '../../../components/Input/CustomCheckbox';
import ProfilePageService from '../../../services/ProfilePageService';
import OrderService from '../../../services/AdminServices/OrderService';
import Config from '../../../config';
import Constants from '../../../shared/constants';
import Document from '../../../components/Document';
import ModalLoader from '../../../components/Loader/ModalLoader';

class Modal extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  orderService = new OrderService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      docId: [],
      docs: [],
      data: props.data || [],
      uploadedFileData: {},
      loading: false,
    };
  }

  // componentDidUpdate(prevProps) {
  //   const { data } = this.state;
  //   const { data: prevData = [] } = prevProps;
  //   if (prevData !== data) {
  //     this.onUpdate();
  //   }
  // }

  onUpdate=() => {
    const { data } = this.state;
    this.setState({ data });
  }

  /**
   * Handles change in input fields
   * @param {Event} event
   * @listens Event
   */

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Collects the data of uploaded file
   * @param {object} filedata Data of the uploaded file
   */

  handleDocumentChange = (filedata) => {
    const { docId } = this.state;
    const uploadedFileData = { ...filedata.data, type: 'upload' };
    docId.push(filedata.data._id);
    this.setState({ uploadedFileData, docId });
  };

  /**
   * Removes the uploaded file
   */

  handleRemoveFile = () => {
    this.setState({ loading: true });
    let { docs, docId } = this.state;
    docs = docs.filter((doc) => {
      if (doc.type && doc.type === 'upload') {
        docId = docId.filter((id) => id !== doc._id);
        return false;
      }
      return true;
    });

    this.setState({
      uploadedFileData: {}, docs, docId, loading: false,
    });
  };

  /**
   * @async
   * @function handleCompleteOrder Completes the order
   */

  handleCompleteOrder = async () => {
    this.setState({ loading: true });
    const { id, updateOrdersList } = this.props;
    const { docId } = this.state;
    const data = {
      docs: {
        value: docId,
      },
    };
    try {
      const response = await this.orderService.confirmOrder(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateOrdersList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateOrdersList();
    }
  };

  /**
   * @async
   * @function handleInvoiceOrder Invoices the order
   */

  handleInvoiceOrder = async () => {
    this.setState({ loading: true });
    const { id, updateOrdersList } = this.props;
    try {
      const response = await this.orderService.invoiceOrder(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateOrdersList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateOrdersList();
    }
  };

  /**
   * @async
   * @function handleCancelOrder Cancels the order
   */

  handleCancelOrder = async () => {
    this.setState({ loading: true });
    const { id, updateOrdersList } = this.props;
    try {
      const response = await this.orderService.cancelOrder(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateOrdersList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateOrdersList();
    }
  };

  /**
   * Adds the existing document
   */

  handleAddDoc = (index) => () => {
    this.setState({ loading: true });
    const { data, uploadedFileData } = this.state;
    let docId = [];
    let docs = [];
    data.recent_docs[index].isSelected = !data.recent_docs[index].isSelected;
    data.recent_docs.map((doc) => {
      if (doc.isSelected) {
        docs.push(doc);
        docId.push(doc._id);
      } else {
        docs = docs.filter((document) => document._id !== doc._id);
        docId = docId.filter((obj) => obj !== doc._id);
      }
      return doc;
    });
    if (uploadedFileData && uploadedFileData._id) {
      docId.push(uploadedFileData._id);
    }
    this.setState({
      data, docs, docId, loading: false,
    });
  };

  /**
   * Ui for cancel order
   */

  renderCancelBody = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage
            id="admin_order_cancel_order_confirmation"
            defaultMessage="Are you sure you want to cancel?"
          />
        </Text>
        <Input>
          <Button onClick={this.handleCancelOrder}>
            <FormattedMessage id="common_yes_cancel" defaultMessage="Yes, cancel" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  }

  /**
   * ui for complete order modal
   */

  renderCompleteOrder = () => {
    const {
      uploadedFileData, data,
    } = this.state;
    const { ModalClose } = this.props;
    const { recent_docs: recentDocs = [] } = data;

    return (
      <>
        <Text className="mt-3">
          <FormattedMessage
            id="admin_order_completing_order_next_steps_info"
            defaultMessage="By completing order the documents will be sent to the customer and XYZ."
          />
        </Text>
        <Input>
          <Text>
            <FormattedMessage id="common_upload_documents" defaultMessage="Upload your documents" />
          </Text>
          <Document
            files={uploadedFileData}
            onChange={this.handleDocumentChange}
            onRemove={this.handleRemoveFile}
            title={data.type}
            allowedTypes={Constants.inputTypes.acceptFileTypes}
          />

          <Text className="mt-2">
            <FormattedMessage id="common_upload_max_doc_info" defaultMessage="Maximum 3 mb, only jpg,pdf,word" />
          </Text>
        </Input>
        {recentDocs.length > 0 && (
          <Input>
            <Text><FormattedMessage id="add_from_recent" defaultMessage="Add from recent" /></Text>
            {recentDocs.map((order, index) => (
              <CheckBox
                isSelected={order.isSelected ? order.isSelected : false}
                label={order.file_name}
                onClick={this.handleAddDoc(index)}
              />
            ))}
          </Input>
        )}
        <Input>
          <Button
            onClick={this.handleCompleteOrder}
          >
            <FormattedMessage id="admin_order_complete_order" defaultMessage="Complete order" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  /**
   * ui for invoice order modal
   */

  renderInvoiceBody = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Input>
          <Text>
            <FormattedMessage
              id="admin_order_by_invoicing_info"
              defaultMessage="Are you sure you want to update the order status?"
            />
          </Text>
        </Input>
        <Input>
          <Button onClick={this.handleInvoiceOrder}>
            <FormattedMessage id="admin_order_yes_invoice" defaultMessage="Yes, invoice" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  handleRemoveOrder = async () => {
    const {
      id, updateOrdersList,
    } = this.props;
    this.setState({ loading: true });
    try {
      const response = await this.orderService.removeOrder(id);
      this.setState({ loading: false });
      if (response) {
        this.closeElement.click();
        updateOrdersList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateOrdersList();
    }
  }

  renderRemoveOrder = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage
            id="remove_filter_confirmation_message"
            defaultMessage="Are you sure you want to delete?"
            values={{
              filter: Constants.translateType.order.toLowerCase(),
            }}
          />
        </Text>
        <Input>
          <Button onClick={this.handleRemoveOrder}>
            <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  render() {
    const {
      cancel, completeOrder, invoiceOrder, ModalClose, remove,
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="OrderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-small" role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      {!cancel && (
                      <Header className="m-0">
                        {invoiceOrder ? (
                          <FormattedMessage id="admin_order_invoice_order" defaultMessage="Invoice order?" />
                        ) : (
                          <FormattedMessage id="admin_order_complete_order" defaultMessage="Complete order" />
                        )}
                      </Header>
                      )}
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {loading && <ModalLoader />}
                  {(cancel && !loading) && this.renderCancelBody()}
                  {(completeOrder && !loading) && this.renderCompleteOrder()}
                  {(invoiceOrder && !loading) && this.renderInvoiceBody()}
                  {(remove && !loading) && this.renderRemoveOrder()}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
