import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import px2vw from '../../styles/px2vw';
import { BackgroundImage } from '../../styles/Images';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color:${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image:${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)};
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export const CompanyDetailsWrapper = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  margin: auto;
  width: 1100px;
  min-height: 100vh;
  margin-top: 40px;
  @media (max-width: 1140px) {
    width: 70%;
  }
  @media (max-width: 1140px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 75%;
    margin-top: 100px;
    margin-left: 22%;
    margin-right: 22%;
    align-self: center;
  }
  @media (max-width: 570px) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    align-self: center;
  }
  @media (max-width: 375px) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    align-self: center;
  }
`;
export const DisplayCards = styled.div`
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 570px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;
export const SectionLeft = styled.div`
  flex: 0.5 0 ${px2vw(380, 1440)};
  margin-right: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const SectionRight = styled.div`
  flex: 0 0 ${px2vw(540, 1440)};
  height: inherit;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
  }
  @media (max-width: 375px) {
  }
`;
export const CardWrapper = styled.div`
  max-width: ${px2vw(475, 1440)};
  flex: 0 0 ${px2vw(475, 1440)};
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const Header = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size28}px;
  font-weight: 500;
  margin: 24px 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  @media (max-width: 768px) {
    font-family: ${Font.fontFamily.AvenirBold};
  }
`;
export const SubHeader1 = styled.p`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  font-size: ${Font.fontSize.Size18}px;
  margin: 0;
  text-decoration: underline !important;
`;
export const SubHeader = styled(SubHeader1)`
  &:hover {
    color: ${Colors.Black};
  }
`;
export const HeaderName = styled(SubHeader1)`
  font-size: ${Font.fontSize.Size14}px;
  margin-top: 16px;
  margin-bottom: 5px;
  font-family: ${(props) => (props.personalNumber ? Font.fontFamily.Archivo : Font.fontFamily.AvenirRegular)};
  text-decoration: none !important;
  color: ${({ lightGreyColor, stromGreyColor }) => (lightGreyColor ? `${Colors.LightGrey}` : stromGreyColor ? `${Colors.StromGrey}` : 'none')};
`;
export const SubHeader2 = styled(SubHeader1)`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  margin-top: 8px;
  text-decoration: none !important;
`;
export const UserDetailsExplanation = styled(SubHeader2)`
  margin-top: 0px;
  margin-bottom: 8px;
`;
export const Upload = styled(SubHeader2)`
  color: ${Colors.Primary};
  cursor: pointer;
`;
export const PageName = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin: 0px;
  padding: 0px;
`;
export const CurrentPage = styled(PageName)`
  font-family: ${Font.fontFamily.Archivo};
  font-weight: 500;
  color: ${Colors.Black};
`;
export const UserDetailsWrapper = styled.div`
  background-color: ${Colors.White};
  padding: 16px 32px 16px 32px;
  flex: 0 0 ${px2vw(696, 1440)};
  max-width: ${px2vw(696, 1440)};
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(20, 375)};
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(16, 375)};
  }
`;
export const MyGiggersWrapper = styled(UserDetailsWrapper)`
  padding: 16px;
`;
export const Content = styled.div`
  margin: 0px;
  text-align: ${({ align }) => align || 'unset'};
`;
export const Button = styled.button`
  border-radius: 2px;
  background-color: ${Colors.Primary};
  width: 100%;
  margin-top: 40px;
  height: 40px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  outline: none;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
`;
export const ForgotPassword = styled.a`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132 !important;
  flex: 1;
  text-align: center;
  cursor: pointer;
`;
export const Delete = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  flex: 1;
  margin-bottom: 11px;
  cursor: pointer;
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Red};
`;
export const Message = styled(Delete)`
  color: ${Colors.LightViolet};
  margin: 0px;
  cursor: text;
`;
export const Input = styled.div`
  padding: 0px;
  max-width: ${px2vw(380, 1440)};
  @media (max-width: 768px) {
    max-width: ${px2vw(380, 768)};
  }
  @media (max-width: 570px) {
    max-width: ${px2vw(380, 570)};
  }
  @media (max-width: 375px) {
    max-width: ${px2vw(346, 375)};
  }
`;
export const Paragraph = styled.div`
  padding: 0px;
  max-width: 42%;
  @media (max-width: 1200px) {
    max-width: 52%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
export const OrderWrapper = styled.div`
  background-color: ${Colors.White};
  padding: 0px;
  margin: 24px 0px 24px 0px;
  flex: 0 0 ${px2vw(854, 1440)};
  max-width: ${px2vw(854, 1440)};
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
    overflow-x: auto;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
    overflow-x: auto;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
    overflow-x: auto;
  }
`;
export const OrderHeader = styled.span`
  font-family: ${Font.fontFamily.AvenirBold};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
`;
export const OrderData = styled(OrderHeader)`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-weight: normal;
`;
export const OrderRow = styled.div`
  padding: 16px 0px 16px 0px;
`;
export const OrderStatusButton = styled.div`
  width: 95px;
  height: 24px;
`;
export const DetailCard = styled.div`
  background-color: ${Colors.DarkWhite};
  padding: 16px 24px;
  width: 100%;
`;
export const CardHeader = styled.p`
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin: 0;
`;
export const UserName = styled(CardHeader)`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-weight: 500;
`;
export const Value = styled(CardHeader)`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-weight: normal;
  margin-right: 15px;
`;
export const Div = styled.div`
  margin: 0;
`;
export const PinkCard = styled.div`
  background-color: ${Colors.Pink};
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  padding: 16px 24px;
`;
export const customerDetailPinkCard = styled(PinkCard)``;
export const Border = styled.div`
  border-top: 1px solid #d1dee4 !important;
`;
export const BorderLight = styled.div`
  border-top: 1px solid #f1f5f7 !important;
`;
export const MessageCard = styled.div`
  background-color: ${Colors.Pink};
  padding: 8px;
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  font-family: ${Font.fontFamily.AvenirRegular};
  color: #21243b;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
`;
export const GreenCard = styled(MessageCard)`
  background-color: ${Colors.DarkWhite};
  color: ${Colors.Primary};
  margin-top: 16px;
`;
export const Form = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const SelectBox = styled.div`
  padding: 0px 25px 0px 25px !important;
  @media (max-width: 768px) {
    padding: 0px 25px 8px 0px !important;
  }
  @media (max-width: 570px) {
    padding: 0px 16px 8px 0px !important;
  }
  @media (max-width: 375px) {
    padding: 0px 16px 8px 0px !important;
  }
`;
export const StarIcon = styled.img`
  width: 22px;
  heigth: 22px;
`;
export const Image = styled.img``;
export const SendButton = styled.div`
  width: 200px;
`;
export const SaveButton = styled.div`
  width: 120px;
`;
export const DetailCheckButton = styled.button`
  height: auto;
  padding: 8px;
  width: 100%;
  border-radius: 2px;
  background-color: #eca477;
  color: #0e3132;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  outline: none;
  margin: 8px 0px;
  border: none;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #f1c5a9;
  }
`;
export const Document = styled.u`
  &:hover {
    color: ${Colors.Black};
  }
`;
export const NoDataCard = styled.div`
  background-color: ${Colors.White};
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #21243b;
  margin: 24px 0px 24px 0px;
  text-align: center !important;
`;
export const SaveGiggerWrapper = styled.span`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  float: right !important;
`;
export const SaveGiggerIcon = styled.div`
  border: 2px solid #06041d;
  border-radius: 4px;
  height: 13.3px;
  width: 13.3px;
`;
export const IsMobile = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: inline-block;
    flex: 0 0 50%;
  }
`;

export const IsDesktop = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: inline-block;
    flex: 0 0 50%;
    flex-direction: column;
  }
`;

export const ShowAllButton = styled.div`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132;
  text-align: center;
  margin: 16px 16px 40px 16px;
`;

export const Desktop = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Mobile = styled.div`
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;

export const WhiteButton = styled.button`
  border: none;
  outline: none;
  color: ${Colors.Secondary};
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  padding: 0;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`;

export const A = styled.a`
  color: inherit;
  &:hover {
    text-decoration: none;
    color: ${Colors.Black};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WelcomeTextContent = styled.p`
  font-size: ${Font.fontSize.Size14}px;
  font-weight: ${({ fontWeightBold }) => (fontWeightBold || '400')};
  color: ${({ lightBlueColor }) => (lightBlueColor || `${Colors.Black}`)};
  margin-bottom: 0;
  text-decoration: ${({ textDecoration }) => (textDecoration || 'none')};
`;

export const BuyContactButton = styled(Button)`
  width: 100%;

  @media (min-width: 1024px) {
    width: 36%;
  }

  @media (min-width: 1440px) {
    width: 30%;
  }
`;
