import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxPromise from 'redux-promise';

const initialState = {};

const middleware = [thunk];
export const store = createStore(
  rootReducer,
  initialState,
  //applyMiddleware(ReduxPromise, ...middleware)
  composeWithDevTools(applyMiddleware(ReduxPromise, ...middleware))
);
