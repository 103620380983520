import React from 'react';
import { FormattedMessage } from 'react-intl';
import Config from '../../config';
import { Link, Line } from '../../pages/SignUp/styles';
import Constants from '../../shared/constants';
import { getLocaleFromURL } from '../../shared/utils';

const currentLanguage = getLocaleFromURL() || Config.language;
const Faq = ({ color, footer }) => (
  <Link href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].faq}`} color={color}>
    <Line role="button">
      {footer ? <FormattedMessage id="common_faq" defaultMessage="FAQ" /> : <FormattedMessage id="myaccount_welcome_card_faq" defaultMessage="FAQ" /> }
    </Line>
  </Link>
);

export default Faq;
