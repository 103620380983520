import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import Constants from '../../shared/constants';
import { Card, Span, Div } from '../Popover/Style';
import {
  FeedbackBody, DateSpan, Box, DateBox, StarBox,
} from './styles';
import {
  Section, Content, MainContent, Image, Line, AccordionAnimate,
} from '../../styles/CommonStyles';
import OrderService from '../../services/AdminServices/OrderService';
import GiggersService from '../../services/AdminServices/GiggersService';
import Config from '../../config';
import Fonts from '../../styles/Fonts';
import Colors from '../../styles/Colors';

const Bold = styled.span`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: 14px;
  font-weight: ${({ fontWeight }) => (fontWeight || '900')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #0e3132;
`;
const Border = styled.div`
  border-top: 1px solid #f1f5f7;
`;

const A = styled.a`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
  &:hover {
    color: ${Colors.Black};
  }
`;

/**
 *
 * @function Star
 * @returns star image
 *
 */
function Star() {
  return <Image src={Constants.icons.Star} alt={Constants.icons.Star} width="16px" height="16px" className="pl-1" />;
}

/**
 *
 * @function EmptyStar
 * @returns empty Star with border
 *
 */
function EmptyStar() {
  return (
    <Image
      src={Constants.icons.StarEmpty}
      alt={Constants.icons.StarEmpty}
      width="16px"
      height="16px"
      className="pl-1"
    />
  );
}

/**
 *
 *@function HalfFilledStar
 * Ui of half filled star
 *
 */
function HalfFilledStar() {
  return (
    <Image src={Constants.icons.HalfStar} alt={Constants.icons.HalfStar} width="16px" height="16px" className="pl-1" />
  );
}

class AccordionBody extends Component {
  orderService = new OrderService(Config.apiBase);

  giggersService = new GiggersService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = { show: false };
    this.handleOutsideClickk = this.handleOutsideClickk.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClickk, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClickk, false);
  }

  /**
   *
   * @async
   * @function handleRemoveDocument Removes the attached document to the order
   * @param {number} id Id of the document
   *
   */
  handleRemoveDocument = (id) => async () => {
    const { data, handleLoad } = this.props;
    const docData = {
      docs: {
        value: [id],
      },
    };
    try {
      const response = await this.orderService.removeDocument(data._id, docData);
      await this.giggersService.removeDoc({ id });
      if (response) {
        handleLoad();
      }
    } catch (e) {
      handleLoad();
    }
  };

  /**
   *
   * Closes the options block on clicking outside the box
   * @param {event} e captures all click events
   *
   */
  handleOutsideClickk(e) {
    const { show } = this.state;
    if (this.orderActions && !this.orderActions.contains(e.target) && show) {
      this.handleDisplayOptions();
    }
  }

  /**
   *
   * @function renderFeedbackTabBody Feedback accordion ui
   * @returns feedback accordion
   *
   */
  renderFeedbackTabBody = () => {
    const {
      feedbackData,
      rating,
      createdDate,
      status,
      handleApproveModalOpen,
      handleDeleteModalOpen,
      animate = false,
    } = this.props;
    const { show } = this.state;
    const starRating = Math.round(rating * 2) / 2;
    return (
      <>
        <AccordionAnimate block animate={animate}>
          <Section className="row m-0 mb-2">
            <Section className="col-11 accordion-background p-0">
              <Content className="row m-3">
                <MainContent className="col p-0">
                  <FeedbackBody>{feedbackData}</FeedbackBody>
                </MainContent>
                <Content className="col-auto p-0">
                  <MainContent className="row justify-content-end m-0">
                    {[...Array(5)].map((obj, index) => {
                      if (starRating > index) {
                        if (starRating > index && starRating !== index + 0.5) {
                          return <Star />;
                        }
                        return <HalfFilledStar />;
                      }
                      return <EmptyStar />;
                    })}

                  </MainContent>
                  <MainContent className="row justify-content-end m-0">
                    <DateSpan>{createdDate}</DateSpan>
                  </MainContent>
                </Content>
              </Content>
            </Section>
            <Section className="col-1 p-0 text-align mt-2">
              <Image
                src={Constants.icons.ThreeDots}
                alt="More options"
                width="24px"
                height="24px"
                onClick={this.handleDisplayOptions}
                role="button"
                ref={(orderActions) => {
                  this.orderActions = orderActions;
                }}
              />
              {show && (
              <Card
                onBlur={this.handleDisplayOptions}
                tabIndex="-1"
                id="clickable-component"
                onLoad={this.toFocusOnDiv}
              >
                <Div className={(status === Constants.status.PENDING && !feedbackData && !rating) ? 'disableOption p-0' : 'p-0'}>
                  <Div onClick={this.handleModalOpen} role="button">
                    <Span>{Constants.language.common_edit}</Span>
                  </Div>
                  <Border />
                  <Div
                    onClick={handleApproveModalOpen}
                    role="button"
                    className={status === 'approved' ? 'disableOption' : ''}
                  >
                    <Span>
                      <FormattedMessage id="common_approve" defaultMessage="Approve" />
                    </Span>
                  </Div>
                  <Border />
                  <Div
                    role="button"
                    onClick={handleDeleteModalOpen}
                    className={status === 'rejected' ? 'disableOption' : ''}
                  >
                    <Span>{Constants.language.common_cancel}</Span>
                  </Div>
                </Div>
              </Card>
              )}
            </Section>
          </Section>
        </AccordionAnimate>
        {' '}

      </>
    );
  };

  /**
   *
   * @function renderOrderBody Order accordion ui
   * @returns Order accordion
   *
   */
  renderOrderBody = () => {
    const { show } = this.state;
    const {
      status, data, invoiceOrder, cancelOrder, deleteOrder, animate = false,
    } = this.props;
    return (
      <>
        <AccordionAnimate animate={animate}>
          <Section className="col-12 accordion-background mb-2">
            <Content className="row">
              <Section className="col-11 card-accordion">
                <Content className="row">
                  <Content className="col-2">
                    <MainContent className="row">
                      <Bold fontWeight="500">{moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</Bold>
                    </MainContent>
                  </Content>
                  <Content className="col-4 p-0">
                    <ul className="progress-tracker progress-tracker--vertical m-0">
                      <li className="progress-step is-complete">
                        <div className="progress-marker" />
                        <div className="progress-text">
                          <FormattedMessage id="order_received" defaultMessage="Order received" />
                          :
                          {' '}
                          {Constants.ORDER_TYPE[data.type]}
                        </div>
                      </li>

                      <li
                        className={
                        `progress-step ${status === 'completed' || status === 'invoice' ? 'is-complete' : 'is-active'}`
                      }
                      >
                        <div className="progress-marker" />
                        <div className="progress-text">
                          <FormattedMessage id="order_complete" defaultMessage="Order complete" />
                        </div>
                      </li>

                      <li
                        className={`progress-step ${status === 'invoice' ? 'is-complete' : 'is-active'}`}
                        aria-current="step"
                      >
                        <div className="progress-last " />
                        <div className="progress-text pb-0">{Constants.language.common_invoiced}</div>
                      </li>
                    </ul>
                  </Content>
                  <MainContent className="col-3">
                    <Content className="row">
                      <Bold>
                        <FormattedMessage id="common_document" defaultMessage="Document" />
                      </Bold>
                    </Content>
                    {data?.docs && data?.orderDocs
                      ? data.orderDocs.map((obj) => {
                        if (obj._id) {
                          return (
                            <Content className="row" title={obj.file_name}>
                              <A className="pr-1 text-truncate" href={obj.url} download={obj.file_name}>
                                <Line>{obj.file_name}</Line>
                              </A>
                              <Image
                                src={Constants.icons.Delete}
                                alt={Constants.icons.Delete}
                                role="button"
                                onClick={this.handleRemoveDocument(obj._id)}
                              />
                            </Content>
                          );
                        }
                        return '';
                      })
                      : '-'}
                  </MainContent>
                  <MainContent className="col-3">
                    <Content className="row text-truncate" title={data._id}>
                      <Bold>{Constants.language.common_id}</Bold>
                    </Content>
                    <Content className="row">{data._id}</Content>
                  </MainContent>
                </Content>
              </Section>
              <Section className="col p-0 text-align mt-2">
                <Image
                  src={Constants.icons.ThreeDots}
                  alt={Constants.icons.ThreeDots}
                  width="24px"
                  height="24px"
                  role="button"
                  ref={(orderActions) => {
                    this.orderActions = orderActions;
                  }}
                  onClick={this.handleDisplayOptions}
                />

                {show && (
                <Card
                  onBlur={this.handleDisplayOptions}
                  tabIndex="-1"
                  id="clickable-component"
                  onLoad={this.toFocusOnDiv}
                >
                  <>
                    <Div
                      onClick={this.handleCompleteOrder}
                      role="button"
                      className={status === 'invoice' || status === 'completed' ? 'disableOption' : ''}
                    >
                      <Span>{Constants.language.admin_order_complete_order}</Span>
                    </Div>
                    <Border />
                    <Div
                      onClick={invoiceOrder}
                      role="button"
                      className={status === 'completed' ? '' : 'disableOption'}
                    >
                      <Span>
                        <FormattedMessage id="common_invoice" defaultMessage="Invoice" />
                      </Span>
                    </Div>
                    <Border />
                    <Div
                      role="button"
                      onClick={cancelOrder}
                      className={status === 'cancelled' ? 'disableOption' : ''}
                    >
                      <Span>{Constants.language.common_cancel}</Span>
                    </Div>
                    <Border />
                    <Div
                      role="button"
                      onClick={deleteOrder}
                    >
                      <Span>{Constants.language.common_delete}</Span>
                    </Div>
                  </>
                </Card>
                )}
              </Section>
            </Content>
          </Section>
        </AccordionAnimate>
      </>
    );
  };

  /**
   *
   * @function renderFeedbackBody Feedback of the gigger shown in mygiggers ui
   * @returns feedback
   *
   */
  renderFeedbackBody = () => {
    const { data } = this.props;
    const rating = Math.round(data.rating * 2) / 2;
    const dateDisplay = new Date(data.created_at).toLocaleString('en-US', {
      dateStyle: 'medium',
    });
    return (

      <Section className="col p-3 accordion-background">
        <Box className="row m-0">
          <MainContent className="col-lg-8 p-0 mb-3 mb-lg-0">
            <FeedbackBody>{data.feedback}</FeedbackBody>
            <MainContent>{`${Constants.language.label_by} ${data.user_name?.trim() || '-'}`}</MainContent>
          </MainContent>
          <DateBox className="col-lg-4 text-lg-right p-0 d-flex d-lg-block justify-content-between">
            <StarBox className="justify-content-lg-end m-0 order-2">
              {[...Array(5)].map((obj, index) => {
                if (rating > index) {
                  if (rating > index && rating !== index + 0.5) {
                    return <Star />;
                  }
                  return <HalfFilledStar />;
                }
                return <EmptyStar />;
              })}
            </StarBox>
            <StarBox className="m-0 pt-lg-3 justify-content-lg-end order-1">
              <DateSpan>{dateDisplay}</DateSpan>
            </StarBox>
          </DateBox>
        </Box>
      </Section>

    );
  };

  /**
   *
   * Closes the options block
   *
   */
  handleDisplayOptions = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  /**
   *
   * Opens edit feedback modal
   *
   */
  handleModalOpen = () => {
    const { handleEditModalOpen } = this.props;
    handleEditModalOpen();
  };

  /**
   *
   * Opens complete order modal
   *
   */
  handleCompleteOrder = () => {
    const { completeOrder } = this.props;
    completeOrder();
  };

  toFocusOnDiv= () => {
    document.getElementById('clickable-component').focus();
  }

  render() {
    const { feedback, feedbackTab, order } = this.props;
    return (
      <>
        {feedbackTab && this.renderFeedbackTabBody()}
        {feedback && this.renderFeedbackBody()}
        {order && this.renderOrderBody()}
      </>
    );
  }
}

export default AccordionBody;

AccordionBody.propTypes = {
  order: PropTypes.bool,
  feedbackTab: PropTypes.bool,
  feedback: PropTypes.bool,
};

AccordionBody.defaultProps = {
  order: false,
  feedback: false,
  feedbackTab: false,
};
