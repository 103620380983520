import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
} from '../../../components/Modals/styles';
import Constants from '../../../shared/constants';
import TextBox from '../../../components/Input/TextBox';
import RadioButton from '../../../components/Input/RadioButton';
import FilterService from '../../../services/AdminServices/FilterPageService';
import Config from '../../../config';
import AccountService from '../../../services/AccountService';
import Colors from '../../../styles/Colors';
import Fonts from '../../../styles/Fonts';
import { RadioWrapper } from './styles';
import { Div } from '../../../styles/CommonStyles';
import ModalLoader from '../../../components/Loader/ModalLoader';

class Modal extends Component {
  accountService = new AccountService(Config.apiBase);

  filterService = new FilterService(Config.apiBase);

  constructor(props) {
    super(props);
    const { updateFilter, name } = this.props;
    this.state = {
      name: updateFilter ? name : '',
      type: '',
      isTitleSelected: false,
      isSkillsSelected: false,
      isCategorySelected: false,
      isSystemsSelected: false,
      isDisabled: true,
      categoryOptions: [],
      skillsOptions: [],
      jobTitleOptions: [],
      systemsOptions: [],
      nameError: false,
      loading: false,
    };
  }

  componentDidMount() {
    const { addFilter } = this.props;
    if (addFilter) {
      this.fetchFilterlist();
    }
  }

  fetchFilterlist = async () => {
    let filterList = await this.accountService.getAllFilter([['list', 'true']]);
    filterList = filterList.data;
    this.setState({
      skillsOptions: filterList.skills,
      jobTitleOptions: filterList.jobtitle,
      categoryOptions: filterList.category,
      systemsOptions: filterList.systems,
    });
  };

  handleChange = (event) => {
    const { name } = event.target;
    let { value } = event.target;
    const { updateFilter } = this.props;
    const { isDisabled } = this.state;
    value = value.replace(value.substr(0, 1), value.substr(0, 1).toUpperCase());
    this.setState({
      [name]: value,
      nameError: false,
      isTitleSelected: false,
      isSkillsSelected: false,
      isCategorySelected: false,
      isSystemsSelected: false,
      isDisabled: updateFilter ? value.trim().length === 0 : isDisabled,
    });
  };

  handleSelectedType =(selectedType) => () => {
    let isSkillsSelected; let isTitleSelected; let isCategorySelected; let
      isSystemsSelected = false;
    switch (selectedType) {
      case Constants.filter.SKILLS:
        isSkillsSelected = true;
        break;
      case Constants.filter.JOBTITLE:
        isTitleSelected = true;
        break;
      case Constants.filter.CATEGORY:
        isCategorySelected = true;
        break;
      case Constants.filter.SYSTEMS:
        isSystemsSelected = true;
        break;
      default:
        break;
    }
    this.setState(
      {
        isSkillsSelected,
        isTitleSelected,
        isCategorySelected,
        isSystemsSelected,
        type: selectedType,
      },
      this.validate,
    );
  };

  handleRemoveFilter = async () => {
    this.setState({ loading: true });
    const {
      type, id, handleToast, ModalClose,
    } = this.props;
    const data = { filter: { value: type }, id: { value: id }, type };
    try {
      let response;
      switch (type) {
        case Constants.filter.CATEGORY:
          response = await this.filterService.removeCategory(data);
          break;
        case Constants.filter.SKILLS:
          response = await this.filterService.removeSkill(data);
          break;
        case Constants.filter.SYSTEMS:
          response = await this.filterService.removeSystems(data);
          break;
        case Constants.filter.JOBTITLE:
          response = await this.filterService.removeJobtitle(data);
          break;
        default:
          break;
      }
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        ModalClose();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_filter_removed,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      ModalClose();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  handleApproveFilter = async () => {
    this.setState({ loading: true });
    const {
      type, id, handleToast, ModalClose,
    } = this.props;
    const data = { filter: { value: type }, id: { value: id }, type };
    try {
      let response;
      switch (type) {
        case Constants.filter.CATEGORY:
          response = await this.filterService.approveCategory(data);
          break;
        case Constants.filter.SKILLS:
          response = await this.filterService.approveSkills(data);
          break;
        case Constants.filter.SYSTEMS:
          response = await this.filterService.approveSystems(data);
          break;
        case Constants.filter.JOBTITLE:
          response = await this.filterService.approveJobTitle(data);
          break;
        default:
          break;
      }
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_filter_approved_succesfully,
          delay: Constants.toast.DELAY,
        });
        ModalClose();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
      ModalClose();
    }
  };

  handleCreateFilter = async () => {
    this.setState({ loading: true });
    const { name, type } = this.state;
    const { handleToast } = this.props;
    const data = { name: { value: name }, filter: { value: type }, type };
    try {
      let response;
      switch (type) {
        case Constants.filter.CATEGORY:
          response = await this.filterService.createCategory(data);
          break;
        case Constants.filter.SKILLS:
          response = await this.filterService.createSkills(data);
          break;
        case Constants.filter.SYSTEMS:
          response = await this.filterService.createSystems(data);
          break;
        case Constants.filter.JOBTITLE:
          response = await this.filterService.createJobTitle(data);
          break;
        default:
          break;
      }
      if (response) {
        this.setState(
          {
            name: '',
            type: '',
            isCategorySelected: false,
            isTitleSelected: false,
            isSkillsSelected: false,
            isSystemsSelected: false,
            loading: false,
          },
        );
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_filter_added,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  updateSecondaryTitle = async () => {
    this.setState({ loading: true });
    const { name } = this.state;
    const { handleToast, id } = this.props;
    const data = { name, id };
    try {
      const response = await this.filterService.updateSecondaryJobTitle(data);

      if (response) {
        this.setState(
          {
            name: '',
            type: '',
            isCategorySelected: false,
            isTitleSelected: false,
            isSkillsSelected: false,
            isSystemsSelected: false,
            loading: false,
          },
        );
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_filter_added,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  validate = () => {
    let options;
    const {
      name, type, categoryOptions, jobTitleOptions, skillsOptions, systemsOptions,
    } = this.state;
    if (type === Constants.filter.JOBTITLE) {
      options = jobTitleOptions.filter(
        (column) => column.label.toLowerCase() === name.toLowerCase(),
      );
    }
    if (type === Constants.filter.CATEGORY) {
      options = categoryOptions.filter(
        (column) => column.label.toLowerCase() === name.toLowerCase(),
      );
    }
    if (type === Constants.filter.SKILLS) {
      options = skillsOptions.filter(
        (column) => column.label.toLowerCase() === name.toLowerCase(),
      );
    }
    if (type === Constants.filter.SYSTEMS) {
      options = systemsOptions.filter(
        (column) => column.label.toLowerCase() === name.toLowerCase(),
      );
    }
    if (options && options.length > 0) {
      this.setState({ nameError: true, isDisabled: true });
    } else {
      this.setState({ nameError: false, isDisabled: false });
    }
  };

  renderRemovefilter = () => {
    const { ModalClose } = this.props;
    let { type } = this.props;
    if (type === 'skills') {
      type = 'skill';
    }
    return (
      <>
        <Text>
          <FormattedMessage
            id="remove_filter_confirmation_message"
            defaultMessage="Are you sure you want to delete?"
            values={{
              filter: Constants.translateType[type].toLowerCase(),
            }}
          />
        </Text>
        <Input>
          <Button onClick={this.handleRemoveFilter}>
            <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  renderApproveFilter = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage
            id="approve_filter_confirmation_message"
            defaultMessage="Are you sure you want to approve?"
          />
        </Text>
        <Input>
          <Button onClick={this.handleApproveFilter}>
            <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  renderAddFilter = () => {
    const { ModalClose } = this.props;
    const {
      name, isTitleSelected, isCategorySelected, isSkillsSelected,
      isDisabled, nameError, isSystemsSelected,
    } = this.state;
    return (
      <>
        <Input>
          <TextBox
            type="text"
            name="name"
            label={<FormattedMessage id="common_name" defaultMessage="Name" />}
            value={name}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_name_message" defaultMessage="Enter a name" />}
            existError={nameError}
            existErrorMessage={<FormattedMessage id="exist_error_name_message" defaultMessage="Name already exists" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <RadioWrapper>
            {' '}
            <RadioButton
              key={Constants.keys.filterTitle}
              color={Colors.Grey}
              fontFamily={Fonts.fontFamily.AvenirMedium}
              label={<FormattedMessage id="common_title" defaultMessage="Title" />}
              isSelected={isTitleSelected}
              onClick={this.handleSelectedType(Constants.filter.JOBTITLE)}
            />
          </RadioWrapper>
          <RadioWrapper>
            {' '}
            <RadioButton
              key={Constants.keys.filterSkill}
              color={Colors.Grey}
              fontFamily={Fonts.fontFamily.AvenirMedium}
              label={<FormattedMessage id="common_skills" defaultMessage="Skills" />}
              isSelected={isSkillsSelected}
              onClick={this.handleSelectedType(Constants.filter.SKILLS)}
            />
          </RadioWrapper>
          <RadioWrapper>
            {' '}
            <RadioButton
              key={Constants.keys.filterCategory}
              color={Colors.Grey}
              fontFamily={Fonts.fontFamily.AvenirMedium}
              label={<FormattedMessage id="common_category" defaultMessage="Category" />}
              isSelected={isCategorySelected}
              onClick={this.handleSelectedType(Constants.filter.CATEGORY)}
            />
          </RadioWrapper>
          <RadioWrapper>
            {' '}
            <RadioButton
              key={Constants.keys.filterSystem}
              color={Colors.Grey}
              fontFamily={Fonts.fontFamily.AvenirMedium}
              label={<FormattedMessage id="common_systems" defaultMessage="systems" />}
              isSelected={isSystemsSelected}
              onClick={this.handleSelectedType(Constants.filter.SYSTEMS)}
            />
          </RadioWrapper>
        </Input>
        <Input>
          <Button disabled={isDisabled} onClick={this.handleCreateFilter}>
            <FormattedMessage id="common_add" defaultMessage="Add" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  renderSecondaryTitleFilter = () => {
    const { ModalClose } = this.props;
    const {
      name, isDisabled,
    } = this.state;
    return (
      <>
        <Input>
          <TextBox
            type="text"
            name="name"
            label={<FormattedMessage id="common_secondary_title" defaultMessage="Secondary Title" />}
            value={name}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_secondary_title_message" defaultMessage="Enter a secondary Title" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <Button disabled={isDisabled} onClick={this.updateSecondaryTitle}>
            <FormattedMessage id="common_save" defaultMessage="Secondary Title" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  render() {
    const {
      remove, addFilter, approve, ModalClose, updateFilter,
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="FilterModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <Div className="modal-dialog modal-small" role="document">
            <Div className="modal-content contracts-modal bg-green rounded border-0">
              <Div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      {!(remove || approve || updateFilter) && (
                      <Header>
                        <FormattedMessage id="admin_filter_add_new_filter" defaultMessage="Add new filter" />
                      </Header>
                      )}
                      {updateFilter && (
                      <Header>
                        <FormattedMessage
                          id="admin_filter_add_seecondary_title_filter"
                          defaultMessage="Add Secondary Title for filter"
                        />
                      </Header>
                      )}
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {loading && <ModalLoader />}
                  {(remove && !loading) && this.renderRemovefilter()}
                  {(approve && !loading) && this.renderApproveFilter()}
                  {(addFilter && !loading) && this.renderAddFilter()}
                  {(updateFilter && !loading) && this.renderSecondaryTitleFilter()}
                </Card>
              </Div>
            </Div>
          </Div>
        </Div>
      </>
    );
  }
}

export default Modal;
