import styled from 'styled-components';
import Colors from '../../../styles/Colors';
import Fonts from '../../../styles/Fonts';

export const GiggerListWrapper = styled.div`
  max-width: 1200px;
  background-color: ${Colors.White};
  padding: 12px 16px 1px 16px;
  height: auto;
  overflow-x: ${({ overFlowScroll }) => (overFlowScroll ? 'scroll' : 'unset')};
`;
export const GiggerDetailsWrapper = styled(GiggerListWrapper)`
  width: 1015px;
`;
export const ButtonWrapper = styled.div`
  min-width: ${({ width }) => (width || '95')}px;
  height: 24px;
`;
export const TabContainer = styled.div`
  margin: 25px 0px;
`;
export const IconWrapper = styled.div`
  justify-content: flex-end !important;
  text-align: end;
`;
export const Icon = styled.img`
  margin-left: 16px;
  height: 16px;
  cursor: pointer;
  align-self: center;
`;
export const Line = styled.u`
  color: ${Colors.Black} !important;
`;
export const BasicCircle1 = styled.div`
  background-color: ${({ checked }) => (checked ? Colors.Primary : Colors.White)};
  height: 10px;
  width: 10px;
  border: 1px solid #0e3132;
  border-radius: 5px;
  align-self: center !important;
`;
export const Label = styled.div`
  z-index: 1 !important;
  position: ${({ position }) => (position || 'relative')};
  height: auto;
  color:${Colors.White};
  background-color: ${Colors.Primary};
  padding: 5px 10px;
  width: fit-content;
  margin-top: 8px;
  top: 8px;
  left: ${({ left }) => (left || 'unset')};
  right: ${({ right }) => (right || '14px')};
  font-size: ${Fonts.fontSize.Size16}px;
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: normal;
  align-items: end;
  border-radius: 2px;
`;
export const ProfileLabel = styled.div`
  z-index: 1 !important;
  position: ${({ position }) => (position || 'relative')};
  height: auto;
  justify-content: center;
  display: flex;
  align-content: center;
  color:${Colors.White};
  background-color: ${Colors.Primary};
  padding: 5px 10px;
  align-self: center;
  margin-top: 8px;
  font-size: ${Fonts.fontSize.Size16}px;
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-weight: 900;
  line-height: 28px;
`;
export const CloseIcon = styled.img`
&:hover {
  cursor: pointer;
  color: #1d1d1d;
};
`;
