import React from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import TextBox from '../../components/Input/TextBox';
import BaseComponent from '../../components/BaseComponent';
import { Content, MainContent } from '../../styles/CommonStyles';
import {
  Description, Header, Input, Form, Container, ButtonWrapper,
} from './styles';
import SignUpService from '../../services/SignUpService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import Constants from '../../shared/constants';

class ForgotPassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '', disabled: true, loading: false, isSubmitted: false,
    };
  }

  signUpService = new SignUpService(Config.apiBase);

  /**
   * Handles change in input field
   * @param {event} event
   */
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      this.validateForm,
    );
  };

  /**
   * Redirects to login page
   */
  handleSignIn = () => {
    this.props.history.push({ pathname: Constants.routes.logIn.url });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });
    const data = {
      email: this.state.email,
    };
    try {
      const response = await this.signUpService.forgotPasswordService(data);
      if (response) {
        this.setState({ isSubmitted: true });
      }
    } catch (e) {
      this.setState({ isSubmitted: true });
    }
  };

  /**
   * Validates the form
   */
  validateForm = () => {
    const { email } = this.state;
    const isValid = validator.isEmail(email);
    this.setState({ disabled: !isValid });
    return email.length > 0 && validator.isEmail(email);
  };

  render() {
    const {
      email, disabled, loading, isSubmitted,
    } = this.state;
    return (
      <Container>
        <Content className="row m-0">
          {isSubmitted ? (
            <Form>
              <MainContent>
                <Header>
                  <FormattedMessage id="forgot_password_mail_sent_header" defaultMessage="Mail succesfully sent" />
                </Header>
                <Description reset>
                  <FormattedMessage
                    id="forgot_password_mail_sent_description"
                    defaultMessage="A link with instructions on how to reset your password has been sent to your email if you are
                  registered with us."
                  />
                </Description>
              </MainContent>
              <MainContent>
                <ButtonWrapper onClick={this.handleSignIn}>
                  <FormattedMessage id="common_sign_in" defaultMessage="Sign in" />

                </ButtonWrapper>
              </MainContent>
            </Form>
          ) : (
            <Form>
              <MainContent>
                {loading ? (
                  <Loader height="60px" width="200px" />
                ) : (
                  <Header>
                    <FormattedMessage id="login_reset_password" defaultMessage="Reset password" />
                  </Header>
                )}
                {loading ? (
                  <Loader width="320px" />
                ) : (
                  <Description>
                    <FormattedMessage
                      id="forgot_password_enter_email_description"
                      defaultMessage="Enter your e-mail address to reset your password"
                    />
                  </Description>
                )}
              </MainContent>
              <Input>
                {loading ? (
                  <Loader height="40px" width="346px" marginTop="40px" />
                ) : (
                  <TextBox
                    label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                    onChange={this.handleChange}
                    type="email"
                    name="email"
                    defaultValue={email}
                    aria-describedby="emailHelp"
                    errorMessage={
                      <FormattedMessage id="error_email_msg" defaultMessage="Enter a valid e-mail address" />
                    }
                    rule={{
                      method: validator.isEmail,
                      validWhen: true,
                    }}
                  />
                )}
              </Input>
              <MainContent>
                {loading ? (
                  <Loader height="40px" width="346px" />
                ) : (
                  <ButtonWrapper onClick={this.handleSubmit} disabled={disabled}>
                    <FormattedMessage id="forgot_password_send_recovery_link" defaultMessage="Send recovery link" />
                  </ButtonWrapper>
                )}
              </MainContent>
            </Form>
          )}
        </Content>
      </Container>
    );
  }
}

export default ForgotPassword;
