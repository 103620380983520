import React, { Component } from 'react';

import moment from 'moment';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sv from 'date-fns/locale/sv';
import {
  InputBox, BorderBox, TextDiv, LabelWrapper, TextBoxLabel, MandatoryLabel,
} from '../Input/styles';
import { CalendarWrapper } from './styles';
import Constants from '../../shared/constants';
import { getLocaleFromURL } from '../../shared/utils';

registerLocale('sv', sv);

const Input = (props) => {
  const { props: data = {}, onClick } = props;
  const { label = '', forFilter, mandatory } = data;
  const handleChange = (event) => {
    data.onChange(data.name, event.target.value);
  };

  return (
    <>
      {label && (
      <LabelWrapper>
        <TextBoxLabel forFilter={forFilter} mandatory={mandatory}>
          {label || ''}
        </TextBoxLabel>
        {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
      </LabelWrapper>
      )}
      <BorderBox onClick={onClick} className={`${data.disabled ? 'opacity-7' : data.existError ? 'border-red' : ''} row m-0 mb-3 pb-2 cursor-pointer`}>
        <div className="col p-0">
          <InputBox
            onChange={data.onChange}
            placeholder={data.placeholder}
            value={data.value}
            id={data.id}
            disabled={data.disabled}
            onBlur={handleChange}
            className="pb-1"
            autoComplete="off"
          />
        </div>
        <div className="col-auto p-0 mr-3 text-end d-flex align-items-center">
          <img src={Constants.icons.Calendar} alt="icon" width="20px" height="20px" />
        </div>
      </BorderBox>
      {data.existError && (
        <TextDiv
          className="mb-2"
        >
          <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
          <span>{data.existErrorMessage}</span>
        </TextDiv>
      )}
    </>
  );
};

export default class CustomDatePicker extends Component {
  /**
   * @callback
   * Handles change in input field and informs parent about the change of value
   * @param {event} event
   */
  handleChange=(event) => {
    const { name, onChange } = this.props;
    const date = moment(event, 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (onChange) {
      onChange(name, date);
    }
  }

  render() {
    const { value, disabled } = this.props;
    const date = value ? new Date(value) : '';
    const locale = getLocaleFromURL();
    return (
      <CalendarWrapper>
        <DatePicker
          value={value ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''}
          dateFormat="YYYY-MM-DD"
          showMonthDropdown
          showYearDropdown
          customInput={<Input props={this.props} />}
          selected={date}
          onChange={this.handleChange}
          disabled={disabled}
          locale={locale}
        />
      </CalendarWrapper>
    );
  }
}
