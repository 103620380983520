import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Config from './config';
import { store } from './state/store';

import PrivateRoute from './shared/PrivateRoute';
import Constants from './shared/constants';
import Container from './components/Container';

// pages
import Home from './pages/home/Home';
import Welcome from './pages/Nopage';
import LogIn from './pages/login/Login';
import LogOut from './pages/login/Logout';
import SignUp from './pages/SignUp/SignUp';
import CustomerSignUp from './pages/SignUp/CustomerSignUp';
import GiggerSignUp from './pages/SignUp/GiggerSignUp';
import ForgotPassword from './pages/Password/ForgotPassword';
import ResetPassword from './pages/Password/ResetPassword';
import AuthendicationFailure from './pages/login/AuthendicationFailure';
import BankIDPage from './pages/SignUp/BankIdPage';
import SignUpConfirmationPage from './pages/SignUp/ConfirmationPage';
import GiggerAccount from './pages/gigger/MyAccounts';
import CustomerAccount from './pages/customer/MyAccounts';
import GiggerUserDetails from './pages/gigger/UserDetails';
import CustomerUserDetails from './pages/customer/UserDetails';
import CustomerCompanyDetails from './pages/customer/CompanyDetails';
import GiggerCompanyDetails from './pages/gigger/CompanyDetails';
import GiggerDetails from './pages/gigger/GiggerDetails';
import Orders from './pages/customer/Orders';
import MyGiggs from './pages/gigger/MyGiggs';
import Profile from './pages/profile/Profile';
import MyGiggers from './pages/customer/MyGiggers';
import ManageUser from './pages/customer/ManageUsers';
import HiredGiggerFeedback from './pages/customer/FeedbackForm';
import GiggerList from './pages/gigger/GiggerList';
import DeleteAccount from './pages/customer/DeleteAccount';
import CustomerDetailView from './pages/customer/CustomerDetailView';
import NotFound from './pages/NotFound';

// admin pages
import Users from './pages/admin/users/index';
import AdminOrders from './pages/admin/orders/index';
import Giggers from './pages/admin/giggers/index';
import Company from './pages/admin/company/index';
import Filter from './pages/admin/filter/index';
import Feedback from './pages/admin/feedback/index';
import GiggerDetailView from './pages/admin/giggers/giggerDetailView';
import CompanyDetailView from './pages/admin/company/CompanyDetailView';
import AdminMyAccount from './pages/admin/MyAccount';
import AdminUserDetails from './pages/admin/Userdetails';

// approve account delete
import ApproveAccountDelete from './pages/ApproveAccountDelete';
import ThankYouPage from './pages/customer/ThankYouPage';
import ExternalFeedback from './pages/gigger/FeedbackForm';

const App = () => (
  <Router basename={`${Config.baseName}`}>
    <Provider store={store}>
      <Container>
        <Switch>
          <PrivateRoute exact path={Constants.routes.home.url} component={Home} />
          <PrivateRoute exact path={Constants.routes.noPage.url} component={Welcome} />
          <PrivateRoute exact path={Constants.routes.logIn.url} component={LogIn} />
          <PrivateRoute exact path={Constants.routes.logOut.url} component={LogOut} />
          <PrivateRoute exact path={Constants.routes.signUp.url} component={SignUp} />
          <PrivateRoute
            exact
            path={Constants.routes.authendicationFailure.url}
            component={AuthendicationFailure}
          />
          {/* <PrivateRoute
            exact
            path={Constants.routes.customerSignUp.url}
            component={CustomerSignUp}
          /> */}
          <PrivateRoute
            exact
            path={Constants.routes.customerSignUpWithOutBankId.url}
            component={CustomerSignUp}
          />
          <PrivateRoute exact path={Constants.routes.giggerSignUp.url} component={GiggerSignUp} />
          <PrivateRoute
            exact
            path={Constants.routes.forgotPassword.url}
            component={ForgotPassword}
          />
          <PrivateRoute exact path={Constants.routes.resetPassword.url} component={ResetPassword} />
          <PrivateRoute
            exact
            path={Constants.routes.bankIdAuthendicate.url}
            component={BankIDPage}
          />
          <PrivateRoute
            exact
            path={Constants.routes.signUpconfirmationPage.url}
            component={SignUpConfirmationPage}
          />
          <PrivateRoute
            exact
            path={Constants.routes.giggerMyAccount.url}
            component={GiggerAccount}
          />
          <PrivateRoute
            exact
            path={Constants.routes.customerMyAccount.url}
            component={CustomerAccount}
          />
          <PrivateRoute
            exact
            path={Constants.routes.giggerUserDetails.url}
            component={GiggerUserDetails}
          />
          <PrivateRoute
            exact
            path={Constants.routes.customerUserDetails.url}
            component={CustomerUserDetails}
          />
          <PrivateRoute
            exact
            path={Constants.routes.customerCompanyDetails.url}
            component={CustomerCompanyDetails}
          />
          <PrivateRoute
            exact
            path={Constants.routes.giggerCompanyDetails.url}
            component={GiggerCompanyDetails}
          />
          <PrivateRoute exact path={Constants.routes.giggerDetails.url} component={GiggerDetails} />
          <PrivateRoute exact path={Constants.routes.myprofile.url} component={Profile} />
          <PrivateRoute exact path={Constants.routes.customerOrders.url} component={Orders} />
          <PrivateRoute exact path={Constants.routes.giggerMyGiggs.url} component={MyGiggs} />
          <PrivateRoute exact path={Constants.routes.customerMyGiggers.url} component={MyGiggers} />
          <PrivateRoute exact path={Constants.routes.manageUsers.url} component={ManageUser} />
          <PrivateRoute
            exact
            path={Constants.routes.hiredGiggerFeedback.url}
            component={HiredGiggerFeedback}
          />
          <PrivateRoute exact path={Constants.routes.portal.url} component={Giggers} />
          <PrivateRoute exact path={Constants.routes.giggerList.url} component={GiggerList} />
          <PrivateRoute exact path={Constants.routes.deleteAccount.url} component={DeleteAccount} />
          <PrivateRoute
            exact
            path={Constants.routes.customerDetailView.url}
            component={CustomerDetailView}
          />
          <PrivateRoute exact path={Constants.routes.users.url} component={Users} />
          <PrivateRoute exact path={Constants.routes.orders.url} component={AdminOrders} />
          <PrivateRoute exact path={Constants.routes.giggers.url} component={Giggers} />
          <PrivateRoute exact path={Constants.routes.filter.url} component={Filter} />
          <PrivateRoute exact path={Constants.routes.feedback.url} component={Feedback} />
          <PrivateRoute exact path={Constants.routes.companies.url} component={Company} />
          <PrivateRoute
            exact
            path={Constants.routes.adminGiggerDetailView.url}
            component={GiggerDetailView}
          />
          <PrivateRoute
            exact
            path={Constants.routes.adminCompanyDetailView.url}
            component={CompanyDetailView}
          />
          <PrivateRoute
            exact
            path={Constants.routes.adminMyAccount.url}
            component={AdminMyAccount}
          />
          <PrivateRoute
            exact
            path={Constants.routes.adminUserDetails.url}
            component={AdminUserDetails}
          />
          <PrivateRoute
            exact
            path={Constants.routes.approveAccountDelete.url}
            component={ApproveAccountDelete}
          />
          <PrivateRoute
            exact
            path={Constants.routes.hiredDates.url}
            component={HiredGiggerFeedback}
          />
          <PrivateRoute
            exact
            path={Constants.routes.thankYouForFeedback.url}
            component={ThankYouPage}
          />
          <PrivateRoute
            exact
            path={Constants.routes.thankYouForHiring.url}
            component={ThankYouPage}
          />
          <PrivateRoute
            exact
            path={Constants.routes.CompanyFeedbackMessage.url}
            component={ThankYouPage}
          />
          <PrivateRoute
            exact
            path={Constants.routes.giggerFeedbackForm.url}
            component={ExternalFeedback}
          />
          <PrivateRoute exact path={Constants.routes.notFound.url} component={NotFound} />
          <PrivateRoute component={NotFound} />
        </Switch>
      </Container>
    </Provider>
  </Router>
);
export default App;
