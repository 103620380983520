import React, { Component } from 'react';
import {
  SearchButton, SearchBarWrapper, SearchButtonWrapper, SearchExample,
} from './styles';
import Constants from '../../shared/constants';
import AutoSuggestInput from './AutoSuggestInput';
import AccountService from '../../services/AccountService';
import Config from '../../config';
import { WhiteButton } from '../../pages/customer/styles';

let currentLanguage = Config.language;
const pathName = window.location.pathname;

if (pathName.search('/en/') >= 0) {
  currentLanguage = Constants.english;
  if (!Constants.langugagesSupported.includes(currentLanguage)) {
    currentLanguage = Config.language;
  }
}
const language = currentLanguage
  ? currentLanguage === Constants.english
    ? require('../../translations/en.json')
    : require('../../translations/sv.json')
  : require('../../translations/sv.json');

let timeout;
class SearchBar extends Component {
  accountService = new AccountService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = { selectData: props.searchData || { id: '', label: '' } };
  }

  componentDidMount = async () => {
    // let filterList = await this.accountService.getAllFilter();
    // filterList = filterList.data;

    // this.setState({
    //   actualList: [...filterList.jobtitle, ...filterList.category, ...filterList.skills],
    // });
  }

  shouldComponentUpdate(nextState) {
    const { selectData } = this.state;
    if (nextState.selectData === selectData) {
      return false;
    }
    return true;
  }

  componentDidUpdate=(prevProps) => {
    const { searchData } = this.props;
    if (prevProps.searchData !== searchData) {
      this.handleupdateSearchData(searchData);
    }
  }

  /**
   * Updates the search data
   * @param {*} selectData
   */
  handleupdateSearchData=(selectData) => {
    this.setState({ selectData });
  }

  /**
   * Redirects to search giggers page on selecting one among the suggestions.
   * @param {*} selectData
   */
  onSuggestionSelected = (selectData) => {
    const { wp } = this.props;
    if (wp) {
      window.location = `${Config.wordpressUrl}/gigger-list?s=${selectData.id}&l=${selectData.label}`;
    }
    this.setState({ selectData });
  };

  /**
   * Redirects to search giggers page and applies search functionality
   */
  search = () => {
    const { onSeachData, wp } = this.props;
    const { selectData } = this.state;
    if (wp) {
      window.location = `${Config.wordpressUrl}/gigger-list?s=${selectData.id}&l=${selectData.label}`;
    }
    if (onSeachData) onSeachData(selectData);
  };

  /**
   * Handles change in search value
   * @param {event} e
   * @param {*} selectedSuggestion
   */
  handleOnChange = (e, selectedSuggestion) => {
    if (e === '') {
      this.setState({ selectData: { id: '', label: '' } });
    }
    if (selectedSuggestion === undefined || selectedSuggestion.label !== e) {
      this.setState({ selectData: { id: e, label: e } });
    }
    this.debounceSearch();
  };

  /**
   * Search happens when user waits for few seconds.
   * @param {string} value search value
   */
  debounceSearch = () => {
    const { searchDelay } = Constants;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      const { onSeachData } = this.props;
      const { selectData } = this.state;
      if (onSeachData) onSeachData(selectData);
    }, searchDelay);
  };

  /*
   * Handles search on clicking example search words.
   * @param {string} word search text
   */
  handleSearchFromExamples =(word) => () => {
    const { onSeachData, wp } = this.props;
    const selectData = { id: word, label: word };
    if (wp) {
      window.location = `${Config.wordpressUrl}/gigger-list?s=${selectData.id}&l=${selectData.label}`;
    }
    if (onSeachData) onSeachData(selectData);
  }

  render() {
    const { wp, admin } = this.props;

    const { actualList, selectData } = this.state;

    const adminStyle = admin ? 'mt-4 mb-0 pb-0' : 'mt-2 pb-3';

    const rowClass = `row m-0 ${wp ? 'mt-2 pb-3 search-wp' : adminStyle}`;

    return (
      <>
        <div className={rowClass}>
          <SearchBarWrapper className=" p-0" wp={wp}>
            <AutoSuggestInput
              actualList={actualList}
              onSuggestionSelected={this.onSuggestionSelected}
              onChange={this.handleOnChange}
              selectedData={selectData}
              wp={wp}
              search={this.search}
              placeholder={wp ? `${language.common_search}` : Constants.language.common_search}
            />
          </SearchBarWrapper>
          <SearchButtonWrapper className=" p-0" wp={wp}>
            <SearchButton onClick={this.search} wp={wp}>
              <img src={Constants.icons.Search} alt="search" className="mr-2 mb-1" />
              {wp ? `${language.admin_search_giggers}` : Constants.language.common_search}
            </SearchButton>
          </SearchButtonWrapper>
        </div>
        {wp && (
        <SearchExample>
          {`${language.search_home_page_example_text}`}
          {' '}
          <WhiteButton className="text-white" onClick={this.handleSearchFromExamples(language.search_home_page_example_text_1)}>{`${language.search_home_page_example_text_1}`}</WhiteButton>
          ,
          {' '}
          <WhiteButton className="text-white" onClick={this.handleSearchFromExamples(language.search_home_page_example_text_2)}>{`${language.search_home_page_example_text_2}`}</WhiteButton>
          ,
          {' '}
          <WhiteButton className="text-white" onClick={this.handleSearchFromExamples(language.search_home_page_example_text_3)}>{`${language.search_home_page_example_text_3}`}</WhiteButton>
        </SearchExample>
        )}
      </>
    );
  }
}

export default SearchBar;
