import React from 'react';
import { FormattedMessage } from 'react-intl';
import Config from '../../config';
import { Link, Line } from '../../pages/SignUp/styles';
import Constants from '../../shared/constants';
import { getLocaleFromURL } from '../../shared/utils';

const currentLanguage = getLocaleFromURL() || Config.language;

const PrivacyPolicy = ({ color }) => (
  <Link
    href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].privacypolicy}`}
    color={color}
  >
    <Line>
      <FormattedMessage id="common_privacy_policy" />
    </Line>
  </Link>
);
export default PrivacyPolicy;
