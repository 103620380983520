import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import { isLoggedIn, isGigger, isAdmin } from '../../shared/utils';
import {
  H5,
  BlurImage,
  Content,
  Address,
  Card,
  Section,
  Image,
  Icon,
  MainContent,
  HourlyRate,
  Span,
  UnderLine,
  H4,
  ExperienceTag,
  Header,
  NoOfReviews,
  ALink,
  JobTitle,
  AverageReview,
  ReviewWrapper,
  JuniorLevelTag,
  ExperienceLevelTag,
  MidLevelTag,
  FeedBackContent,
  TextFoundTag,
  SearchFoundLabel,
  TextFound,
} from './styles';

class ListCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: isLoggedIn(),
      isSearchWordFound: false,
      isGigAvailableInDates: false,
      noOfWeeks: '',
    };
  }

  componentDidMount() {
    const { searchData = [], filterData = [] } = this.props;
    const { stateData = [] } = filterData;
    if (searchData && Object.keys(searchData).length > 0) {
      this.isSearchWordFoundInDocs();
    }
    if (stateData && stateData.addDates) {
      if (stateData.addDates !== 0) {
        let noOfWeeks = '';
        noOfWeeks = stateData.addDates === '1'
          ? `${1} ${Constants.language.common_week.toLowerCase()}`
          : `${stateData.addDates} ${Constants.language.common_weeks.toLowerCase()}`;
        this.setState({ noOfWeeks }, this.isGigAvailableInDates);
      }
    }
  }

  isGigAvailableInDates = () => {
    const { data = {}, filterData } = this.props;
    const { stateData = [] } = filterData;
    const { startDate, endDate, addDates } = stateData;
    let addedStartDate = new Date(startDate);
    let lastWeek = addedStartDate.setDate(addedStartDate.getDate() + (addDates * 7));
    addedStartDate = moment(lastWeek).format(Constants.yearMonthDateFormat);
    let addedEndDate = new Date(endDate);
    lastWeek = addedEndDate.setDate(addedEndDate.getDate() - (addDates * 7));
    addedEndDate = moment(lastWeek).format(Constants.yearMonthDateFormat);
    let subStartDate = new Date(startDate);
    lastWeek = subStartDate.setDate(subStartDate.getDate() - (addDates * 7));
    subStartDate = moment(lastWeek).format(Constants.yearMonthDateFormat);
    let subEndDate = new Date(endDate);
    lastWeek = subEndDate.setDate(subEndDate.getDate() + (addDates * 7));
    subEndDate = moment(lastWeek).format(Constants.yearMonthDateFormat);
    const checkAvailableDate = data.availability.filter(
      (o) => (((addedStartDate >= o.from) && (addedEndDate <= o.to))
      || ((subStartDate >= o.from) && (subEndDate <= o.to))),
    );
    const checkAvailable = addDates > 0 && checkAvailableDate.length === 0;
    this.setState({ isGigAvailableInDates: checkAvailable });
  };

  /**
   * Checks searchword is in uploaded documents or not
   */
  isSearchWordFoundInDocs = () => {
    const { searchData, data } = this.props;
    let { isSearchWordFound } = this.state;
    const { docs = [] } = data;
    for (let i = 0; i < docs.length; i += 1) {
      const word = docs[i] && JSON.stringify(docs[i]).toLowerCase();
      const searchWord = searchData.label.trim().toLowerCase().replaceAll(Constants.specialCharacterRegexPattern, ' ');
      if (word && word.length && searchWord) {
        const replacedText = word
          .replaceAll('\\n', '')
          .replaceAll('\\t', ' ')
          .replaceAll(Constants.specialCharacterRegexPattern, ' ');
        if (replacedText.includes(searchWord) > 0) {
          isSearchWordFound = true;
          break;
        }
      }
    }
    this.setState({ isSearchWordFound });
  };

  /**
   * Removes the saved gigger
   * @param {String} id Id of the gigger.
   */
  handleOnRemove = (id) => (e) => {
    e.preventDefault();
    const { onRemove } = this.props;
    onRemove(id);
  };

  render() {
    const {
      data = {}, onClick, remove, redirect, name, filterData, isSearchPage = false,
    } = this.props;
    const {
      loggedIn, isSearchWordFound, isGigAvailableInDates, noOfWeeks,
    } = this.state;
    let { desired_cities: desiredCities, skills } = data;
    const {
      feedbackAvg,
      id,
      year_of_experience: yearsOfExperience,
      hourly_rate: hourlyRate,
      full_name: fullName,
      job_title: jobTitle,
      feedbackCount,
      profile_pic: profilePic,
      expire,
      company,
    } = data;
    if (typeof desiredCities === 'object') desiredCities = desiredCities.join(', ');
    if (typeof skills === 'object') skills = skills.map((skill) => skill.name).join(', ');

    return (
      <>
        <ALink role="button" to={{ pathname: redirect, data: { filterData, isSearchPage } }} textDecorationNone>
          <Card className="row">
            <FeedBackContent align="end" className="col-12 p-0 justify-content-end">
              <div className="row m-0 align-items-center">
                <ReviewWrapper className="col p-0 mr-3 justify-content-start">
                  <Icon src={Constants.icons.Star} alt={Constants.icons.Star} />
                  <AverageReview>{feedbackAvg ? feedbackAvg.toFixed(1) : 0}</AverageReview>
                  {' '}
                  <NoOfReviews>
                    (
                    {feedbackCount}
                    )
                  </NoOfReviews>
                </ReviewWrapper>
              </div>
            </FeedBackContent>
            <Section className="col-auto p-0">
              <Content className="row m-0">
                {profilePic && profilePic.url ? (
                  <>
                    {expire || isAdmin() || isGigger() || !loggedIn ? (
                      <BlurImage
                        className="image-crop-no-image"
                        style={{
                          backgroundImage: `url("${profilePic.url ? profilePic.url : Constants.icons.Avtar}")`,
                          backgroundSize: 'cover !important',
                        }}
                      />
                    ) : (
                      <Image
                        companyUser={company}
                        className="image-crop-no-image"
                        style={{
                          backgroundImage: `url("${profilePic.url}")`,
                          backgroundSize: 'cover !important',
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Image
                    className="image-crop-no-image"
                    companyUser={!expire}
                    style={{ backgroundImage: `url("${Constants.icons.Avtar}")`, backgroundSize: 'cover !important' }}
                  />
                )}
              </Content>
              {remove && (
                <MainContent align="center" className="col p-0 mt-2" onClick={this.handleOnRemove(id)} role="button">
                  <img src={Constants.icons.Trash} width="16px" height="16px" alt="icon" />
                  <Address className="text-capitalize">
                    {' '}
                    <FormattedMessage id="common_remove" defaultMessage="Remove" />
                  </Address>
                </MainContent>
              )}

              {yearsOfExperience !== '' && (
                <ExperienceTag className="row m-0">
                  {yearsOfExperience === Constants.yearsOfExperience.junior.label
                    || (yearsOfExperience?.start === Constants.yearsOfExperience.junior.start
                      && yearsOfExperience?.end === Constants.yearsOfExperience.junior.end) ? (
                        <JuniorLevelTag>
                          <FormattedMessage id="common_junior" defaultMessage="Junior" />
                        </JuniorLevelTag>
                    ) : yearsOfExperience === Constants.yearsOfExperience.experience.label
                    || (yearsOfExperience?.start === Constants.yearsOfExperience.experience.start
                      && yearsOfExperience?.end === Constants.yearsOfExperience.experience.end) ? (
                        <ExperienceLevelTag>
                          <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                        </ExperienceLevelTag>
                      ) : (
                        <MidLevelTag>
                          <FormattedMessage id="common_senior" defaultMessage="Senior" />
                        </MidLevelTag>
                      )}
                </ExperienceTag>
              )}
            </Section>
            <Section className="col pr-0" style={{ paddingLeft: '30px' }}>
              {name && (
                <>
                  <Header className="row m-0">
                    {onClick ? (
                      <H5 onClick={onClick} role="button">
                        {' '}
                        <MainContent className="col p-0">
                          {name ? (
                            <JobTitle>{jobTitle}</JobTitle>
                          ) : (
                            <>
                              {onClick ? (
                                <H5 onClick={onClick} role="button">
                                  {jobTitle}
                                </H5>
                              ) : (
                                <ALink role="button" to={redirect}>
                                  {jobTitle}
                                </ALink>
                              )}
                            </>
                          )}
                        </MainContent>
                        {' '}
                        {fullName}
                      </H5>
                    ) : (
                      <ALink role="button" to={redirect}>
                        {fullName}
                      </ALink>
                    )}
                  </Header>
                </>
              )}
              <Header className="row m-0 justify-content-between">
                <MainContent className="col p-0">
                  {name ? (
                    <JobTitle>{jobTitle}</JobTitle>
                  ) : (
                    <>
                      {onClick ? (
                        <H5 onClick={onClick} role="button">
                          {jobTitle}
                        </H5>
                      ) : (
                        <ALink role="button" to={{ pathname: redirect, data: { filterData, isSearchPage } }}>
                          {jobTitle}
                        </ALink>
                      )}
                    </>
                  )}
                </MainContent>
                <FeedBackContent align="end" className="col-auto p-0 justify-content-end" normal>
                  <div className="row m-0 align-items-center">
                    <ReviewWrapper className="col p-0 mr-3 justify-content-start">
                      <Icon src={Constants.icons.Star} alt={Constants.icons.Star} />
                      <AverageReview>{feedbackAvg ? feedbackAvg.toFixed(1) : 0}</AverageReview>
                      {' '}
                      <NoOfReviews>
                        (
                        {feedbackCount}
                        )
                      </NoOfReviews>
                    </ReviewWrapper>
                  </div>
                </FeedBackContent>
              </Header>
              <Content className="row m-0">
                <MainContent className="col p-0 text-truncate">
                  <Span>
                    {skills
                      && skills
                        .split(', ')
                        .map(
                          (obj, index) => index < 6 && `${obj}${skills.split(',').length === index + 1 ? ' ' : ', '}`,
                        )}
                  </Span>
                </MainContent>
                <MainContent align="end" className="col-auto p-0">
                  <Span>
                    <UnderLine>
                      {skills && skills.split(',').length > 6 ? `+ ${skills.split(',').length - 6} more` : ''}
                    </UnderLine>
                  </Span>
                </MainContent>
              </Content>
              <Content className="row m-0 pb-2">
                <Content address className="col p-0 text-truncate">
                  <Content className=" m-0 p-0 text-truncate">
                    <Icon
                      src={Constants.icons.Pin}
                      alt={Constants.icons.Pin}
                      style={{ paddingRight: '4px', marginBottom: '2px' }}
                    />
                    <Address className="pt-1">
                      {desiredCities
                        && desiredCities
                          .split(',')
                          .map(
                            (obj, index) => index < 4 && ` ${obj}${desiredCities.split(',').length === index + 1 ? ' ' : ','}`,
                          )}
                    </Address>
                  </Content>
                </Content>
                <MainContent align="end" className="col-auto p-0">
                  <Address end className="text-capitalize">
                    <UnderLine>
                      {desiredCities && desiredCities.split(',').length > 4
                        ? `+ ${desiredCities.split(',').length - 4} more`
                        : ''}
                    </UnderLine>
                  </Address>
                </MainContent>
              </Content>
              <Content className="row m-0">
                {yearsOfExperience !== '' && (
                  <ExperienceTag normal className="col-auto p-0">
                    {yearsOfExperience === Constants.yearsOfExperience.junior.label
                      || (yearsOfExperience?.start === Constants.yearsOfExperience.junior.start
                        && yearsOfExperience?.end === Constants.yearsOfExperience.junior.end) ? (
                          <JuniorLevelTag>
                            <FormattedMessage id="common_junior" defaultMessage="Junior" />
                          </JuniorLevelTag>
                      ) : yearsOfExperience === Constants.yearsOfExperience.experience.label
                      || (yearsOfExperience?.start
                        === Constants.yearsOfExperience.experience.start
                        && yearsOfExperience?.end
                        === Constants.yearsOfExperience.experience.end) ? (
                          <ExperienceLevelTag>
                            <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                          </ExperienceLevelTag>
                        ) : (
                          <MidLevelTag>
                            <FormattedMessage id="common_senior" defaultMessage="Senior" />
                          </MidLevelTag>
                        )}
                  </ExperienceTag>
                )}
                {!isGigAvailableInDates && isSearchWordFound && (
                  <SearchFoundLabel className="col-auto">
                    <TextFoundTag normal>
                      <FormattedMessage
                        id="search_word_found"
                        defaultMessage="Search word found in uploaded document"
                      />
                    </TextFoundTag>
                  </SearchFoundLabel>
                )}
                {!isSearchWordFound && isGigAvailableInDates && (
                  <SearchFoundLabel className="col-auto">
                    <TextFoundTag normal>
                      <FormattedMessage id="gigger_availble_for_one_week" defaultMessage="Gigger available till +/-" />
                      {` ${noOfWeeks} `}
                      <FormattedMessage id="from_available_date" defaultMessage="from available date" />
                    </TextFoundTag>
                  </SearchFoundLabel>
                )}
                <HourlyRate align="end" className="col p-0" normal>
                  <H4 textTranform="none">
                    {`${hourlyRate} kr`}
                    /
                    <FormattedMessage id="common_hour" defaultMessage="hour" />
                  </H4>
                </HourlyRate>
              </Content>
            </Section>
            {isSearchWordFound && (
              <TextFound className="col-12 p-0 mb-3">
                <p className="my-2">
                  <FormattedMessage id="search_word_found" defaultMessage="Search word found in uploaded document" />
                </p>
              </TextFound>
            )}
            {isGigAvailableInDates && (
              <TextFoundTag className="col-12 p-2">
                <FormattedMessage id="gigger_availble_for_one_week" defaultMessage="Gigger available till +/-" />
                {` ${noOfWeeks} `}
                <FormattedMessage id="from_available_date" defaultMessage="from available date" />
              </TextFoundTag>
            )}
            {isSearchWordFound && isGigAvailableInDates && (
              <div className="row m-0 mt-3 h-100 ml-4">
                <TextFound normal className="col p-0 mt-0 mr-3">
                  <p className="mt-14">
                    <FormattedMessage id="search_word_found" defaultMessage="Search word found in uploaded document" />
                  </p>
                </TextFound>
                <TextFound normal className="col p-1 mt-0">
                  <span className="p-2">
                    <FormattedMessage id="gigger_availble_for_one_week" defaultMessage="Gigger available till +/-" />
                    {` ${noOfWeeks} `}
                    <FormattedMessage id="from_available_date" defaultMessage="from available date" />
                  </span>
                </TextFound>
              </div>
            )}
          </Card>
        </ALink>
      </>
    );
  }
}

export default ListCard;
