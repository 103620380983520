import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Border,
  TableValue,
  OptionButton,
  TableData,
  Wrapper,
} from '../../users/styles';
import Modal from '../Modal';
import { Line } from '../../company/styles';
import { ButtonWrapper } from '../styles';
import Constants from '../../../../shared/constants';
import Button from '../../../../components/Button/Button';
import { WhiteButton } from '../../../customer/styles';
import { WhiteCard } from '../../../gigger/styles';
import FeedbackAccordion from '../../../../components/Accordion/AccordionBody';
import ExternalFeedbackLabel from '../../ExternalFeedbackLabel';

class ListViewBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openColumn: '',
      feedbackData: [],
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      isApproveModalOpen: false,
    };
  }

  /**
   * Handles accordion data
   * @param {Number} value row index
   * @param {Object} data row data
   */
  handleOptions = (value, data) => () => {
    const { openColumn } = this.state;

    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value), feedbackData: data });
    }
  };

  /**
   * Opens modal
   */
  handleEditModalOpen = () => {
    this.setState({ isEditModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes modal
   */
  handleModalClose = () => {
    this.setState({ isEditModalOpen: false, isApproveModalOpen: false, isDeleteModalOpen: false });
  };

  /**
   * Opens approve modal
   */
  handleApproveModalOpen = () => {
    this.setState({ isApproveModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens delete modal
   */
  handleDeleteModalOpen = () => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Loads data
   */
  handleLoad = () => {
    const { handleLoad } = this.props;
    this.handleModalClose();
    handleLoad();
  };

  render() {
    const {
      data = [],
      gigger,
      customer,
      searchQuery,
    } = this.props;
    const {
      feedbackData = [],
      openColumn,
      isEditModalOpen,
      isDeleteModalOpen,
      isApproveModalOpen,
    } = this.state;

    return (
      <>
        {data.length > 0 ? (
          <>
            {data.map((obj, index) => (
              <>
                <Border />
                <Wrapper className="row m-0">
                  {!customer && (
                  <TableData className="col d-flex pr-2">
                    <TableData className="text-truncate p-0" title={obj.company_name}>
                      {obj.type !== Constants.EXTERNAL ? (
                        <Link
                          to={{
                            pathname: `/admin/portal/company/${obj.company_id}/profile`,
                            query: { id: obj.company_id },
                          }}
                        >
                          <Line>
                            <TableValue>{obj.company_name}</TableValue>
                          </Line>
                        </Link>
                      )
                        : <TableValue>{obj.company_name}</TableValue>}
                    </TableData>
                    <TableData className="p-0">
                      {obj.type === Constants.EXTERNAL && <ExternalFeedbackLabel id={index} />}
                    </TableData>
                  </TableData>
                  )}

                  <TableData className="col text-truncate" title={obj.company_user_name}>
                    <TableValue>
                      {obj.company_user_name ? obj.company_user_name : 'None'}
                    </TableValue>
                  </TableData>

                  {!gigger && (
                  <TableData className="col text-truncate" title={obj.gigger_name}>
                    <Link
                      to={{
                        pathname: `/admin/portal/gigger/${obj.gigger_id}/profile`,
                        query: { id: obj.gigger_id },
                      }}
                    >
                      <Line>
                        <TableValue>{obj.gigger_name}</TableValue>
                      </Line>
                    </Link>
                  </TableData>
                  )}

                  <TableData className="col">
                    <TableValue>{obj.feedback ? <FormattedMessage id="common_yes" defaultMessage="Yes" /> : <FormattedMessage id="common_no" defaultMessage="No" />}</TableValue>
                  </TableData>

                  <TableData className="col">
                    <TableValue>
                      {obj.rating || '-'}
                      {obj.rating ? '/5' : ''}
                    </TableValue>
                  </TableData>

                  <TableData className="col">
                    <TableValue>{moment(obj.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                  </TableData>

                  <TableData className="col">
                    <TableValue>{moment(obj.updated_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                  </TableData>

                  <TableData className="col-auto text-align">
                    <TableValue>
                      <ButtonWrapper>
                        {obj.status === Constants.status.PENDING ? (
                          <Button
                            name={<FormattedMessage id="common_open" defaultMessage="Open" />}
                            type={Constants.status.OPEN}
                          />
                        ) : obj.status === Constants.status.APPROVED ? (
                          <Button
                            name={<FormattedMessage id="common_approved" defaultMessage="Approved" />}
                            type={Constants.status.APPROVE}
                          />
                        ) : (
                          <Button
                            name={<FormattedMessage id="common_cancelled" defaultMessage="Cancelled" />}
                            type={Constants.status.CANCELLED}
                          />
                        )}
                      </ButtonWrapper>
                    </TableValue>
                  </TableData>

                  <TableData className="col-auto">
                    <OptionButton>
                      <TableValue>
                        <WhiteButton onClick={this.handleOptions(index, obj)}>
                          <img
                            src={Constants.icons.ArrowDown}
                            alt="icon"
                            className={openColumn === index ? 'rotate-180 cursor-pointer' : 'cursor-pointer'}
                          />
                        </WhiteButton>
                      </TableValue>
                    </OptionButton>
                  </TableData>
                </Wrapper>

                <FeedbackAccordion
                  animate={openColumn === index}
                  feedbackTab
                  feedbackData={feedbackData.feedback}
                  rating={feedbackData.rating}
                  status={feedbackData.status}
                  createdDate={moment(feedbackData.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}
                  handleEditModalOpen={this.handleEditModalOpen}
                  handleDeleteModalOpen={this.handleDeleteModalOpen}
                  handleApproveModalOpen={this.handleApproveModalOpen}
                />
              </>
            ))}
          </>
        ) : (
          <>
            <Border />
            <WhiteCard className="my-3">
              {searchQuery && searchQuery.length > 0 ? (
                <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
              ) : (
                <FormattedMessage id="admin_feedback_no_feedbacks_found" defaultMessage="No feedbacks found" />
              )}
            </WhiteCard>
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-backdrop="static"
          data-keyboard="false"
          data-target="#FeedbackModal"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isEditModalOpen && (
        <Modal
          editFeedback={isEditModalOpen}
          id={feedbackData._id}
          data={feedbackData}
          ismodalopen={isEditModalOpen}
          ModalClose={this.handleModalClose}
          updateFeedbackList={this.handleLoad}
        />
        )}
        {isApproveModalOpen && (
        <Modal
          id={feedbackData._id}
          approve={isApproveModalOpen}
          ModalClose={this.handleModalClose}
          updateFeedbackList={this.handleLoad}
        />
        )}
        {isDeleteModalOpen && (
        <Modal
          id={feedbackData._id}
          remove={isDeleteModalOpen}
          ModalClose={this.handleModalClose}
          updateFeedbackList={this.handleLoad}
        />
        )}
      </>
    );
  }
}
export default ListViewBody;
