import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';

export const Card = styled.div`
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  position: relative;
  background: ${Colors.White};
`;
export const MainContent = styled.div`
  text-align: ${({ align }) => align || 'start'};
  font-weight: 500;
`;

export const FeedBackContent = styled.div`
  text-align: ${({ align }) => align || 'start'};
  font-weight: 500;
  display:${(props) => (props.normal ? 'flex' : 'none')};
  @media (max-width: 768px) {
    display:${(props) => (props.normal ? 'none' : 'flex')};
  @media (max-width: 570px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
  @media (max-width: 375px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
`;

export const HourlyRate = styled.div`
  text-align: end;
  align-self: center;
  @media (max-width: 768px) {
    text-align: start;
    margin-top: 4px;
  }
`;

export const Span = styled.span`
  opacity: 0.6;
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${Colors.Secondary};
`;
export const Address = styled.span`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-weight: ${({ end }) => (end ? '500' : 'normal')};
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  font-size: ${Font.fontSize.Size14}px;
  text-transform: none;
`;
export const Content = styled.div`
  letter-spacing: ${(props) => (props.address ? '0.56' : 'normal')};
  @media (max-width:768px){
    padding-bottom: 10px;
  }
`;
export const Section = styled.div``;
export const UnderLine = styled.u`
  &:hover {
    color: ${Colors.Black};
  }
`;
export const H5 = styled.h5`
  color: ${Colors.Primary};
  font-family: ${Font.fontFamily.AvenirBold};
  text-decoration: underline !important;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
  text-transform: capitalize;
  &:hover: {
    color: ${Colors.Black};
  }
`;
export const ALink = styled(Link)`
  color: ${Colors.Primary} !important;
  font-family: ${Font.fontFamily.AvenirBold};
  text-decoration: ${({ textDecorationNone }) => (textDecorationNone ? 'none !important' : 'underline !important')};
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
  text-transform: capitalize;
  &:hover: {
    color: ${Colors.Black} !important;
    text-decoration: ${({ textDecorationNone }) => (textDecorationNone ? 'none !important' : 'underline !important')};
  }
`;
export const CardLink = styled(Link)`
  text-decoration: none !important;
  &:hover: {
    color: ${Colors.Black};
    text-decoration: none !important;
  }
`;
export const H4 = styled(H5)`
  font-size: ${Font.fontSize.Size16}px;
  color: ${Colors.Secondary};
  text-transform: ${({ textTranform }) => (textTranform || 'capitalize')};
  text-decoration: none !important;
`;
export const JobTitle = styled(H5)`
  word-break: break-word;
  text-decoration: none !important;
`;
export const Image = styled.div`
  filter: ${({ companyUser }) => (companyUser ? 'none' : 'blur(5px)')};
  -webkit-filter: ${({ companyUser }) => (companyUser ? 'none' : 'blur(5px)')};
  width: 112px;
  height: 112px;
  @media (max-width: 768px) {
    width: 94px;
    height: 94px;
  }
  @media (max-width: 570px) {
    width: 94px;
    height: 94px;
  }
  @media (max-width: 375px) {
    width: 94px;
    height: 94px;
  }
`;
export const BlurImage = styled.div`
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: 112px;
  height: 112px;
  @media (max-width: 768px) {
    width: 94px;
    height: 94px;
  }
  @media (max-width: 570px) {
    width: 94px;
    height: 94px;
  }
  @media (max-width: 375px) {
    width: 94px;
    height: 94px;
  }
`;
export const Icon = styled.img`
  padding-right: 4px;
  margin-bottom: 2px;
  width: 16px;
  height: 16px;
`;
export const JuniorLevelTag = styled.div`
  width: 70px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #647D7E;
  border: 3px solid rgb(100,125,126,0.2);
  padding: 2px 4px;
  text-align: center;
  align-self: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ExperienceLevelTag = styled(JuniorLevelTag)`
  width: 80px;
  color: #ECA477;
  border: 2px solid rgb(236,164,119);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const MidLevelTag = styled(JuniorLevelTag)`
  width: 70px;
  color: #0E3132;
  border: 2px solid rgb(14,49,50);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ColorTag = styled.span`
  border: 2px solid rgb(114, 44, 120, 0.3);
  border-radius: '5px';
  font-size: ${Font.fontSize.Size16}px;
  color: #722c78;
  background-color: ${Colors.White};
  padding: auto;
  width: 94px;
  height: 30px;
  text-align: center !important;
`;
export const NoOfReviews = styled.span`
  color: #83818e;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
`;

export const AverageReview = styled(NoOfReviews)`
  color: ${Colors.Secondary};
`;

export const Header = styled.div`
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding:4px 0px;
  }
  @media (max-width: 570px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;
export const ExperienceTag = styled.div`
  display:${(props) => (props.normal ? 'flex' : 'none')};
  @media (max-width: 768px) {
    display:${(props) => (props.normal ? 'none' : 'flex')};
  @media (max-width: 570px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
  @media (max-width: 375px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
`;
export const Tag = styled.div`
  width: 124px;
  height: 24px;
  z-index: 5 !important;
  background-color: black;
  border: 1px solid black;
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.12px;
  color: #ffffff;
  padding: 8px;
  text-align: center;
  @media (max-width: 570px) {
    left: 0px;
    margin-left: 8px;
  }
`;

export const ReviewWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 378px) {
    margin-right: 0;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
`;

export const TextFoundTag = styled.span`
  width: auto;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${Colors.Primary};
  text-align: center;
  font-size: ${Font.fontSize.Size14}px;
  font-family: ${Font.fontFamily.AvenirRegular};
  background-color: ${Colors.LightOrange};
  align-self:center;
  text-transform: none !important;
  display:${(props) => (props.normal ? 'inline' : 'none')};
  @media (max-width: 1024px) {
    display:${(props) => (props.normal ? 'none' : 'inline')};
  @media (max-width: 570px) {
    display: ${(props) => (props.normal ? 'none' : 'inline')};
  }
  @media (max-width: 375px) {
    display: ${(props) => (props.normal ? 'none' : 'inline')};
  }
`;

export const SearchFoundLabel = styled.div`
  justify-content: center;
  display: flex;
  align-self: center;
  @media (max-width:1024px) {
    display: none;
  }
`;

export const TextFound = styled.div`
  width: 100%;
  padding: 6px 8px;
  border-radius: 2px;
  color: ${Colors.Primary};
  text-align: center;
  justify-content: center;
  margin-top: 4px;
  font-size: ${Font.fontSize.Size14}px;
  font-family: ${Font.fontFamily.AvenirRegular};
  background-color: ${Colors.LightOrange};
  align-self:center;
  text-transform: none !important;
  display:${(props) => (props.normal ? 'inline' : 'none')};
  @media (max-width: 1024px) {
    display:${(props) => (props.normal ? 'none' : 'flex')};
  @media (max-width: 570px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
  @media (max-width: 375px) {
    display: ${(props) => (props.normal ? 'none' : 'flex')};
  }
`;
