import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constants from '../../shared/constants';
import {
  SearchInputButton, SearchInputBox, SearchInputBar, ButtonSearch,
} from './styles';

let timeout;

class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    const { value } = this.props;
    if (nextProps.value === value) {
      return false;
    }
    return true;
  }

  /**
   * Handles search
   */
  searchItem = async () => {
    const { value, onSearch } = this.props;
    await onSearch(value);
  };

  /**
   * Handles on key press event
   * @param {event} e
   */
  _handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.searchItem();
    }
  };

  /**
   * Handles focus of the input field
   * @param {event} event search value
   */
  handleFocus = (event) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  focus = () => {
    this.textInput.current.focus();
  };

  /**
   * Search happens when user waits for few seconds.
   * @param {string} value search value
   */
  debounceSearch = (value) => {
    const { searchDelay } = Constants;
    const { onSearch } = this.props;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (onSearch) {
        onSearch(value);
      }
    }, searchDelay);
  }

  /**
   * Handles change in search value
   * @param {event} e search value
   * @listens Event
   */
  handleOnChange = (e) => {
    const { value = '' } = e.target;
    const { onChange } = this.props;
    if (onChange) {
      onChange(e);
    }
    this.debounceSearch(value);
  };

  render() {
    const { value } = this.props;
    return (
      <SearchInputBar>
        <SearchInputButton className="pl-1">
          <ButtonSearch className="btn bg-white p-0 d-flex align-self-center">
            <img src={Constants.icons.SearchIcon} width="16" height="16" alt="search-icon" className="mr-0" />
          </ButtonSearch>
        </SearchInputButton>
        <SearchInputBox
          id="searchField"
          aria-describedby="search"
          placeholder={Constants.language.common_search}
          onChange={this.handleOnChange}
          value={value}
          ref={this.textInput}
          onFocus={this.handleFocus}
          onKeyPress={this._handleKeyPress}
        />
      </SearchInputBar>
    );
  }
}

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchInput;
