import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Tabs from '../../../components/Tabs';
import HiredGiggersTab from './Tabs/HiredGiggersTab';
import FeedbackTab from './Tabs/FeedbackTab';
import OrdersTab from './Tabs/OrdersTab';
import UsersTab from './Tabs/UsersTab';
import AccountDetails from './Tabs/AccountDetails';
import { TabContainer } from '../giggers/styles';
import { PageName, CurrentPage } from '../../gigger/styles';
import { FlexContainer, Container, Wrapper } from '../users/styles';
import Constants from '../../../shared/constants';
import Modal from './Modal';
import AuthService from '../../../services/AuthService';
import CompanyService from '../../../services/AdminServices/CompanyService';
import Config from '../../../config';
import ChangeLanguageModal from '../../../components/Modals/index';
import { MainContext } from '../../../components/Toast/Toast';

class CompanyDetailView extends Component {
  authService = new AuthService(Config.apiBase);

  companyService = new CompanyService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        Constants.language.common_account_details,
        Constants.language.common_users,
        Constants.language.common_orders,
        Constants.language.company_my_giggers_hired_giggers,
        Constants.language.common_feedback,
      ],
      tab: Constants.language.common_account_details,
      loading: false,
      isApproveModalOpen: false,
      isDeleteModalOpen: false,
      isAssignModalOpen: false,
      isLangModalOpen: false,
      isAddUserModalOpen: false,
      isLockModalOpen: false,
      loggedIn: this.authService.isAuthenticated(),
      data: [],
      toastData: {},
      showToast: false,
    };
  }

  componentDidMount() {
    this.fetchAccountDetails();
  }

  /**
   *
   * Moves to selected tab.
   * @param {string} selectedTab Selcted tab name
   *
   */
  currentTab = (selectedTab) => {
    this.setState({ tab: selectedTab });
  };

  /**
   *
   * Opens assign owner modal
   *
   */
  handleAssignOwner = () => {
    this.setState({ isAssignModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * Opens approve profile modal
   *
   */
  handleApproveProfile = () => {
    this.setState({ isApproveModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * Opens delete account
   *
   */
  handleDeleteAccount = () => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  handleLock = () => {
    this.setState({ isLockModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * Redirects to companies list screen
   *
   */
  handleCompanyPage = () => {
    const { history } = this.props;
    history.push(Constants.routes.companies.url);
  };

  /**
   *
   * Redirects to my account page
   *
   */
  handleLogintoapp = () => {
    const { history } = this.props;
    history.push(Constants.routes.adminMyAccount.url);
  };

  /**
   *
   * Logout the account
   *
   */
  handleLogout = () => {
    const { history } = this.props;
    localStorage.clear();
    history.push(Constants.routes.logIn.url);
  };

  /**
   *
   * Loads data
   *
   */
  handleLoaddata = () => {
    this.handleModalClose();
    this.fetchAccountDetails();
  };

  /**
   *
   * Opens change language modal
   *
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   *
   * Closes modal
   *
   */
  handleModalClose = () => {
    this.setState({
      isLangModalOpen: false,
      isAssignModalOpen: false,
      isApproveModalOpen: false,
      isDeleteModalOpen: false,
      isAddUserModalOpen: false,
      isLockModalOpen: false,
    });
  };

  /**
   *
   * Opens add user modal
   *
   */
  handleAddUser = () => {
    this.setState({ isAddUserModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Handles toaster display,closing,loading data logic.
   * @param {Object} data toaster data
   */
  handleToast = (data) => {
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchAccountDetails();
    }
    this.setState({ showToast: true, toastData: data });
  };

  handleToastClose = () => {
    this.setState({ showToast: false });
  }

  /**
   *
   * @async
   * @function fetchAccountDetails Fetches company account details
   *
   */
  async fetchAccountDetails() {
    const { match } = this.props;
    const { params } = match;
    this.setState({ loading: true });
    try {
      const response = await this.companyService.getAccountDetails(params.companyname);
      this.setState({
        loading: false,
        data: response.data,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      data,
      tabs,
      loggedIn,
      tab,
      loading,
      isApproveModalOpen,
      isDeleteModalOpen,
      isAssignModalOpen,
      isLangModalOpen,
      isAddUserModalOpen,
      isLockModalOpen,
      toastData,
      showToast,
    } = this.state;
    const { match } = this.props;
    const { companyname: companyID = '' } = match.params;
    const { company_name: companyName = '', organization_number: orgNumber = '', accountOwner = {} } = data;
    const { name: accountOwnerName = '' } = accountOwner;

    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <Header
          page={Constants.CompanyProfile}
          data={data}
          loading={loading}
          handleApproveProfile={this.handleApproveProfile}
          handleModalClose={this.handleModalClose}
          handleLogintoapp={this.handleLogintoapp}
          handleLogout={this.handleLogout}
          handleDeleteAccount={this.handleDeleteAccount}
          handleAssignOwner={this.handleAssignOwner}
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
          handleAddUser={this.handleAddUser}
          handleLock={this.handleLock}
        />
        <FlexContainer>
          <Container>
            <Wrapper>
              <PageName className="opacity-7 pl-1" onClick={this.handleCompanyPage} role="button">
                {`${Constants.language.common_company} > `}
              </PageName>
              <CurrentPage>{companyName}</CurrentPage>
            </Wrapper>
            <TabContainer>
              <Tabs tabs={tabs} currentTab={this.currentTab} selectedTab={tab} />
            </TabContainer>
            {tab === Constants.language.common_account_details && (
              <AccountDetails companyDetails={data} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_users && (
              <UsersTab companyId={companyID} orgNum={orgNumber} load={loading} />
            )}
            {tab === Constants.language.common_orders && <OrdersTab companyId={companyID} />}
            {tab === Constants.language.company_my_giggers_hired_giggers && (
              <HiredGiggersTab companyId={companyID} />)}
            {tab === Constants.language.common_feedback && <FeedbackTab companyId={companyID} />}
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#CompanyModal"
              ref={(openModal) => {
                this.openElement = openModal;
              }}
            />
            {isDeleteModalOpen && (
              <Modal
                id={companyID}
                handleLoaddata={this.handleLoaddata}
                remove={isDeleteModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {isApproveModalOpen && (
              <Modal
                approveProfile={isApproveModalOpen}
                handleLoaddata={this.handleLoaddata}
                id={companyID}
                ModalClose={this.handleModalClose}
              />
            )}
            {isAssignModalOpen && (
              <Modal
                assignOwner={isAssignModalOpen}
                currentAccountOwner={accountOwnerName}
                handleLoaddata={this.handleLoaddata}
                id={companyID}
                ModalClose={this.handleModalClose}
              />
            )}
            {isAddUserModalOpen && (
              <Modal
                addUser={isAddUserModalOpen}
                orgNum={data.organization_number}
                ModalClose={this.handleModalClose}
                handleLoad={this.handleLoaddata}
              />
            )}
            {isLockModalOpen && (
              <Modal
                id={companyID}
                limitCount={Number(data && data.limit) || ''}
                isLock={isLockModalOpen}
                ModalClose={this.handleModalClose}
                handleLoaddata={this.handleLoaddata}
              />
            )}
          </Container>
          <a
            href
            data-backdrop="static"
            data-keyboard="false"
            data-toggle="modal"
            data-target="#AdminModal"
            ref={(openLangModal) => {
              this.openLangElement = openLangModal;
            }}
          />
          {isLangModalOpen && (
            <ChangeLanguageModal
              changeLanguage={isLangModalOpen}
              ModalClose={this.handleModalClose}
            />
          )}
        </FlexContainer>
      </MainContext.Provider>
    );
  }
}

export default CompanyDetailView;
