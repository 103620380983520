import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Header from '../../../components/Header/Header';
import Tabs from '../../../components/Tabs';
import TitleTab from './Tabs/TitleTab';
import SkillsTab from './Tabs/SkillsTab';
import SystemsTab from './Tabs/SystemsTab';
import CategoryTab from './Tabs/CategoryTab';
import { TabContainer, A } from './styles';
import Modal from './Modal';
import { FlexContainer, Container } from '../users/styles';
import Constants from '../../../shared/constants';
import AuthService from '../../../services/AuthService';
import Config from '../../../config';
import ChangeLanguageModal from '../../../components/Modals/index';
import { MainContext } from '../../../components/Toast/Toast';

class Filter extends Component {
  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      tabs: [Constants.language.common_title,
        Constants.language.common_skills,
        Constants.language.common_category,
        Constants.language.common_systems],
      tab: Constants.language.common_title,
      isAddFilterModalOpen: false,
      loading: false,
      toastData: {},
      showToast: false,
      isLangModalOpen: false,
      loggedIn: this.authService.isAuthenticated(),
    };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.key !== prevProps.location.key) {
      this.handleLoad();
    }
  }

  /**
   * Changes to selected tab
   * @param {string} selectedTab Tab name
   */
  currentTab = (selectedTab) => {
    this.setState({ tab: selectedTab });
  };

  /**
   * Opens add filter modal
   */
  handleOpenModal = () => {
    this.setState({ isAddFilterModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   * Closes pened modals
   */
  handleModalClose = () => {
    this.setState({ isAddFilterModalOpen: false, isLangModalOpen: false });
  };

  /**
   *  redirects to my account page
   */
  handleLogintoapp = () => {
    const { history } = this.props;
    history.push(Constants.routes.adminMyAccount.url);
  };

  /**
   * Logs out the account
   */
  handleLogout = () => {
    localStorage.clear();
    const { history } = this.props;
    history.push(Constants.routes.logIn.url);
  };

  /**
   * Shows and closes the toast
   * @param {object} data toast data
   */
  handleToast = (data) => {
    let { loading } = this.state;
    if (data.type === Constants.toast.SUCCESS) {
      loading = !loading;
    }
    this.setState({ showToast: true, loading, toastData: data });
  };

  handleToastClose = () => {
    this.setState({ showToast: false });
  }

  handleLoad = () => {
    const { loading } = this.state;
    this.setState({ loading: !loading, isAddFilterModalOpen: false });
  };

  render() {
    const {
      tabs, loggedIn, tab, isAddFilterModalOpen, loading, showToast, toastData, isLangModalOpen,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <Header
          name={<FormattedMessage id="common_filter" defaultMessage="Filter" />}
          filter
          handleOpenModal={this.handleOpenModal}
          handleLogintoapp={this.handleLogintoapp}
          handleLogout={this.handleLogout}
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
        />

        <FlexContainer>
          <Container>
            <TabContainer>
              <Tabs tabs={tabs} currentTab={this.currentTab} selectedTab={tab} />
            </TabContainer>
            {tab === Constants.language.common_title && (
              <TitleTab loading={loading} showToast={showToast} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_skills && (
              <SkillsTab loading={loading} showToast={showToast} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_category && (
              <CategoryTab loading={loading} showToast={showToast} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_systems && (
              <SystemsTab loading={loading} showToast={showToast} handleToast={this.handleToast} />
            )}

            <A
              data-toggle="modal"
              data-target="#FilterModal"
              href="#FilterModal"
              ref={(openModal) => { this.openElement = openModal; }}
              data-backdrop="static"
              data-keyboard="false"
            />
            {isAddFilterModalOpen && (
              <Modal
                addFilter={isAddFilterModalOpen}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
              />
            )}
          </Container>
          <A
            data-backdrop="static"
            data-keyboard="false"
            data-toggle="modal"
            data-target="#AdminModal"
            href="#AdminModal"
            ref={(openLangModal) => { this.openLangElement = openLangModal; }}
          />
          {isLangModalOpen && (
            <ChangeLanguageModal
              changeLanguage={isLangModalOpen}
              ModalClose={this.handleModalClose}
            />
          )}
        </FlexContainer>
      </MainContext.Provider>
    );
  }
}

export default withRouter(Filter);
