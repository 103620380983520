import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';

export const FilterComponent = styled.div`
  margin: 74px 0px 64px 0px;
  width: inherit;
  @media (max-width: 768px) {
    margin-top: 20px;
    width: 100%;
  }
  @media (max-width: 570px) {
    margin-top: 0;
  }
  @media (max-width: 375px) {
    margin-top: 0;
  }
`;
export const FilterButton = styled.button`
  width: ${({ width }) => (width || '100%')};
  background-color: ${(props) => (props.update ? '#0e3132' : props.open ? '#f9e3d6' : props.approve ? `${Colors.Green}` : '#ffffff')};
  border-radius: 2px;
  padding: 8px 35px;
  color: ${({ update, approve }) => ((approve || update) ? `${Colors.White}` : `${Colors.Primary}`)};
  cursor:${({ open, approve }) => ((open || approve) ? 'text !important' : 'pointer')};
  font-family: ${Font.fontFamily.AvenirRegular};
  border: ${(props) => ((props.open || props.approve) ? 'none' : '1px solid #0e3132')};
  &:active,
  &:focus,
  &:hover {
    border: ${({ open, approve }) => ((open || approve) ? 'none' : `1px solid ${Colors.Black}`)};
    outline: none;
    opacity: 0.9;
    background-color: ${(props) => (props.update ? '#000000' : 'none')};
    color: ${({ update, approve }) => ((approve || update) ? `${Colors.White}` : `${Colors.Black}`)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
export const Span = styled.span`
  font-size:${({ font }) => (font || `${Font.fontSize.Size16}`)}px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => (props.cancel ? '#0e3132' : '#ffffff')};
`;

export const Whitebutton = styled.button`
  width: ${({ customWidth }) => (customWidth ? '254.94px' : '100%')};
  border: none;
  padding: 8px 16px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  color: ${Colors.Primary};
  background-color: ${Colors.Transparent};
  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const CrossMark = styled.span`
`;
