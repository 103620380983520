import Constants from '../shared/constants';
import ApiService from './ApiService';

class CustomerService extends ApiService {
  endPoint = {
    userDetails: 'company/getUserDetails',
    updateuserDetails: 'company/updateUserDetails',
    companyDetails: 'company/getCompanyDetails',
    updateCompanyDetails: 'company/updateCompanyDetails',
    changePassword: 'company/changePassword',
    createFeedback: 'feedback/submit-feedback',
    deleteAccount: 'company/delete-account',
    getOrders: 'company/getOrders',
    myGiggers: 'company/my-gigger',
    manageUsers: 'company/listCompanyUsers',
    inviteUser: 'company/addCompanyUsers',
    removeUser: 'company/removeCompanyUsers',
    removeSavedGigger: 'company/remove-saved-gigger',
    fileUpload: 'file/upload',
    getDetails: 'details',
    saveGigger: 'company/save-gigger',
    logOut: 'logout',
    updateCompanyUsers: 'company/updateCompanyUsers',
    sendFeedback: 'feedback/send-feedback',
    getFeedback: 'feedback',
    company: 'company/',
    adminDeleteAccount: 'delete-account',
  };

  async userDetails() {
    const response = await this.get(this.baseURL + this.endPoint.userDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateUserDetails(data) {
    const response = await this.post(this.baseURL + this.endPoint.updateuserDetails, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async companyDetails() {
    const response = await this.get(this.baseURL + this.endPoint.companyDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateCompanyDetails(data) {
    const response = await this.post(this.baseURL + this.endPoint.updateCompanyDetails, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async changePassword(data) {
    const response = await this.post(this.baseURL + this.endPoint.changePassword, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createFeedback(data) {
    const response = await this.post(this.baseURL + this.endPoint.createFeedback, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async deleteAccount(data) {
    const response = await this.post(this.baseURL + this.endPoint.deleteAccount, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getOrders() {
    const response = await this.get(this.baseURL + this.endPoint.getOrders);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async myGiggers() {
    const response = await this.get(this.baseURL + this.endPoint.myGiggers);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeSavedGigger(data) {
    const response = await this.post(this.baseURL + this.endPoint.removeSavedGigger, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async manageUsers() {
    const response = await this.get(this.baseURL + this.endPoint.manageUsers);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async inviteUser(data) {
    const response = await this.post(this.baseURL + this.endPoint.inviteUser, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateUserRole(id, payload) {
    const response = await this.post(`${this.baseURL + this.endPoint.updateCompanyUsers}/${id}`, payload);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeUser(data) {
    const response = await this.post(this.baseURL + this.endPoint.removeUser, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async uploadmediaFile(file, documentType, category = null) {
    const form = new FormData();
    form.append('category', category);
    if (documentType === 'docs') {
      form.append('media', file);
      form.append('type', 'docs');
    } else {
      form.append('media', file);
      form.append('type', 'image');
    }
    const response = await this.postImage(this.baseURL + this.endPoint.fileUpload, form);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getDetails() {
    const response = await this.post(this.baseURL + this.endPoint.getDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async saveGigger(data) {
    const response = await this.post(this.baseURL + this.endPoint.saveGigger, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async logOut() {
    const response = await this.get(this.baseURL + this.endPoint.logOut);
    return response;
  }

  async sendFeedback(data) {
    const response = await this.post(this.baseURL + this.endPoint.sendFeedback, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getFeedbackDetails(id) {
    const response = await this.get(`${this.baseURL}${this.endPoint.company}${id}/${this.endPoint.getFeedback}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async deleteAdminAccount() {
    const response = await this.post(`${this.baseURL}${this.endPoint.adminDeleteAccount}`);
    if (response.status !== Constants.HTTP_STATUS.OK) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}

export default CustomerService;
