import styled from 'styled-components';
import Font from '../../styles/Fonts';

export const FeedbackBody = styled.span`
  font-size: ${Font.fontSize.Size16}px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-weight: ${({ fontWeight }) => (fontWeight ? '600px' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #06041d;
`;
export const DateSpan = styled.span`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-weight: 500;
  color: #0e3132;
`;
export const Box = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 570px) {
    flex-direction: column;
  }
  @media (max-width: 375px) {
    flex-direction: column;
  }
`;
export const DateBox = styled.div`
  @media (max-width: 768px) {
    flex-direction: row;
    min-width: 300px;
  }
  @media (max-width: 570px) {
    flex-direction: row;
    min-width: 200px;
  }
  @media (max-width: 375px) {
    flex-direction: row;
    min-width: 200px;
  }
`;
export const StarBox = styled.div`
  justify-content: flex-end;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 768px) {
    padding-top: 8px;
    justify-content: flex-start;
  }
  @media (max-width: 570px) {
    padding-top: 8px;
    justify-content: flex-start;
  }
  @media (max-width: 375px) {
    padding-top: 8px;
    justify-content: flex-start;
  }
`;
