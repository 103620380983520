import styled from 'styled-components';
import Fonts from '../../styles/Fonts';

export const Container = styled.div`
  background-color: #f9e3d6;
  position: absolute;
  padding: 16px 16px 30px 16px;
  top: 25px;
  line-height: 24px;
  letter-spacing: 0.54px;
  min-width: 361px;
  border-radius: 2px;
  border: none;
  border-color: none;
  outline: none;
  box-shadow: 1px 2px 4px 0 rgba(184, 184, 184, 0.25);
`;
export const Card = styled.div`
  background-color: #ffffff;
  position: absolute;
  line-height: 24px;
  letter-spacing: 0.54px;
  min-width: 141px;
  border-radius: 2px;
  border: none;
  border-color: none;
  outline: none;
  z-index: 5 !important;
  text-align: left !important;
  box-shadow: 1px 2px 4px 0 rgba(172, 169, 169, 0.3);
`;
export const Border = styled.div`
  border-top: 1px solid #f1f5f7;
`;
export const SidemenuBorder = styled.div`
  border-top: 1px solid #0e3132;
  opacity: 0.2;
  margin-right: 16px;
  margin-bottom:16px;
`;
export const Span = styled.span`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: #0e3132;
  margin: 8px;
`;
export const Div = styled.div`
  padding: 8px;
`;

export const Title = styled.div`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
`;
