import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import Constants from '../../shared/constants';
import {
  Nav, List, ListItem, Image, Span,
} from '../../styles/CommonStyles';

class Pagination extends Component {
  /**
   * @function handleClick
   * @callback gotoPage
   * Takes to the selected page
   * @param {number} param0 PageNumber
   * @param {event} e event
   */
  handleClick = ({ pageNumber }, e) => {
    e.preventDefault();
    const { gotoPage } = this.props;
    gotoPage(pageNumber);
  };

  /**
   * @function handleClick
   * @callback gotoPage
   * Takes to the selected page
   * @param {number} param0 PageNumber
   * @param {event} e event
   */
  handlePageClick = ({ pageNumber }, e) => {
    const { cursor, gotoPage } = this.props;
    e.preventDefault();
    if (cursor.pageNumber > 1 || cursor.pageNumber < cursor.pages) {
      gotoPage(pageNumber);
    }
  };

  /**
 * Generates an array with and start and end page
 * @param {number} min starting page
 * @param {number} max ending page
 */
  generateArray = (min, max) => Array(max - min)
    .join()
    .split(',')
    .map((e, i) => min + i);

  render() {
    const { cursor } = this.props;
    return (
      <>
        <Nav>
          <List className="pagination">
            <ListItem className="page-item my-auto">
              <a
                href="#previous"
                aria-label="Previous"
                className={`mr-2 pr-1 ${cursor.pageNumber <= 1 || cursor.pages === 1 ? 'disableOption ' : ''}`}
                onClick={this.handlePageClick.bind(this, {
                  pageNumber: cursor.pageNumber - 1,
                })}
              >
                <Image src={Constants.icons.ArrowLeft} width="16" height="16" alt="icon" />
              </a>
            </ListItem>
            {cursor.pages < 6 ? (
              this.generateArray(0, cursor.pages).map((obj, index) => (
                <a
                  key={`page${{ index }}`}
                  href="#page"
                  role="button"
                  onClick={this.handleClick.bind(this, {
                    pageNumber: obj + 1,
                  })}
                >
                  <Span className={cursor.pageNumber === obj + 1 ? 'active' : 'inactive'}>{obj + 1}</Span>
                </a>
              ))
            ) : (
              <ListItem className="page-item">
                <Span className="page-data page-details border-0 font-weight-normal text-black-1">
                  <Span>
                    {cursor.pageNumber !== cursor.pages && (
                    <a
                      href="#page"
                      role="button"
                      onClick={this.handleClick.bind(this, {
                        pageNumber: cursor.pageNumber,
                      })}
                    >
                      <Span className="active">{cursor.pageNumber}</Span>
                    </a>
                    )}

                    <>
                      {cursor.pageNumber + 1 < cursor.pages && (
                        <a
                          href="#page"
                          role="button"
                          onClick={this.handleClick.bind(this, {
                            pageNumber: cursor.pageNumber + 1,
                          })}
                        >
                          <Span className="inactive">{cursor.pageNumber + 1}</Span>
                        </a>
                      )}
                      {cursor.pageNumber + 2 < cursor.pages && (
                        <a
                          href="#page"
                          role="button"
                          onClick={this.handleClick.bind(this, {
                            pageNumber: cursor.pageNumber + 2,
                          })}
                        >
                          <Span className="inactive">
                            {cursor.pageNumber + 2}
                            {' '}
                          </Span>
                        </a>
                      )}
                      {cursor.pageNumber === cursor.pages && cursor.pages > 3 && (
                        <a
                          href="#page"
                          role="button"
                          onClick={this.handleClick.bind(this, {
                            pageNumber: 1,
                          })}
                        >
                          <Span className="inactive">
                            {1}
                            {' '}
                          </Span>
                        </a>
                      )}
                    </>
                    <Span className="ml-2 mr-2"> ... </Span>
                    <a
                      href="#page"
                      role="button"
                      onClick={this.handleClick.bind(this, {
                        pageNumber: cursor.pages,
                      })}
                    >
                      <Span className={`mr-0 ${cursor.pageNumber === cursor.pages && cursor.pages > 3 ? ' active' : ' inactive'}`}>{cursor.pages}</Span>
                    </a>
                  </Span>
                </Span>
              </ListItem>
            )}
            <ListItem className="page-item my-auto">
              <a
                className={
                  `ml-2 pl-1 ${cursor.pageNumber >= cursor.pages || cursor.pages === 1 ? 'disableOption ' : ''}`
                }
                href="#next"
                aria-label="Next"
                onClick={this.handlePageClick.bind(this, {
                  pageNumber: cursor.pageNumber + 1,
                })}
              >
                <Image src={Constants.icons.ArrowRight} width="16" height="16" alt="icon" />
              </a>
            </ListItem>
          </List>
        </Nav>
      </>
    );
  }
}

Pagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
};

export default Pagination;
