import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Div, Heading, Row,
} from './styles';
import { Button } from '../../components/Modals/styles';

const SuccessModal = ({ onClick }) => (
  <>
    <div
      className="modal fade bd-example-modal-sm fade-profile-modal success-modal"
      id="successModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
      minHeight="200px"
    >
      <div className="modal-dialog modal-small m-auto d-flex" role="document">
        <div className="modal-content bg-green rounded border-0" minHeight="200px">
          <div className="modal-body rounded p-4 m-4 align-items-center">
            <Div>
              <Row>
                <Heading>
                  <FormattedMessage id="label_done" defaultMessage="Done!" />
                </Heading>
              </Row>
              <Row>
                <FormattedMessage id="text_external_feedback_sent" defaultMessage="The request is sent!" />
              </Row>
              <Row className="pt-3">
                <Button width="160" data-dismiss="modal" aria-label="Close" onClick={onClick}>
                  <FormattedMessage id="common_ok" defaultMessage="Cancel" />
                </Button>
              </Row>
            </Div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default SuccessModal;
