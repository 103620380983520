import styled from 'styled-components';
import Colors from '../../../styles/Colors';
import Fonts from '../../../styles/Fonts';

export const CompanyListWrapper = styled.div`
  max-width: 1015px;
  background-color: ${Colors.White};
  padding: 12px 16px 1px 16px;
  height: auto;
`;
export const OrdersListWrapper = styled(CompanyListWrapper)`
  width: 870px;
`;
export const FeedbackListWrapper = styled(CompanyListWrapper)`
  width: 1029px;
`;
export const ButtonWrapper = styled.div`
  min-width: 95px;
  height: 24px;
`;
export const CompanyDetailsWrapper = styled(CompanyListWrapper)`
  width: 665px;
  padding: 24px;
`;
export const Input = styled.div`
  width: 380px;
  margin-top: 24px;
`;
export const Orgnumber = styled.span`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
`;
export const UpdateButtonWrapper = styled.div`
  width: 124px;
`;
export const Line = styled.u`
  color: ${Colors.Black} !important;
`;
export const SelectContainer = styled.div`
  min-width: 129px;
`;
