import React, { Component } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { BankIdLoading } from '../../styles/Images';
import BankIDImage from '../../assets/images/BankIDColorIcon.svg';
import Constants from '../../shared/constants';
import {
  FlexContainer, BankIDPageWrapper, Header, SubTitle,
  ImageWrapper,
} from './styles';
import SignUpService from '../../services/SignUpService';
import Config from '../../config';
import { isGigger, isCustomer } from '../../shared/utils';
import { SectionLeft, SectionRight } from '../login/styles';

const Cancel = styled(SubTitle)`
  margin-top: 48px;
  margin-bottom: 51px;
  text-decoration: underline;
`;
class BankIDPage extends Component {
  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = { orderRef: '', cancel: false, personalNumber: '' };
  }

  componentDidMount() {
    const { history, location = {} } = this.props;
    const { bankidData } = location;
    if (bankidData === undefined || bankidData.orderRef === undefined) {
      history.push({ pathname: Constants.routes.logIn.url });
    } else {
      this.setState({ orderRef: bankidData.orderRef, personalNumber: bankidData.personal_number });
      setTimeout(() => {
        this.handleCollectUser();
      }, 5000);
    }
  }

  /**
   * @async
   * @function handleCollectUser Verifies the bankId
   */
  handleCollectUser = async () => {
    const { history, location = {} } = this.props;
    const { orderRef, personalNumber, cancel } = this.state;
    const { data, redirectTo } = location;
    try {
      if (!cancel) {
        const response = await this.signUpService.collectBankIdData({ orderRef, personalNumber });

        if (response.success.status === 'pending') {
          setTimeout(() => {
            this.handleCollectUser();
          }, 10000);
        } else {
          const { success } = response;

          if (success.status === 'failed') {
            history.push({
              pathname: Constants.routes.authendicationFailure.url,
              data,
              redirectTo,
            });
          }
          if (success.status === 'complete') {
            localStorage.setItem(Constants.storage.token, success.token);
            localStorage.setItem('userData', JSON.stringify(success.user));
            if (isGigger()) {
              history.push({ pathname: Constants.routes.giggerMyAccount.url });
            }
            if (isCustomer()) {
              history.push({ pathname: Constants.routes.customerMyAccount.url });
            }
          }
        }
      }
    } catch (e) {
      // TODO: handle error
    }
  };

  /**
   * @async
   * @function gotoAuthFail Cancels the bankId authentication and
   * redirects to bankId auth failure page
   */
  gotoAuthFail = async () => {
    this.setState({ cancel: true });
    const { orderRef } = this.state;
    const { history, location = {} } = this.props;
    const { data, redirectTo } = location;
    try {
      await this.signUpService.cancelBankIdAuth({ orderRef });
      history.push({
        pathname: Constants.routes.authendicationFailure.url,
        data,
        redirectTo,
      });
    } catch (e) {
      // TODO: handle error
    }
  };

  render() {
    return (
      <>
        <FlexContainer>
          <SectionLeft>
            <BankIDPageWrapper bankIdPage className="mb-4 w-50">
              <Header>
                <FormattedMessage id="bankid_login_with_bankid" defaultMessage="Log in with BankID" />
              </Header>
              <SubTitle>
                <FormattedMessage
                  id="bankid_open_bankid_app_message"
                  defaultMessage="Open the BankId app on you mobile device"
                />
              </SubTitle>
              <div className="loader">
                <FormattedMessage id="common_loading" defaultMessage="Loading..." />
              </div>
              <Cancel role="button" onClick={this.gotoAuthFail}>
                <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
              </Cancel>
              <img src={BankIDImage} alt="icon" width="43px" height="40px" />
            </BankIDPageWrapper>
          </SectionLeft>
          <SectionRight>
            <ImageWrapper
              backgroundImage={BankIdLoading}
              backgroundSize="cover !important"
              backgroundPosition="inherit"
              height="813px"
            />
          </SectionRight>
        </FlexContainer>
      </>
    );
  }
}

export default BankIDPage;
