import React from 'react';
import BuyContactForm from './BuyContactForm';

const Modal = ({
  type, redirectToSignUpPage, open, refresh, amount, userId, giggerId, closeSlider,
}) => {
  /**
   * Closes the modal
   */
  const closeModal = () => {
    closeSlider();
  };
  return (
    <>
      <div
        className="modal fade bd-example-modal-sm fade-buycontact-modal"
        id="FilterModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-large" role="document">
          <div className="modal-content contracts-modal bg-green rounded border-0">
            <div className="modal-body rounded p-0 m-0">
              <>
                <BuyContactForm
                  open={open}
                  closeSlider={closeModal}
                  refresh={refresh}
                  type={type}
                  amount={amount}
                  userId={userId}
                  giggerId={giggerId}
                  mobile
                  redirectToSignUpPage={redirectToSignUpPage}
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
