import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Line, Link } from '../../pages/SignUp/styles';
import Constants from '../../shared/constants';

const Search = ({ color }) => (
  <Link href={Constants.routes.giggerList.url} color={color}>
    <Line role="button">
      <FormattedMessage id="common_search" defaultMessage="search" />
    </Line>
  </Link>
);

export default Search;
