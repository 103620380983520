import React from 'react';
import { FormattedMessage } from 'react-intl';
import Config from '../../config';
import { Link, Line } from '../../pages/SignUp/styles';
import Constants from '../../shared/constants';
import { getLocaleFromURL } from '../../shared/utils';

const currentLanguage = getLocaleFromURL() || Config.language;
const ContactUs = ({ color }) => (
  <Link href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].aboutUs}`} color={color}>
    <Line role="button" className="text-lowercase">
      <FormattedMessage id="common_contact_us" defaultMessage="Contact us" />
    </Line>
  </Link>
);

export default ContactUs;
