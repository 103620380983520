import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../BaseComponent';
import TextBox from '../Input/TextBox';
import Select from '../Input/Select';
import {
  Span,
  Section,
  Content,
  Card,
  Button,
  Explanation,
  SubHeader,
  Head,
  Border,
  CancelButton,
  SelectContainer,
} from './styles';
import { getYearsOptions } from '../../shared/utils';
import Constants from '../../shared/constants';
import { TextDiv } from '../Input/styles';
import ModalLoader from '../Loader/ModalLoader';

class ProfileModal extends BaseComponent {
  constructor(props) {
    super(props);
    const { education } = this.props;
    let formValid = {
      title_isValid: '',
      position_isValid: '',
    };

    if (!education) {
      formValid = {
        ...formValid,
        startDateMonth_isValid: '',
        startDateYear_isValid: '',
        endDateMonth_isValid: '',
        endDateYear_isValid: '',
      };
    }
    this.state = {
      ismodalopen: false,
      education,
      title: '',
      position: '',
      startDateYear: '',
      endDateYear: '',
      isDisabled: true,
      formValid,
      year: getYearsOptions(Constants.startingYear),
      endYearOptions: getYearsOptions(Constants.startingYear, 0, true),
      yearError: '',
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    const { ismodalopen, education, editData } = this.props;
    const lengthofData = Object.keys(editData).length;
    if (lengthofData && education) {
      this.setState({
        title: editData.institution_name,
        position: editData.degree,
        startDateYear: editData.start_date,
        endDateYear: editData.end_date,
      });
    }
    if (lengthofData && !education) {
      this.setState({
        title: editData.title,
        position: editData.company_name,
        startDateYear: editData.start_date,
        endDateYear: editData.end_date,
      });
    }
    this.setState({ ismodalopen, education, loading: false });
  }

  /**
   * handles change in input value
   * @param {event} event
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  };

  /**
   * Handles change in select input element
   * @param {string} name Name of the input element.
   * @param {string} value Value of the input element.
   */
  handleSelectChange = (name, value) => {
    this.setState({ [name]: value }, this.validate);
  };

  /**
   * Validates form
   */
  validate() {
    const {
      title = '', position = '', startDateYear = '', endDateYear = '', formValid,
    } = this.state;
    let error = false;
    let yearError = '';
    if (!title || validator.isEmpty(title)) {
      formValid.title_isValid = false;
    } else {
      formValid.title_isValid = true;
    }
    if (!position || validator.isEmpty(position)) {
      formValid.position_isValid = false;
    } else {
      formValid.position_isValid = true;
    }
    if (validator.isEmpty(startDateYear.toString())) {
      formValid.startDateYear_isValid = false;
    } else {
      formValid.startDateYear_isValid = true;
    }
    if (validator.isEmpty(endDateYear.toString())) {
      formValid.endDateYear_isValid = false;
    } else {
      formValid.endDateYear_isValid = true;
    }

    if (startDateYear !== '' && endDateYear !== '' && startDateYear > endDateYear) {
      formValid.startDateYear_isValid = false;

      yearError = Constants.language.end_year_past_start_year;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({
      isDisabled: error, isSaveDisabled: false, yearError,
    });
    return { error, formValid };
  }

  /**
   * @callback
   * Sends the data filled in the form to its parent.
   */
  handleSubmit = () => {
    this.setState({ loading: true });
    const { collectData, ModalClose } = this.props;
    const {
      title, position, startDateYear, endDateYear, education,
    } = this.state;
    if (education) {
      const data = {
        institution_name: title,
        degree: position,
        start_date: startDateYear,
        end_date: endDateYear,
      };
      collectData(data);
    } else {
      const data = {
        title,
        company_name: position,
        start_date: startDateYear,
        end_date: endDateYear,
      };
      collectData(data);
    }
    this.closeElement.click();
    this.setState({ ismodalopen: false, loading: false });
    ModalClose();
  };

  /**
   * @callback
   * Requests parent element to close the modal
   */
  handleClick = () => {
    this.setState({ ismodalopen: false });
    this.props.ModalClose();
  };

  render() {
    const {
      ismodalopen,
      education,
      year,
      yearError,
      isDisabled,
      title,
      position,
      startDateYear,
      endDateYear,
      loading,
      endYearOptions,
    } = this.state;

    return (
      <>
        {ismodalopen && (
          <div
            className="modal fade fade-profile-modal"
            id="profilePageModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content rounded border-0">
                <div className="modal-body rounded p-0 m-0">
                  <Card className="m-0">
                    {!loading && (
                    <Section className="col">
                      <Section className="row">
                        {' '}
                        <Content className="col-8">
                          <Head strong>
                            {education ? (
                              <FormattedMessage id="profile_modal_add_education" defaultMessage="Add education" />
                            ) : (
                              <FormattedMessage
                                id="profile_modal_add_work_experience"
                                defaultMessage="Add work experience"
                              />
                            )}
                          </Head>
                        </Content>
                        <Content message className="col-4">
                          {' '}
                          <Span>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={this.handleClick}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Span>
                        </Content>
                      </Section>
                    </Section>
                    )}
                    {loading && <ModalLoader />}
                  </Card>
                  {(!loading && (
                  <>
                    <Border />
                    <Card className="pt-0">
                      <>
                        <Section className="row">
                          <Section className="col p-4">
                            <Content>
                              <Explanation>
                                <div className="mb-4">
                                  <SubHeader>
                                    {education ? (
                                      <FormattedMessage id="common_school" defaultMessage="School" />
                                    ) : (
                                      <FormattedMessage id="common_title" defaultMessage="Title" />
                                    )}
                                  </SubHeader>
                                  <TextBox
                                    name="title"
                                    value={title}
                                    onChange={this.handleChange}
                                    errorMessage={
                                    education ? (
                                      <FormattedMessage
                                        id="error_school_name_message"
                                        defaultMessage="Enter name of school"
                                      />
                                    ) : (
                                      <FormattedMessage
                                        id="error_title_message"
                                        defaultMessage="Enter your job title"
                                      />
                                    )
                                  }
                                    rule={{
                                      method: validator.isEmpty,
                                      validWhen: false,
                                    }}
                                  />
                                </div>
                                <div className="mb-4">
                                  <SubHeader>
                                    {education ? (
                                      <FormattedMessage id="common_degree" defaultMessage="Degree" />
                                    ) : (
                                      <FormattedMessage id="common_employer" defaultMessage="Employer" />
                                    )}
                                  </SubHeader>
                                  <TextBox
                                    name="position"
                                    value={position}
                                    onChange={this.handleChange}
                                    errorMessage={
                                    education ? (
                                      <FormattedMessage id="error_degree_message" defaultMessage="Enter your degree" />
                                    ) : (
                                      <FormattedMessage
                                        id="error_employer_name_message"
                                        defaultMessage="Enter name of your employer"
                                      />
                                    )
                                  }
                                    rule={{
                                      method: validator.isEmpty,
                                      validWhen: false,
                                    }}
                                  />
                                </div>
                                <>
                                  <div className="mb-4">
                                    <div className="row m-0">
                                      <SelectContainer className="col-auto p-0 mr-3">
                                        <Select
                                          label={<FormattedMessage id="common_start_date" defaultMessage="Start date" />}
                                          name="startDateYear"
                                          value={startDateYear}
                                          onChange={this.handleSelectChange}
                                          errorMessage={<FormattedMessage id="error_select_year_message" defaultMessage="Select year" />}
                                          options={year}
                                          search={false}
                                          noEmptyError={education}
                                        />
                                      </SelectContainer>
                                    </div>

                                  </div>

                                  <div className="row m-0">
                                    <SelectContainer className="col-auto p-0 mr-3">
                                      <Select
                                        label={<FormattedMessage id="common_end_date" defaultMessage="End date" />}
                                        options={education ? year : endYearOptions}
                                        name="endDateYear"
                                        value={endDateYear}
                                        onChange={this.handleSelectChange}
                                        errorMessage={<FormattedMessage id="error_select_year_message" defaultMessage="Select year" />}
                                        search={false}
                                        noEmptyError={education}
                                      />
                                    </SelectContainer>

                                  </div>
                                  {yearError.length > 0 && (
                                  <TextDiv className="mt-2">
                                    <img
                                      src={Constants.icons.ErrorIcon}
                                      alt="icon"
                                      className="mr-1"
                                      width="12px"
                                      height="12px"
                                    />
                                    <span>{yearError}</span>
                                  </TextDiv>
                                  )}

                                </>
                              </Explanation>
                            </Content>
                          </Section>
                        </Section>
                        <Section className="row m-0 px-2">
                          <Section className="p-0 mt-2">
                            <Button
                              onClick={this.handleSubmit}
                              disabled={isDisabled}
                            >
                              <FormattedMessage id="common_save" defaultMessage="Save" />
                            </Button>
                          </Section>
                          <Section className="p-0 mt-2 pl-3">
                            <CancelButton onClick={this.handleClick} data-dismiss="modal" aria-label="Close" ref={(closeModal) => { this.closeElement = closeModal; }}>
                              <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                            </CancelButton>
                          </Section>
                        </Section>
                      </>
                    </Card>
                  </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
ProfileModal.propTypes = {
  education: PropTypes.bool,
  handleClick: PropTypes.func,
};
export default ProfileModal;
