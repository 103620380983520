import ApiService from './ApiService';
import GiggerProfile from '../models/GiggerProfile';

class ProfilePageService extends ApiService {
  endPoint = {
    getgiggerProfile: 'gigger/getGiggerProfile',
    giggerProfile: 'gigger/create',
    viewProfile: 'gigger/profile',
    updateProfile: 'gigger/update',
    categoryList: 'category/list',
    jobTitleList: 'jobtitle/list',
    skillsList: 'skills/list',
    fileUpload: 'file/upload',
    profilePicUpload: 'file/profilePic',
    sendToReview: 'gigger/sendforreview',
    removeDoc: 'file/remove/docs',
    removeSignUpdoc: 'file/remove',
    resumeParser: 'gigger/resume-parser',
  };

  async getgiggerProfile() {
    const response = await this.get(this.baseURL + this.endPoint.getgiggerProfile);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      const { data: { data = {} } = {} } = response;
      return GiggerProfile.fromJson(data);
    }
  }

  async giggerProfile(data) {
    const response = await this.post(this.baseURL + this.endPoint.giggerProfile, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async viewProfile(data) {
    const response = await this.post(this.baseURL + this.endPoint.viewProfile, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateProfile(data) {
    const response = await this.post(this.baseURL + this.endPoint.updateProfile, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async categoryList() {
    const response = await this.get(this.baseURL + this.endPoint.categoryList);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async jobTitleList() {
    const response = await this.get(this.baseURL + this.endPoint.jobTitleList);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async skillsList() {
    const response = await this.get(this.baseURL + this.endPoint.skillsList);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async uploadmediaFile(file, documentType, category = null, giggerId = 0, options = {}) {
    const form = new FormData();
    form.append('category', category);
    if (documentType === 'docs') {
      form.append('media', file);
      form.append('type', 'docs');
    } else {
      form.append('media', file);
      form.append('type', 'image');
    }
    form.append('category', category);
    form.append('giggerId', giggerId);
    const response = await this.postImage(this.baseURL + this.endPoint.fileUpload, form, options);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async sendToReview() {
    const response = await this.get(this.baseURL + this.endPoint.sendToReview);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async uploadProfilePic(file) {
    const form = new FormData();

    form.append('media', file);
    form.append('type', 'image');
    const response = await this.postImage(this.baseURL + this.endPoint.profilePicUpload, form);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async removeSignUpdoc(fileId) {
    const response = await this.delete(`${this.baseURL}${this.endPoint.removeSignUpdoc}/${fileId}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async removeDoc(fileId) {
    const response = await this.post(this.baseURL + this.endPoint.removeDoc, fileId);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getResumeDetails(data) {
    const response = await this.post(this.baseURL + this.endPoint.resumeParser, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }
}

export default ProfilePageService;
