import React from 'react';
import { Div, Slide } from './styles';
import './Slider.css';

const Slider = ({ open = false, children = {}, id = '' }) => (
  <Div>
    <Slide
      className={`modal slide-in from-right ${open ? 'show  d-block' : ''}`}
      id={(id) || 'test'}
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content slide-in-content">
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </Slide>
    <div className={`modal-backdrop${open ? ' show' : 'd-none'}`} />
  </Div>
);

export default Slider;
