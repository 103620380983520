import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { BankIdLoading } from '../../styles/Images';
import {
  FlexContainer,
  SectionLeft,
  SectionRight,
  LoginWrapper,
  Button,
  TitleFailure,
  SubTitleFailure,
} from './styles';
import Constants from '../../shared/constants';
import { ImageWrapper, Div } from '../SignUp/styles';
import BankIDImage from '../../assets/images/BankIDColorIcon.svg';

class AuthendicationFailure extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Redirects to signup page
   */
  handleBackToSignUp = () => {
    const { history, location = {} } = this.props;
    const { data, redirectTo } = location;
    if (redirectTo === Constants.customer) {
      history.push({ pathname: Constants.routes.customerSignUpWithOutBankId.url, data });
    } else if (redirectTo === Constants.gigger) {
      history.push({ pathname: Constants.routes.giggerSignUp.url, data });
    } else {
      history.push({ pathname: Constants.routes.logIn.url, data });
    }
  };

  render() {
    return (
      <FlexContainer>
        <SectionLeft>
          <FlexContainer bgColor="none" bgImage="none">
            <LoginWrapper align>
              <TitleFailure>
                <FormattedMessage id="authentication_failure_authentication_failed" defaultMessage="Authentication failed" />
              </TitleFailure>
              <SubTitleFailure>
                <FormattedMessage id="authentication_failure_you_have_aborted_the_test" defaultMessage="You have aborted the test." />
                {' '}
              </SubTitleFailure>
              <Button className="h-auto w-auto py-2 px-4" onClick={this.handleBackToSignUp}><FormattedMessage id="authentication_failure_back_to_registration" defaultMessage="Back to registration" /></Button>
              <Div className="pt-5">
                <img src={BankIDImage} alt="icon" width="43px" height="40px" />
              </Div>
            </LoginWrapper>
          </FlexContainer>
        </SectionLeft>
        <SectionRight>
          <ImageWrapper
            backgroundImage={BankIdLoading}
            backgroundSize="cover !important"
            backgroundPosition="inherit"
            height="813px"
          />
        </SectionRight>
      </FlexContainer>
    );
  }
}

export default AuthendicationFailure;
