import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  Container,
  CardWrapper,
  Content,
  CardHeader,
  Header,
  OrderData,
  SendButton,
  SubHeader2,
} from './customer/styles';
import Constants from '../shared/constants';
import TextArea from '../components/Input/TextArea';
import { Instruction, AgreeTerms } from './SignUp/styles';
import CustomCheckBox from '../components/Input/CustomCheckbox';
import Button from '../components/Button/Button';
import GiggerService from '../services/GiggerService';
import Config from '../config';
import CustomerService from '../services/CustomerService';
import AuthService from '../services/AuthService';

import { RelativeContainer } from '../styles/CommonStyles';
import Toast, { MainContext } from '../components/Toast/Toast';
import Loader from '../components/Loader/Loader';
import TermsAndConditions from '../components/RedirectLinks/TermsAndConditions';
import PrivacyPolicy from '../components/RedirectLinks/PrivacyPolicy';

class ApproveDelete extends Component {
  giggerService = new GiggerService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  user = JSON.parse(localStorage.getItem('userData'));

  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      feedback: '',
      redirect: false,
      loading: false,
      toastData: {},
      showToast: false,
    };
  }

  /**
   *
   * @param {event} event Change in input value
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Checks the terms and conditions box
   */
  handleAgreeTermsCheckbox = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected });
  };

  /**
   * @async
   * @function handleSubmitFeedback submitts feedback
   */
  handleSubmitFeedback = async () => {
    const { feedback } = this.state;
    const data = {
      feedback,
    };
    const toastData = {
      type: Constants.toast.SUCCESS,
      delay: Constants.toast.SHORT_DELAY,
    };

    this.setState({ loading: true });

    let response;

    try {
      const pathName = window.location.pathname;
      const userId = pathName.substring(pathName.lastIndexOf('/') + 1);

      response = await this.authService.deleteAccount(userId, data);

      if (response) {
        toastData.message = Constants.language.toast_account_deleted_success;
        this.setState({ showToast: true, toastData });
      }
    } catch (e) {
      toastData.type = Constants.toast.ERROR;
      toastData.message = Constants.language.toast_account_deleted_failed;
      this.setState({
        showToast: true,
        toastData,
        loading: false,
      });
    }
  };

  /**
   * Closes the toast
   */
  handleToastClose = () => {
    this.setState({ redirect: true });
  };

  render() {
    const { location } = this.props;
    const {
      isSelected, feedback, redirect, loading, showToast, toastData,
    } = this.state;

    const pathName = window.location.pathname;
    const userId = pathName.substring(pathName.lastIndexOf('/') + 1);
    const user = JSON.parse(localStorage.getItem('userData'));

    if (!this.authService.isAuthenticated() || (user && userId !== user._id) || redirect) {
      localStorage.clear();
      return (
        <Redirect
          to={{
            pathname: Constants.routes.logIn.url,
            redirectTo: location.pathname,
          }}
        />
      );
    }

    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <FlexContainer>
            <Container>
              <CardWrapper>
                <>
                  <Header>
                    <FormattedMessage
                      id="delete_account_message"
                      defaultMessage="We feel sad that you want to leave us"
                    />
                  </Header>
                  <SubHeader2>
                    <FormattedMessage
                      id="delete_account_feedback_request_message"
                      defaultMessage="You will get a confirmation mail where you also can revert the account removal. Please tell us why you
                left us so we can improve ourself"
                    />
                  </SubHeader2>
                  {loading ? (
                    <Loader height="100px" margin="24px" width="70%" />
                  ) : (
                    <Content className="row m-0 mt-4">
                      <OrderData>
                        <FormattedMessage id="delete_account_feedback_header" defaultMessage="How can we get better" />
                      </OrderData>
                      <TextArea name="feedback" value={feedback} onChange={this.handleChange} />
                    </Content>
                  )}
                  <Instruction className="row m-0 mt-4" onClick={this.handleAgreeTermsCheckbox}>
                    <Content className="col  p-0 mt-1">
                      <CustomCheckBox onClick={this.handleAgreeTermsCheckbox} isSelected={isSelected} label="" />
                    </Content>
                    <AgreeTerms className="col-11 p-0">
                      <>
                        {loading ? (
                          <Loader height="70px" width="70%" />
                        ) : (
                          <Content>
                            <CardHeader>
                              <FormattedMessage
                                id="delete_account_yes_delete"
                                defaultMessage="Yes, delete my account"
                              />
                            </CardHeader>
                            <FormattedMessage
                              id="common_agree_delete_account_terms_and_conditions_message"
                              defaultMessage="Some terms and conditions text if wee need it. Jag godkänner gällande allmänna villkor och att mina personuppgifter behandlas i enlighet med nu gällande och kommande integritetspolicy."
                              values={{
                                termsAndConditionsLink: <TermsAndConditions />,
                                privacyLink: <PrivacyPolicy />,
                              }}
                            />
                          </Content>
                        )}
                      </>
                    </AgreeTerms>
                  </Instruction>
                  <SendButton>
                    <Content className="col p-0 mb-5">
                      <Button
                        disabled={!isSelected}
                        name={<FormattedMessage id="delete_account" defaultMessage="Delete account" />}
                        type="update"
                        onClick={this.handleSubmitFeedback}
                      />
                    </Content>
                  </SendButton>
                </>
              </CardWrapper>
            </Container>
          </FlexContainer>
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default withRouter(ApproveDelete);
