import axios from 'axios';
import PersistentStorage from '../shared/PersistentStorage.ts';

class ApiService {
  baseURL = '';

  headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  userheaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  imageHeaders = {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  };

  persistentStorage = new PersistentStorage();

  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  async get(url, cancelToken = {}) {
    this.setAuthorizationToken();
    const response = await axios.get(url,
      { headers: this.headers, cancelToken: cancelToken.token });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async getImage(url) {
    this.setAuthorizationToken();
    const response = await axios.get(url, { headers: this.headers });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async getUser(url) {
    this.setAuthorizationToken();
    const response = await axios.get(url, { headers: this.userheaders });
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    }
    return response;
  }

  async postImage(url, data, options = {}) {
    this.setAuthorizationToken();
    const res = await axios.post(url, data, { ...options, headers: this.imageHeaders });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async login(url, data) {
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async signup(url, data) {
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async post(url, data) {
    this.setAuthorizationToken();
    const res = await axios.post(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202 && res.status !== 201) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async put(url, data) {
    this.setAuthorizationToken();
    const res = await axios.put(url, data, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async delete(url) {
    this.setAuthorizationToken();
    const res = await axios.delete(url, { headers: this.headers });
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  async filterdelete(url, payload) {
    this.setAuthorizationToken();
    const { headers } = this;
    const config = {
      headers,
      data: payload,
    };
    const res = await axios.delete(url, config);
    if (res.status !== 200 && res.status !== 202) {
      // TODO: Throw more specific errors
      throw Error(res);
    }
    return res;
  }

  setHeader(key, value) {
    this.headers[key] = value;
    this.imageHeaders[key] = value;
  }

  setAuthorizationToken() {
    this.setHeader('Authorization', `Bearer ${this.persistentStorage.getToken()}`);
  }

  generateCancelToken=() => axios.CancelToken.source()

  setCancelToken(token) {
    this.headers.cancelToken = token;
  }

  queryParamString=(data) => {
    let queryParam = data;
    const filterElement = queryParam.find((obj) => obj[0] === 'filter');
    queryParam = queryParam.filter((obj) => obj[0] !== 'filter');

    let filterKeys;
    let filterQueryParams = '';
    if (filterElement && filterElement[1]) {
      filterKeys = Object.keys(filterElement[1]);
      if (filterKeys.length > 0) {
        filterQueryParams = `filter=${
          filterKeys.map((obj) => obj).join(',')
        }&${
          filterKeys
            .map(
              (obj) => `${obj
              }=${
                filterElement[1][obj]
                  .map((ele) => encodeURIComponent(ele))
                  .filter((o) => o !== '')
                  .join(',')}`,
            )
            .filter((o) => o !== '')
            .join('&')}`;
      }
    }

    let otherQueries;
    if (queryParam.length > 0) {
      otherQueries = queryParam
        .map((obj) => {
          if (obj[1] !== undefined && obj[1] !== null) {
            return obj.join('=');
          }
          return undefined;
        })
        .filter((o) => o !== undefined)
        .join('&');
    }
    let params = '?';
    if (filterQueryParams) {
      params = `${params + filterQueryParams}&`;
    }
    return params + otherQueries;
  }
}
export default ApiService;
