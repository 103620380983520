import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Content, Span } from '../../styles/CommonStyles';
import Colors from '../../styles/Colors';

const StyledDiv = styled.div`
    background-color: ${Colors.DarkGrey};
    width: max-content;
`;

const ToolTip = styled(StyledDiv)`
&:after {
  content: "";
  position: absolute;
  margin-left: -3px;
  border-style: 5px solid;
  border-color: transparent transparent ${Colors.DarkGrey} transparent;
  bottom: 100%;
}
`;

const ExternalFeedbackLabel = ({ id }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleOnMouseOver = () => {
    setShowPopover(true);
  };

  const handleOnMouseOut = () => {
    setShowPopover(false);
  };
  return (
    <Content className="d-inline-block position-relative mx-2 align-self-center">
      <StyledDiv
        className="px-1 font-weight-bold"
        role="button"
      >
        <Span
          onFocus={handleOnMouseOver}
          onMouseOver={handleOnMouseOver}
          onMouseOut={handleOnMouseOut}
          onBlur={handleOnMouseOut}
        >
          E
        </Span>
      </StyledDiv>
      {id !== '' && showPopover && (
      <ToolTip className="position-absolute px-2 mt-2">
        <Span className="d-flex">
          <FormattedMessage id="label_external_feedback" defaultMessage="External feedback" />
        </Span>
      </ToolTip>
      )}
    </Content>
  );
};

export default ExternalFeedbackLabel;
