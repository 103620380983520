import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../../components/BaseComponent';
import Constants from '../../shared/constants';
import {
  isLoggedIn, isGigger, isAdmin, isCustomer, isCvVerificationUser,
} from '../../shared/utils';

class Home extends BaseComponent {
  componentDidMount() {}

  render() {
    if (!isLoggedIn()) {
      return <Redirect to="/login" />;
    }
    if (isGigger()) {
      return <Redirect to={Constants.routes.giggerMyAccount.url} />;
    }
    if (isCustomer()) {
      return <Redirect to={Constants.routes.customerMyAccount.url} />;
    }
    if (isAdmin() || isCvVerificationUser()) {
      return <Redirect to={Constants.routes.adminMyAccount.url} />;
    }
    return (
      <>
        <h1><FormattedMessage id="common_welcome" defaultMessage="Welcome" /></h1>
      </>
    );
  }
}

export default Home;
