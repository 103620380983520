/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import messagesEn from './translations/en.json';
import messagesSv from './translations/sv.json';
import * as serviceWorker from './serviceWorker';
import App from './App';
import configureMarkerIO from './utils/configureMarkerIO';

// css
import './styles/main.css';
import './styles/custom.css';
import './assets/fonts/fonts.css';

import Config from './config';
import Constants from './shared/constants';

configureMarkerIO();

const messages = {
  en: messagesEn,
  sv: messagesSv,
};

const currentLanguage = Config.language;
localStorage.setItem(Constants.localStorageKeys.language, currentLanguage);

if (document.getElementById('root')) {
  ReactDOM.render(
    <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
      <App />
    </IntlProvider>,
    document.getElementById('root'),
  );
}

serviceWorker.unregister();
