import React from 'react';
import Loader from '../../../components/Loader/Loader';
import { Border, TableData, Wrapper } from './styles';

const LoadingCard = () => {
  const load = ['1', '2', '3', '4', '5'];
  return (
    <>
      {load.map(() => (
        <>
          <Wrapper className="row m-0">
            <TableData className="col ">
              <Loader />
            </TableData>
            <TableData className="col">
              <Loader />
            </TableData>
            <TableData className="col">
              <Loader />
            </TableData>
          </Wrapper>
          <Border />
        </>
      ))}
    </>
  );
};

export default LoadingCard;
