import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TableHeader, TableData, Wrapper, OptionButton,
} from '../../users/styles';
import { ArrowBack } from '../../../../styles/Icons';
import Constants from '../../../../shared/constants';

const ListViewHeader = ({
  handleSorting, sortedBy, sortAscending, title = false,
}) => (
  <Wrapper className="row m-0">
    <TableData className="col ">
      <TableHeader>
        <FormattedMessage id="common_added" defaultMessage="Added" />
      </TableHeader>
    </TableData>

    {title && (
      <TableData className="col ">
        <TableHeader>
          <FormattedMessage id="common_secondary_title" defaultMessage="Secondary Title" />
        </TableHeader>
      </TableData>
    )}
    <TableData className="col">
      <TableHeader>
        <FormattedMessage id="common_type" defaultMessage="Type" />
      </TableHeader>
    </TableData>

    <TableData className="col">
      <TableHeader>
        <FormattedMessage id="common_added_by" defaultMessage="Added by" />
      </TableHeader>
    </TableData>

    <TableData className="col">
      <TableHeader>
        <FormattedMessage id="common_created" defaultMessage="Created" />
      </TableHeader>
    </TableData>

    <TableData className="col text-align">
      <TableHeader onClick={() => handleSorting(Constants.sort.status)} className="cursor-pointer">
        {' '}
        <FormattedMessage id="common_status" defaultMessage="Status" />
        <ArrowBack
          width="16px"
          height="16px"
          className={sortedBy === Constants.sort.status ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
        />
      </TableHeader>
    </TableData>

    <TableData className="col-auto">
      <OptionButton />
    </TableData>
  </Wrapper>
);
export default ListViewHeader;
