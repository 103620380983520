const fontSize = {
  Size24: 24,
  Size16: 16,
  Size14: 14,
  Size13: 13,
  Size12: 12,
  Size15: 15,
  Size18: 18,
  Size8: 8,
  Size30: 30,
  Size200: 200,
  Size100: 100,
  Size40: 40,
  Size32: 32,
  Size28: 28,
  Size20: 20,
};

const fontFamily = {
  Archivo: 'Archivo',
  AvenirRegular: 'AvenirLTStd-55Roman',
  AvenirMedium: 'AvenirLTStd-65',
  AvenirBold: 'AvenirLTStd-85',
  AvenirBlack: 'AvenirLTStd-95',
  AvenirLight: 'AvenirLTStd-35',
};

export default {
  fontSize,
  fontFamily,
};
