import styled from 'styled-components';
import Colors from '../../../styles/Colors';

export const FilterListWrapper = styled.div`
  max-width: 1015px;
  background-color: ${Colors.White};
  padding: 12px 16px 1px 16px;
  height: auto;
`;
export const ButtonWrapper = styled.div`
  min-width: 95px;
  height: 24px;
  margin: auto;
`;
export const TabContainer = styled.div`
  margin-bottom: 25px;
`;
export const A = styled.a`
  color: red;
`;
export const RadioWrapper = styled.div`
margin-top: 1rem;
`;
