import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';

export const P = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${({ fontSize }) => (fontSize ? `${Font.fontSize.Size14}px` : `${Font.fontSize.Size16}px`)};
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => (props.Black ? `${Colors.Black}` : props.LightGrey ? `${Colors.LightGrey}` : `${Colors.White}`)};
  margin-bottom: 0;
  max-width: ${({ maxWidth }) => (maxWidth && '360px')};
`;
export const Div = styled.div`
  width: ${({ customWidth }) => (customWidth && '600px')};
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Img = styled.img``;
export const Ul = styled.ul``;
export const Li = styled.li`
  border-left: 4px solid #0e3132;
  padding: 12px 0px 12px 38px !important;
  display: block;
`;
