import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { CompanyListWrapper } from '../styles';
import Constants from '../../../../shared/constants';
import LoadingCard from '../../LoadingCard';
import { IconWrapper, Icon } from '../../giggers/styles';
import Button from '../../../../components/Button/Button';
import { ButtonWrapper } from '../../orders/styles';
import { WhiteCard } from '../../../gigger/styles';
import { Card, Span, Div } from '../../../../components/Popover/Style';
import SearchInput from '../../../../components/Input/SearchInput';
import Config from '../../../../config';
import Modal from '../Modal';
import {
  Border, TableHeader, TableValue, OptionButton, TableData, Wrapper,
} from '../../users/styles';
import CompanyService from '../../../../services/AdminServices/CompanyService';
import { WhiteButton } from '../../../customer/styles';
import { getFormattedCSVData } from '../../../../shared/utils';
import Pagination from '../../../../components/Pagination/Pagination';
import CustomerService from '../../../../services/CustomerService';

class UsersTab extends Component {
  companyService = new CompanyService(Config.apiBase);

  customerService= new CustomerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      openColumn: '',
      loading: false,
      userData: [],
      searchbar: true,
      meta: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: 1,
        pageSize: 15,
        pages: 1,
      },
      editUserData: {},
      deleteUserData: {},
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      searchQuery: '',
      csvDownloadData: [],
      csvExportFileds: ['full_name', 'role_name', 'email', 'personal_number', '_id', 'status', 'role_ids'],
    };
    this.filterOptions = [];
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: { pageNumber },
    } = this.state;
    const { load } = this.props;
    if (prevState.meta.pageNumber !== pageNumber || prevProps.load !== load) {
      this.fetchUsers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Ignores clicks on the component itself
   * @param {Event} e
   * @listens Event
   */
  handleOutsideClick(e) {
    const { openColumn } = this.state;
    if (this.filterOptions[openColumn] && !this.filterOptions[openColumn].contains(e.target)) {
      if (this.filterOptions[openColumn]) this.setState({ openColumn: '' });
    }
  }

  /**
   * Handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    const { meta } = this.state;
    this.setState({
      meta: {
        ...meta,
        pageNumber,
      },
    });
  };

  /**
   * Dowloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { companyId } = this.props;
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.companyService.getUsers(companyId, queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.ordersCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Handles search functionality
   * @param {String} value search text
   */
  handlesearchUsers = (value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchUsers(value);
    });
  };

  /**
   * Handles Change in search value
   * @param {Event} event search value
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Displays options
   * @param {Number} value row index
   * @param {Object} data row data
   */
  handleOptions = (value) => () => {
    const { openColumn } = this.state;
    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value) });
    }
  };

  /**
   * Removes user
   */
  handleRemoveUser = async () => {
    this.setState({ loading: true });
    const { deleteUserData } = this.state;
    const data = {
      user_id: deleteUserData.id,
    };
    try {
      const response = await this.customerService.removeUser(data);
      if (response) {
        this.setState(
          {
            loading: false,
          },
          this.handleLoad,
        );
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleDisplayOptions = () => {
    this.setState({ openColumn: '' });
  };

  /**
   * displays search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * Loads data after the toaster gets closed
   */
  handleLoad = () => {
    this.handleModalClose();
    this.fetchUsers();
  };

  /**
   * Opens edit user modal
   * @param {Object} data selected user data
   */
  handleEditUser = (data) => () => {
    this.setState({ isEditModalOpen: true, editUserData: data }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens remove user modal
   * @param {Object} user selected user data
   */
  handleRemoveUserModalOpen = (user) => () => {
    this.setState({ deleteUserData: user, isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes modal
   */
  handleModalClose = () => {
    this.setState({
      isEditModalOpen: false,
      isDeleteModalOpen: false,
    });
  };

  /**
   * @async
   * @function fetchUsers Fetches users
   * @param {String} searchInput search text
   */
  async fetchUsers(searchInput) {
    const {
      meta: { pageNumber, pageSize },
      searchQuery,
    } = this.state;
    const { companyId } = this.props;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    try {
      const response = await this.companyService.getUsers(companyId, queryParams);
      const {
        meta: {
          per_page: perPage, current_page: currentPage, total: count, last_page: pages,
        },
        data = [],
      } = response;
      this.setState({
        userData: data,
        loading: false,
        meta: {
          pageSize: perPage,
          pageNumber: currentPage,
          count,
          pages,
        },
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      userData,
      searchbar,
      openColumn,
      loading,
      editUserData,
      searchQuery,
      csvDownloadData,
      isEditModalOpen,
      isDeleteModalOpen,
      meta,
    } = this.state;
    const { orgNum } = this.props;
    return (
      <>
        <CSVLink
          data={csvDownloadData}
          filename="companyUsers.csv"
          className="d-none"
          ref={(r) => {
            this.ordersCsv = r;
          }}
        >
          click
        </CSVLink>

        <CompanyListWrapper>
          <IconWrapper className="row m-0">
            {searchbar ? (
              <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
            ) : (
              <div className="text-end col-auto p-0">
                <SearchInput
                  onSearch={this.handlesearchUsers}
                  onChange={this.handleSearchOnChange}
                  value={searchQuery}
                />
              </div>
            )}
            <Icon
              src={Constants.icons.ExportIcon}
              alt="icon"
              className={userData && userData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
              onClick={this.handleDownloadCsv}
            />
          </IconWrapper>
          {loading && (
            <CompanyListWrapper>
              <LoadingCard />
            </CompanyListWrapper>
          )}
          {!loading && (
            <>
              <Wrapper className="row m-0">
                <TableData className="col ">
                  <TableHeader>
                    <FormattedMessage id="common_user" defaultMessage="User" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_role" defaultMessage="Role" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_email" defaultMessage="E-mail" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_billing_reference" defaultMessage="Billing reference" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_id" defaultMessage="ID" />
                  </TableHeader>
                </TableData>

                <TableData className="col-auto text-align">
                  <ButtonWrapper>
                    <TableHeader>
                      <FormattedMessage id="commmon_status" defaultMessage="Status" />
                    </TableHeader>
                  </ButtonWrapper>
                </TableData>

                <TableData className="col-auto">
                  <OptionButton />
                </TableData>
              </Wrapper>
              {userData.length > 0 ? (
                <>
                  {userData.map((data, index) => (
                    <>
                      <Border />
                      <Wrapper className="row m-0">
                        <TableData className="col text-truncate" title={data.full_name}>
                          <TableValue>{data.full_name}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data.type}>
                          <TableValue>{Constants[data.role_name]}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data.email}>
                          <TableValue className="text-lowercase">{data.email ? data.email : '-'}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data.billing_reference}>
                          <TableValue>{data.billing_reference ? data.billing_reference : '-'}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data._id}>
                          <TableValue>{data.personal_number ? data.personal_number : '-'}</TableValue>
                        </TableData>

                        <TableData className="col-auto text-align">
                          <TableValue>
                            <ButtonWrapper>
                              {data.invited === Constants.status.PENDING ? (
                                <Button
                                  name={<FormattedMessage id="common_invited" defaultMessage="Invited" />}
                                  type={Constants.status.COMPLETE}
                                />
                              ) : (
                                <>
                                  {data.status === Constants.status.APPROVED ? (
                                    <Button
                                      name={<FormattedMessage id="common_active" defaultMessage="Active" />}
                                      type={Constants.status.APPROVE}
                                    />
                                  ) : (
                                    <Button
                                      name={<FormattedMessage id="common_pending" defaultMessage="Pending" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                </>
                              )}
                            </ButtonWrapper>
                          </TableValue>
                        </TableData>

                        <TableData
                          className="col-auto"
                          ref={(filterOptionRef) => {
                            this.filterOptions[index] = filterOptionRef;
                          }}
                        >
                          <TableValue>
                            <WhiteButton onClick={this.handleOptions(index)}>
                              <img src={Constants.icons.ThreeDots} alt="icon" className="cursor-pointer" />
                            </WhiteButton>
                            {openColumn === index && (
                              <Card onBlur={this.handleDisplayOptions} tabIndex="-1" id="clickable-component">
                                <>
                                  <Div onClick={this.handleEditUser(data)} role="button">
                                    <Span>
                                      <FormattedMessage id="common_edit_user" defaultMessage="Edit user" />
                                    </Span>
                                  </Div>
                                  <Border />
                                  <Div role="button" onClick={this.handleRemoveUserModalOpen(data)}>
                                    <Span>
                                      <FormattedMessage id="common_remove_user" defaultMessage="Remove user" />
                                    </Span>
                                  </Div>
                                </>
                              </Card>
                            )}
                          </TableValue>
                        </TableData>
                      </Wrapper>
                    </>
                  ))}
                  <a
                    href
                    data-backdrop="static"
                    data-keyboard="false"
                    data-toggle="modal"
                    data-target="#CompanyModal"
                    ref={(openModal) => {
                      this.openElement = openModal;
                    }}
                  />
                  {isEditModalOpen && (
                    <Modal
                      EditUser={isEditModalOpen}
                      data={editUserData}
                      ModalClose={this.handleModalClose}
                      handleDelete={this.handleRemoveUser}
                      handleLoad={this.handleLoad}
                    />
                  )}

                  {isDeleteModalOpen && (
                    <Modal
                      deleteUser={isDeleteModalOpen}
                      orgNum={orgNum}
                      handleDelete={this.handleRemoveUser}
                      ModalClose={this.handleModalClose}
                      handleLoad={this.handleLoad}
                    />
                  )}
                </>
              ) : (
                <>
                  <Border />
                  <WhiteCard className="my-3">
                    {searchQuery && searchQuery.length > 0 ? (
                      <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
                    ) : (
                      <FormattedMessage id="common_no_orders_found" defaultMessage="No orders found" />
                    )}
                  </WhiteCard>
                </>
              )}
            </>
          )}
        </CompanyListWrapper>
        {!loading && meta.pages > 1 && (
        <CompanyListWrapper className="bg-transparent">
          <Pagination cursor={meta} gotoPage={this.handleGotoPage} />
        </CompanyListWrapper>
        )}
      </>
    );
  }
}
export default UsersTab;
