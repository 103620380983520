import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container, SectionLeft, ThankYouPageWrapper, Header, SubTitle, Div,
} from './styles';
import Button from '../../components/Button/Button';
import Constants from '../../shared/constants';
import Config from '../../config';
import { isCustomer, isGigger } from '../../shared/utils';

class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.location.hash = 'top';
  };

  /**
   * Redirects to my profile page
   */
  handleMyProfile = () => {
    const { history } = this.props;
    if (isCustomer()) {
      history.push({ pathname: Constants.routes.customerMyAccount.url });
    }

    if (isGigger()) {
      history.push({ pathname: Constants.routes.giggerMyAccount.url });
    }
  };

  /**
   * Redirects to home page
   */
  handleBackToHome = () => {
    window.location = Config.wordpressUrl;
  };

  render() {
    return (
      <Container>
        <SectionLeft>
          <ThankYouPageWrapper>
            <Header>
              <FormattedMessage
                id="sign_up_confirmation_header"
                defaultMessage="Thank you for registering an account"
              />
            </Header>
            <SubTitle>
              <FormattedMessage
                id="sign_up_confirmation_header_description"
                defaultMessage="You will be contacted by the GigAssembly team to verify your information before the account registration can be completed. Until then, please feel free to browse professional giggers within GigAssemblys extensive network."
              />
            </SubTitle>
            <Div className="row mt-3">
              {/* <SearchGiggerButton>
                <Button
                  type="update"
                  name={<FormattedMessage id="sign_up_back_to_home" defaultMessage="My account" />}
                  onClick={this.handleBackToHome}
                />
              </SearchGiggerButton> */}
              <Div className="mb-3">
                <Button
                  type="cancel"
                  name={<FormattedMessage id="common_my_account" defaultMessage="My account" />}
                  onClick={this.handleMyProfile}
                />
              </Div>
            </Div>
          </ThankYouPageWrapper>
        </SectionLeft>
      </Container>
    );
  }
}

export default ConfirmationPage;
