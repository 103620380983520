import ApiService from './ApiService';

class SignUpService extends ApiService {
  endPoint = {
    login: 'login',
    giggerSignUp: 'gigger/register',
    companySignUp: 'company/register',
    forgetPassword: 'forget-password',
    resetPassword: 'reset-password',
    authBankId: 'bankId/auth',
    collectBankIdData: 'bankId/collectData',
    cancelBankIdAuth: 'bankId/cancelAuth',
    getRole: 'get-roles',
  };

  async loginService(data) {
    const response = await this.post(this.baseURL + this.endPoint.login, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      localStorage.setItem('userData', JSON.stringify(response.data.user));
      return response.data;
    }
  }

  async loginBankID(data) {
    const response = await this.post(this.baseURL + this.endPoint.authBankId, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async collectBankIdData(data) {
    const response = await this.post(this.baseURL + this.endPoint.collectBankIdData, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userData', JSON.stringify(response.data.user));
      }
      return response.data;
    }
  }

  async cancelBankIdAuth(data) {
    const response = await this.post(this.baseURL + this.endPoint.cancelBankIdAuth, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async giggerSignUpService(data) {
    const response = await this.signup(this.baseURL + this.endPoint.giggerSignUp, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      if (data.withoutBankID) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userData', JSON.stringify(response.data.data.user));
      }
      return response.data;
    }
  }

  async customerSignUpService(data) {
    const response = await this.signup(this.baseURL + this.endPoint.companySignUp, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      if (data.withoutBankID) {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('userData', JSON.stringify(response.data.data.user));
      }
      return response.data;
    }
  }

  async forgotPasswordService(data) {
    const response = await this.post(this.baseURL + this.endPoint.forgetPassword, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async resetPasswordService(data) {
    const response = await this.post(this.baseURL + this.endPoint.resetPassword, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getRole() {
    const response = await this.get(this.baseURL + this.endPoint.getRole);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}
export default SignUpService;
