import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
  StarIcon,
} from '../../../components/Modals/styles';
import TextArea from '../../../components/Input/TextArea';
import FeedbackService from '../../../services/AdminServices/FeedbackService';
import Config from '../../../config';
import Constants from '../../../shared/constants';
import ModalLoader from '../../../components/Loader/ModalLoader';

class Modal extends Component {
  feedbackService = new FeedbackService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      feedback: '',
      rating: 0,
      isDisabled: true,
      formValid: {
        feedback_isValid: false,
        rating_isValid: false,
      },
      loading: false,
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({ feedback: data.feedback, rating: data.rating });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  };

  handleRatingClick = (value) => {
    this.setState({ rating: value }, this.validate);
  };

  validate = () => {
    const { feedback, rating, formValid } = this.state;
    let error = false;
    if (validator.isEmpty(feedback)) {
      formValid.feedback_isValid = false;
    } else {
      formValid.feedback_isValid = true;
    }
    if (rating > 0) {
      formValid.rating_isValid = true;
    } else {
      formValid.rating_isValid = false;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  };

  handleSaveFeedback = async () => {
    this.setState({ loading: true });
    const { id, updateFeedbackList } = this.props;
    const { feedback, rating } = this.state;
    const data = { feedback: { value: feedback }, rating: { value: rating } };
    try {
      const response = await this.feedbackService.editFeedback(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateFeedbackList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateFeedbackList();
    }
  };

  handleApproveFeedback = async () => {
    this.setState({ loading: true });
    const { id, updateFeedbackList } = this.props;
    try {
      const response = await this.feedbackService.approveFeedback(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateFeedbackList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateFeedbackList();
    }
  };

  handleDeleteFeedback = async () => {
    this.setState({ loading: true });
    const { id, updateFeedbackList } = this.props;
    try {
      const response = await this.feedbackService.deleteFeedback(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        updateFeedbackList();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      updateFeedbackList();
    }
  };

  renderRemoveBody = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage
            id="admin_feedback_remove_body_confirmation_message"
            defaultMessage="Are you sure you want to cancel?"
          />
        </Text>
        <Input>
          <Button onClick={this.handleDeleteFeedback}>
            <FormattedMessage id="common_yes_cancel" defaultMessage="Yes, cancel" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  renderApproveBody = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage
            id="approve_filter_confirmation_message"
            defaultMessage="Are you sure you want to approve?"
          />
        </Text>
        <Input>
          <Button onClick={this.handleApproveFeedback}>
            <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  renderEditFeedbackBody = () => {
    const {
      rating, feedback, isDisabled,
    } = this.state;
    const { data, ModalClose } = this.props;
    return (
      <>
        <Input>
          <TextArea
            type="text"
            name="feedback"
            value={feedback}
            label={`${Constants.language.feedback_how_was}  ${data.gigger_name} ?`}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_feedback_message" defaultMessage="Share your feedback" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <Text>
            <FormattedMessage id="common_rate_gigger" defaultMessage="Rate gigger" />
          </Text>
          <Content className="row m-0 mt-1">
            <StarIcon
              src={rating >= 1 ? Constants.icons.Star : Constants.icons.GreyBorderStar}
              alt="icon"
              className="mr-2"
              role="button"
              onClick={() => this.handleRatingClick(1)}
            />
            <StarIcon
              src={rating >= 2 ? Constants.icons.Star : Constants.icons.GreyBorderStar}
              alt="icon"
              className="mr-2"
              role="button"
              onClick={() => this.handleRatingClick(2)}
            />
            <StarIcon
              src={rating >= 3 ? Constants.icons.Star : Constants.icons.GreyBorderStar}
              alt="icon"
              className="mr-2"
              role="button"
              onClick={() => this.handleRatingClick(3)}
            />
            <StarIcon
              src={rating >= 4 ? Constants.icons.Star : Constants.icons.GreyBorderStar}
              alt="icon"
              className="mr-2"
              role="button"
              onClick={() => this.handleRatingClick(4)}
            />
            <StarIcon
              src={rating >= 5 ? Constants.icons.Star : Constants.icons.GreyBorderStar}
              alt="icon"
              role="button"
              onClick={() => this.handleRatingClick(5)}
            />
          </Content>
        </Input>
        <Input>
          <Button onClick={this.handleSaveFeedback} disabled={isDisabled}>
            <FormattedMessage id="common_save" defaultMessage="Save" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  render() {
    const {
      remove, editFeedback, approve, ModalClose,
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="FeedbackModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-small" role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      {!(remove || approve) && (
                      <Header>
                        <FormattedMessage id="admin_feedback_edit_feedbaack" defaultMessage="Edit feedback" />
                      </Header>
                      )}
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {loading && <ModalLoader />}
                  {(remove && !loading) && this.renderRemoveBody()}
                  {(approve && !loading) && this.renderApproveBody()}
                  {(editFeedback && !loading) && this.renderEditFeedbackBody()}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
