import React from 'react';
import validator from 'validator';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../../components/BaseComponent';
import { Content, SubContent } from '../../styles/CommonStyles';
import {
  Description, Header, Form, Container, Error, Wrapper, ButtonWrapper,
} from './styles';
import Constants from '../../shared/constants';
import SignUpService from '../../services/SignUpService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import PasswordBox from '../../components/Input/PasswordBox';

const taiPasswordStrength = require('tai-password-strength');

class ResetPassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeatpassword: '',
      disabled: false,
      isSubmitted: false,
      doesntMeetRequirements: false,
      doesntMatch: false,
      loading: false,
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
      name: '',
    };
    const token = localStorage.getItem(Constants.storage.token);
    if (token) {
      localStorage.clear();
      this.props.history.push({ pathname: Constants.routes.home.url });
    }
  }

  signUpService = new SignUpService(Config.apiBase);

  componentWillMount() {
    const {
      location: { search },
    } = this.props;

    const params = queryString.parse(search);
    this.setState({ params });
  }

  /**
   * Validates the form
   */
  validateForm = () => {
    const { password, repeatpassword } = this.state;
    const passwordStrength = new taiPasswordStrength.PasswordStrength();
    const validation = passwordStrength.check(password);

    const doesntMatch = password === repeatpassword;
    const doesntMeetRequirements = validation
      && validation.charsets
      && (validation.charsets.symbol || validation.charsets.punctuation)
      && validation.charsets.lower
      && validation.charsets.upper
      && validation.charsets.number
      && validation.passwordLength >= 8;
    this.setState({ doesntMatch, doesntMeetRequirements });
    const disabled = doesntMatch && doesntMeetRequirements;
    this.setState({ disabled });
    return { doesntMatch, doesntMeetRequirements };
  };

  /**
   * Handles change in password
   * @param {event} event
   */
  handlePasswordChange = (event) => {
    const { rule } = this.state;
    let { isPasswordValid } = this.state;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.validateForm,
    );
    if (rule) {
      isPasswordValid = this.validate(event.target.value, rule);
    }
    this.setState({ isPasswordValid, showRequirementsDoesntMatch: false });
  };

  /**
   * Validates the password onclicking outside password box
   * @param {event} event
   */
  handlePasswordBlur = (event) => {
    const showError = true;
    this.handlePasswordChange(event);
    let { RequirementsDoesntMatch } = false;
    let { DoesntMatch } = false;
    const { password, repeatpassword } = this.state;
    if (!validator.isEmpty(password)) {
      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(password);
      RequirementsDoesntMatch = validation
        && validation.charsets
        && (validation.charsets.symbol || validation.charsets.punctuation)
        && validation.charsets.lower
        && validation.charsets.upper
        && validation.charsets.number
        && validation.passwordLength >= 8;
      this.setState({ showRequirementsDoesntMatch: !RequirementsDoesntMatch });
    }
    if (!validator.isEmpty(password) && !validator.isEmpty(repeatpassword)) {
      DoesntMatch = password === repeatpassword;
      this.setState({ showPwdDoesntMatch: !DoesntMatch });
    }
    this.setState({ showPasswordError: showError, name: event.target.name });
  };

  /**
   * Handles change in repeat password
   * @param {event} event
   */
  handleRepeatPasswordChange = (event) => {
    const { rule } = this.state;
    let { isRepeatPasswordValid } = this.state;
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.validateForm,
    );
    if (rule) {
      isRepeatPasswordValid = this.validate(event.target.value, rule);
    }
    this.setState({ isRepeatPasswordValid, showPwdDoesntMatch: false });
  };

  /**
   * Validates repeat password field
   * @param {event} event
   */
  handleRepeatPasswordBlur = (event) => {
    const showError = true;
    this.handleRepeatPasswordChange(event);
    let { DoesntMatch } = false;
    const { password, repeatpassword } = this.state;
    if (!validator.isEmpty(password) && !validator.isEmpty(repeatpassword)) {
      DoesntMatch = password === repeatpassword;
      this.setState({ showPwdDoesntMatch: !DoesntMatch });
    }
    this.setState({ showRepeatPasswordError: showError, name: event.target.name });
  };

  validate(value) {
    const { rule } = this.state;
    const args = rule.args || [];
    return rule.presence || !validator.isEmpty(value) || !validator.isEmail(value)
      ? rule.method(value, ...args) === rule.validWhen
      : true;
  }

  /**
   * @async
   * @function handleSubmit Submitts passwords
   * @param {event} event
   */
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { params } = this.state;
    const data = {
      email: params.email,
      token: params.token,
      password: this.state.password,
      password_confirmation: this.state.repeatpassword,
    };
    try {
      const response = await this.signUpService.resetPasswordService(data);
      if (response.status !== 200) {
        throw Error(response.statusText);
      } else {
        this.setState({ isSubmitted: true });
      }
    } catch (e) {
      // TODO: Handle error.
    }
    this.setState({ isSubmitted: true });
  };

  /**
   * Redirects to login page
   */
  handleSignIn = () => {
    this.props.history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * hide or show password on clicking tooglre eye icon
   */
  toggleShowPassword = () => {
    const { passwordHidden } = this.state;
    this.setState({ passwordHidden: !passwordHidden });
  };

  /**
   * hide or show repeat password on clicking tooglre eye icon
   */
  toggleShowRepeatPassword = () => {
    const { repeatPasswordHidden } = this.state;
    this.setState({ repeatPasswordHidden: !repeatPasswordHidden });
  };

  render() {
    const {
      isSubmitted, disabled, showRequirementsDoesntMatch, showPwdDoesntMatch, loading,
    } = this.state;

    return (
      <Container>
        <Content className="row m-0">
          {isSubmitted ? (
            <Form>
              <SubContent>
                <Header>
                  <FormattedMessage
                    id="reset_password_change_successful_message"
                    defaultMessage="Password succesfully changed"
                  />
                </Header>
                <Description reset>
                  <FormattedMessage
                    id="reset_password_how_to_reset_message"
                    defaultMessage="The new password has been saved to your account settings. Welcome to log in again and continue browsing professional giggers within GigAssembly's extensive network."
                  />
                </Description>
              </SubContent>
              <SubContent>
                <ButtonWrapper onClick={this.handleSignIn}>
                  <FormattedMessage id="common_log_in" defaultMessage="Log in" />
                </ButtonWrapper>
              </SubContent>
            </Form>
          ) : (
            <Form>
              {loading ? (
                <Loader height="60px" width="200px" />
              ) : (
                <Header>
                  <FormattedMessage id="common_reset_password" defaultMessage="Reset password" />
                </Header>
              )}
              <SubContent>
                {loading ? (
                  <Loader width="320px" />
                ) : (
                  <Description reset>
                    <FormattedMessage
                      id="reset_password_requirements_info"
                      defaultMessage="Some copy on what requirements we have for resetting a password"
                    />
                  </Description>
                )}
              </SubContent>

              {loading && <Loader height="20px" width="76px" marginTop="10px" />}
              {loading ? (
                <Loader height="40px" width="346px" marginTop="20px" />
              ) : (
                <Wrapper>
                  <PasswordBox
                    label={<FormattedMessage id="common_password" defaultMessage="Password" />}
                    name="password"
                    classname={showRequirementsDoesntMatch && 'border-red'}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    onBlur={this.handlePasswordBlur}
                    errorMessage={
                      <FormattedMessage id="error_password_message" defaultMessage="Enter a valid password" />
                    }
                  />
                  {showRequirementsDoesntMatch && (
                    <Error>
                      <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                      <span>
                        <FormattedMessage
                          id="error_password_doesnt_meet_requirements"
                          defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number. "
                        />
                      </span>
                    </Error>
                  )}
                </Wrapper>
              )}

              {loading && <Loader height="20px" width="76px" marginTop="10px" />}
              {loading ? (
                <Loader height="40px" width="346px" marginTop="20px" />
              ) : (
                <Wrapper>
                  <PasswordBox
                    label={
                      <FormattedMessage id="error_repeat_message" defaultMessage="Repeat password" />
                    }
                    name="repeatpassword"
                    classname={showPwdDoesntMatch && 'border-red'}
                    value={this.state.repeatpassword}
                    onChange={this.handleRepeatPasswordChange}
                    onBlur={this.handleRepeatPasswordBlur}
                    errorMessage={
                      <FormattedMessage id="error_repeat_password_message" defaultMessage="Repeat password" />
                    }
                  />
                  {showPwdDoesntMatch && (
                    <Error>
                      <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                      <span>
                        <FormattedMessage id="error_password_doesnt_match" defaultMessage="Passwords doesn't match" />
                      </span>
                    </Error>
                  )}
                </Wrapper>
              )}

              <SubContent className="pt-3 mt-4">
                {loading ? (
                  <Loader height="40px" width="346px" marginTop="24px" />
                ) : (
                  <ButtonWrapper onClick={this.handleSubmit} disabled={!disabled}>
                    <FormattedMessage id="common_reset" defaultMessage="Reset" />
                  </ButtonWrapper>
                )}
              </SubContent>
            </Form>
          )}
        </Content>
      </Container>
    );
  }
}

export default ResetPassword;
