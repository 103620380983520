import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { SignUpImage } from '../../styles/Images';
import {
  FlexContainer,
  LoginLink,
  LoginWrapper,
  Description,
  Header,
  Line,
  ImageWrapper,
} from './styles';
import { WhiteCardSignUp } from '../gigger/styles';
import { SubTitle, SectionLeft, SectionRight } from '../login/styles';
import { UploadCondition } from '../profile/styles';
import Button from '../../components/Button/Button';
import Constants from '../../shared/constants';
import { Div } from '../../styles/CommonStyles';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  /**
   * Redirects to login page
   */
  handleLoginButton= () => {
    const { history } = this.props;
    history.push(Constants.routes.logIn.url);
  }

  /**
   * Redirects to customer signUp page
   */
  handleCustomerSignUp =() => {
    const { history } = this.props;
    history.push(Constants.routes.customerSignUpWithOutBankId.url);
  }

  /**
   * Redirects to gigger signup page
   */
  handleGiggerSignUp = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerSignUp.url);
  }

  render() {
    return (
      <>
        <FlexContainer>
          <SectionLeft className="mb-5">
            <LoginWrapper marginTop="88px" className="mb-5">
              <>
                <Description>
                  <FormattedMessage
                    id="customer_sign_up_already_have_account"
                    defaultMessage="Already have an account? "
                  />
                  {' '}
                  <LoginLink onClick={this.handleLoginButton} role="button">
                    <Line>
                      {' '}
                      <FormattedMessage id="login_login" defaultMessage="Log in" />
                    </Line>
                  </LoginLink>
                </Description>
                <Header className="mb-5 col-md-12" MainHeading>
                  <FormattedMessage
                    id="sign_up_header_message"
                    defaultMessage="Create a account for free and start xyz"
                    values={{ break: <br /> }}
                  />
                </Header>
              </>
              <Div className="row m-0 ">
                <Div className="pr-lg-5">
                  <WhiteCardSignUp className="px-3 py-4 mb-4" borderLeftLightGrey>
                    <SubTitle className="mb-2">
                      <FormattedMessage id="common_company" defaultMessage="Company" />
                    </SubTitle>
                    <UploadCondition>
                      <FormattedMessage id="create_company_account" defaultMessage="Description with max two rows" />
                    </UploadCondition>
                    <UploadCondition className="justify-content-center d-inline-block m-0">
                      <Button
                        type="update"
                        onClick={this.handleCustomerSignUp}
                        name={<FormattedMessage id="gigger_sign_up" defaultMessage="Sign up" />}
                      />
                    </UploadCondition>
                  </WhiteCardSignUp>
                </Div>
                <Div>
                  <WhiteCardSignUp className="px-3 py-4 ml-lg-4 mb-4">
                    <SubTitle className="mb-2">
                      <FormattedMessage id="common_gigger" defaultMessage="Gigger" />
                    </SubTitle>
                    <UploadCondition>
                      <FormattedMessage id="gigger_sign_up_description" defaultMessage="Description with max two rows" />
                    </UploadCondition>
                    <UploadCondition className="justify-content-center d-inline-block m-0">
                      <Button
                        type="update"
                        onClick={this.handleGiggerSignUp}
                        name={<FormattedMessage id="gigger_sign_up" defaultMessage="Sign up" />}
                      />
                    </UploadCondition>
                  </WhiteCardSignUp>
                </Div>
              </Div>
            </LoginWrapper>
          </SectionLeft>
          <SectionRight>
            <ImageWrapper
              backgroundImage={SignUpImage}
              backgroundSize="cover !important"
              height="666px"
            />
          </SectionRight>
        </FlexContainer>
      </>
    );
  }
}
export default SignUp;
