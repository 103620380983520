import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CloseButton, UploadDocument, DragAndDrop, Text,
} from '../pages/profile/styles';
import Constants from '../shared/constants';
import { TextDiv } from './Input/styles';
import Loader from './Loader/Loader';
import ProfilePageService from '../services/ProfilePageService';
import Config from '../config';
import { WhiteButton } from '../pages/customer/styles';

class Document extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      docUploading: false,
      docloading: false,
      docSizeError: false,
      docUploadError: false,
      uploadProgress: 0,
      allowedTypes: props.allowedTypes || Constants.inputTypes.acceptFileTypes,
      invalidDoc: false,
    };
    this.options = {
      onUploadProgress: this.handleFileUploadProgress,
    };
  }

  /**
   * Handles all drag events
   * @param {event} e
   */
  handleDragEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * Opens the file in new tab
   * @param {url} url
   */

  handleFile = (url) => () => {
    window.open(url);
  };

  /**
   * @async
   * @function onFileLoad Uploads file
   * @param {event} e
   * @listens Event
   */

  onFileLoad = async (e) => {
    const {
      title, onChange, indexValue, allowedTypes,
    } = this.props;
    e.preventDefault();
    this.setState({
      docUploading: true, docSizeError: false, docUploadError: false, invalidDoc: false,
    });
    const sizeArray = [
      Constants.docsCategory[0],
      Constants.docsCategory[1],
      Constants.docsCategory[2],
    ];
    const fileSize = sizeArray.indexOf(title) !== -1
      ? Constants.fileSizeLimitTwoMB : Constants.fileSizeLimitTenMB;
    const file = e.currentTarget.files && e.currentTarget.files[0];
    if (file && allowedTypes.includes(file.type)) {
      if (file.size <= fileSize) {
        try {
          const response = await this.profilePageService.uploadmediaFile(file, 'docs', title, 0, this.options);
          if (response && onChange) {
            onChange(response.data, title, indexValue);
            this.setState({ docUploading: false, uploadProgress: 0 });
          }
        } catch (err) {
          this.setState({ docUploading: false, docUploadError: true });
        }
      } else {
        this.setState({ docSizeError: true, docUploading: false });
      }
    } else {
      this.setState({ invalidDoc: true });
    }
  };

  /**
   * @async
   * @function onFileDrag Uploads file
   * @param {event} e
   * @listens Event
   */

  onFileDrag = async (e) => {
    const {
      title, onChange, indexValue, allowedTypes,
    } = this.props;
    e.preventDefault();
    const sizeArray = [
      Constants.docsCategory[0],
      Constants.docsCategory[1],
      Constants.docsCategory[2],
    ];
    const fileSize = sizeArray.indexOf(title) !== -1
      ? Constants.fileSizeLimitTwoMB : Constants.fileSizeLimitTenMB;
    this.setState({
      docUploading: true, docSizeError: false, docUploadError: false, invalidDoc: false,
    });
    const file = e.dataTransfer.files && e.dataTransfer.files[0];
    if (file && allowedTypes.includes(file.type)) {
      if (file.size <= fileSize) {
        try {
          const response = await this.profilePageService.uploadmediaFile(file, 'docs', title, 0, this.options);
          if (response && onChange) {
            onChange(response.data, title, indexValue);
            this.setState({ docUploading: false, uploadProgress: 0 });
          }
        } catch (err) {
          this.setState({ docUploading: false, docUploadError: true });
        }
      } else {
        this.setState({ docSizeError: true, docUploading: false });
      }
    } else {
      this.setState({ invalidDoc: true });
    }
  };

  /**
   * Displays file Upload Progress
   * @param {event} event
   * @listens Event
   */

  handleFileUploadProgress = (event) => {
    this.setState({
      uploadProgress: Math.round((100 * event.loaded) / event.total),
    });
  };

  /**
   * @async
   * @function handleRemoveFile Removes uploaded file
   */

  handleRemoveFile = async () => {
    const {
      files, onRemove, title, isSignupPage,
    } = this.props;
    this.setState({ docloading: true });
    let response;
    try {
      if (isSignupPage) {
        response = await this.profilePageService.removeSignUpdoc(files._id);
      } else {
        response = await this.profilePageService.removeDoc({ id: files._id });
      }
      if (response && onRemove) {
        onRemove(files, title);
        this.setState({ docloading: false });
      }
    } catch (e) {
      this.setState({ docloading: false });
    }
  };

  render() {
    const { files, disabled } = this.props;
    const {
      docSizeError,
      docloading,
      docUploadError,
      uploadProgress,
      docUploading,
      allowedTypes,
      invalidDoc,
    } = this.state;
    return (
      <div className={`mb-3 ${disabled ? 'disableOption' : ''}`}>
        {files && (files.name || files.file_name) ? (
          <>
            {docloading && <Loader height="42px" margin="0" />}
            {!docloading && (
              <UploadDocument className="col p-2">
                <Text className="text-truncate">
                  <a href={files.url} download={files.file_name || files.name} target="_blank" rel="noreferrer">
                    <WhiteButton role="button">
                      {files.name ? files.name : files.file_name}
                    </WhiteButton>
                  </a>
                </Text>
              </UploadDocument>
            )}
          </>
        ) : (
          <div className="col p-0">
            {docUploading && !invalidDoc && (
              <UploadDocument className="progress mb-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={uploadProgress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${uploadProgress}%` }}
                />
              </UploadDocument>
            )}
            {(!docUploading || invalidDoc) && (
              <label
                onDrop={this.onFileDrag}
                onDragOver={(e) => this.handleDragEvent(e)}
                onDragEnter={(e) => this.handleDragEvent(e)}
                onDragLeave={(e) => this.handleDragEvent(e)}
                className="w-100 mb-0"
              >
                <UploadDocument className="col p-2">
                  <u role="button" className="">
                    <FormattedMessage id="common_drag_and_drop_or_upload" defaultMessage="Drag and drop or upload" />
                  </u>

                  <DragAndDrop
                    accept={allowedTypes.join(', ')}
                    onClick={this.openFileDialog}
                    className="FileInput"
                    type="file"
                    id="file-browser-input"
                    name="file-browser-input"
                    ref={(input) => { this.fileInput = input; }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDragEnter={(e) => this.handleDragEvent(e)}
                    onDragLeave={(e) => this.handleDragEvent(e)}
                    onDrop={this.onFileLoad}
                    onChange={this.onFileLoad}
                  />
                </UploadDocument>
              </label>
            )}
          </div>
        )}

        {files && (files.name || files.file_name) && (
          <div className="col text-end p-0 pt-1">
            <CloseButton onClick={this.handleRemoveFile} role="button">
              <FormattedMessage id="common_remove" defaultMessage="Remove" />
            </CloseButton>
          </div>
        )}
        {(docSizeError || docUploadError || invalidDoc) && (
          <TextDiv className="text-center">
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            {docUploadError && (
              <span>
                <FormattedMessage id="error_doc_upload" defaultMessage="Unable to upload document" />
              </span>
            )}
            {docSizeError && (
              <span>
                <FormattedMessage id="error_doc_size" defaultMessage="Document size exceeded" />
              </span>
            )}
            {invalidDoc && (
            <span>
              <FormattedMessage id="error_doc_type" defaultMessage="Document type is invalid" />
            </span>
            )}
          </TextDiv>
        )}
      </div>
    );
  }
}

export default Document;
