import React, { useState, useEffect } from 'react';
import { FlexContainer } from '../../styles/CommonStyles';
import {
  P, MonthName, CalenderCol, CalenderCell, CalenderNavContainer, CalendarDiv,
} from './styles';
import { ArrowDirection } from '../../styles/Icons';
import { getActivedates, getLocaleFromURL } from '../../shared/utils';

const CustomCalender = ({ monthsToDisplay = 2, dates = [] }) => {
  const [activeDate, setActiveDate] = useState(new Date());

  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  const nDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  /**
   * Checks the month, year and generates a 7X7 matrix with active dates.
   * Using the param the year and month are taken max days of the month are
   * taken from the ndays array, a counter value will be added only to the active days.
   * @function generateMatrix
   * @param {object} activeDateParam From and to dates in month
   * @returns 7X7 month matrix.
   */
  const generateMatrix = (activeDateParam) => {
    const matrix = [];
    matrix[0] = weekDays;
    const year = activeDateParam.getFullYear();
    const month = activeDateParam.getMonth();

    const firstDay = new Date(year, month, 1).getDay();
    let maxDays = nDays[month];
    if (month === 1) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        maxDays += 1;
      }
    }

    let counter = 1;
    for (let row = 1; row < 7; row += 1) {
      matrix[row] = [];
      for (let col = 1; col <= 7; col += 1) {
        matrix[row][col] = -1;
        if ((row === 1 && col >= firstDay) || (row > 1 && counter <= maxDays)) {
          matrix[row][col] = new Date(year, month, counter);
          counter += 1;
        }
      }
    }
    return matrix;
  };

  /**
  *
  * Calls fetchCategories function when the below values changes
  * [loading, meta.pageNumber, showToast, sortAscending, sortedBy]
  *
  */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Adds days
   * @param {string} currentDate
   * @param {number} days
   */
  const addDays = (currentDate, days) => {
    const date = new Date(currentDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  /**
   * Returns an array consistes of dates between from and to dates.
   * @param {string} from Fromdate
   * @param {string} to To date
   */
  const getDates = (from, to) => {
    const datesValue = [];
    let currentDate = from;
    while (currentDate <= to) {
      datesValue.push(currentDate.setHours(0, 0, 0, 0));
      currentDate = addDays(currentDate, 1);
    }
    return datesValue;
  };

  /**
   * Generates a monthly calendar.
   * @param {object} date
   */
  const generateCalender = (date) => {
    let rows = [];

    rows = generateMatrix(date).map((row) => {
      const rowItems = row.map((item, outerIndex) => {
        const betweenDates = dates.map((dateValue) => getDates(
          new Date(dateValue.from), new Date(dateValue.to),
        ));
        const highlightDates = betweenDates.map((betweenDate) => {
          const index = betweenDate.findIndex((bd) => {
            const curDate = new Date(item);
            return Number(bd) === Number(curDate.setHours(0, 0, 0, 0));
          });
          return index >= 0;
        });
        const highlight = highlightDates.findIndex((hd) => hd === true) !== -1;
        const opacity = item.toString() === new Date().toString() || highlight ? '1' : typeof item === 'object' ? '0.4' : '0.8';

        return (
          <CalenderCell
            key={(item === -1 ? outerIndex : item) * 100}
            colorWhite={highlight}
            opacity={opacity}
            highLightCell={highlight}
            colHeading={typeof item === 'object'}
          >
            {item !== -1 ? (typeof item === 'object' ? item.getDate() : item) : ''}
          </CalenderCell>
        );
      });

      return (
        <FlexContainer key={row}>
          {rowItems}
        </FlexContainer>
      );
    });
    return rows;
  };

  /**
   * Moves to next or previous month
   * @param {number} n No of months to move
   */
  const changeMonth = (n) => {
    setActiveDate(getActivedates(n, activeDate));
  };

  /**
   * Generates how many months to be displayed.
   * @param {number} min min number of months to be displayed
   * @param {number} max max number of months to be displayed.
   */
  const generateArray = (min, max) => Array(max - min + 1)
    .join()
    .split(',')
    .map((e, i) => min + i);

  /**
     * Generates the monthly calendar
     * @param {string} month Calendar for certain month
     */
  const getDisplayCalender = (month) => getActivedates(month, activeDate, 1);
  return (
    <>
      <CalendarDiv className="row">
        {generateArray(0, monthsToDisplay - 2).map((ele, index) => (
          <CalenderCol className="col-md-6 py-md-1 px-md-3">
            <MonthName>
              {index === 0 && (
                <CalenderNavContainer className="float-left" onClick={() => changeMonth(-1)}>
                  <ArrowDirection className="cursor-pointer" />
                </CalenderNavContainer>
              )}
              <P>
                {getDisplayCalender(ele).toLocaleDateString(getLocaleFromURL(), {
                  month: 'long',
                })}
                {' '}
                {getDisplayCalender(ele).getFullYear()}
                {' '}
              </P>
              {index === monthsToDisplay - 2 && (
                <CalenderNavContainer onClick={() => changeMonth(+1)}>
                  <ArrowDirection className="rotate-180 float-right d-block cursor-pointer" />
                  {' '}
                </CalenderNavContainer>
              )}
            </MonthName>

            {generateCalender(getDisplayCalender(ele))}
          </CalenderCol>
        ))}
      </CalendarDiv>
    </>
  );
};

export default CustomCalender;
