import ApiService from './ApiService';

class GiggerService extends ApiService {
  endPoint = {
    userDetails: 'gigger/getUserDetails',
    updateUserDetails: 'gigger/updateUserDetails',
    companyDetails: 'gigger/getCompanyDetails',
    updateCompanyDetails: 'gigger/updateCompanyDetails',
    getContacts: 'order/create',
    changePassword: 'gigger/changePassword',
    myGiggs: 'gigger/my-gigs',
    deleteAccount: 'gigger/delete-account',
    fileUpload: 'file/upload',
    getDetails: 'details',
    logOut: 'logout',
    gigger: 'gigger',
    requestFeedback: 'request-feedback',
  };

  async userDetails() {
    const response = await this.get(this.baseURL + this.endPoint.userDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateUserDetails(data) {
    const response = await this.post(this.baseURL + this.endPoint.updateUserDetails, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async changePassword(data) {
    const response = await this.post(this.baseURL + this.endPoint.changePassword, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async companyDetails() {
    const response = await this.get(this.baseURL + this.endPoint.companyDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateCompanyDetails(data) {
    const response = await this.post(this.baseURL + this.endPoint.updateCompanyDetails, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getContacts(data) {
    const response = await this.post(this.baseURL + this.endPoint.getContacts, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async myGiggs() {
    const response = await this.get(this.baseURL + this.endPoint.myGiggs);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async deleteAccount(data) {
    const response = await this.post(this.baseURL + this.endPoint.deleteAccount, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async uploadmediaFile(file, documentType, category = null) {
    const form = new FormData();
    form.append('category', category);
    if (documentType === 'docs') {
      form.append('media', file);
      form.append('type', 'docs');
    } else {
      form.append('media', file);
      form.append('type', 'image');
    }
    const response = await this.postImage(this.baseURL + this.endPoint.fileUpload, form);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getDetails() {
    const response = await this.post(this.baseURL + this.endPoint.getDetails);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async logOut() {
    const response = await this.get(this.baseURL + this.endPoint.logOut);
    return response;
  }

  async requestFeedback(id, data) {
    const response = await this.post(`${this.baseURL + this.endPoint.gigger}/${id}/${this.endPoint.requestFeedback}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}

export default GiggerService;
