import React from 'react';
import validator from 'validator';
import Constants from '../../shared/constants';
import { TickIcon } from '../../styles/Icons';
import {
  Divison,
  TextDiv,
  Section,
  LabelWrapper,
  Label,
  MandatoryLabel,
  InputArea,
  BorderArea,
  EndAdornment,
} from './styles';

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showError: false,
      tickMark: false,
      rule: {
        method: validator.isEmpty,
        validWhen: false,
        // args: [{ min: 10, max: 10 }],
      },
    };
    this.textInput = React.createRef();
  }

  /**
   * @function handleChange Handles change in input element
   * @param {event} event
   */
  handleChange = (event) => {
    const eventData = event;
    const { onChange, type } = this.props;
    const { rule } = this.state;
    let { isValid } = this.state;
    const { value: eventValue } = event.target;

    if (rule) {
      isValid = this.validate(eventValue);
    }
    this.setState({ isValid, tickMark: isValid && eventValue.length > 0 });
    if (type === 'numeric') {
      eventData.target.value = eventValue.replace(/[^0-9^]+/g, '');
    }

    if (onChange) {
      onChange(eventData);
    }
  };

  /**
   * Handles focus event of the input element.
   * @param {event} event
   */
  handleFocus = (event) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  /**
   * Handles on blur event of the input element.
   * @param {event} event
   */
  handleBlur = (event) => {
    const { onBlur } = this.props;
    const { value } = event.target;

    const showError = !this.validate(value);

    if (onBlur) {
      onBlur(event);
    }
    this.setState({ showError, tickMark: !showError && value.length > 0 });
  };

  focus = () => {
    this.textInput.current.focus();
  };

  /**
   * Validates the input element
   * @param {*} value Value of the input element.
   */
  validate(value) {
    const { rule } = this.props;
    const args = (rule && rule.args) || [];
    return (rule && rule.presence) || !validator.isEmpty(value) || !validator.isEmail(value)
      ? (rule && rule.method(value, ...args)) === (rule && rule.validWhen)
      : true;
  }

  render() {
    const {
      showError, isValid, tickMark,
    } = this.state;
    const {
      className,
      label,
      name,
      value,
      type,
      id,
      placeholder = '',
      classname,
      minLength,
      maxLength,
      disabled,
      mandatory,
      errorMessage,
      existError,
      existErrorMessage,
      rule,
    } = this.props;

    return (
      <Section>
        {label && (
          <LabelWrapper>
            <Label mandatory={mandatory}>{label || ''}</Label>
            {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
          </LabelWrapper>
        )}
        <BorderArea
          mandatory
          className={
            (showError && !isValid) || existError
              ? 'border-red row m-0 p-0'
              : classname
                ? 'border-red row m-0 p-0'
                : 'row m-0 p-0'
          }
        >
          <Divison className="col p-0">
            <InputArea
              customHeight
              name={name}
              type={type}
              id={id}
              onChange={this.handleChange}
              onFocus={() => this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.textInput}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              minLength={minLength}
              maxLength={maxLength}
              className={className}
            />
          </Divison>

          {showError && !isValid && rule && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{rule.message || errorMessage}</span>
          </TextDiv>
          )}

          {tickMark && !classname && !existError && (
            <EndAdornment className="col-1 p-0 mr-3 mt-2 text-end">
              <TickIcon size={16} fill="green" />
            </EndAdornment>
          )}

        </BorderArea>

        {existError && rule && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{existErrorMessage}</span>
          </TextDiv>
        )}

      </Section>
    );
  }
}

export default TextArea;
