import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Constants from '../../shared/constants';
import Toast, { MainContext } from '../../components/Toast/Toast';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import UserService from '../../services/AdminServices/UserService';
import { RelativeContainer } from '../../styles/CommonStyles';
import UserDetailsUI from '../../components/UserDetails/index';

class UserDetails extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      emailError: false,
      oldPasswordError: false,
      firstName: '',
      lastName: '',
      id: '',
      loggedIn: this.authService.isAuthenticated(),
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      userData: [],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchUserDetails();
  }

  /**
   * @async
   * @function handleChangeMailButton
   * Saves the changes made in user details
   * @param {object} data Data of the user
   */
  handleChangeMailButton = async (data) => {
    this.setState({ loading: true, emailError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    const { firstName, lastName, id } = this.state;
    const { email = '' } = data;
    const payload = { name: { value: `${firstName} ${lastName}` }, email: { value: email } };
    try {
      const response = await this.userService.editUser(id, payload);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_user_details_updated;
        this.setState({ loading: false, toastData, showToast: true });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    } catch (e) {
      const { response = {} } = e;
      const { data: errorData = {} } = response;
      const { message = '' } = errorData;
      if (message === Constants.emailExistsMessage) {
        this.setState({ emailError: true, loading: false, showToast: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   * Redirects to forgot password page
   */
  handleForgotPassword = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.forgotPassword.url });
  };

  /**
   * Logs out the acount
   */
  handleLogOutAccount = () => {
    localStorage.clear();
    window.location.reload();
  };

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.adminMyAccount.url);
  };

  /**
   * @async
   * @function handleChangePasswordButton Saves change in password
   * @param {object} data passwords data
   */
  handleChangePasswordButton = async (data) => {
    this.setState({ loading: true, oldPasswordError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.customerService.changePassword(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_password_updated;
        this.setState({
          loading: false,
          toastData,
          showToast: true,
        });
        this.handleLogOutAccount();
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ toastData, showToast: true, loading: false });
      }
    } catch (e) {
      if (e.response && e.response.data.error === "Old Password didn't match") {
        this.setState({ oldPasswordError: true, loading: false, showToast: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   * Closes toast
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   * Redirect delete account confirmation page
   */
  handleDelete = async () => {
    const { history } = this.props;
    history.push(Constants.routes.deleteAccount.url, { admin: true });
  };

  /**
   * @async
   * @function fetchUserDetails fetches the user details
   */
  async fetchUserDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.customerService.getDetails();
      const { data = {} } = response;
      const { full_name: fullName = '', _id: id = '' } = data;
      const firstName = fullName.split(' ')[0] || '';
      const lastName = fullName.split(' ')[1] || '';
      this.setState({
        firstName,
        lastName,
        userData: data,
        loading: false,
        id,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      loading, oldPasswordError, loggedIn, showToast, toastData, emailError, userData,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <UserDetailsUI
            data={userData}
            loading={loading}
            emailError={emailError}
            oldPasswordError={oldPasswordError}
            handleMyAccount={this.handleMyAccount}
            handleDelete={this.handleDelete}
            handleSubmitDetails={this.handleChangeMailButton}
            handleChangePassword={this.handleChangePasswordButton}
            handleForgotPassword={this.handleForgotPassword}
          />
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default UserDetails;
