import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { Border } from '../../users/styles';
import LoadingCard from '../../LoadingCard';
import { WhiteCard } from '../../../gigger/styles';
import ListView from '../ListView/ListView';
import ListViewHeader from '../ListView/ListViewHeader';
import ToolBar from '../../ToolBar';
import Constants from '../../../../shared/constants';
import { FilterListWrapper } from '../styles';
import Modal from '../Modal';
import FilterPageService from '../../../../services/AdminServices/FilterPageService';
import Config from '../../../../config';
import Pagination from '../../../../components/Pagination/Pagination';
import { getFormattedCSVData } from '../../../../shared/utils';

const TitleTab = ({ loading, handleToast }) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [csvDownloadData, setCsvDownloadData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isApproveTitleModalOpen, setIsApproveTitleModalOpen] = useState(false);
  const [isDeleteTitleModalOpen, setIsDeleteTitleModalOpen] = useState(false);
  const [isSecondaryTitleModalOpen, setIsSecondaryTitleModalOpen] = useState(false);
  const [titleData, setTitleData] = useState([]);
  const csvExportFileds = ['name', 'type', 'added_by', 'created_at', 'status'];
  const filterPageService = new FilterPageService(Config.apiBase);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 15,
    pages: 1,
  });
  const [titleTabPageLoading, setTitleTabPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchbar, setSearchBar] = useState(true);
  const [sortedBy, setSortedBy] = useState('created_at');
  const openModal = useRef(null);
  const titleCsv = useRef(null);

  /**
   *
   * @async
   * @function fetchJobTitles Fetches job title filters
   * @param {String} searchInput search text
   *
   */
  const fetchJobTitles = (searchInput) => {
    setTitleTabPageLoading(true);
    const queryParams = [
      ['pageNumber', searchInput ? 1 : meta.pageNumber],
      ['pageSize', meta.pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['search', searchInput],
      ['type', Constants.filter.JOBTITLE],
    ];
    try {
      filterPageService.getJobtitlesList(queryParams).then((response) => {
        const { meta: srcMetaData = {} } = response;
        const {
          current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
        } = srcMetaData;
        const metaData = {
          pageNumber: currentPage,
          pageSize: perPage,
          pages,
          totalCount,
        };
        setTitleTabPageLoading(false);
        setTitleData(response.data);
        setMeta(metaData);
      });
    } catch (e) {
      setTitleTabPageLoading(false);
    }
  };

  /**
   *
   * Calls fetchJobTitles function when the below values changes
   * [isDeleteModalOpen,isApproveModalOpen, csvDownloadData]
   *
   */
  useEffect(() => {
    if (isDeleteTitleModalOpen || isApproveTitleModalOpen || isSecondaryTitleModalOpen) {
      openModal.current.click();
    }
  }, [isDeleteTitleModalOpen, isApproveTitleModalOpen, isSecondaryTitleModalOpen]);

  /**
   *
   * Calls fetchJobTitles function when the below values changes
   * [loading, meta.pageNumber, showToast, sortAscending, sortedBy]
   *
   */
  useEffect(() => {
    fetchJobTitles();
  }, [loading, sortAscending, sortedBy]);

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handlePagination = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 15;
    setMeta(meta);
    fetchJobTitles();
  };

  /**
   *
   * Handles search functionality
   * @param {String} value search text
   *
   */
  const handlesearchJobTitles = (value) => {
    setSearchQuery(value);
    setSearchBar(value.length <= 0);
    fetchJobTitles(value);
  };

  /**
   *
   * Handles change in search value
   * @param {Event} event search text
   *
   */
  const handleChangeInSearchWord = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  /**
   *
   * Downloads data in the form of csv file
   *
   */
  const handleDownloadTitleCsv = async () => {
    setTitleTabPageLoading(true);
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
      ['type', Constants.filter.JOBTITLE],
    ];
    try {
      const response = await filterPageService.getJobtitlesList(queryParams);
      setCsvDownloadData(getFormattedCSVData(response.data));
      setTitleTabPageLoading(false);
      titleCsv.current.link.click();
    } catch (e) {
      setTitleTabPageLoading(false);
    }
  };

  /**
   *
   * Opens delete modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleDeleteTitleModal = (deleteData) => () => {
    setIsDeleteTitleModalOpen(true);
    setData(deleteData);
  };

  /**
   *
   * Opens delete modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleSecondaryTitleModal = (deleteData) => () => {
    setIsSecondaryTitleModalOpen(true);
    setData(deleteData);
  };

  /**
   *
   * Opens approve modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleApproveTitleModal = (approveData) => () => {
    setIsApproveTitleModalOpen(true);
    setData(approveData);
  };

  /**
   *
   * Closes the opened modal
   *
   */
  const handleCloseModal = () => {
    setIsApproveTitleModalOpen(false);
    setIsDeleteTitleModalOpen(false);
    setIsSecondaryTitleModalOpen(false);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSortTitleList = (name) => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };

  /**
   *
   * Opens out search bar
   *
   */
  const openSearchBar = () => {
    setSearchBar(false);
  };

  return (
    <>
      <CSVLink data={csvDownloadData} filename="title.csv" className="d-none" ref={titleCsv}>
        click
      </CSVLink>
      <FilterListWrapper>
        <ToolBar
          searchbar={searchbar}
          handleSearch={handlesearchJobTitles}
          handleSearchOnChange={handleChangeInSearchWord}
          searchQuery={searchQuery}
          handleDownloadCsv={handleDownloadTitleCsv}
          openSearchBar={openSearchBar}
          data={titleData}
        />
        {titleTabPageLoading && (
        <FilterListWrapper>
          <LoadingCard />
        </FilterListWrapper>
        )}
        {!titleTabPageLoading && (
        <>
          <ListViewHeader
            handleSorting={handleSortTitleList}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
            title
          />
          {titleData.length > 0 ? (
            <>
              <ListView
                data={titleData}
                handleApprove={handleApproveTitleModal}
                handleDelete={handleDeleteTitleModal}
                handleSecondaryTitle={handleSecondaryTitleModal}
                type={<FormattedMessage id="common_title" defaultMessage="title" />}
                title
              />
              <a
                href
                data-backdrop="static"
                data-keyboard="false"
                data-toggle="modal"
                data-target="#FilterModal"
                ref={openModal}
              />
              {isApproveTitleModalOpen && (
              <Modal
                approve={isApproveTitleModalOpen}
                id={data._id}
                type={Constants.filter.JOBTITLE}
                ModalClose={handleCloseModal}
                handleToast={handleToast}
              />
              )}
              {isDeleteTitleModalOpen && (
              <Modal
                remove={isDeleteTitleModalOpen}
                id={data._id}
                type={Constants.filter.JOBTITLE}
                ModalClose={handleCloseModal}
                handleToast={handleToast}
              />
              )}
              {isSecondaryTitleModalOpen && (
              <Modal
                updateFilter={isSecondaryTitleModalOpen}
                id={data._id}
                name={data.secondary_title}
                type={Constants.filter.JOBTITLE}
                ModalClose={handleCloseModal}
                handleToast={handleToast}
              />
              )}
            </>
          ) : (
            <>
              <Border />
              <WhiteCard className="my-3">
                {searchQuery.length > 0 ? (
                  <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
                ) : (
                  <FormattedMessage id="not_found_filter_title" defaultMessage="No titles found" />
                )}
              </WhiteCard>
            </>
          )}
        </>
        )}
      </FilterListWrapper>
      {!titleTabPageLoading && meta.pages > 1 && (
      <FilterListWrapper style={{ backgroundColor: 'transparent' }}>
        <Pagination cursor={meta} gotoPage={handlePagination} />
      </FilterListWrapper>
      )}
    </>
  );
};

export default TitleTab;
