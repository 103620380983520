import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import validator from 'validator';
import Luhn from 'luhn';
import {
  Content, UserDetailsWrapper, SubHeader, Image, Input, HeaderName,
} from '../../../customer/styles';
import Constants from '../../../../shared/constants';
import TextBox from '../../../../components/Input/TextBox';
import PasswordBox from '../../../../components/Input/PasswordBox';
import Button from '../../../../components/Button/Button';
import GiggerService from '../../../../services/AdminServices/GiggersService';
import Config from '../../../../config';
import { addHyphenToNumber, isCvVerificationUser } from '../../../../shared/utils';

const AccountDetails = ({ giggerId, giggerDetails, handleToast }) => {
  const giggerService = new GiggerService(Config.apiBase);
  const [expandDetails, setExpandDetails] = useState(false);
  const [expandPassword, setExpandPassword] = useState(false);
  const [data, setGiggerData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [personalNumError, setPersonalNumError] = useState(false);
  const [orgNumberError, setOrgNumError] = useState(false);
  const [formValid, setFormValid] = useState({
    personalNo_isValid: false,
    orgNumber_isValid: false,
  });

  const { input } = Constants;
  const { name: inputNames } = input;

  const handleExpandDetails = () => {
    setExpandDetails(!expandDetails);
  };

  const handleExpandPassword = () => {
    setExpandPassword(!expandPassword);
  };

  const validateForm = (details = {}) => {
    let error = false;
    const { orgNumber = '', personalNumber = '' } = details;
    if (orgNumber && orgNumber.length === Constants.OrganisationNumberLength
      && !orgNumberError
    ) {
      const number = orgNumber && orgNumber.replace('-', '');
      formValid.orgNumber_isValid = Luhn.validate(number);
    } else {
      formValid.orgNumber_isValid = orgNumber.length === 0;
    }
    if (personalNumber && personalNumber.length === Constants.PersonalNumberLength
      && !personalNumError
    ) {
      formValid.personalNo_isValid = true;
    } else {
      formValid.personalNo_isValid = false;
    }

    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    setFormValid(formValid);
    setIsDisabled(error);
  };

  useEffect(() => {
    const { personalNumber = '', orgNumber = '', thirdPartyInvoice = false } = giggerDetails;
    const details = {
      personalNumber: addHyphenToNumber(personalNumber, 8),
      orgNumber: addHyphenToNumber(orgNumber, 6),
      thirdPartyInvoice,
    };
    setGiggerData(details);
  }, [giggerDetails]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setGiggerData({ ...data, [name]: value });
    validateForm({ ...data, [name]: value });
    if (name === Constants.personalNumber) {
      setPersonalNumError(false);
    }
    if (name === Constants.orgNumber) {
      setOrgNumError(false);
    }
  };

  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (name === Constants.personalNumber && value.length
      >= Constants.PersonalNumberLength) e.preventDefault();
    if (
      name === Constants.organisationNumber
        && ((value.replace(/-/g, '')).length) >= Constants.OrganisationNumberLength - 1
    ) {
      e.preventDefault();
      setGiggerData({ ...data, [name]: addHyphenToNumber(value, 6) });
      validateForm(data);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      organization_number: data.orgNumber.replace('-', ''),
      personal_number: data.personalNumber.replace('-', ''),
      password: data.newPassword,
      password_confirmation: data.repeatPassword,
      is_organization_not_empty: data.orgNumber.replace('-', '').length > 0,
    };
    try {
      await giggerService.updateGiggerDetails(giggerId, payload);
      handleToast({
        type: Constants.toast.SUCCESS,
        message: Constants.language.toast_profile_updated,
        delay: Constants.toast.DELAY,
      });
    } catch (error) {
      const { response = {} } = error;
      const { data: errorData = {} } = response;
      const { errors: inputError = {} } = errorData;
      if (inputError && inputError.organization_number) {
        setOrgNumError(true);
        setIsDisabled(true);
      } else if (inputError && inputError.personal_number) {
        setPersonalNumError(true);
        setIsDisabled(true);
      } else {
        handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_try_after_time,
          delay: Constants.toast.DELAY,
        });
      }
    }
  };

  return (
    <>
      <Content className="row">
        <UserDetailsWrapper>
          <Content className="row">
            <Content className="col-10 p-0">
              <SubHeader role="button" onClick={handleExpandDetails}>
                <FormattedMessage id="common_accountOwner" defaultMessage="Account owner" />
              </SubHeader>
            </Content>
            <Content className="col-2 text-end p-0">
              <Image
                src={Constants.icons.ArrowDown}
                alt="icon"
                onClick={handleExpandDetails}
                className={expandDetails && 'rotate-180'}
                role="button"
              />
            </Content>
          </Content>
          {expandDetails && (
            <>
              <Input>
                <Content className="row mt-3">
                  <TextBox
                    type="numeric"
                    label={
                      <FormattedMessage id="common_personal_number" defaultMessage="Personal ID number" />
                           }
                    name={inputNames.personalNumber}
                    value={data.personalNumber}
                    onChange={handleOnChange}
                    className={isCvVerificationUser() ? 'disableOption' : ''}
                    rule={{
                      method: validator.isEmpty,
                      validWhen: false,
                      message: <FormattedMessage id="error_first_name" defaultMessage="Enter your first name" />,
                    }}
                    customRule={
                      {
                        validWhen: personalNumError,
                        message: <FormattedMessage
                          id="error_personal_no_exists_message"
                          defaultMessage="Enter a valid personal ID number"
                        />,
                      }
                    }
                  />
                </Content>
                <Content className={`${!!isCvVerificationUser() && 'disableOption'} row mt-3 flex-column`}>
                  <>
                    <HeaderName>
                      <FormattedMessage id="common_organisation_number" defaultMessage="Organisation number" />
                    </HeaderName>
                    <HeaderName lightGreyColor className="mt-0">
                      <FormattedMessage id="info_optional" defaultMessage="This info is optional" />
                    </HeaderName>
                  </>
                  <TextBox
                    type="numeric"
                    name={inputNames.orgNumber}
                    value={data.orgNumber}
                    onChange={handleOnChange}
                    placeholder="XXXXXX-YYYY"
                    onKeyPress={handleKeyPress}
                    rule={{
                      method: validator.isLength,
                      validWhen: data.orgNumber.length > 0,
                      args: [
                        {
                          min: Constants.OrganisationNumberLength,
                          max: Constants.OrganisationNumberLength,
                        },
                      ],
                      message: <FormattedMessage
                        id="error_corporate_id_message"
                        defaultMessage="Enter a valid corporate ID number"
                      />,
                    }}
                    customRule={
                      {
                        validWhen: orgNumberError
                        || (
                          !formValid.orgNumber_isValid
                    && data.orgNumber
                    && data.orgNumber.length === Constants.OrganisationNumberLength
                        ),
                        message: <FormattedMessage
                          id="error_corporate_id_message"
                          defaultMessage="Enter a valid corporate ID number"
                        />,
                      }
                    }
                  />
                </Content>
              </Input>
            </>
          )}
        </UserDetailsWrapper>
        <UserDetailsWrapper>
          <Content className="row">
            <Content className="col-10 p-0">
              <SubHeader role="button" onClick={handleExpandPassword}>
                <FormattedMessage id="common_change_password" defaultMessage="Change password" />
              </SubHeader>
            </Content>
            <Content className="col-2 text-end p-0">
              <Image
                src={Constants.icons.ArrowDown}
                alt="icon"
                onClick={handleExpandPassword}
                className={expandPassword && 'rotate-180'}
                role="button"
              />
            </Content>
          </Content>
          {expandPassword && (
            <>
              <Input>
                <Content className={`${!!isCvVerificationUser() && 'disableOption'} row mt-3`}>
                  <PasswordBox
                    type="text"
                    name={Constants.NEW_PASSWORD}
                    label={<FormattedMessage id="common_new_password" defaultMessage="New password" />}
                    value={data.newPassword}
                    onChange={handleOnChange}
                    errorMessage={(
                      <FormattedMessage
                        id="error_password_doesnt_meet_requirements"
                        defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number. "
                      />
                            )}
                  />
                </Content>

                <Content className={`${!!isCvVerificationUser() && 'disableOption'} row mt-3`}>
                  <PasswordBox
                    type="text"
                    name={Constants.REPEAT_PASSWORD}
                    label={(
                      <FormattedMessage
                        id="customer_sign_up_repeat_password"
                        defaultMessage="Repeat password"
                      />
)}
                    value={data.repeatPassword}
                    onChange={handleOnChange}
                    password={data.newPassword}
                    errorMessage={(
                      <FormattedMessage
                        id="error_password_doesnt_match"
                        defaultMessage="Passwords doesn't match"
                      />
)}
                  />
                </Content>

              </Input>
            </>
          )}
        </UserDetailsWrapper>
      </Content>
      <Button
        name={<FormattedMessage id="common_update" defaultMessage="Update" />}
        type="update"
        className={`${!!isCvVerificationUser() && 'disableOption'} w-auto`}
        disabled={isDisabled}
        onClick={handleSubmit}
      />
    </>
  );
};

export default AccountDetails;
