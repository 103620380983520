import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import validator from 'validator';
import Luhn from 'luhn';
import {
  Content, UserDetailsWrapper, SubHeader, Image, Input,
} from '../../../customer/styles';
import Constants from '../../../../shared/constants';
import TextBox from '../../../../components/Input/TextBox';
import Button from '../../../../components/Button/Button';
import CompanyService from '../../../../services/AdminServices/CompanyService';
import Config from '../../../../config';
import { addHyphenToNumber } from '../../../../shared/utils';

const AccountDetails = ({ companyDetails, handleToast }) => {
  const companyService = new CompanyService(Config.apiBase);
  const [expandDetails, setExpandDetails] = useState(true);
  const [orgNumberError, setOrgNumError] = useState(false);
  const [data, setCompanyData] = useState({
    companyName: '',
    orgNumber: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [formValid, setFormValid] = useState({
    orgNumber_isValid: false,
    company_isValid: false,
  });

  const { input } = Constants;
  const { name: inputNames, type: inputTypes } = input;

  const handleExpandDetails = () => {
    setExpandDetails(!expandDetails);
  };

  const validateForm = (details) => {
    let error = false;
    const { orgNumber = '', companyName = '' } = details;
    if (orgNumber && orgNumber.length === Constants.OrganisationNumberLength
      && !orgNumberError
    ) {
      const number = orgNumber && orgNumber.replace('-', '');
      formValid.orgNumber_isValid = Luhn.validate(number);
    } else {
      formValid.orgNumber_isValid = false;
    }
    formValid.company_isValid = !validator.isEmpty(companyName);

    setFormValid(formValid);
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    setIsDisabled(error);
  };

  useEffect(() => {
    const { company_name: companyName = '', organization_number: orgNumber = '' } = companyDetails;
    const details = {
      companyName,
      orgNumber: addHyphenToNumber(orgNumber, 6),
    };
    setCompanyData(details);
    validateForm(details);
  }, [companyDetails]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...data, [name]: value });
    validateForm({ ...data, [name]: value });
  };

  const handleKeyPress = (e) => {
    const { name, value } = e.target;
    if (
      name === Constants.organisationNumber
        && ((value.replace(/-/g, '')).length) >= Constants.OrganisationNumberLength - 1
    ) {
      e.preventDefault();
      setCompanyData({ ...data, [name]: addHyphenToNumber(value, 6) });
      validateForm(data);
    }
  };

  const handleSubmit = async () => {
    const { _id: companyId = '' } = companyDetails;
    const payload = {
      organization_number: data.orgNumber.replace('-', ''),
      company_name: data.companyName,
    };
    try {
      await companyService.updateCompany(companyId, payload);
      handleToast({
        type: Constants.toast.SUCCESS,
        message: Constants.language.toast_profile_updated,
        delay: Constants.toast.DELAY,
      });
    } catch (error) {
      const { response = {} } = error;
      const { data: errorData = {} } = response;
      const { errors: inputError = {} } = errorData;
      if (inputError && inputError.organization_number) {
        setOrgNumError(true);
        setIsDisabled(true);
      } else {
        handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_try_after_time,
          delay: Constants.toast.DELAY,
        });
      }
    }
  };

  return (
    <>
      <Content className="row">
        <UserDetailsWrapper>
          <Content className="row">
            <Content className="col-10 p-0">
              <SubHeader role="button" onClick={handleExpandDetails}>
                <FormattedMessage id="common_company_settings" defaultMessage="Company settings" />
              </SubHeader>
            </Content>
            <Content className="col-2 text-end p-0">
              <Image
                src={Constants.icons.ArrowDown}
                alt="icon"
                onClick={handleExpandDetails}
                className={expandDetails && 'rotate-180'}
                role="button"
              />
            </Content>
          </Content>
          {expandDetails && (
            <>
              <Input>
                <Content className="row mt-3">
                  <TextBox
                    type={inputTypes.text}
                    label={<FormattedMessage id="common_company_name" defaultMessage="Company name" />}
                    name={inputNames.companyName}
                    value={data.companyName}
                    onChange={handleOnChange}
                    rule={{
                      method: validator.isEmpty,
                      validWhen: false,
                      message: <FormattedMessage id="error_company_name_message" defaultMessage="Enter name of company" />,
                    }}
                  />
                </Content>
                <Content className="row mt-3">
                  <TextBox
                    type="numeric"
                    name={inputNames.orgNumber}
                    label={<FormattedMessage id="common_organisation_number" defaultMessage="Organisation number" />}
                    value={data.orgNumber}
                    onChange={handleOnChange}
                    placeholder="XXXXXX-YYYY"
                    onKeyPress={handleKeyPress}
                    rule={{
                      method: validator.isLength,
                      validWhen: true,
                      args: [
                        {
                          min: Constants.OrganisationNumberLength,
                          max: Constants.OrganisationNumberLength,
                        },
                      ],
                      message: <FormattedMessage
                        id="error_corporate_id_message"
                        defaultMessage="Enter a valid corporate ID number"
                      />,
                    }}
                    customRule={
                      {
                        validWhen: orgNumberError
                        || (
                          !formValid.orgNumber_isValid
                    && data.orgNumber
                    && data.orgNumber.length === Constants.OrganisationNumberLength
                        ),
                        message: <FormattedMessage
                          id="error_corporate_id_message"
                          defaultMessage="Enter a valid corporate ID number"
                        />,
                      }
                    }
                  />
                </Content>
              </Input>
            </>
          )}
        </UserDetailsWrapper>
      </Content>
      <Button
        name={<FormattedMessage id="common_update" defaultMessage="Update" />}
        type="update"
        disabled={isDisabled}
        onClick={handleSubmit}
        className="w-auto"
      />
    </>
  );
};

export default AccountDetails;
