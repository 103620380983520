import ApiService from './ApiService';

class AccountService extends ApiService {
  endPoint = {
    list: 'account/list',
    details: 'account/details',
    filterList: 'filter/list',
    search: 'filter/search',
  };

  async getAllFilter(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.filterList + (queryParam ? this.queryParamString(queryParam) : ''),
    );
    if (response.status !== 200) {
      // TODO: Throw more specific errors
      throw Error(response.statusText);
    } else {
      const { data } = response.data;
      return {
        data,
      };
    }
  }

  async list(queryParam) {
    const response = await this.get(this.baseURL + this.endPoint.list
      + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async search(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.search + this.queryParamString(queryParam),
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async detail(data) {
    const response = await this.post(this.baseURL + this.endPoint.details, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}

export default AccountService;
