import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  ProfileViewContainer,
  DetailsLeft,
  DetailsRight,
  Row,
  UserName,
  OrderServicesHeader,
  ProfileViews,
  ProfileImageWrapper,
  ProfileStatus,
  WelcomeText,
  Button,
  A,
  JobTitle,
  JobPrice,
  SubHeader,
  WelcomeTextHeader,
  SideHeading,
  EducationTitle,
  ResumeBlock,
  ResumeContent,
  JuniorLevelTag,
  ExperienceLevelTag,
  MidLevelTag,
  ResumeContentCapitalize,
  Image,
  PinkArrowCard,
  CreateAccountCard,
  FeedbackCard,
  WhiteCard,
  BlurImage,
  ProfileImage,
  ProfileData,
  FeedbackContainer,
  Div,
  ContactDescription,
  Span,
  ShowMoreLink,
} from '../gigger/styles';
import {
  DetailCheckButton,
  BuyContactButton,
  SaveGiggerWrapper,
  ShowAllButton,
  IsDesktop,
  IsMobile,
  Desktop,
  Mobile,
  WhiteButton,
  WelcomeTextContent,
} from './styles';
import Card from '../../components/Card/Cards';
import Constants from '../../shared/constants';
import { profilePic, DefaultProfile } from '../../styles/Images';
import {
  Paperclip, Download, Lock, ProfileIcon, CreditcardIcon, TickmarkIcon, ProfileGreyIcon,
} from '../../styles/Icons';
import CustomCalender from '../../components/CustomCalender/CustomCalender';
import BuyContact from '../../components/BuyContact/BuyContactSlider';
import BuyContactForMobile from '../../components/BuyContact/Modal';
import AccountService from '../../services/AccountService';
import AuthService from '../../services/AuthService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import PersistentStorage from '../../shared/PersistentStorage.ts';
import AccordionBody from '../../components/Accordion/AccordionBody';
import Toast, { MainContext } from '../../components/Toast/Toast';
import CustomerService from '../../services/CustomerService';
import { RelativeContainer, Line } from '../../styles/CommonStyles';
import {
  getLocaleFromURL, getLanguages, isGigger, isAdmin, isCustomer,
} from '../../shared/utils';
import Modal from '../admin/company/Modal';
import { Address } from '../../components/ListCard/styles';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import { P } from '../../components/Sidemenu/styles';

const currentLanguage = getLocaleFromURL() || Config.language;

class CustomerDetailView extends Component {
  accountService = new AccountService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  persistentStorage = new PersistentStorage();

  currentLanguage = getLocaleFromURL();

  constructor(props) {
    super(props);
    this.state = {
      statusPublished: false,
      BuyContactOpen: false,
      gigger: {},
      loading: true,
      loggedIn: this.authService.isAuthenticated(),
      user: JSON.parse(this.persistentStorage.getUserDetails()) || {},
      orderType: '',
      giggerId: '',
      loadFeedback: false,
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      amount: Constants.price.BUY_CONTACT,
      languages: [],
      languagesOptions: getLanguages(),
      limitOverModal: false,
      showMoreJobs: false,
      showMoreEducation: false,
    };
  }

  componentDidMount() {
    const { match = {} } = this.props;

    if (match && match.params.id) {
      this.fetchGiggerDetails(match.params.id);
      this.setState({ giggerId: match.params.id });
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    const { showMoreJobs, showMoreEducation } = this.state;
    if (!showMoreJobs && !showMoreEducation) {
      window.scrollTo(0, 0);
    }
  }

  /**
   *
   * Redirects to profile page
   *
   */
  handleEditProfile = () => {
    const { history } = this.props;
    history.push(Constants.routes.myprofile.url);
  };

  /**
   *
   * Loads more fedback
   *
   */
  handleLoadMoreButton = () => {
    this.setState({ loadFeedback: true });
  };

  /**
   *
   * saves the gigger in under user account
   * @param {number} id gigger id
   *
   */
  handleSaveGigger = (id) => async () => {
    const { match = {} } = this.props;
    const { params = {} } = match;
    this.setState({ loading: true });
    const data = {
      gigger_id: id,
    };
    const toastData = {
      type: '',
      delay: Constants.toast.LONG_DELAY,
      message: '',
    };
    try {
      const response = await this.customerService.saveGigger(data);
      if (response) {
        this.fetchGiggerDetails(params.id);
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_gigger_saved;
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
      }
    } catch (e) {
      toastData.type = Constants.toast.ERROR;
      toastData.message = Constants.language.toast_try_after_time;
    }
    this.setState({ loading: false, toastData, showToast: true });
  };

  refresh = (toastData) => {
    this.setState({ toastData, showToast: true });
    const { match = {} } = this.props;
    const { params = {} } = match;
    if (params.id) {
      this.fetchGiggerDetails(params.id);
    }
    this.setState({ BuyContactOpen: false });
  };

  handleOpenLimitPopup = () => {
    this.setState({ BuyContactOpen: false, limitOverModal: true }, () => {
      this.openElement.click();
    });
  }

  /**
   *
   * Closes the slider or modal and shows toast
   * @param {object} toastData Data of toast.
   *
   */
  closeSlider = (toastData) => {
    if (toastData) {
      this.setState({ toastData, showToast: true, BuyContactOpen: false });
    } else {
      this.setState({ BuyContactOpen: false });
    }
  };

  /**
   *
   * Opens modal which consists of buy contact form.
   * @param {string} orderType type of the order user want to place
   *
   */
  buyContactModalOpen = (orderType) => () => {
    this.setState({ BuyContactOpen: true, orderType, amount: Constants.price[orderType] }, () => {
      this.openElement.click();
    });
  };

  /**
   *
   * Opens slider which consists of buy contact form.
   * @param {string} orderType type of the order user want to place
   *
   */
  buyContactOpen = (orderType) => () => {
    this.setState({ BuyContactOpen: true, orderType, amount: Constants.price[orderType] });
  };

  handleShowMoreJobs = () => {
    this.setState({ showMoreJobs: true });
  }

  handleShowMoreEducation = () => {
    this.setState({ showMoreEducation: true });
  }

  /**
   *
   * Redirects to my account page
   *
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
   *
   * Closes the toast
   *
   */
  handleToastClose = () => {
    this.setState({ showToast: false, limitOverModal: false });
  };

  /**
   *
   * Redirects to previous page
   *
   */
  goBack = () => {
    const { history, location: { data = {} } } = this.props;
    const { filterData, isSearchPage = false } = data;
    if (isSearchPage) {
      history.push({ pathname: Constants.routes.giggerList.url, data: filterData });
    } else {
      history.goBack();
    }
  };

  /**
   *
   * Redirects to signup page
   *
   */
  redirectToSignUpPage = () => {
    // window.location = Constants.routes.customerSignUp.url;
    window.location = Constants.routes.customerSignUpWithOutBankId.url;
  };

  /**
   *
   * Parsing and translation of languages.
   *
   */
  parseLanguages = () => {
    const { languagesOptions, languages } = this.state;
    const translatedlanguages = [];
    languagesOptions.map((obj) => {
      if (languages.includes(obj.value)) {
        translatedlanguages.push(obj.label);
      }
      return obj;
    });
    this.setState({ languages: translatedlanguages });
  };

  /**
   *
   * @function showDate set the year to the label
   * @param {string} education education object.
   * @returns string
   *
   */
  showDate = (education) => {
    if (education.start_date && education.end_date) {
      return `${education.start_date} - ${education.end_date}`;
    }
    if (education.start_date) {
      return education.start_date;
    }
    if (education.end_date) {
      return education.end_date;
    }
    return null;
  }

  /**
   *
   * @async
   * @function fetchGiggerDetails Fetches the gigger details
   * @param {number} id Id of the gigger
   *
   */
  async fetchGiggerDetails(id) {
    this.setState({ loading: true });
    try {
      const response = await this.accountService.detail({ id });
      const { data = {} } = response;
      const { education = [], previous_jobs: previousJobs = [] } = data;
      data.previousJobs = previousJobs.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));
      data.education = education.sort((a, b) => (a.start_date < b.start_date ? 1 : -1))
        .map((obj) => {
          const startYear = obj.start_date ? obj.start_date.toString().split('-')[0] : '';
          const endYear = obj.end_date ? obj.end_date.toString().split('-')[0] : '';
          return { ...obj, start_date: startYear, end_date: endYear };
        });
      this.setState({
        gigger: data,
        languages: data.language_spoken || [],
        loading: false,
      }, () => {
        this.parseLanguages();
        window.scrollTo(0, 0);
      });
    } catch (e) {
      const { response: { status = '' } = {} } = e;
      if (status === 401) {
        const { history } = this.props;
        history.push({ pathname: Constants.routes.notFound.url });
      }
      this.setState({ loading: false, gigger: {} });
    }
  }

  render() {
    const {
      statusPublished,
      BuyContactOpen,
      gigger,
      loading,
      loggedIn,
      user,
      orderType,
      giggerId,
      showToast,
      toastData,
      loadFeedback,
      amount,
      limitOverModal,
      showMoreJobs,
      showMoreEducation,
    } = this.state;
    let { languages } = this.state;
    let {
      desired_cities: desiredCities,
      skills, systems,
    } = gigger;
    const { feedbackAvg, previousJobs, education } = gigger;
    const displaydocTypes = [
      Constants.docsCategory[0],
      Constants.docsCategory[1],
      Constants.docsCategory[2],
    ];

    if (typeof languages === 'object') languages = languages.join(', ');
    if (typeof desiredCities === 'object') desiredCities = desiredCities.join(', ');
    if (typeof skills === 'object') {
      skills = skills
        .filter((skill) => {
          if (skill.status === Constants.status.APPROVED) return true;
          return false;
        })
        .map((skill) => skill.name)
        .join(', ');
    }

    if (typeof systems === 'object') {
      systems = systems
        .filter((system) => {
          if (system.status === Constants.status.APPROVED) return true;
          return false;
        })
        .map((system) => system.name)
        .join(', ');
    }

    const show = gigger.expire || isGigger() || isAdmin();
    const createAccountText = (isGigger() || isAdmin()) ? (
      Constants.language.customer_details_create_customer_account
    ) : (
      Constants.language.common_create_an_account
    );
    const feedBackContainer = (
      <div>
        {loggedIn && !isGigger() ? (
          <>
            <Row>
              <div className="col-10 p-0">
                {!loading && (
                  <SideHeading color>
                    {`${Constants.language.customer_details_customers_feedback} (${gigger.feedbackCount})`}
                  </SideHeading>
                )}
              </div>
              <>
                {gigger && gigger.feedbackCount > 0 && (
                  <div className="col-2 p-0 d-flex justify-content-end">
                    {!loading && (
                    <>
                      <Image
                        src={Constants.icons.Star}
                        alt={Constants.icons.Star}
                        style={{ paddingRight: '8px' }}
                        width="20px"
                        height="20px"
                      />
                      <>
                        <ResumeContent>
                          {`${feedbackAvg !== null ? feedbackAvg.toFixed(1) : 0} (${gigger.feedbackCount})`}
                        </ResumeContent>
                      </>
                    </>
                    )}
                  </div>
                )}
              </>
            </Row>

            {!loading && gigger.feedbacks && gigger.feedbacks.length > 0 ? (
              <FeedbackContainer>
                {gigger.feedbacks.map((feedback, index) => {
                  if (loadFeedback) {
                    return (
                      <FeedbackCard>
                        <AccordionBody feedback data={feedback} />
                      </FeedbackCard>
                    );
                  }
                  if (index < 6) {
                    return (
                      <FeedbackCard>
                        <AccordionBody feedback data={feedback} />
                      </FeedbackCard>
                    );
                  }
                  return '';
                })}
                {!loadFeedback && gigger.feedbacks && gigger.feedbacks.length > 6 && (
                  <ShowAllButton onClick={this.handleLoadMoreButton}>
                    <span role="button">
                      <img src={Constants.icons.ArrowDown} alt="icon" className="mr-2" />
                      <Line>
                        {`${Constants.language.common_show_all} (${gigger.feedbacks.length - 6})`}
                      </Line>
                    </span>
                  </ShowAllButton>
                )}
              </FeedbackContainer>
            ) : (
              <>
                {!loading && (
                <div className="my-3">
                  <WhiteCard className="p-3">
                    <FormattedMessage
                      id="customer_details_no_feedbacks_so_far"
                      defaultMessage="No feedbacks so far.."
                    />
                  </WhiteCard>
                </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {!loading && (
              <CreateAccountCard>
                <Row>
                  <div className="col-lg-8">
                    <p>
                      {`${createAccountText} ${Constants.language.customer_details_get_access_to_gigger}`}
                    </p>
                  </div>
                  <Desktop className="col-lg-4">
                    <Button onClick={this.redirectToSignUpPage}>
                      <FormattedMessage
                        id="customer_details_join_gigassembly"
                        defaultMessage="Join GigAssembly"
                      />
                    </Button>
                  </Desktop>
                  <IsMobile className="d-flex flex-fill pt-4 pt-lg-0 d-lg-none">
                    <Button onClick={
                      this.redirectToSignUpPage
                    }
                    >
                      <FormattedMessage
                        id="customer_details_join_gigassembly"
                        defaultMessage="Join GigAssembly"
                      />
                    </Button>
                  </IsMobile>
                </Row>
              </CreateAccountCard>
            )}
          </>
        )}
      </div>
    );
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <a
          href
          data-backdrop="static"
          data-keyboard="false"
          data-toggle="modal"
          data-target="#CompanyModal"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {limitOverModal && (
          <Modal
            limitOverModal={limitOverModal}
            ModalClose={this.handleToastClose}
          />
        )}
        {BuyContactOpen && (
          <>
            <IsDesktop className="d-inline-block">
              <BuyContact
                open={BuyContactOpen}
                closeSlider={this.closeSlider}
                refresh={this.refresh}
                type={orderType}
                amount={amount}
                userId={user._id}
                giggerId={gigger.id}
                redirectToSignUpPage={this.redirectToSignUpPage}
                showLimitPopup={this.handleOpenLimitPopup}
              />
            </IsDesktop>
            <IsMobile>
              <a
                href
                data-toggle="modal"
                data-target="#FilterModal"
                className=""
                ref={(openFilterModal) => {
                  this.openElement = openFilterModal;
                }}
              />
              <div>
                <BuyContactForMobile
                  open={BuyContactOpen}
                  closeSlider={this.closeSlider}
                  refresh={this.refresh}
                  type={orderType}
                  amount={amount}
                  userId={user._id}
                  giggerId={gigger.id}
                  redirectToSignUpPage={this.redirectToSignUpPage}
                  showLimitPopup={this.handleOpenLimitPopup}
                />
              </div>
            </IsMobile>
          </>
        )}
        <RelativeContainer>
          {showToast && (
            <Toast />
          )}
          <FlexContainer>
            <ProfileViewContainer>
              {!loading && (
                <Row className="mb-2">
                  <SaveGiggerWrapper onClick={this.goBack}>
                    <img src={Constants.icons.ArrowLeft} alt="icon" className="mr-2 cursor-pointer" />
                    <Line role="button">
                      <FormattedMessage id="common_back" defaultMessage="Back" />
                    </Line>
                  </SaveGiggerWrapper>
                </Row>
              )}
              <Row>
                <DetailsLeft>
                  {!loading && (
                    <div className="mb-3">
                      <>
                        <Image
                          src={Constants.icons.Star}
                          alt={Constants.icons.Star}
                          style={{ paddingRight: '8px', marginBottom: '2px' }}
                          width="20px"
                          height="20px"
                        />
                        <>
                          {`${feedbackAvg !== null ? feedbackAvg && feedbackAvg.toFixed(1) : 0} (${gigger.feedbackCount
                          })`}
                        </>
                      </>
                      {!isGigger()
                        && !isAdmin()
                        && loggedIn && (
                          <>
                            {gigger.type ? (
                              <SaveGiggerWrapper>
                                <FormattedMessage id="customer_details_profile_saved" defaultMessage="Profile saved" />
                              </SaveGiggerWrapper>
                            ) : (
                              <SaveGiggerWrapper onClick={this.handleSaveGigger(giggerId)} role="button">
                                <span>
                                  <img src={Constants.icons.Plus} alt="icon" className="mr-1" />
                                </span>
                                <FormattedMessage id="customer_details_save_gigger" defaultMessage="Save gigger" />
                              </SaveGiggerWrapper>
                            )}
                          </>
                      )}
                    </div>
                  )}
                  <ProfileImageWrapper>
                    {loading && <Loader width="100%" height="200px" />}
                    {!loading && (
                      <>
                        {gigger.profile_pic && gigger.profile_pic.url ? (
                          show ? (
                            <BlurImage
                              className="image-crop-no-image"
                              style={{
                                backgroundImage: `url("${gigger.profile_pic ? gigger.profile_pic.url : profilePic}")`,
                                backgroundSize: 'cover !important',
                              }}
                            />
                          ) : (
                            <ProfileImage>
                              <div
                                className="image-crop-no-image"
                                style={{
                                  backgroundImage: `url("${gigger.profile_pic.url}")`,
                                  backgroundSize: 'cover !important',
                                }}
                              />
                            </ProfileImage>
                          )
                        ) : (
                          <ProfileImage>
                            <div
                              className="image-crop-no-image"
                              style={{
                                backgroundImage: `url("${DefaultProfile}")`,
                                backgroundSize: 'cover !important',
                              }}
                            />
                          </ProfileImage>
                        )}
                      </>
                    )}
                    {statusPublished && (
                      <ProfileStatus>
                        <FormattedMessage id="customer_details_profile_published" defaultMessage="Profile Published" />
                      </ProfileStatus>
                    )}
                  </ProfileImageWrapper>

                  {loading ? (
                    loading && <Loader width="100%" height="160px" />
                  ) : show ? (
                    <>
                      {!loggedIn && (
                        <div className="row m-0 my-3 pb-1">
                          <DetailCheckButton
                            onClick={this.redirectToSignUpPage}
                          >
                            <FormattedMessage
                              id="customer_details_create_an_account"
                              defaultMessage="Create account"
                            />
                          </DetailCheckButton>
                        </div>
                      )}
                      <ResumeContent customPadding className="text-center mb-3">
                        {gigger.year_of_experience !== '' && (
                        <>
                          {gigger.year_of_experience === '0-3' ? (
                            <JuniorLevelTag>
                              <FormattedMessage id="common_junior" defaultMessage="Junior" />
                            </JuniorLevelTag>
                          ) : gigger.year_of_experience === '4-10' ? (
                            <ExperienceLevelTag>
                              <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                            </ExperienceLevelTag>
                          ) : (
                            <MidLevelTag>
                              <FormattedMessage id="common_senior" defaultMessage="Senior" />
                            </MidLevelTag>
                          )}
                        </>
                        )}
                        {gigger.year_of_experience && `${gigger.year_of_experience} ${Constants.language.common_years}`}
                      </ResumeContent>
                      <Card
                        customerDetailPinkCard
                        header={[
                          <WelcomeTextHeader fontSizeLarge>
                            <FormattedMessage id="customer_details_how_it_works" defaultMessage="How it works" />
                          </WelcomeTextHeader>,
                        ]}
                        description={[
                          <>
                            <WelcomeText className="text-break text-center pt-3">
                              <ProfileIcon />
                              <P className="mb-3" Black>
                                <FormattedMessage id="how_it_works_1" />
                              </P>
                              <TickmarkIcon />
                              <P className="mb-3" Black>
                                <FormattedMessage id="how_it_works_2" />
                              </P>
                              <CreditcardIcon />
                              <P className="mb-3" Black>
                                <FormattedMessage id="how_it_works_3" />
                              </P>
                            </WelcomeText>
                          </>,
                        ]}
                      />
                      <Card className="px-3 py-4 my-4">
                        <ProfileViews className="my-2 py-1 text-center">
                          <WelcomeTextHeader fontSizeLarge fontWeightSemibold>
                            <FormattedMessage
                              id="label_price"
                              defaultMessage="Price"
                            />
                          </WelcomeTextHeader>
                        </ProfileViews>
                        <ProfileViews className="mb-4 text-center">
                          <WelcomeTextContent>
                            {Constants.language.common_buy_contact}
                          </WelcomeTextContent>
                          <WelcomeTextContent fontWeightBold="700">100 kr</WelcomeTextContent>
                          <WelcomeTextContent className="pt-3">
                            {Constants.language.label_hire_consultant}
                          </WelcomeTextContent>
                          <WelcomeTextContent lightBlueColor={Colors.LightBlue}>
                            {`> 3 ${Constants.language.label_months}`}
                          </WelcomeTextContent>
                          <WelcomeTextContent fontWeightBold="700">25 000 kr</WelcomeTextContent>
                          <WelcomeTextContent className="pt-3">
                            {Constants.language.label_hire_consultant}
                          </WelcomeTextContent>
                          <WelcomeTextContent lightBlueColor={Colors.LightBlue}>
                            {`< 3 ${Constants.language.label_months}`}
                          </WelcomeTextContent>
                          <A textTransformNone href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].aboutUsWithContact}`}>
                            <WelcomeTextContent fontWeightBold="700">{`${Constants.language.common_contact_us}`}</WelcomeTextContent>
                          </A>
                        </ProfileViews>
                        <OrderServicesHeader>
                          <FormattedMessage
                            id="label_additional_services"
                            defaultMessage="Additional services"
                          />
                        </OrderServicesHeader>
                        <ProfileViews className="mb-2 text-center">
                          <WelcomeTextContent>
                            {Constants.language.label_competence_based}
                          </WelcomeTextContent>
                          <WelcomeTextContent>
                            {Constants.language.label_interview}
                          </WelcomeTextContent>
                          <WelcomeTextContent fontWeightBold="700">30 000 kr</WelcomeTextContent>
                          <WelcomeTextContent className="pt-3">
                            {Constants.language.common_reference_check}
                          </WelcomeTextContent>
                          <WelcomeTextContent fontWeightBold="700">5 000 kr</WelcomeTextContent>
                        </ProfileViews>
                      </Card>
                    </>
                  ) : (
                    <>
                      <>
                        <UserName>{gigger.full_name}</UserName>
                        <ResumeContent customPadding className="text-center mb-3">
                          {gigger.year_of_experience !== '' && (
                          <>
                            {gigger.year_of_experience === '0-3' ? (
                              <JuniorLevelTag customPadding>
                                <FormattedMessage id="common_junior" defaultMessage="Junior" />
                              </JuniorLevelTag>
                            ) : gigger.year_of_experience === '4-10' ? (
                              <ExperienceLevelTag>
                                <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                              </ExperienceLevelTag>
                            ) : (
                              <MidLevelTag>
                                <FormattedMessage id="common_senior" defaultMessage="Senior" />
                              </MidLevelTag>
                            )}
                          </>
                          )}
                          {gigger.year_of_experience && `${gigger.year_of_experience} ${Constants.language.common_years}`}
                        </ResumeContent>
                        <>
                          <Div className="mb-4 ml-0">
                            <Card
                              reminder
                              pinkCard
                              removeDeleteButton
                              header={[
                                <WelcomeTextHeader>
                                  <FormattedMessage
                                    id="customer_details_contact_details_available"
                                    defaultMessage="Contact details available"
                                  />
                                </WelcomeTextHeader>,
                              ]}
                              description={[
                                <>
                                  <WelcomeText className="text-break">
                                    <p>
                                      <FormattedMessage
                                        id="common_details_saved_on"
                                        defaultMessage="Contact details have been saved to"
                                      />
                                      {' '}
                                      <WhiteButton onClick={this.handleMyAccount} className="cursor-pointer">
                                        <FormattedMessage id="common_my_account" defaultMessage="My account" />
                                      </WhiteButton>
                                      {' '}
                                      <FormattedMessage
                                        id="customer_details_and_available_for_info"
                                        defaultMessage="and will be available for 30 days."
                                      />
                                    </p>
                                  </WelcomeText>
                                </>,
                              ]}
                            />
                          </Div>
                          <Div className="mb-4">
                            <Card
                              customerDetailPinkCard
                              header={[
                                <WelcomeTextHeader fontSizeLarge>
                                  <FormattedMessage id="customer_details_how_it_works" defaultMessage="How it works" />
                                </WelcomeTextHeader>,
                              ]}
                              description={[
                                <>
                                  <WelcomeText className="text-break text-center pt-3">
                                    <ProfileGreyIcon />
                                    <P className="mb-3" LightGrey>
                                      <FormattedMessage id="how_it_works_1" defaultMessage="Buy contact information" />
                                    </P>
                                    <TickmarkIcon />
                                    <P className="mb-3" Black>
                                      <FormattedMessage id="how_it_works_2" defaultMessage="Hire consulting information" />
                                    </P>
                                    <CreditcardIcon />
                                    <P className="mb-3" Black>
                                      <FormattedMessage id="how_it_works_3" defaultMessage="Pay fixed fee" />
                                    </P>
                                  </WelcomeText>
                                </>,
                              ]}
                            />
                          </Div>
                          <Card className="px-3 py-4 my-4">
                            <ProfileViews className="my-2 py-1 text-center">
                              <WelcomeTextHeader fontSizeLarge fontWeightSemibold>
                                <FormattedMessage
                                  id="label_price"
                                  defaultMessage="Price"
                                />
                              </WelcomeTextHeader>
                            </ProfileViews>
                            <ProfileViews className="mb-4 text-center">
                              <WelcomeTextContent>
                                {Constants.language.common_buy_contact}
                              </WelcomeTextContent>
                              <WelcomeTextContent fontWeightBold="700">100 kr</WelcomeTextContent>
                              <WelcomeTextContent className="pt-3">
                                {Constants.language.label_hire_consultant}
                              </WelcomeTextContent>
                              <WelcomeTextContent lightBlueColor={Colors.LightBlue}>
                                {`> 3 ${Constants.language.label_months}`}
                              </WelcomeTextContent>
                              <WelcomeTextContent fontWeightBold="700">25 000 kr</WelcomeTextContent>
                              <WelcomeTextContent className="pt-3">
                                {Constants.language.label_hire_consultant}
                              </WelcomeTextContent>
                              <WelcomeTextContent lightBlueColor={Colors.LightBlue}>
                                {`< 3 ${Constants.language.label_months}`}
                              </WelcomeTextContent>
                              <A textTransformNone href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].aboutUsWithContact}`}>
                                <WelcomeTextContent fontWeightBold="700">{`${Constants.language.common_contact_us}`}</WelcomeTextContent>
                              </A>
                            </ProfileViews>
                          </Card>
                          <Card>
                            <OrderServicesHeader>
                              <FormattedMessage
                                id="label_additional_services"
                                defaultMessage="Additional services"
                              />
                            </OrderServicesHeader>
                            <ProfileViews className="text-center">
                              <WelcomeTextContent>
                                {Constants.language.label_competence_based}
                              </WelcomeTextContent>
                              <WelcomeTextContent>
                                {Constants.language.label_interview}
                              </WelcomeTextContent>
                              <WelcomeTextContent fontWeightBold="700">30 000 kr</WelcomeTextContent>
                            </ProfileViews>
                            <div className="row m-0 mb-4">
                              <Desktop className="col-12 p-0">
                                <Button
                                  customWidth="160px"
                                  className="mt-2 mx-auto"
                                  onClick={this.buyContactOpen(Constants.order.INTERVIEW)}
                                  disabled={gigger && gigger.existingOrders
                                    && gigger.existingOrders.interview}
                                >
                                  <FormattedMessage id="label_order" defaultMessage="Order" />
                                </Button>
                              </Desktop>
                              <Mobile className="col-12 p-0">
                                <Button
                                  customWidth="160px"
                                  className="mt-2 mx-auto"
                                  onClick={this.buyContactModalOpen(Constants.order.INTERVIEW)}
                                  disabled={gigger && gigger.existingOrders
                                    && gigger.existingOrders.interview}
                                >
                                  <FormattedMessage id="label_order" defaultMessage="Order" />
                                </Button>
                              </Mobile>
                            </div>
                            <ProfileViews className="text-center">
                              <WelcomeTextContent className="pt-3">
                                {Constants.language.common_reference_check}
                              </WelcomeTextContent>
                              <WelcomeTextContent fontWeightBold="700">5 000 kr</WelcomeTextContent>
                            </ProfileViews>
                            <div className="row m-0">
                              <Mobile className="col-12 p-0">
                                <Button
                                  className="mt-2 mx-auto"
                                  customWidth="160px"
                                  onClick={this.buyContactModalOpen(
                                    Constants.order.REFERENCE_CHECK,
                                  )}
                                  disabled={gigger && gigger.existingOrders
                                    && gigger.existingOrders.reference_check}
                                >
                                  <FormattedMessage id="label_order" defaultMessage="Order" />
                                </Button>
                              </Mobile>
                              <Desktop className="col-12 p-0">
                                <Button
                                  className="mt-2 mx-auto"
                                  customWidth="160px"
                                  onClick={this.buyContactOpen(Constants.order.REFERENCE_CHECK)}
                                  disabled={gigger && gigger.existingOrders
                                    && gigger.existingOrders.reference_check}
                                >
                                  <FormattedMessage id="label_order" defaultMessage="Order" />
                                </Button>
                              </Desktop>
                            </div>
                          </Card>
                        </>
                      </>
                    </>
                  )}
                </DetailsLeft>
                <DetailsRight>
                  {loading && <Loader width="75%" height="40px" />}
                  {loading && <Loader width="50%" height="24px" />}
                  {!loading && <JobTitle>{gigger.job_title}</JobTitle>}
                  {!loading && (
                    <JobPrice>
                      {`${gigger.hourly_rate} kr`}
                      /
                      <FormattedMessage id="common_hour" defaultMessage="hour" />
                    </JobPrice>
                  )}
                  <Card className="p-0">
                    {loading ? (
                      loading && <Loader width="100%" height="160px" />
                    ) : show ? (
                      <Div className="p-5 my-lg-4 py-lg-3 ml-lg-4 pl-lg-2 text-center text-lg-left">
                        <ContactDescription>
                          <FormattedMessage
                            id="customer_details_create_and_buy_ful_profile_info"
                            defaultMessage={`and buy full profile to view contact details and uploaded documents for
                          ${Constants.price[Constants.order.BUY_CONTACT]} kr`}
                            values={{ break: <br /> }}
                          />
                        </ContactDescription>
                        <BuyContactButton
                          onClick={this.buyContactOpen(Constants.order.BUY_CONTACT)}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Lock className="mb-1" />
                          <Span className="ml-2 text-white" fontSizeRegular>
                            <FormattedMessage
                              id="customer_buy_contact_details"
                              defaultMessage="Buy contact details"
                            />
                          </Span>
                        </BuyContactButton>
                      </Div>
                    ) : (
                      <div className="my-4">
                        <Row>
                          <ProfileData className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
                            <Row>
                              <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-truncate">
                                <SubHeader>
                                  <FormattedMessage id="common_email" defaultMessage="Email" />
                                </SubHeader>
                                <ResumeContent>{gigger.email}</ResumeContent>
                              </div>
                            </Row>
                            <Row className="mt-4">
                              <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-truncate">
                                <SubHeader>
                                  <FormattedMessage id="common_phone" defaultMessage="Phone" />
                                </SubHeader>
                                <ResumeContent>{gigger.phone}</ResumeContent>
                              </div>
                            </Row>
                          </ProfileData>
                          <ProfileData className="col-xl-6 col-md-6 col-sm-12 col-xs-12">
                            <SubHeader>
                              <Paperclip width="20px" height="20px" className="mb-1 mr-1" />
                              <FormattedMessage id="common_documents" defaultMessage="Documents" />
                            </SubHeader>
                            {gigger.docs
                              && gigger.docs.length > 0
                              && gigger.docs.map(
                                (doc) => doc
                                  && doc.url && displaydocTypes.includes(doc.category) && (
                                    <ResumeContent className="m-0 text-truncate">
                                      <a href={doc.url} download={doc.file_name} className="color-black" target="_blank" rel="noreferrer">
                                        <Download width="20px" height="20px" className="mb-1 mr-1" />
                                        {doc.file_name}
                                      </a>
                                    </ResumeContent>
                                ),
                              )}
                          </ProfileData>
                        </Row>
                      </div>
                    )}
                  </Card>
                  <Card>
                    <Row>
                      <div className="col mt-3">
                        <ResumeBlock>
                          {loading ? (
                            <Loader width="100%" height="160px" />
                          ) : (
                            <>
                              <SideHeading>
                                <FormattedMessage id="common_summary" defaultMessage="Summary" />
                              </SideHeading>
                              {loggedIn && isCustomer() ? (
                                <EducationTitle color>
                                  {gigger.summary ? gigger.summary : ''}
                                </EducationTitle>
                              ) : (
                                <PinkArrowCard>
                                  <p className="m-0">
                                    {' '}
                                    <IsMobile>
                                      <A
                                        onClick={
                                          this.buyContactModalOpen(Constants.order.BUY_CONTACT)
                                        }
                                      >
                                        {show && loggedIn ? (
                                          <FormattedMessage
                                            id="customer_details_buying_contact"
                                            defaultMessage="buying contact"
                                          />
                                        ) : (
                                          <FormattedMessage
                                            id="customer_details_create_an_account"
                                            defaultMessage="creating an account."
                                          />
                                        )}
                                      </A>
                                    </IsMobile>
                                    <IsDesktop>
                                      <A onClick={this.buyContactOpen(Constants.order.BUY_CONTACT)}>
                                        {show && loggedIn ? (
                                          <FormattedMessage
                                            id="customer_details_buying_contact"
                                            defaultMessage="buying contact"
                                          />
                                        ) : (
                                          <FormattedMessage
                                            id="customer_details_create_an_account"
                                            defaultMessage="creating an account."
                                          />
                                        )}
                                      </A>
                                    </IsDesktop>
                                    {' '}
                                    <FormattedMessage id="customer_details_see_summary" defaultMessage="to see summary" />
                                  </p>
                                </PinkArrowCard>
                              )}
                            </>
                          )}
                        </ResumeBlock>

                        <ResumeBlock>
                          {loading ? (
                            <Loader width="100%" height="50px" />
                          ) : (
                            <>
                              <SideHeading>
                                <FormattedMessage id="common_systems" defaultMessage="Systems" />
                              </SideHeading>
                              <ResumeContentCapitalize>{systems || ''}</ResumeContentCapitalize>
                            </>
                          )}
                        </ResumeBlock>

                        <ResumeBlock>
                          {loading ? (
                            <Loader width="100%" height="50px" />
                          ) : (
                            <>
                              <SideHeading>
                                <FormattedMessage id="common_skills" defaultMessage="Skills" />
                              </SideHeading>
                              <ResumeContentCapitalize>{skills || ''}</ResumeContentCapitalize>
                            </>
                          )}
                        </ResumeBlock>
                        <ResumeBlock className="mb-3">
                          {loading ? (
                            <Loader width="100%" height="160px" />
                          ) : (
                            <>
                              <SideHeading className="mb-3">
                                <FormattedMessage id="common_previos_jobs" defaultMessage="Previous jobs" />
                              </SideHeading>
                              <Row className="line-dot-wrapper">
                                {previousJobs && previousJobs.length
                                  ? previousJobs
                                    .filter((item, index) => showMoreJobs || (!showMoreJobs
                                    && index < 3))
                                    .map((element, index = 0) => (
                                      <>
                                        <Div className="lines d-flex col-12 p-0 pb-3 pb-lg-4" key={index}>
                                          <Div className="knob ml-4" />
                                          <Div className="text-right" customWidth="93px">
                                            <Desktop>
                                              <ResumeContentCapitalize fontWeightNormal>
                                                {element.start_date && element.end_date
                                                  ? `${element.start_date} - ${element.end_date}`
                                                  : element.start_date ? element.start_date : element.end_date ? element.end_date : ''}
                                              </ResumeContentCapitalize>
                                            </Desktop>
                                          </Div>
                                          <Div className="d-block">
                                            <Mobile>
                                              <ResumeContentCapitalize fontWeightNormal className="pl-4 mb-1">
                                                {element.start_date && element.end_date
                                                  ? `${element.start_date} - ${element.end_date}`
                                                  : element.start_date ? element.start_date : element.end_date ? element.end_date : ''}
                                              </ResumeContentCapitalize>
                                            </Mobile>
                                            {element.title && (
                                            <Div className="d-inline-flex mb-1 mb-lg-2">
                                              <EducationTitle fontSize={`${Fonts.fontSize.Size18}px`} semiBold className="ml-lg-2 pl-4">{element.title}</EducationTitle>
                                            </Div>
                                            )}
                                            {element.company_name && (
                                            <Div>
                                              <ResumeContentCapitalize fontWeightNormal className="ml-lg-2 pl-4">{!show && element.company_name}</ResumeContentCapitalize>
                                            </Div>
                                            )}
                                          </Div>
                                          <Div className={index !== 2 && 'line ml-4'} />
                                          <Div className={showMoreJobs && index === 2 && 'line ml-4'} />
                                        </Div>
                                      </>
                                    ))
                                  : (
                                    <ResumeContent>
                                      <FormattedMessage
                                        id="empty_job_experience"
                                        defaultMessage="The consultant has chosen to only state work experience in the uploaded document that is available after purchasing contact information"
                                      />
                                    </ResumeContent>
                                  )}
                                {previousJobs
                                  && previousJobs.length > 3 && !showMoreJobs && (
                                  <Div className="mt-4 mt-lg-0 pb-4">
                                    <A onClick={this.handleShowMoreJobs}>
                                      <ShowMoreLink fontSize={`${Fonts.fontSize.Size18}px`}>
                                        {`${Constants.language.label_show_more} (${(previousJobs.length - 3)})`}
                                      </ShowMoreLink>
                                    </A>
                                  </Div>
                                )}
                              </Row>
                            </>
                          )}
                        </ResumeBlock>
                        <ResumeBlock className="mb-3">
                          {loading ? (
                            <Loader width="100%" height="160px" />
                          ) : (
                            <>
                              <SideHeading className="mb-3">
                                <FormattedMessage id="common_education" defaultMessage="Education" />
                              </SideHeading>
                              <Row className="line-dot-wrapper">
                                {education && education.length
                                  ? education
                                    .filter((item, index) => showMoreEducation
                                    || (!showMoreEducation && index < 3))
                                    .map((element, index = 0) => (
                                      <Div className="lines d-flex col-12 p-0 pb-3 pb-lg-4" key={index}>
                                        <Div className="knob ml-4" />
                                        <Div className="text-right" customWidth="93px">
                                          <Desktop>
                                            <ResumeContentCapitalize fontWeightNormal>
                                              {element.start_date && element.end_date
                                                ? `${element.start_date} - ${element.end_date}`
                                                : element.start_date
                                                  ? element.start_date : element.end_date}
                                            </ResumeContentCapitalize>
                                          </Desktop>
                                        </Div>
                                        <Div className="d-block">
                                          <Mobile>
                                            <ResumeContentCapitalize fontWeightNormal className="pl-4 mb-1">
                                              {element.start_date && element.end_date
                                                ? `${element.start_date} - ${element.end_date}`
                                                : element.start_date ? element.start_date : element.end_date ? element.end_date : '' }
                                            </ResumeContentCapitalize>
                                          </Mobile>
                                          {element.institution_name && (
                                          <Div className="d-inline-flex mb-1 mb-lg-2">
                                            <EducationTitle fontSize={`${Fonts.fontSize.Size18}px`} semiBold className="ml-lg-2 pl-4">{element.institution_name}</EducationTitle>
                                          </Div>
                                          )}
                                          {element.degree && (
                                          <Div>
                                            <ResumeContentCapitalize fontWeightNormal className="ml-lg-2 pl-4">{element.degree}</ResumeContentCapitalize>
                                          </Div>
                                          )}
                                        </Div>
                                        <Div className={index !== 2 && 'line ml-4'} />
                                        <Div className={showMoreEducation && index === 2 && 'line ml-4'} />
                                      </Div>
                                    )) : (
                                      <ResumeContent>
                                        <FormattedMessage
                                          id="empty_education_details"
                                          defaultMessage="The consultant has chosen to only state training in uploaded documents that are available after the purchase of contact information"
                                        />
                                      </ResumeContent>
                                  )}
                                {education
                                  && education.length > 3 && !showMoreEducation && (
                                  <Div className="mt-4 mt-lg-0 pb-4">
                                    <A onClick={this.handleShowMoreEducation}>
                                      <ShowMoreLink fontSize={`${Fonts.fontSize.Size18}px`}>
                                        {`${Constants.language.label_show_more} (${(education.length - 3)})`}
                                      </ShowMoreLink>
                                    </A>
                                  </Div>
                                )}
                              </Row>
                            </>
                          )}
                        </ResumeBlock>

                        <ResumeBlock>
                          <Row>
                            <Div className="col-12 p-0">
                              {loading ? (
                                <Loader width="80%" height="50px" />
                              ) : (
                                <>
                                  <SideHeading>
                                    <FormattedMessage id="common_languages" defaultMessage="Languages" />
                                  </SideHeading>
                                  <ResumeContentCapitalize>
                                    {languages}
                                  </ResumeContentCapitalize>
                                </>
                              )}
                            </Div>
                          </Row>
                        </ResumeBlock>
                        <ResumeBlock className="mb-5">
                          {loading ? (
                            <Loader width="100%" height="24px" />
                          ) : (
                            <>
                              <SideHeading>
                                <FormattedMessage id="desired_city" defaultMessage="Desired city for work" />
                              </SideHeading>

                              <ResumeContentCapitalize>
                                {desiredCities && (
                                  <>
                                    <img
                                      src={Constants.icons.PinEmpty}
                                      alt="icon"
                                      width="14px"
                                      height="14px"
                                      className="mr-1 mb-1"
                                    />
                                    <Address>{desiredCities}</Address>
                                  </>
                                )}
                              </ResumeContentCapitalize>
                            </>
                          )}
                        </ResumeBlock>
                        <ResumeBlock className="mb-5">
                          {loading ? (
                            <Loader width="100%" height="200px" />
                          ) : (
                            <>
                              <SideHeading>
                                <FormattedMessage id="common_work_availability" defaultMessage="Work availability" />
                                :
                              </SideHeading>
                              <ResumeContentCapitalize>
                                {Constants.profile[gigger.work_availability]}
                              </ResumeContentCapitalize>
                              {loggedIn && !isGigger() ? (
                                <CustomCalender
                                  monthsToDisplay={3}
                                  dates={gigger.work_availability_date}
                                />
                              ) : (
                                <PinkArrowCard>
                                  <p className="m-0">
                                    <IsDesktop>
                                      {' '}
                                      <A onClick={this.buyContactOpen(Constants.order.BUY_CONTACT)}>
                                        {show && loggedIn ? (
                                          <FormattedMessage
                                            id="customer_details_buying_contact"
                                            defaultMessage="buying contact"
                                          />
                                        ) : (
                                          <FormattedMessage
                                            id="customer_details_create_an_account"
                                            defaultMessage="creating an account."
                                          />
                                        )}
                                      </A>
                                    </IsDesktop>
                                    <IsMobile>
                                      {' '}
                                      <A onClick={
                                        this.buyContactModalOpen(Constants.order.BUY_CONTACT)
                                      }
                                      >
                                        {show && loggedIn ? (
                                          <FormattedMessage
                                            id="customer_details_buying_contact"
                                            defaultMessage="buying contact"
                                          />
                                        ) : (
                                          <FormattedMessage
                                            id="customer_details_create_an_account"
                                            defaultMessage="creating an account."
                                          />
                                        )}
                                      </A>
                                    </IsMobile>
                                    {' '}
                                    <FormattedMessage
                                      id="customer_details_see_availability"
                                      defaultMessage="to see availability"
                                    />
                                  </p>
                                </PinkArrowCard>
                              )}
                            </>
                          )}
                        </ResumeBlock>
                      </div>
                    </Row>
                  </Card>

                  <Card className="p-0 bg-transparent">
                    <Row>
                      <div className="col mt-3">
                        <ResumeBlock>
                          {!loggedIn && !loading && (
                          <>
                            <SideHeading>
                              <FormattedMessage
                                id="customer_details_customers_feedback"
                                defaultMessage="Customer’s feedback"
                              />
                              (
                              {`(${gigger.feedbackCount})`}
                              )
                            </SideHeading>
                            <PinkArrowCard>
                              <p className="m-0">
                                <A onClick={
                                    this.buyContactModalOpen(Constants.order.BUY_CONTACT)
                                  }
                                >
                                  <FormattedMessage
                                    id="customer_details_create_an_account"
                                    defaultMessage="creating an account"
                                  />
                                </A>

                                {' '}
                                <IsMobile>
                                  <FormattedMessage
                                    id="customer_details_information_available_after"
                                    defaultMessage="Information available after"
                                  />
                                </IsMobile>
                                <IsDesktop>
                                  <FormattedMessage
                                    id="customer_details_information_available_after"
                                    defaultMessage="Information available after"
                                  />
                                </IsDesktop>
                              </p>
                            </PinkArrowCard>
                          </>
                          )}
                          {loggedIn && gigger.feedbacks && feedBackContainer}
                        </ResumeBlock>
                      </div>
                    </Row>
                  </Card>

                </DetailsRight>
              </Row>
            </ProfileViewContainer>
          </FlexContainer>
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}
export default CustomerDetailView;
