import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import TextBox from '../Input/TextBox';
import {
  BuyContactContainer,
  Div,
  BuyContactHeader,
  BuyContactHeaderBold,
  BuyContactSubHeader,
  Header,
  InvoiceHeader,
  Span,
  Row,
  A,
} from '../../pages/gigger/styles';
import { SaveGiggerWrapper } from '../../pages/customer/styles';
import { Line } from '../../styles/CommonStyles';
import Button from '../Button/Button';
import CustomCheckBox from '../Input/CustomCheckbox';
import Constants from '../../shared/constants';
import GiggerService from '../../services/GiggerService';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import { FilterButton, Span as ButtonName } from '../Filter/styles';
import Loader from '../Loader/Loader';
import PrivacyPolicy from '../RedirectLinks/PrivacyPolicy';
import TermsAndConditions from '../RedirectLinks/TermsAndConditions';
import Colors from '../../styles/Colors';
import { isCustomer, isGigger, isLoggedIn } from '../../shared/utils';

class BuyContactForm extends Component {
  giggerService = new GiggerService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      cost: '',
      amount: props.amount,
      formValid: {
        email_isValid: '',
      },
      loggedIn: isLoggedIn(),
      isDisabled: true,
      isSelected: false,
      loading: false,
      saveDetails: false,
      emailError: false,
    };
  }

  componentDidMount() {
    this.fetchUserDetails();
  }

  /**
   *
   * @async
   * @function fetchUserDetails Fetches user details
   *
   */
  fetchUserDetails = async () => {
    this.setState({ loading: true });
    try {
      const response = await this.customerService.userDetails();
      if (response) {
        const { data = {} } = response;
        const { status, invoiceDetails = {} } = data;
        const {
          cost_center: cost = '',
        } = invoiceDetails || {};
        const { invoice_email: email = '' } = data || {};
        this.setState(
          {
            status,
            email: email || '',
            cost: cost || '',
            saveDetails: !!(invoiceDetails),
            loading: false,
          },
          this.validate,
        );
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   *
   * Closes slider or modal
   *
   */
  closeSlider = () => {
    const { closeSlider } = this.props;
    closeSlider();
  };

  /**
   *
   * Redirects to signup page
   *
   */
  redirectToSignUpPage = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.customerSignUpWithOutBankId.url });
    // history.push({ pathname: Constants.routes.customerSignUp.url });
  };

  /**
   *
   * @async
   * @function handleSubmit Places the order
   *
   */
  handleSubmit = async () => {
    const {
      email, cost, amount, saveDetails,
    } = this.state;
    const {
      userId, giggerId, type, refresh, closeSlider, showLimitPopup,
    } = this.props;
    const data = {
      user_id: userId,
      gigger_id: giggerId,
      type,
      email,
      cost_center: cost,
      amount,
      saveForLater: saveDetails,
    };
    let emailError = false;
    this.setState({ loading: true, emailError });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.giggerService.getContacts(data);

      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_order_completed;
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
      }
      refresh(toastData);
      this.setState({ loading: false });
    } catch (e) {
      const { response = {} } = e;
      const { data: errorData = [] } = response;
      emailError = errorData.errors && errorData.errors.email;
      this.setState({ loading: false, emailError });
      if (errorData && errorData.message === Constants.limitExceeded) {
        showLimitPopup();
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        closeSlider(toastData);
      }
      this.setState({ loading: false });
    }
  };

  /**
   *
   * Handles change in input value
   * @param {event} event
   *
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  };

  /**
   *
   * Checks in or out by agreeing terms.
   *
   */
  handleAgreeTerms = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected }, this.validate);
  };

  /**
   *
   * Checks save invoice details to true or false
   *
   */
  handleSaveInvoiceDetails = () => {
    const { saveDetails } = this.state;
    this.setState({ saveDetails: !saveDetails });
  };

  /**
   *
   * Handles key press event
   * @param {event} evt
   *
   */
  handleKeyPress=(evt) => {
    const theEvent = evt || window.event;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    if (name === 'zip' && value.length >= Constants.zipcodeLength) {
      theEvent.preventDefault();
    }
  }

  /**
   *
   * Validates the form
   *
   */
  validate() {
    const {
      email, formValid, isSelected,
    } = this.state;

    let error = false;
    /** Validates email */
    formValid.email_isValid = validator.isEmail(email);

    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    if (!isSelected) {
      error = true;
    }
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  render() {
    const {
      type, redirectToSignUpPage, mobile,
    } = this.props;
    const {
      isDisabled, isSelected, loading, loggedIn, status, email, cost, emailError,
    } = this.state;

    return (
      <>
        {loading && (
        <BuyContactContainer>
          <Loader />
        </BuyContactContainer>
        )}
        {!loading && (
          <Row className="mt-3 ml-4">
            <SaveGiggerWrapper
              onClick={this.closeSlider}
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={Constants.icons.ArrowLeft} alt="icon" className="mr-2 cursor-pointer" />
              <Line role="button">
                <FormattedMessage id="common_back" defaultMessage="Back" />
              </Line>
            </SaveGiggerWrapper>
          </Row>
        )}
        {loggedIn ? (
          <>
            {isCustomer() && status === Constants.status.APPROVED ? (
              <BuyContactContainer mobile={mobile} className={mobile ? 'mt-3' : 'pt-0 pb-0'}>
                <>
                  {loading && <Loader height="40px" width="200px" />}
                  {!loading && (
                  <BuyContactHeader>
                    {type === Constants.order.BUY_CONTACT ? (
                      <>
                        <Header className={mobile ? '' : 'mt-3'}>
                          <FormattedMessage id="buy_contact_details_for" defaultMessage="Buy contact details for" />
                        </Header>
                      </>
                    ) : type === Constants.order.REFERENCE_CHECK ? (
                      <>
                        <FormattedMessage
                          id="buy_contact_reference_check_for"
                          defaultMessage="Reference check for"
                        />
                      </>
                    ) : type === Constants.order.CV_VERIFICATION ? (
                      <>
                        <FormattedMessage
                          id="buy_contact_CV_verification_for"
                          defaultMessage="CV verification for"
                        />
                        {' '}
                        <BuyContactHeaderBold>
                          {`${Constants.price[Constants.order.CV_VERIFICATION]} kr`}
                        </BuyContactHeaderBold>
                      </>
                    ) : type === Constants.order.INTERVIEW ? (
                      <>
                        <FormattedMessage id="buy_contact_interview_for" defaultMessage="Interview for" />
                      </>
                    ) : (
                      ''
                    )}
                  </BuyContactHeader>
                  )}
                </>
                <Div>
                  {loading && <Loader height="40px" width="310px" />}
                  {!loading && type === Constants.order.BUY_CONTACT && (
                    <>
                      <BuyContactSubHeader>
                        <FormattedMessage
                          id="buy_contact_sub_header_1"
                          defaultMessage="Contact details avialable for 30 days"
                          values={{ break: <br /> }}
                        />
                      </BuyContactSubHeader>
                    </>
                  )}
                  {!loading && type === Constants.order.REFERENCE_CHECK
                     && (
                     <BuyContactSubHeader>
                       <FormattedMessage
                         id="reference_check_order_request_message"
                         defaultMessage="Upon request we do checks on gigger to further ensure the candidates qualities."
                         values={{ break: <br /> }}
                       />
                     </BuyContactSubHeader>
                     ) }
                  {!loading && type === Constants.order.INTERVIEW
                     && (
                     <BuyContactSubHeader>
                       <FormattedMessage
                         id="interview_order_request_message"
                         defaultMessage="Upon request we do checks on gigger to further ensure the candidates qualities."
                         values={{ break: <br /> }}
                       />
                     </BuyContactSubHeader>
                     ) }
                </Div>
                {loading && <Loader height="24px" width="70px" />}
                {!loading && (
                <InvoiceHeader className="mt-3 mb-2">
                  <FormattedMessage id="buy_contact_invoice_details" defaultMessage="Invoice details" />
                </InvoiceHeader>
                )}
                {loading && <Loader height="24px" width="70px" />}
                {!loading && (
                <BuyContactSubHeader className="mb-3">
                  <FormattedMessage
                    id="buy_contact_invoice_details_change_info"
                    defaultMessage="Invoice details can be changed in My account."
                    values={{
                      link: <A className="font-weight-normal" href={Constants.routes.customerMyAccount.url} primaryColor textTransformNone><FormattedMessage id="common_my_account" defaultMessage="My account" /></A>,
                    }}
                  />
                </BuyContactSubHeader>
                )}
                <>
                  {loading && <Loader height="40px" />}
                  {!loading && (
                  <TextBox
                    className="mb-4"
                    label={(
                      <b>
                        <FormattedMessage id="common_billing_address" defaultMessage="Faktureringsadress" />
                      </b>
                        )}
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    existError={emailError}
                    errorMessage={<FormattedMessage id="error_email_message" defaultMessage="Enter the valid email" />}
                    rule={{
                      method: validator.isEmail,
                      validWhen: true,
                    }}
                  />
                  )}
                </>
                <>
                  {loading && <Loader height="40px" />}
                  {!loading && (
                  <TextBox
                    className="mb-4"
                    label={(
                      <b>
                        <FormattedMessage id="common_billing_reference" defaultMessage="Billing reference" />
                      </b>
                        )}
                    mandatory={(
                      <Span>
                        <FormattedMessage id="common_optional" defaultMessage="Optional" />
                      </Span>
                        )}
                    type="text"
                    name="cost"
                    value={cost}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeypress}
                    errorMessage={<FormattedMessage id="error_cost_message" defaultMessage="Enter the cost" />}
                  />
                  )}
                </>
                <Div className="mt-2">
                  {loading && <Loader height="24px" width="100px" />}
                  {!loading && (
                  <CustomCheckBox
                    isSelected={isSelected}
                    onClick={this.handleAgreeTerms}
                    label={[
                      <FormattedMessage
                        id="common_agree_terms_and_conditions_message"
                        values={{
                          privacyLink: <PrivacyPolicy color={Colors.Primary} />,
                          termsAndConditionsLink: <TermsAndConditions color={Colors.Primary} />,
                        }}
                        defaultMessage="Jag godkänner gällande allmänna villkor och att mina personuppgifter behandlas i enlighet med nu gällande och kommande integritetspolicy, also something about Finders Fee"
                      />,
                    ]}
                  />
                  )}
                </Div>
                <Div className="mt-4 pt-3">
                  {loading ? (
                    <Loader width="100%" height="40px" />
                  ) : (
                    <>
                      {type === Constants.order.BUY_CONTACT ? (
                        <FilterButton
                          data-dismiss="modal"
                          aria-label="Close"
                          update
                          className="cursor-pointer"
                          disabled={isDisabled}
                          onClick={this.handleSubmit}
                        >
                          <ButtonName>
                            <FormattedMessage id="common_get_contacts" defaultMessage="Get contacts" />
                          </ButtonName>
                        </FilterButton>
                      ) : (
                        <FilterButton
                          data-dismiss="modal"
                          aria-label="Close"
                          update
                          className="cursor-pointer"
                          disabled={isDisabled}
                          onClick={this.handleSubmit}
                        >
                          <ButtonName>
                            <FormattedMessage id="common_get_details" defaultMessage="Get Details" />
                          </ButtonName>
                        </FilterButton>
                      )}
                    </>
                  )}
                </Div>
                <Div className="mt-4 pt-1">
                  {loading ? (
                    <Loader width="100%" height="40px" />
                  ) : (
                    <div className="row justify-content-center">
                      <div className="col-md-5 col-xl-5">
                        <FilterButton data-dismiss="modal" aria-label="Close" onClick={this.closeSlider} className="cursor-pointer">
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </FilterButton>
                      </div>
                    </div>
                  )}
                </Div>
              </BuyContactContainer>
            ) : (
              <>
                {isGigger() && !loading ? (
                  <BuyContactContainer>
                    <BuyContactHeader>
                      <FormattedMessage
                        id="common_create_company_account"
                        defaultMessage="Create a Company account"
                      />
                    </BuyContactHeader>
                    <BuyContactSubHeader>
                      <FormattedMessage
                        id="buy_contact_cannot_buy_gigger_message"
                        defaultMessage="We are sorry but you can not buy giggers contact details yet. As soon you create a account to view and buy gigger details."
                      />
                    </BuyContactSubHeader>
                    <Div className="mt-4 pt-4">
                      <FilterButton
                        data-dismiss="modal"
                        aria-label="Close"
                        update
                        className="cursor-pointer"
                        onClick={redirectToSignUpPage}
                      >
                        <ButtonName>
                          <FormattedMessage
                            id="common_create_company_account"
                            defaultMessage="Create a Company account"
                          />
                        </ButtonName>
                      </FilterButton>
                    </Div>
                  </BuyContactContainer>
                ) : (
                  <>
                    {!loading && (
                    <BuyContactContainer>
                      <BuyContactHeader>
                        <FormattedMessage
                          id="buy_contact_account_under_review_message"
                          defaultMessage=" You account is still under review"
                        />
                      </BuyContactHeader>
                      <BuyContactSubHeader>
                        <FormattedMessage
                          id="buy_contact_cannot_buy_gigger_message_1"
                          defaultMessage="We are sorry but you can not buy giggers contact details yet. As soon you have been approved by our team you will recive an e-mail."
                        />
                      </BuyContactSubHeader>
                      <Div className="mt-4 pt-4">
                        <FilterButton
                          update
                          className="cursor-pointer"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.closeSlider}
                        >
                          <FormattedMessage
                            id="buy_contact_back_to_my_result"
                            defaultMessage="Ok, back to my result"
                          />
                        </FilterButton>
                      </Div>
                    </BuyContactContainer>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            {!loading && (
            <BuyContactContainer>
              <BuyContactHeader>
                <FormattedMessage id="common_create_company_account" defaultMessage="Create a Company account" />
              </BuyContactHeader>
              <BuyContactSubHeader>
                <FormattedMessage
                  id="buy_contact_cannot_buy_gigger_message"
                  defaultMessage="We are sorry but you can not buy giggers contact details yet. As soon you create a account to view and buy gigger details."
                />
              </BuyContactSubHeader>
              {mobile
                ? (
                  <Div className="mt-4 pt-2">
                    <FilterButton
                      update
                      className="cursor-pointer"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={redirectToSignUpPage}
                    >
                      <ButtonName>
                        <FormattedMessage
                          id="common_create_company_account"
                          defaultMessage="Create a Company account"
                        />
                      </ButtonName>
                    </FilterButton>
                  </Div>
                )
                : (
                  <Div className="mt-4 pt-4">
                    <Button
                      type="update"
                      name={
                        <FormattedMessage id="common_create_company_account" defaultMessage="Create a Company account" />
                  }
                      onClick={redirectToSignUpPage}
                    />
                  </Div>
                )}
            </BuyContactContainer>
            )}
          </>
        )}
      </>
    );
  }
}
export default BuyContactForm;
