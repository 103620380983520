import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import Feedback from '../../components/Accordion/AccordionBody';
import {
  FlexContainer,
  Container,
  Content,
  PageName,
  Header,
  SubHeader,
  WhiteCard,
  CurrentPage,
  MyGiggsWrapper,
  MyGiggHeader,
  Div,
  BuyContactSubHeader,
} from './styles';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import PersistentStorage from '../../shared/PersistentStorage.ts';
import Toast, { MainContext } from '../../components/Toast/Toast';
import { RelativeContainer, P } from '../../styles/CommonStyles';
import PrimaryButton from '../../components/Button/Button';

class MyGiggs extends Component {
  authService = new AuthService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  persistentStorage = new PersistentStorage();

  constructor(props) {
    super(props);
    this.state = {
      feedback: [],
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      user: JSON.parse(this.persistentStorage.getUserDetails()) || {},
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchMyGiggs();
  }

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerMyAccount.url);
  };

  /**
   * sends request feedback to hired company
   */
  handleRequestFeedback = (id) => async () => {
    this.setState({ loading: true });
    const { user } = this.state;
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    const data = { user_id: id };
    try {
      const response = await this.giggerService.requestFeedback(user._id, data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_request_mail_sent;
        this.setState({ loading: false, toastData, showToast: true }, this.fetchMyGiggs);
      }
    } catch (e) {
      toastData.type = Constants.toast.ERROR;
      toastData.message = Constants.language.toast_try_after_time;
      this.setState({ loading: false, toastData, showToast: true });
    }
  };

  /**
   * Closes toast bar
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   * @async
   * @function fetchMyGiggs Fetches the companies hired by the user
   */
  async fetchMyGiggs() {
    this.setState({ loading: true });
    try {
      const response = await this.giggerService.myGiggs();
      const { data = {} } = response;
      this.setState({ feedback: data, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      feedback, loading, loggedIn, showToast, toastData,
    } = this.state;
    if (!loggedIn) {
      return (
        <Redirect
          to={{
            pathName: Constants.routes.logIn.url,
          }}
        />
      );
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <FlexContainer>
            <Container>
              <Content className="row">
                <PageName className="opacity-7 pl-1" onClick={this.handleMyAccount} role="button">
                  {`${Constants.language.common_my_account} > `}
                </PageName>
                <CurrentPage>
                  <FormattedMessage id="common_my_gigs" defaultMessage="My gigs" />
                </CurrentPage>
              </Content>
              <Content className="row">
                {loading ? (
                  <Loader width="150px" marginTop="20px" />
                ) : (
                  <Header className="mb-3">
                    <FormattedMessage id="common_my_gigs" defaultMessage="My Giggs" />
                  </Header>
                )}
              </Content>
              <Content className="row mb-3">
                {loading ? (
                  <Loader width="300px" marginTop="15px" />
                ) : (
                  <SubHeader>
                    <FormattedMessage
                      id="mygiggs_companies_you_hired_by"
                      defaultMessage="Previous gigs via GigAssembly"
                    />
                  </SubHeader>
                )}
              </Content>
              {loading ? (
                <Loader height="62px" width="346px" marginTop="30px" />
              ) : (
                <>
                  {feedback && feedback.length > 0 ? (
                    <>
                      {feedback.map((feedbackdata) => (
                        <Content className="row">
                          <MyGiggsWrapper>
                            <Div className={`${feedbackdata.feedback.length > 0 ? ' d-block' : 'd-flex'} justify-content-between align-items-center`}>
                              <Div>
                                <MyGiggHeader className="mb-0">{feedbackdata.company_name}</MyGiggHeader>
                                <Content className="justify-content-between">
                                  {feedbackdata.feedback.length > 0 ? (
                                    <>
                                      {feedbackdata.feedback.map((dataFeedback) => (
                                        <Div className="p-1">
                                          <Feedback feedback data={dataFeedback} />
                                        </Div>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <Div className="mb-3 mb-lg-0">
                                        {feedbackdata.mail_sent_before >= 0
                                     && !feedbackdata.feedback_provided && (
                                       <BuyContactSubHeader className="m-0 mb-1" color>
                                         {`${Constants.language.my_giggs_email_requested_message} `}
                                         {feedbackdata.mail_sent_before === 0 ? (
                                           <FormattedMessage id="common_today" defaultMessage="today" />
                                         ) : (
                                           <>
                                             {`${feedbackdata.mail_sent_before} ${Constants.language.common_days_ago}`}
                                           </>
                                         )}
                                       </BuyContactSubHeader>
                                        )}
                                      </Div>
                                    </>
                                  )}
                                </Content>
                              </Div>
                              {feedbackdata.feedback.length === 0 && (
                              <Div className={`${feedbackdata.mail_sent_before >= 0 ? 'py-2' : 'py-4'}`}>
                                <PrimaryButton
                                  className="w-auto"
                                  id="search"
                                  type="update"
                                  feedbackButton
                                  onClick={this.handleRequestFeedback(feedbackdata._id)}
                                  name={(
                                    <P className="m-0">
                                      <FormattedMessage
                                        id="my_giggs_request_feedback"
                                        defaultMessage="Request feedback"
                                      />
                                    </P>
                                      )}
                                />
                              </Div>
                              )}
                            </Div>
                          </MyGiggsWrapper>
                        </Content>
                      ))}
                    </>
                  ) : (
                    <MyGiggsWrapper>
                      <WhiteCard>
                        <FormattedMessage id="myiggs_not_hired_yet_message" defaultMessage="You are not hired yet" />
                      </WhiteCard>
                    </MyGiggsWrapper>
                  )}
                </>
              )}
            </Container>
          </FlexContainer>
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default MyGiggs;
