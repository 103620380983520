const Colors = {
  Primary: '#0e3132',
  Secondary: '#06041d',
  NavyBlue: '#2c2f7c',
  Black: '#000000',
  White: '#ffffff',
  Orange: '#eca477',
  LightOrange: '#f9e3d6',
  Grey: '#a9bbbc',
  DarkWhite: '#f1f5f7',
  LightWhite: '#f7f7f7',
  Green: '#27ae60',
  LightBlue: '#6b6887',
  LightViolet: '#73718c',
  Transparent: 'transparent',
  Red: '#aa0000',
  Pink: '#f9e3d6',
  HighlightGreen: '#219653',
  DarkOrange: '#cf8b60',
  LightGrey: '#B2C3C4',
  DarkGrey: '#D4DDDD',
  StromGrey: '#6B6887',
  LightGreyOpacity: 'rgba(178, 195, 196, 0.25)',
  CreamyPeach: '#F5AA82',
};

export default Colors;
