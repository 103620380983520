import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';

export const ToastContainer = styled.div`
  width: 100%;
  z-index: 999 !important;
  background-color: ${({ type }) => (type === 'success' ? Colors.Green : Colors.Red)};
  position: ${({ position }) => (position || 'sticky')};
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  left: 0;
`;
export const ToastText = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  margin: 0;
  padding-left: 8px;
`;
