import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Constants from '../../shared/constants';
import Toast, { MainContext } from '../../components/Toast/Toast';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import { RelativeContainer } from '../../styles/CommonStyles';
import UserDetailsUI from '../../components/UserDetails/index';

class UserDetails extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      oldPasswordError: false,
      loggedIn: this.authService.isAuthenticated(),
      showToast: false,
      emailError: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      userData: {},
    };
  }

  async componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchUserDetails();
  }

  /**
   *
   * Redirects to forgot password page
   *
   */
  handleForgotPassword = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.forgotPassword.url });
  };

  /**
   *
   * Logs out the account
   *
   */
  handleLogOutAccount = () => {
    localStorage.clear();
    window.location.reload();
  };

  /**
   * @async
   * @function handleChangeMailButton Saves the changes made in user details
   *
   * @param {object} data user details
   *
   */
  handleChangeMailButton = async (data) => {
    this.setState({ loading: true, emailError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.customerService.updateCompanyDetails(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_user_details_updated;
        this.setState({ loading: false, toastData, showToast: true });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ toastData, showToast: true, loading: false });
      }
    } catch (e) {
      if (e && e.respone && e.response.data && e.response.data.message === 'Email Id Already Exists') {
        this.setState({ emailError: true, loading: false, showToast: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   *
   * @async
   * @function handleChangePasswordButton changes password
   * @param {object} data password data
   *
   */
  handleChangePasswordButton = async (data) => {
    this.setState({ loading: true, oldPasswordError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.customerService.changePassword(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_password_updated;
        this.setState({
          loading: false,
          toastData,
          showToast: true,
        });
        this.handleLogOutAccount();
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ toastData, showToast: true, loading: false });
      }
    } catch (e) {
      if (e && e.response && e.response.data.error === "Old Password didn't match") {
        this.setState({ oldPasswordError: true, loading: false, showToast: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   *
   * Closes toast
   *
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   *
   * Redirects to my account page
   *
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
   * Redirects to My accounts page
   */
  handleSearchGiggers = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerList.url);
  };

  /**
   *
   * Redirects to delete account page.
   *
   */
  handleDelete = () => {
    const { history } = this.props;
    history.push(Constants.routes.deleteAccount.url, { gigger: false });
  };

  /**
   *
   * @async
   * @function fetchUserDetails Fetches the user details
   *
   */
  async fetchUserDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.customerService.userDetails();
      const { data = {} } = response;
      this.setState({
        userData: data,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      showToast, toastData, emailError, userData, loading, oldPasswordError, loggedIn,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <UserDetailsUI
            oldPasswordError={oldPasswordError}
            handleMyAccount={this.handleMyAccount}
            data={userData}
            loading={loading}
            emailError={emailError}
            handleChangePassword={this.handleChangePasswordButton}
            handleForgotPassword={this.handleForgotPassword}
            handleDelete={this.handleDelete}
            handleSubmitDetails={this.handleChangeMailButton}
            handleRedirect={this.handleSearchGiggers}
          />
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default UserDetails;
