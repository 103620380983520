import { TAB_ACTION } from './types';

// eslint-disable-next-line import/prefer-default-export
export function tabActions(value) {
  return function (dispatch) {
    dispatch({
      type: TAB_ACTION,
      payload: value,
    });
  };
}
