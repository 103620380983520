import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Header from '../../../components/Header/Header';
import Constants from '../../../shared/constants';
import { FeedbackListWrapper } from './styles';
import { IconWrapper, Icon } from '../giggers/styles';
import FeedbackService from '../../../services/AdminServices/FeedbackService';
import Config from '../../../config';
import AuthService from '../../../services/AuthService';
import {
  FlexContainer,
  Container,
} from '../users/styles';
import LoadingCard from '../LoadingCard';
import Pagination from '../../../components/Pagination/Pagination';
import SearchInput from '../../../components/Input/SearchInput';
import ChangeLanguageModal from '../../../components/Modals/index';
import { getFormattedCSVData } from '../../../shared/utils';
import ListViewHeader from './ListView/ListViewHeader';
import ListViewBody from './ListView/ListViewBody';

class Feedback extends Component {
  authService = new AuthService(Config.apiBase);

  feedbackService = new FeedbackService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      feedbackData: [],
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      searchbar: true,
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
          pageNumber: 1,
          pageSize: 15,
        },
      },
      searchQuery: '',
      isLangModalOpen: false,
      csvDownloadData: [],
      csvExportFileds: [
        'full_name',
        'company_user_name',
        'gigger_name',
        'feedback',
        'rating',
        'created_at',
        'updated_at',
        'status',
        'user_id',
        'gigger_id',
      ],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchFeedbackList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        cursor: { pageNumber },
      },
    } = this.state;
    const { location } = this.props;
    if (prevState.meta.cursor.pageNumber !== pageNumber
      || location.key !== prevProps.location.key) {
      this.fetchFeedbackList();
    }
  }

  /**
   * HAndles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { cursor: { ...meta.cursor, pageNumber, pageSize: 15 } },
    }), this.fetchFeedbackList);
  };

  /**
   * Handles search functionality
   * @param {String} value search text
   */
  handlesearchFeedback=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchFeedbackList(value);
    });
  }

  /**
   * Handles change in search value
   * @param {Event} event search text
   * @listens Event
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Downloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.feedbackService.getFeedbackList(queryParams);
      const { data = {} } = response;
      this.setState({ csvDownloadData: getFormattedCSVData(data), loading: false });
      if (response) {
        this.feedbackCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Opens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   * Closes the opened modal
   */
  handleModalClose = () => {
    this.setState({
      isLangModalOpen: false,
    });
  };

  /**
   * Displays search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * Loads the data
   */
  handleLoad = () => {
    this.handleModalClose();
    this.fetchFeedbackList();
  };

  /**
   * @async
   * @function fetchFeedbackList Fetches feedback
   * @param {String} searchInput search text
   */
  async fetchFeedbackList(searchInput) {
    const {
      meta: {
        cursor: { pageNumber, pageSize },
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    try {
      const response = await this.feedbackService.getFeedbackList(queryParams);
      const { data, meta } = response;
      this.setState({
        feedbackData: data, loading: false, meta,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      searchbar,
      meta,
      loggedIn,
      loading,
      feedbackData,
      csvDownloadData,
      searchQuery,
      isLangModalOpen,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <CSVLink
          data={csvDownloadData}
          filename="feedback.csv"
          className="d-none"
          ref={(r) => { this.feedbackCsv = r; }}
        >
          click
        </CSVLink>
        <Header
          name={<FormattedMessage id="common_feedback" defaultMessage="Feedback" />}
          listScreen
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
        />
        <FlexContainer>
          <Container>
            <FeedbackListWrapper>
              <IconWrapper className="row m-0">
                {searchbar ? (
                  <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
                ) : (
                  <div className="text-end col-auto p-0">
                    <SearchInput
                      onSearch={this.handlesearchFeedback}
                      onChange={this.handleSearchOnChange}
                      value={searchQuery}
                    />
                  </div>
                )}
                <Icon
                  src={Constants.icons.ExportIcon}
                  alt="icon"
                  className={feedbackData && feedbackData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
                  onClick={this.handleDownloadCsv}
                />
              </IconWrapper>
              {loading && (
                <FeedbackListWrapper>
                  <LoadingCard />
                </FeedbackListWrapper>
              )}

              {!loading && (
                <>
                  <ListViewHeader />
                  <ListViewBody
                    data={feedbackData}
                    handleLoad={this.handleLoad}
                    searchQuery={searchQuery}
                  />
                </>
              )}
            </FeedbackListWrapper>
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#AdminModal"
              ref={(openLangModal) => { this.openLangElement = openLangModal; }}
            />
            {isLangModalOpen && (
              <ChangeLanguageModal
                changeLanguage={isLangModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {!loading && meta.cursor.pages > 1 && (
              <FeedbackListWrapper style={{ 'background-color': 'transparent' }}>
                <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
              </FeedbackListWrapper>
            )}
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default withRouter(Feedback);
