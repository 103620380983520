import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import { ButtonsWrapper } from '../gigger/styles';
import Button from '../../components/Button/Button';
import {
  FlexContainer,
  Container,
  Content,
  PageName,
  Header,
  Paragraph,
  OrderWrapper,
  OrderRow,
  BorderLight,
  OrderHeader,
  CurrentPage,
  OrderData,
  OrderStatusButton,
  NoDataCard,
  Div,
} from './styles';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import { addZero } from '../../shared/utils';

class Orders extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = { orders: [], loading: false, loggedIn: this.authService.isAuthenticated() };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchOrders();
  }

  /**
   * Redirects to myaccount page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

    /**
   * Redirects to My accounts page
   */
    handleSearchGiggers = () => {
      const { history } = this.props;
      history.push(Constants.routes.giggerList.url);
    };

    /**
   * @async
   * @function fetchOrders Fetches all the orders placed by the user
   */
    async fetchOrders() {
      this.setState({ loading: true });
      try {
        const response = await this.customerService.getOrders();
        this.setState({ orders: response.data, loading: false });
      } catch (e) {
        this.setState({ loading: false });
      }
    }

    render() {
      const { orders, loading, loggedIn } = this.state;
      if (!loggedIn) {
        return <Redirect to={Constants.routes.logIn.url} />;
      }
      return (
        <>
          <FlexContainer>
            <Container>
              <Content className="row">
                <PageName className="opacity-7 " onClick={this.handleMyAccount} role="button">
                  <FormattedMessage id="common_my_account" defaultMessage="My account" />
                  {' '}
                  {' > '}
                  {' '}
                </PageName>
                <span>&nbsp;</span>
                <CurrentPage>
                  <FormattedMessage id="common_orders" defaultMessage="Orders" />
                </CurrentPage>
              </Content>
              <Content className="row d-flex justify-content-between">
                {loading ? (
                  <Loader width="150px" />
                ) : (
                  <>
                    <Header className="mb-2">
                      <FormattedMessage id="common_orders" defaultMessage="Orders" />
                    </Header>
                    <Div className="p-0 mr-lg-3 pr-lg-1">
                      <ButtonsWrapper>
                        <Button
                          id="search"
                          type="update"
                          name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                          onClick={this.handleSearchGiggers}
                        />
                      </ButtonsWrapper>
                    </Div>
                  </>
                )}
              </Content>
              <Content className="row">
                <Paragraph>
                  {loading ? (
                    <Loader height="50px" width="346px" />
                  ) : (
                    <PageName>
                      <FormattedMessage
                        id="orders_invoice_message"
                        defaultMessage="All orders are invoiced on a monthly basis. Each purchase order invoice will be sent via e-mail to the registered user who made the order. (is that correct?) For any questions regarding orders, please visit the  FAQ section."
                      />
                    </PageName>
                  )}
                </Paragraph>
              </Content>
              <Content className="row">
                <OrderWrapper>
                  {loading ? (
                    <Loader height="52px" marginTop="10px" />
                  ) : (
                    <>
                      {orders.length > 0 && (
                      <OrderRow className="row pl-4">
                        <Content className="col">
                          <OrderHeader>
                            <FormattedMessage id="common_orders" defaultMessage="Gigger" />
                          </OrderHeader>
                        </Content>
                        <Content className="col-3">
                          <OrderHeader>
                            <FormattedMessage id="common_service" defaultMessage="Service" />
                          </OrderHeader>
                        </Content>
                        <Content className="col">
                          <OrderHeader>
                            <FormattedMessage id="common_date" defaultMessage="Date" />
                          </OrderHeader>
                        </Content>
                        <Content className="col-2 pl-0" align="right">
                          <OrderHeader>
                            <FormattedMessage id="common_amount" defaultMessage="Amount" />
                          </OrderHeader>
                        </Content>
                        <Content className="col">
                          <OrderHeader>
                            <FormattedMessage id="common_status" defaultMessage="Status" />
                          </OrderHeader>
                        </Content>
                      </OrderRow>
                      )}
                      <BorderLight />
                      {orders.length > 0
                      && orders.map((order) => {
                        const dateDisplay = `${addZero(new Date(order.created_at).getMonth() + 1)
                        }-${
                          addZero(new Date(order.created_at).getDate())
                        }-${
                          new Date(order.created_at).getFullYear()}`;
                        return (
                          <Content>
                            <OrderRow className="row pl-4">
                              <Content className="col">
                                <OrderData>{order.gigger_name}</OrderData>
                              </Content>
                              <Content className="col-3">
                                <OrderData>{order.type ? Constants.translateOrderType[order.type] : '-'}</OrderData>
                              </Content>
                              <Content className="col">
                                <OrderData>{dateDisplay}</OrderData>
                              </Content>
                              <Content className="col-2 pl-0" align="right">
                                <OrderData>
                                  {`${order.amount} kr`}
                                </OrderData>
                              </Content>
                              <Content className="col">
                                <OrderStatusButton>
                                  {order.status === Constants.status.OPEN ? (
                                    <Button
                                      name={<FormattedMessage id="common_in_progress" defaultMessage="In progress" />}
                                      type={Constants.status.PENDING}
                                    />
                                  ) : order.status === Constants.status.CANCELLED ? (
                                    <Button
                                      name={<FormattedMessage id="common_cancelled" defaultMessage="Cancelled" />}
                                      type={Constants.status.CANCELLED}
                                    />
                                  ) : order.status === Constants.status.COMPLETED ? (
                                    <Button
                                      name={<FormattedMessage id="common_complete" defaultMessage="Complete" />}
                                      type={Constants.status.COMPLETE}
                                    />
                                  ) : (
                                    <Button
                                      name={<FormattedMessage id="common_invoiced" defaultMessage="Invoiced" />}
                                      type={Constants.status.APPROVE}
                                    />
                                  )}
                                </OrderStatusButton>
                              </Content>
                            </OrderRow>
                            <BorderLight />
                          </Content>
                        );
                      })}
                      {orders.length === 0 && (
                      <>
                        <NoDataCard className="mx-4">
                          <FormattedMessage
                            id="orders_you_dint_place_any_message"
                            defaultMessage="You haven't placed any order yet"
                          />
                        </NoDataCard>
                      </>
                      )}
                    </>
                  )}
                </OrderWrapper>
              </Content>
            </Container>
          </FlexContainer>
        </>
      );
    }
}

export default Orders;
