import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';

export const Card = styled.div`
  width: 100%;
  max-width: 1045px;
  background-color: ${({ bgColor, message }) => (bgColor || (message ? '#f9e3d6' : '#ffffff'))};
  display: block;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 2px;
`;
export const ReminderCard = styled.div`
  width: 100%;
  background-color: ${(props) => (props.pinkCard ? `${Colors.LightOrange}` : props.customerDetailPinkCard ? `${Colors.LightOrange}` : 'rgb(169,187,188,0.2)')};
  display: block;
  margin-bottom: 10px;
  padding: 16px 16px 24px 24px;
  border-radius: 2px;
`;
export const Content = styled.div`
  text-align: ${(props) => (props.message ? 'end' : 'start')};
  padding: 0px;
`;
export const Section = styled.div``;
export const Span = styled.span`
  font-size: ${Font.fontSize.Size18}px;
  font-weight: 500;
  font-family: ${Font.fontFamily.AvenirMedium};
  line-height: 27px;
  letter-spacing: normal;
  color: ${Colors.Primary};
  cursor: pointer;
  &:hover {
    color: ${Colors.Black};
  }
  @media (max-width: 768px) {
    font-family: ${(props) => (props.addFont ? Font.fontFamily.Archivo : Font.fontFamily.AvenirMedium)};
  }
`;
export const ReminderHeader = styled.span`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size20}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: #0e3132;
  letter-spacing: normal;
`;
export const SpanDes = styled.span`
  font-size: ${Font.fontSize.Size14}px;
  font-weight: 400;
  font-family: ${Font.fontFamily.AvenirRegular};
  line-height: 21px;
  letter-spacing: 0.56px;
  color: #0e3132;
`;
export const ReminderDes = styled(ReminderHeader)`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
`;
export const Head = styled.span`
  font-family:${(strong) => (strong ? Font.fontFamily.AvenirMedium : Font.fontFamily.AvenirRegular)};
  font-size: ${(strong) => (strong ? `${Font.fontSize.Size18}px` : `${Font.fontSize.Size16}px`)};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(strong) => (strong ? '#000000' : '#0e3132')};
  margin-bottom: 16px;
`;
export const Explanation = styled.span`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132;
`;
export const Button = styled.button`
  border-radius: 2px;
  background-color: ${Colors.Primary};
  width: ${(buttonWidth) => (buttonWidth ? '160px' : '92px')};
  height: 40px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  outline: none;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
`;
export const CancelButton = styled(Button)`
  background-color: ${Colors.White};
  color: ${Colors.Primary} !important;
  border: 2px solid #0e3132;
`;
export const Border = styled.div`
  border-bottom: 1px solid #a9bbbc;
`;
export const SubHeader = styled.p`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 500;
  opacity: ${(props) => (props.head ? 1 : 0.8)};
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132;
  margin: 0px;
  padding: 0px;
  margin-bottom: 4px;
`;
export const CloseIcon = styled.img``;
export const SelectContainer = styled.div`
  min-width: 139px !important;
`;
