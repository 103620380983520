import React, { Component } from 'react';
import validator from 'validator';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TextBox from '../../components/Input/TextBox';
import Popover from '../../components/Popover/Popover';
import Constants from '../../shared/constants';
import Select from '../../components/Input/Select';
import Button from '../../components/Button/Button';
import { ButtonsWrapper } from '../gigger/styles';
import {
  FlexContainer,
  Container,
  PageName,
  Header,
  Content,
  CurrentPage,
  UserDetailsWrapper,
  SubHeader,
  Value,
  SelectBox,
  Div,
  Border,
  Form,
  SendButton,
  UserName,
  Desktop,
  Mobile,
} from './styles';
import PersistentStorage from '../../shared/PersistentStorage.ts';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import Modal from '../admin/company/Modal';
import { SelectContainer } from '../admin/company/styles';

class ManageUsers extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  persistentStorage = new PersistentStorage();

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      users: [],
      pendingUsers: [],
      show: false,
      inviteUserEmail: '',
      inviteUserRole: '',
      inviteUserFname: '',
      inviteUserLname: '',
      emailError: false,
      isModalOpen: false,
      showChangeRoleConfirmModal: false,
      formValid: {
        invite_email_isValid: '',
        invite_role_isValid: '',
        invite_fname_isValid: '',
        invite_lname_isValid: '',
      },
      loading: false,
      deleteUserData: [],
      userOptions: [
        { label: Constants.company, value: Constants.role.COMPANY },
        { label: Constants.company_user, value: Constants.role.COMPANY_USER },
      ],
      loggedIn: this.authService.isAuthenticated(),
      role: '',
      currentEditingUser: {},
      companyDetails: {},
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchManageUsers();
  }

  /**
    *  Redirects to search giggers page
    */

     handleSearchGiggers = () => {
       const { history } = this.props;
       history.push(Constants.routes.giggerList.url);
     };

  /**
   * Shows a popup explaing about the user roles.
   */
  handlePopUp = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  /**
   * Handles change in input value
   * @param {event} event
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  };

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
   * Handles change in user role
   */
  handleUserRoleChange = (name, label, value) => {
    this.setState({ inviteUserRole: value }, this.validate);
  };

  /**
   * Handles change in selected user's user role
   * @param {object} currentUser selected user data
   */
  handleManageUsersRoleChange = (currentUser) => (name, label, value) => {
    const { users } = this.state;
    const user = users.find((obj) => obj._id === currentUser._id);
    const currentEditingUser = currentUser;
    if (user.role_name !== value) {
      currentEditingUser.role_name = value;
      this.setState({
        showChangeRoleConfirmModal: true,
        currentEditingUser,
      }, () => this.openElement.click());
    }
  };

  /**
   * @async
   * @function handleSendInvitation Send invitation to join gigassembly to the invited user.
   */
  handleSendInvitation = async () => {
    let emailError = false;
    this.setState({ loading: true, emailError });
    const {
      inviteUserEmail, inviteUserRole, inviteUserFname, inviteUserLname, companyDetails,
    } = this.state;

    const data = {
      organization_number: companyDetails.organization_number,
      first_name: inviteUserFname,
      last_name: inviteUserLname,
      email: inviteUserEmail.toLowerCase(),
      role: inviteUserRole === Constants.role.COMPANY
        ? Constants.role.COMPANY : Constants.role.COMPANY_USER,
    };
    try {
      const response = await this.customerService.inviteUser(data);
      if (response) {
        this.setState(
          {
            inviteUserEmail: '',
            inviteUserRole: '',
            inviteUserFname: '',
            inviteUserLname: '',
            users: [],
            pendingUsers: [],
            loading: false,
          },
          this.fetchManageUsers,
        );
      }
    } catch (e) {
      const { response: { data: errorData = [] } = {} } = e;
      if (errorData.errors && errorData.errors.email) {
        emailError = true;
      }
      this.setState({ loading: false, emailError });
    }
  };

  handleRemoveModalOpen = (user) => () => {
    this.setState({ deleteUserData: user, isModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes opened modals
   */
  handleModalClose = () => {
    const { currentEditingUser } = this.state;
    let { users } = this.state;
    users = users.map((obj) => {
      const user = obj;
      if (user._id === currentEditingUser._id) {
        if (user.role_name === Constants.role.COMPANY) user.role_name = Constants.role.COMPANY_USER;
        else user.role_name = Constants.role.COMPANY;
      }
      return user;
    });

    this.setState({ isModalOpen: false, showChangeRoleConfirmModal: false, users });
  };

  /**
   * @async
   * @function handleRemoveUser Removes the user
   */
  handleRemoveUser = async () => {
    this.setState({ loading: true });
    const { deleteUserData } = this.state;
    const data = {
      user_id: deleteUserData._id,
    };
    try {
      const response = await this.customerService.removeUser(data);
      if (response) {
        this.setState(
          {
            users: [],
            pendingUsers: [],
            loading: false,
          },
          this.fetchManageUsers,
        );
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * @async
   * @function handleUpdateUserRole Updates user role
   */
  handleUpdateUserRole = async () => {
    const { currentEditingUser } = this.state;
    this.setState({ loading: true });
    const data = {
      role: currentEditingUser.role_name,
    };
    try {
      const response = await this.customerService.updateUserRole(currentEditingUser._id, data);
      if (response) {
        this.setState(
          {
            users: [],
            pendingUsers: [],
            loading: false,
          },
          this.fetchManageUsers,
        );
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Validates the invite user form
   */
  validate() {
    const {
      inviteUserEmail, inviteUserRole, formValid, inviteUserFname, inviteUserLname,
    } = this.state;
    let error = false;
    if (validator.isEmpty(inviteUserFname)) {
      formValid.invite_fname_isValid = false;
    } else {
      formValid.invite_fname_isValid = true;
    }
    if (validator.isEmpty(inviteUserLname)) {
      formValid.invite_lname_isValid = false;
    } else {
      formValid.invite_lname_isValid = true;
    }
    if (!validator.isEmail(inviteUserEmail)) {
      formValid.invite_email_isValid = false;
    } else {
      formValid.invite_email_isValid = true;
    }
    if (validator.isEmpty(inviteUserRole)) {
      formValid.invite_role_isValid = false;
    } else {
      formValid.invite_role_isValid = true;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  /**
   * @async
   * @function fetchManageUsers Fetches all users under the user company
   */
  async fetchManageUsers() {
    this.setState({ loading: true });
    let {
      role,
    } = this.state;
    const { users, pendingUsers } = this.state;
    try {
      const response = await this.customerService.manageUsers();
      const companyResponse = await this.customerService.companyDetails();
      const { data: manageUsersData = [] } = response;
      const { data: companyData = {} } = companyResponse;
      manageUsersData.map((obj) => {
        if (obj.invited === Constants.status.PENDING) {
          pendingUsers.push(obj);
        } else {
          users.push(obj);
        }
        return obj;
      });
      if (localStorage.getItem('userData')) {
        role = JSON.parse(localStorage.getItem('userData')).role;
      }
      this.setState({
        pendingUsers, users, loading: false, role, companyDetails: companyData,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      role,
      show,
      isModalOpen,
      isDisabled,
      userOptions,
      users,
      pendingUsers,
      loading,
      emailError,
      loggedIn,
      showChangeRoleConfirmModal,
      inviteUserFname,
      inviteUserLname,
      inviteUserEmail,
      inviteUserRole,
      currentEditingUser,
    } = this.state;

    const popupData = {
      title: <FormattedMessage id="common_user_roles" defaultMessage="User roles" />,
      brief: (
        <FormattedMessage
          id="about_user_roles"
          defaultMessage="Some text about the different user roles and what they stand for and what they can do."
        />
      ),
    };
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <Content className="row">
              <PageName className="opacity-7 " onClick={this.handleMyAccount} role="button">
                <FormattedMessage id="common_my_account" defaultMessage="My Account" />
                {' '}
                {' > '}
                {' '}
              </PageName>
              <span>&nbsp;</span>
              <CurrentPage>
                <FormattedMessage id="company_manage_users" defaultMessage="Manage users" />
              </CurrentPage>
            </Content>
            <Desktop>
              <Content className="row d-flex justify-content-between">
                {loading ? (
                  <Loader width="150px" />
                ) : (
                  <Header>
                    <FormattedMessage id="company_manage_users" defaultMessage="Manage users" />
                  </Header>
                )}
                <Div className="p-0 mr-lg-3 pr-lg-1">
                  <ButtonsWrapper>
                    <Button
                      id="search"
                      type="update"
                      name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                      onClick={this.handleSearchGiggers}
                    />
                  </ButtonsWrapper>
                </Div>
              </Content>
            </Desktop>
            <Mobile>
              <Content className="row">
                {loading ? (
                  <Loader width="150px" />
                ) : (
                  <>
                    <Div className="mt-2 col-md-12 text-right">
                      <ButtonsWrapper>
                        <Button
                          id="search"
                          type="update"
                          name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                          onClick={this.handleSearchGiggers}
                        />
                      </ButtonsWrapper>
                    </Div>
                    <Header className="my-1">
                      <FormattedMessage id="common_my_giggers" defaultMessage="My Giggers" />
                    </Header>
                  </>
                )}
              </Content>
            </Mobile>
            <Content className="row">
              <UserDetailsWrapper>
                {loading ? (
                  <Loader height="30px" />
                ) : (
                  <Content className="row">
                    <SubHeader>
                      <FormattedMessage id="company_manage_users" defaultMessage="Manage users" />
                    </SubHeader>
                  </Content>
                )}
                <>
                  <Content className="row m-0 my-3 pb-2 align-items-end">
                    {loading ? (
                      <Loader height="32px" width="346px" marginTop="10px" />
                    ) : (
                      <>
                        <Value>
                          <FormattedMessage
                            id="company_super_user_privilage_message"
                            defaultMessage="As a super user you can invite colleagues to the platform and assign user role"
                          />
                          {' '}
                          <Popover isOpen={show} onClick={this.handlePopUp} infoData={popupData} type="info" />
                        </Value>
                      </>
                    )}
                  </Content>
                  {users.length > 0
                    && users.map((user) => (
                      <Content className="col-12 p-0">
                        {loading ? (
                          <Loader height="32px" width="346px" marginTop="10px" />
                        ) : (
                          <>
                            <Div className="row m-0 mb-1">
                              <UserName>{user.full_name}</UserName>
                            </Div>
                            <Form className="row m-0">
                              <Div className="col p-0 mb-3 mr-3 pb-2 disableOption">
                                <TextBox
                                  type="email"
                                  name="email"
                                  value={user.email}
                                  onChange={this.handleChange}
                                  errorMessage={(
                                    <FormattedMessage
                                      id="error_email_message"
                                      defaultMessage="Enter a valid e-mail address"
                                    />
                                    )}
                                  rule={{
                                    method: validator.isEmail,
                                    validWhen: true,
                                  }}
                                />
                              </Div>
                              <Div className="col p-0">
                                <Div className="row m-0">
                                  <SelectContainer className="col-7 mb-3 p-0">
                                    <Select
                                      options={userOptions}
                                      search={false}
                                      onChange={this.handleManageUsersRoleChange(user)}
                                      value={
                                        user.role_name === Constants.role.COMPANY ? (
                                          Constants.language.company_super_user
                                        ) : (
                                          Constants.company_user
                                        )
                                      }
                                    />
                                  </SelectContainer>
                                  {role === Constants.role.COMPANY && (
                                  <>
                                    <Div className="col-auto ml-3 p-0 pt-2">
                                      <Value onClick={this.handleRemoveModalOpen(user)} role="button">
                                        <u>
                                          <FormattedMessage id="common_remove" defaultMessage="Remove" />
                                        </u>
                                      </Value>
                                    </Div>
                                  </>
                                  )}
                                </Div>
                              </Div>
                            </Form>
                          </>
                        )}
                      </Content>
                    ))}
                  {pendingUsers.length > 0 && (
                    <>
                      <Border className="mb-4 mt-3" />
                      <Content className="row mb-3">
                        {loading ? (
                          <Loader width="150px" />
                        ) : (
                          <SubHeader>
                            <img src={Constants.icons.ClockIcon} className="mb-1 mr-1" alt="icon" />
                            <FormattedMessage id="company_pending_invitations" defaultMessage="Pending Invitations" />
                          </SubHeader>
                        )}
                      </Content>
                      {pendingUsers.map((user) => (
                        <Content className="col-12 p-0">
                          {loading ? (
                            <Loader height="32px" width="346px" marginTop="10px" />
                          ) : (
                            <>
                              <Div className="row m-0 mb-1">
                                <UserName>{user.full_name}</UserName>
                              </Div>
                              <Form className="row m-0">
                                <Div className="col p-0 mb-3  mr-3 pb-2 disableOption">
                                  <TextBox type="email" name="email" value={user.email} disabled />
                                </Div>
                                <Div className="col p-0">
                                  <Div className="row m-0">
                                    <SelectBox className="col-7 disableOption">
                                      <TextBox
                                        type="text"
                                        name="role"
                                        value={
                                          user.role_name === Constants.role.COMPANY ? (
                                            Constants.language.company_super_user
                                          ) : (
                                            Constants.company_user
                                          )
                                        }
                                        disabled
                                      />
                                    </SelectBox>

                                    <Div className="col-5 p-0 pt-2">
                                      <Value
                                        role="button"
                                        id={user._id}
                                        onClick={this.handleRemoveModalOpen(user)}
                                      >
                                        <u>
                                          {' '}
                                          <FormattedMessage id="common_remove" defaultMessage="Remove" />
                                        </u>
                                      </Value>
                                    </Div>
                                  </Div>
                                </Div>
                              </Form>
                            </>
                          )}
                        </Content>
                      ))}
                    </>
                  )}
                  <Border className="mb-4 mt-3" />
                  <Content className="col-12 p-0">
                    {loading ? (
                      <Loader width="150px" />
                    ) : (
                      <Div className="row m-0 mb-1">
                        <UserName>
                          <FormattedMessage id="company_invite_new_collegue" defaultMessage="Invite new collegue" />
                        </UserName>
                      </Div>
                    )}
                    {loading ? (
                      <Loader height="32px" width="346px" marginTop="10px" />
                    ) : (
                      <>
                        {' '}
                        <Form className="row m-0">
                          <Div className="col-lg-4 col-md-12 p-0 mb-3 pb-2 ">
                            <TextBox
                              type="text"
                              label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
                              name="inviteUserFname"
                              value={inviteUserFname}
                              onChange={this.handleChange}
                              errorMessage={
                                <FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />
                              }
                              existError={false}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          </Div>
                          <Div className="col-1 p-0 mb-3 pb-2 " />
                          <Div className="col-lg-4 col-md-12 p-0 mb-3 pb-2 ">
                            <TextBox
                              type="text"
                              label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                              name="inviteUserLname"
                              value={inviteUserLname}
                              onChange={this.handleChange}
                              errorMessage={
                                <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                              }
                              existError={false}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          </Div>
                        </Form>
                        <Form className="row m-0">
                          <Div className="col-lg-7 col-md-12 p-0 mb-3 pb-2">
                            <TextBox
                              type="email"
                              name="inviteUserEmail"
                              value={inviteUserEmail}
                              onChange={this.handleChange}
                              label={(
                                <FormattedMessage
                                  id="common_email"
                                  defaultMessage="Email"
                                />
                              )}
                              errorMessage={(
                                <FormattedMessage
                                  id="error_email_message"
                                  defaultMessage="Enter a valid e-mail address"
                                />
                              )}
                              existErrorMessage={(
                                <FormattedMessage
                                  id="error_email_exists_message"
                                  defaultMessage="This e-mail address is already registeredId is already taken"
                                />
                              )}
                              existError={emailError}
                              rule={{
                                method: validator.isEmail,
                                validWhen: true,
                              }}
                            />
                          </Div>
                          <Div className="col-lg-4 col-md-12 p-0">
                            <SelectBox>
                              <Select
                                options={userOptions}
                                value={Constants[inviteUserRole] || inviteUserRole}
                                name="inviteUserRole"
                                placeholder={<FormattedMessage id="common_role" defaultMessage="Role" />}
                                onChange={this.handleUserRoleChange}
                                search={false}
                                label={(
                                  <FormattedMessage
                                    id="common_role"
                                    defaultMessage="Role"
                                  />
                                  )}
                                errorMessage={(
                                  <FormattedMessage
                                    id="error_select_role_message"
                                    defaultMessage="Select user role"
                                  />
                                  )}
                              />
                            </SelectBox>
                          </Div>
                        </Form>
                      </>
                    )}
                    <SendButton>
                      {loading ? (
                        <Loader height="32px" />
                      ) : (
                        <Button
                          type="update"
                          name={<FormattedMessage id="company_send_invitation" defaultMessage="Send invitation" />}
                          disabled={isDisabled}
                          onClick={this.handleSendInvitation}
                        />
                      )}
                    </SendButton>
                  </Content>
                </>
              </UserDetailsWrapper>
            </Content>
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#CompanyModal"
              ref={(openModal) => { this.openElement = openModal; }}
            />

            {isModalOpen && (
              <Modal
                deleteUser={isModalOpen}
                ModalClose={this.handleModalClose}
                handleDelete={this.handleRemoveUser}
              />
            )}
            {showChangeRoleConfirmModal && (
              <Modal
                updateUserRole={showChangeRoleConfirmModal}
                ModalClose={this.handleModalClose}
                data={currentEditingUser}
                handleUpdateUserRole={this.handleUpdateUserRole}
              />
            )}
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default ManageUsers;
