import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  ProfileViewContainer,
  Row,
  DetailsLeft,
  DetailsRight,
  Content,
  Header,
  SubHeader,
  GiggerDetailsWrapper,
  GiggerSearchWrapper,
  CreateAccountCardList,
  Button,
  FilterIcon,
  Div,
  FilterContent,
  A,
  HeadingWrapper,
} from './styles';
import Filter from '../../components/Filter/Filter';
import MobileFilter from '../../components/Filter/FilterInMobile';
import Constants from '../../shared/constants';
import GiggerCard from '../../components/ListCard/ListCard';
import LoadingCard from '../../components/ListCard/LoadingCard';
import Search from '../../components/Input/SearchBar';
import AccountService from '../../services/AccountService';
import ProfilePageService from '../../services/ProfilePageService';
import Config from '../../config';
import Pagination from '../../components/Pagination/Pagination';
import {
  searchQuery, isLoggedIn, isCustomer, isGigger, formatDate,
  getLanguages, getWorkAvailabilityOptions,
} from '../../shared/utils';
import {
  IsDesktop, IsMobile, WhiteButton,
} from '../customer/styles';
import AuthService from '../../services/AuthService';
import Modal from './Modal';
import { CloseIcon } from '../../styles/Icons';
import Fonts from '../../styles/Fonts';
import { TableHeader } from '../admin/users/styles';
import { FilterButton } from '../../components/Filter/styles';
import Colors from '../../styles/Colors';

const defaultMeta = {
  cursor: {
    pageNumber: 1,
    pageSize: Constants.pageSize,
  },
};

class GiggerList extends Component {
  accountService = new AccountService(Config.apiBase);

  profilePageService = new ProfilePageService(Config.apiBase);

  authService = new AuthService();

  constructor(props) {
    super(props);
    this.state = {
      giggers: [],
      loading: true,
      meta: defaultMeta,
      toBeFilteredData: [],
      jobTitle: [],
      skillsData: [],
      categoryData: [],
      systemsData: [],
      isFilterModalOpen: false,
      loggedIn: isLoggedIn(),
      stateData: {},
      searchData: {
        name: 'search',
        label: '',
        id: '',
      },
      filterData: Constants.defaultFilterData,
      loginModal: false,
      updateFilterData: true,
      chooseSearchType: false,
      disableJobTitle: false,
    };
  }

  async componentDidMount() {
    const { searchData } = this.state;
    const {
      location: { data = {} },
    } = this.props;
    const isAuthenticated = this.authService.isAuthenticated() === true;
    this.setState(
      {
        loginModal: !isAuthenticated,
      },
      () => {
        if (!isAuthenticated) {
          this.openLoginModal.click();
        }
      },
    );

    const searchQueryData = searchQuery();
    if (data && Object.keys(data).length > 0) {
      const {
        searchData: searchParam = {},
        stateData = {},
        filterData = [],
        chooseSearchType,
      } = data;
      this.setState({ chooseSearchType });
      if (Object.keys(searchParam).length > 0 && Object.keys(stateData).length > 0) {
        const {
          jobTitle, categoryData, skillsData, systemsData,
        } = stateData;
        if (!jobTitle || !categoryData || !skillsData || !systemsData) {
          this.fetchOptions();
        }
        const {
          meta = {},
        } = this.state;
        this.setState(
          {
            searchData: searchParam,
            stateData,
            jobTitle,
            categoryData,
            skillsData,
            systemsData,
            filterData,
            meta: stateData?.meta ? stateData.meta : meta,
          },
          this.handleApplyFilter(filterData, stateData, searchParam),
        );
      } else if (Object.keys(searchParam).length > 0) {
        await this.fetchOptions();
        this.handleSeachData(searchParam);
      } else if (Object.keys(stateData).length > 0) {
        const {
          meta = {},
        } = this.state;
        this.setState({
          meta: stateData?.meta ? stateData.meta : meta,
        }, () => {
          this.handleApplyFilter(filterData, stateData, searchParam);
        });
      } else {
        await this.fetchOptions();
      }
      window.scrollTo(0, 0);
    } else {
      this.setState({ updateFilterData: false });
      await this.fetchOptions();
      if (searchQueryData && searchQueryData.s) {
        searchData.label = searchQueryData.l ? searchQueryData.l : searchQueryData.s;
        searchData.id = searchQueryData.s;
        await this.handleSeachData(searchData);
      } else {
        await this.fetchGiggerList();
      }
    }
  }

  /**
   * @async
   * @function fetchOptions fetches all filters
   */
  fetchOptions = async () => {
    let filterList = await this.accountService.getAllFilter();
    filterList = filterList.data;
    const {
      jobtitle: jobTitle = [],
      category: categoryData = [],
      skills: skillsData = [],
      systems: systemsData = [],
    } = filterList;
    this.setState({
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
    });
  };

  /**
   * Redirects to gigger detail page
   * @param {*} id
   */

  handleDetailView = (id) => {
    const { history } = this.props;
    history.push(Constants.routes.customerDetailView.url, { show: false, id });
  };

  /**
   * Loader Ui
   */

  loadingCards = (count = 10) => [...new Array(count)].map(() => (
    <>
      <GiggerDetailsWrapper>
        <Content>
          <LoadingCard />
        </Content>
      </GiggerDetailsWrapper>
    </>
  ));

  /**
   * Redirects to Myaccount Page
   */

  handleMyAccount = () => {
    const { history } = this.props;
    if (isGigger()) {
      history.push(Constants.routes.giggerMyAccount.url);
    } else {
      history.push(Constants.routes.customerMyAccount.url);
    }
  };

  /**
   * logs out the account
   */

  handleLogOut = () => {
    localStorage.clear();
    const { history } = this.props;
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * handles change in pagenumber
   * @param {number} pageNumber
   */
  handleGotoPage = (pageNumber) => {
    const { toBeFilteredData = [] } = this.state;
    this.setState(
      ({ meta, stateData }) => ({
        meta: { cursor: { ...meta.cursor, pageNumber } },
        stateData: { ...stateData, meta: { cursor: { ...meta.cursor, pageNumber } } },
      }),
      () => this.fetchGiggerList(toBeFilteredData),
    );
    window.scrollTo(0, 0);
  }

  handleFilterDataUpdation = () => {
    this.setState({ updateFilterData: false });
  };

  /**
   * Applies filter
   * @param {object} data
   */

  handleApplyFilter = (data, stateData, searchParam = null) => {
    let { searchData } = this.state;
    let updatedData = data;
    if (searchParam) {
      searchData = searchParam;
      updatedData.push(searchParam);
    } else {
      const checkSearchFilter = updatedData.find((obj) => obj.name === 'search');
      if (checkSearchFilter) {
        updatedData = updatedData.map((obj) => {
          if (obj.name === 'search') {
            return searchData;
          }
          return obj;
        });
      } else {
        updatedData.push(searchData);
      }
    }
    const toBeFilteredData = [];
    updatedData.map((obj) => {
      if (toBeFilteredData[obj.name] || obj.id !== '') {
        toBeFilteredData[obj.name] = [];
        const objValue = obj.label === 'available_date' || obj.label === 'hourly_rate' ? obj.id : obj.label;
        toBeFilteredData[obj.name].push(objValue);
      } else {
        delete toBeFilteredData[obj.name];
      }

      return obj;
    });
    this.setState({
      searchData,
      toBeFilteredData,
      loading: true,
      stateData,
      filterData: updatedData,
      meta: defaultMeta,
    }, () => this.fetchGiggerList(toBeFilteredData));
  };

  /**
   * Clears out the filters
   */
  handleClearFilter = () => {
    const {
      jobTitle, categoryData, skillsData, systemsData,
    } = this.state;
    let { toBeFilteredData } = this.state;
    const { search = [] } = toBeFilteredData;
    toBeFilteredData = [];
    toBeFilteredData.search = search;
    this.setState({
      toBeFilteredData,
      filterData: Constants.defaultFilterData,
      stateData: {},
      disableJobTitle: false,
      loading: true,
      jobTitle: jobTitle.map((obj) => ({ ...obj, isSelected: false })),
      skillsData: skillsData.map((obj) => ({ ...obj, isSelected: false })),
      systemsData: systemsData.map((system) => ({ ...system, isSelected: false })),
      categoryData: categoryData.map((obj) => ({ ...obj, isSelected: false })),
      meta: defaultMeta,
      updateFilterData: true,
    }, () => this.fetchGiggerList(toBeFilteredData));
  };

  /**
   * Clears out the single filter
   */
  handleClearSingleFilter = ({ item = {}, name = '' }) => () => {
    const { filterData = [] } = this.state;
    const updatedFilterData = filterData.map((obj) => {
      if (item.name === obj.name) {
        const title = (obj.name === Constants.AVAILABLEDATE_FILTER_NAME || obj.name === Constants.RATE_FILTER_NAME) ? obj.id.split(',') : obj.label.split(',');
        return {
          ...obj,
          label: obj.name === Constants.AVAILABLEDATE_FILTER_NAME || obj.name === Constants.RATE_FILTER_NAME ? title.filter((value) => name.search(value) < 0).join(', ') : title.filter((value) => name !== value).join(','),
          id: obj.name === Constants.AVAILABLEDATE_FILTER_NAME || obj.name === Constants.RATE_FILTER_NAME ? title.filter((value) => name.search(value) < 0).join(', ') : title.filter((value) => name !== value).join(','),
        };
      }
      return obj;
    });
    switch (item.name) {
      case 'job_title':
        this.handleJobTitleFliterChange(updatedFilterData);
        break;
      case 'category':
        this.handleCategoryFliterChange(updatedFilterData);
        break;
      case 'skills':
        this.handleSkillsFliterChange(updatedFilterData);
        break;
      case 'systems':
        this.handleSystemsFliterChange(updatedFilterData);
        break;
      default:
        break;
    }
    this.setState({
      filterData: updatedFilterData,
      updateFilterData: true,
    });
  }

    handleJobTitleFliterChange = (updatedFilterData) => {
      const { jobTitle } = this.state;
      const jobTitileFilter = updatedFilterData.find((obj) => (obj.name === 'job_title'));
      this.setState({
        disableJobTitle: false,
        jobTitle: jobTitle.map(
          (obj) => ({ ...obj, isSelected: jobTitileFilter.label.includes(obj.label) }),
        ),
      });
    }

    handleCategoryFliterChange = (updatedFilterData) => {
      const { categoryData: category } = this.state;
      const categoryFilter = updatedFilterData.find((obj) => (obj.name === 'category'));
      this.setState({
        categoryData: category.map(
          (obj) => ({ ...obj, isSelected: categoryFilter.label.includes(obj.label) }),
        ),
      });
    }

    handleSkillsFliterChange = (updatedFilterData) => {
      const { skillsData: skills } = this.state;
      const skillsFilter = updatedFilterData.find((obj) => (obj.name === 'skills'));
      this.setState({
        skillsData: skills.map(
          (obj) => ({ ...obj, isSelected: skillsFilter.label.includes(obj.label) }),
        ),
      });
    }

    handleSystemsFliterChange = (updatedFilterData) => {
      const { systemsData: systems } = this.state;
      const systemsFilter = updatedFilterData.find((obj) => (obj.name === 'systems'));
      this.setState({
        systemsData: systems.map(
          (obj) => ({ ...obj, isSelected: systemsFilter.label.includes(obj.label) }),
        ),
      });
    }

    /**
   * fetches giggers on search
   * @param {event} e
   */

  handleSeachData = async (e) => {
    const { label, id } = e;
    const {
      searchData, toBeFilteredData, jobTitle, chooseSearchType,
    } = this.state;
    searchData.label = label;
    searchData.id = id;

    let filteredData = toBeFilteredData;
    let jobTitleOptions = jobTitle;

    if (chooseSearchType) {
      filteredData = toBeFilteredData.filter((o) => o.job_title);
      jobTitleOptions = jobTitle.map((o) => ({ ...o, isSelected: false }));
    }
    filteredData[searchData.name] = [];
    if (filteredData[searchData.name] && (e.id !== '' || e.label !== '')) {
      filteredData[searchData.name] = [];
      filteredData[searchData.name].push(e.type === 'secondary_job_title' ? e.name : e.label);
    } else {
      delete filteredData[searchData.name];
    }

    this.setState({
      searchData,
      toBeFilteredData: filteredData,
      meta: defaultMeta,
      disableJobTitle: false,
      jobTitle: jobTitleOptions,
      chooseSearchType: !!jobTitle.find((o) => o.label.toLowerCase() === label.toLowerCase()),
    }, () => this.fetchGiggerList(filteredData));
  };

  /**
   * Opens filter modal in mobile
   */

  handleFilterModalOpen = () => {
    this.setState({ isFilterModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes filter modal in mobile
   */

  handleModalClose = () => {
    this.setState({ isFilterModalOpen: false });
    this.openElement.click();
  };

  handleSearchTitle=() => {
    const {
      chooseSearchType, toBeFilteredData = [], searchData, jobTitle,
      filterData: stateFilterData = [], meta, stateData,
    } = this.state;
    const filterData = toBeFilteredData && toBeFilteredData.filter((o) => o.search);

    const { label: jobTitleLabel = '' } = jobTitle.find((o) => o.label.toLowerCase()
     === searchData.label.toLowerCase());

    if (filterData && filterData.find((o) => o.job_title)) {
      filterData.job_title.push(jobTitleLabel);
    } else {
      filterData.job_title = [jobTitleLabel];
    }

    const jobTitleOptions = jobTitle.map((obj) => (
      { ...obj, isSelected: filterData.job_title.includes(obj.label) }));
    const updatedFilterData = stateFilterData.map((o) => (o.name === 'job_title'
      ? { ...o, label: jobTitleLabel, id: jobTitleLabel } : o));
    this.setState({
      meta: { cursor: { ...meta.cursor, pageNumber: 1 } },
      stateData: { ...stateData, meta: { cursor: { ...meta.cursor, pageNumber: 1 } } },
      filterData: updatedFilterData,
      chooseSearchType: !chooseSearchType,
      toBeFilteredData: filterData,
      disableJobTitle: true,
      updateFilterData: true,
      jobTitle: jobTitleOptions,
      searchData: {
        name: 'search',
        label: '',
        id: '',
      },
    },
    () => this.fetchGiggerList(filterData));
  };

  handleContinueSearch=() => {
    const { chooseSearchType } = this.state;
    this.setState({
      chooseSearchType: !chooseSearchType,
      disableJobTitle: false,
    });
  };

  /**
   *
   * Handle redirecting login/ signup pages
   */
  handleRedirect = (url) => {
    const { history } = this.props;
    history.push({ pathname: url });
    this.openLoginModal.click();
  };

  getCapsuleLabel = (labelContent, item) => {
    switch (item.name) {
      case 'language':
        return getLanguages().find((o) => o.value === labelContent)?.label;
      case Constants.workAvailability:
        return getWorkAvailabilityOptions().find((o) => o.value === labelContent)?.label;
      case Constants.Experience:
        return `${labelContent} ${Constants.language.common_years}`;
      default:
        return labelContent;
    }
  };

  renderFilterCapsule = () => {
    const { filterData } = this.state;
    const filterLabel = filterData.filter((filter) => filter.name !== 'search').map(
      (item) => (
        (item.name === Constants.AVAILABLEDATE_FILTER_NAME
        || item.name === Constants.RATE_FILTER_NAME) ? item.id : item.label),
    ).filter((obj) => obj && obj);

    return (
      <Div display="inline-block" customWidth="90%">
        {filterData.filter((item) => item.id && item.label && item.name !== 'search').map((item) => {
          const labelContent = (
            item.name === Constants.AVAILABLEDATE_FILTER_NAME
            || item.name === Constants.RATE_FILTER_NAME
          ) ? item.id.split(',').map((obj) => (item.name === Constants.AVAILABLEDATE_FILTER_NAME
              ? formatDate(obj, Constants.dateMonthYearFormat) : obj))
            : item.label.split(',');
          return (
            item.name === Constants.RATE_FILTER_NAME
          ) ? (
              this.renderCapsuleLabel(`${labelContent[1] !== '' ? labelContent.join(',').replace(/,/g, `${item.name === Constants.RATE_FILTER_NAME ? ` kr/${Constants.language.common_hour}` : ''} - `) : labelContent.join(',').replace(/,/g, ' ')}
               ${item.name === Constants.RATE_FILTER_NAME ? `kr/${Constants.language.common_hour}` : ''}`, item, item.id)
            ) : item.name === Constants.AVAILABLEDATE_FILTER_NAME ? (
              this.renderCapsuleLabel(`${labelContent[0] === Constants.INVALID_DATE ? labelContent[1] : labelContent[1] === Constants.INVALID_DATE ? labelContent[0] : labelContent.join(',').replace(/,/g, ' - ')}
               `, item, item.id)
            ) : labelContent.map((itr) => (
              this.renderCapsuleLabel(itr, item, itr)
            ));
        })}
        {filterLabel.length > 0
        && (
        <Div className="float-left ml-3 p-1" maxWidth="90px">
          <A
            Bold
            fontFamily={Fonts.fontFamily.AvenirRegular}
            textTransformNone
            onClick={this.handleClearFilter}
          >
            {Constants.language.gigger_filter_clear_all}
          </A>
        </Div>
        )}
      </Div>
    );
  }

  /**
   * Fetches all approved giggers.
   *
   * @async
   * @function fetchGiggerList
   * @param {*} toBeFilteredData
   * @param {*} searchInput
   * @return {Promise<Array.<Object>>} The data from the URL.
   */

  async fetchGiggerList(toBeFilteredData) {
    const {
      meta: {
        cursor: { pageNumber, pageSize },
      },
      toBeFilteredData: filterData,
      stateData,
    } = this.state;
    this.setState({ loading: true });

    const queryParams = [
      ['pageNumber', pageNumber],
      ['pageSize', pageSize],
      ['filter', toBeFilteredData || filterData],
    ];

    try {
      const response = await this.accountService.list(queryParams);
      const { data = {} } = response;

      const { gigger_list: giggerList = [], pruchased_gigger: purchasedGiggers = [] } = data;

      const giggers = giggerList.map((gigger) => {
        const purchasedGigger = purchasedGiggers.find((obj) => obj.id === gigger.id);
        return purchasedGigger
          ? {
            ...gigger,
            profile_pic: { url: purchasedGigger.profile_pic },
            company: isCustomer(),
          }
          : { ...gigger, expire: true };
      });
      this.setState({
        giggers,
        meta: response.meta,
        loading: false,
        stateData: { ...stateData, meta: response.meta },
      });
    } catch (_e) {
      this.setState({
        giggers: [],
        meta: defaultMeta,
        stateData: { ...stateData, meta: defaultMeta },
        loading: false,
      });
    }
  }

  renderCapsuleLabel = (labelContent, item, name) => (
    <Div bgColor={Colors.LightGreyOpacity} className="d-flex align-items-center justify-content-between mb-3 p-1 mr-2 float-left">
      <Div className="pr-3 ml-1">
        <FilterContent>
          {this.getCapsuleLabel(labelContent, item, name)}
        </FilterContent>
      </Div>
      <Div className="text-right pr-1">
        <A onClick={this.handleClearSingleFilter({ item, name })}>
          <CloseIcon className="align-baseline" />
        </A>
      </Div>
    </Div>
  )

  render() {
    const {
      giggers,
      loading,
      meta,
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
      loggedIn,
      searchData,
      isFilterModalOpen,
      stateData,
      filterData,
      loginModal,
      updateFilterData,
      chooseSearchType,
      disableJobTitle,
    } = this.state;

    const { history } = this.props;
    return (
      <FlexContainer>
        <ProfileViewContainer>
          <Row>
            <DetailsRight>
              <Header className="mb-1">
                <FormattedMessage id="common_results" defaultMessage="Results" />
              </Header>
              <div className="row justify-content-end m-0">
                <IsMobile className="text-right p-0">
                  <WhiteButton onClick={this.handleFilterModalOpen} role="button">
                    {Constants.language.common_filter}
                  </WhiteButton>
                  <FilterIcon
                    src={Constants.icons.FilterIcon}
                    alt="icon"
                    width="16px"
                    height="10px"
                    role="button"
                    className="mt-0 ml-1"
                    onClick={this.handleFilterModalOpen}
                  />
                </IsMobile>
              </div>
              <GiggerSearchWrapper>
                <Search wp={false} onSeachData={this.handleSeachData} searchData={searchData} />
              </GiggerSearchWrapper>
              <Div className="d-flex">
                {this.renderFilterCapsule()}
              </Div>
              {!loading && searchData && searchData.label.length > 0
                && (
                  chooseSearchType
                    ? (
                      <>
                        <HeadingWrapper>
                          <TableHeader>
                            {`${Constants.language.text_show_titles} ”${searchData.label}” ${Constants.language.text_in_profile}`}
                          </TableHeader>
                        </HeadingWrapper>
                        <HeadingWrapper>
                          <TableHeader>
                            {`${Constants.language.text_show_in_search} ”${searchData.label}” ${Constants.language.text_in_title}`}
                          </TableHeader>
                        </HeadingWrapper>
                        <HeadingWrapper className="my-3">
                          <FilterButton onClick={this.handleSearchTitle} className="w-auto rounded-0">
                            <FormattedMessage id="common_yes" defaultMessage="Yes" />
                          </FilterButton>
                          <FilterButton update onClick={this.handleContinueSearch} className="w-auto rounded-0">
                            <FormattedMessage id="common_no" defaultMessage="No" />
                          </FilterButton>
                        </HeadingWrapper>
                      </>
                    ) : (
                      giggers.length > 0
                      && (
                      <HeadingWrapper className="mb-3">
                        <TableHeader>
                          {`${Constants.language.text_show_titles} ”${searchData.label}” ${Constants.language.text_in_profile}`}
                        </TableHeader>
                      </HeadingWrapper>
                      )
                    )
                )}
              <>
                {loading && this.loadingCards()}
                {!loading && giggers.length > 0 ? (
                  giggers.map((gigger, index) => {
                    const url = `/gigger-${gigger.full_name.replace(' ', '-')}/${gigger.id}`;
                    return (
                      <>
                        <GiggerDetailsWrapper>
                          <Content>
                            <GiggerCard
                              data={gigger}
                              redirect={url}
                              searchData={searchData}
                              filterData={{
                                filterData, stateData, searchData, chooseSearchType,
                              }}
                              isSearchPage
                            />
                          </Content>
                        </GiggerDetailsWrapper>
                        {index === 2 && !loggedIn && (
                          <CreateAccountCardList>
                            <Row>
                              <div className="col">
                                <p>
                                  <FormattedMessage
                                    id="giggerlist_create_account_message"
                                    defaultMessage="Create an account and get access to gigger´s photos and profiles"
                                  />
                                </p>
                              </div>
                              <div className="col-lg-4 pt-4 pt-lg-0">
                                <Button
                                  onClick={
                                    () => {
                                      history.push(
                                        Constants.routes.customerSignUpWithOutBankId.url,
                                      );
                                    }
}
                                >
                                  <FormattedMessage id="common_create_account" defaultMessage="Create account" />
                                </Button>
                              </div>
                            </Row>
                          </CreateAccountCardList>
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    {!loading && (
                      <GiggerDetailsWrapper fontSize={`${Fonts.fontSize.Size14}px`} className="p-0" style={{ 'background-color': 'transparent' }}>
                        <SubHeader fontSize={Fonts.fontSize.Size14}>
                          <FormattedMessage
                            id="no_gigger_found_contact_us_message"
                            defaultMessage="No search results found"
                            values={{
                              break: <br />,
                            }}
                          />
                        </SubHeader>
                      </GiggerDetailsWrapper>
                    )}
                  </>
                )}
                {!loading && meta.cursor.pages > 1 && (
                  <GiggerDetailsWrapper style={{ 'background-color': 'transparent' }}>
                    <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
                  </GiggerDetailsWrapper>
                )}
              </>
            </DetailsRight>
            <DetailsLeft>
              <IsDesktop className="w-100">
                <Filter
                  updateFilterData={updateFilterData}
                  cancelFilterDataUpdation={this.handleFilterDataUpdation}
                  jobTitle={jobTitle}
                  disableJobTitle={disableJobTitle}
                  categoryData={categoryData}
                  skillsData={skillsData}
                  systemsData={systemsData}
                  onCancel={this.handleCancelFilter}
                  onApply={this.handleApplyFilter}
                  onClearFilters={this.handleClearFilter}
                  total={meta.cursor.totalCount}
                  data={{ filterData, stateData, searchData }}
                />
              </IsDesktop>
            </DetailsLeft>
          </Row>
        </ProfileViewContainer>
        <a
          href
          data-toggle="modal"
          data-target="#FilterModal"
          ref={(openFilterModal) => {
            this.openElement = openFilterModal;
          }}
        />
        {isFilterModalOpen && (
          <MobileFilter
            openFilter={isFilterModalOpen}
            disableJobTitle={disableJobTitle}
            ModalClose={this.handleModalClose}
            jobTitle={jobTitle}
            categoryData={categoryData}
            skillsData={skillsData}
            systemsData={systemsData}
            onCancel={this.handleCancelFilter}
            onApply={this.handleApplyFilter}
            onClearFilters={this.handleClearFilter}
            total={meta.cursor.totalCount}
            data={{ filterData, stateData, searchData }}
          />
        )}
        <a
          href
          data-backdrop="static"
          data-keyboard="false"
          data-toggle="modal"
          data-target="#LoginModal"
          ref={(openModal) => {
            this.openLoginModal = openModal;
          }}
        />
        {loginModal && <Modal handleRedirect={this.handleRedirect} />}
      </FlexContainer>
    );
  }
}
export default GiggerList;
