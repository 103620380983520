import styled from 'styled-components';
import Fonts from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import px2vw from '../../styles/px2vw';
import { Card } from '../../components/Card/styles';
import { BackgroundImage } from '../../styles/Images';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.DarkWhite};
  background-image: url('${BackgroundImage}');
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const HeadingWrapper = styled.div`
  flex: 0 0 ${px2vw(960, 1440)};
  max-width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const Container = styled.div`
  margin: auto;
  width: 1100px;
  min-height: 100vh;
  margin-top: 40px;
  @media (max-width: 1140px) {
    width: 70%;
  }
  @media (max-width: 1140px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 75%;
    margin-left: 22%;
    margin-right: 22%;
    align-self: center;
    margin-top: 90px;
  }
  @media (max-width: 570px) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    align-self: center;
  }
  @media (max-width: 375px) {
    width: 92%;
    margin-left: 8%;
    margin-right: 8%;
    align-self: center;
  }
`;
export const SectionLeft = styled.div`
  flex: 0 0 41%;
  max-width: 41%;
  margin: 0px 30px 90px 0px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const SectionRight = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  height: inherit;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 6px;
  }
  @media (max-width: 570px) {
  }
  @media (max-width: 375px) {
  }
`;
export const CardWrapper = styled.div`
  max-width: 380px;
  margin-top: 38px;
  margin-left: 230px;
  margin-right: 30px;
  @media (max-width: 768px) {
    width: 55%;
    align-self: center;
  }
  @media (max-width: 570px) {
    width: 80%;
    align-self: center;
  }
  @media (max-width: 375px) {
    width: 80%;
    align-self: center;
  }
`;

export const Header = styled.h1`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size28}px;
  font-weight: 500;
  margin: 24px 0px 8px 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
`;
export const Name = styled(Header)`
  font-size: ${Fonts.fontSize.Size24}px;
  color: ${Colors.Black};
  margin: 24px 0px;
`;
export const SubHeader = styled(Header)`
  font-size: ${({ fontSize }) => (fontSize || Fonts.fontSize.Size18)}px;
  margin: 0;
  margin-bottom: 8px;
`;
export const HeaderName = styled(SubHeader)`
  font-size: ${Fonts.fontSize.Size16}px;
  margin-top: 16px;
  margin-bottom: 5px;
  opacity: 0.5;
`;
export const PageName = styled.span`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  margin: 0px;
  padding: 0px;
`;
export const CurrentPage = styled(PageName)`
  font-family: ${Fonts.fontFamily.Archivo};
  font-weight: 500;
  color: ${Colors.Black};
`;
export const UserDetailsWrapper = styled.div`
  background-color: ${Colors.White};
  padding: 24px 32px 32px 32px;
  flex: 0 0 ${px2vw(446, 1440)};
  max-width: ${px2vw(446, 1440)};
  margin-bottom: 48px;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(20, 375)};
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(16, 375)};
  }
`;
export const MyGiggsWrapper = styled(UserDetailsWrapper)`
  flex: 0 0 ${px2vw(500, 1440)};
  max-width: ${px2vw(500, 1440)};
  margin-bottom: 24px;
  padding: 24px 32px 24px 32px;
`;
export const Content = styled.div`
  margin: 0px;
`;
export const Button = styled.button`
  border-radius: 2px;
  background-color: ${Colors.Primary};
  width: 100%;
  width: ${({ customWidth }) => (customWidth || '100%')};
  margin-top: 40px;
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  outline: none;
  border: none;
  padding: 8px 16px;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: ${Colors.Black};
  }
`;
export const A = styled.a`
  font-family: ${({ fontFamily }) => (fontFamily || `${Fonts.fontFamily.Archivo}`)};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: ${({ Bold }) => (Bold ? '700' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ primaryColor }) => (primaryColor ? Colors.Primary : Colors.LightBlue)};
  flex: 1;
  text-decoration: underline !important;
  text-align: center;
  cursor: pointer;
  text-transform: ${({ textTransformNone }) => (textTransformNone ? 'none' : 'lowercase')};
  &:hover,
  &:focus {
    color: inherit;
  }
`;
export const Delete = styled.p`
  font-size: ${Fonts.fontSize.Size16}px;
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  flex: 1;
  letter-spacing: normal;
  color: #eb5757;
  text-align: center;
  margin-bottom: 11px;
  cursor: pointer;
`;
export const Message = styled(Delete)`
  font-size: ${Fonts.fontSize.Size14}px;
  letter-spacing: 0.56px;
  color: ${Colors.LightViolet};
  margin: 0px;
  cursor: text;
`;
export const DragAndDrop = styled.input`
  display: none;
`;
export const UploadDocument = styled.div`
  height: 42px;
  border-style: dashed !important;
  border: solid 1px rgb(127, 125, 152, 0.5);
  text-align: left;
  color: ${Colors.Primary};
`;
export const CloseButton = styled.a`
  font-size: 26px;
  color: rgba(0, 0, 0, 0.5);
`;
export const MyGiggHeader = styled.h2`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Black};
`;
export const PinkCard = styled.div`
  background-color: ${Colors.LightOrange};
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #21243b;
  width: 100%;
  padding: 24px 0px 24px 0px;
  text-align: center !important;
`;
export const WhiteCard = styled(PinkCard)`
  background-color: ${Colors.White};
  padding: 0;
  border-top: ${({ border }) => (border ? `1px solid ${Colors.Grey}` : 'none')};
  border-bottom: ${({ border }) => (border ? `1px solid ${Colors.Grey}` : 'none')};
`;
export const GreyCard = styled.div`
  background-color: rgb(178,195,196,0.25);
  width: 285px;
`;
export const WhiteCardSignUp = styled(PinkCard)`
  background-color: ${Colors.White};
  padding: 0;
  border-top: ${({ border }) => (border ? `1px solid ${Colors.Grey}` : 'none')};
  border-bottom: ${({ border }) => (border ? `1px solid ${Colors.Grey}` : 'none')};
  border-left: ${({ borderLeftLightGrey }) => (borderLeftLightGrey ? `7px solid ${Colors.LightGrey}` : `7px solid ${Colors.Pink}`)};
  border-radius: 5px;
  width: 210px;
  @media (max-width: 375px) {
    width: 336px;
    text-align: left !important;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const DetailsLeft = styled.div`
  flex: 0 0 21%;
  max-width: 21%;
  margin-right: 3%;
  @media (max-width: 1024px) {
    max-width: 25%;
    flex: 0 0 25%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 368px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export const DetailsRight = styled.div`
  flex: 0 0 76%;
  max-width: 76%;

  @media (max-width: 1024px) {
    max-width: 70%;
    flex: 0 0 70%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 368px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const UserName = styled(Header)`
  font-size: ${Fonts.fontSize.Size24}px;
  color: ${Colors.Primary};
  margin: 8px 0;
  text-align: center;
  text-transform: capitalize;
`;
export const OrderServicesHeader = styled(UserName)`
  font-size: ${Fonts.fontSize.Size20}px;
`;
export const ProfileViewContainer = styled(Container)`
  width: 70%;
  margin: 0 auto;
  margin-top: 40px;
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    margin: 90px auto 36px auto;
  }
`;

export const ProfileData = styled.div`
  margin-bottom: 32px;
`;
export const ProfileViewsWrapper = styled(Row)`
  align-items: ${({ forPublished }) => (forPublished ? 'center' : 'flex-start')};
  justify-content: center;
`;
export const ProfileViews = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  margin: 0;
`;
export const ProfileViewsIcon = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
`;
export const Image = styled.img``;
export const ProfileImage = styled.div`
  width: ${({ width }) => width || '100%'};
  height: 214px;
  margin: auto;
  @media (max-width: 768px) {
    height: 293px;
    width: 293px;
  }
  @media (max-width: 570px) {
    height: 284px;
    width: 284px;
  }
  @media (max-width: 368px) {
    height: 284px;
    width: 284px;
  }
`;
export const BlurImage = styled(ProfileImage)`
  filter: blur(5px);
  -webkit-filter: blur(5px);
`;
export const EditProfileButton = styled(Button)`
  background-color: ${Colors.Transparent};
  border: 1px solid ${Colors.Primary};
  margin-top: 32px;
  color: ${Colors.Primary};
  &:hover {
    border: 1px solid ${Colors.Black};
    color: ${Colors.Black};
    background-color: ${Colors.Transparent};
  }
`;
export const ProfileImageWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
  text-align: center;
`;
export const ProfileStatus = styled.span`
  position: absolute;
  bottom: 0;
  justify-content: center;
  display: flex;
  align-content: center;
  background: ${Colors.Green};
  padding: 8px;
  font-size: ${Fonts.fontSize.Size16}px;
  font-family: ${Fonts.fontFamily.Archivo};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: ${Colors.White};
  height: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 1 !important;
  width: ${({ width }) => width || '100%'};
  @media (max-width: 768px) {
    width: 293px;
    top: 253px;
  }
  @media (max-width: 570px) {
    width: 284px;
    top: 253px;
  }
  @media (max-width: 368px) {
    width: 284px;
    top: 253px;
  }
`;

export const WelcomeText = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin-bottom: 8px;
`;
export const WelcomeTextHeader = styled.span`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: ${({ fontSizeLarge }) => (fontSizeLarge ? `${Fonts.fontSize.Size20}px` : `${Fonts.fontSize.Size16}px`)};
  font-weight: ${({ fontWeightSemibold }) => (fontWeightSemibold ? '600' : '900')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin-bottom: 8px;
`;
export const JobTitle = styled(WelcomeText)`
  font-family: ${Fonts.fontFamily.AvenirBlack};
  font-size: 52.5px;
  font-weight: 900;
  line-height: 1.49;
  margin: 0 0 8px 0;
  text-transform: capitalize;
  word-break: break-word;
`;

export const JobPrice = styled(WelcomeText)`
  font-size: ${Fonts.fontSize.Size40}px;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 32px;
`;
export const SideHeading = styled(WelcomeText)`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size24}px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ color }) => (color ? Colors.Primary : Colors.Black)};
`;

export const ResumeContent = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Black};
  margin: 0;
  padding: ${({ customPadding }) => (customPadding ? '16px' : 'unset')};
`;
export const ResumeContentCapitalize = styled.span`
  text-transform: capitalize;
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: ${({ fontWeightNormal }) => (fontWeightNormal ? '400' : '500')};
  font-family: ${Fonts.fontFamily.AvenirMedium};
  margin-bottom: 14px;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size14}px;
  }
`;
export const EducationTitle = styled(ResumeContentCapitalize)`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-weight: ${({ semiBold }) => (semiBold ? '700' : '500')};
  margin: 0;
  font-size: ${({ fontSize }) => (fontSize || `${Fonts.fontSize.Size16}px`)};
  color: ${({ color }) => (color ? Colors.Primary : Colors.Black)};
  word-break: break-word;
  line-height: 24px;
  text-transform: none;

  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size16}px;
  }
`;

export const ShowMoreLink = styled(EducationTitle)`
  font-weight: 600;
  text-transform: none;
  font-family: ${Fonts.fontFamily.AvenirLight};
`;

export const ResumeBlock = styled.div`
  margin-bottom: 40px;
`;

export const ExperienceTag = styled.span`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #722c78;
  border: 2px solid #e6d0e9;
  padding: 4px;
  margin-right: 4px;
`;
export const JuniorLevelTag = styled.span`
  width: 70px;
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #647D7E;
  border: 3px solid rgb(100,125,126,0.2);
  padding: 4px 10px;
  margin-right: 16px;
`;
export const ExperienceLevelTag = styled(JuniorLevelTag)`
  width: 100px;
  color: #ECA477;
  border: 3px solid rgb(236,164,119);
  margin-right: 16px;
`;
export const MidLevelTag = styled(JuniorLevelTag)`
  width: 56px;
  color: #0E3132;
  border: 3px solid rgb(14,49,50);
  margin-right: 16px;
`;
export const MapsContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  margin-top: 24px;
`;
export const GiggerSearchWrapper = styled.div`
  max-width: 90%;
  @media (max-width: 1024px) {
    max-width: 90%;
    flex: 0 0 90%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const GiggerDetailsWrapper = styled.div`
  font-size: ${({ fontSize }) => (fontSize || 'unset')};
  background-color: ${Colors.White};
  padding: 18px;
  max-width: 90%;
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    max-width: 90%;
    flex: 0 0 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(20, 375)};
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
    padding: ${px2vw(16, 375)};
  }
`;
export const DisplayCards = styled.div`
  flex-wrap: ${({ flexWrap }) => flexWrap || 'wrap'};
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 570px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 375px) {
    flex-direction: column-reverse;
  }
`;
export const Div = styled.div`
  background-color: ${({ bgColor }) => (bgColor || 'none')};
  max-width: ${({ maxWidth }) => (maxWidth || 'unset')};
  grid-template-columns: 'none';
  display: ${({ display }) => (display || 'block')};
  @media (min-width: 1024px) {
    width: ${({ customWidth }) => (customWidth)};
  }
`;

// Buy contacts
export const BuyContactHeader = styled.span`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size24}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
`;
export const BuyContactHeaderBold = styled(BuyContactHeader)`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-weight: 900;
`;

export const BuyContactSubHeader = styled(BuyContactHeader)`
  font-family: ${Fonts.fontFamily.Archivo};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  color: ${({ color }) => (color ? Colors.LightBlue : Colors.Primary)};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
`;
export const BuyContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 100%;
  padding: ${(props) => (props.mobile ? '15px' : '25% 128px 25% 28px')};
  margin: ${(props) => (props.mobile ? '15px' : 0)};
  @media (max-width: 768px) {
    padding: 0;
    margin: 15px;
  }
`;
export const Body = styled.div`
  overflow: scroll;
  @media (max-width: 768px) {
    height: 1098px;
  }
  @media (max-width: 570px) {
    min-height: 1138px;
  }
  @media (max-width: 375px) {
    min-height: 1228px;
  }
`;
export const PinkArrowCard = styled.div`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
  border-radius: 2px;
  background-color: ${Colors.Pink};
  padding: 18px 23px 13px 16px;
  display: inline-block;
  position: relative;
  text-decoration: none;
  &: after {
    content: '';
    display: block;
    position: absolute;
    left: 20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 10px solid ${Colors.Pink};
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -20px;
  }
  & a {
    font-family: ${Fonts.fontFamily.Archivo};
    color: ${Colors.Primary};
  }
`;

export const CreateAccountCard = styled(Card)`
  background-color: ${Colors.Pink};
  padding: 32px;
  & p {
    font-family: ${Fonts.fontFamily.AvenirMedium};
    font-size: ${Fonts.fontSize.Size18}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Colors.Primary};
    margin: 0;
  }
  & button {
    margin: 0;
  }
  & .col-8,
  & .col-4 {
    align-self: center;
  }
`;
export const CreateAccountCardList = styled(GiggerDetailsWrapper)`
  background-color: ${Colors.Pink};
  padding: 32px;
  & p {
    font-family: ${Fonts.fontFamily.AvenirMedium};
    font-size: ${Fonts.fontSize.Size18}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Colors.Primary};
    margin: 0;
  }
  & button {
    margin: 0;
  }
  & .col-8,
  & .col-4 {
    align-self: center;
  }
`;
export const FeedbackCard = styled(Card)`
  padding: 0;
  & .accordion-background {
    background-color: white;
  }
`;
export const ButtonsWrapper = styled.div`
  float: right;
  align-self: center;
`;
export const InvoiceHeader = styled.div`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: ${Fonts.fontSize.Size16}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #06041d;
`;

export const Span = styled.span`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${({ fontSizeRegular }) => (fontSizeRegular ? `${Fonts.fontSize.Size16}px` : `${Fonts.fontSize.Size14}px`)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: rgba(0, 0, 0, 0.5);
`;

export const FilterIcon = styled.img`
  margin-top: 6px;
  opacity: 0.8;
  margin-left: 8px;
`;

export const FeedbackContainer = styled.div`
  max-height: 360px;
  overflow-y: auto;
`;

export const LoginHeader = styled.p`
  font-family: ${Fonts.fontFamily.AvenirRegular};
  font-size: ${Fonts.fontSize.Size24}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
`;

export const LoginSectionLeft = styled.div`
  flex: 0 0 61%;
  max-width: 61%;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const LoginSectionRight = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  height: inherit;
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    display: none;
  }
  @media (max-width: 375px) {
    display: none;
  }
`;

export const ContactDescription = styled.p`
  font-weight: 400;
  max-width: 540px;
  font-size: ${Fonts.fontSize.Size20}px;
  line-height: 30px;
  color: ${Colors.Black};

  @media (max-width: 768px) {
    font-size: ${Fonts.fontSize.Size14}px;
    line-height: 24px;
  }
`;

export const FilterContent = styled(ResumeContent)`
  line-height: 16px;
`;
