import ApiService from '../ApiService';

class OrderService extends ApiService {
  endPoint = {
    orders: 'admin/orders',
    order: 'admin/order/',
  };

  async getOrderList(queryParam) {
    const response = await this.get(this.baseURL
      + this.endPoint.orders + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async confirmOrder(id, data) {
    const response = await this.post(`${this.baseURL + this.endPoint.order + id}/confirm-order`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async cancelOrder(id) {
    const response = await this.post(`${this.baseURL + this.endPoint.order + id}/cancel-order`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async invoiceOrder(id) {
    const response = await this.post(`${this.baseURL + this.endPoint.order + id}/invoice-order`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeDocument(id, data) {
    const response = await this.post(`${this.baseURL + this.endPoint.order + id}/remove-document`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeOrder(id) {
    const response = await this.delete(`${this.baseURL}${this.endPoint.order}${id}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}
export default OrderService;
