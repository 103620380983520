import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Card, Span, Div } from '../../../../components/Popover/Style';
import { ButtonWrapper } from '../styles';
import Button from '../../../../components/Button/Button';
import Constants from '../../../../shared/constants';
import {
  Border, TableValue, TableData, Wrapper,
} from '../../users/styles';
import { WhiteButton } from '../../../customer/styles';

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openColumn: '',
    };
    this.filterOptions = [];
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Ignores clicks on the component itself
   * @param {Event} e
   * @listens Event
   */
  handleOutsideClick(e) {
    const { openColumn } = this.state;
    if (this.filterOptions[openColumn] && !this.filterOptions[openColumn].contains(e.target)) {
      if (this.filterOptions[openColumn]) this.setState({ openColumn: '' });
    }
  }

  /**
   * Handles display of options
   * @param {Number} value row index
   */
  handleOptions = (value) => () => {
    const { openColumn } = this.state;
    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value) });
    }
  };

  handleDisplayOptions = () => {
    this.setState({ openColumn: '' });
  };

  /**
   * Focus on Division
   */
  toFocusOnDiv=() => {
    document.getElementById('clickable-component').focus();
  }

  render() {
    const {
      handleApprove,
      handleDelete,
      handleSecondaryTitle,
      data,
      type,
      title,
    } = this.props;
    const { openColumn } = this.state;
    return (
      <>
        {data.map((obj, index) => (
          <>
            <Border />
            <Wrapper className="row m-0">
              <TableData className="col text-truncate" title={obj.name}>
                <TableValue>{obj.name}</TableValue>
              </TableData>

              {title && (
              <TableData className="col ">
                <TableValue>
                  {obj.secondary_title}
                </TableValue>
              </TableData>
              )}

              <TableData className="col">
                <TableValue>
                  {type}
                </TableValue>
              </TableData>

              <TableData className="col text-truncate" title={obj.added_by}>
                <TableValue>{obj.added_by ? obj.added_by : '-'}</TableValue>
              </TableData>

              <TableData className="col">
                <TableValue>{moment(obj.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
              </TableData>

              <TableData className="col text-align">
                <ButtonWrapper>
                  {obj && obj.status === Constants.status.APPROVED ? (
                    <Button
                      name={<FormattedMessage id="common_approved" defaultMessage="Approved" />}
                      type={Constants.status.APPROVE}
                    />
                  ) : (
                    <Button
                      name={<FormattedMessage id="common_open" defaultMessage="Open" />}
                      type={Constants.status.OPEN}
                    />
                  )}
                </ButtonWrapper>
              </TableData>

              <TableData
                className="col-auto"
                ref={(filterOptionRef) => {
                  this.filterOptions[index] = filterOptionRef;
                }}
              >
                <TableValue>
                  <WhiteButton onClick={this.handleOptions(index)}>
                    <img
                      src={Constants.icons.ThreeDots}
                      alt="icon"
                      className="cursor-pointer"
                    />
                  </WhiteButton>
                  {openColumn === index && (
                  <Card
                    onBlur={this.handleDisplayOptions}
                    tabIndex="-1"
                    id="clickable-component"
                    onLoad={this.toFocusOnDiv}
                  >
                    <>
                      <Div
                        onClick={handleApprove(obj)}
                        role="button"
                        className={obj && obj.status === Constants.status.APPROVED ? 'disableOption' : ''}
                      >
                        <Span>
                          <FormattedMessage id="common_approve" defaultMessage="Approve" />
                        </Span>
                      </Div>
                      <Border />
                      <Div role="button" onClick={handleDelete(obj)}>
                        <Span>
                          <FormattedMessage id="common_delete" defaultMessage="Delete" />
                        </Span>
                      </Div>
                      {title && (
                        <>
                          <Border />
                          <Div
                            role="button"
                            onClick={handleSecondaryTitle(obj)}
                            className={obj && obj.status !== Constants.status.APPROVED ? 'disableOption' : ''}
                          >
                            <Span>
                              <FormattedMessage id="common_secondary_title" defaultMessage="Secondary Title" />
                            </Span>
                          </Div>
                        </>
                      )}
                    </>
                  </Card>
                  )}
                </TableValue>
              </TableData>
            </Wrapper>
          </>
        ))}
      </>
    );
  }
}
export default ListView;
