import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
} from '../../../components/Modals/styles';
import Constants from '../../../shared/constants';
import TextBox from '../../../components/Input/TextBox';
import UserService from '../../../services/AdminServices/UserService';
import Config from '../../../config';
import Select from '../../../components/Input/Select';
import ModalLoader from '../../../components/Loader/ModalLoader';

class Modal extends Component {
  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      role: '',
      emailError: false,
      isDisabled: true,
      formValid: {
        firstName_isValid: false,
        lastName_isValid: false,
        email_isValid: false,
        role_isValid: false,
      },
      userOptions: [
        { label: Constants.language.common_cv_verification, value: Constants.role.CV_VERIFICATION },
        { label: Constants.language.common_admin, value: Constants.role.Admin },
      ],
      loading: false,
    };
  }

  componentDidMount() {
    const { editUser, data = {} } = this.props;
    if (editUser && data) {
      this.setState({
        email: data.email || '',
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        id: data.id || '',
        role: data.role_name || '',
      });
    }
  }

  /**
   * Handles change in input fields
   * @param {Event} event
   * @listens Event
   */
  handleChange = (event) => {
    let { emailError } = this.state;
    if (event.target.name === 'email') {
      emailError = false;
    }
    this.setState({ [event.target.name]: event.target.value, emailError }, this.validate);
  };

  /**
   * Validates form
   */
  validate = () => {
    const {
      firstName, lastName, email, formValid, role,
    } = this.state;
    let error = false;
    if (validator.isEmpty(lastName)) {
      formValid.lastName_isValid = false;
    } else {
      formValid.lastName_isValid = true;
    }
    if (validator.isEmpty(firstName)) {
      formValid.firstName_isValid = false;
    } else {
      formValid.firstName_isValid = true;
    }
    if (!validator.isEmail(email)) {
      formValid.email_isValid = false;
    } else {
      formValid.email_isValid = true;
    }
    if (validator.isEmpty(role)) {
      formValid.role_isValid = false;
    } else {
      formValid.role_isValid = true;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  };

  /**
   * Updates user details
   * @param {Event} event
   * @listens Event
   */
  handleUpdateUser = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const {
      email, firstName, lastName, id, role,
    } = this.state;
    const { handleToast } = this.props;
    const data = { name: { value: `${firstName} ${lastName}` }, email: { value: email }, role: { value: role } };
    try {
      const response = await this.userService.editUser(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_user_updated,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      const { response = {} } = e;
      const { data: errordata = {} } = response;
      this.setState({ loading: false });
      if (errordata && errordata.message === 'Email Id Already Exists') {
        this.setState({ emailError: true });
      } else {
        this.closeElement.click();
        handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_try_after_time,
          delay: Constants.toast.DELAY,
        });
      }
    }
  };

  /**
   * Removes user
   */
  handleRemoveUser = async () => {
    this.setState({ loading: true });
    const { userId, handleToast } = this.props;
    try {
      const response = await this.userService.removeUser(userId);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_user_removed,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * Creates user
   */
  handleCreateUser = async () => {
    this.setState({ loading: true });
    const {
      email, firstName, lastName, role,
    } = this.state;
    const { handleToast } = this.props;
    const data = {
      first_name: firstName, last_name: lastName, email: { value: email }, role: { value: role },
    };
    try {
      const response = await this.userService.addUser(data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_user_created,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      const { response = {} } = e;
      const { data: errordata = {} } = response;
      if (errordata && errordata.message === 'User already exists with same email') {
        this.setState({ emailError: true });
      } else {
        this.closeElement.click();
        handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_try_after_time,
          delay: Constants.toast.DELAY,
        });
      }
    }
  };

  /**
   * Handles change in role
   * @param {String} name name of the field
   * @param {String} label label of selected role
   * @param {String} value value of selected role
   */
  handleUsersRoleChange=(name, label, value) => {
    this.setState({ [name]: value }, this.validate);
  }
  /**
   * Delete user modal ui
   */

  renderdeleteUserBody = () => {
    const { ModalClose } = this.props;
    return (
      <>
        <Text>
          <FormattedMessage id="admin_user_delete_confirmation" defaultMessage="Are you sure you want to delete?" />
        </Text>
        <Input>
          <Button onClick={this.handleRemoveUser}>
            <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
          </Button>
          <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
            <FormattedMessage id="common_no" defaultMessage="No" />
          </CancelButton>
        </Input>
      </>
    );
  };

  /**
   * Edit user modal ui
   */
  renderEditUser = () => {
    const {
      firstName, lastName, email, isDisabled, emailError, userOptions, role,
    } = this.state;
    const { ModalClose } = this.props;
    return (
      <>
        <Input>
          <TextBox
            type="text"
            className="disableOption"
            name="firstName"
            value={firstName}
            label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <TextBox
            type="text"
            name="lastName"
            className="disableOption"
            value={lastName}
            label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <TextBox
            type="email"
            name="email"
            label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
            value={email}
            onChange={this.handleChange}
            existError={emailError}
            existErrorMessage={<FormattedMessage id="error_email_use" defaultMessage="E-mail id already in use" />}
            errorMessage={<FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />}
            rule={{
              method: validator.isEmail,
              validWhen: true,
            }}
          />
        </Input>
        <Input>
          <Select
            options={userOptions}
            search={false}
            name="role"
            label={<FormattedMessage id="common_role" defaultMessage="Role" />}
            onChange={this.handleUsersRoleChange}
            value={
              role === Constants.role.Admin ? (
                Constants.language.common_admin
              ) : role === Constants.role.CV_VERIFICATION ? (
                Constants.language.common_cv_verification
              ) : ''
            }
            errorMessage={<FormattedMessage id="error_select_role_message" defaultMessage="Select user role" />}
          />
        </Input>
        <Input>
          <Button disabled={isDisabled} onClick={this.handleUpdateUser}>
            <FormattedMessage id="common_update" defaultMessage="Update" />
          </Button>
          <CancelButton
            data-dismiss="modal"
            aria-label="Close"
            onClick={ModalClose}
            ref={(closeModal) => { this.closeElement = closeModal; }}
          >
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  /**
   * Add user modal ui
   */
  renderAddUser = () => {
    const {
      firstName, lastName, email, isDisabled, emailError, userOptions, role,
    } = this.state;
    const { ModalClose } = this.props;
    return (
      <>
        <Input>
          <TextBox
            type="text"
            name="firstName"
            value={firstName}
            label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_first_name" defaultMessage="Enter first name" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <TextBox
            type="text"
            name="lastName"
            value={lastName}
            label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="common_cancel" defaultMessage="Enter last name" />}
            rule={{
              method: validator.isEmpty,
              validWhen: false,
            }}
          />
        </Input>
        <Input>
          <TextBox
            type="email"
            name="email"
            label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
            value={email}
            onChange={this.handleChange}
            errorMessage={<FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />}
            existError={emailError}
            existErrorMessage={<FormattedMessage id="error_email_use" defaultMessage="E-mail id is already in use" />}
            rule={{
              method: validator.isEmail,
              validWhen: true,
            }}
          />
        </Input>
        <Input>
          <Select
            options={userOptions}
            search={false}
            name="role"
            label={<FormattedMessage id="common_role" defaultMessage="Role" />}
            onChange={this.handleUsersRoleChange}
            value={
              role === Constants.role.Admin ? (
                Constants.language.common_admin
              ) : role === Constants.role.CV_VERIFICATION ? (
                Constants.language.common_cv_verification
              ) : ''
            }
            errorMessage={<FormattedMessage id="error_select_role_message" defaultMessage="Select user role" />}
          />
        </Input>
        <Input>
          <Button disabled={isDisabled} onClick={this.handleCreateUser}>
            <FormattedMessage id="common_add" defaultMessage="Add" />
          </Button>
          <CancelButton
            data-dismiss="modal"
            aria-label="Close"
            onClick={ModalClose}
            ref={(closeModal) => { this.closeElement = closeModal; }}
          >
            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
          </CancelButton>
        </Input>
      </>
    );
  };

  render() {
    const {
      editUser, remove, addUser, ModalClose,
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="UserModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-small" role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      {!remove && (
                      <Header>
                        {editUser ? (
                          <FormattedMessage id="common_edit_user" defaultMessage="Edit user" />
                        ) : (
                          <FormattedMessage id="common_add_user" defaultMessage="Add user" />
                        )}
                      </Header>
                      )}
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {loading && <ModalLoader />}
                  {(remove && !loading) && this.renderdeleteUserBody()}
                  {(editUser && !loading) && this.renderEditUser()}
                  {(addUser && !loading) && this.renderAddUser()}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
