import styled from 'styled-components';

export const Div = styled.div`
  width: 100%;
`;
export const Slide = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  display: ${({ open }) => (open ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
`;
