import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer, Wrapper, ImageWrapper, Link,
} from '../SignUp/styles';
import { PoweredByLogo, ModalImage } from '../../styles/Images';
import Button from '../../components/Button/Button';
import { LoginSectionLeft, LoginSectionRight, LoginHeader } from './styles';
import Constants from '../../shared/constants';
import Config from '../../config';

const language = localStorage.getItem('language') ?? 'sv';

const Modal = ({ handleRedirect }) => (
  <>
    <div
      className="modal fade bd-example-modal-lg fade-profile-modal login-modal"
      id="LoginModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-large m-auto d-flex h-100" role="document">
        <Wrapper customWidth className="my-0 mx-auto align-self-center" minHeight="200px">
          <div className="modal-content contracts-modal bg-green rounded border-0">
            <div className="modal-body rounded p-0 m-0 ">
              <FlexContainer bgImage="none">
                <LoginSectionLeft className="p-4">
                  <div className="p-4">
                    <img
                      className="gig-logo gm-loaded gm-observing gm-observing-cb my-2"
                      src={PoweredByLogo}
                      loading="lazy"
                      alt="logo"
                      width="150px"
                      height="30px"
                    />

                    <LoginHeader className="py-4">
                      <FormattedMessage
                        id="login_modal_header"
                        defaultMessage="Create company account for free and get gigger details"
                      />
                    </LoginHeader>
                    <div className="d-flex mt-2 py-4">
                      <div className="mr-2">
                        <Button
                          type="cancel"
                          name={<FormattedMessage id="gigger_sign_up" defaultMessage="Sign Up" />}
                          onClick={
                            () => handleRedirect(Constants.routes.customerSignUpWithOutBankId.url)
}
                        />
                      </div>
                      <div className="ml-2">
                        <Button
                          type="update"
                          name={<FormattedMessage id="common_log_in" defaultMessage="Log in" />}
                          onClick={() => handleRedirect(Constants.routes.logIn.url)}
                        />
                      </div>
                    </div>
                    <div className="d-flex w-75 mt-3">
                      <p className="m-0">
                        <Link href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].home}/`} target="_self">
                          <FormattedMessage id="login_modal_footer" defaultMessage="Log in" />
                        </Link>
                      </p>
                    </div>
                  </div>
                </LoginSectionLeft>
                <LoginSectionRight>
                  <ImageWrapper backgroundImage={ModalImage} backgroundSize="cover" />
                </LoginSectionRight>
              </FlexContainer>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  </>
);

export default Modal;
