import React from 'react';
import Constants from '../../shared/constants';
import { Container, Title } from './Style';
import { Content, MainContent, Image } from '../../styles/CommonStyles';

const Popover = ({
  type, onClick, infoData, isOpen,
}) => {
  const { title, brief } = infoData;
  function toFocusOnDiv() {
    document.getElementById('pop-up-clickable-component').focus();
  }
  return (
    <Content className="d-inline-block position-relative">
      {type === 'info' && (
        <>
          <Image
            src={Constants.icons.InfoIcon}
            width="16"
            height="16"
            alt="icon"
            role="button"
            className="mr-2"
            onClick={onClick}
          />
          {isOpen && (
            <Container
              className="popover"
              onBlur={onClick}
              tabIndex="-1"
              id="pop-up-clickable-component"
              onLoad={() => toFocusOnDiv}
            >
              <Content className="row justify-content-between mb-1">
                <MainContent className="col ">
                  <Title className="d-inline-block  mb-0 mr-2 ">{title}</Title>
                </MainContent>
                <MainContent className="col-2 text-right ">
                  <Image
                    src={Constants.icons.CrossNew1}
                    width="12.8"
                    height="12.8"
                    alt="icon"
                    role="button"
                    opacity="0.5"
                    className="opacity-7"
                    onClick={onClick}
                  />
                </MainContent>
              </Content>
              <Content className="row">
                <MainContent className="col font-weight-normal">{brief}</MainContent>
              </Content>
            </Container>
          )}
        </>
      )}
    </Content>
  );
};

export default Popover;
