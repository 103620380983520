import ApiService from '../ApiService';

class FeedbackService extends ApiService {
  endPoint = {
    feedback: 'admin/feedbacks',
    editfeedback: 'admin/feedback/',
    requestFeedback: 'giggers/me/request-external-feedback',
  };

  async getFeedbackList(queryParam) {
    const response = await this.get(this.baseURL
      + this.endPoint.feedback + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async editFeedback(id, data) {
    const response = await this.put(this.baseURL + this.endPoint.editfeedback + id, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveFeedback(id) {
    const response = await this.post(`${this.baseURL + this.endPoint.editfeedback + id}/approve`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async deleteFeedback(id) {
    const response = await this.delete(this.baseURL + this.endPoint.editfeedback + id);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async requestFeedback(payload) {
    return this.post(`${this.baseURL}${this.endPoint.requestFeedback}`, payload).then((response) => response.data);
  }
}
export default FeedbackService;
