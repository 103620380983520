import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BaseComponent from '../BaseComponent';
import Constants from '../../shared/constants';
import { DefaultProfile } from '../../styles/Images';
import {
  Span, Section, Content, Card, SpanDes, CloseIcon, ReminderCard, ReminderHeader, ReminderDes,
} from './styles';

class Cards extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      message: this.props.reminder ? this.props.reminder : false,
      pinkCard: this.props.pinkCard ? this.props.pinkCard : false,
      isFadingOut: false,
    };
  }

  componentDidMount() {}

  /**
   * Adds FadeOut animation when card is closing
   */
  fadeOut = () => {
    this.setState({ isFadingOut: true });
  };

  /**
   * Closes the card
   */
  handleRemoveItem = () => {
    this.props.onClick();
    this.setState({ isFadingOut: false });
  };

  render() {
    const { message, isFadingOut } = this.state;
    const {
      header,
      description,
      onClick,
      pinkCard,
      customerDetailPinkCard,
      icon,
      children,
      bgColor,
      removeDeleteButton,
      iconUrl,
      className,
      subheader,
      subheaderText,
    } = this.props;
    return (
      <>
        {message ? (
          pinkCard ? (
            <ReminderCard pinkCard aria-labelledby="reminderPinkCard" className={isFadingOut ? 'item-fadeout' : ''}>
              <Section className="col">
                <Section className="row">
                  {' '}
                  <Content className={!removeDeleteButton && 'col-8'}>
                    <ReminderHeader>{header}</ReminderHeader>
                  </Content>
                  {message && !removeDeleteButton && (
                    <Content message className="col-4">
                      {' '}
                      <Span>
                        <CloseIcon
                          src={Constants.icons.CircleX}
                          alt={Constants.icons.CircleX}
                          width="16px"
                          height="16px"
                          data-dismiss="reminderPinkCard"
                          onClick={() => (
                            this.fadeOut(setTimeout(() => this.handleRemoveItem(), 2000))
                          )}
                        />
                      </Span>
                    </Content>
                  )}
                </Section>
              </Section>
              {subheader && (
                <Section className="row m-0">
                  <b>{subheaderText}</b>
                </Section>
              )}
              <Section className="row m-0 d-contents">
                <Content>
                  <ReminderDes>
                    {description}
                  </ReminderDes>
                </Content>
              </Section>
            </ReminderCard>
          ) : (
            <ReminderCard aria-labelledby="reminderCard" className={isFadingOut ? 'item-fadeout' : ''}>
              <Section className="col">
                <Section className="row mb-3">
                  {' '}
                  <Content className="col-8">
                    <ReminderHeader>{header}</ReminderHeader>
                  </Content>
                  {message && (
                    <Content message className="col-4">
                      {' '}
                      <Span>
                        <CloseIcon
                          src={Constants.icons.CircleX}
                          alt={Constants.icons.CircleX}
                          width="16px"
                          height="16px"
                          data-dismiss="reminderCard"
                          onClick={() => (
                            this.fadeOut(setTimeout(() => this.handleRemoveItem(), 2000))
                          )}
                          // onClick={onClick}
                        />
                      </Span>
                    </Content>
                  )}
                </Section>
              </Section>
              <Section className="row">
                <Section className="col">
                  <Content>
                    <ReminderDes>{description}</ReminderDes>
                  </Content>
                </Section>
              </Section>
            </ReminderCard>
          )
        ) : icon ? (
          <Card bgColor={bgColor}>
            <Section className="row m-0">
              <Content className="col-auto mr-3">
                {iconUrl !== null ? (
                  <div
                    className="image-crop-no-image"
                    style={{
                      backgroundImage: `url("${iconUrl}")`,
                      backgroundSize: 'cover !important',
                      height: '46px',
                      width: '46px',
                    }}
                  />
                ) : (
                  <div
                    className="image-crop-no-image"
                    style={{
                      backgroundImage: `url("${DefaultProfile}")`,
                      backgroundSize: 'cover !important',
                      height: '46px',
                      width: '46px',
                    }}
                  />
                )}
              </Content>
              <Content className="col">
                <Content className="row m-0">
                  <Span onClick={onClick} addFont>
                    {/* {this.props.hasProfile ? (
                      <Link to={{ pathname: `/details/${this.props.name}-${this.props.id}` }}>
                        <u className="color-black">{header}</u>
                      </Link>
                    ) : (
                      <Link to={{ pathname: `/profile/${this.props.name}-${this.props.id}` }}>
                        <u className="color-black">{header}</u>
                      </Link>
                    )} */}
                    <u className="color-black">{header}</u>
                  </Span>
                </Content>
                <Content className="row m-0">
                  <SpanDes>{description}</SpanDes>
                </Content>
              </Content>
            </Section>
          </Card>
        )
          : customerDetailPinkCard ? (
            <ReminderCard customerDetailPinkCard className={isFadingOut ? 'item-fadeout' : ''}>
              <Content className=" d-flex justify-content-center">
                <ReminderHeader>{header}</ReminderHeader>
              </Content>
              {subheader && (
              <Section className="row m-0">
                <b>{subheaderText}</b>
              </Section>
              )}
              <Section className="row m-0 d-contents">
                <Content>
                  <ReminderDes>
                    {description}
                  </ReminderDes>
                </Content>
              </Section>
            </ReminderCard>
          ) : (
            <Card bgColor={bgColor} className={className}>
              <Section className="col">
                <Section className="row">
                  <Content className="col">
                    {header === 'Admin Portal' ? (
                      <Link to={{ pathname: '/admin/portal/gigger-list' }}>
                        <Span>
                          <u>{header}</u>
                        </Span>
                      </Link>
                    ) : (
                      <Span onClick={onClick} addFont>
                        <u>
                          {header}
                        </u>
                      </Span>
                    )}
                  </Content>
                </Section>
              </Section>
              <Section className="row">
                <Section className="col">
                  <Content>
                    <SpanDes>{description || children}</SpanDes>
                  </Content>
                </Section>
              </Section>
            </Card>
          )}
      </>
    );
  }
}
Card.propTypes = {
  header: PropTypes.node,
  description: PropTypes.node,
  message: PropTypes.bool,
  handleClick: PropTypes.func,
};
export default Cards;
