import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  Container,
  PageName,
  Header,
  Content,
  CurrentPage,
  UserDetailsWrapper,
  SubHeader,
  HeaderName,
  Image,
  ForgotPassword,
  Delete,
  Input,
  UserDetailsExplanation,
  Desktop,
  Mobile,
  Div,
} from '../../pages/customer/styles';
import { Error } from '../../pages/SignUp/styles';
import TextBox from '../Input/TextBox';
import ChangeButton from '../Button/Button';
import PasswordBox from '../Input/PasswordBox';
import Constants from '../../shared/constants';
import Loader from '../Loader/Loader';
import {
  isCustomer, isGigger, isAdmin,
} from '../../shared/utils';
import { ButtonsWrapper } from '../../pages/gigger/styles';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import GiggerCompanyDetails from '../../pages/gigger/CompanyDetails';

const taiPasswordStrength = require('tai-password-strength');

class UserDetailsUI extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
      isDisabled: true,
      expandPassword: false,
      expandDetails: false,
      expandAddressDetails: false,
      newPassword: '',
      repeatPassword: '',
      oldPassword: '',
      passwordsValid: {
        oldPassword_isValid: false,
        newPassword_isValid: false,
        repeatPassword_isValid: false,
      },
      changePasswordDisabled: true,
      changeAddressDisabled: true,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      emailInvoice: '',
      formValid: {
        firstName_isValid: false,
        lastName_isValid: false,
        email_isValid: false,
        emailInvoice_isValid: false,
        phoneNumber_isValid: false,
      },
      addressFormValid: {
        address_isValid: false,
        city_isValid: false,
        zipcode_is_Valid: true,
        companyPhoneNumberIsValid: true,
      },
      billingRef: '',
      emailError: props.emailError || false,
      phoneNumError: false,
      orgNumber: '',
      companyName: '',
      address: '',
      zipcode: '',
      city: '',
      zipcodeError: false,
      loggedIn: this.authService.isAuthenticated(),
    };
  }

  componentDidMount=() => {
    const { data = {} } = this.props;
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchCompanyDetails();
    }
    this.onDataUpdation(data);
  }

  componentDidUpdate(prevProps) {
    const { data, emailError } = this.props;
    if (prevProps.data !== data) {
      this.onDataUpdation(data);
    }
    if (prevProps.emailError !== emailError) {
      this.handleUpdateEmailError();
    }
  }

  /**
   * Setstates email error value.
   */
  handleUpdateEmailError=() => {
    const { emailError } = this.props;
    this.setState({ emailError });
  }

  /**
   * Updation of user data in form.
   * @param {object} data Data of the user.
   */
  onDataUpdation = (data = {}) => {
    const {
      first_name: firstName = '',
      last_name: lastName = '',
      email = '',
      invoice_email: emailInvoice = '',
      phone = '',
      billing_reference: billingRef = '',
      invoiceDetails = {},
    } = data;

    const { address = '', zip: zipcode = '', city = '' } = invoiceDetails || {};
    this.setState({
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      emailInvoice: emailInvoice || '',
      phone: phone || '',
      billingRef: billingRef || '',
      address: address || '',
      zipcode: zipcode || '',
      city: city || '',
    });
  }

  /**
   * Handles change in input element value.
   * @param {event} event
   */
  handleChange = (event) => {
    const { name = '', value = '' } = event.target;
    let { emailError, phone } = this.state;
    if (name === Constants.email) {
      emailError = false;
    }

    if (name === Constants.phone
      && validator.matches(value.toString(), Constants.phoneNumberRegexPattern)) {
      phone = value;
    }

    this.setState({
      [name]: value,
      phone,
      emailError,
    }, this.validate);
  };

  /**
   * handles change in password input element
   * @param {event} event
   */
    handleAddressDetailChange = (event) => {
      const { name, value } = event.target;
      let { companyPhoneNumber } = this.state;
      if (name === 'companyPhoneNumber'
        && validator.matches(value.toString(), Constants.phoneNumberRegexPattern)) {
        companyPhoneNumber = value;
      }
      this.setState(
        { [name]: value, companyPhoneNumber },
        this.validateAddressDetails,
      );
    };

  /**
   * handles change in password input element
   * @param {event} event
   */
  handlePasswordChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      { [name]: value, showRequirementsDoesntMatch: false },
      this.validatePassword,
    );
  };

  /**
   * Handles change in repeat password element
   * @param {event} event
   */
  handleRepeatPasswordChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      showPwdDoesntMatch: false,
    },
    this.validatePassword);
  };

  /**
   * Verifies whether password reaches the requirements in on blur event.
   */
  handlePasswordBlur = () => {
    let { RequirementsDoesntMatch } = false;
    const { newPassword } = this.state;
    if (!validator.isEmpty(newPassword)) {
      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(newPassword);
      RequirementsDoesntMatch = validation
        && validation.charsets
        && (validation.charsets.symbol || validation.charsets.punctuation)
        && validation.charsets.lower
        && validation.charsets.upper
        && validation.charsets.number
        && validation.passwordLength >= 8;
      this.setState({ showRequirementsDoesntMatch: !RequirementsDoesntMatch });
    }
  };

  /**
   * Verifies whether password and repeat password are same or different in on blur event of
   * repeat password input element.
   */
  handleRepeatPwdBlur = () => {
    let { DoesntMatch } = false;
    const { newPassword, repeatPassword } = this.state;
    if (!validator.isEmpty(newPassword) && !validator.isEmpty(repeatPassword)) {
      DoesntMatch = newPassword === repeatPassword;
      this.setState({ showPwdDoesntMatch: !DoesntMatch });
    }
  };

  /**
   * handles on key press event of the input element.
   * @param {event} evt
   */
    handleKeyPress=(evt) => {
      const theEvent = evt || window.event;
      const { name, value } = theEvent.target;

      if (name === Constants.zipcode && value.length >= Constants.zipcodeLength) {
        theEvent.preventDefault();
      }
    }

  /**
   * Expands user details accordion
   */
  handleExpandDetails = () => {
    const { expandDetails } = this.state;
    this.setState({ expandDetails: !expandDetails });
  };

  /**
   * Expands user details password accordion
   */
  handleExpandPassword = () => {
    const { expandPassword } = this.state;
    this.setState({ expandPassword: !expandPassword });
  };

  /**
   * Expands location details accordion
   */
  handleExpandAddressDetails = () => {
    const { expandAddressDetails } = this.state;
    this.setState({ expandAddressDetails: !expandAddressDetails });
  };

  /**
   * @function handleChangePassword
   * @callback handleChangePassword
   * Sends password data to parent.
   */
  handleChangePassword=() => {
    const { handleChangePassword } = this.props;
    const { oldPassword, repeatPassword, newPassword } = this.state;
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirmation: repeatPassword,
    };
    handleChangePassword(data);
    this.setState({
      oldPassword: '',
      repeatPassword: '',
      newPassword: '',
    });
  }

  /**
   * @function handleSubmitDetails
   * @callback handleSubmitDetails
   * Sends the user data to parent.
   */
  handleSubmitDetails=() => {
    const { handleSubmitDetails } = this.props;
    const {
      firstName, lastName, email, emailInvoice, billingRef, phone,
    } = this.state;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      invoice_email: emailInvoice,
      phone,
    };
    if (isCustomer()) {
      data.billing_reference = billingRef || '';
    }
    handleSubmitDetails(data);
  }

  /**
   * @function handleSubmitAddressDetails
   * @callback handleSubmitAddressDetails
   * Sends the user data to parent.
   */
    handleSubmitAddressDetails=() => {
      const { handleSubmitDetails } = this.props;
      const {
        address, city, zipcode, email, companyPhoneNumber,
      } = this.state;
      const data = {
        email,
        address,
        city,
        zip: zipcode,
        company_phone_number: companyPhoneNumber,
      };
      handleSubmitDetails(data);
    }

    /**
   * @async
   * @function fetchCompanyDetails Fetches company details of the user
   */
    async fetchCompanyDetails() {
      const response = await this.customerService.companyDetails();
      const { data = [] } = response;
      const {
        organization_number: orgNumber = '', company_name: companyName = '', invoice_email: emailInvoice = '',
      } = data;
      this.setState({
        orgNumber,
        companyName,
        emailInvoice,
      });
    }

    /**
   * Validates change password form
   */
    validatePassword() {
      const {
        oldPassword, newPassword, repeatPassword, passwordsValid,
      } = this.state;
      let error = false;

      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(newPassword);
      const doesntMatch = newPassword === repeatPassword;
      const doesntMeetRequirements = validation
      && validation.charsets
      && (validation.charsets.symbol || validation.charsets.punctuation)
      && validation.charsets.lower
      && validation.charsets.upper
      && validation.charsets.number
      && validation.passwordLength >= 8;

      if (doesntMatch && doesntMeetRequirements) {
        passwordsValid.newPassword_isValid = true;
        passwordsValid.repeatPassword_isValid = true;
      } else {
        passwordsValid.newPassword_isValid = false;
        passwordsValid.repeatPassword_isValid = false;
      }
      if (validator.isEmpty(oldPassword)) {
        passwordsValid.oldPassword_isValid = false;
      } else {
        passwordsValid.oldPassword_isValid = true;
      }
      Object.keys(passwordsValid).map((obj) => {
        if (passwordsValid[obj] === false) {
          error = true;
        }
        return error;
      });
      this.setState({ changePasswordDisabled: error });
      return { error, passwordsValid };
    }

    /**
   * Validates user details form
   */
    validate() {
      const {
        firstName = '', lastName = '', email = '', emailInvoice = '', formValid = {}, phone = '',
      } = this.state;
      let error = false;
      if (validator.isEmpty(firstName)) {
        formValid.firstName_isValid = false;
      } else {
        formValid.firstName_isValid = true;
      }
      if (validator.isEmpty(lastName)) {
        formValid.lastName_isValid = false;
      } else {
        formValid.lastName_isValid = true;
      }
      if (!validator.isEmail(email)) {
        formValid.email_isValid = false;
      } else {
        formValid.email_isValid = true;
      }
      formValid.emailInvoice_isValid = isCustomer() ? validator.isEmail(emailInvoice) : true;
      formValid.phoneNumber_isValid = validator.matches(phone.toString(),
        Constants.phoneNumberRegexPattern);
      Object.keys(formValid).map((obj) => {
        if (formValid[obj] === false) {
          error = true;
        }
        return error;
      });
      this.setState({ isDisabled: error });
      return { error, formValid };
    }

    /**
   * Validates address details form
   */
    validateAddressDetails() {
      const {
        addressFormValid, address, city, zipcode,
      } = this.state;
      let error = false;
      if (validator.isEmpty(address)) {
        addressFormValid.address_isValid = false;
      } else {
        addressFormValid.address_isValid = true;
      }
      if (validator.isEmpty(city)) {
        addressFormValid.city_isValid = false;
      } else {
        addressFormValid.city_isValid = true;
      }
      if (zipcode && zipcode.length > 0 && zipcode.length < 5) {
        addressFormValid.zipcode_is_Valid = false;
      } else {
        addressFormValid.zipcode_is_Valid = true;
      }
      Object.keys(addressFormValid).map((obj) => {
        if (addressFormValid[obj] === false) {
          error = true;
        }
        return error;
      });
      this.setState({ changeAddressDisabled: error });
      return { error, addressFormValid };
    }

    render() {
      const {
        showRequirementsDoesntMatch,
        isDisabled,
        changePasswordDisabled,
        changeAddressDisabled,
        expandDetails,
        expandPassword,
        expandAddressDetails,
        showPwdDoesntMatch,
        firstName,
        lastName,
        email,
        emailInvoice,
        phone,
        address,
        zipcode,
        city,
        oldPassword,
        newPassword,
        repeatPassword,
        billingRef,
        emailError,
        phoneNumError,
        formValid,
        zipcodeError,
        orgNumber,
        companyName,
      } = this.state;
      const {
        oldPasswordError,
        loading,
        handleDelete,
        handleMyAccount,
        handleForgotPassword,
        handleRedirect,
        handleAddressDetails,
      } = this.props;
      return (
        <>
          <FlexContainer>
            <Container>
              <Content className="row">
                <PageName className="opacity-7 pr-1" onClick={handleMyAccount} role="button">
                  {`${Constants.language.common_my_account} > `}
                </PageName>
                <CurrentPage>
                  <FormattedMessage id="common_user_details" defaultMessage="User Details" />
                </CurrentPage>
              </Content>
              <Desktop>
                <Content className="row d-flex justify-content-between">
                  <Header>
                    <FormattedMessage id="common_user_details" defaultMessage="User Details" />
                  </Header>
                  {!isAdmin() && !isGigger() && (
                  <Div className="p-0 mr-lg-3 pr-lg-1">
                    <ButtonsWrapper>
                      <ChangeButton
                        id="search"
                        type="update"
                        name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                        onClick={handleRedirect}
                      />
                    </ButtonsWrapper>
                  </Div>
                  )}
                </Content>
              </Desktop>
              <Mobile>
                <Content className="row">
                  {loading ? (
                    <Loader width="150px" />
                  ) : (
                    <>
                      <Div className="mt-2 col-md-12 text-right">
                        <ButtonsWrapper>
                          <ChangeButton
                            id="search"
                            type="update"
                            name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                            onClick={handleRedirect}
                          />
                        </ButtonsWrapper>
                      </Div>
                      <Header className="my-1">
                        <FormattedMessage id="common_my_giggers" defaultMessage="My Giggers" />
                      </Header>
                    </>
                  )}
                </Content>
              </Mobile>

              <Content className="row ">
                {isAdmin() && (
                <UserDetailsExplanation>
                  <FormattedMessage
                    id="common_edit_user_details_description"
                    defaultMessage="Edit my personal account settings"
                  />
                </UserDetailsExplanation>
                )}
              </Content>

              <Content className="row">
                <UserDetailsWrapper>
                  {loading && <Loader height="40px" />}
                  {(!loading && (
                  <>
                    <Content className="row">
                      <Content className="col-10 p-0">
                        <SubHeader role="button" onClick={this.handleExpandDetails}>
                          <FormattedMessage id="label_my_details" defaultMessage="My details" />
                        </SubHeader>
                      </Content>
                      <Content className="col-2 text-end p-0">
                        <Image
                          src={Constants.icons.ArrowDown}
                          alt="icon"
                          onClick={this.handleExpandDetails}
                          className={expandDetails && 'rotate-180'}
                          role="button"
                        />
                      </Content>
                    </Content>

                    {expandDetails && (
                    <>
                      <Input>
                        <Content className="row">
                          <>
                            <Content className="col-12 p-0">
                              <HeaderName>
                                <FormattedMessage id="common_first_name" defaultMessage="First name" />
                              </HeaderName>
                            </Content>
                            <Content className="col-12 p-0">
                              <TextBox
                                type="text"
                                name="firstName"
                                value={firstName}
                                className={!isGigger() ? 'disableOption' : ''}
                                onChange={this.handleChange}
                                errorMessage={
                                  <FormattedMessage id="error_first_name" defaultMessage="Enter your first name" />
                                }
                                rule={{
                                  method: validator.isEmpty,
                                  validWhen: false,
                                }}
                              />
                            </Content>
                          </>
                        </Content>
                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_last_name" defaultMessage="Last name" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox
                              type="text"
                              name="lastName"
                              value={lastName}
                              className={!isGigger() ? 'disableOption' : ''}
                              onChange={this.handleChange}
                              errorMessage={
                                <FormattedMessage id="error_last_name" defaultMessage="Enter your last name" />
                                }
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          </Content>
                        </Content>

                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_phone" defaultMessage="Phone" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox
                              type="text"
                              name="phone"
                              value={phone}
                              onChange={this.handleChange}
                              existError={phoneNumError}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                              existErrorMessage={(
                                <FormattedMessage
                                  id="error_phone_number_exists_message"
                                  defaultMessage="Enter a valid phone number"
                                />
                                )}
                              errorMessage={
                                <FormattedMessage id="error_phone_message" defaultMessage="Enter a valid phone number" />
                                }
                            />
                          </Content>
                        </Content>

                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_email" defaultMessage="E-mail" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox
                              type="email"
                              name="email"
                              value={email}
                              onChange={this.handleChange}
                              existError={emailError && formValid.email_isValid}
                              existErrorMessage={(
                                <FormattedMessage
                                  id="error_email_taken_message_1"
                                  defaultMessage="This e-mail address is already registered"
                                />
                                )}
                              errorMessage={
                                <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                                }
                              rule={{
                                method: validator.isEmail,
                                validWhen: true,
                              }}
                            />
                          </Content>
                        </Content>
                        {isCustomer() && (
                        <>
                          <Content className="row">
                            <Content className="col-12 p-0">
                              <HeaderName>
                                <FormattedMessage id="label_email_for_invoices" defaultMessage="E-mail for invoices" />
                              </HeaderName>
                            </Content>
                            <Content className="col-12 p-0">
                              <TextBox
                                type="email"
                                name="emailInvoice"
                                value={emailInvoice}
                                onChange={this.handleChange}
                                existError={emailError && formValid.emailInvoice_isValid}
                                existErrorMessage={(
                                  <FormattedMessage
                                    id="error_email_message"
                                    defaultMessage="This e-mail address is already registered"
                                  />
                                )}
                                errorMessage={
                                  <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                                }
                                rule={{
                                  method: validator.isEmail,
                                  validWhen: true,
                                }}
                              />
                            </Content>
                          </Content>
                          <Content className="row">
                            <Content className="col-12 p-0">
                              <HeaderName>
                                <FormattedMessage id="common_billing_refrence" defaultMessage="Billing reference" />
                              </HeaderName>
                            </Content>
                            <Content className="col-12 p-0">
                              <TextBox
                                type="text"
                                name="billingRef"
                                value={billingRef}
                                onChange={this.handleChange}
                                noEmptyError
                              />
                            </Content>
                          </Content>
                        </>
                        )}

                        <Content className="mt-3 mb-4">
                          <ChangeButton
                            type="update"
                            name={<FormattedMessage id="common_update_details" defaultMessage="Update details" />}
                            onClick={this.handleSubmitDetails}
                            disabled={isDisabled}
                          />
                        </Content>
                        <Content className="row">
                          <Delete onClick={handleDelete}>
                            <FormattedMessage id="common_delete_my_user_account" defaultMessage="Delete my account" />
                          </Delete>
                        </Content>
                      </Input>
                    </>
                    )}
                  </>
                  ))}
                </UserDetailsWrapper>
                <UserDetailsWrapper>
                  {loading && <Loader height="40px" />}
                  {!loading && (
                  <>
                    <Content className="row">
                      <Content className="col-10 p-0">
                        <SubHeader role="button" onClick={this.handleExpandPassword}>
                          <FormattedMessage id="common_change_password" defaultMessage="Change password" />
                        </SubHeader>
                      </Content>
                      <Content className="col-2 text-end p-0">
                        <Image
                          src={Constants.icons.ArrowDown}
                          alt="icon"
                          onClick={this.handleExpandPassword}
                          className={expandPassword && 'rotate-180'}
                          role="button"
                        />
                      </Content>
                    </Content>

                    {expandPassword && (
                    <>
                      <Input>
                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_old_password" defaultMessage="Old password" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <PasswordBox
                              type="text"
                              name="oldPassword"
                              value={oldPassword}
                              onChange={this.handlePasswordChange}
                              errorMessage={(
                                <FormattedMessage
                                  id="error_password_validation_message"
                                  defaultMessage="Enter old password"
                                />
                                )}
                              existErrorMessage={(
                                <FormattedMessage
                                  id="error_old_password_message"
                                  defaultMessage="Old password is incorrect"
                                />
                                )}
                              existError={oldPasswordError}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          </Content>
                        </Content>

                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_new_password" defaultMessage="New password" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <PasswordBox
                              type="text"
                              name="newPassword"
                              value={newPassword}
                              classname={showRequirementsDoesntMatch && 'border-red'}
                              onChange={this.handlePasswordChange}
                              onBlur={this.handlePasswordBlur}
                              errorMessage={(
                                <FormattedMessage
                                  id="error_password_validation_message"
                                  defaultMessage="Enter a valid password"
                                />
                                )}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                            {showRequirementsDoesntMatch && (
                            <Error>
                              <Image
                                src={Constants.icons.ErrorIcon}
                                alt="icon"
                                className="mr-1"
                                width="12px"
                                height="12px"
                              />
                              <span>
                                <FormattedMessage
                                  id="error_password_doesnt_meet_requirements"
                                  defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number. "
                                />
                              </span>
                            </Error>
                            )}
                          </Content>
                        </Content>

                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage
                                id="customer_sign_up_repeat_password"
                                defaultMessage="Repeat password"
                              />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <PasswordBox
                              type="text"
                              name="repeatPassword"
                              value={repeatPassword}
                              classname={showPwdDoesntMatch && 'border-red'}
                              onChange={this.handleRepeatPasswordChange}
                              onBlur={this.handleRepeatPwdBlur}
                              errorMessage={(
                                <FormattedMessage
                                  id="error_password_validation_message"
                                  defaultMessage="Enter a valid password"
                                />
                                )}
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                            {showPwdDoesntMatch && (
                            <Error>
                              <Image
                                src={Constants.icons.ErrorIcon}
                                alt="icon"
                                className="mr-1"
                                width="12px"
                                height="12px"
                              />
                              <span>
                                <FormattedMessage
                                  id="error_password_doesnt_match"
                                  defaultMessage="Passwords doesn't match"
                                />
                              </span>
                            </Error>
                            )}
                          </Content>
                        </Content>

                        <Content className="row mt-3 mb-2">
                          <Content className="col-xl-12 col-md-12 col-sm-12 col-xs-12 p-0">
                            <ChangeButton
                              type="update"
                              name={<FormattedMessage id="common_change_password" defaultMessage="Change password" />}
                              disabled={changePasswordDisabled}
                              onClick={this.handleChangePassword}
                            />
                          </Content>
                          <Content className="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-center py-3 px-0">
                            <ForgotPassword onClick={handleForgotPassword}>
                              <FormattedMessage id="login_forgot_password" defaultMessage="Forgot password?" />
                            </ForgotPassword>
                          </Content>
                        </Content>
                        {' '}
                      </Input>
                    </>
                    )}
                  </>
                  )}
                </UserDetailsWrapper>
              </Content>
              {(isCustomer() || isGigger()) && (
              <Content className="row">
                <UserDetailsWrapper>
                  {loading ? (
                    <Loader width="150px" />
                  ) : (
                    <Content className="row">
                      <Content className="col-10 p-0">
                        <SubHeader role="button" onClick={this.handleExpandAddressDetails}>
                          <FormattedMessage id="common_company_settings" defaultMessage="Company settings" />
                        </SubHeader>
                      </Content>
                      <Content className="col-2 text-end p-0">
                        <Image
                          src={Constants.icons.ArrowDown}
                          alt="icon"
                          onClick={this.handleExpandAddressDetails}
                          className={expandAddressDetails && 'rotate-180'}
                          role="button"
                        />
                      </Content>
                    </Content>
                  )}
                  {expandAddressDetails && (
                  <>
                    <Input>
                      {isCustomer() && (
                      <>
                        <Content className="pl-0">
                          {loading ? (
                            <Loader width="150px" />
                          ) : (
                            <>
                              <HeaderName className="mt-2 mb-0">
                                {`${Constants.language.common_orginisation_number}: ${orgNumber}`}
                              </HeaderName>
                              <HeaderName className="mt-2 mb-0">
                                {`${Constants.language.common_company_name}: ${companyName}`}
                              </HeaderName>
                            </>
                          )}
                        </Content>

                        <Content className="row">
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_address" defaultMessage="address" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox type="text" name="address" value={address} onChange={this.handleAddressDetailChange} />
                          </Content>
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_city" defaultMessage="City" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox type="text" name="city" value={city} onChange={this.handleAddressDetailChange} />
                          </Content>
                          <Content className="col-12 p-0">
                            <HeaderName>
                              <FormattedMessage id="common_zip_code" defaultMessage="Zipcode" />
                            </HeaderName>
                          </Content>
                          <Content className="col-12 p-0">
                            <TextBox
                              type="number"
                              name="zipcode"
                              value={zipcode}
                              onChange={this.handleAddressDetailChange}
                              onKeyPress={this.handleKeyPress}
                              existError={zipcodeError && !formValid.zipcode_is_Valid}
                              disabletickMark={!(zipcode
                              && zipcode.length === Constants.zipcodeLength)}
                              onBlur={this.handleBlur}
                              existErrorMessage={
                                <FormattedMessage id="error_zip_code_message" defaultMessage="Enter a valid ZIP code" />
                          }
                              noEmptyError
                            />
                          </Content>
                        </Content>

                        <Content className="mt-3 mb-4">
                          <ChangeButton
                            type="update"
                            name={<FormattedMessage id="common_update_details" defaultMessage="Update details" />}
                            onClick={this.handleSubmitAddressDetails}
                            disabled={changeAddressDisabled}
                          />
                        </Content>
                      </>
                      )}
                      {isGigger() && (
                      <GiggerCompanyDetails
                        loading={loading}
                        handleAddressDetails={handleAddressDetails}
                      />
                      )}
                    </Input>
                  </>
                  )}
                </UserDetailsWrapper>
              </Content>
              )}
            </Container>
          </FlexContainer>
        </>
      );
    }
}

export default UserDetailsUI;
