import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Header from '../../../components/Header/Header';
import Constants from '../../../shared/constants';
import { IconWrapper, Icon } from '../giggers/styles';
import { OrdersListWrapper } from './styles';
import ChangeLanguageModal from '../../../components/Modals/index';
import {
  FlexContainer,
  Container,
} from '../users/styles';
import Pagination from '../../../components/Pagination/Pagination';
import AuthService from '../../../services/AuthService';
import OrderService from '../../../services/AdminServices/OrderService';
import Config from '../../../config';
import LoadingCard from '../LoadingCard';
import SearchInput from '../../../components/Input/SearchInput';
import { getFormattedCSVData } from '../../../shared/utils';
import ListViewHeader from './ListView/ListViewHeader';
import ListViewBody from './ListView/ListViewBody';

class Orders extends Component {
  authService = new AuthService(Config.apiBase);

  orderService = new OrderService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
          pageNumber: 1,
          pageSize: 15,
        },
      },
      searchbar: true,
      searchQuery: '',
      isLangModalOpen: false,
      csvDownloadData: [],

      csvExportFileds: [
        'company_name',
        'company_user_name',
        'gigger_name',
        'assigned_to',
        'created_at',
        'type',
        'status',
        'user_id',
        'gigger_id',
      ],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        cursor: { pageNumber },
      },
    } = this.state;
    const { location } = this.props;
    if (prevState.meta.cursor.pageNumber !== pageNumber
      || location.key !== prevProps.location.key) {
      this.fetchOrders();
    }
  }

  /**
   * Handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { cursor: { ...meta.cursor, pageNumber, pageSize: 15 } },
    }), this.fetchOrders);
  };

  /**
   * Downloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.orderService.getOrderList(queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.ordersCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Handles search functionality
   * @param {string} value search text
   */
  handlesearchOrders=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchOrders(value);
    });
  }

  /**
   * Handles search value change
   * @param {Event} event
   * @listens Event
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Opens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   * Closes the opened modal
   */
  handleModalClose = () => {
    this.setState({
      isLangModalOpen: false,
    });
  };

  /**
   * Shows search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * Fetches orders
   */
  handleLoad = () => {
    this.handleModalClose();
    this.fetchOrders();
  };

  /**
   * @async
   * @function fetchOrders Fetches orders
   * @param {String} searchInput search text
   */
  async fetchOrders(searchInput) {
    const {
      meta: {
        cursor: { pageNumber, pageSize },
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    try {
      const response = await this.orderService.getOrderList(queryParams);
      this.setState({ orderData: response.data, loading: false, meta: response.meta });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      loggedIn,
      orderData,
      loading,
      meta,
      isLangModalOpen,
      searchbar,
      csvDownloadData,
      searchQuery,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <CSVLink data={csvDownloadData} filename="orders.csv" className="d-none" ref={(r) => { this.ordersCsv = r; }}>
          click
        </CSVLink>
        <Header
          name={<FormattedMessage id="common_orders" defaultMessage="common_orders" />}
          listScreen
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
        />
        <FlexContainer>
          <Container>
            <OrdersListWrapper>
              <IconWrapper className="row m-0">
                {searchbar ? (
                  <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
                ) : (
                  <div className="text-end col-auto p-0">
                    <SearchInput
                      onSearch={this.handlesearchOrders}
                      onChange={this.handleSearchOnChange}
                      value={searchQuery}
                    />
                  </div>
                )}
                <Icon
                  src={Constants.icons.ExportIcon}
                  alt="icon"
                  className={orderData && orderData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
                  onClick={this.handleDownloadCsv}
                />
              </IconWrapper>
              {loading && (
                <OrdersListWrapper>
                  <LoadingCard />
                </OrdersListWrapper>
              )}
              {!loading && (
                <>
                  <ListViewHeader admin />
                  <ListViewBody
                    admin
                    handleLoad={this.handleLoad}
                    data={orderData}
                  />
                </>
              )}
            </OrdersListWrapper>
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#AdminModal"
              ref={(openLangModal) => { this.openLangElement = openLangModal; }}
            />
            {isLangModalOpen && (
              <ChangeLanguageModal
                changeLanguage={isLangModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {!loading && meta.cursor.pages > 1 && (
              <OrdersListWrapper style={{ 'background-color': 'transparent' }}>
                <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
              </OrdersListWrapper>
            )}
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default withRouter(Orders);
