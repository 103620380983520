import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Constants from '../../shared/constants';
import GiggerCard from '../../components/ListCard/ListCard';
import Button from '../../components/Button/Button';
import { ButtonsWrapper } from '../gigger/styles';
import {
  FlexContainer,
  Container,
  PageName,
  Header,
  Content,
  CurrentPage,
  MyGiggersWrapper,
  SubHeader,
  DetailCard,
  Div,
  Value,
  Image,
  CardHeader,
  PinkCard,
  Border,
  GreenCard,
  Document,
  NoDataCard,
  A,
  Desktop,
  Mobile,
} from './styles';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import PersistentStorage from '../../shared/PersistentStorage.ts';
import Colors from '../../styles/Colors';
import { tabActions } from '../../state/actions/MyGiggers';

class MyGiggers extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  persistentStorage = new PersistentStorage();

  constructor(props) {
    super(props);
    this.state = {
      purchasedGiggers: [],
      savedGiggers: [],
      hiredGiggers: [],
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      user: JSON.parse(this.persistentStorage.getUserDetails()),
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      window.scrollTo(0, 0);
      this.fetchMyGiggers();
    }
  }

  /**
    *  Redirects to search giggers page
    */
    handleSearchGiggers = () => {
      const { history } = this.props;
      history.push(Constants.routes.giggerList.url);
    };

  /**
   * @async
   * @function handleCancelbutton Answers "NO" for have you hired this gigger question..
   * @param {object} gigger Gigger data
   */
  handleCancelbutton = (gigger) => async () => {
    this.setState({ loading: true });
    const { user } = this.state;
    const { history } = this.props;
    const data = {
      user_id: user._id,
      gigger_id: gigger.id,
      feedback_request: 'false',
      company_gigger_id: gigger.company_gigger_id,
    };
    if (!gigger.expire && !gigger.status) {
      data.company_gigger_id = gigger.company_gigger_id;
    }
    try {
      const response = await this.customerService.createFeedback(data);
      if (response) {
        history.push({
          pathname: `${Constants.routes.hiredDatesPrefix.url}/${user._id}-${gigger.id}`,
          taketoThankYouPage: true,
        });
      }
    } catch (e) {
      this.setState({ loading: false }, this.fetchMyGiggers);
    }
  };

  /**
   * Expands purchased contacts accordion
   */
  handleExpandPurchasedContacts = () => {
    const { expandTabActions, expandPurchasedContacts } = this.props;
    expandTabActions({ expandPurchasedContacts: !expandPurchasedContacts });
  };

  /**
   * Expands hired giggers accordion
   */
  handleExpandHiredGiggers = () => {
    const { expandTabActions, expandHiredGiggers } = this.props;
    expandTabActions({ expandHiredGiggers: !expandHiredGiggers });
  };

  /**
   * Expands saved giggers accordion
   */
  handleExpandSavedGiggers = () => {
    const { expandTabActions, expandSavedGiggers } = this.props;
    expandTabActions({ expandSavedGiggers: !expandSavedGiggers });
  };

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
   * Redirects to provide feedback to gigger page since
   * the user answered yes i have hired this gigger.
   * @param {object} gigger Gigger data
   */
  handleOpenFeedbackForm = (gigger) => () => {
    const { user } = this.state;
    const { history } = this.props;
    history.push(`${Constants.routes.hiredDatesPrefix.url}/${user._id}-${gigger.id}`);
  };

  /**
   * @async
   * @function handleRemoveSavedGigger Removes the gigger from user's saved giggers.
   * @param {number} id Id of the saved gigger.
   */
  handleRemoveSavedGigger = async (id) => {
    this.setState({ loading: true });
    const data = {
      gigger_id: id,
    };
    try {
      const response = await this.customerService.removeSavedGigger(data);
      if (response) {
        this.fetchMyGiggers();
      }
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
    this.fetchMyGiggers();
  };

  /**
   * Opens the document in new window
   * @param {url} url Url of the file.
   */
  openDocument = (url) => () => {
    window.open(url);
  };

  /**
   * @async
   * @function fetchMyGiggers Fetches all the purchased, saved and hired giggers of the user.
   */
  async fetchMyGiggers() {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    try {
      const response = await this.customerService.myGiggers();
      const { data = [] } = response;
      this.setState({
        hiredGiggers: data['hired-gigger'] || [],
        purchasedGiggers: data['purchased-gigger'] || [],
        savedGiggers: data['saved-gigger'] || [],
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
    window.scrollTo(0, 0);
  }

  render() {
    const {
      purchasedGiggers,
      hiredGiggers,
      savedGiggers,
      loading,
      loggedIn,
      user,
    } = this.state;
    const {
      expandPurchasedContacts,
      expandHiredGiggers,
      expandSavedGiggers,
    } = this.props;
    const displaydocTypes = [
      Constants.docsCategory[0],
      Constants.docsCategory[1],
      Constants.docsCategory[2],
    ];

    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <Content className="row">
              <PageName className="opacity-7 " onClick={this.handleMyAccount} role="button">
                <FormattedMessage id="common_my_account" defaultMessage="My account" />
                {' '}
                {' > '}
                {' '}
              </PageName>
              <span>&nbsp;</span>
              <CurrentPage>
                <FormattedMessage id="common_my_giggers" defaultMessage="My Giggers" />
              </CurrentPage>
            </Content>
            <Desktop>
              <Content className="row d-flex justify-content-between">
                {loading ? (
                  <Loader width="150px" />
                ) : (
                  <>
                    <Header className="mb-2">
                      <FormattedMessage id="common_my_giggers" defaultMessage="My Giggers" />
                    </Header>
                    <Div className="p-0 mr-lg-3 pr-lg-1">
                      <ButtonsWrapper>
                        <Button
                          id="search"
                          type="update"
                          name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                          onClick={this.handleSearchGiggers}
                        />
                      </ButtonsWrapper>
                    </Div>
                  </>
                )}
              </Content>
            </Desktop>
            <Mobile>
              <Content className="row">
                {loading ? (
                  <Loader width="150px" />
                ) : (
                  <>
                    <Div className="mt-2 col-md-12 text-right">
                      <ButtonsWrapper>
                        <Button
                          id="search"
                          type="update"
                          name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                          onClick={this.handleSearchGiggers}
                        />
                      </ButtonsWrapper>
                    </Div>
                    <Header className="my-1">
                      <FormattedMessage id="common_my_giggers" defaultMessage="My Giggers" />
                    </Header>
                  </>
                )}
              </Content>
            </Mobile>
            <Content className="row">
              <MyGiggersWrapper>
                {loading ? (
                  <Loader height="40px" />
                ) : (
                  <Content className="row">
                    <Content className="col-10 p-0">
                      <SubHeader role="button" onClick={this.handleExpandPurchasedContacts}>
                        <FormattedMessage id="common_purchased_contacts" defaultMessage="Purchased contacts" />
                      </SubHeader>
                    </Content>
                    <Content className="col-2 text-end p-0">
                      <Image
                        src={Constants.icons.ArrowDown}
                        alt="icon"
                        onClick={this.handleExpandPurchasedContacts}
                        className={expandPurchasedContacts && 'rotate-180'}
                        role="button"
                      />
                    </Content>
                  </Content>
                )}
                {!loading && expandPurchasedContacts && (
                  <>
                    {purchasedGiggers
                      && purchasedGiggers.length > 0
                      && purchasedGiggers.map((gigger, index) => {
                        const url = `/gigger-${
                          gigger.full_name ? gigger.full_name.replace(' ', '-') : '-'}/${gigger.id}`;
                        return (
                          <>
                            <Content className="my-3">
                              <GiggerCard data={{ ...gigger, company: true }} name redirect={url} />
                              {gigger.expire ? (
                                <>
                                  <GreenCard green className="row m-0 mt-3">
                                    <FormattedMessage
                                      id="company_my_giggers_contact_details_expired"
                                      defaultMessage="Contact details expired"
                                    />
                                  </GreenCard>
                                </>
                              ) : (
                                <>
                                  <DetailCard className="col m-0 my-3">
                                    <Div className="row m-0">
                                      <Div className="col-xl-6 col-md-12 col-sm-12 col-xs-12 pl-0 text-truncate">
                                        <Content className="mb-1">
                                          <CardHeader>
                                            <FormattedMessage id="common_email" defaultMessage="E-mail" />
                                          </CardHeader>
                                          <Value>{gigger.email}</Value>
                                        </Content>
                                      </Div>

                                      <Div className="col-6 pl-0 text-truncate">
                                        <Content className="mb-1 mt-2">
                                          <CardHeader>
                                            <FormattedMessage id="common_phone" defaultMessage="Phone" />
                                          </CardHeader>
                                          <Value>{gigger.phone}</Value>
                                        </Content>
                                      </Div>

                                      <Div className="col-12 px-0">
                                        <Content className="mb-1 mt-2">
                                          <CardHeader>
                                            <FormattedMessage id="common_documents" defaultMessage="Documents" />
                                          </CardHeader>
                                          {gigger.docs
                                            && gigger.docs.length > 0
                                            && gigger.docs.map((obj) => (
                                              <Value>
                                                {obj && displaydocTypes.includes(obj.category) && (
                                                  <Document
                                                    onClick={this.openDocument(obj.url)}
                                                    role="button"
                                                  >
                                                    {obj && obj.file_name}
                                                  </Document>
                                                )}
                                              </Value>
                                            ))}
                                        </Content>
                                      </Div>
                                    </Div>
                                    <Div className="row m-0 mt-2">
                                      <Value>
                                        <FormattedMessage
                                          id="company_my_giggers_contact_details_expire_message"
                                          defaultMessage="Contact details expires in 30 days"
                                        />
                                      </Value>
                                    </Div>
                                  </DetailCard>

                                  {!(gigger.status === 'completed' || gigger.status === 'rejected') && (
                                    <PinkCard className="row m-0">
                                      <Div className="col-xl-5 col-md-12 col-sm-12 col-xs-12 p-0 py-2 my-1 text-align-left">
                                        <FormattedMessage
                                          id="company_my_giggers_have_you_hired"
                                          defaultMessage="Have you hired"
                                        />
                                        {' '}
                                        {gigger.full_name}
                                        ?
                                      </Div>
                                      <Div className="col-xl-7 col-md-12 col-sm-12 col-xs-12 p-0">
                                        <Div className="row m-0">
                                          <Div className="col-xl-6 col-md-6 col-sm-12 col-xs-12 p-0">
                                            <Button
                                              type="update"
                                              name={(
                                                <FormattedMessage
                                                  id="mygigger_yes_i_have"
                                                  defaultMessage="Yes, I have"
                                                />
                                              )}
                                              onClick={this.handleOpenFeedbackForm(gigger)}
                                            />
                                          </Div>
                                          {' '}
                                          <Div className="col-xl col-md col-sm-12 col-xs-12 p-0">
                                            <Button
                                              name={(
                                                <FormattedMessage
                                                  id="mygigger_no_i_havenot"
                                                  defaultMessage="No, I haven't"
                                                />
                                              )}
                                              onClick={this.handleCancelbutton(gigger)}
                                            />
                                          </Div>
                                          {' '}
                                        </Div>
                                      </Div>
                                    </PinkCard>
                                  )}
                                </>
                              )}
                            </Content>
                            {purchasedGiggers && purchasedGiggers.length - 1 === index ? '' : <Border />}
                          </>
                        );
                      })}
                    {(purchasedGiggers === undefined
                      || (purchasedGiggers && purchasedGiggers.length === 0)
                      || purchasedGiggers === null) && (
                      <>
                        <NoDataCard>
                          <FormattedMessage
                            id="company_my_giggers_havent_purchased_any_gigger"
                            defaultMessage="You haven't purchased any gigger yet"
                          />
                        </NoDataCard>
                      </>
                    )}
                  </>
                )}
              </MyGiggersWrapper>
              <MyGiggersWrapper>
                {loading ? (
                  <Loader height="40px" />
                ) : (
                  <Content className="row">
                    <Content className="col-10 p-0">
                      <SubHeader role="button" onClick={this.handleExpandSavedGiggers}>
                        <FormattedMessage id="company_my_giggers_saved_giggers" defaultMessage="Saved Giggers" />
                      </SubHeader>
                    </Content>
                    <Content className="col-2 text-end p-0">
                      <Image
                        src={Constants.icons.ArrowDown}
                        alt="icon"
                        onClick={this.handleExpandSavedGiggers}
                        className={expandSavedGiggers && 'rotate-180'}
                        role="button"
                      />
                    </Content>
                  </Content>
                )}
                {!loading && expandSavedGiggers && (
                  <>
                    {savedGiggers
                      && savedGiggers.length > 0
                      && savedGiggers.map((gigger, index) => {
                        const url = `/gigger-${
                          gigger.full_name ? gigger.full_name.replace(' ', '-') : '-'}/${gigger.id}`;
                        return (
                          <>
                            <Content className="my-3">
                              <GiggerCard
                                data={{ ...gigger, company: true }}
                                name={gigger.full_name}
                                remove
                                redirect={url}
                                onRemove={this.handleRemoveSavedGigger}
                              />
                            </Content>
                            {savedGiggers && savedGiggers.length - 1 === index ? '' : <Border />}
                          </>
                        );
                      })}
                    {(savedGiggers === undefined
                      || (savedGiggers && savedGiggers.length === 0)
                      || savedGiggers === null) && (
                      <>
                        <NoDataCard>
                          <FormattedMessage
                            id="company_my_giggers_havent_saved_any_gigger_message"
                            defaultMessage="You haven't saved any gigger yet"
                          />
                        </NoDataCard>
                      </>
                    )}
                  </>
                )}
              </MyGiggersWrapper>
              <MyGiggersWrapper>
                {loading ? (
                  <Loader height="40px" />
                ) : (
                  <Content className="row">
                    <Content className="col-10 p-0">
                      <SubHeader role="button" onClick={this.handleExpandHiredGiggers}>
                        <FormattedMessage id="company_my_giggers_hired_giggers" defaultMessage="Hired Giggers" />
                      </SubHeader>
                    </Content>
                    <Content className="col-2 text-end p-0">
                      <Image
                        src={Constants.icons.ArrowDown}
                        alt="icon"
                        onClick={this.handleExpandHiredGiggers}
                        className={expandHiredGiggers && 'rotate-180'}
                        role="button"
                      />
                    </Content>
                  </Content>
                )}

                {!loading && expandHiredGiggers && (
                  <>
                    {hiredGiggers
                      && hiredGiggers.length > 0
                      && hiredGiggers.map((gigger, index) => {
                        const url = `/gigger-${
                          gigger.full_name ? gigger.full_name.replace(' ', '-') : '-'}/${gigger.id}`;
                        const feedbackUrl = `/customer-feedback/${user._id}-${gigger.id}`;
                        return (
                          <>
                            <Content className="my-3">
                              <GiggerCard
                                data={{ ...gigger, company: true }}
                                name
                                redirect={url}
                              />
                              <>
                                <GreenCard green>
                                  <FormattedMessage id="common_hired" defaultMessage="Hired" />
                                  {' '}
                                  {/* {gigger.hiredDetails && gigger.hiredDetails[0]} */}
                                </GreenCard>
                                {!gigger.expire && gigger.request_feedback && (
                                <PinkCard className="mt-1 py-2 text-center">
                                  <p
                                    style={{ color: `${Colors.Black}` }}
                                    className="m-0 mt-1"
                                  >
                                    <A href={feedbackUrl} className="cursor-pointer" target="_self">
                                      <FormattedMessage
                                        id="my_giggs_submit_feedback"
                                        defaultMessage="Submit feedback"
                                      />
                                    </A>
                                  </p>
                                </PinkCard>
                                )}
                              </>
                            </Content>
                            {hiredGiggers && hiredGiggers.length - 1 === index ? '' : <Border />}
                          </>
                        );
                      })}
                    {(hiredGiggers === undefined
                      || (hiredGiggers && hiredGiggers.length === 0)
                      || hiredGiggers === null) && (
                      <>
                        <NoDataCard>
                          <FormattedMessage
                            id="company_my_giggers_havent_hired_any_gigger_message"
                            defaultMessage="You haven't hired any gigger yet"
                          />
                        </NoDataCard>
                      </>
                    )}
                  </>
                )}
              </MyGiggersWrapper>
            </Content>
          </Container>
        </FlexContainer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  expandSavedGiggers: state.MyGiggers.expandSavedGiggers,
  expandHiredGiggers: state.MyGiggers.expandHiredGiggers,
  expandPurchasedContacts: state.MyGiggers.expandPurchasedContacts,
});

function mapDispatchToProps(dispatch) {
  return {
    expandTabActions: (payload) => dispatch(tabActions(payload)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(MyGiggers);
