import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import { Col } from '../../styles/CommonStyles';
import { BackgroundImage } from '../../styles/Images';

export const FlexContainer = styled.div`
  background-color:${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image:${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)};
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    flex: 0 0 100%;
  }
`;
export const Container = styled.div`
  background-color: ${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image: none;
  min-height: 100vh;
  width: 70%;
  margin: 0 auto;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const HeaderPart = styled.div`
  @media (max-width: 768px) {
    width: 92%;
    margin: 4%;
    margin-top: 0%;
  }
`;
export const UpperPart = styled(HeaderPart)``;
export const Div = styled.div``;
export const NavContent = styled.span`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
`;
export const NavActive = styled(NavContent)`
  font-family: ${Font.fontFamily.Archivo};
  font-weight: 500;
  color: ${Colors.Black};
`;
export const ContentWrapper = styled.div`
  margin-top: 24px;
  display: block;
`;

export const Heading = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size28}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Black};
  @media (max-width: 768px) {
    font-family: ${Font.fontFamily.AvenirBold};
    font-size: ${Font.fontFamily.Size32}px;
  }
`;

export const DetailsWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 132px;
`;
export const UploadWrapper = styled.div`
  align-self: center;
  margin-left: 30px;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-left: 0px;
  }
`;
export const UploadButton = styled.button`
  border-radius: 2px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
  margin-bottom: 8px;
  text-decoration: underline;
  padding: 8px 32px;
  border: none;
  background-color: ${Colors.White};
  &:hover {
    background-color: ${Colors.DarkWhite};
  }
  &:focus,
  &:active {
    outline: none;
    border: none;
  }
`;
export const UploadCondition = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  text-align: ${({ align }) => (align || 'intial')};
`;
export const OpenModal = styled.u``;
export const LinkedInCard = styled(FlexContainer)`
  width: 70%;
  float: right;
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LinkedInWrapper = styled.div`
  align-self: flex-end;
`;
export const LinkedInContent = styled.div`
  width: 70%;
  margin: 0 auto;
`;
export const LinkedInText = styled(UploadCondition)`
  color: ${Colors.Black};
  text-align: center;
`;
export const LinkedInButton = styled(UploadButton)`
  background-color: #2f80ed;
  color: ${Colors.White};
  padding: 8px 24px;
  text-decoration: none;
  width: 100%;
`;
export const HeaderCol = styled(Col)`
  flex: ${({ width }) => (width ? `0 0 ${width}%` : '0 0 100%')};
  max-width: ${({ width }) => (width ? `${width}%` : ' 100%')};
  @media (max-width: 768px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export const ProfileSubHeading = styled(UploadCondition)`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size20}px;
  font-weight: 500;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  @media (max-width: 768px) {
    font-family: ${Font.fontFamily.AvenirBold};
    font-size: ${Font.fontFamily.Size18}px;
  }
`;

export const FormFieldWrapperLeft = styled.div`
  padding-right: 15px;
  padding-left: 0;
  @media (max-width: 960px) {
    padding: 0;
  }
`;
export const FormFieldWrapperRight = styled.div`
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: 960px) {
    padding: 0;
  }
`;

export const FormLeft = styled.div`
  max-width: 530px;
  margin-right: 130px;
  @media (max-width: 768px) {
    width: 100%;
    margin-right:0px
  }
`;
export const FormRight = styled.div`
  max-width: 400px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const ProfileImage = styled.div`
  width: ${({ width }) => (width || '200px')};
  height: 200px;
  @media (max-width: 768px) {
    height: 293px;
    width: 293px;
    align-self: center;
  }
  @media (max-width: 570px) {
    height: 293px;
    width: 293px;
    align-self: center;
  }
  @media (max-width: 368px) {
    height: 293px;
    width: 293px;
    align-self: center;
  }
`;

export const UploadProfileImage = styled.div`
  width: ${({ width }) => (width || '200px')};
  height: 200px;
  @media (max-width: 768px) {
    height: 158px;
    width: 158px;
  }
  @media (max-width: 570px) {
    height: 158px;
    width: 158px;
  }
  @media (max-width: 368px) {
    height: 158px;
    width: 158px;
  }
`;

export const ProfileStatus = styled.span`
  position: relative;
  bottom: 0;
  justify-content: center;
  display: flex;
  align-content: center;
  background: ${Colors.HighlightGreen};
  padding: 8px;
  font-size: ${Font.fontSize.Size16}px;
  font-family: ${Font.fontFamily.Archivo};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: ${Colors.White};
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1 !important;
  width: ${({ width }) => (width || '200px')};
  top: 160px;
  @media (max-width: 768px) {
    width: 293px;
    top: 253px;
  }
  @media (max-width: 570px) {
    width: 293px;
    top: 253px;
  }
  @media (max-width: 368px) {
    width: 293px;
    top: 253px;
  }
`;
export const ProfileViewsIcon = styled.img`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;
export const SubHeader = styled.p`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 500;
  opacity: ${(props) => (props.head ? 1 : 0.8)};
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e3132;
  margin: 0px;
  padding: 0px;
  margin-bottom: 4px;
`;
export const CharactersLeft = styled(SubHeader)`
  font-size: ${Font.fontSize.Size12}px;
  line-height: 1.75;
  opacity: 0.8;
  padding-top: 4px;
`;
export const Wrapper = styled.div`
  margin-top: 16px;
  padding: 0px;
`;
export const UploadDocument = styled.div`
  height: 42px;
  border-style: dashed !important;
  border: solid 1px rgb(127, 125, 152, 0.5);
  text-align: center;
  color: ${Colors.Primary};
`;
export const CloseButton = styled.a`
  text-decoration: underline !important;
  font-size: ${Font.fontSize.Size14}px;
  color: ${Colors.Secondary};
`;
export const DragAndDrop = styled.input`
  display: none;
`;
export const Text = styled.div`
  color: ${Colors.Secondary};
  padding-top: 2px;
`;
export const LinkedInPinkCard = styled.div`
  width: 100%;
  background-color: #f9e3d6;
  display: block;
  margin: 0px;
  padding: 16px;
  border-radius: 2px;
`;
export const JuniorLevelTag = styled.div`
  width: 80px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #647D7E;
  border: 3px solid rgb(100,125,126,0.2);
  padding: 2px 4px;
  text-align: center;
`;
export const ExperienceLevelTag = styled(JuniorLevelTag)`
  color: #ECA477;
  border: 2px solid rgb(236,164,119);
`;
export const MidLevelTag = styled(JuniorLevelTag)`
  width: 80px;
  color: #0E3132;
  border: 2px solid rgb(14,49,50);
`;
export const Description = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin: 0px;
`;
export const Name = styled(Description)`
  font-family: ${Font.fontFamily.AvenirBold};
  font-weight: 900;
`;
export const EditButton = styled(Description)`
  text-decoration: underline !important;
  align-self: center;
`;
export const HourlyRateSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: 960px) {
    padding: 0;
  }
`;
export const NavButton = styled.button`
  text-decoration:none;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  cursor: pointer;
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  &:active,
  &:focus {
    outline: none;
    border: none;
  }
`;
export const DeleteButton = styled.div`
  text-align: right;
  @media (max-width: 576px) {
    text-align: center;
    margin-top:10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
