import React, { Component } from 'react';
import Loader from '../../components/Loader/Loader';
import { Border, TableData, Wrapper } from './users/styles';
import { ButtonWrapper } from './giggers/styles';

class LoadingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const load = ['1', '2', '3', '4', '5'];
    return (
      <>
        {load.map(() => (
          <>
            <Wrapper className="row m-0">
              <TableData className="col ">
                <Loader />
              </TableData>
              <TableData className="col">
                <Loader />
              </TableData>
              <TableData className="col">
                <Loader />
              </TableData>
              <TableData className="col">
                <Loader />
              </TableData>
              <TableData className="col">
                <Loader />
              </TableData>
              <TableData className="col-auto">
                <ButtonWrapper>
                  <Loader />
                </ButtonWrapper>
              </TableData>
            </Wrapper>
            <Border />
          </>
        ))}
      </>
    );
  }
}

export default LoadingCard;
