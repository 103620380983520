import { ADD_DATA, ADD_PROPERTY, CLEAR_DATA } from '../actions/types';

const initialState = {
  data: '',
  property: '',
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case CLEAR_DATA:
      return {
        ...state,
        data: '',
      };

    default:
      return { ...state };
  }
};
