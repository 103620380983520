import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexContainer } from './login/styles';
import { Div } from '../components/Slider/styles';

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <FlexContainer style={{ backgroundColor: '#f1f5f7', height: '100vh' }}>
        <Div className="container">
          <h2 className="mt-5"><FormattedMessage id="page_not_found" defaultMessage="Not Found" /></h2>
        </Div>
      </FlexContainer>
    );
  }
}

export default NotFound;
