import React, { Component } from 'react';
import Constants from '../shared/constants';

class BaseComponent extends Component {
  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {}

  render() {
    return <React.Fragment />;
  }

  goBack(event) {
    const { history } = this.props;
    event.preventDefault();
    if (history) {
      if (history.length > 1) {
        return history.goBack();
      } else {
        return history.push({
          pathname: Constants.routes.home.url,
        });
      }
    } else {
      return history.push({
        pathname: Constants.routes.home.url,
      });
    }
  }
}

export default BaseComponent;
