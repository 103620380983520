import React from 'react';
import BuyContactForm from './BuyContactForm';
import Slider from '../Slider/Slider';

const BuyContactSlider = ({
  type, redirectToSignUpPage, open, refresh, amount, userId, giggerId, closeSlider, showLimitPopup,
}) => {
  /**
   * Closes the slider
   */
  const closeModal = () => {
    closeSlider();
  };
  return (
    <>
      <Slider open={open}>
        <BuyContactForm
          open={open}
          closeSlider={closeModal}
          refresh={refresh}
          type={type}
          amount={amount}
          userId={userId}
          giggerId={giggerId}
          redirectToSignUpPage={redirectToSignUpPage}
          showLimitPopup={showLimitPopup}
        />
      </Slider>
    </>
  );
};
export default BuyContactSlider;
