import React from 'react';
import styled from 'styled-components';
import validator from 'validator';
import Constants from '../../shared/constants';
import { TickIcon, PlusIcon } from '../../styles/Icons';
import {
  TextDiv, Section, LabelWrapper, MandatoryLabel, InputBox, BorderBox, TextBoxLabel,
} from './styles';

const Divison = styled.div``;
class TextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showError: false,
      tickMark: false,
      rule: {
        method: validator.isEmpty,
        validWhen: false,
        // args: [{ min: 10, max: 10 }],
      },
    };
    this.textInput = React.createRef();
  }

  componentDidUpdate() {
    const { value } = this.props;
    if (value && value.length === 0) {
      this.handleTickMark();
    }
  }

  /**
   * Disables tickmark
   */
  handleTickMark=() => {
    this.setState({ tickMark: false });
  }

  /**
   * Handles change in value
   * @param {Event} event value
   * @listens Event
   */
  handleChange = (event) => {
    const { onChange } = this.props;
    const { rule } = this.state;
    let { isValid } = this.props;
    const eventValue = event.target.value;
    if (rule) {
      isValid = this.validate(eventValue, rule);
    }
    this.setState({ isValid, tickMark: isValid && eventValue.length > 0 });
    // if (type === 'numeric') {
    //   eventValue = eventValue.replace(/[^0-9^]+/g, '');
    // }

    if (onChange) {
      onChange(event);
    }
  };

  handleKeyPress =(event) => {
    const {
      onKeyPress, disabletickMark, classname, existError,
    } = this.props;
    const { value } = event.target;
    const tickMark = (this.validate(value) && !disabletickMark && !classname
    && !existError);
    this.setState({ tickMark });
    if (onKeyPress) {
      onKeyPress(event);
    }
  }

  /**
   * Handles on blur event of text box
   * @param {Event} event
   */
  handleBlur = (event) => {
    const { onChange, onBlur } = this.props;
    const { isValid } = this.state;
    const showError = true;
    if (onChange) {
      this.handleChange(event);
    }
    if (onBlur) {
      onBlur(event);
    }
    this.setState({ showError, tickMark: isValid });
  };

  /**
   * Handles focus event of text box
   * @param {Event} event
   */
  handleFocus = (event) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  focus = () => {
    this.textInput.current.focus();
  };

  /**
   * Validates text box
   * @param {Even} value
   */
  validate(value) {
    const { rule } = this.props;
    const args = (rule && rule.args) || [];
    return (rule && rule.presence) || !validator.isEmpty(value) || !validator.isEmail(value)
      ? (rule && rule.method(value, ...args)) === (rule && rule.validWhen)
      : true;
  }

  render() {
    const {
      showError, isValid, tickMark,
    } = this.state;
    const {
      name,
      label,
      type,
      id,
      minLength,
      maxLength,
      disabled,
      value,
      mandatory,
      errorMessage,
      placeholder = '',
      classname,
      existError,
      existErrorMessage,
      searchClassname,
      isDisabled,
      forFilter,
      showPlusIcon,
      onAddNewOption,
      disabletickMark,
      className,
      customRule = {},
      rule,
    } = this.props;

    const displayError = (showError && !isValid) || existError || classname || customRule.validWhen;

    return (
      <Section className={className}>
        {label && (
          <LabelWrapper>
            <TextBoxLabel forFilter={forFilter} mandatory={mandatory}>
              {label || ''}
            </TextBoxLabel>
            {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
          </LabelWrapper>
        )}
        <BorderBox
          className={
            `${displayError
              ? 'border-red '
              : isDisabled
                ? 'disableOption '
                : ''}d-flex row m-0 p-0`
          }
        >
          <Divison className="col p-0">
            <InputBox
              name={name}
              type={type}
              id={id}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onKeyPress={this.handleKeyPress}
              ref={this.textInput}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              minLength={minLength}
              maxLength={maxLength}
              className={`${type === 'date' ? 'pr-3' : searchClassname || ''}pb-1`}
              autoComplete="off"
            />
          </Divison>
          {showPlusIcon ? (
            <Divison className="col-1 p-0 mr-3 mt-2 text-end " onClick={onAddNewOption} role="button">
              <PlusIcon className="cursor-pointer" size={16} fill="#a9bbbc" />
            </Divison>
          ) : (
            tickMark
            && !classname
            && !existError
            && !disabletickMark
            && value
            && value.length > 0 && (
              <Divison className="col-1 p-0 mr-3 mt-2 text-end">
                <TickIcon size={16} fill="green" />
              </Divison>
            )
          )}
        </BorderBox>
        {(existError || customRule.validWhen) && rule && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{existErrorMessage || customRule.message}</span>
          </TextDiv>
        )}
        {showError && !isValid && rule && (
          <TextDiv>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{rule.message || errorMessage}</span>
          </TextDiv>
        )}
      </Section>
    );
  }
}
export default TextBox;
