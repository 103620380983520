import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from '../../components/Card/styles';
import {
  Header,
  SubHeader2,
  FlexContainer,
  Container,
  CardWrapper,
  Div,
} from './styles';
import { RelativeContainer } from '../../styles/CommonStyles';
import Button from '../../components/Button/Button';
import { Line } from '../admin/giggers/styles';
import Constants from '../../shared/constants';
import Config from '../../config';

const ThankYouPage = (props) => {
  const { history } = props;
  const { location } = history;

  /**
   * Redirects to my account page
   */
  const handleBackToMyAccount = () => {
    history.push(Constants.routes.customerMyAccount.url);
  };

  const language = localStorage.getItem('language');
  window.scrollTo(0, 0);

  return (
    <>
      <RelativeContainer>
        <FlexContainer>
          <Container>
            <CardWrapper>
              {location.pathname === Constants.routes.thankYouForHiring.url
                ? (
                  <>
                    <Header className="my-2 pb-1">
                      <FormattedMessage
                        id="thank_you_feedback_message_for_hiring_gigger"
                        defaultMessage="Thank you for your purchase!"
                      />
                    </Header>
                    <SubHeader2>
                      <FormattedMessage
                        id="thank_you_feedback_message_for_hiring_gigger_para"
                        defaultMessage="You will find hired consultant under My account hired consultants.
                        where you also can leave a review when the assigment is completed."
                      />
                    </SubHeader2>
                    <SubHeader2 onClick={handleBackToMyAccount} role="button" className="mt-3">
                      <Line>
                        <FormattedMessage id="company_feedback_back_to_my_account" defaultMessage="Back to My account" />
                      </Line>
                    </SubHeader2>
                  </>
                )
                : (
                  location.pathname === Constants.routes.CompanyFeedbackMessage.url)
                  ? (
                    <>
                      <Header className="my-2 pb-1">
                        <FormattedMessage
                          id="company_feedback_thank_you_for_feedback"
                          defaultMessage="Thank you for your feedback!"
                        />
                      </Header>
                      <SubHeader2 onClick={handleBackToMyAccount} role="button" className="mt-3">
                        <Line>
                          <FormattedMessage id="company_feedback_back_to_my_account" defaultMessage="Back to My account" />
                        </Line>
                      </SubHeader2>
                    </>
                  )
                  : (
                    <>
                      <Header className="my-2">
                        <FormattedMessage
                          id="company_feedback_thank_you_for_feedback"
                          defaultMessage="Thank you for your feedback!"
                        />
                      </Header>
                      <SubHeader2>
                        <FormattedMessage
                          id="text_para_feedback"
                          defaultMessage="It will be available in the employee’s profile."
                        />
                      </SubHeader2>
                      <Card message className="mt-5">
                        <SubHeader2 className="mb-3">
                          <FormattedMessage
                            id="message_thank_for_feedback"
                            defaultMessage="Do you work for a company where you use consulting assistance in your business? Feel free to read more about how Gigassembly can quickly and easily help you find quality-assured competence."
                            values={{ break: <br /> }}
                          />
                        </SubHeader2>
                        <Div className="w-50 mt-3">
                          <a
                            href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].forCompanies}/`}
                            target="_self"
                          >
                            <Button
                              name={<FormattedMessage id="common_agree_terms_and_conditions_read_more" defaultMessage="Read more" />}
                              type="update"
                            />
                          </a>
                        </Div>
                      </Card>
                    </>
                  )}
            </CardWrapper>
          </Container>
        </FlexContainer>
      </RelativeContainer>
    </>
  );
};

export default ThankYouPage;
