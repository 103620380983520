import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { FeedbackListWrapper } from '../styles';
import Constants from '../../../../shared/constants';
import { IconWrapper, Icon } from '../../giggers/styles';
import LoadingCard from '../../LoadingCard';
import CompanyService from '../../../../services/AdminServices/CompanyService';
import Config from '../../../../config';
import SearchInput from '../../../../components/Input/SearchInput';
import { getFormattedCSVData } from '../../../../shared/utils';
import Pagination from '../../../../components/Pagination/Pagination';
import ListViewHeader from '../../feedback/ListView/ListViewHeader';
import ListViewBody from '../../feedback/ListView/ListViewBody';

class FeedbackTab extends Component {
  companyService = new CompanyService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      feedbackData: [],
      loading: false,
      searchbar: true,
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
          pageNumber: 1,
          pageSize: 15,
          pages: 1,
        },
      },
      searchQuery: '',
      csvDownloadData: [],
      csvExportFileds: [
        'full_name',
        'company_user_name',
        'feedback',
        'rating',
        'created_at',
        'updated_at',
        'status',
        'user_id',
        'gigger_id',
      ],
    };
  }

  componentDidMount() {
    this.fetchFeedbacks();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        cursor: { pageNumber },
      },
    } = this.state;
    if (prevState.meta.cursor.pageNumber !== pageNumber) {
      this.fetchFeedbacks();
    }
  }

  /**
   *
   * Loads data
   *
   */
  handleLoad = () => {
    this.fetchFeedbacks();
  };

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  handleGotoPage = (pageNumber) => {
    const { meta: { cursor } } = this.state;
    this.setState({
      meta: {
        cursor: {
          ...cursor,
          pageNumber,
        },
      },
    });
  };

  /**
   *
   * displays search bar
   *
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   *
   * @async
   * @function handleDownloadCsv
   * Dowloads data in the form of csv file
   *
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { companyId } = this.props;
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];

    try {
      const response = await this.companyService.getCompanyFeedback(companyId, queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.feedbackCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   *
   * Handles search functionality
   * @param {String} value search text
   *
   */
  handlesearchFeedback=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchFeedbacks(value);
    });
  }

  /**
   *
   * Handles Change in search value
   * @param {Event} event search value
   *
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   *
   * @async
   * @function fetchFeedbacks Fetches feedback
   * @param {String} searchInput search text
   *
   */
  fetchFeedbacks = async (searchInput) => {
    const {
      meta: {
        cursor: { pageNumber, pageSize },
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    const { companyId } = this.props;
    try {
      const response = await this.companyService.getCompanyFeedback(companyId, queryParams);
      const { data = [], meta = {} } = response;
      this.setState({
        loading: false, feedbackData: data, meta,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      feedbackData,
      searchbar,
      loading,
      csvDownloadData,
      searchQuery,
      meta,
    } = this.state;
    return (
      <>
        <CSVLink
          data={csvDownloadData}
          filename="companyFeedback.csv"
          className="d-none"
          ref={(r) => { this.feedbackCsv = r; }}
        >
          click
        </CSVLink>

        <FeedbackListWrapper>

          <IconWrapper className="row m-0">

            {searchbar ? (
              <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
            ) : (
              <div className="text-end col-auto p-0">
                <SearchInput
                  onSearch={this.handlesearchFeedback}
                  onChange={this.handleSearchOnChange}
                  value={searchQuery}
                />
              </div>
            )}

            <Icon
              src={Constants.icons.ExportIcon}
              alt="icon"
              className={feedbackData && feedbackData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
              onClick={this.handleDownloadCsv}
            />

          </IconWrapper>

          {loading && (
            <FeedbackListWrapper>
              <LoadingCard />
            </FeedbackListWrapper>
          )}

          {!loading && (
            <>

              <ListViewHeader customer />

              <ListViewBody
                data={feedbackData}
                customer
                handleLoad={this.handleLoad}
                searchQuery={searchQuery}
              />

            </>
          )}
        </FeedbackListWrapper>

        {!loading && meta.cursor.pages > 1 && (
        <FeedbackListWrapper className="bg-transparent">
          <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
        </FeedbackListWrapper>
        )}

      </>
    );
  }
}

export default FeedbackTab;
