import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Filter from '../../../components/Filter/Filter';
import Header from '../../../components/Header/Header';
import Button from '../../../components/Button/Button';
import {
  GiggerListWrapper, ButtonWrapper, IconWrapper, Icon, Line, CloseIcon,
} from './styles';
import {
  FlexContainer,
  Container,
  Border,
  TableHeader,
  TableValue,
  TableData,
  Wrapper,
} from '../users/styles';
import Constants from '../../../shared/constants';
import AccountService from '../../../services/AccountService';
import GiggerService from '../../../services/AdminServices/GiggersService';
import Config from '../../../config';
import LoadingCard from '../LoadingCard';
import AuthService from '../../../services/AuthService';
import { WhiteCard, DetailsLeft } from '../../gigger/styles';
import Pagination from '../../../components/Pagination/Pagination';
import SearchInput from '../../../components/Input/SearchInput';
import ChangeLanguageModal from '../../../components/Modals/index';
import { getFormattedCSVData } from '../../../shared/utils';
import MultiSelect from '../../../components/Input/MultiSelect';
import { ArrowBack } from '../../../styles/Icons';
import Search from '../../../components/Input/SearchBar';

class Giggers extends Component {
  authService = new AuthService(Config.apiBase);

  accountService = new AccountService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      meta: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: 1,
        pageSize: 15,
      },
      loggedIn: this.authService.isAuthenticated(),
      giggerData: [],
      loading: false,
      searchbar: true,
      searchQuery: '',
      csvDownloadData: [],
      isLangModalOpen: false,
      csvExportFileds: ['full_name', 'jobTitle', 'accountOwner', 'created_at', '_id', 'status', 'email'],
      filter: false,
      filterOptions: [
        { label: Constants.language.common_draft, value: Constants.status.DRAFT },
        { label: Constants.language.common_pending, value: Constants.status.PENDING },
        { label: Constants.language.common_active, value: Constants.status.APPROVED },
        { label: Constants.language.common_status_id_pending, value: Constants.status.IDPENDING },
        {
          label: Constants.language.common_status_id_disapprove,
          value: Constants.status.IDDISAPPROVE,
        },
        { label: Constants.language.common_not_checked, value: Constants.status.NOTCHECKED },
        { label: Constants.language.common_disapproved, value: Constants.status.CV_DISAPPROVED },
        { label: Constants.language.common_followed_up, value: Constants.status.FOLLOWEDUP },
        { label: Constants.language.common_edited, value: Constants.status.EDITED },
        { label: Constants.language.common_approved_cv, value: Constants.status.CV_APPROVED },
        { label: Constants.language.common_not_started, value: Constants.status.NOTSTARTED },
      ],
      filterData: Constants.defaultFilterData,
      updateFilterData: true,
      filterStatus: [{ name: 'status', label: '', id: '' }],
      toBeFilteredData: [],
      statusFilterData: [],
      jobTitle: [],
      skillsData: [],
      categoryData: [],
      systemsData: [],
      stateData: [],
      searchData: {
        name: 'search_filter',
        label: '',
        id: '',
      },
      sortAscending: false,
      sortedBy: 'created_at',
    };
  }

  componentDidMount() {
    const { loggedIn, filterOptions } = this.state;
    const {
      location: { data = {} },
    } = this.props;
    if (loggedIn) {
      if (data && Object.keys(data).length > 0) {
        const {
          statusFilterData = [], searchData: searchParam = {}, stateData = {}, filterData = [],
        } = data;
        let tempFilterOptions = filterOptions;
        if (Object.keys(statusFilterData).length > 0) {
          const filterStatus = statusFilterData.status[0] ? statusFilterData.status[0].split(',') : [];
          filterStatus.map((name) => {
            tempFilterOptions = tempFilterOptions.map((obj) => {
              if (obj.value === name) {
                return { ...obj, isSelected: true };
              }
              return obj;
            });
            return name;
          });
        }
        if (Object.keys(searchParam).length > 0 && Object.keys(stateData).length > 0) {
          const {
            jobTitle, categoryData, skillsData, systemsData,
          } = stateData;
          this.setState(
            {
              searchData: searchParam,
              stateData,
              jobTitle,
              categoryData,
              skillsData,
              systemsData,
              filterData,
              statusFilterData,
              filterOptions: tempFilterOptions,
            },
            this.handleApplyFilter(filterData, stateData, searchParam),
          );
        } else if (Object.keys(statusFilterData).length > 0) {
          this.setState({
            statusFilterData, filter: true, filterOptions: tempFilterOptions,
          },
          () => {
            this.fetchGiggers(true, statusFilterData);
            this.fetchOptions();
          });
        } else if (Object.keys(searchParam).length > 0) {
          this.fetchOptions();
          this.handleSeachData(searchParam);
        } else if (Object.keys(stateData).length > 0) {
          this.handleApplyFilter(filterData, stateData, searchParam);
        } else {
          this.fetchOptions();
        }
      } else {
        this.setState({ updateFilterData: false });
        this.fetchGiggers();
        this.fetchOptions();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        pageNumber,
      },
      sortedBy,
      sortAscending,
      toBeFilteredData,
    } = this.state;
    const { location = {} } = this.props;
    if (prevState.meta.pageNumber !== pageNumber || location.key
      !== prevProps.location.key || sortedBy !== prevState.sortedBy
      || sortAscending !== prevState.sortAscending) {
      if (toBeFilteredData.length > 0) {
        this.fetchGiggers(toBeFilteredData);
      } else {
        this.fetchGiggers();
      }
    }
  }

  /**
 * @async
 * @function fetchOptions fetches all filters
 */
     fetchOptions = async () => {
       let filterList = await this.accountService.getAllFilter();
       filterList = filterList.data;
       const {
         jobtitle: jobTitle = [],
         category: categoryData = [], skills: skillsData = [], systems: systemsData = [],
       } = filterList;
       this.setState({
         jobTitle,
         categoryData,
         skillsData,
         systemsData,
       });
     }

     /**
   * Applies filter
   * @param {object} data
   */

  handleApplyFilter = (data, stateData, searchParam = null) => {
    let { searchData } = this.state;
    const filterData = data;
    if (searchParam) {
      searchData = searchParam;
      filterData.push(searchParam);
    } else {
      filterData.push(searchData);
    }
    const toBeFilteredData = [];
    filterData.map((obj) => {
      if (toBeFilteredData[obj.name] || obj.id !== '') {
        toBeFilteredData[obj.name] = [];
        const objValue = obj.label === 'available_date' || obj.label === 'hourly_rate' ? obj.id
          : obj.label;
        toBeFilteredData[obj.name].push(objValue);
      } else {
        delete toBeFilteredData[obj.name];
      }

      return obj;
    });
    this.setState({
      searchData,
      toBeFilteredData,
      loading: true,
      stateData,
      filterData,
    }, () => { this.fetchGiggers(true, toBeFilteredData); });
  };

  /**
   * Clears out the filters
   */
     handleClearFilter = () => {
       const {
         jobTitle,
         categoryData,
         skillsData,
         systemsData,
       } = this.state;
       let { toBeFilteredData } = this.state;
       const { search = [] } = toBeFilteredData;
       toBeFilteredData = [];
       toBeFilteredData.search = search;
       this.setState({
         toBeFilteredData,
         filterData: [],
         stateData: {},
         loading: true,
         jobTitle: jobTitle.map((obj) => ({ ...obj, isSelected: false })),
         skillsData: skillsData.map((obj) => ({ ...obj, isSelected: false })),
         systemsData: systemsData.map((system) => ({ ...system, isSelected: false })),
         categoryData: categoryData.map((obj) => ({ ...obj, isSelected: false })),
       }, () => { this.fetchGiggers(true, toBeFilteredData); });
     };

  /**
   * Downloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.giggerService.getGiggerList(queryParams);
      const { data } = response;

      this.setState({ csvDownloadData: getFormattedCSVData(data), loading: false });
      if (response) {
        this.giggersCsv.link.click();
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  /**
   * Handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber, pageSize: 15 },
    }), this.fetchGiggers);
  };

  /**
   * Handles change in search value
   * @param {Event} event search text
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Displays search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * Close search bar
   */
  closeSearchBar = () => {
    this.setState({ searchbar: true });
  };

  /**
   * Opend language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  handleFilterDataUpdation = () => {
    this.setState({ updateFilterData: false });
  };

  /**
   * Closes language modal
   */
  handleModalClose = () => {
    this.setState({
      isLangModalOpen: false,
    });
  };

  /**
   * Handles search functionality
   * @param {String} value search text
   */
  searchGiggers=(value) => {
    this.setState({ searchQuery: value }, () => {
      this.fetchGiggers(value);
    });
  }

  /**
   * fetches giggers on search
   * @param {event} e
   */

     handleSeachData = async (e) => {
       const { searchData, toBeFilteredData } = this.state;
       searchData.label = e.label;
       searchData.id = e.id;

       toBeFilteredData[searchData.name] = [];
       if (toBeFilteredData[searchData.name] || e.id !== '') {
         toBeFilteredData[searchData.name] = [];
         toBeFilteredData[searchData.name].push(e.type === 'secondary_job_title' ? e.name : e.label);
       } else {
         delete toBeFilteredData[searchData.name];
       }
       this.setState({ searchData, toBeFilteredData, searchbar: e.length <= 0 });
       await this.fetchGiggers(toBeFilteredData);
     };

  /**
   * toggle filter open/close
   */
  handleFilterIcon = () => {
    const { filter } = this.state;
    this.setState({ filter: !filter });
  }

  handleSortList = (name) => {
    let { sortedBy, sortAscending } = this.state;
    if (sortedBy === name) {
      sortAscending = !sortAscending;
    } else {
      sortAscending = true;
      sortedBy = name;
    }
    this.setState({ sortAscending, sortedBy });
  }

  /**
   * Handles filter functionality
   * @param {String} name filter name
   * @param {String} label selected option label
   * @param {String} value selected option value
   */
  handleFilterChange = (name, label, value, doReLoad = true) => {
    let { filterStatus, toBeFilteredData } = this.state;
    const statusFilterData = [];
    filterStatus = filterStatus.map((obj) => {
      if (obj.name === name) {
        if (statusFilterData[name] || value !== '') {
          statusFilterData[name] = [];
          statusFilterData[name].push(value);
        } else {
          statusFilterData.filter((o) => (o.name !== name));
          toBeFilteredData = toBeFilteredData.filter((o) => (o.name !== name));
        }
        return { ...obj, id: value, label };
      }
      return obj;
    });

    this.setState({ filterStatus, statusFilterData, toBeFilteredData },
      () => {
        if (doReLoad) {
          this.fetchGiggers(true, filterStatus);
        }
      });
  }

  handleDisplayStatus = (status) => {
    switch (status) {
      case Constants.status.PENDING:
        return <Button name={<FormattedMessage id="common_pending" defaultMessage="Pending" />} type={Constants.status.OPEN} />;
      case Constants.status.APPROVED:
        return <Button name={<FormattedMessage id="common_active" defaultMessage="Active" />} type={Constants.status.APPROVE} />;
      case Constants.status.DRAFT:
        return <Button name={<FormattedMessage id="common_draft" defaultMessage="Draft" />} type={Constants.status.OPEN} />;
      case Constants.status.IDPENDING:
        return <Button name={<FormattedMessage id="common_status_id_pending" defaultMessage="Id pending" />} type={Constants.status.OPEN} />;
      case Constants.status.IDDISAPPROVE:
        return <Button name={<FormattedMessage id="common_status_id_disapprove" defaultMessage="Id disapproved" />} type="cancelled" />;
      default:
        return '';
    }
  };

  /**
   * @async
   * @function fetchGiggers Fetches giggers
   * @param {String} searchInput search text
   */
  async fetchGiggers(searchInput) {
    const {
      meta: {
        pageNumber, pageSize,
      },
      searchQuery,
      statusFilterData = [],
      toBeFilteredData = [],
      sortedBy,
      sortAscending,
    } = this.state;
    const filterData = toBeFilteredData;
    if (statusFilterData && statusFilterData.status) {
      filterData.status = statusFilterData.status;
    }
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['search', searchQuery],
      ['filter', filterData],
    ];
    try {
      const response = await this.giggerService.getGiggerList(queryParams);
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = response.meta;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };
      this.setState({
        giggerData: response.data,
        meta: metaData,
        loading: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      searchbar,
      csvDownloadData,
      giggerData,
      meta,
      loading,
      loggedIn,
      searchQuery,
      isLangModalOpen,
      filter,
      filterOptions,
      filterData,
      jobTitle,
      categoryData,
      skillsData,
      systemsData,
      stateData,
      searchData,
      sortedBy,
      sortAscending,
      statusFilterData,
      updateFilterData,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <CSVLink data={csvDownloadData} filename="giggers.csv" className="d-none" ref={(r) => { this.giggersCsv = r; }}>
          click
        </CSVLink>
        <Header
          name={<FormattedMessage id="common_giggers" defaultMessage="Giggers" />}
          listScreen
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
        />
        <FlexContainer>
          <Container>
            <GiggerListWrapper overFlowScroll>
              <IconWrapper className="row m-0">
                {searchbar ? (
                  <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
                ) : (
                  <div className="text-end col-auto p-0 d-flex align-items-center">
                    <SearchInput
                      onSearch={this.searchGiggers}
                      onChange={this.handleSearchOnChange}
                      value={searchQuery}
                    />
                    <CloseIcon
                      className="px-2"
                      src={Constants.icons.CircleX}
                      onClick={this.closeSearchBar}
                    />
                  </div>
                )}
                {filter && (
                  <div className="text-end col-2 p-0">
                    <MultiSelect
                      className="ml-2 text-left"
                      placeholder={<FormattedMessage id="select_placeholder_choose" defaultMessage="Choose" />}
                      name="status"
                      options={filterOptions}
                      onChange={this.handleFilterChange}
                      onKeyPress={this.handleFilterKeypress}
                      noEmptyError
                    />
                  </div>
                )}
                <Icon
                  src={Constants.icons.FilterIcon}
                  alt="icon"
                  width="16px"
                  className={giggerData && giggerData.length > 0 ? '' : 'disableOption'}
                  onClick={this.handleFilterIcon}
                />
                <Icon
                  src={Constants.icons.ExportIcon}
                  alt="icon"
                  className={giggerData && giggerData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
                  onClick={this.handleDownloadCsv}
                />
              </IconWrapper>
              {loading && (
                <GiggerListWrapper>
                  <LoadingCard />
                </GiggerListWrapper>
              )}

              {!loading && (
                <>
                  <Wrapper className="row m-0">
                    <TableData className="col ">
                      <TableHeader onClick={() => this.handleSortList(Constants.sort.fullName)} className="cursor-pointer">
                        <FormattedMessage id="common_name" defaultMessage="Name" />
                        <ArrowBack
                          width="16px"
                          height="16px"
                          className={sortedBy === Constants.sort.fullName ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                        />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader onClick={() => this.handleSortList(Constants.sort.jobTitle)} className="cursor-pointer">
                        <FormattedMessage id="common_title" defaultMessage="Title" />
                        <ArrowBack
                          width="16px"
                          height="16px"
                          className={sortedBy === Constants.sort.jobTitle ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                        />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader onClick={() => this.handleSortList(Constants.sort.accountOwner)} className="cursor-pointer">
                        <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                        <ArrowBack
                          width="16px"
                          height="16px"
                          className={sortedBy === Constants.sort.accountOwner ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                        />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader onClick={() => this.handleSortList(Constants.sort.createdAt)} className="cursor-pointer">
                        <FormattedMessage id="common_profile_created" defaultMessage="Profile created" />
                        <ArrowBack
                          width="16px"
                          height="16px"
                          className={sortedBy === Constants.sort.createdAt ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                        />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="common_email" defaultMessage="Email" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-align">
                      <ButtonWrapper>
                        <TableHeader onClick={() => this.handleSortList(Constants.sort.translatedStatus)} className="cursor-pointer">
                          <FormattedMessage id="common_status_profile" defaultMessage="Status profile" />
                          <ArrowBack
                            width="16px"
                            height="16px"
                            className={sortedBy === Constants.sort.translatedStatus ? (sortAscending ? 'arrow-up-sort ' : 'arrow-down-sort ') : 'd-none'}
                          />
                        </TableHeader>
                      </ButtonWrapper>
                    </TableData>

                    <TableData className="col text-align">
                      <ButtonWrapper width="142">
                        <TableHeader>
                          <FormattedMessage id="common_cv_status" defaultMessage="Status CV" />
                        </TableHeader>
                      </ButtonWrapper>
                    </TableData>

                  </Wrapper>
                  {giggerData.length > 0 ? (
                    <>
                      {giggerData.map((data) => (
                        <>
                          <Border />
                          <Wrapper className="row m-0">
                            <TableData className="col text-truncate" title={data.full_name}>
                              <Link
                                to={{
                                  pathname: `/admin/portal/gigger/${data.id}/profile`,
                                  query: { id: data.id },
                                  data: {
                                    filterData: {
                                      statusFilterData, filterData, stateData, searchData,
                                    },
                                  },
                                }}
                              >
                                <TableValue>
                                  {data.full_name === null ? '-' : (
                                    <Line>
                                      {' '}
                                      {data.full_name}
                                    </Line>
                                  )}
                                </TableValue>
                              </Link>
                            </TableData>
                            <TableData className="col text-truncate" title={data.job_title}>
                              <TableValue>{data.job_title ? data.job_title : '-'}</TableValue>
                            </TableData>

                            <TableData className="col text-truncate" title={data.account_owner}>
                              <TableValue>{data.account_owner ? data.account_owner : '-'}</TableValue>
                            </TableData>

                            <TableData className="col text-truncate">
                              <TableValue>{moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                            </TableData>

                            <TableData className="col text-truncate" title={data.email}>
                              <TableValue className="text-lowercase">{data.email ? data.email : '-'}</TableValue>
                            </TableData>

                            <TableData className="col text-align" title={this.handleDisplayStatus(data.status)}>
                              <TableValue>
                                <ButtonWrapper>
                                  {this.handleDisplayStatus(data.status)}
                                </ButtonWrapper>
                              </TableValue>
                            </TableData>

                            <TableData className="col text-align">
                              <TableValue>
                                <ButtonWrapper width="142">
                                  {data.cv_status === Constants.status.NOTCHECKED && (
                                    <Button
                                      name={<FormattedMessage id="common_not_checked" defaultMessage="not checked" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                  {data.cv_status === Constants.status.CV_DISAPPROVED && (
                                    <Button
                                      name={<FormattedMessage id="common_disapproved" defaultMessage="disapproved" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                  {data.cv_status === Constants.status.FOLLOWEDUP && (
                                    <Button
                                      name={<FormattedMessage id="common_followed_up" defaultMessage="followed up" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                  {data.cv_status === Constants.status.EDITED && (
                                    <Button
                                      name={<FormattedMessage id="common_edited" defaultMessage="edited" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                  {data.cv_status === Constants.status.CV_APPROVED && (
                                    <Button
                                      name={<FormattedMessage id="common_approved_cv" defaultMessage="Approved CV" />}
                                      type={Constants.status.APPROVE}
                                    />
                                  )}
                                  {data.cv_status === Constants.status.NOTSTARTED && (
                                    <Button
                                      name={<FormattedMessage id="common_not_started" defaultMessage="not started" />}
                                      type={Constants.status.OPEN}
                                    />
                                  )}
                                </ButtonWrapper>
                              </TableValue>
                            </TableData>
                          </Wrapper>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Border />
                      <WhiteCard className="my-3">
                        {searchQuery && searchQuery.length > 0 ? (
                          <FormattedMessage
                            id="common_no_search_results_found"
                            defaultMessage="No search results found"
                          />
                        ) : (
                          <FormattedMessage id="giggers_not_found" defaultMessage="No giggers found" />
                        )}
                      </WhiteCard>
                    </>
                  )}
                </>
              )}
            </GiggerListWrapper>
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#AdminModal"
              ref={(openLangModal) => { this.openLangElement = openLangModal; }}
            />
            {isLangModalOpen && (
              <ChangeLanguageModal
                changeLanguage={isLangModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {!loading && meta.pages > 1 && (
              <GiggerListWrapper style={{ 'background-color': 'transparent' }}>
                <Pagination cursor={meta} gotoPage={this.handleGotoPage} />
              </GiggerListWrapper>
            )}
          </Container>
          <DetailsLeft>
            <Search wp={false} onSeachData={this.handleSeachData} searchData={searchData} admin />
            <Filter
              updateFilterData={updateFilterData}
              cancelFilterDataUpdation={this.handleFilterDataUpdation}
              jobTitle={jobTitle}
              categoryData={categoryData}
              skillsData={skillsData}
              systemsData={systemsData}
              onCancel={this.handleCancelFilter}
              onApply={this.handleApplyFilter}
              onClearFilters={this.handleClearFilter}
              isAdmin
              data={{ filterData, stateData, searchData }}
            />
          </DetailsLeft>
        </FlexContainer>
      </>
    );
  }
}

export default withRouter(Giggers);
