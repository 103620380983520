import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../BaseComponent';
import {
  Span, Section, Content, Card, Button, Explanation, Head,
} from './styles';

class Modal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      ismodalopen: false,
    };
  }

  componentDidMount() {
    const { ismodalopen } = this.props;
    this.setState({ ismodalopen });
  }

  /**
   * Closes modal
   */
  handleClick = () => {
    this.setState({ ismodalopen: false });
    this.props.customerModalClose();
  };

  render() {
    const { ismodalopen } = this.state;
    return (
      <>
        {ismodalopen && (
          <Card>
            <div
              className="modal fade fade-products"
              id="runModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="modal"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content rounded border-0">
                  <div className="modal-body rounded p-0 m-4">
                    <Section className="col">
                      <Section className="row">
                        {' '}
                        <Content className="col-8 mb-2">
                          <Head heading><FormattedMessage id="error_bussiness_account_exists" defaultMessage="Business account already exists" /></Head>
                        </Content>
                        <Content message className="col-4">
                          {' '}
                          <Span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </Span>
                        </Content>
                      </Section>
                    </Section>
                    <Section className="row">
                      <Section className="col">
                        <Content>
                          <Explanation>
                            <FormattedMessage id="error_bussiness_account_exists_more_info" defaultMessage="In order to be added to this business account you need a personal invitation from the account owner. Please contact your employer or the GigAssembly team for further assistance." />
                          </Explanation>
                        </Content>
                      </Section>
                    </Section>
                    <Section className="row">
                      <Section className="col mt-3">
                        <Button onClick={this.handleClick} data-dismiss="modal" aria-label="Close">
                          <FormattedMessage id="common_ok" defaultMessage="Ok" />
                        </Button>
                      </Section>
                    </Section>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </>
    );
  }
}
Modal.propTypes = {
  handleClick: PropTypes.func,
};
export default Modal;
