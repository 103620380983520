import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import AuthService from '../../services/AuthService';
import Constants from '../../shared/constants';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import {
  isAdmin, isGigger, isCustomer, isCvVerificationUser,
} from '../../shared/utils';
import { SidemenuBorder } from '../Popover/Style';
import {
  MobileLoginButton, LoginButton, SignupButton, MyAccount, Div, Logout,
} from './styles';

class Header extends Component {
  constructor(props) {
    super(props);
    this.authService = new AuthService(Config.apiBase);
    this.giggerService = new GiggerService(Config.apiBase);
  }

  /**
   *
   * @async
   * @function handleLogOut Logout the account and redirects to login page.
   *
   */
  handleLogOut = async () => {
    const { history } = this.props;
    const { userData, token, firstLogin } = Constants.localStorageKeys;
    try {
      const response = await this.giggerService.logOut();
      if (response.status === 204 || response.status === 200) {
        [userData, token, firstLogin].forEach((storageKey) => localStorage.removeItem(storageKey));
        history.push({ pathname: Constants.routes.logIn.url });
      }
    } catch (_) {
      // Ignore exception
    }
    const receiver = document.getElementById('receiver').contentWindow;
    receiver.postMessage({ isAuthenticated: false }, Config.wordpressUrl);

    [userData, token, firstLogin].forEach((storageKey) => localStorage.removeItem(storageKey));
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   *
   * Redirects tp my account page
   *
   */
  handleMyaccount = () => {
    const { history } = this.props;

    let url = '';
    if (isGigger()) {
      url = Constants.routes.giggerMyAccount.url;
    } else if (isCustomer()) {
      url = Constants.routes.customerMyAccount.url;
    } else if (isAdmin() || isCvVerificationUser()) {
      url = Constants.routes.adminMyAccount.url;
    } else {
      url = Constants.routes.logIn.url;
    }

    history.push(url);
  };

  render() {
    const { history } = this.props;
    const isAuthenticated = this.authService.isAuthenticated();

    let language = localStorage.getItem('language');

    if (!language) {
      language = Config.language;
      localStorage.setItem('language', language);
    }

    return (
      <Div>
        <div id="wrapper-navbar" className="gig-header">
          <div className="container pt-1">
            <div className="row m-0">
              <div className="col pr-0 pl-0 col-lg-2 d-flex align-items-center">
                <a
                  className="navbar-brand"
                  href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].home}/`}
                  aria-label="gigassembly"
                  target="_self"
                >
                  <img
                    className="d-none d-lg-block desktop-logo gm-loaded gm-observing gm-observing-cb m-0"
                    src={Constants.icons.HeaderIcon}
                    alt="Gigassembly"
                    data-gmsrc={`${Config.wordpressUrl}/wp-content/uploads/2020/09/GA_wordmark_novare_Black-2.svg`}
                    loading="lazy"
                  />
                  <img
                    className="d-block d-lg-none mobile-logo gm-loaded gm-observing gm-observing-cb"
                    src={Constants.icons.MobileHeaderIcon}
                    alt="gigassembly"
                    data-gmsrc={`${Config.wordpressUrl}/wp-content/uploads/2020/09/GA_wordmark_black-1-1.svg`}
                    loading="lazy"
                  />
                </a>
              </div>

              <div className="col-auto pr-0 pl-0 mobile-toggle d-lg-none">
                <button
                  className="navbar-toggler p-0 slide-toggle collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                  <span className="new-close" />
                </button>
              </div>
              <div className="col-12 pr-0 pl-0 col-lg-10 mobile-menu">
                <nav
                  id="main-nav"
                  className="navbar navbar-expand-md navbar-light sticky-top pr-0"
                  aria-labelledby="main-nav-label"
                >
                  <div id="navbarNavDropdown" className="collapse navbar-collapse">
                    <ul id="nav-menu-list" className="navbar-nav">
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-197"
                        className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home  menu-item-197 nav-item"
                      >
                        <a
                          title={Constants.language.common_home}
                          href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].home}/ `}
                          className="nav-link"
                          aria-current="page"
                          target="_self"
                        >
                          <FormattedMessage id="common_home" defaultMessage="Home" />
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-91"
                        className="menu-item menu-item-type-post_type
                        menu-item-object-page menu-item-91 nav-item"
                      >
                        <a
                          title={Constants.language.common_for_giggers}
                          href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].forGiggers}/`}
                          className="nav-link"
                          target="_self"
                        >
                          <FormattedMessage
                            id="common_for_giggers"
                            defaultMessage="For Giggers"
                          />
                        </a>
                      </li>
                      <li
                        itemScope="itemscope"
                        itemType="https://www.schema.org/SiteNavigationElement"
                        id="menu-item-92"
                        className="menu-item menu-item-type-post_type
                        menu-item-object-page menu-item-92 nav-item"
                      >
                        <a
                          title={Constants.language.common_for_companies}
                          href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].forCompanies}/`}
                          className="nav-link"
                          target="_self"
                        >
                          <FormattedMessage id="common_for_companies" defaultMessage="For Companies" />
                        </a>
                      </li>

                      {isAuthenticated ? (
                        <>
                          <div className="d-block d-lg-none" id="loginSectionDisplay">
                            <SidemenuBorder />
                            <MyAccount role="button" onClick={this.handleMyaccount}>
                              <FormattedMessage id="common_my_account" defaultMessage="My account" />
                            </MyAccount>
                            <Logout role="button" onClick={this.handleLogOut} className="mr-0 mt-4 pt-2 justify-content-end">
                              <FormattedMessage id="common_log_out" defaultMessage="Log out" />
                            </Logout>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-block d-lg-none" id="loginSectionDisplay">
                            <SignupButton
                              className="signup-btn"
                              onClick={() => history.push(Constants.routes.signUp.url)}
                              role="button"
                            >
                              <FormattedMessage id="gigger_sign_up" defaultMessage="Sign up" />
                            </SignupButton>
                            <MobileLoginButton
                              role="button"
                              onClick={() => history.push(Constants.routes.logIn.url)}
                            >
                              <FormattedMessage id="common_log_in" defaultMessage="Log in" />
                            </MobileLoginButton>
                          </div>
                        </>
                      )}
                    </ul>
                  </div>
                  <div className="new d-none d-lg-block" id="loginSectionDisplay">
                    <div className="row mt-lg-1 m-0 ">
                      {isAuthenticated ? (
                        <>
                          <div className="col-lg-6 sign-gig p-0">
                            <MyAccount role="button" onClick={this.handleMyaccount}>
                              <FormattedMessage id="common_my_account" defaultMessage="My account" />
                            </MyAccount>
                          </div>
                          <div className="col-lg-6 login-gig pr-0">
                            <Logout role="button" onClick={this.handleLogOut} className="mr-0">
                              <FormattedMessage id="common_log_out" defaultMessage="Log out" />
                            </Logout>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6 sign-gig p-0">
                            <SignupButton
                              className="signup-btn"
                              onClick={() => history.push(Constants.routes.signUp.url)}
                              role="button"
                            >
                              <FormattedMessage id="gigger_sign_up" defaultMessage="Sign up" />
                            </SignupButton>
                          </div>
                          <div className="col-lg-6 color-primary p-0">
                            <LoginButton
                              className="login-btn mx-0"
                              role="button"
                              onClick={() => history.push(Constants.routes.logIn.url)}
                            >
                              <FormattedMessage id="common_log_in" defaultMessage="Log in" />
                            </LoginButton>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Div>
    );
  }
}

export default withRouter(Header);
