import React, { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { FilterListWrapper } from '../styles';
import Modal from '../Modal';
import FilterPageService from '../../../../services/AdminServices/FilterPageService';
import Config from '../../../../config';
import { Border } from '../../users/styles';
import LoadingCard from '../../LoadingCard';
import { WhiteCard } from '../../../gigger/styles';
import Pagination from '../../../../components/Pagination/Pagination';
import { getFormattedCSVData } from '../../../../shared/utils';
import ListView from '../ListView/ListView';
import ListViewHeader from '../ListView/ListViewHeader';
import ToolBar from '../../ToolBar';
import Constants from '../../../../shared/constants';

const CategoryTab = ({ loading, handleToast }) => {
  const [categoryTabPageLoading, setCategoryTabPageLoading] = useState(false);
  const [isApproveCategoryModalOpen, setIsApproveCategoryModalOpen] = useState(false);
  const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [data, setData] = useState([]);
  const [searchbar, setSearchBar] = useState(true);
  const [sortedBy, setSortedBy] = useState('created_at');
  const [sortAscending, setSortAscending] = useState(false);
  const [csvDownloadData, setCsvDownloadData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const csvExportFileds = ['name', 'type', 'added_by', 'created_at', 'status'];
  const filterPageService = new FilterPageService(Config.apiBase);
  const [meta, setMeta] = useState({
    previous: null,
    current: 0,
    next: 1,
    count: null,
    pageNumber: 1,
    pageSize: 15,
    pages: 1,
  });
  const openElement = useRef(null);
  const categoryCsv = useRef(null);

  /**
   *
   * @async
   * @function fetchCategories Fetches job title filters
   * @param {String} searchInput search text
   *
   */
  const fetchCategories = (searchInput) => {
    setCategoryTabPageLoading(true);
    const queryParams = [
      ['pageNumber', searchInput ? 1 : meta.pageNumber],
      ['pageSize', meta.pageSize],
      ['sortedBy', sortedBy],
      ['sortAscending', sortAscending],
      ['search', searchInput],
      ['type', Constants.filter.CATEGORY],
    ];
    try {
      filterPageService.getCategoriesList(queryParams).then((response) => {
        const { meta: srcMetaData = {} } = response;
        const {
          current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
        } = srcMetaData;
        const metaData = {
          pageNumber: currentPage,
          pageSize: perPage,
          pages,
          totalCount,
        };
        setCategoryTabPageLoading(false);
        setCategoryData(response.data);
        setMeta(metaData);
      });
    } catch (e) {
      setCategoryTabPageLoading(false);
    }
  };

  /**
   *
     * Calls fetchCategories function when the below values changes
     * [isDeleteModalOpen,isApproveModalOpen, csvDownloadData]
     *
     */
  useEffect(() => {
    if (isDeleteCategoryModalOpen || isApproveCategoryModalOpen) {
      openElement.current.click();
    }
  }, [isDeleteCategoryModalOpen, isApproveCategoryModalOpen]);

  /**
   *
     * Calls fetchCategories function when the below values changes
     * [loading, meta.pageNumber, showToast, sortAscending, sortedBy]
     *
     */
  useEffect(() => {
    fetchCategories();
  }, [loading, sortAscending, sortedBy]);

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  const handleCategoryListPagination = (pageNumber) => {
    meta.pageNumber = pageNumber;
    meta.pageSize = 15;
    setMeta(meta);
    fetchCategories();
  };

  /**
   *
   * Handles search functionality
   * @param {String} value search text
   *
   */
  const handlesearchCategories = (value) => {
    setSearchQuery(value);
    setSearchBar(value.length <= 0);
    fetchCategories(value);
  };

  /**
   *
   * Handles change in search value
   * @param {Event} event search text
   *
   */
  const handleSearchOnChange = (event) => {
    const { value } = event.target;
    setSearchQuery(value);
  };

  /**
   *
   * Downloads data in the form of csv file
   *
   */
  const handleDownloadCsv = async () => {
    setCategoryTabPageLoading(true);
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
      ['type', Constants.filter.CATEGORY],
    ];
    try {
      const response = await filterPageService.getCategoriesList(queryParams);
      setCsvDownloadData(getFormattedCSVData(response.data));
      setCategoryTabPageLoading(false);
      categoryCsv.current.link.click();
    } catch (e) {
      setCategoryTabPageLoading(false);
    }
  };

  /**
   *
   * Opens delete modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleDeleteCategory = (deleteData) => () => {
    setIsDeleteCategoryModalOpen(true);
    setData(deleteData);
  };

  /**
   *
   * Opens approve modal
   * @param {Object} data data of the selected gigger
   *
   */
  const handleApproveCategory = (approveData) => () => {
    setIsApproveCategoryModalOpen(true);
    setData(approveData);
  };

  /**
   *
   * Closes the opened modal
   *
   */
  const handleCloseAllModals = () => {
    setIsApproveCategoryModalOpen(false);
    setIsDeleteCategoryModalOpen(false);
  };

  /**
   *
   * Handles sorting in column
   * @param {string} name header
   *
   */
  const handleSortCategoryList = (name) => {
    if (sortedBy === name) {
      setSortAscending(!sortAscending);
    } else {
      setSortedBy(name);
      setSortAscending(true);
    }
  };

  /**
   *
   * Opens out search bar
   *
   */
  const openSearchBar = () => {
    setSearchBar(false);
  };

  return (
    <>
      <CSVLink data={csvDownloadData} filename="category.csv" className="d-none" ref={categoryCsv}>
        click
      </CSVLink>
      <FilterListWrapper>
        <ToolBar
          searchbar={searchbar}
          handleSearch={handlesearchCategories}
          handleSearchOnChange={handleSearchOnChange}
          searchQuery={searchQuery}
          handleDownloadCsv={handleDownloadCsv}
          openSearchBar={openSearchBar}
          data={categoryData}
        />
        {categoryTabPageLoading && (
        <FilterListWrapper>
          <LoadingCard />
        </FilterListWrapper>
        )}
        {!categoryTabPageLoading && (
        <>
          <ListViewHeader
            handleSorting={handleSortCategoryList}
            sortedBy={sortedBy}
            sortAscending={sortAscending}
          />
          {categoryData.length > 0 ? (
            <>
              <ListView
                data={categoryData}
                handleApprove={handleApproveCategory}
                handleDelete={handleDeleteCategory}
                type={<FormattedMessage id="common_category" defaultMessage="Category" />}
              />
              <a
                href
                data-backdrop="static"
                data-keyboard="false"
                data-toggle="modal"
                data-target="#FilterModal"
                ref={openElement}
              />
              {isApproveCategoryModalOpen && (
              <Modal
                approve={isApproveCategoryModalOpen}
                id={data._id}
                type={Constants.filter.CATEGORY}
                ModalClose={handleCloseAllModals}
                handleToast={handleToast}
                fetchData={fetchCategories}
              />
              )}
              {isDeleteCategoryModalOpen && (
              <Modal
                remove={isDeleteCategoryModalOpen}
                id={data._id}
                type={Constants.filter.CATEGORY}
                ModalClose={handleCloseAllModals}
                handleToast={handleToast}
                fetchData={fetchCategories}
              />
              )}
            </>
          ) : (
            <>
              <Border />
              <WhiteCard className="my-3">
                {searchQuery.length > 0 ? (
                  <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
                ) : (
                  <FormattedMessage id="not_found_filter_category" defaultMessage="No categories found" />
                )}
              </WhiteCard>
            </>
          )}
        </>
        )}
      </FilterListWrapper>
      {!categoryTabPageLoading && meta.pages > 1 && (
      <FilterListWrapper style={{ backgroundColor: 'transparent' }}>
        <Pagination cursor={meta} gotoPage={handleCategoryListPagination} />
      </FilterListWrapper>
      )}
    </>
  );
};

export default CategoryTab;
