import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Nopage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <h1><FormattedMessage id="page_under_construction_text" defaultMessage="Page under construction...." /></h1>;
  }
}

export default Nopage;
