import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  Container,
  CardWrapper,
  Content,
  CurrentPage,
  Header,
  Image,
  SaveButton,
  SubHeader2,
} from './styles';
import Constants from '../../shared/constants';
import { Line } from '../SignUp/styles';
import Button from '../../components/Button/Button';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import CustomerService from '../../services/CustomerService';
import AuthService from '../../services/AuthService';
import Loader from '../../components/Loader/Loader';
import { ButtonWrapper } from '../Password/styles';
import TermsAndConditions from '../../components/RedirectLinks/TermsAndConditions';
import Colors from '../../styles/Colors';

class DeleteAccount extends Component {
  giggerService = new GiggerService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      isSubmitted: false,
      proceed: true,
      feedback: '',
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
    };
  }

  /**
   * Redirects to user details page
   */
  handleUserDetailsPage = () => {
    const { history, location = {} } = this.props;
    const { gigger, admin } = location.state;
    if (gigger) {
      history.push(Constants.routes.giggerUserDetails.url);
    } else if (admin) {
      history.push(Constants.routes.adminUserDetails.url);
    } else {
      history.push(Constants.routes.customerUserDetails.url);
    }
  };

  /**
   * Handles change in input value
   * @param {event} event
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * @async
   * @function handleSubmitFeedback Answers to delete account or not?
   */
  handleSubmitFeedback = async () => {
    this.setState({ loading: true });
    const { location = {} } = this.props;
    const { gigger, admin } = location.state;
    const { feedback } = this.state;
    const data = {
      feedback,
    };
    if (gigger) {
      try {
        await this.giggerService.deleteAccount(data);
      } catch (e) {
        console.error(e);
      }
    } else if (admin) {
      try {
        await this.customerService.deleteAdminAccount();
      } catch (e) {
        // TODO: Handle error.
      }
    } else {
      try {
        await this.customerService.deleteAccount(data);
      } catch (e) {
        console.error(e);
      }
    }
    this.setState({ isSubmitted: true, proceed: false, loading: false });
  };

  /**
   * Redircts to my account page
   */
  handleBackToMyAccount = () => {
    const { history, location = {} } = this.props;
    const { gigger, admin } = location.state;
    if (gigger) {
      history.push(Constants.routes.giggerMyAccount.url);
    } else if (admin) {
      history.push(Constants.routes.adminMyAccount.url);
    } else {
      history.push(Constants.routes.customerMyAccount.url);
    }
  };

  /**
   * Proceeds to delete account page
   */
  handleProceed = () => {
    this.setState({ proceed: false });
  };

  /**
   * Returns to proceed page
   */
  handleBack = () => {
    this.setState({ proceed: true });
  };

  /**
   * Checks agree terms and conditions page
   */
  handleAgreeTermsCheckbox = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected });
  };

  render() {
    const {
      isSubmitted, proceed, loggedIn, loading,
    } = this.state;

    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <CardWrapper>
              {proceed ? (
                <>
                  {loading && <Loader />}
                  {!loading && (
                    <CurrentPage role="button" onClick={this.handleUserDetailsPage}>
                      <Image src={Constants.icons.ArrowLeft} alt="icon" />
                      <Line>
                        <FormattedMessage id="common_back" defaultMessage="Back" />
                      </Line>
                    </CurrentPage>
                  )}
                  {loading && <Loader />}
                  {!loading && (
                    <Header>
                      <FormattedMessage
                        id="delete_account_confirmation"
                        defaultMessage="Do you really want to delete your account?"
                      />
                    </Header>
                  )}
                  {loading && <Loader />}
                  {!loading && (
                    <SubHeader2 className="my-3">
                      <FormattedMessage
                        id="customer_delete_account_info"
                        defaultMessage="If you choose to delete your account, a confirmation email will be sent to your registered email address. All your personal information will be deleted or anonymized, with the exception of such information that we are required by law to retain, such as information about the company and previous orders. For more information, see our {privacyLink}."
                        values={{
                          termsAndConditionsLink: <TermsAndConditions
                            color={Colors.Secondary}
                          />,
                        }}
                      />
                    </SubHeader2>
                  )}
                  <SaveButton>
                    {loading && <Loader />}
                    {!loading && (
                      <Button
                        type="cancel"
                        name={<FormattedMessage id="common_proceed" defaultMessage="Proceed" />}
                        onClick={this.handleSubmitFeedback}
                      />
                    )}
                  </SaveButton>
                </>
              ) : (
                <>
                  {isSubmitted && (
                    <>
                      <Header>
                        <FormattedMessage
                          id="delete_account_confirmation_mail"
                          defaultMessage="Confirmation mail has been sent"
                        />
                      </Header>
                      <SubHeader2>
                        <FormattedMessage
                          id="delete_account_info"
                          defaultMessage="Your account will be deleted and a confirmation e-mail has been sent to your registered e-mail address"
                        />
                      </SubHeader2>
                      <Content className="col p-0 my-4">
                        <ButtonWrapper onClick={this.handleBackToMyAccount}>
                          <FormattedMessage id="common_back_to_home" defaultMessage="Back to home" />
                        </ButtonWrapper>
                      </Content>
                    </>
                  )}
                </>
              )}
            </CardWrapper>
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default DeleteAccount;
