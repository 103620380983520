import React from 'react';
import {
  Divison,
  Section,
  LabelWrapper,
  Label,
  MandatoryLabel,
  Slider,
  MinSlider,
  MinDrag,
  MaxSlider,
  MaxDrag,
  RangeInput,
  RangeLabel,
} from './styles';
import { Row } from '../../styles/CommonStyles';
import Constants from '../../shared/constants';

let timeout;

class DoubleRangeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      min: props.min || 0,
      max: props.max || 100,
      minValueBetween: 10,
      currentMin: props.minValue || 55,
      inputMin: props.minValue || 55,
      currentMax: props.maxValue || 100,
      inputMax: props.maxValue || 100,
    };
  }

  componentDidMount() {
    const {
      currentMin, currentMax, max,
    } = this.state;

    this.minValue.style.width = `${(currentMin * 100) / max}%`;
    this.maxValue.style.width = `${(currentMax * 100) / max}%`;
  }

  componentDidUpdate(prevProps) {
    this.onUpdate(prevProps);
  }

  onUpdate = (prevProps) => {
    const { minValue } = this.props;
    let { maxValue } = this.props;
    const { max } = this.state;
    if (prevProps.minValue !== minValue || prevProps.maxValue !== maxValue) {
      this.setState({
        inputMin: minValue, inputMax: maxValue, currentMin: minValue, currentMax: maxValue,
      });
      maxValue = maxValue > max ? max : maxValue;
      this.maxValue.style.width = `${(maxValue * 100) / max}%`;
      this.minValue.style.width = `${(minValue * 100) / max}%`;
    }
  }

  /**
   * Sets min value
   * @param {event} e
   */

  setMin = (e) => {
    const {
      min, max, currentMax, minValueBetween,
    } = this.state;
    const inputMin = e.target.value;

    this.setState({
      inputMin,
    });

    if (inputMin >= min && inputMin <= currentMax - minValueBetween) {
      this.setState({
        currentMin: parseInt(inputMin, 10),
      });

      this.minValue.style.width = `${(inputMin * 100) / max}%`;
    }
    this.debounceSlider(inputMin, currentMax);
  };

  handleSetMinOnBlur = () => {
    const {
      min, max, currentMax, minValueBetween,
    } = this.state;
    let { inputMin } = this.state;
    if (!(inputMin >= min && inputMin <= currentMax - minValueBetween)) {
      inputMin = min;
      this.setState({
        inputMin,
        currentMin: parseInt(inputMin, 10),
      });
      this.minValue.style.width = `${(inputMin * 100) / max}%`;
      this.debounceSlider(inputMin, currentMax);
    }
  }

  handleSetMaxOnBlur = () => {
    const {
      max, minValueBetween, currentMin,
    } = this.state;
    let { inputMax } = this.state;
    if (!(inputMax >= currentMin + minValueBetween && inputMax <= max)) {
      inputMax = max;
      this.setState({
        inputMax,
        currentMax: parseInt(inputMax, 10),
      });
      this.maxValue.style.width = `${(inputMax * 100) / max}%`;
      this.debounceSlider(currentMin, inputMax);
    }
  }

  /**
   * Handels change in slider value (minimum)
   * @param {event} e
   */

  changeMinValue = (e) => {
    e.preventDefault();

    document.addEventListener('mousemove', this.onMouseMoveMin);
    document.addEventListener('mouseup', this.onMouseUpMin);
  };

  /**
   * Slider happens when user waits for few seconds.
   * @param {string} currentMax slider current mx value
   * @param {string} currentMin slider current min value
   */
  debounceSlider = (currentMin, currentMax) => {
    const { sliderDelay } = Constants;
    const { sliderChange } = this.props;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      if (sliderChange) {
        sliderChange(currentMin, currentMax);
      }
    }, sliderDelay);
  }

  /**
   * On mouse move event
   * @param {event} e
   */

  onMouseMoveMin = (e) => {
    const {
      min, max, currentMax, minValueBetween,
    } = this.state;
    const { isPortal } = this.props;

    let clientX = 0;
    if (e.type === 'mousemove') {
      clientX = e.clientX;
    } else if (e.type === 'touchmove') {
      const { touches } = e;
      clientX = (touches[0] && touches[0].clientX) || 0;
    }
    const sliderWidth = this.slider.offsetWidth;
    let offsetSliderWidht = this.slider.offsetLeft;
    if (isPortal) {
      offsetSliderWidht += 232;
    }
    const dragedWidht = clientX - offsetSliderWidht;

    if (dragedWidht >= 0) {
      const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
      const currentMin = Math.abs(parseInt((max * dragedWidhtInPercent) / 100, 10));

      if (currentMin >= min && currentMin <= currentMax - minValueBetween) {
        this.minValue.style.width = `${dragedWidhtInPercent}%`;
        this.minValue.dataset.content = currentMin;
        this.debounceSlider(currentMin, currentMax);
        this.setState({
          currentMin,
          inputMin: currentMin,
        });
      }
    }
  };

  /**
   * on mouse up event
   */

  onMouseUpMin = () => {
    document.removeEventListener('mouseup', this.onMouseUpMin);
    document.removeEventListener('mousemove', this.onMouseMoveMin);
  };

  /**
   * Sets the maximum value
   * @param {event} e
   */

  setMax = (e) => {
    const { max, currentMin, minValueBetween } = this.state;
    const inputMax = e.target.value;

    this.setState({
      inputMax,
    });

    if (inputMax >= currentMin + minValueBetween && inputMax <= max) {
      this.setState({
        currentMax: parseInt(inputMax, 10),
      });
      this.maxValue.style.width = `${(inputMax * 100) / max}%`;
    }
    this.debounceSlider(currentMin, inputMax);
  };

  /**
   * Handels change in slider value (maximum)
   * @param {event} e
   */

  changeMaxValue = (e) => {
    e.preventDefault();

    document.addEventListener('mousemove', this.onMouseMoveMax);
    document.addEventListener('mouseup', this.onMouseUpMax);
  };

  /**
   * On mouse move event
   * @param {event} e
   */

  onMouseMoveMax = (e) => {
    const { max, currentMin, minValueBetween } = this.state;
    const { isPortal } = this.props;

    let clientX = 0;
    if (e.type === 'mousemove') {
      clientX = e.clientX;
    } else if (e.type === 'touchmove') {
      const { touches } = e;
      clientX = (touches[0] && touches[0].clientX) || 0;
    }

    const sliderWidth = this.slider.offsetWidth;
    let offsetSliderWidht = this.slider.offsetLeft;
    if (isPortal) {
      offsetSliderWidht += 232;
    }
    const dragedWidht = clientX - offsetSliderWidht;
    if (dragedWidht >= 0) {
      const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
      const currentMax = Math.abs(parseInt((max * dragedWidhtInPercent) / 100, 10));

      if (currentMax >= currentMin + minValueBetween && currentMax <= max) {
        this.maxValue.style.width = `${dragedWidhtInPercent}%`;
        this.maxValue.dataset.content = currentMax;
        this.debounceSlider(currentMin, currentMax);
        this.setState({
          currentMax,
          inputMax: currentMax,
        });
      }
    }
  };

  /**
   * on mouse up event
   */

  onMouseUpMax = () => {
    document.removeEventListener('mouseup', this.onMouseUpMax);
    document.removeEventListener('mousemove', this.onMouseMoveMax);
  };

  maxForMin = () => {
    const { currentMax, minValueBetween } = this.state;
    return currentMax - minValueBetween;
  };

  minForMax = () => {
    const { currentMin, minValueBetween } = this.state;
    return currentMin + minValueBetween;
  };

  handleKeypress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  render() {
    const {
      min, max, currentMin, inputMin, currentMax, inputMax,
    } = this.state;
    const {
      label, mandatory, forFilter, className,
    } = this.props;

    return (
      <Section className={className}>
        {label && (
          <LabelWrapper>
            <Label forFilter={forFilter} mandatory={mandatory}>
              {label || ''}
            </Label>
            {mandatory && <MandatoryLabel mandatory={mandatory}>{mandatory}</MandatoryLabel>}
          </LabelWrapper>
        )}

        <Slider ref={(ref) => { this.slider = ref; }}>
          <MinSlider
            ref={(ref) => { this.minValue = ref; }}
            data-content={currentMin}
          >
            <MinDrag
              onMouseDown={this.changeMinValue}
              onTouchMove={this.onMouseMoveMin}
            />
          </MinSlider>

          <MaxSlider
            ref={(ref) => { this.maxValue = ref; }}
            data-content={currentMax}
          >
            <MaxDrag
              onMouseDown={this.changeMaxValue}
              onTouchMove={this.onMouseMoveMax}
            />
          </MaxSlider>
        </Slider>
        <Row>
          <Divison className="col-6 p-0">
            <RangeInput
              id="min-input"
              type="number"
              onChange={this.setMin}
              value={inputMin}
              min={min}
              max={this.maxForMin}
              onBlur={this.handleSetMinOnBlur}
              onKeyPress={this.handleKeypress}
            />

            <RangeLabel htmlFor="min-input align-self-center">kr</RangeLabel>
          </Divison>
          <Divison className="col-6 p-0">
            <RangeInput
              id="max-input"
              type="number"
              onChange={this.setMax}
              value={inputMax}
              min={this.minForMax}
              max={max}
              onBlur={this.handleSetMaxOnBlur}
              onKeyPress={this.handleKeypress}
            />

            <RangeLabel htmlFor="max-input align-self-center">kr</RangeLabel>
          </Divison>
        </Row>
      </Section>
    );
  }
}
export default DoubleRangeSlider;
