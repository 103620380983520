import React from 'react';

const TickIcon = ({ fill, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 224 224" width={size} height={size}>
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="1"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit="10"
      strokeDasharray=""
      strokeDashoffset="0"
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
      style={{ mixBlendMode: 'normal' }}
    >
      <path d="M0,224v-224h224v224z" fill="none" />
      <g fill={fill}>
        <g id="surface1">
          <path d="M194.41827,40.75481l-15.44711,-10.5c-4.27404,-2.89424 -10.12981,-1.78365 -12.99039,2.45673l-75.72115,111.66347l-34.79808,-34.79808c-3.63462,-3.63462 -9.55769,-3.63462 -13.19231,0l-13.22596,13.22596c-3.63462,3.63462 -3.63462,9.55769 0,13.22597l53.50961,53.50961c2.9952,2.99519 7.70674,5.28365 11.94712,5.28365c4.24039,0 8.51442,-2.65865 11.27404,-6.66346l91.13462,-134.44712c2.89423,-4.24038 1.78365,-10.0625 -2.49039,-12.95673z" />
        </g>
      </g>
    </g>
  </svg>
);
export default TickIcon;
