import styled from 'styled-components';
import Colors from '../../../styles/Colors';
import Fonts from '../../../styles/Fonts';
import { BackgroundImage } from '../../../styles/Images';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.DarkWhite};
  background-image: url("${BackgroundImage}");
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;
export const Container = styled.div`
  margin: 24px 28px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 768px) {
    width: 56%;
    margin-left: 22%;
    margin-right: 22%;
    align-self: center;
  }
  @media (max-width: 570px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
  }
  @media (max-width: 375px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
  }
`;
export const UsersListWrapper = styled.div`
  max-width: 1015px;
  background-color: ${Colors.White};
  padding: 5px 10px;
  height: auto;
`;
export const Border = styled.div`
  border-top: 1px solid #f1f5f7 !important;
`;
export const TableHeader = styled.span`
  font-family: ${Fonts.fontFamily.AvenirBold};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
`;
export const TableValue = styled(TableHeader)`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-weight: normal;
  text-transform: capitalize;
`;
export const TableData = styled.div`
  padding: 11px 6px;
`;
export const Wrapper = styled.div``;
export const OptionButton = styled.div`
  width: 24px;
  height: 24px;
`;
export const OptionWrapper = styled.div`
  top: 25px;
`;
