import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card, CancelButton, Button, Content, Span, Input,
} from '../../components/Modals/styles';
import Constants from '../../shared/constants';
import {
  SubHeader,
  ProfileSubHeading,
  FormFieldWrapperLeft,
  FormFieldWrapperRight,
  JuniorLevelTag,
  ExperienceLevelTag,
  MidLevelTag,
  DeleteButton,
  UploadCondition,
} from './styles';
import Document from '../../components/Document';
import { Border, WhiteButton } from '../customer/styles';
import { WhiteCard } from '../gigger/styles';
import TextBox from '../../components/Input/TextBox';
import MultiSelect from '../../components/Input/MultiSelect';
import { Row } from '../../styles/CommonStyles';
import ProfilePageService from '../../services/ProfilePageService';
import AccountService from '../../services/AccountService';
import Config from '../../config';
import Select from '../../components/Input/Select';
import Loader from '../../components/Loader/Loader';
import { getYearsOptions } from '../../shared/utils';

class UploadCVModal extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  accountService = new AccountService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      skillsOptions: [],
      languagesOptions: props.languagesOptions.map((o) => ({ ...o, isSelected: false })) || [],
      skills: '',
      languages: '',
      systems: '',
      cvfile: {},
      previousJobs: [],
      education: [],
      yearsOfExperience: props.yearsOfExperience,
      jobTitle: props.jobTitle,
      experienceOptions: [
        { label: '0-3', value: '0-3' },
        { label: '4-10', value: '4-10' },
        { label: '10+', value: '10+' },
      ],
      jobTitleOptions: [],
      loading: false,
      InvalidDocError: false,
      year: getYearsOptions(Constants.startingYear),
    };
  }

  componentDidMount = async () => {
    let filterList = await this.accountService.getAllFilter();
    filterList = filterList.data;
    this.setState({
      skillsOptions: filterList.skills.map((skill) => (
        { ...skill, status: Constants.status.APPROVED })),
      systemsOptions: filterList.systems.map((skill) => (
        { ...skill, status: Constants.status.APPROVED })),
      jobTitleOptions: filterList.jobtitle,
    });
  };

  /**
   * Handles change in select input field
   * @param {String} name Name of the input field
   * @param {String} value Value of the input field
   */
  handleSelectChange = (name, value) => {
    this.setState({ [name]: value }, this.validate);
  };

  /**
   * Handles data from uploaded cv
   */
  handleSubmitCV = () => {
    const { handleUpdateCV } = this.props;
    const {
      skillsOptions, cvfile, previousJobs, yearsOfExperience, jobTitle, education,
      languagesOptions, systemsOptions,
    } = this.state;
    let { skills, languages, systems } = this.state;
    if (typeof skills !== 'object') skills = skills.split(', ');
    if (typeof languages !== 'object') languages = languages.split(', ');
    if (typeof systems !== 'object') systems = systems.split(', ');
    const data = {
      skillsOptions,
      languagesOptions,
      languages,
      skills,
      cvfile,
      previousJobs,
      yearsOfExperience,
      jobTitle,
      education,
      systemsOptions,
      systems,
    };
    handleUpdateCV(data);
  };

  /**
   * @function handleDateToYear checks year from the given date.
   * @param {Date} date
   * @returns year from date
   */
  handleDateToYear = (date) => {
    if (date) {
      const newDate = new Date(date);
      return newDate.getFullYear();
    }
    return '';
  };

  /**
   * Form UI
   */
  renderForm = (data = [], index = 0, education = false) => {
    const { year } = this.state;
    return (
      <>
        {!education && (
          <SubHeader head className="mt-3 fs-14 font-weight-bold">
            <FormattedMessage id="common_job" defaultMessage="Job" />
          &nbsp;
            {index + 1}
          </SubHeader>
        )}
        <div className="mb-4 mt-3">
          <SubHeader>
            {education ? (
              <FormattedMessage id="common_school" defaultMessage="School" />
            ) : (
              <FormattedMessage id="common_title" defaultMessage="Title" />
            )}
          </SubHeader>
          <TextBox
            name={education ? 'institution_name' : 'title'}
            value={education ? data.institution_name : data.title}
            onChange={this.handleChange(index, education)}
            noEmptyError
          />
        </div>
        <div>
          <SubHeader>
            {education ? (
              <FormattedMessage id="common_degree" defaultMessage="Degree" />
            ) : (
              <FormattedMessage id="common_employer" defaultMessage="Employer" />
            )}
          </SubHeader>
          <TextBox
            name={education ? 'degree' : 'company_name'}
            value={education ? data.degree : data.company_name}
            onChange={this.handleChange(index, education)}
            noEmptyError
          />
        </div>
        <Row className="mb-3 p-0">
          <>
            <div className="col-l-6 col-xl-6 p-0">
              <FormFieldWrapperLeft>
                <Input>
                  <Select
                    label={<FormattedMessage id="common_from" defaultMessage="From" />}
                    options={year}
                    name="start_date"
                    value={data.start_date}
                    onChange={this.handleChangeInDate(index)}
                    errorMessage={<FormattedMessage id="error_select_year_message" defaultMessage="Select year" />}
                    search={false}
                  />
                </Input>
              </FormFieldWrapperLeft>
            </div>
            <div className="col-l-6 col-xl-6 p-0">
              <FormFieldWrapperRight>
                <Input>
                  <Select
                    label={<FormattedMessage id="common_to" defaultMessage="To" />}
                    options={year}
                    name="end_date"
                    value={data.end_date}
                    onChange={this.handleChangeInDate(index)}
                    errorMessage={<FormattedMessage id="error_select_year_message" defaultMessage="Select year" />}
                    search={false}
                  />
                </Input>
              </FormFieldWrapperRight>
            </div>
          </>
        </Row>
        <Border />
      </>
    );
  };

  /**
   * Removes document and reverts changes
   */
  handleRemoveDocument = () => {
    const {
      skillsOptions, jobTitle, yearsOfExperience, languagesOptions, systemsOptions,
    } = this.props;
    this.setState({
      skillsOptions: skillsOptions.map((obj) => ({ ...obj, isSelected: false })),
      systemsOptions: systemsOptions.map((obj) => ({ ...obj, isSelected: false })),
      systems: '',
      skills: '',
      cvfile: {},
      previousJobs: [],
      education: [],
      yearsOfExperience,
      jobTitle,
      languages: '',
      languagesOptions: languagesOptions.map((obj) => ({ ...obj, isSelected: false })),
      InvalidDocError: false,
    });
  };

  /**
   * Parsing of skills
   */
  parseSkills = () => {
    let {
      skills, skillsOptions, systemsOptions, systems,
    } = this.state;
    skills = skills.length ? skills : [];
    const newlyAddedOptions = [];

    skills
      .forEach((userSkill) => {
        skillsOptions = skillsOptions.map((skillOption) => {
          if (skillOption.label
            && skillOption.label.trim().toLowerCase() === userSkill.trim().toLowerCase()) {
            return { ...skillOption, isSelected: true };
          }
          return skillOption;
        });
        systemsOptions = systemsOptions.map((systemOption) => {
          if (systemOption.label
            && systemOption.label.trim().toLowerCase() === userSkill.trim().toLowerCase()) {
            return { ...systemOption, isSelected: true };
          }
          return systemOption;
        });
      });
    skillsOptions = [...skillsOptions, ...newlyAddedOptions];
    skills = skillsOptions.filter((a) => a.isSelected).map((b) => b.label);
    systems = systemsOptions.filter((a) => a.isSelected).map((b) => b.label);
    this.setState({
      skillsOptions, skills, systemsOptions, systems,
    });
  };

  /**
   * Parsing of languages
   */
  parseLanguages = () => {
    let { languagesOptions, languages } = this.state;
    languages = languages.length ? languages : [];
    languages = languages.map((userlanguage) => {
      languagesOptions = languagesOptions.map((option) => {
        if (option.value
            && option.value.trim().toLowerCase() === userlanguage.trim().toLowerCase()) {
          return { ...option, isSelected: true };
        }
        return option;
      });
      return userlanguage;
    }).filter((parsedLang) => parsedLang);
    this.setState({ languagesOptions, languages });
  }

  /**
   * Handles change in document and data parsing
   * @param {Object} filedata uploading file data
   */
  handleDocumentChange = async (filedata) => {
    this.setState({ loading: true, InvalidDocError: false });
    let { InvalidDocError } = this.state;
    const { jobTitle = '', yearsOfExperience = '' } = this.state;
    const { data: cvfile = {} } = filedata;
    const resumeData = { id: cvfile._id };
    try {
      let response = await this.profilePageService.getResumeDetails(resumeData);
      response = response.data || [];
      const { data: resumedetails = {} } = response;
      const {
        job_title: jobTitleFromParser = jobTitle,
        previous_jobs: previousJobs = [],
        skills = [],
        language_spoken: languages = [],
      } = resumedetails;
      let { education = [] } = resumedetails;
      previousJobs.map((obj) => {
        const job = obj;
        const startDate = this.handleDateToYear(job.start_date);
        const endDate = this.handleDateToYear(job.end_date);
        job.start_date = startDate;
        job.end_date = endDate;
        return job;
      });
      let { year_of_experience: yearsOfExperienceFromParser = yearsOfExperience } = resumedetails;
      if (yearsOfExperienceFromParser >= 10) {
        yearsOfExperienceFromParser = '10+';
      } else if (yearsOfExperienceFromParser < 10 && yearsOfExperienceFromParser > 3) {
        yearsOfExperienceFromParser = '4-10';
      } else {
        yearsOfExperienceFromParser = '0-3';
      }

      education = education.map((obj) => {
        const startYear = obj.start_date ? obj.start_date.toString().split('-')[0] : '';
        const endYear = obj.end_date ? obj.end_date.toString().split('-')[0] : '';
        return { ...obj, start_date: startYear, end_date: endYear };
      });
      this.setState(
        {
          cvfile,
          jobTitle: jobTitleFromParser === '' ? jobTitle : jobTitleFromParser,
          previousJobs,
          skills,
          languages,
          education,
          yearsOfExperience: yearsOfExperienceFromParser === '' ? yearsOfExperience : yearsOfExperienceFromParser,
          loading: false,
        },
        () => {
          this.parseSkills();
          this.parseLanguages();
        },
      );
    } catch (e) {
      const { response = {} } = e;
      const { status = '' } = response;
      if (Constants.errorStatusCodes.includes(status)) {
        InvalidDocError = true;
      }
      this.setState({ loading: false, InvalidDocError, cvfile });
    }
  };

  /**
   * handles change in education or previous jobs
   * @param {Number} index index value of changed data
   * @param {Object} education boolean value
   */
  handleChange = (index, education) => (event) => {
    const { name, value } = event.target;
    const { education: cvEducation, previousJobs } = this.state;
    if (education) {
      cvEducation[index][name] = value;
      this.setState({ education: cvEducation });
    } else {
      previousJobs[index][name] = value;
      this.setState({ previousJobs });
    }
  };

  /**
   * Handles change in date for previous jobs
   * @param {Number} index Index value of previous job data.
   */
  handleChangeInDate = (index) => (name, value) => {
    const { previousJobs } = this.state;
    previousJobs[index][name] = value;
    if (previousJobs[index].start_date !== '' && previousJobs[index].end_date !== '') {
      if (Date.parse(previousJobs[index].start_date) > Date.parse(previousJobs[index].end_date)) {
        previousJobs[index].error = true;
      } else {
        previousJobs[index].error = false;
      }
    }
    this.setState({ previousJobs });
  };

  /**
   * Form UI
   */
  renderUploadCvBody = () => {
    const { ModalClose } = this.props;
    const {
      skillsOptions,
      systemsOptions,
      languagesOptions,
      skills,
      systems,
      previousJobs,
      education,
      cvfile,
      yearsOfExperience,
      jobTitle,
      jobTitleOptions,
      experienceOptions,
      loading,
      languages,
      InvalidDocError,
    } = this.state;
    return (
      <>
        <div className="px-3 mx-2 mt-3">
          <UploadCondition head className="mb-1">
            <FormattedMessage id="modal_upload_cv_description" defaultMessage="CV" />
          </UploadCondition>
          <Input className="mt-0">
            <UploadCondition>
              2 mb
            </UploadCondition>
            {loading && <Loader height="42px" margin="0px 0px 16px 0px" />}
            {!loading && (
              <Document
                files={cvfile}
                onChange={this.handleDocumentChange}
                onRemove={this.handleRemoveDocument}
                title={Constants.docsCategory[0]}
                allowedTypes={Constants.inputTypes.acceptDocTypes}
              />
            )}
            {InvalidDocError && (
            <UploadCondition>
              <FormattedMessage
                id="save_uploaded_file_message"
                defaultMessage="something in your document didn't work, but still save the document, even if the CV-upload-function doesn't work.

"
              />
            </UploadCondition>
            )}
          </Input>
        </div>
        {!loading && cvfile && cvfile.name && !InvalidDocError && (
          <>
            <div className="px-3 mx-2">
              <Input>
                <SubHeader head>
                  <FormattedMessage id="common_previos_jobs" defaultMessage="Previous jobs" />
                </SubHeader>
                <Border />
                {previousJobs.map((obj, index) => this.renderForm(obj, index))}
              </Input>
              <Input>
                <SubHeader head>
                  <FormattedMessage id="common_add_education" defaultMessage="Add education" />
                </SubHeader>
                <Border className="mb-2" />
                {education.map((obj, index) => this.renderForm(obj, index, true))}
              </Input>
              <Input className="mt-4">
                <SubHeader>
                  <FormattedMessage id="common_job_title" defaultMessage="Job title" />
                </SubHeader>
                <Select
                  name="jobTitle"
                  search
                  value={jobTitle}
                  onChange={this.handleSelectChange}
                  options={jobTitleOptions}
                  searchPlaceholder={Constants.language.select_search_or_add}
                  noEmptyError
                />
              </Input>
              <Input className="mt-4">
                <SubHeader>
                  <FormattedMessage id="common_years_of_experience" defaultMessage="Years of experience" />
                </SubHeader>
                <Select
                  name="yearsOfExperience"
                  search
                  options={experienceOptions}
                  value={yearsOfExperience}
                  onChange={this.handleSelectChange}
                  onKeyPress={this.handleKeypress}
                  noEmptyError
                />
              </Input>
              <Input>
                <SubHeader>
                  <FormattedMessage id="common_professional_level" defaultMessage="Professional level" />
                </SubHeader>
                {yearsOfExperience !== '' && (
                  <>
                    {yearsOfExperience === '0-3' ? (
                      <JuniorLevelTag>
                        <FormattedMessage id="common_junior" defaultMessage="Junior" />
                      </JuniorLevelTag>
                    ) : yearsOfExperience === '4-10' ? (
                      <ExperienceLevelTag>
                        <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                      </ExperienceLevelTag>
                    ) : (
                      <MidLevelTag>
                        <FormattedMessage id="common_senior" defaultMessage="Senior" />
                      </MidLevelTag>
                    )}
                  </>
                )}
              </Input>
              <Input className="my-4">
                <SubHeader>
                  <FormattedMessage id="common_skills" defaultMessage="Skills" />
                </SubHeader>
                <MultiSelect
                  showSearchAndAdd
                  name="skills"
                  options={skillsOptions}
                  value={skills}
                  onChange={this.handleSelectChange}
                  noEmptyError
                  placeholder={Constants.language.select_placeholder_choose}
                  position
                />
              </Input>
              <Input className="my-4">
                <SubHeader>
                  <FormattedMessage id="common_systems" defaultMessage="Systems" />
                </SubHeader>
                <MultiSelect
                  showSearchAndAdd
                  name="systems"
                  options={systemsOptions}
                  value={systems}
                  onChange={this.handleSelectChange}
                  noEmptyError
                  placeholder={Constants.language.select_placeholder_choose}
                  position
                />
              </Input>
              <Input className="my-4">
                <SubHeader>
                  <FormattedMessage id="common_languages_spoken" defaultMessage="Language Spoken" />
                </SubHeader>
                <MultiSelect
                  showSearchAndAdd
                  name="languages"
                  options={languagesOptions}
                  value={languages}
                  onChange={this.handleSelectChange}
                  noEmptyError
                  placeholder={Constants.language.select_placeholder_choose}
                  position
                />
              </Input>
            </div>
          </>
        )}
        {!loading && cvfile && cvfile.name && (
        <WhiteCard className="row m-0 fixed-bottom py-4 px-3 position-sticky " border>
          <div className="col-lg col-md-12 p-0 text-left">
            <Button data-dismiss="modal" aria-label="Close" onClick={this.handleSubmitCV}>
              <FormattedMessage id="common_update" defaultMessage="Update" />
            </Button>
            <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose}>
              <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
            </CancelButton>
          </div>
          <DeleteButton className="col-lg-auto col-md-12 p-0">
            <WhiteButton
              onClick={this.handleRemoveDocument}
              className="px-4 mx-2 py-2 text-decoration-none color-primary"
            >
              <FormattedMessage id="common_delete_all" defaultMessage="Delete all" />
            </WhiteButton>
          </DeleteButton>
        </WhiteCard>
        )}
      </>
    );
  };

  render() {
    const { ModalClose, uploadCV } = this.props;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-filter-modal p-0"
          id="UploadCvModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-small m-auto my-lg-5" role="document" style={{ maxWidth: '776px' }}>
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0 ">
                <Card widthFixed="776px" className="p-0">
                  <WhiteCard className="row m-0 p-3 pb-0 pl-4 mb-0" border>
                    <Content className="col text-left">
                      <ProfileSubHeading className="m-0" head>
                        {uploadCV && <FormattedMessage id="common_upload_cv" defaultMessage="Upload CV" />}
                      </ProfileSubHeading>
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </WhiteCard>
                  {uploadCV && this.renderUploadCvBody()}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UploadCVModal;
