import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BaseComponent from '../BaseComponent';
import { Section, Button } from './styles';
import { Heading } from '../Header/styles';
import { P, Div } from '../Sidemenu/styles';

class UpdateProfilePopup extends BaseComponent {
  render() {
    const { updateProfilePopup } = this.props;
    return (
      <>
        {updateProfilePopup && (
          <Div
            className="modal fade fade-products"
            id="updateProfileModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modal"
            aria-hidden="true"
          >
            <Div className="modal-dialog m-auto top-50" role="document">
              <Div className="modal-content rounded border-0" customWidth>
                <Div className="modal-body rounded p-3">
                  <Section className="col">
                    <Section className="row d-flex justify-content-center text-center">
                      <Heading fontWeightBold fontSize className="mb-3">
                        <FormattedMessage
                          id="profile_complete_popup_heading"
                          defaultMessage=" Thank you for completing your profile "
                        />
                      </Heading>
                      <P className="text-center" Black fontSize maxWidth>
                        <FormattedMessage
                          id="profile_complete_popup_description"
                          defaultMessage="
                          We will now review the content and verify any training.
                          You will receive an email when your profile has been published. "
                        />
                      </P>
                    </Section>
                  </Section>
                  <Section className="row">
                    <Section className="col mt-3 d-flex justify-content-center">
                      <Button onClick={this.handleClick} data-dismiss="modal" aria-label="Close" buttonWidth>
                        <FormattedMessage id="common_ok" defaultMessage="Ok" />
                      </Button>
                    </Section>
                  </Section>
                </Div>
              </Div>
            </Div>
          </Div>
        )}
      </>
    );
  }
}

export default withRouter(UpdateProfilePopup);
