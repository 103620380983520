import ApiService from '../ApiService';
import Constants from '../../shared/constants';

class FilterPageService extends ApiService {
  endPoint = {
    addfilter: 'admin/filters/create',
    approvefilter: 'admin/filters/approve',
    removefilter: 'admin/filters/delete',
    filterList: 'admin/filters/list',
    categoryList: 'category/list',
    skillsList: 'skills/list',
    jobTitleList: 'jobtitle/list',
    systemsList: 'systems/list',
    createCategory: 'category/create',
    createSkills: 'skills/create',
    createJobTitle: 'jobtitle/create',
    createSystems: 'systems/create',
    approveCategory: 'category/approve',
    approveSkills: 'skills/approve',
    approveJobTitle: 'jobtitle/approve',
    approveSystems: 'systems/approve',
    deleteCategory: 'category/delete',
    deleteSkills: 'skills/delete',
    deleteJobTitle: 'jobtitle/delete',
    deleteSystems: 'systems/delete',
    updateSecondaryTitle: 'jobtitle/secondary-title',
  };

  async getSkillsList(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.skillsList + this.queryParamString(queryParam),
    );
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getSystemsList(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.systemsList + this.queryParamString(queryParam),
    );
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getJobtitlesList(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.jobTitleList + this.queryParamString(queryParam),
    );
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getCategoriesList(queryParam) {
    const response = await this.get(
      this.baseURL + this.endPoint.categoryList + this.queryParamString(queryParam),
    );
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createCategory(data) {
    const response = await this.post(this.baseURL + this.endPoint.createCategory, data);
    if (Constants.HTTP_STATUS.CREATED !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createSkills(data) {
    const response = await this.post(this.baseURL + this.endPoint.createSkills, data);
    if (Constants.HTTP_STATUS.CREATED !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createJobTitle(data) {
    const response = await this.post(this.baseURL + this.endPoint.createJobTitle, data);
    if (Constants.HTTP_STATUS.CREATED !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createSystems(data) {
    const response = await this.post(this.baseURL + this.endPoint.createSystems, data);
    if (Constants.HTTP_STATUS.CREATED !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveCategory(data) {
    const response = await this.post(this.baseURL + this.endPoint.approveCategory, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveSkills(data) {
    const response = await this.post(this.baseURL + this.endPoint.approveSkills, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveJobTitle(data) {
    const response = await this.post(this.baseURL + this.endPoint.approveJobTitle, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveSystems(data) {
    const response = await this.post(this.baseURL + this.endPoint.approveSystems, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async addFilter(data) {
    const response = await this.post(this.baseURL + this.endPoint.addfilter, data);
    if (Constants.HTTP_STATUS.CREATED !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveFilter(data) {
    const response = await this.post(this.baseURL + this.endPoint.approvefilter, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeSkill(data) {
    const response = await this.filterdelete(this.baseURL + this.endPoint.deleteSkills, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeCategory(data) {
    const response = await this.filterdelete(this.baseURL + this.endPoint.deleteCategory, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeJobtitle(data) {
    const response = await this.filterdelete(this.baseURL + this.endPoint.deleteJobTitle, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeSystems(data) {
    const response = await this.filterdelete(this.baseURL + this.endPoint.deleteSystems, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeFilter(data) {
    const response = await this.filterdelete(this.baseURL + this.endPoint.removefilter, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateSecondaryJobTitle(data) {
    const response = await this.put(this.baseURL + this.endPoint.updateSecondaryTitle, data);
    if (Constants.HTTP_STATUS.OK !== response.status) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}
export default FilterPageService;
