import moment from 'moment';
import Config from '../config';
import Constants from './constants';
import PersistentStorage from './PersistentStorage.ts';
import LanguagesOptionsInEng from './languages/en.json';
import LanguagesOptionsInSv from './languages/sv.json';
import CitiesInEng from './cities/en.json';
import CitiesInSv from './cities/sv.json';
import AuthService from '../services/AuthService';

/**
 * @function getUserDetails Collects the user details from local storage.
 * @returns loggedIn user details.
 */
export const getUserDetails = () => {
  const persistentStorage = new PersistentStorage();
  const user = JSON.parse(persistentStorage.getUserDetails());
  return user;
};

/**
 * @function getYearsOptions years in the form of label and value object is returned.
 * @param {number} startYear starting year
 * @param {number} n upto how many years
 * @returns years object
 */
export const getYearsOptions = (startYear, n = 0, continuing = false) => {
  const yearsOption = [];
  let currentYear;
  let year = startYear;
  if (year) {
    currentYear = new Date().getFullYear();
    if (n) {
      for (let i = year; i < startYear + n; i += 1) {
        yearsOption.push({ label: i, value: i });
      }
    } else {
      for (let i = year; i <= currentYear; i += 1) {
        yearsOption.push({ label: i, value: i });
      }
    }

    const response = yearsOption.reverse();
    if (continuing) {
      response.unshift({ label: Constants.ONGOING, value: Constants.ONGOING });
    }
    return response;
  }
  year = new Date().getFullYear();
  for (let i = 0; i < 10; i += 1) {
    yearsOption.push({ label: year + i, value: year + i });
  }

  for (let i = 1; i <= 10; i += 1) {
    yearsOption.push({ label: year - i, value: year - i });
  }

  return yearsOption.sort((a, b) => a.value - b.value);
};

/**
 * @function addZero Add's zero before the date.
 * @param {number} n Date
 * @returns date.
 */
export const addZero = (n) => {
  let i = n;
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
};

/**
 * @function searchQuery decodes the query
 */
export const searchQuery = () => {
  const s = window.location.search.substr(1);
  const p = s.split(/&/);
  let l = p.length;
  let kv;
  const r = {};
  if (l === 0) {
    return false;
  }
  while (l) {
    l -= 1;
    kv = p[l].split(/=/);
    r[kv[0]] = decodeURIComponent(kv[1] || '') || '';
  }
  return r;
};

/**
 * Returns the index of the substring present in input string
 * at postion specified
 *
 * @function
 * @param {string} string - Main string.
 * @param {string} subString - Sub string to be checked for a match.
 * @param {number} position - Start position to check for a match.
 * @returns {number} 0 based index of substring  match at the position specified.
 */
function getPosition(string, subString, position) {
  return string.split(subString, position).join(subString).length;
}

/**
 * Returns the URL prepended with language selected.
 *
 * @function
 * @param {string} language - language selected
 * @returns {string}  URL.
 */
export const getSiteMapUrl = (lang) => {
  const { origin, pathname } = window.location;
  const path = pathname.substring(getPosition(pathname, '/', 2) + 1);

  return `${origin}/${lang}/${path}`;
};

/**
 * Returns the current locale chosen from URL.
 *
 * @function
 * @returns {string}  locale in URL.
 */
export const getLocaleFromURL = () => Config.language;

/**
 * Returns active availability dates
 *
 * @function
 * @returns
 */
export const getActivedates = (n, activeDate, value = n) => {
  switch (activeDate.getMonth() + n) {
    case 12:
      return new Date(activeDate.getFullYear() + value, 0, 1);
    case -1:
      if (value !== n) {
        return new Date(activeDate.getFullYear() - value, 11, 1);
      }
      return new Date(activeDate.getFullYear() + value, 11, 1);

    default:
      return new Date(activeDate.getFullYear(), activeDate.getMonth() + n, 1);
  }
};
/**
   * Returns the current locale from url.
   * @function getCurrentLocaleFile
   * @returns {string}
 */

const currentLanguage = getLocaleFromURL();

const language = currentLanguage
  ? currentLanguage === 'en'
    ? require('../translations/en.json')
    : require('../translations/sv.json')
  : require('../translations/sv.json');

export const getCurrentLocaleFile = () => language;

/**
 * @function getDocsInArray Coverts document object to array.
 * @param {object} docs documents
 * @returns documents.
 */
export const getDocsInArray = (docs) => {
  const documents = [];
  const { cv = '', personal_letter: personalLetter = '', other_docs: otherDocs = [] } = docs;
  if (cv) {
    documents.push(cv);
  }
  if (personalLetter) {
    documents.push(personalLetter);
  }
  if (otherDocs && otherDocs.length > 0) {
    otherDocs.map((obj) => documents.push(obj));
  }
  return documents;
};

/**

   * Returns formatted csv data.

   * @function getFormattedCSVData

   * @returns {array}

 */

const renameKey = (obj, oldKey, newKey) => {
  // eslint-disable-next-line no-param-reassign
  obj[newKey] = obj[oldKey];
  // eslint-disable-next-line no-param-reassign
  delete obj[oldKey];
  // let item = Object.assign({}, obj); // decouple instance
  // item[newKey] = item[oldKey]; // assign a property
  // return item[newKey]; // replace original with new instance
  // const renamedObj = { ...obj, [newKey]: obj[oldKey] };
  // delete renamedObj[oldKey];
};

export const getFormattedCSVData = (data = []) => {
  const getKeys = data
    .map((key) => Object.keys(key))
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index);
  getKeys.map(
    (key) => data.forEach(
      (obj) => {
        renameKey(obj, key, Constants.language[`common_${key}`]);
      },
    ),
  );
  return data;
};

/**
 * Add's hyphen after specified digits in the number.
 * @param {Number} number
 */
export const addHyphenToNumber = (number, position) => {
  let value = number || '';
  const regEx = new RegExp(`(\\d{${position}})`, 'g');
  value = value.replace(/([^0-9 -\s])/gm, '').replace(/\s+/g, '');
  value = value && value.replace(/([^0-9 -\s])/gm, '').replace(/\s+/g, '');
  if (value && (value.length > (position + 1) || value.length < (position + 1))) {
    value = value.replace(/-/g, '');
  }
  if (value && value.length > position && value.indexOf('-') !== position) {
    value = value.replace(regEx, '$1-');
  }
  return value;
};

/**
 * @function parseData Parsing of data happens between the options and values.
 * if any value is not present in options a new option with the value is added to existing options.
 * @param {object} option options
 * @param {string} value values
 * @returns Options and values
 */
export const parseData = (option, value) => {
  let options = option;
  let values = value;
  if (typeof values !== 'object') values = values && values.split(', ');
  if (values) {
    values.map((data) => {
      if (data.status === Constants.status.PENDING) {
        options.push({
          ...data, isSelected: true, label: data.name, value: data._id,
        });
      } else if (data.status === Constants.status.APPROVED) {
        options = options.map((obj) => {
          if (obj.value === data._id) {
            return { ...obj, isSelected: true, status: Constants.status.APPROVED };
          }
          return { ...obj, status: Constants.status.APPROVED };
        });
      }
      return data;
    });
  }

  values = values && values.reduce((prev, curr) => `${prev + curr.name}, `, '').slice(0, -2);

  return { options, values };
};

/**
 * @function parseAndTranslateData Parses and translates the options and values
 * @param {object} option options
 * @param {string} value Values
 * @returns An object which is parsed and translated.
 */
export const parseAndTranslateData = (option, value = []) => {
  let options = option;
  let values = value;

  if (typeof values !== 'object') values = values && values.split(', ');
  const translatedData = [];
  if (values) {
    options = options.map((obj) => {
      const opt = obj;
      values.forEach((lang) => {
        if (opt.value === lang) {
          opt.isSelected = true;
          translatedData.push(opt.label);
        }
      });
      return opt;
    });
    values = translatedData.join(', ');
  }
  return { options, values };
};

/**
 * @function getLanguages Translates the language option as per the language set in localstorage.
 * @returns languages
 */
export const getLanguages = () => {
  const langOptions = currentLanguage === Constants.english
    ? Object.entries(LanguagesOptionsInEng).map((o) => (
      { label: o[1], value: o[0], isSelected: false }))
    : Object.entries(LanguagesOptionsInSv).map((o) => (
      { label: o[1], value: o[0], isSelected: false }));
  return langOptions;
};

export const getCities = () => {
  const cityOptions = currentLanguage === Constants.english
    ? Object.entries(CitiesInEng).map((o) => (
      { label: o[1], value: o[0], isSelected: false }))
    : Object.entries(CitiesInSv).map((o) => (
      { label: o[1], value: o[0], isSelected: false }));
  return cityOptions;
};

export const getWorkAvailabilityOptions = () => [
  { label: Constants.language.common_full_time, value: 'full_time', isSelected: false },
  { label: Constants.language.common_part_time, value: 'part_time', isSelected: false },
];

const authService = new AuthService(Config.apiBase);

export const isLoggedIn = () => authService.isAuthenticated();

export const isAdmin = () => authService.isAdminUser();

export const isGigger = () => authService.isGiggerUser();

export const isCustomer = () => authService.isCustomerUser();

export const isCvVerificationUser = () => authService.isCvVerificationUser();

export const formatDate = (date, format) => moment(date).format(format);
