import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Header from '../../../components/Header/Header';
import Button from '../../../components/Button/Button';
import Constants from '../../../shared/constants';
import { CompanyListWrapper, ButtonWrapper, Line } from './styles';
import { IconWrapper, Icon } from '../giggers/styles';
import CompanyService from '../../../services/AdminServices/CompanyService';
import AuthService from '../../../services/AuthService';
import Config from '../../../config';
import {
  FlexContainer,
  Container,
  Border,
  TableHeader,
  TableValue,
  TableData,
  Wrapper,
} from '../users/styles';
import LoadingCard from '../LoadingCard';

import { WhiteCard } from '../../gigger/styles';
import Pagination from '../../../components/Pagination/Pagination';
import SearchInput from '../../../components/Input/SearchInput';
import ChangeLanguageModal from '../../../components/Modals/index';
import { getFormattedCSVData } from '../../../shared/utils';

class Company extends Component {
  companyService = new CompanyService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      meta: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: 1,
        pageSize: 15,
      },
      searchbar: true,
      searchQuery: '',
      loggedIn: this.authService.isAuthenticated(),
      companyData: [],
      loading: false,
      csvDownloadData: [],
      isLangModalOpen: false,
      csvExportFileds: [
        'full_name',
        'personal_number',
        'connected_users',
        'service_bought',
        'hired_giggers',
        'created_at',
        'status',
      ],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchCompanies();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        pageNumber,
      },
    } = this.state;
    const { location } = this.props;
    if (prevState.meta.pageNumber !== pageNumber
      || location.key !== prevProps.location.key) {
      this.fetchCompanies();
    }
  }

  /**
   * Handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber, pageSize: 15 },
    }), this.fetchCompanies);
  };

  /**
   * Handles search functionality
   * @param {String} value search text
   */
  handlesearchCompanies=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchCompanies(value);
    });
  }

  /**
   * Handles change in search value
   * @param {Event} event
   * @listens Event
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Downloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.companyService.getCompanyList(queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.companyCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Displays search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * OPens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   * Closes modal
   */
  handleModalClose = () => {
    this.setState({
      isLangModalOpen: false,
    });
  };

  /**
   * @async
   * @function fetchCompanies Fetches companies
   * @param {String} searchInput search text
   */
  async fetchCompanies(searchInput) {
    const {
      meta: {
        pageNumber, pageSize,
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    try {
      const response = await this.companyService.getCompanyList(queryParams);
      const {
        current_page: currentPage, per_page: perPage, last_page: pages, total: totalCount,
      } = response.meta;
      const metaData = {
        pageNumber: currentPage,
        pageSize: perPage,
        pages,
        totalCount,
      };

      this.setState({ companyData: response.data, meta: metaData, loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      csvDownloadData,
      meta,
      searchbar,
      companyData,
      loading,
      loggedIn,
      searchQuery,
      isLangModalOpen,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <CSVLink data={csvDownloadData} filename="company.csv" className="d-none" ref={(r) => { this.companyCsv = r; }}>
          click
        </CSVLink>
        <Header
          name={<FormattedMessage id="common_company" defaultMessage="Company" />}
          listScreen
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
        />
        <FlexContainer>
          <Container>
            <CompanyListWrapper>
              <IconWrapper className="row m-0">
                {searchbar ? (
                  <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
                ) : (
                  <div className="text-end col-auto p-0">
                    <SearchInput
                      onSearch={this.handlesearchCompanies}
                      onChange={this.handleSearchOnChange}
                      value={searchQuery}
                    />
                  </div>
                )}
                <Icon
                  src={Constants.icons.ExportIcon}
                  alt="icon"
                  className={companyData && companyData.length > 0 ? 'mr-3' : 'disableOption mr-3'}
                  onClick={this.handleDownloadCsv}
                />
              </IconWrapper>
              {loading && (
                <CompanyListWrapper>
                  <LoadingCard />
                </CompanyListWrapper>
              )}
              {!loading && (
                <>
                  <Wrapper className="row m-0">
                    <TableData className="col ">
                      <TableHeader>
                        <FormattedMessage id="common_company" defaultMessage="Company" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="common_created" defaultMessage="Created" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-end">
                      <TableHeader>
                        <FormattedMessage id="admin_company_connected_users" defaultMessage="Connected users" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-end">
                      <TableHeader>
                        <FormattedMessage id="admin_company_service_bought" defaultMessage="Service bought" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-end">
                      <TableHeader>
                        <FormattedMessage id="admin_company_hired_giggers" defaultMessage="Hired giggers" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-end">
                      <TableHeader>
                        <FormattedMessage id="common_id" defaultMessage="ID" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col text-align">
                      <ButtonWrapper>
                        <TableHeader>
                          <FormattedMessage id="common_status" defaultMessage="Status" />
                        </TableHeader>
                      </ButtonWrapper>
                    </TableData>
                  </Wrapper>
                  {companyData.length > 0 ? (
                    <>
                      {companyData.map((data) => (
                        <>
                          <Border />
                          <Wrapper className="row m-0">
                            <TableData className="col text-truncate" title={data.company_name}>
                              {data.company_name ? (
                                <Link
                                  to={{
                                    pathname: `/admin/portal/company/${data._id}/profile`,
                                    query: { id: data._id },
                                  }}
                                >
                                  <Line>
                                    <TableValue>{data.company_name}</TableValue>
                                  </Line>
                                </Link>
                              ) : (
                                <TableValue>-</TableValue>
                              )}
                            </TableData>

                            <TableData className="col text-truncate" title={data.created}>
                              <TableValue>{moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                            </TableData>

                            <TableData className="col text-end text-truncate" title={data.connected_users}>
                              <TableValue>{data.connected_users}</TableValue>
                            </TableData>

                            <TableData className="col text-end text-truncate" title={data.service_bought}>
                              <TableValue>{data.service_bought}</TableValue>
                            </TableData>

                            <TableData className="col text-end text-truncate" title={data.hired_giggers}>
                              <TableValue>{data.hired_giggers}</TableValue>
                            </TableData>

                            <TableData className="col text-end text-truncate" title={data.personal_number}>
                              <TableValue>{data.personal_number}</TableValue>
                            </TableData>

                            <TableData className="col text-align">
                              <TableValue>
                                <ButtonWrapper>
                                  {data.status === 'pending' ? (
                                    <Button
                                      name={<FormattedMessage id="common_pending" defaultMessage="Pending" />}
                                      type="open"
                                    />
                                  ) : (
                                    <Button
                                      name={<FormattedMessage id="common_active" defaultMessage="Active" />}
                                      type="approve"
                                    />
                                  )}
                                </ButtonWrapper>
                              </TableValue>
                            </TableData>
                          </Wrapper>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Border />
                      <WhiteCard className="my-3">
                        {searchQuery && searchQuery.length > 0 ? (
                          <FormattedMessage
                            id="common_no_search_results_found"
                            defaultMessage="No search results found"
                          />
                        ) : (
                          <FormattedMessage id="common_no_company_found" defaultMessage="No company found" />
                        )}
                      </WhiteCard>
                    </>
                  )}
                </>
              )}
            </CompanyListWrapper>
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#AdminModal"
              ref={(openLangModal) => { this.openLangElement = openLangModal; }}
            />
            {isLangModalOpen && (
              <ChangeLanguageModal
                changeLanguage={isLangModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {!loading && meta.pages > 1 && (
              <CompanyListWrapper style={{ 'background-color': 'transparent' }}>
                <Pagination cursor={meta} gotoPage={this.handleGotoPage} />
              </CompanyListWrapper>
            )}
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default withRouter(Company);
