import React from 'react';
import { CheckBox, CheckBoxWrapper, CheckboxLabel } from './styles';
import { TickIcon } from '../../styles/Icons';

const CustomCheckBox = ({
  isSelected, onClick, label, size = 20, type, bgcolor,
}) => (
  <CheckBoxWrapper onClick={onClick} type={type}>
    <CheckBox checked={isSelected} size={size} bgcolor={bgcolor}>
      <TickIcon size={size - 2} fill="#fff" />
    </CheckBox>
    <CheckboxLabel>{label}</CheckboxLabel>
  </CheckBoxWrapper>
);

export default CustomCheckBox;
