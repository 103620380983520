import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer, Container, SectionLeft, DisplayCards, SectionRight, Header, Div,
} from './styles';
import Card from '../../components/Card/Cards';
import { ButtonsWrapper, HeadingWrapper } from '../gigger/styles';
import Constants from '../../shared/constants';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import Button from '../../components/Button/Button';
import Search from '../../components/RedirectLinks/Search';
import Faq from '../../components/RedirectLinks/Faq';
import ContactUs from '../../components/RedirectLinks/ContactUs';
import Colors from '../../styles/Colors';

class MyAccounts extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      loading: false,
      role: '',
      loggedIn: this.authService.isAuthenticated(),
      status: Constants.status.PENDING,
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchMyAccounts();
    window.scrollTo(0, 0);
  }

  /**
   * Redirects to orders page
   */
  handleOrders = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerOrders.url);
  };

  /**
   * Redirects to my giggers page
   */
  handleMyGiggers = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyGiggers.url);
  };

  /**
   * Redirects to user details page
   */
  handleUserDetails = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerUserDetails.url);
  };

  /**
   * Redirects to company details page
   */
  handleCompanyDetails = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerCompanyDetails.url);
  };

  /**
   * Redirects to manage users page
   */
  handleManageUsers = () => {
    const { history } = this.props;
    history.push(Constants.routes.manageUsers.url);
  };

  /**
   * Logout the account
   */
  handleLogOut = async () => {
    const { history } = this.props;
    try {
      const response = await this.customerService.logOut();
      if (response) {
        localStorage.clear();
        history.push({ pathname: Constants.routes.logIn.url });
      }
    } catch (e) {
      // TODO: handle error
    }
    localStorage.clear();
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * Redirects to search giggers page
   */
  handleSearchGiggers = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerList.url);
  };

  /**
   * @async
   * @function fetchMyAccounts Fetches account details of loggedin user
   */
  async fetchMyAccounts() {
    this.setState({ loading: true });
    try {
      const response = await this.customerService.getDetails();
      const { data = {} } = response;

      this.setState({
        name: data.full_name,
        role: data.role,
        loading: false,
        status: data.status,
      });
    } catch (e) {
      // TODO: handle error
      let role = '';
      if (localStorage.getItem('userData')) {
        role = JSON.parse(localStorage.getItem('userData')).role;
      }
      this.setState({ loading: false, role });
    }
  }

  render() {
    const {
      name, loading, loggedIn, role, status,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    if (role === Constants.role.GIGGER) {
      return <Redirect to={Constants.routes.giggerMyAccount.url} />;
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <HeadingWrapper className="justify-content-between row m-0 pr-3">
              {loading ? (
                <Loader width="200px" height="32px" marginTop="24px" />
              ) : (
                <>
                  <div className="p-0">
                    <Header>
                      <FormattedMessage id="common_hi" defaultMessage="Hi" />
                      {' '}
                      {name}
                      ,
                    </Header>
                  </div>
                  <div className="p-0 mr-lg-0 pr-lg-0">
                    <ButtonsWrapper>
                      <Button
                        id="search"
                        type="update"
                        name={<FormattedMessage id="company_search_giggers" defaultMessage="Search giggers" />}
                        onClick={this.handleSearchGiggers}
                      />
                    </ButtonsWrapper>
                  </div>
                </>
              )}
            </HeadingWrapper>
            <DisplayCards className="row m-0 pr-3">
              <SectionLeft className="col-5 p-0">
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="common_my_giggers" defaultMessage="My giggers" />}
                    description={(
                      <FormattedMessage
                        id="company_my_giggers_card_description"
                        defaultMessage="Saved and purchased contacts"
                      />
                    )}
                    onClick={this.handleMyGiggers}
                  />
                )}
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="company_my_account_order_history" defaultMessage="Order history" />}
                    description={(
                      <FormattedMessage
                        id="customer_see_previous_orders "
                        defaultMessage="See all your previous orders"
                      />
                    )}
                    onClick={this.handleOrders}
                  />
                )}
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="common_user_details" defaultMessage="User details" />}
                    description={(
                      <FormattedMessage
                        id="company_user_details_card_description"
                        defaultMessage="Edit user details, user settings, delete account"
                      />
                    )}
                    onClick={this.handleUserDetails}
                  />
                )}

                {role === Constants.role.COMPANY && status !== Constants.status.REJECTED && (
                  <>
                    {loading ? (
                      <Loader width="340px" height="52px" marginTop="24px" />
                    ) : (
                      <Card
                        className={status === Constants.status.PENDING ? 'opacity-5' : ''}
                        header={<FormattedMessage id="company_manage_users" defaultMessage="Manage users" />}
                        description={(
                          <FormattedMessage
                            id="company_view_and_invite_new_colleagues_message"
                            defaultMessage="Edit user settings and invite new colleagues"
                          />
                        )}
                        onClick={status === Constants.status.PENDING ? '' : this.handleManageUsers}
                      />
                    )}
                  </>
                )}
              </SectionLeft>
              {loading ? (
                <Loader width="340px" height="72px" marginTop="24px" />
              ) : (
                <SectionRight className="col-6 p-0 mb-3">
                  <Card
                    reminder
                    pinkCard
                    removeDeleteButton
                    header={(
                      <>
                        {status === Constants.status.APPROVED
                          ? (
                            `${Constants.language.common_hi} ${name}!`
                          )
                          : (
                            <Div className="pb-4">
                              <FormattedMessage
                                id="common_welcome_message"
                                defaultMessage="Welcome to GigAssembly"
                              />
                            </Div>
                          )}
                      </>
                    )}
                    description={
                        status === Constants.status.PENDING ? (
                          <FormattedMessage
                            id="customer_welcome_message_pending_status"
                            defaultMessage="Because you have registered without BankID, a manual verification of your account is performed. Should we have any questions, we will contact you, otherwise you will receive an email when your account has been approved. You can already update your information and get acquainted with the platform's various {searchLink} functions."
                            values={{
                              searchLink: <Search color={Colors.Primary} />,
                              faqLink: <Faq color={Colors.Primary} />,
                              contactLink: <ContactUs color={Colors.Primary} />,
                            }}
                          />
                        ) : (
                          <>
                            <Div className="mt-3">
                              <FormattedMessage
                                id="customer_welcome_message"
                                defaultMessage="You can now {searchLink} for professional and quality-assured gigs within GigAssembly's wide network. If you have any questions, please look at the {faqLink}, or {contactLink} at GigAssembly."
                                values={{
                                  searchLink: <Search color={Colors.Primary} />,
                                  faqLink: <Faq color={Colors.Primary} />,
                                  contactLink: <ContactUs color={Colors.Primary} />,
                                  break: <br />,
                                }}
                              />
                            </Div>
                            <ul className="pl-4 mb-0">
                              <li><FormattedMessage id="customer_welcome_message_list_one" defaultMessage="Full profile and contact information: SEK 100 / consultant" /></li>
                              <li><FormattedMessage id="customer_welcome_message_list_two" defaultMessage="One-time cost per hired consultant: SEK 25,000 / assignment" /></li>
                            </ul>
                            <FormattedMessage
                              id="for_assignment_message"
                              defaultMessage="for assignments up to 3 months, contact us for a reduced price"
                              values={{
                                break: <br />,
                              }}
                            />
                            <ul className="pl-4">
                              <li><FormattedMessage id="customer_welcome_message_list_three" defaultMessage="Competence-based interviews: SEK 30,000" /></li>
                              <li><FormattedMessage id="customer_welcome_message_list_four" defaultMessage="Reference: SEK 5,000" /></li>
                            </ul>
                            <FormattedMessage
                              id="customer_contact_message"
                              defaultMessage="for assignments up to 3 months, contact us for a reduced price"
                              values={{
                                break: <br />,
                              }}
                            />
                          </>
                        )
}
                  />
                </SectionRight>
              )}
            </DisplayCards>
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default MyAccounts;
