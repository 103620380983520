import Constants from '../shared/constants';
/**
 * Handles Profile serialization.
 */
class GiggerProfile {
  constructor(obj) {
    this.firstName = obj.firstName;
    this.lastName = obj.lastName;
    this.profilePic = obj.profilePic;
    this.phone = obj.phone;
    this.id = obj.id;
    this.jobTitle = obj.jobTitle;
    this.category = obj.category;
    this.summary = obj.summary;
    this.skills = obj.skills;
    this.systems = obj.systems;
    this.languages = obj.languages;
    this.yearsOfExperience = obj.yearsOfExperience;
    this.hourlyRate = obj.hourlyRate;
    this.previousJobs = obj.previousJobs;
    this.education = obj.education;
    this.workAvailabilityDate = obj.workAvailabilityDate;
    this.profileViews = obj.profileViews;
    this.characterCount = obj.characterCount;
    this.workAvailability = obj.workAvailability;
    this.cvDocs = obj.cvDocs;
    this.personalDocs = obj.personalDocs;
    this.otherDocs = obj.otherDocs;
    this.docs = obj.docs;
    this.desiredCities = obj.desiredCities;
    this.review = obj.review;
    this.documents = obj.documents;
    this.fullName = obj.fullName;
    this.email = obj.email;
    this.label = obj.label;
    this.status = obj.status;
  }

  /**
   * Method to parse the gigger Details response.
   *
   * @method
   * @static
   * @param {Object} obj GiggerData object.
   * @return {Object}
   */
  static fromJson(obj) {
    const { docs = [] } = obj;
    const cvDocs = docs.filter((cvDoc) => cvDoc.category === Constants.docsCategory[0]);
    const personalDocs = docs.filter((personalDoc) => personalDoc.category
    === Constants.docsCategory[1]);
    const otherDocs = docs.filter((otherDoc) => otherDoc.category
    === Constants.docsCategory[2]);

    if (!otherDocs.length) otherDocs.push({ category: Constants.docsCategory[2] });
    const data = {
      firstName: obj.first_name || '',
      lastName: obj.last_name || '',
      profilePic: obj.profile_pic || {},
      phone: obj.phone || '',
      id: obj.id || '',
      jobTitle: obj.job_title || '',
      category: obj.category || '',
      summary: obj.summary || '',
      skills: obj.skills || [],
      systems: obj.systems || [],
      languages: obj.language_spoken || '',
      yearsOfExperience: obj.year_of_experience || '',
      hourlyRate: obj.hourly_rate || '',
      previousJobs: obj.previous_jobs || [],
      education: obj.education || [],
      workAvailabilityDate: obj.work_availability_date || [{ from: '', to: '' }],
      profileViews: obj.profile_views || 0,
      characterCount: obj.summary ? obj.summary.length : 0,
      workAvailability: obj.work_availability || '',
      cvDocs: cvDocs || [],
      documents: obj.docs || [],
      fullName: obj.full_name || '',
      email: obj.email || '',
      personalDocs: personalDocs || [],
      otherDocs: otherDocs || [{ category: 'OtherDocs' }],
      docs: obj.docs.map((o) => o._id) || {},
      desiredCities: obj.desired_cities && obj.desired_cities.length ? obj.desired_cities : '',
      review: obj.review || false,
      label: obj.profileLabel || '',
      status: obj.status,
    };
    return new GiggerProfile(data);
  }

  /**
   * Method to parse the gigger Details response.
   *
   * @method
   * @static
   * @param {Object} obj GiggerData object.
   * @return {Object}
   */
  static fromAdminProfileJson(obj) {
    const {
      accounts = {},
      documents: docs = [],
      first_name: firstName = '',
      last_name: lastName = '',
      full_name: fullName = '',
      profile_picture: profilePic = {},
      job_titles: jobTitles = [],
      category = [],
      profile_views: profileViews = 0,
      status = '',
      review = false,
      email = '',
      _id: id = '',
    } = obj;
    const {
      phone = '',
      summary = '',
      skills = [],
      systems = [],
      label = '',
      language_spoken: languages = '',
      year_of_experience: yearsOfExperience = '',
      hourly_rate: hourlyRate = '',
      previous_jobs: previousJobs = [],
      education = [],
      desired_cities: desiredCities = '',
      work_availability: workAvailability = '',
      work_availability_date: workAvailabilityDate = [{ from: '', to: '' }],
    } = accounts;
    const cvDocs = docs.filter((cvDoc) => cvDoc.category === Constants.docsCategory[0]);
    const personalDocs = docs.filter((personalDoc) => personalDoc.category
    === Constants.docsCategory[1]);
    const otherDocs = docs.filter((otherDoc) => otherDoc.category
    === Constants.docsCategory[2]);

    if (!otherDocs.length) otherDocs.push({ category: Constants.docsCategory[2] });
    const data = {
      firstName: firstName || '',
      lastName: lastName || '',
      fullName: fullName || '',
      profilePic: profilePic || {},
      phone: phone || '',
      id: id || '',
      jobTitle: jobTitles[0] || '',
      category: category[0] || '',
      summary: summary || '',
      skills: skills || [],
      systems: systems || [],
      languages: languages || '',
      documents: docs || [],
      yearsOfExperience: yearsOfExperience || '',
      hourlyRate: hourlyRate || '',
      previousJobs: previousJobs || [],
      education: education || [],
      workAvailabilityDate: workAvailabilityDate || [{ from: '', to: '' }],
      profileViews: profileViews || 0,
      characterCount: summary ? summary.length : 0,
      workAvailability: workAvailability || '',
      cvDocs: cvDocs || [],
      email: email || '',
      personalDocs: personalDocs || [],
      otherDocs: otherDocs || [{ category: 'OtherDocs' }],
      docs: docs.map((o) => o._id) || {},
      desiredCities: desiredCities || '',
      review: review || false,
      label: label || '',
      status: status || '',
    };
    return new GiggerProfile(data);
  }

  /**
   * Returns the gigger Details payload of JSON type.
   *
   * @method
   */
  toJson=(obj, admin) => {
    const data = {
      profile_pic: obj.profilePic,
      id: obj.id,
      phone: obj.phone,
      first_name: obj.firstName,
      last_name: obj.lastName,
      job_title: obj.jobTitle,
      category: obj.category,
      summary: obj.summary,
      skills: obj.skills,
      systems: obj.systems,
      language_spoken: obj.languages,
      year_of_experience: obj.yearsOfExperience,
      hourly_rate: obj.hourlyRate,
      previous_jobs: obj.previousJobs,
      education: obj.education,
      docs: obj.docs,
      work_availability: obj.workAvailability,
      desired_cities: obj.desiredCities,
      work_availability_date: obj.workAvailabilityDate,
      status: obj.status,
    };
    if (admin) {
      data.review = obj.review;
      data.email = obj.email;
    }
    return data;
  }
}
export default GiggerProfile;
