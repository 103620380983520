import React, { Component } from 'react';
import validator from 'validator';
import Luhn from 'luhn';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import { CustomerSignUpImage } from '../../styles/Images';
import TextBox from '../../components/Input/TextBox';
import Button from '../../components/Button/Button';
import CustomCheckBox from '../../components/Input/CustomCheckbox';
import Modal from '../../components/Card/Modal';
import PasswordBox from '../../components/Input/PasswordBox';
import { addHyphenToNumber } from '../../shared/utils';
import {
  FlexContainer,
  LoginWrapper,
  Instruction,
  Description,
  Header,
  SubHeader,
  // SubTitle,
  Input,
  Content,
  TermsandConditions,
  Wrapper,
  Line,
  Link,
  LoginLink,
  AgreeTerms,
  SignInButton,
  Error,
  ButtonWrapper,
  ImageWrapper,
} from './styles';
import SignUpService from '../../services/SignUpService';
import Config from '../../config';
import Company from '../../models/Company';
import Loader from '../../components/Loader/Loader';
import PrivacyPolicy from '../../components/RedirectLinks/PrivacyPolicy';
import TermsAndConditionsLink from '../../components/RedirectLinks/TermsAndConditions';
import { SectionLeft, SectionRight } from '../login/styles';

class CustomerSignUp extends Component {
  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      withoutBankID: false,
      isDisabled: true,
      emailError: false,
      phoneNumError: false,
      orgNumber: '',
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      ismodalopen: false,
      repeatPassword: '',
      formValid: {
        email_isValid: false,
        phoneNumber_isValid: false,
        orgNumber_isValid: false,
        company_isValid: false,
        password_isValid: false,
        repeatPassword_isValid: false,
        firstName_isValid: false,
        lastName_isValid: false,
        agreeTerms_isValid: false,
      },
      isSelected: false,
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
      loading: false,
    };
  }

  /**
   * Removes localstorage when redirected from wp
   */
  componentDidMount() {
    const { location: { pathname, data } } = this.props;
    if (pathname === Constants.url.customer) this.handleWithoutBankID();
    if (data) {
      const { formValid } = this.state;
      formValid.orgNumber_isValid = (data.orgNumber.length === Constants.OrganisationNumberLength);
      this.setState({
        orgNumber: data.orgNumber || '',
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        companyName: data.companyName || '',
        email: data.email || '',
        phone: data.phone || '',
        password: data.password || '',
        repeatPassword: data.repeatPassword || '',
        withoutBankID: data.withoutBankID || false,
        formValid,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;

    const { location: { pathname: prevPathname } } = prevProps;

    if (pathname !== prevPathname) {
      this.handleWithoutBankID();
    }
  }

  /**
   * Redirects to SignUp without BankId screen
   */
  handleRedirectToWithoutBankIdPage=() => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.customerSignUpWithOutBankId.url });
  };

  /**
   * Redirects to without bankId page
   */
  handleWithoutBankID = () => {
    const { withoutBankID } = this.state;
    this.setState({
      withoutBankID: !withoutBankID,
      isDisabled: true,
    });
    window.scrollTo(0, 0);
  };

  /**
   * Handles change in value
   * @param {*} event
   */

  handleChange = (event) => {
    const { name = '', value = '' } = event.target;
    let { phone } = this.state;

    if (name === Constants.phone
      && validator.matches(value.toString(), Constants.phoneNumberRegexPattern)) {
      phone = value;
    }

    this.setState({ [name]: value, phone }, this.validate);
  };

  /**
   * Handles change in Password
   * @param {*} event
   */

  handlePasswordChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      showRequirementsDoesntMatch: false,
    },
    this.validate);
  };

  /**
   * Handles change in repeat password
   * @param {*} event
   */

  handleRepeatPasswordChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      showPwdDoesntMatch: false,
    },
    this.validate);
  };

  /**
   * Validates Password as per its requirements
   * @example Test@123
   * @param {*} event
   */

  handlePasswordBlur = () => {
    let { RequirementsDoesntMatch } = false;
    const { password } = this.state;
    if (!validator.isEmpty(password)) {
      // eslint-disable-next-line global-require
      const taiPasswordStrength = require('tai-password-strength');
      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(password);
      RequirementsDoesntMatch = validation
        && validation.charsets
        && (validation.charsets.symbol || validation.charsets.punctuation)
        && validation.charsets.lower
        && validation.charsets.upper
        && validation.charsets.number
        && validation.passwordLength >= 8;
      this.setState({ showRequirementsDoesntMatch: !RequirementsDoesntMatch });
    }
  };

  /**
   * Confirms Password and repeat password are same or not
   *
   */
  handleRepeatPwdBlur = () => {
    let { DoesntMatch } = false;
    const { password, repeatPassword } = this.state;
    if (!validator.isEmpty(password) && !validator.isEmpty(repeatPassword)) {
      DoesntMatch = password === repeatPassword;
      this.setState({ showPwdDoesntMatch: !DoesntMatch });
    }
  };
  /**
   * Redirects to Gigger SignUp page
   */

  handleSignUpgigger = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.giggerSignUp.url });
  };

  /**
   * Redirects to login page
   */

  handleLogin = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * Check the box if ready to agree terms and conditions
   */
  handleAgreeTermsCheckbox = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected }, this.validate);
  };

  /**
   *
   * Popup comes if coperate ID already exists
   */
  handleEdit = () => {
    this.setState({ ismodalopen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes popup
   */

  handleCustomerModalClose = () => {
    this.setState({ ismodalopen: false });
  };

  /**
   * Creates Customer account
   */
  handleCompanySignUp = async () => {
    const {
      orgNumber,
      firstName,
      lastName,
      email,
      phone,
      password,
      repeatPassword,
      withoutBankID,
      companyName,
    } = this.state;
    this.setState({ loading: true, emailError: false, phoneNumError: false });
    const data = {
      orgNumber,
      firstName,
      lastName,
      companyName,
      email: email.toLowerCase(),
      phone,
      password,
      repeatPassword,
      withoutBankID,
    };
    const { history } = this.props;
    this.company = new Company({});
    try {
      const response = await this.signUpService.customerSignUpService(this.company.toJson(data));
      if (withoutBankID) {
        history.push({
          pathname: Constants.routes.customerMyAccount.url,
          data: response.data.user,
        });
      } else {
        const bankidData = { orderRef: response.success.orderRef };
        history.push({
          pathname: Constants.routes.bankIdAuthendicate.url,
          bankidData,
          data,
          redirectTo: 'Customer',
        });
      }
      if (response.data && response.data.error.organization_number) {
        this.setState({ loading: false }, this.handleEdit);
        this.handleEdit();
      }
    } catch (error) {
      const { response = {} } = error;
      const { data: errorData = {} } = response;
      const { errors: inputError = {} } = errorData;
      if (inputError && inputError.organization_number) {
        this.setState({ loading: false, isDisabled: true }, this.handleEdit);
        this.handleEdit();
      }
      if (inputError && inputError.email) {
        this.setState({ loading: false, emailError: true, isDisabled: true });
      }
      if (inputError && inputError.phone) {
        this.setState({ loading: false, phoneNumError: true, isDisabled: true });
      }
    }
  };

  /**
   * Handles change in value if error in Coperate ID,emailr
   * @param {*} event
   */

  handleErrorChange = (event) => {
    let { emailError, phoneNumError } = this.state;
    const { name, value } = event.target;
    if (name === Constants.email) {
      emailError = false;
    }
    if (name === Constants.phone) {
      phoneNumError = false;
    }
    this.setState({ [name]: value, emailError, phoneNumError }, this.validate);
  };

  /**
   * Restricts alphabets in Organisation number
   * @param {*} evt
   */

  handleKeyPress = (evt) => {
    const theEvent = evt || window.event;
    // let key;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    // Handle paste

    // if (theEvent.type === 'paste') {
    //   key = evt.clipboardData.getData('text/plain');
    // } else {
    //   // Handle key press
    //   key = theEvent.keyCode || theEvent.which;
    //   key = String.fromCharCode(key);
    // }
    // const regex = /[0-9 -]|\./;
    // if (!regex.test(key)) {
    //   theEvent.returnValue = false;
    //   if (theEvent.preventDefault) theEvent.preventDefault();
    // }

    if (
      name === Constants.organisationNumber
      && ((value.replace(/-/g, '')).length) >= Constants.OrganisationNumberLength - 1
    ) {
      theEvent.preventDefault();
      this.setState({ [name]: addHyphenToNumber(value, 6) }, this.validate);
    }

    if (name === Constants.phone && !validator.matches(value.toString(),
      Constants.phoneNumberRegexPattern)) {
      theEvent.preventDefault();
      this.setState({ [name]: value }, this.validate);
    }
  };

  /**
   * Validates all the input fields present in the form

   * @constant orgNumber {number} Corporate ID number of the User
     @constant companyName {string} Company Name of the User
     @constant firstName {string} First Name of the User
     @constant lastName {string} Last Name of the User
     @constant email {email} E-mail ID of the User
     @constant phone {string} Phone number of the User
     @constant password {password} Password of the User
     @constant repeatPassword {password} Repeat Password of the User

   */
  validate() {
    const {
      withoutBankID,
      formValid,
      orgNumber,
      companyName,
      firstName,
      lastName,
      email,
      phone,
      password,
      repeatPassword,
      isSelected,
      emailError,
    } = this.state;

    let error = false;
    // eslint-disable-next-line global-require
    const taiPasswordStrength = require('tai-password-strength');
    const passwordStrength = new taiPasswordStrength.PasswordStrength();
    const validation = passwordStrength.check(password);
    // whether the password and repeatpassword are same or not
    const doesntMatch = password === repeatPassword;
    // password doesnt meet requirements?
    const doesntMeetRequirements = validation
      && validation.charsets
      && (validation.charsets.symbol || validation.charsets.punctuation)
      && validation.charsets.lower
      && validation.charsets.upper
      && validation.charsets.number
      && validation.passwordLength >= 8;
    // password and repeat password validation
    if (doesntMatch && doesntMeetRequirements) {
      formValid.password_isValid = true;
      formValid.repeatPassword_isValid = true;
    } else {
      formValid.password_isValid = false;
      formValid.repeatPassword_isValid = false;
    }

    // Organisation number validation using Luhn algorithm

    if (
      validator.isLength(orgNumber.toString(), {
        min: Constants.OrganisationNumberLength,
        max: Constants.OrganisationNumberLength,
      })
    ) {
      const number = orgNumber.replace('-', '');
      formValid.orgNumber_isValid = Luhn.validate(number);
    } else {
      formValid.orgNumber_isValid = false;
    }
    // e-mail validation
    if (validator.isEmail(email) && !emailError) {
      formValid.email_isValid = true;
    } else {
      formValid.email_isValid = false;
    }
    // phone number validation
    formValid.phoneNumber_isValid = !validator.isEmpty(phone) && validator.matches(phone.toString(),
      Constants.phoneNumberRegexPattern);
    // company name validation
    if (validator.isEmpty(companyName)) {
      formValid.company_isValid = false;
    } else {
      formValid.company_isValid = true;
    }
    // full name validation
    if (validator.isEmpty(firstName) && withoutBankID) {
      formValid.firstName_isValid = false;
    } else {
      formValid.firstName_isValid = true;
    }
    if (validator.isEmpty(lastName) && withoutBankID) {
      formValid.lastName_isValid = false;
    } else {
      formValid.lastName_isValid = true;
    }

    // agree to terms validation
    if (!isSelected) {
      formValid.agreeTerms_isValid = false;
    } else {
      formValid.agreeTerms_isValid = true;
    }

    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  render() {
    const {
      withoutBankID,
      isDisabled,
      isSelected,
      showPwdDoesntMatch,
      loading,
      ismodalopen,
      showRequirementsDoesntMatch,
      emailError,
      phoneNumError,
      formValid,
      orgNumber,
      email,
      phone,
      firstName,
      companyName,
      password,
      repeatPassword,
      lastName,
    } = this.state;

    const { input } = Constants;
    const { name: inputNames, type: inputTypes } = input;
    return (
      <>
        <FlexContainer>
          <SectionLeft height="1500px">
            {withoutBankID && (
              <>
                {loading ? (
                  <Loader height="24px" marginLeft="70%" margin="24px" marginRight="24px" width="100px" />
                ) : (
                  <LoginWrapper className="col-12 col-md-6 p-0">
                    <Button
                      type="update"
                      onClick={this.handleSignUpgigger}
                      name={(
                        <FormattedMessage id="customer_sign_up_gigger" defaultMessage="Sign up Gigger" />
                      )}
                    />
                  </LoginWrapper>
                )}
              </>
            )}
            <>
              <LoginWrapper marginTop="24px">
                {withoutBankID ? (
                  <>
                    {loading ? (
                      <Loader width="320px" />
                    ) : (
                      <Description>
                        <FormattedMessage
                          id="customer_sign_up_already_have_account"
                          defaultMessage="Already have an account?"
                        />
                        {' '}
                        <LoginLink onClick={this.handleLogin} role="button">
                          <Line>
                            <FormattedMessage id="login_login" defaultMessage="Log in" />
                          </Line>
                        </LoginLink>
                      </Description>
                    )}
                    {loading ? (
                      <Loader height="60px" width="326px" />
                    ) : (
                      <Wrapper>
                        <Header>
                          <FormattedMessage
                            id="customer_sign_up_without_bankid"
                            defaultMessage="Sign up without BankID"
                          />
                        </Header>
                      </Wrapper>
                    )}
                  </>
                ) : (
                  <>
                    {loading ? (
                      <Loader width="320px" />
                    ) : (
                      <Description>
                        <FormattedMessage
                          id="customer_sign_up_already_have_account"
                          defaultMessage="Already have an account?"
                        />
                        {' '}
                        <LoginLink onClick={this.handleLogin} role="button">
                          <Line>
                            <FormattedMessage id="login_login" defaultMessage="Log in" />
                          </Line>
                        </LoginLink>
                      </Description>
                    )}
                    {loading ? (
                      <Loader height="60px" width="326px" />
                    ) : (
                      <Wrapper>
                        <Content>
                          <Header>
                            <FormattedMessage
                              id="customer_sign_up_header"
                              defaultMessage="Create company account for free and get gigger details"
                            />
                          </Header>
                        </Content>
                      </Wrapper>
                    )}
                  </>
                )}
                {/* {withoutBankID && (
                  <>
                    {loading ? (
                      <Loader width="320px" />
                    ) : (
                      <Wrapper>
                        <SubTitle>
                          <FormattedMessage
                            id="customer_sign_up_abroad_users_message"
                            defaultMessage="If you are a company
                            abroad who dont have BankID and want to get access to our app.
                            Please send us your e-mail and we will contact you for verification."
                          />
                        </SubTitle>
                      </Wrapper>
                    )}
                  </>
                )} */}
                {loading && [...Array(10)].map(() => (
                  <div className="mr-5">
                    <Loader width="150px" marginTop="30px" />
                    <Loader height="40px" width="346px" marginTop="18px" />
                  </div>
                ))}
                {!loading && (
                <Wrapper>
                  <Content>
                    <SubHeader>
                      <FormattedMessage id="customer_sign_up_company_details" defaultMessage="Company details" />
                    </SubHeader>

                    <Input>
                      <TextBox
                        type="numeric"
                        label={
                          <FormattedMessage id="common_organisation_number" defaultMessage="Organisation number" />
                          }
                        name={inputNames.orgNumber}
                        value={orgNumber}
                        onChange={this.handleChange}
                        placeholder="XXXXXX-YYYY"
                        onKeyPress={this.handleKeyPress}
                        rule={{
                          method: validator.isLength,
                          validWhen: true,
                          args: [
                            {
                              min: Constants.OrganisationNumberLength,
                              max: Constants.OrganisationNumberLength,
                            },
                          ],
                        }}
                        errorMessage={(
                          <FormattedMessage
                            id="error_corporate_id_message"
                            defaultMessage="Enter a valid corporate ID number"
                          />
                          )}
                        existError={
                            !formValid.orgNumber_isValid
                            && orgNumber.length === Constants.OrganisationNumberLength
                          }
                        existErrorMessage={(
                          <FormattedMessage
                            id="error_corporate_id_message"
                            defaultMessage="Enter a valid corporate ID number"
                          />
                          )}
                      />
                    </Input>

                    <Input>
                      <TextBox
                        type={inputTypes.text}
                        label={<FormattedMessage id="common_company_name" defaultMessage="Company name" />}
                        name={inputNames.companyName}
                        value={companyName}
                        onChange={this.handleChange}
                        errorMessage={
                          <FormattedMessage id="error_company_name_message" defaultMessage="Enter name of company" />
                          }
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                      />
                    </Input>
                  </Content>

                  <Content>
                    <SubHeader>
                      <FormattedMessage id="common_account_details" defaultMessage="Account details" />
                    </SubHeader>
                    {withoutBankID && (
                    <>
                      <Input>

                        <TextBox
                          label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
                          name={inputNames.firstName}
                          type={inputTypes.text}
                          value={firstName}
                          onChange={this.handleChange}
                          rule={{
                            method: validator.isEmpty,
                            validWhen: false,
                          }}
                          errorMessage={
                            <FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />
                              }
                        />
                      </Input>

                      <Input>
                        <TextBox
                          label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                          name={inputNames.lastName}
                          type={inputTypes.text}
                          value={lastName}
                          onChange={this.handleChange}
                          rule={{
                            method: validator.isEmpty,
                            validWhen: false,
                          }}
                          errorMessage={
                            <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                              }
                        />
                      </Input>
                    </>
                    )}

                    <Input>
                      <TextBox
                        label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                        name={inputNames.email}
                        type={inputTypes.email}
                        value={email}
                        onChange={emailError ? this.handleErrorChange : this.handleChange}
                        existError={emailError}
                        rule={{
                          method: validator.isEmail,
                          validWhen: true,
                        }}
                        errorMessage={
                          <FormattedMessage id="error_email_message" defaultMessage="Enter a valid e-mail address" />
                          }
                        existErrorMessage={(
                          <FormattedMessage
                            id="error_email_taken_message"
                            defaultMessage="This e-mail address is already registered"
                          />
                          )}
                      />
                    </Input>

                    <Input>
                      <TextBox
                        label={<FormattedMessage id="common_phone" defaultMessage="Phone" />}
                        name="phone"
                        type="text"
                        value={phone}
                        onChange={phoneNumError ? this.handleErrorChange : this.handleChange}
                        onKeyPress={this.handleKeypress}
                        existError={phoneNumError}
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                        errorMessage={
                          <FormattedMessage id="error_phone_message" defaultMessage="Enter a valid phone number" />
                          }
                        existErrorMessage={(
                          <FormattedMessage
                            id="error_phone_number_exists_message"
                            defaultMessage="Enter a valid phone number"
                          />
                          )}
                      />
                    </Input>

                    <Input>
                      <PasswordBox
                        label={<FormattedMessage id="common_password" defaultMessage="Password" />}
                        name="password"
                        value={password}
                        classname={showRequirementsDoesntMatch && 'border-red'}
                        onChange={this.handlePasswordChange}
                        onBlur={this.handlePasswordBlur}
                        errorMessage={(
                          <FormattedMessage
                            id="error_password_validation_message"
                            defaultMessage="Enter a valid password"
                          />
                          )}
                      />
                      {showRequirementsDoesntMatch && (
                      <Error>
                        <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                        <span>
                          <FormattedMessage
                            id="error_password_doesnt_meet_requirements"
                            defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number. "
                          />
                        </span>
                      </Error>
                      )}
                    </Input>

                    <Input>
                      <PasswordBox
                        label={
                          <FormattedMessage id="customer_sign_up_repeat_password" defaultMessage="Repeat password" />
                          }
                        classname={showPwdDoesntMatch && 'border-red'}
                        name={inputNames.repeatPassword}
                        value={repeatPassword}
                        onChange={this.handleRepeatPasswordChange}
                        onBlur={this.handleRepeatPwdBlur}
                        errorMessage={
                          <FormattedMessage id="error_repeat_password_message" defaultMessage="Repeat password" />
                          }
                      />
                      {showPwdDoesntMatch && (
                      <Error>
                        <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                        <span>
                          <FormattedMessage
                            id="error_password_doesnt_match"
                            defaultMessage="Passwords doesn't match"
                          />
                        </span>
                      </Error>
                      )}
                    </Input>

                  </Content>

                  <Instruction className="row m-0" onClick={this.handleAgreeTermsCheckbox}>
                    <div className="col-auto  p-0 mt-1">
                      <CustomCheckBox onClick={this.handleAgreeTermsCheckbox} isSelected={isSelected} label="" />
                    </div>
                    <AgreeTerms className="col p-0">
                      <div>
                        <FormattedMessage
                          id="common_agree_terms_and_conditions_message"
                          values={{
                            privacyLink: <PrivacyPolicy />,
                            termsAndConditionsLink: <TermsAndConditionsLink />,
                          }}
                          defaultMessage="I approve general terms and conditions and that my personal data is processed according to GigAssembly's privacy policy."
                        />
                      </div>
                    </AgreeTerms>
                  </Instruction>

                  {withoutBankID ? (
                    <ButtonWrapper className="mb-4">
                      <Button
                        type="update"
                        name={<FormattedMessage id="common_create_account" defaultMessage="Create account" />}
                        disabled={isDisabled}
                        onClick={this.handleCompanySignUp}
                      />
                    </ButtonWrapper>
                  ) : (
                    <>
                      <SignInButton>
                        <Button
                          type="signIn"
                          name={(
                            <FormattedMessage
                              id="customer_sign_up_approve_with_bankid"
                              defaultMessage="Approve with Mobile BankID"
                            />
                              )}
                          disabled={isDisabled}
                          onClick={this.handleCompanySignUp}
                        />
                      </SignInButton>

                      <TermsandConditions className="mt-4">
                        <FormattedMessage
                          id="customer_sign_up_dont_have_bankid"
                          defaultMessage="Don't have BankID? "
                        />
                        <Link href onClick={this.handleRedirectToWithoutBankIdPage}>
                          <Line>
                            <FormattedMessage id="common_apply_here" defaultMessage="Apply here" />
                          </Line>
                        </Link>
                      </TermsandConditions>

                      <TermsandConditions policy>
                        <FormattedMessage
                          id="terms_and_conditions_by_clicking_message"
                          defaultMessage="By clicking on button, you agree to our"
                        />
                        &nbsp;
                        <TermsAndConditionsLink />
                        &nbsp;
                        <FormattedMessage id="common_and" defaultMessage="and" />
                        &nbsp;
                        <PrivacyPolicy />
                      </TermsandConditions>
                    </>
                  )}
                </Wrapper>
                )}
              </LoginWrapper>
            </>
          </SectionLeft>
          <SectionRight>
            {loading ? (
              <Loader height="1441px" />
            ) : (
              <>
                <ImageWrapper
                  backgroundPosition="unset"
                  backgroundImage={CustomerSignUpImage}
                  backgroundSize="cover !important"
                />
              </>
            )}
          </SectionRight>
          <a
            href
            data-toggle="modal"
            data-target="#runModal"
            ref={(openModal) => {
              this.openElement = openModal;
            }}
          />
          {ismodalopen && (
          <Modal
            ismodalopen={ismodalopen}
            customerModalClose={this.handleCustomerModalClose}
          />
          )}
        </FlexContainer>
      </>
    );
  }
}

export default CustomerSignUp;
