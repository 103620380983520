import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import px2vw from '../../styles/px2vw';
import { BackgroundImage } from '../../styles/Images';

export const Container = styled.div`
  background-color:${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image:${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)};
  display: flex;
  justify-content: center;
  width: ${px2vw(1440)} !important;
`;
export const Form = styled.div`
  margin: 105px auto 242px;
  padding: 0px;
  width: 1100px;
  @media (max-width: 1200px) {
    width: 930px;
  }
  @media (max-width: 990px) {
    width: 688px;
  }
  @media (max-width: 768px) {
    margin-top: 90px;
    margin-bottom:121px;
    justify-content: center;
    width: 508px;
  }
  @media (max-width: 570px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  @media (max-width: 364px) {
    width: 90%;
    align-self: center;
  }
`;
export const Input = styled.div`
  margin-bottom: 30px;
  max-width: ${px2vw(380, 1440)};
  height: 64px;
  @media (max-width: 768px) {
    max-width: ${px2vw(380, 768)};
  }
  @media (max-width: 570px) {
    max-width: 100%;
  }
  @media (max-width: 375px) {
    max-width: 100%;
  }
`;
export const Header = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-weight: 500;
  line-height: 60px;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  @media (max-width: 768px) {
    font-family: ${Font.fontFamily.AvenirBold};
  }
`;
export const Error = styled.div`
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #f03738;
  margin-top: 4px;
`;
export const Description = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${({ label }) => (label ? '14px' : '16px')};
  margin-bottom: ${({ reset, label }) => (reset ? '30px' : label ? '4px' : '38px')};
  color: ${Colors.Primary};
`;
export const InputBox = styled.input`
  width: 100%;
  height: 38px;
  padding: 8px;
  background-color: ${Colors.White};
  outline: 0;
  border: none;
  border-radius: 2px;
`;
export const BorderBox = styled.div`
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.White};
  margin-bottom: 30px;
  max-width: ${px2vw(380, 1440)};
  @media (max-width: 768px) {
    max-width: ${px2vw(380, 768)};
  }
  @media (max-width: 570px) {
    max-width: ${px2vw(380, 570)};
  }
  @media (max-width: 375px) {
    max-width: ${px2vw(346, 375)};
  }
`;
export const Div = styled.div``;
export const Wrapper = styled(Input)`
  height: auto !important;
`;
export const ButtonWrapper = styled.button`
  background-color: ${Colors.Primary};
  border-radius: 2px;
  padding: 8px 35px;
  cursor: pointer;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  border: 1px solid ${Colors.Primary};
  &:active,
  &:focus,
  &:hover {
    border: 1px solid ${Colors.Black};
    outline: none;
    opacity: 0.9;
    background-color: ${Colors.Black};
    color: ${Colors.White};
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
