import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Config from '../../config';
import Constants from '../../shared/constants';
import GiggerService from '../../services/GiggerService';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.giggerService = new GiggerService(Config.apiBase);
  }

  componentDidMount() {
    this.handleLogOut();
  }

  /**
   * @async
   * @function handleLogOut Stops all access to endpoints and loggs out the account
   */
  handleLogOut = async () => {
    try {
      const response = await this.giggerService.logOut();
      if (response.status === 204 || response.status === 200) {
        localStorage.clear();
      }
    } catch (_) {
      // Ignore exception
    }
    const receiver = document.getElementById('receiver').contentWindow;

    receiver.postMessage({ isAuthenticated: false }, Config.wordpressUrl);

    localStorage.clear();
  };

  render() {
    localStorage.clear();
    return <Redirect to={Constants.routes.logIn.url} />;
  }
}

export default withRouter(Logout);
