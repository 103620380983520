const LoginBg = require('../assets/images/loginBg.jpg');
const LoginBank = require('../assets/images/loginBank.png');
const profilePic = require('../assets/images/profilePic.png');
const DefaultProfile = require('../assets/images/DefaultProfile.png');
const GiggerSignUpImage = require('../assets/images/gigger-signup.svg');
const CustomerSignUpImage = require('../assets/images/company-signup.svg');
const SignUpImage = require('../assets/images/MainSignUp.png');
const BackgroundImage = require('../assets/images/background.svg');
const Logo = require('../assets/images/GA_wordmark_novare_Black-1.svg');
const GreenLogo = require('../assets/images/GreenLogo.png');
const ModalImage = require('../assets/images/ModalImage.png');
const PoweredByLogo = require('../assets/images/GAPoweredByNovareLogo.png');
const BankIdLoading = require('../assets/images/BankIdLoading.jpg');
const AccountCircleBlack = require('../assets/images/AccountCircleBlack.svg');
const AccountCircleWhite = require('../assets/images/AccountCircleWhite.svg');

export {
  LoginBg,
  LoginBank,
  DefaultProfile,
  profilePic,
  GiggerSignUpImage,
  CustomerSignUpImage,
  SignUpImage,
  BackgroundImage,
  Logo,
  GreenLogo,
  ModalImage,
  PoweredByLogo,
  BankIdLoading,
  AccountCircleBlack,
  AccountCircleWhite,
};
