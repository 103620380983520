import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
  DragAndDrop,
  CloseButton,
  UploadDocument,
  OpenModal,
} from './styles';
import Select from '../Input/Select';
import { TextDiv } from '../Input/styles';
import Constants from '../../shared/constants';
import RadioButton from '../Input/RadioButton';
import ProfilepageService from '../../services/ProfilePageService';
import OrderService from '../../services/AdminServices/OrderService';
import Config from '../../config';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import { getSiteMapUrl } from '../../shared/utils';
import ModalLoader from '../Loader/ModalLoader';

const LANGUAGE_CODE_MAP = {
  en: 'English',
  sv: 'Swedish',
};
class Modal extends Component {
  profilePageService = new ProfilepageService(Config.apiBase);

  orderService = new OrderService(Config.apiBase);

  constructor(props) {
    super(props);
    const language = localStorage.getItem('language');
    this.state = {
      docs: [],
      docSizeError: false,
      uploadedFile: false,
      isDoctypeSelected: false,
      category: '',
      language: language ? LANGUAGE_CODE_MAP[language] : 'Swedish',
      languageOptions: [
        { label: 'English', value: 'en' },
        { label: 'Swedish', value: 'sv' },
      ],
      loading: false,
    };
  }

  /**
   * @async
   * @function handleRemoveDocument
   * @callback handleLoad
   * Removes the attached document
   */
  handleRemoveDocument = async () => {
    this.setState({ loading: true });
    const { id, handleLoad } = this.props;
    const { docs } = this.state;
    const data = {
      docs: {
        value: [docs],
      },
    };
    try {
      const response = await this.orderService.removeDocument(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoad();
    }
  };

  /**
   * Captures drag and drop event of the file
   * @param {event} e
   */
  async onFileDrag(e) {
    e.preventDefault();
    this.setState({ docSizeError: false });
    const filedata = e.dataTransfer.files && e.dataTransfer.files[0];
    if (filedata && filedata.size <= Constants.fileSizeLimitTenMB) {
      this.setState({
        uploadedFile: true,
        docs: filedata,
        docSizeError: false,
      });
    } else {
      this.setState({ docSizeError: true });
    }
  }

  /**
   * Uploads file after verifying type and size of the file.
   * @param {event} e
   */
  onFileLoad = async (e) => {
    e.preventDefault();
    const filedata = e.currentTarget.files && e.currentTarget.files[0];
    if (filedata && filedata.size <= Constants.fileSizeLimitTenMB) {
      this.setState({
        uploadedFile: true,
        docs: filedata,
        docSizeError: false,

      });
    } else {
      this.setState({ docSizeError: true });
    }
  };

  /**
 * Handles all types of drag events
 * @param {event} e
 */
  handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  /**
   * Removes the attached document.
   */
  handleRemoveFile = () => {
    this.setState({ uploadedFile: false, docs: [] });
  };

  /**
   * Handles the category of document
   * @param {string} category
   */
  handleSelectType = (category) => () => {
    this.setState({ category, isDoctypeSelected: true });
  };

  /**
   * Handles change in language
   */
  handleSelectLanguageChange = (name, label) => {
    this.setState({ [name]: label });
  };

  /**
   * Changes language of the portal by seting language variable in localstorage.
   */
  handleChangeLanguage = () => {
    this.setState({ loading: true });
    const { ModalClose } = this.props;
    const { language } = this.state;
    const languageMap = {
      English: 'en',
      Swedish: 'sv',
    };

    const currentLanguage = localStorage.getItem('language');

    if (currentLanguage && currentLanguage !== languageMap[language]) {
      localStorage.setItem('language', languageMap[language]);
      window.location = getSiteMapUrl(languageMap[language]);
    }
    this.setState({ loading: false });
    this.closeElement.click();
    ModalClose();
  };

  /**
   * Adds the uploaded document with existing documents.
   */
  handleAddDocumentClick = () => {
    const { handleAddDocument } = this.props;
    const { docs, category } = this.state;
    this.closeElement.click();
    handleAddDocument(docs, category);
  };

  render() {
    const {
      changeLanguage, remove, addDocument, ModalClose, handleDeleteDocument,
    } = this.props;
    const {
      languageOptions,
      docs,
      uploadedFile,
      category,
      docSizeError,
      language,
      isDoctypeSelected,
      loading,
    } = this.state;

    return (
      <>
        <div
          className="modal fade fade-profile-modal"
          id="AdminModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      <Header>
                        {changeLanguage ? (
                          'Change language'
                        ) : addDocument ? (
                          <FormattedMessage id="common_upload_document" defaultMessage="Upload document" />
                        ) : (
                          ''
                        )}
                      </Header>
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {(remove && !loading) && (
                    <>
                      <Text>
                        <FormattedMessage
                          id="admin_user_delete_confirmation"
                          defaultMessage="Are you sure you want to delete?"
                        />
                      </Text>
                      <Input>
                        <Button onClick={handleDeleteDocument} data-dismiss="modal" aria-label="Close">
                          <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
                        </Button>
                        <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose}>
                          <FormattedMessage id="common_no" defaultMessage="No" />
                        </CancelButton>
                      </Input>
                    </>
                  )}

                  {(changeLanguage && !loading) && (
                    <>
                      {' '}
                      <Input>
                        <Select
                          name="language"
                          value={language}
                          label={<FormattedMessage id="common_language" defaultMessage="Language" />}
                          onChange={this.handleSelectLanguageChange}
                          errorMessage={
                            <FormattedMessage id="error_select_language_message" defaultMessage="Select languages" />
                          }
                          options={languageOptions}
                        />
                      </Input>
                      <Input>
                        <Button onClick={this.handleChangeLanguage}>
                          <FormattedMessage id="common_ok" defaultMessage="Ok" />
                        </Button>
                        <CancelButton onClick={ModalClose} data-dismiss="modal" aria-label="Close" ref={(closeModal) => { this.closeElement = closeModal; }}>
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>
                      </Input>
                    </>
                  )}

                  {(addDocument && !loading) && (
                    <>
                      {uploadedFile ? (
                        <UploadDocument className="col p-2">
                          <Text className="text-truncate">
                            {docs.name}
                          </Text>
                        </UploadDocument>
                      ) : (
                        <div className="col p-0">
                          <label
                            onDrop={this.onFileDrag.bind(this)}
                            onDragOver={(e) => this.handleDragOver(e)}
                            onDragEnter={(e) => this.handleDragEnter(e)}
                            onDragLeave={(e) => this.handleDragLeave(e)}
                            className="w-100"
                          >
                            <UploadDocument className="col p-2">
                              <OpenModal role="button" className="mt-2">
                                <FormattedMessage
                                  id="common_drag_and_drop_file_uploader"
                                  defaultMessage="Drag-and-drop file uploader"
                                />
                              </OpenModal>

                              <DragAndDrop
                                accept={Constants.inputTypes.acceptFileTypes}
                                onClick={this.openFileDialog}
                                className="FileInput"
                                type="file"
                                id="file-browser-input"
                                name="file-browser-input"
                                ref={(input) => {
                                  this.fileInput = input;
                                }}
                                onDragOver={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                onDragEnter={(e) => this.handleDragEnter(e)}
                                onDragLeave={(e) => this.handleDragLeave(e)}
                                onDrop={this.onFileLoad}
                                onChange={this.onFileLoad}
                              />
                            </UploadDocument>
                          </label>
                        </div>
                      )}
                      {uploadedFile && (
                        <div className="col text-end p-0 pt-1">
                          <CloseButton onClick={this.handleRemoveFile} role="button">
                            <FormattedMessage id="common_remove" defaultMessage="remove" />
                          </CloseButton>
                        </div>
                      )}
                      {docSizeError && (
                        <TextDiv className="text-center">
                          <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                          <span>
                            <FormattedMessage id="error_doc_size" defaultMessage="Document size exceeded" />
                          </span>
                        </TextDiv>
                      )}
                      <Text><FormattedMessage id="common_upload_max_doc_info" defaultMessage="Maximum 800 kb, only jpg,pdf,word" /></Text>
                      <Input>
                        <div className="mt-3">
                          {' '}
                          <RadioButton
                            key={Constants.docsCategory[3]}
                            label="CV"
                            color={Colors.Grey}
                            fontFamily={Fonts.fontFamily.AvenirMedium}
                            isSelected={category === Constants.docsCategory[3]}
                            onClick={this.handleSelectType(Constants.docsCategory[3])}
                          />
                        </div>
                        <div className="mt-3">
                          {' '}
                          <RadioButton
                            key={Constants.docsCategory[4]}
                            label="Personal letter"
                            color={Colors.Grey}
                            fontFamily={Fonts.fontFamily.AvenirMedium}
                            isSelected={category === Constants.docsCategory[4]}
                            onClick={this.handleSelectType(Constants.docsCategory[4])}
                          />
                        </div>
                        <div className="mt-3">
                          {' '}
                          <RadioButton
                            key={Constants.docsCategory[5]}
                            label="Other"
                            color={Colors.Grey}
                            fontFamily={Fonts.fontFamily.AvenirMedium}
                            isSelected={category === Constants.docsCategory[5]}
                            onClick={this.handleSelectType(Constants.docsCategory[5])}
                          />
                        </div>
                      </Input>
                      <Input>
                        <Button
                          disabled={!(uploadedFile && isDoctypeSelected)}
                          onClick={this.handleAddDocumentClick}
                        >
                          <FormattedMessage id="common_upload" defaultMessage="Upload" />
                        </Button>
                        <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>
                      </Input>
                    </>
                  )}
                  {loading && <ModalLoader />}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
