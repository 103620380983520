/**
 * Company model which handles all serialization.
 */
class Company {
  static fromJson(obj) {
    return new Company({ obj });
  }

  toJson = (data) => ({
    organization_number: data.orgNumber,
    first_name: data.firstName,
    last_name: data.lastName,
    full_name: `${data.firstName} ${data.lastName}`,
    company_name: data.companyName,
    email: data.email,
    phone: data.phone,
    password: data.password,
    password_confirmation: data.repeatPassword,
    personal_number: data.personalNumber,
    withoutBankID: data.withoutBankID,
  });
}

export default Company;
