import React, { Component } from 'react';
import validator from 'validator';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TextBox from '../../components/Input/TextBox';
import ChangeButton from '../../components/Button/Button';
import Constants from '../../shared/constants';
import {
  Content,
  Image,
  SaveButton,
  HeaderName,
  SubHeader2,
  Upload,
} from '../customer/styles';
import {
  DragAndDrop, UploadDocument, CloseButton,
} from './styles';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import Loader from '../../components/Loader/Loader';
import AuthService from '../../services/AuthService';
import { TextDiv } from '../../components/Input/styles';
import CustomCheckBox from '../../components/Input/CustomCheckbox';

class CompanyDetails extends Component {
  giggerService = new GiggerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      uploadedFile: false,
      filename: '',
      fileData: {},
      fileURL: '',
      fileId: '',
      billingAddress: '',
      orgNumber: '',
      billingRef: '',
      formValid: {
        billingAddress_isValid: false,
        file_isValid: false,
      },
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      docUploading: false,
      docSizeError: false,
      docUploadError: false,
      isInvoiceWithThirdPartyChecked: false,
      emailError: false,
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchCompanyDetails();
  }

  /**
   * Handles change in input field
   * @param {Event} event Consists of name and value of the input field
   * @listens Event
   */
  handleChange = (event) => {
    let { emailError } = this.state;
    const { name = '', value = '' } = event.target;
    if (name === Constants.billingAddress) {
      emailError = false;
    }
    this.setState({ [name]: value, emailError }, this.validate);
  };

  /**
   * Updates the details
   */
  handleSaveButton = (event) => {
    event.preventDefault();
    const { billingAddress, billingRef, fileId } = this.state;
    const { handleAddressDetails } = this.props;
    const data = {
      email: billingAddress,
      billing_reference: billingRef,
      tax_doc: fileId,
    };
    handleAddressDetails(data);
  };

  /**
   * @async
   * @function onFileLoad Uploads file
   */
  onFileLoad = async (e) => {
    const file = e.currentTarget.files && e.currentTarget.files[0];
    const {
      inputTypes: { acceptFileTypes },
    } = Constants;
    if (file && acceptFileTypes.includes(file.type)) {
      this.setState({
        docUploading: true,
        docSizeError: false,
        docUploadError: false,
      });
      if (file.size <= Constants.fileSizeLimitTenMB) {
        try {
          const response = await this.giggerService.uploadmediaFile(file, 'docs', 'F-skattsedel');
          const { data = {} } = response.data;
          this.setState(
            {
              uploadedFile: true,
              fileId: data._id,
              fileURL: data.url,
              fileData: data,
              filename: data.name,
              docUploading: false,
            },
            this.validate,
          );
        } catch (err) {
          this.setState({ docUploading: false, docUploadError: true });
        }
      } else {
        this.setState({ docSizeError: true, docUploading: false });
      }
    }
  };

  /**
   * Opens uploaded file
   */
  handleFile = () => {
    const { fileURL } = this.state;
    window.open(fileURL);
  };

  handleRemoveFile = () => {
    this.setState(
      {
        uploadedFile: false,
        filename: '',
        fileData: [],
        fileId: '',
      },
      this.validate,
    );
  };

  /**
   * Redirects to My accounts page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerMyAccount.url);
  };

  /**
   * Validates form
   */
  validate() {
    const {
      billingAddress, formValid, fileId, isInvoiceWithThirdPartyChecked,
    } = this.state;
    let error = false;
    if (!validator.isEmail(billingAddress)) {
      formValid.billingAddress_isValid = false;
    } else {
      formValid.billingAddress_isValid = true;
    }
    if (fileId || isInvoiceWithThirdPartyChecked) {
      formValid.file_isValid = true;
    } else {
      formValid.file_isValid = false;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  /**
   * @async
   * @function fetchCompanyDetails Fetches company details of the user
   */
  async fetchCompanyDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.giggerService.companyDetails();
      const { data = {} } = response;
      const {
        email: billingAddress = '',
        billing_reference: billingRef = '',
        organization_number: orgNumber = '',
        tax_doc: fileData = {},
      } = data;
      this.setState({
        billingAddress,
        billingRef,
        fileData,
        fileId: fileData._id,
        filename: fileData.file_name,
        orgNumber,
        uploadedFile: !!fileData.file_name,
        loading: false,
        isInvoiceWithThirdPartyChecked: data.third_party_invoice,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      uploadedFile,
      filename,
      isDisabled,
      orgNumber = '-',
      loading,
      loggedIn,
      billingRef,
      billingAddress,
      docUploadError,
      docSizeError,
      docUploading,
      isInvoiceWithThirdPartyChecked,
      fileData,
      emailError,
    } = this.state;
    const { loading: propsLoading } = this.props;
    if (!loggedIn) {
      return (
        <Redirect
          to={{
            pathName: Constants.routes.logIn.url,
          }}
        />
      );
    }
    return (
      <>
        <Content className="row">
          {(loading || propsLoading) ? (
            <Loader width="150px" />
          ) : (
            <SubHeader2>
              {`${Constants.language.common_corporate_id_number}: ${orgNumber || '-'}`}
            </SubHeader2>
          )}
        </Content>
        {isInvoiceWithThirdPartyChecked && (
        <Content className="row">
          <SubHeader2 className="col-auto  p-0 my-2 disableOption">
            {loading || propsLoading ? (
              <Loader height="20px" width="150px" />
            ) : (
              <CustomCheckBox
                isSelected={isInvoiceWithThirdPartyChecked}
                label={(
                  <FormattedMessage
                    id="gigger_signup_invoice_with_third_party_check_label"
                    defaultMessage="I will invoice through third party company"
                  />
                                )}
              />
            )}
          </SubHeader2>
        </Content>
        )}
        <Content className="row">
          {loading || propsLoading ? (
            <Loader height="32px" width="346px" marginTop="10px" />
          ) : (
            <>
              <Content className="col-12 p-0">
                <HeaderName>
                  <FormattedMessage id="common_billing_address" defaultMessage="Billing address" />
                </HeaderName>
              </Content>
              <Content className="col-12 p-0">
                <TextBox
                  type="text"
                  name="billingAddress"
                  value={billingAddress}
                  onChange={this.handleChange}
                  existError={emailError}
                  existErrorMessage={(
                    <FormattedMessage
                      id="error_email_taken_message_1"
                      defaultMessage="This e-mail address is already registered"
                    />
                                )}
                  errorMessage={(
                    <FormattedMessage
                      id="error_billing_address_message"
                      defaultMessage="Enter a billing address"
                    />
                                )}
                  rule={{
                    method: validator.isEmail,
                    validWhen: true,
                  }}
                />
              </Content>
            </>
          )}
        </Content>
        <Content className="row">
          {loading || propsLoading ? (
            <Loader height="32px" width="346px" marginTop="10px" />
          ) : (
            <>
              <Content className="col-12 p-0">
                <HeaderName>
                  <FormattedMessage id="common_billing_reference" defaultMessage="Billing reference" />
                </HeaderName>
              </Content>
              <Content className="col-12 p-0">
                <TextBox
                  type="text"
                  name="billingRef"
                  value={billingRef}
                  onChange={this.handleChange}
                  noEmptyError
                />
              </Content>
            </>
          )}
        </Content>
        {!isInvoiceWithThirdPartyChecked && (
        <>
          <Content className="row">
            {loading || docUploading || propsLoading ? (
              <Loader height="32px" width="346px" marginTop="10px" />
            ) : (
              <>
                <Content className="col-12 p-0">
                  <HeaderName>
                    <FormattedMessage id="common_file_upload" defaultMessage="F-skattsedel" />
                  </HeaderName>
                </Content>
                <Content className="col-12 p-0">
                  {uploadedFile && fileData ? (
                    <UploadDocument>
                      <Content className="row m-0">
                        <Content className="col-11 py-2 pl-2 pr-0 text-truncate">
                          <a
                            href={fileData.url}
                            role="button"
                            download={fileData.file_name}
                            className="color-black"
                          >
                            {filename}
                          </a>
                        </Content>
                        <Content className="col text-end mr-2 p-0">
                          <CloseButton onClick={this.handleRemoveFile} role="button">
                            &times;
                          </CloseButton>
                        </Content>
                      </Content>
                    </UploadDocument>
                  ) : (
                    <label>
                      <Upload>
                        <Image src={Constants.icons.Plus} alt="icon" height="16px" width="16px" />
                        {' '}
                        <FormattedMessage id="common_upload" />
                      </Upload>

                      <DragAndDrop
                        accept={Constants.inputTypes.acceptFileTypes}
                        onClick={this.openFileDialog}
                        className="FileInput"
                        type="file"
                        id="file-browser-input"
                        name="file-browser-input"
                        ref={(input) => {
                          this.fileInput = input;
                        }}
                        onChange={this.onFileLoad}
                      />
                    </label>
                  )}
                </Content>
              </>
            )}
          </Content>
          {(docSizeError || docUploadError) && (
          <TextDiv className="text-center">
            <img
              src={Constants.icons.ErrorIcon}
              alt="icon"
              className="mr-1"
              width="12px"
              height="12px"
            />
            {docUploadError && (
            <span>
              <FormattedMessage id="error_doc_upload" defaultMessage="Unable to upload document" />
            </span>
            )}
            {docSizeError && (
            <span>
              <FormattedMessage id="error_doc_size" defaultMessage="Document size exceeded" />
            </span>
            )}
          </TextDiv>
          )}
        </>
        )}
        <SaveButton className="my-3">
          {loading || propsLoading ? (
            <Loader width="150px" />
          ) : (
            <ChangeButton
              type="update"
              name={<FormattedMessage id="common_save" defaultMessage="Save" />}
              disabled={isDisabled}
              onClick={this.handleSaveButton}
            />
          )}
        </SaveButton>
      </>
    );
  }
}

export default CompanyDetails;
