import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';

export const CalenderCol = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
  min-width: 223px;
  }
`;
export const CalenderNavContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const P = styled.p`
  height: 18.6px;
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size18}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
  color: ${Colors.Primary};

}
`;

export const CalenderCell = styled.p`
  font-family: ${({ colHeading }) => (!colHeading ? Fonts.fontFamily.AvenirRegular : Fonts.fontFamily.AvenirMedium)};
  font-size: ${({ colHeading }) => (!colHeading ? Fonts.fontSize.Size14 : Fonts.fontSize.Size16)}px;
  padding: 5px;
  width: 100%;
  text-align: center;
  color: ${({ colorWhite }) => (colorWhite ? Colors.White : Colors.Primary)};
  opacity: ${({ opacity }) => (opacity || '1')};
  background-color: ${({ highLightCell }) => (highLightCell ? Colors.HighlightGreen : Colors.Transparent)};
  font-weight: ${({ colHeading }) => (!colHeading ? 'normal' : '500')};
  margin-bottom: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: ${({ colHeading }) => (!colHeading ? '0.56px' : 'normal')};
`;
export const MonthName = styled.p`
  font-family: ${Fonts.fontFamily.AvenirMedium};
  font-size: ${Fonts.fontSize.Size18};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
  justify-content: center;
  display: flex;
`;

export const CalendarWrapper = styled.div`
width: 100%;
  & .react-datepicker-wrapper {
    display: block;
  }
  & .react-datepicker-popper {
    z-index: 5;
  }
  & .react-datepicker__header {
    background-color: ${Colors.White} !important;
    border-bottom: none;
  }
  & .react-datepicker__day {
    opacity: 0.4 !important;
    text-align: center;
    font-size: ${Fonts.fontSize.Size16}px;
    font-family: ${Fonts.fontFamily.AvenirMedium};
  }
  & .react-datepicker__day-name {
    opacity: 0.8 !important;
    font-size: ${Fonts.fontSize.Size14}px;
    font-family: ${Fonts.fontFamily.AvenirRegular};
  }
  & .react-datepicker__current-month {
    font-family: ${Fonts.fontFamily.AvenirMedium};
    font-size: ${Fonts.fontSize.Size18}px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Colors.Primary};
  }
  & .react-datepicker__day--keyboard-selected {
    background-color: ${Colors.White};
    color: ${Colors.Primary};
    &:focus {
      border: none;
      outline: none;
    }
  }
  & .react-datepicker__day--selected {
    background-color: ${Colors.HighlightGreen};
    opacity: 1 !important;
    height: 24px;
    padding: 1px 0px 1px 0px;
    &:focus {
      border: none;
      outline: none;
    }
  }
  & .react-datepicker__day--today {
    font-weight: normal;
  }
  & .react-datepicker__navigation {
    outline: none !important;
  }
  & .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    border-bottom-color: ${Colors.White};
  }
  .react-datepicker-popper .react-datepicker {
    min-height: 300px;
  }
`;

export const CalendarDiv = styled.div`
  @media (max-width: 768px) {
    overflow: scroll;
    flex-wrap: inherit;
  }
`;
