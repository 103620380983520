const host = 'https://api.app.gigassembly.com/';
export default {
  domain: host,
  apiBase: `${host}api/v1/`,
  baseName: '/',
  googleMapsApikey: 'AIzaSyDS90V5Lkqnaj2U0a3BSy7TDJJVEa-W6mQ',
  language: 'sv',
  markerDestinationId: '619ca345ded0e203be4c90e1',
  appUrl: 'https://app.gigassembly.com',
  wordpressUrl: 'https://gigassembly.com',
};
