import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import ProfileModel from '../../../../models/GiggerProfile';
import { FlexContainer } from '../../../profile/styles';
import Constants from '../../../../shared/constants';
import Config from '../../../../config';
import AuthService from '../../../../services/AuthService';
import GiggerService from '../../../../services/AdminServices/GiggersService';
import ProfileForm from '../../../profile/ProfileForm';

class ProfileTab extends Component {
  authService = new AuthService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: this.authService.isAuthenticated(),
      areChangesSaved: true,
      giggerData: {},
      loading: false,
    };
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchGiggerProfile();
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;
    if (prevProps.loading !== loading && loading) {
      this.fetchGiggerProfile();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    const message = 'Please click Update. Unsaved changes will be lost.';
    const { areChangesSaved } = this.state;
    if (!areChangesSaved) {
      (e || window.event).preventDefault(); // for Firefox
      (e || window.event).returnValue = message; // for Chrome
    }
  };

  /**
   * @async
   * @function handleFinishProfile Saves the gigger profile
   * @param {object} data Data of gigger.
   */
  handleFinishProfile = async (data) => {
    this.setState({ loading: true });
    let response;
    const { giggerId, handleToast } = this.props;
    this.profileModel = new ProfileModel({});
    try {
      response = await this.giggerService.updateGigger(
        giggerId, this.profileModel.toJson(data, true),
      );
      if (response) {
        this.fetchGiggerProfile();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_profile_updated,
          delay: Constants.toast.DELAY,
        });
      } else {
        handleToast({
          type: Constants.toast.ERROR,
          message: Constants.language.toast_try_after_time,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      // TODO: handle exception
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
    this.setState({ loading: false, areChangesSaved: true });
  };

  /**
   * Stores whether any input value is changed or not
   */
  handleChangesInInput = () => {
    this.setState({ areChangesSaved: false });
  };

  /**
   * Stops loading
   */
  stopLoader = () => {
    const { stopLoader } = this.props;
    this.setState({ loading: false }, stopLoader());
  };

  /**
   * Fetches the gigger profile
   */
  async fetchGiggerProfile() {
    this.setState({ loading: true });
    const { giggerId } = this.props;
    try {
      const response = await this.giggerService.getGiggerProfile(giggerId);
      this.setState({ giggerData: response });
    } catch (exception) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { giggerData, areChangesSaved, loading } = this.state;
    const { cvStatus } = this.props;

    return (
      <>
        <Prompt when={!areChangesSaved} message={Constants.language.Error_changes_not_saved} />
        <FlexContainer bgColor="none" bgImage="none">
          <ProfileForm
            data={giggerData}
            loading={loading}
            areChangesSaved={this.handleChangesInInput}
            onSubmit={this.handleFinishProfile}
            cvStatus={cvStatus}
            admin
            stopLoader={this.stopLoader}
          />
        </FlexContainer>
      </>
    );
  }
}

export default ProfileTab;
