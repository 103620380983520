import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
} from '../../../components/Modals/styles';
import CompanyService from '../../../services/AdminServices/CompanyService';
import CustomerService from '../../../services/CustomerService';
import UserService from '../../../services/AdminServices/UserService';
import Config from '../../../config';
import Select from '../../../components/Input/Select';
import TextBox from '../../../components/Input/TextBox';
import Constants from '../../../shared/constants';
import { SubHeader2 } from '../../customer/styles';
import ModalLoader from '../../../components/Loader/ModalLoader';
import PasswordBox from '../../../components/Input/PasswordBox';

class Modal extends Component {
  companyService = new CompanyService(Config.apiBase);

  userService = new UserService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      assignOwnerOptions: [{ label: 'None', value: '' }],
      owner: '',
      ownerId: '',
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      id: '',
      personalNumber: '',
      billingReference: '',
      invoiceEmail: '',
      addUserFormValid: {
        invite_first_name_valid: '',
        invite_last_name_valid: '',
        invite_role_valid: '',
        invite_email_valid: '',
      },
      editUserFormValid: {
        first_name_valid: '',
        last_name_valid: '',
        role_valid: '',
        email_valid: '',
        zipcode_is_Valid: '',
        phone_is_Valid: '',
        invoice_email_is_valid: '',
      },
      isAddUserDisabled: true,
      isRoleChanged: false,
      emailError: false,
      isDisabled: true,
      userOptions: [
        { label: Constants.company, value: Constants.role.COMPANY },
        { label: Constants.company_user, value: Constants.role.COMPANY_USER },
      ],
      loading: false,
      address: '',
      city: '',
      zipcode: '',
      zipcodeError: false,
      limit: '',
      newPassword: '',
      repeatPassword: '',
      phone: '',
    };
  }

  async componentDidMount() {
    const {
      assignOwner, data = {}, EditUser, currentAccountOwner, limitCount = '',
    } = this.props;
    this.setState({ loading: true, limit: limitCount });
    const {
      first_name: firstName = '',
      last_name: lastName = '',
      billing_reference: billingReference = '',
      role_name: role = '',
      personal_number: personalNumber = '',
      email = '',
      id = '',
      invoice_details: invoiceDetails = {},
      phone = '',
      invoice_email: invoiceEmail = '',
    } = data;
    const { address = '', city = '', zip: zipcode = '' } = invoiceDetails || {};
    let { assignOwnerOptions } = this.state;
    const queryParams = [['pageSize', 100], ['role', Constants.role.Admin]];
    if (assignOwner) {
      const response = await this.userService.getUsersList(queryParams);
      if (data) {
        assignOwnerOptions = response.data.map((obj) => ({
          label: obj.full_name,
          value: obj.id,
        }));
      }
      this.setState({ assignOwnerOptions, owner: currentAccountOwner });
    }
    if (EditUser) {
      this.setState({
        firstName: firstName || '',
        lastName: lastName || '',
        billingReference: billingReference || '',
        role: role || '',
        personalNumber: personalNumber || '',
        email: email || '',
        id: id || '',
        address: address || '',
        city: city || '',
        zipcode: zipcode || '',
        phone: phone || '',
        invoiceEmail,
      });
    }
    this.setState({ loading: false });
  }

  handleUserRoleChange = (name, label, value) => {
    this.setState({ [name]: value, isRoleChanged: true }, this.isEditUserFormValid);
  };

  handleInviteUserRoleChange = (name, label, value) => {
    this.setState({ [name]: value }, this.isAddUserValid);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let { emailError } = this.state;
    if (name === Constants.input.name.email) {
      emailError = false;
    }
    this.setState({ [name]: value, emailError }, this.isEditUserFormValid);
  };

  handleKeyPress=(evt) => {
    const theEvent = evt || window.event;
    const { name } = theEvent.target;
    const { value } = theEvent.target;

    if (name === Constants.zipcode && value.length >= Constants.zipcodeLength) {
      theEvent.preventDefault();
    }
  }

  handleChangeInviteUser = (event) => {
    const { name, value } = event.target;
    let { emailError } = this.state;
    if (name === Constants.input.name.email) {
      emailError = false;
    }
    this.setState({ [name]: value, emailError }, this.isAddUserValid);
  };

  handleSendInvitation = async () => {
    this.setState({ emailError: false, loading: true });
    const { orgNum, handleLoad } = this.props;
    const {
      firstName, lastName, email, role,
    } = this.state;
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      organization_number: orgNum,
      role,
    };
    try {
      const response = await this.customerService.inviteUser(data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
      }
    } catch (e) {
      const { response = {} } = e;
      const { data: errorData = {} } = response;
      const { errors = {} } = errorData;
      if (errors && errors.email) {
        this.setState({ emailError: true, isAddUserDisabled: true });
      } else {
        this.closeElement.click();
        handleLoad();
      }
      this.setState({ loading: false });
    }
  };

  handleapprove = async () => {
    this.setState({ loading: true });
    const { id, handleLoaddata } = this.props;
    try {
      const response = await this.companyService.approveCompany(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoaddata();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoaddata();
    }
  };

  isAddUserValid = () => {
    const {
      firstName, lastName, email, role, addUserFormValid,
    } = this.state;
    let error = false;
    if (validator.isEmpty(lastName)) {
      addUserFormValid.invite_last_name_valid = false;
    } else {
      addUserFormValid.invite_last_name_valid = true;
    }
    if (validator.isEmpty(firstName)) {
      addUserFormValid.invite_first_name_valid = false;
    } else {
      addUserFormValid.invite_first_name_valid = true;
    }
    if (validator.isEmpty(role)) {
      addUserFormValid.invite_role_valid = false;
    } else {
      addUserFormValid.invite_role_valid = true;
    }
    if (!validator.isEmail(email)) {
      addUserFormValid.invite_email_valid = false;
    } else {
      addUserFormValid.invite_email_valid = true;
    }
    Object.keys(addUserFormValid).map((obj) => {
      if (addUserFormValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isAddUserDisabled: error });
    return { error, addUserFormValid };
  };

  isEditUserFormValid = () => {
    const {
      firstName, lastName, email, role, editUserFormValid, zipcode, phone, invoiceEmail,
    } = this.state;

    let error = false;
    if (validator.isEmpty(lastName)) {
      editUserFormValid.last_name_valid = false;
    } else {
      editUserFormValid.last_name_valid = true;
    }
    if (validator.isEmpty(firstName)) {
      editUserFormValid.first_name_valid = false;
    } else {
      editUserFormValid.first_name_valid = true;
    }
    if (validator.isEmpty(role)) {
      editUserFormValid.role_name_valid = false;
    } else {
      editUserFormValid.role_name_valid = true;
    }
    if (!validator.isEmail(email)) {
      editUserFormValid.email_valid = false;
    } else {
      editUserFormValid.email_valid = true;
    }
    if (zipcode && zipcode.length > 0 && zipcode.length < 5) {
      editUserFormValid.zipcode_is_Valid = false;
    } else {
      editUserFormValid.zipcode_is_Valid = true;
    }
    editUserFormValid.phone_is_Valid = phone && validator.matches(phone.toString(),
      Constants.phoneNumberRegexPattern);
    editUserFormValid.invoice_email_is_valid = invoiceEmail && validator.isEmail(invoiceEmail);
    Object.keys(editUserFormValid).map((obj) => {
      if (editUserFormValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, editUserFormValid };
  };

  handleBlur=() => {
    const { zipcode } = this.state;
    let zipcodeError = false;
    if (zipcode && zipcode.length > 0 && zipcode.length < 5) {
      zipcodeError = true;
    }
    this.setState({ zipcodeError });
  }

  handleEditUser = async () => {
    this.setState({ loading: true });
    const {
      isRoleChanged, firstName, lastName, role, email, billingReference, id, address, zipcode, city,
      newPassword, repeatPassword, phone, invoiceEmail,
    } = this.state;
    const { handleLoad } = this.props;
    const data = {
      first_name: firstName,
      last_name: lastName,
      billing_reference: billingReference,
      id,
      email,
      role,
      role_change_flag: isRoleChanged,
      address,
      city,
      zip: zipcode,
      password: newPassword,
      password_confirmation: repeatPassword,
      phone,
      invoice_email: invoiceEmail,
    };
    try {
      const response = await this.customerService.updateCompanyDetails(data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
      }
    } catch (e) {
      const { response = {} } = e;
      const { data: errorData = {} } = response;
      const { message = '' } = errorData;
      if (message === Constants.error.emailExistsMessage) {
        this.setState({ emailError: true, loading: false });
      } else {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
      }
    }
  };

  handleAssignOwner = async () => {
    this.setState({ loading: true });
    const { id, handleLoaddata } = this.props;
    const { ownerId } = this.state;
    const data = { accountOwner: { value: ownerId } };
    try {
      const response = await this.companyService.updateCompanyOwner(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoaddata();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoaddata();
    }
  };

  handleLockUsers = async () => {
    this.setState({ loading: true });
    const { id, handleLoaddata } = this.props;
    const { limit } = this.state;
    const data = { limit };
    try {
      const response = await this.companyService.updateCompanyOwner(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoaddata();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoaddata();
    }
  };

  handleSelectChange = (name, value, id) => {
    this.setState({ [name]: value, ownerId: id });
  };

  handleRemoveAccount = async () => {
    this.setState({ loading: true });
    const { id, history, handleLoaddata } = this.props;
    try {
      const response = await this.companyService.deleteCompanyUser(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        history.replace(Constants.routes.companies.url);
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoaddata();
    }
  };

  render() {
    const {
      remove,
      approveProfile,
      approve,
      deleteUser,
      assignOwner,
      updateUserRole,
      EditUser,
      addUser,
      ModalClose,
      handleDelete,
      handleUpdateUserRole,
      isLock,
      limitOverModal,
    } = this.props;
    const {
      owner,
      assignOwnerOptions,
      emailError,
      isDisabled,
      userOptions,
      isAddUserDisabled,
      editUserFormValid,
      firstName,
      lastName,
      email,
      role,
      billingReference,
      personalNumber,
      loading,
      address,
      zipcode,
      newPassword,
      repeatPassword,
      zipcodeError,
      city,
      limit,
      phone,
      invoiceEmail,
    } = this.state;
    const { input } = Constants;
    const { name: inputNames, type: inputTypes } = input;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="CompanyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-small" role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card>
                  {!loading && (
                  <Section className="row m-0">
                    <Content className="col">
                      {!(remove || approve || deleteUser || updateUserRole) && (
                      <Header>
                        {EditUser && <FormattedMessage id="common_edit_user" defaultMessage="Edit user" />}
                        {addUser && <FormattedMessage id="admin_header_add_new_user" defaultMessage="Add new user" />}
                        {approveProfile && (
                        <FormattedMessage id="admin_company_approve_profile" defaultMessage="Approve profile?" />
                        )}
                        {assignOwner && (
                        <FormattedMessage
                          id="admin_gigger_assign_account_owner"
                          defaultMessage="Assign account owner"
                        />
                        )}
                        {isLock && (
                        <FormattedMessage
                          id="common_lock"
                          defaultMessage="Lock"
                        />
                        )}
                        {limitOverModal && (
                          <FormattedMessage
                            id="common_limit"
                            defaultMessage="Limit"
                          />
                        )}
                      </Header>
                      )}
                      {updateUserRole && (
                      <Header>
                        <FormattedMessage id="admin_company_change_user_role" defaultMessage="Change role?" />
                      </Header>
                      )}
                    </Content>
                    <Content message className="col-auto">
                      <Span>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </Span>
                    </Content>
                  </Section>
                  )}
                  {(remove && !loading) && (
                  <>
                    <Text>
                      <FormattedMessage
                        id="admin_company_remove_account_confirmation_message"
                        defaultMessage="Are you sure you want to delete?"
                      />
                    </Text>
                    <Input>
                      <Button onClick={this.handleRemoveAccount}>
                        <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_no" defaultMessage="No" />
                      </CancelButton>
                    </Input>
                  </>
                  )}
                  {(deleteUser && !loading) && (
                  <>
                    <Text>
                      <FormattedMessage
                        id="admin_company_remove_user_confirmation_text"
                        defaultMessage="Are you sure you want to remove this user?"
                      />
                    </Text>
                    <Input>
                      <Button data-dismiss="modal" aria-label="Close" onClick={handleDelete}>
                        <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose}>
                        <FormattedMessage id="common_no" defaultMessage="No" />
                      </CancelButton>
                    </Input>
                  </>
                  )}
                  {(updateUserRole && !loading) && (
                  <>
                    <Text>
                      <FormattedMessage
                        id="admin_company_change_user_role_confirmation_text"
                        defaultMessage="Are you sure you want to change the user role?"
                      />
                    </Text>
                    <Input>
                      <Button onClick={handleUpdateUserRole} data-dismiss="modal" aria-label="Close">
                        <FormattedMessage id="common_yes_change" defaultMessage="Yes, change" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_no" defaultMessage="No" />
                      </CancelButton>
                    </Input>
                  </>
                  )}
                  {(assignOwner && !loading) && (
                  <>
                    <Input>
                      <Select
                        name="owner"
                        label={Constants.language.common_owner}
                        value={owner}
                        placeholder={<FormattedMessage id="common_none" defaultMessage="None" />}
                        errorMessage={
                          <FormattedMessage id="error_account_owner_message" defaultMessage="Select account owner" />
                          }
                        onChange={this.handleSelectChange}
                        options={assignOwnerOptions}
                      />
                    </Input>
                    <Input>
                      <Button onClick={this.handleAssignOwner}>
                        <FormattedMessage id="common_assign" defaultMessage="Assign" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                      </CancelButton>
                    </Input>
                  </>
                  )}
                  {(approve && !loading) && (
                  <>
                    <Text>
                      <FormattedMessage
                        id="approve_filter_confirmation_message"
                        defaultMessage="Are you sure you want to approve?"
                      />
                    </Text>
                    <Input>
                      <Button onClick={this.handleApproveFilter}>
                        <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_no" defaultMessage="No" />
                      </CancelButton>
                    </Input>
                  </>
                  )}

                  {(addUser && !loading) && (
                  <>
                    <Input>
                      <TextBox
                        type={Constants.input.type.text}
                        name={Constants.input.name.firstName}
                        value={firstName}
                        label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
                        onChange={this.handleChangeInviteUser}
                        errorMessage={
                          <FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />
                          }
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.text}
                        name={Constants.input.name.lastName}
                        value={lastName}
                        label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                        onChange={this.handleChangeInviteUser}
                        errorMessage={
                          <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                          }
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.email}
                        name={Constants.input.name.email}
                        label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                        value={email}
                        onChange={this.handleChangeInviteUser}
                        existError={emailError && isAddUserDisabled}
                        existErrorMessage={
                          <FormattedMessage id="error_email_use" defaultMessage="E-mail id already in use" />
                          }
                        errorMessage={
                          <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                          }
                        rule={{
                          method: validator.isEmail,
                          validWhen: true,
                        }}
                      />
                    </Input>
                    <Input>
                      <Select
                        label={<FormattedMessage id="common_role" defaultMessage="Role" />}
                        options={userOptions}
                        name={Constants.input.name.role}
                        value={Constants[role]}
                        placeholder={<FormattedMessage id="common_role" defaultMessage="Role" />}
                        onChange={this.handleInviteUserRoleChange}
                        search={false}
                        errorMessage={(
                          <FormattedMessage
                            id="error_select_role_message"
                            defaultMessage="Select user role"
                          />
                        )}
                      />
                    </Input>
                    <Input>
                      <Button
                        disabled={isAddUserDisabled}
                        onClick={this.handleSendInvitation}
                      >
                        <FormattedMessage id="common_add" defaultMessage="Add" />
                      </Button>
                      <CancelButton
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={ModalClose}
                        ref={(closeModal) => {
                          this.closeElement = closeModal;
                        }}
                      >
                        <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                      </CancelButton>
                    </Input>
                  </>
                  )}
                  {(EditUser && !loading) && (
                  <>
                    <Input>
                      <SubHeader2>
                        <FormattedMessage id="common_personal_number" defaultMessage="Personal ID number" />
                        :
                        {' '}
                        {personalNumber}
                      </SubHeader2>
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.text}
                        name={Constants.input.name.firstName}
                        value={firstName}
                        label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
                        onChange={this.handleChange}
                        errorMessage={
                          <FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />
                          }
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.text}
                        name={Constants.input.name.lastName}
                        value={lastName}
                        label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                        onChange={this.handleChange}
                        errorMessage={
                          <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                          }
                        rule={{
                          method: validator.isEmpty,
                          validWhen: false,
                        }}
                      />
                    </Input>
                    <Input>
                      <Select
                        label={<FormattedMessage id="common_role" defaultMessage="Role" />}
                        options={userOptions}
                        name={Constants.input.name.role}
                        value={Constants[role]}
                        placeholder={<FormattedMessage id="common_role" defaultMessage="Role" />}
                        onChange={this.handleUserRoleChange}
                        search={false}
                        errorMessage={(
                          <FormattedMessage
                            id="error_select_role_message"
                            defaultMessage="Select user role"
                          />
                        )}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.email}
                        name={Constants.input.name.email}
                        label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                        value={email}
                        onChange={this.handleChange}
                        existError={emailError && editUserFormValid.email_valid}
                        existErrorMessage={
                          <FormattedMessage id="error_email_use" defaultMessage="E-mail id already in use" />
                          }
                        errorMessage={
                          <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                          }
                        rule={{
                          method: validator.isEmail,
                          validWhen: true,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.email}
                        name={Constants.input.name.invoiceEmail}
                        label={<FormattedMessage id="label_email_for_invoices" defaultMessage="E-mail" />}
                        value={invoiceEmail}
                        onChange={this.handleChange}
                        errorMessage={
                          <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                          }
                        rule={{
                          method: validator.isEmail,
                          validWhen: true,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type="Text"
                        name="phone"
                        label={<FormattedMessage id="common_phone" defaultMessage="Phone" />}
                        value={phone}
                        onChange={this.handleChange}
                        errorMessage={(
                          <FormattedMessage
                            id="error_phone_message"
                            defaultMessage="Enter a valid phone number"
                          />
                        )}
                        rule={{
                          method: validator.matches,
                          validWhen: true,
                          args: [Constants.phoneNumberRegexPattern],
                          message: <FormattedMessage id="error_phone_message" defaultMessage="Enter a valid phone number" />,
                        }}
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type={Constants.input.type.text}
                        name={Constants.input.name.billingReference}
                        value={billingReference}
                        label={<FormattedMessage id="common_billing_reference" defaultMessage="Billing reference" />}
                        onChange={this.handleChange}
                        noEmptyError
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type="text"
                        name="address"
                        value={address}
                        label={<FormattedMessage id="common_billing_address" defaultMessage="Billing reference" />}
                        onChange={this.handleChange}
                        noEmptyError
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type="text"
                        name="city"
                        value={city}
                        label={<FormattedMessage id="common_city" defaultMessage="city" />}
                        onChange={this.handleChange}
                        noEmptyError
                      />
                    </Input>
                    <Input>
                      <TextBox
                        type="number"
                        name="zipcode"
                        value={zipcode}
                        label={<FormattedMessage id="common_zip_code" defaultMessage="Zip" />}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        existError={zipcodeError && !editUserFormValid.zipcode_is_Valid}
                        onBlur={this.handleBlur}
                        disabletickMark={!(zipcode && zipcode.length === Constants.zipcodeLength)}
                        existErrorMessage={<FormattedMessage id="error_zip_code_message" defaultMessage="Enter a valid ZIP code" />}
                        noEmptyError
                      />
                    </Input>
                    <Input>
                      <PasswordBox
                        type={inputTypes.text}
                        name={inputNames.newPassword}
                        label={<FormattedMessage id="common_new_password" defaultMessage="New password" />}
                        value={newPassword}
                        onChange={this.handleChange}
                        errorMessage={(
                          <FormattedMessage
                            id="error_password_doesnt_meet_requirements"
                            defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number. "
                          />
                                )}
                      />
                    </Input>
                    <Input>
                      <PasswordBox
                        type={inputTypes.text}
                        label={(
                          <FormattedMessage
                            id="customer_sign_up_repeat_password"
                            defaultMessage="Repeat password"
                          />
)}
                        name={inputNames.repeatPassword}
                        value={repeatPassword}
                        onChange={this.handleChange}
                        password={newPassword}
                        errorMessage={(
                          <FormattedMessage
                            id="error_password_doesnt_match"
                            defaultMessage="Passwords doesn't match"
                          />
)}
                      />
                    </Input>

                    <Input>
                      <Button
                        disabled={isDisabled}
                        onClick={this.handleEditUser}
                      >
                        <FormattedMessage id="common_update" defaultMessage="Update" />
                      </Button>
                      <CancelButton
                        onClick={ModalClose}
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={(closeModal) => {
                          this.closeElement = closeModal;
                        }}
                      >
                        <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                      </CancelButton>
                    </Input>
                  </>
                  )}

                  {(approveProfile && !loading) && (
                  <>
                    <Input>
                      <Text>
                        <FormattedMessage
                          id="admin_company_approve_confirmation_message"
                          defaultMessage="By approving, this profile will get published and you will become the account owner for
                          company. You can change that later."
                        />
                      </Text>
                    </Input>
                    <Input>
                      <Button onClick={this.handleapprove}>
                        <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                      </CancelButton>
                    </Input>
                  </>
                  )}

                  {(limitOverModal && !loading) && (
                  <>
                    <Input>
                      <Text>
                        <FormattedMessage
                          id="error_cant_buy_gigger"
                          defaultMessage="You have already reached the maximum limit for buying giggers this month."
                        />
                      </Text>
                      <Text className="mt-3">
                        <FormattedMessage
                          id="error_cant_buy_message"
                          defaultMessage="You have already reached the maximum limit for buying giggers this month."
                        />
                      </Text>
                    </Input>
                    <Input>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_close" defaultMessage="Close" />
                      </CancelButton>
                    </Input>
                  </>
                  )}

                  {(isLock && !loading) && (
                  <>
                    <Input>
                      <TextBox
                        type={Constants.input.type.number}
                        name={Constants.input.name.limit}
                        value={limit}
                        label={<FormattedMessage id="common_limit" defaultMessage="Limit" />}
                        onChange={this.handleChange}
                      />
                    </Input>
                    <Input>
                      <Button onClick={this.handleLockUsers}>
                        <FormattedMessage id="common_save" defaultMessage="Save" />
                      </Button>
                      <CancelButton data-dismiss="modal" aria-label="Close" onClick={ModalClose} ref={(closeModal) => { this.closeElement = closeModal; }}>
                        <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                      </CancelButton>
                    </Input>
                  </>
                  )}

                  {loading && <ModalLoader />}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Modal);
