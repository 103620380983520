import React from 'react';
import Constants from '../../shared/constants';
import { IconWrapper, Icon } from './giggers/styles';
import SearchInput from '../../components/Input/SearchInput';

const ToolBar = (props) => {
  const {
    searchbar,
    handleSearch,
    data = [],
    handleSearchOnChange,
    searchQuery,
    handleDownloadCsv,
    openSearchBar,
  } = props;
  return (
    <>
      <IconWrapper className="row m-0">
        {searchbar ? (
          <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={openSearchBar} />
        ) : (
          <div className="text-end col-auto p-0">
            <SearchInput
              onSearch={handleSearch}
              onChange={handleSearchOnChange}
              value={searchQuery}
            />
          </div>
        )}
        <Icon
          src={Constants.icons.ExportIcon}
          alt="icon"
          className={data.length > 0 ? 'mr-3' : 'disableOption mr-3'}
          onClick={handleDownloadCsv}
        />
      </IconWrapper>
    </>
  );
};
export default ToolBar;
