import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import px2vw from '../../styles/px2vw';
import { BackgroundImage } from '../../styles/Images';

export const FlexContainer = styled.div`
  background-color:${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image:${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)};
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    flex-direction: column;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    flex: 0 0 100%;
  }
`;
export const Container = styled(FlexContainer)`
  height: 100vh;
`;
export const SectionLeft = styled.div`
  margin: 0 0 0 auto;
@media (max-width: 1200px) {
  width: 940px;
  flex: 0 0 940px;
}
@media (max-width: 990px) {
  width: 688px;
  flex: 0 0 688px;
}
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 90%;
    flex: 0 0 90%;
    margin: auto;
  }
  @media (max-width: 570px) {
    width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    max-width: 90%;
    flex: 0 0 90%;
  }
`;
export const SectLeft = styled(SectionLeft)`
  flex-direction: column;
  @media (max-width: 570px) {
    width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 375px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;
export const SectionRight = styled.div`
  flex: ${({ flex }) => (flex || '0.64')};
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    display: none;
  }
  @media (max-width: 375px) {
    display: none;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: 100%;
`;
export const A = styled.a`
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: ${Colors.Primary};
  margin-top: ${px2vw(40, 1491)};
  text-align: right;
  flex: 1;
  margin-right: ${px2vw(24, 830)};
`;
export const Line = styled.u``;
export const Description = styled.p`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: ${Colors.Primary};
  letter-spacing: normal;
  padding: 0px;
  margin-bottom: ${px2vw(24, 1491)};
`;
export const LoginWrapper = styled.div`
  margin-top: ${({ marginTop }) => (marginTop || '40px')};
  @media (max-width: 768px) {
    width: ${px2vw(346, 375)};
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    margin-top: ${({ marginTop }) => (marginTop || '100px')};
  }
`;
export const ThankYouPageWrapper = styled.div`
  width: 68%;
  margin-left: 27%;
  margin-top: 105px;
  @media (max-width: 768px) {
    width: ${px2vw(346, 375)};
    margin-left: ${px2vw(15, 368)};
    align-self: center;
  }
  @media (max-width: 570px) {
    width: 92%;
    margin-left: 5%;
    margin-right: 5%;
    align-self: center;
  }
`;
export const SearchGiggerButton = styled.div`
  margin-right: 40px;
  margin-bottom: 16px;
  @media (max-width: 570px) {
    margin-right: 0px;
    margin-bottom: 16px;
  }
`;
export const Error = styled.div`
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #f03738;
  margin-top: 4px;
`;
export const BankIDPageWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100vh;
  margin-top: ${px2vw(103, 1491)};
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 100px;
    margin-top: 89px;
  }
  @media (max-width: 570px) {
    width: 100%;
  }
  @media (max-width: 375px) {
    width: 100%;
  }
`;
export const Wrapper = styled.div`
  width: ${(customWidth) => (customWidth ? '50.5%' : '35%')};
  @media (max-width: 768px) {
    width: ${px2vw(346, 375)};
  }
  @media (max-width: 570px) {
    width: ${px2vw(346, 375)};
  }
  @media (max-width: 375px) {
    width: ${px2vw(346, 375)};
  }
`;
export const Header = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size32}px;
  font-weight: ${(MainHeading) => (MainHeading ? '750' : '500')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  padding: 0px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-family: ${Font.fontFamily.AvenirBold};
    margin-bottom: 16px;
  }
`;
export const SubHeader = styled.h6`
  font-family: ${Font.fontFamily.AvenirBold};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  padding: 0px;
  margin-bottom: 16px;
`;
export const Input = styled.div`
  margin-bottom: 24px;
  padding: 0px;
  max-width: ${px2vw(380, 830)};
  @media (max-width: 768px) {
    max-width: ${px2vw(380, 768)};
  }
  @media (max-width: 570px) {
    max-width: ${px2vw(380, 570)};
  }
  @media (max-width: 375px) {
    max-width: ${px2vw(346, 375)};
  }
`;
export const SignInButton = styled.div`
  margin-bottom: ${px2vw(24, 1491)};
  padding: 0px;
  max-width: ${px2vw(380, 830)};
  @media (max-width: 768px) {
    text-align: center;
    max-width: ${px2vw(380, 768)};
    margin: 0 25% 0;
  }
  @media (max-width: 570px) {
    text-align: center;
    max-width: ${px2vw(380, 570)};
    margin: 0 13% 0;
  }
  @media (max-width: 375px) {
    text-align: center;
    max-width: ${px2vw(346, 375)};
    margin: 0;
  }
`;
export const Content = styled.div`
  margin-bottom: ${px2vw(32, 1491)};
  padding: 0px;
`;
export const SubTitle = styled.p`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  margin-bottom: ${px2vw(24, 1491)};
`;
export const TermsandConditions = styled(SubTitle)`
  text-align: center;
  font-size: ${Font.fontSize.Size14}px;
  line-height: ${({ policy }) => (policy ? '1.29' : '1.78')};
  color: ${Colors.LightViolet};
  margin-bottom: ${(props) => (props.policy ? '180px' : '16px')};
  @media (max-width: 768px) {
    margin-bottom: ${(props) => (props.policy ? '39px' : '16px')};
  }
`;
export const Link = styled.a`
  color: ${({ color }) => (color || Colors.LightViolet)};;
  cursor: pointer;
  &:hover,
  &:focus {
    color: inherit;
  }
`;
export const LoginLink = styled.a`
  color: ${Colors.Secondary};
`;
export const AgreeTerms = styled.div`
  color: ${Colors.LightBlue};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-family: ${Font.fontFamily.AvenirRegular};
  margin-bottom: 32px;
`;
export const UploadDocument = styled.div`
  height: 42px;
  border-style: dashed !important;
  border: solid 1px rgb(127, 125, 152, 0.5);
  text-align: center;
  color: ${Colors.Primary};
`;
export const Heading = styled.div`
  text-align: ${({ end }) => (end ? 'right' : 'left')};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Secondary};
  margin-bottom: 8px;
`;
export const Div = styled.div`
  padding: 0;
  margin: 0;
  @media (max-width: 768px) {
  }
  @media (max-width: 570px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
  @media (max-width: 375px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
`;
export const DragAndDrop = styled.input`
  display: none;
`;
export const CloseButton = styled.a`
  text-decoration: underline !important;
  font-size: ${Font.fontSize.Size16}px;
  color: ${Colors.Secondary};
`;
export const Invalid = styled.h6`
  color: #f03738;
`;
export const Instruction = styled.div`
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
`;
export const Text = styled.div`
  color: ${Colors.Secondary};
`;
export const ButtonWrapper = styled.div`
  width: 200px;
`;

export const UploadDocumentFileName = styled.a`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
`;

export const HowItWorksWrapper = styled.div`
  margin-bottom: 16px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;
export const ImageWrapper = styled.div`
  width:${({ width }) => (width || '100%')};
  height:${({ height }) => (height || '100%')};
  background-position:${({ backgroundPosition }) => (backgroundPosition || 'center center')};
  background-repeat:${({ backgroundRepeat }) => (backgroundRepeat || 'no-repeat')};
  background-size:${({ backgroundSize }) => (backgroundSize || 'cover !important')};
  position: ${({ position }) => (position || 'relative')};
  background-image: ${({ backgroundImage }) => (`url("${backgroundImage}")`)};
  transition: transform 0.5s ease;
`;
