import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';
import px2vw from '../../styles/px2vw';

export const Container = styled.div`
  width: 100%;
  align-items: center;
  background-color: ${Colors.White};
  padding: 29px;
  top: 0;
  z-index: 3;
  position: sticky;
`;
export const Heading = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size24}px;
  font-weight: ${(fontWeightBold) => (fontWeightBold ? '800' : '500')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Black};
  margin: 0px;
  @media (max-width: 768px) {
    font-size: ${({ fontSize }) => (fontSize && `${Font.fontSize.Size18}px`)};
  }
`;
export const AvtarIcon = styled.img`
  border-radius: 11.5px;
  height: 23px;
  width: 23px;
`;
export const UserName = styled.span`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
`;
export const TitleWrapper = styled.div``;
export const ProfileWrapper = styled.div`
  text-align: right;
  padding-right: ${px2vw(133, 1440)} !important;
  padding: 0px;
`;
export const DisplayOptions = styled.div`
  position: relative;
  z-index: 3;
`;
export const HeaderIcon = styled.img`
  align-self: center !important;
`;
export const IconDiv = styled.div`
  align-self: center !important;
`;
export const OptionWrapper = styled.div`
  top: 25;
`;
export const Status = styled.span`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Primary};
  margin-right: 32px;
  align-self: center;
`;
export const CheckBox = styled.span`
  width: 18px;
  height: 18px;
  border: 2px solid #27a660;
  display: flex;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  align-self: start;
  background-color: #27ae60;
  margin-top: 4px;
`;
