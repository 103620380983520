import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Config from '../../config';
import { Heading } from '../profile/styles';
import {
  FlexContainer,
  Container,
  SubHeader2,
  HeaderName,
  StarIcon,
  Input,
} from '../customer/styles';
import { RelativeContainer, Div } from '../../styles/CommonStyles';
import TextBox from '../../components/Input/TextBox';
import TextArea from '../../components/Input/TextArea';
import CustomCheckBox from '../../components/Input/CustomCheckbox';
import Button from '../../components/Button/Button';
import Constants from '../../shared/constants';
import { ReminderHeader } from '../../components/Card/styles';
import GiggerService from '../../services/AdminServices/GiggersService';
import { DefaultProfile } from '../../styles/Images';
import PrivacyPolicy from '../../components/RedirectLinks/PrivacyPolicy';
import Colors from '../../styles/Colors';

const FeedbackForm = () => {
  const history = useHistory();
  const { gigger_id: giggerId = '' } = useParams();
  const giggerService = new GiggerService(Config.apiBase);
  const [data, setData] = useState({});
  const [giggerDetails, setGiggerDetails] = useState({});
  const [isFormValid, setIsFormValid] = useState(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const { language, inputFields } = Constants;

  useEffect(() => {
    const fetchGigger = async () => {
      try {
        const { data: giggerData = [] } = await giggerService.getGiggerDetails(giggerId);
        setGiggerDetails(giggerData);
      } catch (e) {
        // catch the error
      }
    };
    fetchGigger();
  }, []);

  const handleRatingClick = (value) => () => {
    setData({ ...data, rating: value });
  };

  const validateForm = (values = {}) => {
    const {
      rating = '', feedback = '', firstName = '', lastName = '', companyName = '', termsAccepted = false,
    } = values;
    let error = false;
    const form = {
      rating: rating > 0,
      feedback: !validator.isEmpty(feedback),
      firstName: !validator.isEmpty(firstName),
      lastName: !validator.isEmpty(lastName),
      companyName: !validator.isEmpty(companyName),
      termsAccepted,
    };
    Object.keys(form).map((obj) => {
      if (form[obj] === false) {
        error = true;
      }
      return error;
    });

    setIsFormValid(error);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    validateForm({ ...data, [name]: value });
  };

  const handleTermsConditions = () => {
    setIsTermsAccepted(!isTermsAccepted);
    validateForm({ ...data, termsAccepted: !isTermsAccepted });
  };

  const handleSubmitFeedback = async () => {
    const {
      rating = '', feedback = '', firstName = '', lastName = '', companyName = '',
    } = data;
    const payload = {
      company_name: companyName,
      first_name: firstName,
      last_name: lastName,
      full_name: `${firstName} ${lastName}`,
      rating: String(rating),
      feedback,
    };
    try {
      await giggerService.updateGiggerFeedback(giggerId, payload);
      history.push(Constants.routes.thankYouForFeedback.url);
    } catch (e) {
      // catch error
    }
  };

  const {
    rating = '', feedback = '', firstName = '', lastName = '', companyName = '',
  } = data;
  const { input } = Constants;
  const { name: inputNames, type: inputTypes } = input;
  const { profile_pic: profilePic = '', full_name: fullName = '' } = giggerDetails;

  return (
    <RelativeContainer>
      <FlexContainer>
        <Container>
          <Heading>
            <FormattedMessage id="text_leave_feedback" defaultMessage="Leave a feedback" />
          </Heading>
          <SubHeader2>
            <FormattedMessage
              id="message_feedback"
              defaultMessage="Some sample text here, explaining what is happening"
              values={{ break: <br /> }}
            />
          </SubHeader2>
          <Input className="my-4">
            <Div className="my-3">
              <ReminderHeader>
                <FormattedMessage id="text_about_employee" defaultMessage="About the employee" />
              </ReminderHeader>
            </Div>
            <Div className="bg-white d-flex">
              <div
                className="image-crop-no-image"
                style={{
                  backgroundImage: `url("${profilePic || DefaultProfile}")`,
                  backgroundSize: 'cover !important',
                  width: '80px',
                  height: '80px',
                }}
              />
              <Div className="p-2">
                <ReminderHeader>{fullName}</ReminderHeader>
              </Div>
            </Div>
            <HeaderName>
              <FormattedMessage id="text_rate_gigger" defaultMessage="Rate gigger *" />
            </HeaderName>
            <Div className="mb-3">
              {[...Array(5)].map((a, i) => (
                <StarIcon
                  src={rating >= i + 1
                    ? Constants.icons.Star : Constants.icons.GreyBorderStar}
                  alt="icon"
                  className="mr-2"
                  role="button"
                  onClick={handleRatingClick(i + 1)}
                />
              ))}
            </Div>
            <TextArea
              name={inputFields.feedback}
              value={feedback}
              label={`${language.text_give_feedback}`}
              onChange={handleChange}
              rule={{
                method: validator.isEmpty,
                validWhen: false,
                message: <FormattedMessage id="error_feedback_message" defaultMessage="Enter your feedback here" />,
              }}
            />
            <Div className="my-3">
              <ReminderHeader>
                <FormattedMessage id="text_about_you" defaultMessage="About you" />
              </ReminderHeader>
            </Div>
            <TextBox
              type="text"
              name={inputFields.firstName}
              value={firstName}
              label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
              onChange={handleChange}
              rule={{
                method: validator.isEmpty,
                validWhen: false,
                message: <FormattedMessage id="error_first_name" defaultMessage="Enter your first name" />,
              }}
            />
            <Div className="my-3">
              <TextBox
                type="text"
                name={inputFields.lastName}
                value={lastName}
                label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                onChange={handleChange}
                rule={{
                  method: validator.isEmpty,
                  validWhen: false,
                  message: <FormattedMessage id="error_last_name" defaultMessage="Enter your last name" />,
                }}
              />
            </Div>
            <HeaderName>
              <FormattedMessage id="common_company_name" defaultMessage="Company name" />
            </HeaderName>
            <HeaderName stromGreyColor className="mt-0">
              <FormattedMessage id="company_name_info" defaultMessage="Will not appear in the consultant's profile" />
            </HeaderName>
            <TextBox
              type={inputTypes.text}
              name={inputNames.companyName}
              value={companyName}
              onChange={handleChange}
              rule={{
                method: validator.isEmpty,
                validWhen: false,
                message: <FormattedMessage id="error_company_name_message" defaultMessage="Enter name of company" />,
              }}
            />
            <Div className="my-3">
              <CustomCheckBox
                isSelected={isTermsAccepted}
                onClick={handleTermsConditions}
                label={(
                  <FormattedMessage
                    id="terms_gigger_feedback"
                    values={{
                      privacyLink: <PrivacyPolicy color={Colors.Primary} />,
                    }}
                    defaultMessage="I agree that my personal data is processed in accordance with the current and upcoming privacy policy. Read more."
                  />
)}
              />
            </Div>
            <Button
              disabled={(isFormValid)}
              name={<FormattedMessage id="common_send" defaultMessage="Send" />}
              type="update"
              onClick={handleSubmitFeedback}
              className="w-auto"
            />
          </Input>
        </Container>
      </FlexContainer>
    </RelativeContainer>
  );
};

export default FeedbackForm;
