import styled from 'styled-components';
import Colors from '../../../styles/Colors';

export const FeedbackListWrapper = styled.div`
  max-width: 1015px;
  background-color: ${Colors.White};
  padding: 12px 16px 1px 16px;
  height: auto;
`;
export const ButtonWrapper = styled.div`
  min-width: 95px;
  height: 24px;
`;
