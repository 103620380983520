import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import {
  FlexContainer,
  Container,
  CardWrapper,
  Content,
  StarIcon,
  CurrentPage,
  Header,
  OrderData,
  SubHeader2,
} from './styles';
import Constants from '../../shared/constants';
import TextArea from '../../components/Input/TextArea';
import { Instruction, Line } from '../SignUp/styles';
import CustomCheckBox from '../../components/Input/CustomCheckbox';
import Button from '../../components/Button/Button';
import CustomerService from '../../services/CustomerService';
import Config from '../../config';
import TermsAndConditions from '../../components/RedirectLinks/TermsAndConditions';
import Colors from '../../styles/Colors';
import PrivacyPolicy from '../../components/RedirectLinks/PrivacyPolicy';
import AuthService from '../../services/AuthService';
import Loader from '../../components/Loader/Loader';
import Toast, { MainContext } from '../../components/Toast/Toast';
import { RelativeContainer } from '../../styles/CommonStyles';

class FeedbackForm extends Component {
  customerService = new CustomerService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      isSelected: true,
      isSubmitted: false,
      rating: 0,
      feedback: '',
      giggerId: props.match.params.giggerId,
      userId: props.match.params.userId,
      disabled: true,
      showStartDateField: false,
      notHired: false,
      formValid: {
        rating_isValid: false,
        feedback_is_Valid: false,
      },
      redirect: true,
      loading: false,
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
    };
  }

  async componentDidMount() {
    const { location = {} } = this.props;
    const { taketoThankYouPage = false } = location;
    this.setState({ isSubmitted: taketoThankYouPage, notHired: taketoThankYouPage, loading: true });
    window.scrollTo(0, 0);
    const {
      match: { path },
    } = this.props;
    if (path === Constants.routes.hiredDates.url) {
      this.setState({
        isSelected: false,
        showStartDateField: true,
        formValid: {
          rating_isValid: true,
          feedback_is_Valid: true,
        },
      });
    }
    const myGiggers = await this.fetchMyGiggers();
    const redirect = await this.isGiggerPresent(myGiggers);
    this.setState({ redirect, loading: false });
  }

  /**
   * @async
   * @function fetchMyGiggers fetches the users purchased giggers.
   */
  fetchMyGiggers = async () => {
    try {
      const response = await this.customerService.myGiggers();
      const { data = [] } = response;
      const purchasedGiggers = (data && data[Constants.purchasedGiggers]) || [];
      return purchasedGiggers;
    } catch (e) {
      return [];
    }
  };

  /**
   * Checks whether the user is in purchased giggers list or not
   * @param {object} myGiggers users purchased giggers
   */
  isGiggerPresent = async (myGiggers) => {
    let redirect = true;
    const pathName = window.location.pathname;
    const userId = pathName.substring(pathName.lastIndexOf('-') + 1);
    redirect = myGiggers.find((obj) => obj.id === userId);
    return redirect;
  };

  /**
   * Redirects to my giggers page
   */
  handleMyGiggersPage = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyGiggers.url);
  };

  /**
   * Handles change in input value
   * @param {event} event
   */
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validate);
  };

  /**
   * Submitts the feedback to gigger by the user.
   */
  handleSubmitFeedback = async () => {
    this.setState({ loading: true });
    const { toastData } = this.state;
    const {
      rating, feedback, giggerId, userId, showStartDateField,
    } = this.state;
    const { history } = this.props;
    const data = {
      user_id: userId,
      gigger_id: giggerId,
      rating,
      feedback,
    };
    try {
      let response;
      if (showStartDateField) {
        response = await this.customerService.createFeedback(data);
        if (response) {
          history.push(Constants.routes.thankYouForHiring.url);
        }
      } else {
        response = await this.customerService.sendFeedback(data);
        if (response) {
          this.setState({ isSubmitted: true, loading: false });
        }
      }
      window.scrollTo(0, 0);
    } catch (e) {
      toastData.type = Constants.toast.ERROR;
      toastData.message = Constants.language.toast_try_after_time;
      this.setState({
        loading: false,
        toastData,
        showToast: true,
        isSubmitted: false,
      });
    }
  };

  /**
   * Submits the feedback to company by the user.
   */
   handleSubmitFeedbackpage = async () => {
     this.setState({ loading: true });
     const { toastData } = this.state;
     const {
       rating, feedback, giggerId, userId, showStartDateField,
     } = this.state;
     const { history } = this.props;
     const data = {
       user_id: userId,
       gigger_id: giggerId,
       rating,
       feedback,
     };
     try {
       let response;
       if (showStartDateField) {
         response = await this.customerService.createFeedback(data);
         if (response) {
           history.push(Constants.routes.CompanyFeedbackMessage.url);
         }
       } else {
         response = await this.customerService.sendFeedback(data);
         if (response) {
           this.setState({ isSubmitted: true, loading: false });
         }
       }
       window.scrollTo(0, 0);
     } catch (e) {
       toastData.type = Constants.toast.ERROR;
       toastData.message = Constants.language.toast_try_after_time;
       this.setState({
         loading: false,
         toastData,
         showToast: true,
         isSubmitted: false,
       });
     }
   };

  /**
   * Handles change in select type of input fields
   * @param {string} name Name of the input
   * @param {string} label Label of the input
   * @param {string} value Value of the input
   */
  handleDataChange = (name, label, value) => {
    this.setState({ [name]: value }, this.validate);
  };

  /**
   * Redirects to my account page
   */
  handleBackToMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.customerMyAccount.url);
  };

  /**
   * Checks agree terms and conditions box
   */
  handleAgreeTermsCheckbox = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected }, this.validate);
  };

  /**
   * Handles change in rating
   * @param {number} rating Rating to gigger.
   */
  handleRatingClick = (rating) => () => {
    this.setState({ rating }, this.validate);
  };

  /**
   * Closes the toast
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   * Validates the form
   */
  validate() {
    const {
      formValid, rating, feedback, showStartDateField,
    } = this.state;
    let error = false;
    if (!validator.isEmpty(feedback) || showStartDateField) {
      formValid.feedback_is_Valid = true;
    } else {
      formValid.feedback_is_Valid = false;
    }
    if (rating > 0 || showStartDateField) {
      formValid.rating_isValid = true;
    } else {
      formValid.rating_isValid = false;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ disabled: error });
  }

  render() {
    const {
      isSelected,
      isSubmitted,
      rating,
      disabled,
      feedback,
      showStartDateField,
      notHired,
      redirect,
      loading,
      showToast,
      toastData,
    } = this.state;

    const { location } = this.props;
    const pathName = window.location.pathname;
    const id = pathName.substring(pathName.lastIndexOf('/') + 1);
    const userId = id && id.split('-')[0];
    const user = JSON.parse(localStorage.getItem('userData'));

    if (!this.authService.isAuthenticated()) {
      return (
        <Redirect
          to={{
            pathname: Constants.routes.logIn.url,
            redirectTo: location.pathname,
          }}
        />
      );
    }

    if ((user && userId !== user._id) || !redirect) {
      return (
        <Redirect
          to={{
            pathname: Constants.routes.notFound.url,
            redirectTo: location.pathname,
          }}
        />
      );
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <FlexContainer>
            <Container>
              <CardWrapper>
                {isSubmitted ? (
                  <>
                    <Header>
                      {notHired ? (
                        <FormattedMessage
                          id="company_feedback_not_hired"
                          defaultMessage="How sad that the candidate did not match your requirements profile."
                        />
                      ) : (
                        <FormattedMessage
                          id="company_feedback_thank_you_for_feedback"
                          defaultMessage="Thank you for your feedback!"
                        />
                      )}
                    </Header>
                    <SubHeader2>
                      {notHired && (
                        <FormattedMessage
                          id="company_not_hired_message"
                          defaultMessage="We're sorry to hear that you didn't move forward with the candidate. Let us know if there is something we can do better.
More consultants sign up every week. Do a new search and hopefully the next candidate will meet your needs."
                          values={{ break: <br /> }}
                        />
                      )}
                    </SubHeader2>
                    <SubHeader2 onClick={this.handleBackToMyAccount} role="button" className="mt-3">
                      <Line>
                        <FormattedMessage
                          id="company_feedback_back_to_my_account"
                          defaultMessage="Back to My account"
                        />
                      </Line>
                    </SubHeader2>
                  </>
                ) : (
                  <>
                    {loading && <Loader width="60px" />}
                    {!loading && (
                      <CurrentPage role="button" onClick={this.handleMyGiggersPage}>
                        <img src={Constants.icons.ArrowLeft} alt="icon" />
                        <Line>
                          <FormattedMessage id="common_back" defaultMessage="Back" />
                        </Line>
                      </CurrentPage>
                    )}
                    {loading && <Loader />}
                    {!loading && (
                      <Header>
                        {showStartDateField ? (
                          <FormattedMessage
                            id="company_hired_feedback_heading"
                            defaultMessage="We are pleased that you have found a consultant who matches your requirements profile"
                          />
                        ) : (
                          <FormattedMessage id="company_feedback_comment" defaultMessage="Your feedback means a lot" />
                        )}
                      </Header>
                    )}
                    {loading && <Loader height="60px" />}
                    {!loading && (
                      <>
                        <SubHeader2>
                          {showStartDateField ? (
                            <FormattedMessage
                              id="company_thank_Hired"
                              defaultMessage="Thank you for your feedback!"
                              values={{
                                break: <br />,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id="company_feedback_info_regarding_contacting_back"
                              defaultMessage="Please fill in this form so we can do something and someone from our team will contact you. And something about finders fee"
                            />
                          )}
                        </SubHeader2>
                      </>
                    )}
                    {!showStartDateField && (
                      <>
                        <Content className="row m-0 mt-4">
                          {loading && <Loader />}
                          {!loading && (
                            <OrderData>
                              <FormattedMessage id="company_feedback_rate_gigger" defaultMessage="Rate gigger" />
                            </OrderData>
                          )}
                        </Content>
                        <Content className="row m-0 mt-3">
                          {loading && <Loader />}
                          {!loading && (
                            <>
                              <StarIcon
                                src={rating >= 1 ? Constants.icons.Star
                                  : Constants.icons.GreyBorderStar}
                                alt="icon"
                                className="mr-2"
                                onClick={this.handleRatingClick(1)}
                              />
                              <StarIcon
                                src={rating >= 2 ? Constants.icons.Star
                                  : Constants.icons.GreyBorderStar}
                                alt="icon"
                                className="mr-2"
                                onClick={this.handleRatingClick(2)}
                              />
                              <StarIcon
                                src={rating >= 3 ? Constants.icons.Star
                                  : Constants.icons.GreyBorderStar}
                                alt="icon"
                                className="mr-2"
                                onClick={this.handleRatingClick(3)}
                              />
                              <StarIcon
                                src={rating >= 4 ? Constants.icons.Star
                                  : Constants.icons.GreyBorderStar}
                                alt="icon"
                                className="mr-2"
                                onClick={this.handleRatingClick(4)}
                              />
                              <StarIcon
                                src={rating >= 5 ? Constants.icons.Star
                                  : Constants.icons.GreyBorderStar}
                                alt="icon"
                                onClick={this.handleRatingClick(5)}
                              />
                            </>
                          )}
                        </Content>
                        <Content className="row m-0 mt-4">
                          {loading && <Loader />}
                          {!loading && (
                            <>
                              <OrderData>
                                <FormattedMessage id="feedback_form_give_feedback" defaultMessage="Give feedback" />
                              </OrderData>
                              <TextArea
                                name="feedback"
                                value={feedback}
                                onChange={this.handleChange}
                                errorMessage={(
                                  <FormattedMessage
                                    id="error_feedback_message"
                                    defaultMessage="Enter your feedback here"
                                  />
                                )}
                                rule={{
                                  method: validator.isEmpty,
                                  validWhen: false,
                                }}
                              />
                            </>
                          )}
                        </Content>
                        <Content className="col-xl-3 my-4 col-md-4 col-xs-12 p-0">
                          {loading && <Loader />}
                          {!loading && (
                          <Button
                            name={<FormattedMessage id="common_send" defaultMessage="Send" />}
                            type="update"
                            onClick={this.handleSubmitFeedbackpage}
                            disabled={disabled}
                          />
                          )}
                        </Content>
                      </>
                    )}
                    {' '}
                    {showStartDateField && (
                      <>
                        <Instruction className="row my-4" onClick={this.handleAgreeTermsCheckbox}>
                          <Content className="col-auto  p-0">
                            {loading && <Loader />}
                            {!loading && (
                            <CustomCheckBox
                              onClick={this.handleAgreeTermsCheckbox}
                              isSelected={isSelected}
                              type="checkbox"
                              label={(
                                <>
                                  <Content>
                                    <FormattedMessage
                                      id="feedback_form_approve_message_1"
                                      defaultMessage="I approve general "
                                    />
                                    {' '}
                                    <TermsAndConditions color={Colors.Primary} />
                                    {' '}
                                    <FormattedMessage
                                      id="feedback_form_approve_message_2"
                                      defaultMessage=" and that my personal data is processed according to GigAssembly's "
                                    />
                                    {' '}
                                    <PrivacyPolicy color={Colors.Primary} />
                                    {' '}
                                  </Content>
                                </>
                                )}
                            />
                            )}
                          </Content>
                        </Instruction>
                        <Content className="col-xl-3 my-4 col-md-4 col-xs-12 p-0">
                          {loading && <Loader />}
                          {!loading && (
                          <Button
                            name={<FormattedMessage id="common_send" defaultMessage="Send" />}
                            type="update"
                            onClick={this.handleSubmitFeedback}
                            disabled={disabled}
                          />
                          )}
                        </Content>
                      </>
                    )}
                  </>
                )}
              </CardWrapper>
            </Container>
          </FlexContainer>
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default FeedbackForm;
