import ApiService from '../ApiService';
import GiggerProfile from '../../models/GiggerProfile';

class GiggersService extends ApiService {
  endPoint = {
    giggerList: 'admin/giggers',
    removeDoc: 'file/remove/docs',
    updateCVStatus: 'cv/update-status',
    gigger: 'gigger',
    giggers: 'giggers',
    requestFeedback: 'request-company-feedback',
  };

  async getGiggerList(queryParam) {
    const response = await this.get(this.baseURL + this.endPoint.giggerList
      + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getGiggerProfile(id, parseData = true) {
    const response = await this.get(`${this.baseURL + this.endPoint.giggerList}/${id}/profile`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      if (parseData) {
        const { data: { data = {} } = {} } = response;
        const profile = GiggerProfile.fromAdminProfileJson(data);
        return profile;
      }
      return response.data;
    }
  }

  async getGiggerOrders(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.giggerList}/${id}/orders${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getGiggerDocs(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.giggerList}/${id}/docs${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getGiggerFeedback(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.giggerList}/${id}/feedback${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeGigger(id) {
    const response = await this.delete(`${this.baseURL + this.endPoint.giggerList}/${id}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveGigger(id, data) {
    const response = await this.post(`${this.baseURL + this.endPoint.giggerList}/${id}/approve`, data);
    if (response.status === 200 || response.status === 204) {
      return response.data;
    }
    throw Error(response.statusText);
  }

  async updateGigger(id, data) {
    const response = await this.put(`${this.baseURL + this.endPoint.giggerList}/${id}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeDoc(fileId) {
    const response = await this.post(this.baseURL + this.endPoint.removeDoc, fileId);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async updateCvStatus(id, data) {
    const response = await this.put(`${this.baseURL + this.endPoint.gigger}/${id}/${this.endPoint.updateCVStatus}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async updateGiggerDetails(id, payload) {
    return this.put(`${this.baseURL}${this.endPoint.giggers}/${id}`, payload).then((response) => response.data);
  }

  async updateGiggerFeedback(id, payload) {
    const response = await this.post(`${this.baseURL}${this.endPoint.gigger}/${id}/${this.endPoint.requestFeedback}`, payload);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  async getGiggerDetails(id) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.giggers}/${id}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }
}
export default GiggersService;
