import React, { Component } from 'react';
import validator from 'validator';
import Luhn from 'luhn';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import { GiggerSignUpImage } from '../../styles/Images';
import TextBox from '../../components/Input/TextBox';
import Button from '../../components/Button/Button';
import CustomCheckBox from '../../components/Input/CustomCheckbox';
import SignUpService from '../../services/SignUpService';
import Config from '../../config';
import PasswordBox from '../../components/Input/PasswordBox';
import {
  FlexContainer,
  Instruction,
  LoginWrapper,
  Description,
  Header,
  SubHeader,
  Input,
  Content,
  TermsandConditions,
  Wrapper,
  Line,
  Link,
  LoginLink,
  AgreeTerms,
  Heading,
  SignInButton,
  ButtonWrapper,
  ImageWrapper,
} from './styles';
import Document from '../../components/Document';
import GiggerModel from '../../models/Gigger';
import Loader from '../../components/Loader/Loader';
import ProfilePageService from '../../services/ProfilePageService';
import {
  addHyphenToNumber, isCustomer, isGigger,
} from '../../shared/utils';
import PrivacyPolicy from '../../components/RedirectLinks/PrivacyPolicy';
import TermsAndConditionsLink from '../../components/RedirectLinks/TermsAndConditions';
import { TextDiv } from '../../components/Input/styles';
import { SectionLeft, SectionRight } from '../login/styles';

const taiPasswordStrength = require('tai-password-strength');

class GiggerSignUp extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  signUpService = new SignUpService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      withoutBankID: true,
      isDisabled: true,
      orgNumberError: false,
      PersonalNumError: false,
      emailError: false,
      phoneNumError: false,
      loading: false,
      orgNumber: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      repeatPassword: '',
      personalNumber: '',
      formValid: {
        email_isValid: false,
        phoneNumber_isValid: false,
        orgNumber_isValid: false,
        personalNo_isValid: false,
        password_isValid: false,
        repeatPassword_isValid: false,
        firstName_isValid: false,
        lastName_isValid: false,
        agreeTerms_isValid: false,
      },
      isSelected: false,
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
      taxDoc: '',
      fileData: {},
      isInvoiceWithThirdPartyChecked: false,
    };
  }

  /**
   * Removes localstorage when redirected from wp
   */
  componentDidMount() {
    const {
      location: { pathname, data },
    } = this.props;
    if (pathname === Constants.url.gigger) this.handleWithoutBankID();
    if (data) {
      const { formValid } = this.state;
      let { orgNumber = '' } = data;
      const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        password = '',
        repeatPassword = '',
        personalNumber = '',
        withoutBankID = true,
        tax_doc: taxDoc = '',
        fileData = {},
        isInvoiceWithThirdPartyChecked = false,
      } = data;
      formValid.orgNumber_isValid = isInvoiceWithThirdPartyChecked
        ? true
        : orgNumber.length === Constants.OrganisationNumberLength;
      orgNumber = orgNumber === null && orgNumber;
      this.setState({
        orgNumber,
        firstName,
        lastName,
        email,
        phone,
        password,
        repeatPassword,
        personalNumber,
        withoutBankID,
        taxDoc,
        fileData,
        isInvoiceWithThirdPartyChecked,
        formValid,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;

    const {
      location: { pathname: prevPathname },
    } = prevProps;

    if (pathname !== prevPathname) {
      this.handleWithoutBankID();
    }
  }

  /**
   * Redirects to SignUp without BankId screen
   *  */

    handleRedirectToWithoutBankIdPage = () => {
      const { history } = this.props;
      history.push({ pathname: Constants.routes.giggerSignUpWithOutBankId.url });
    };

  handleWithoutBankID = () => {
    const { withoutBankID } = this.state;
    this.setState({
      withoutBankID: !withoutBankID,
      isDisabled: true,
    });
    window.scrollTo(0, 0);
  };

  /**
   * Handles change in value
   * @param {*} event
   */

  handleChange = (event) => {
    const { name = '', value = '' } = event.target;
    let { phone } = this.state;

    if (name === Constants.phone
      && validator.matches(value.toString(), Constants.phoneNumberRegexPattern)) {
      phone = value;
    }
    this.setState({ [name]: value, phone }, this.validate);
  };

  /**
   * Handles change in value if error in Coperate ID,email and personal number
   * @param {*} event
   */

  handleErrorChange = (event) => {
    let {
      emailError, PersonalNumError, orgNumberError, phoneNumError,
    } = this.state;
    const { name } = event.target;
    const { value } = event.target;
    if (name === Constants.email) {
      emailError = false;
    }
    if (name === Constants.personalNumber) {
      PersonalNumError = false;
    }
    if (name === Constants.organisationNumber) {
      orgNumberError = false;
    }
    if (name === Constants.phone) {
      phoneNumError = false;
    }
    this.setState(
      {
        [name]: value,
        emailError,
        PersonalNumError,
        orgNumberError,
        phoneNumError,
      },
      this.validate,
    );
  };

  /**
   * Restricts alphabets in Personal number and Organisation number
   * @param {*} evt
   */

  handleKeyPress = (evt) => {
    const theEvent = evt || window.event;
    // let key;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    // Handle paste

    // if (theEvent.type === 'paste') {
    //   key = evt.clipboardData.getData('text/plain');
    // } else {
    //   // Handle key press
    //   key = theEvent.keyCode || theEvent.which;
    //   key = String.fromCharCode(key);
    // }
    // const regex = /[0-9 -]|\./;
    // if (!regex.test(key)) {
    //   theEvent.returnValue = false;
    //   if (theEvent.preventDefault) theEvent.preventDefault();
    // }
    if (
      name === Constants.organisationNumber
      && value.replace(/-/g, '').length >= Constants.OrganisationNumberLength - 1
    ) {
      theEvent.preventDefault();
      this.setState({ [name]: addHyphenToNumber(value, 6) }, this.validate);
    }
    if (name === Constants.personalNumber && value.replace(/-/g, '').length >= Constants.PersonalNumberLength - 1) {
      theEvent.preventDefault();
      this.setState({ [name]: addHyphenToNumber(value, 8) }, this.validate);
    }
    if (name === Constants.phone && !validator.matches(value.toString(),
      Constants.phoneNumberRegexPattern)) {
      theEvent.preventDefault();
      this.setState({ [name]: value }, this.validate);
    }
  };

  /**
   * Handles change in Password
   * @param {*} event
   */

  handlePasswordChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        showRequirementsDoesntMatch: false,
      },
      this.validate,
    );
  };

  /**
   * Handles change in repeat password
   * @param {*} event
   */

  handleRepeatPasswordChange = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        showPwdDoesntMatch: false,
      },
      this.validate,
    );
  };

  /**
   * Validates Password as per its requirements
   * @example Test@123
   * @param {*} event
   */

  handlePasswordBlur = () => {
    const { password, repeatPassword } = this.state;
    let requirementsDoesntMatch = false;
    let passwordsDoesntMatch = false;
    if (!validator.isEmpty(password)) {
      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(password);
      requirementsDoesntMatch = validation
        && validation.charsets
        && (validation.charsets.symbol || validation.charsets.punctuation)
        && validation.charsets.lower
        && validation.charsets.upper
        && validation.charsets.number
        && validation.passwordLength >= 8;

      if (!validator.isEmpty(repeatPassword)) {
        passwordsDoesntMatch = password === repeatPassword;
        this.setState({ showPwdDoesntMatch: !passwordsDoesntMatch });
      }
      this.setState({
        showRequirementsDoesntMatch: !requirementsDoesntMatch,
      });
    }
  };

  /**
   * Confirms Password and repeat password are same or not
   *
   */

  handleRepeatPwdBlur = () => {
    let { DoesntMatch } = false;
    const { password, repeatPassword } = this.state;
    if (!validator.isEmpty(password) && !validator.isEmpty(repeatPassword)) {
      DoesntMatch = password === repeatPassword;
      this.setState({ showPwdDoesntMatch: !DoesntMatch });
    }
  };

  /**
   * Redirects to login page
   */

  handleLogin = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * Redirects to Customer SignUp page
   */

  handleCustomerSignUp = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.customerSignUpWithOutBankId.url });
  };

  /**
   * Check the box if ready to agree terms and conditions
   */

  handleAgreeTermsCheckbox = () => {
    const { isSelected } = this.state;
    this.setState({ isSelected: !isSelected }, this.validate);
  };

  /**
   * Removes document
   */

  handleRemoveDocument = () => {
    this.setState({ taxDoc: '', fileData: {} });
  };

  /**
   * Uploads document
   * @param {*} file
   */

  handleDocumentChange = (file) => {
    this.setState({
      taxDoc: file.data._id || '',
      fileData: file.data,
    });
  };

  /**
   * Creates Gigger account
   */

  handleSignInGigger = async () => {
    const {
      fileData,
      orgNumber,
      firstName,
      lastName,
      email,
      phone,
      password,
      repeatPassword,
      personalNumber,
      withoutBankID,
      taxDoc,
      isInvoiceWithThirdPartyChecked,
    } = this.state;
    this.setState({
      loading: true,
      emailError: false,
      PersonalNumError: false,
      orgNumberError: false,
      phoneNumError: false,
    });
    const data = {
      orgNumber: isInvoiceWithThirdPartyChecked ? null : orgNumber,
      firstName,
      lastName,
      email: email.toLowerCase(),
      phone,
      password,
      repeatPassword,
      personalNumber,
      withoutBankID,
      taxDoc,
      isInvoiceWithThirdPartyChecked,
    };
    const { history } = this.props;
    this.giggerModel = new GiggerModel({});
    try {
      const response = await this.signUpService.giggerSignUpService(this.giggerModel.toJson(data));
      if (withoutBankID) {
        if (isCustomer()) {
          history.push(Constants.routes.customerMyAccount.url);
        }
        if (isGigger()) {
          history.push(Constants.routes.giggerMyAccount.url);
        }
      } else {
        data.fileData = fileData;
        const bankidData = { personal_number: personalNumber, orderRef: response.success.orderRef };
        history.push({
          pathname: Constants.routes.bankIdAuthendicate.url,
          bankidData,
          data,
          redirectTo: 'Gigger',
        });
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'signup_complete' });
    } catch (error) {
      const { response = {} } = error;
      const { data: errorData = {} } = response;
      const { errors: inputError = {} } = errorData;
      if (inputError && inputError.email) {
        this.setState({ loading: false, emailError: true, isDisabled: true });
      }
      if (inputError && inputError.organization_number) {
        this.setState({ loading: false, orgNumberError: true, isDisabled: true });
      }
      if (inputError && inputError.personal_number) {
        this.setState({ loading: false, PersonalNumError: true, isDisabled: true });
      }
      if (inputError && inputError.phone) {
        this.setState({ loading: false, phoneNumError: true, isDisabled: true });
      }
    }
  };

  /**
   * Handler to toggle 'Invoice with third party' checkbox
   */
  toggleInvoiceWithThirdParty = () => {
    const { isInvoiceWithThirdPartyChecked } = this.state;
    this.setState(
      {
        isInvoiceWithThirdPartyChecked: !isInvoiceWithThirdPartyChecked,
      },
      this.validate,
    );
  };

  /**
   * Validates all the input fields present in the form

   * @constant orgNumber {number} Corporate ID number of the User
     @constant companyName {string} Company Name of the User
     @constant firstName {string} First Name of the User
     @constant lastName {string} Last Name of the User
     @constant email {email} E-mail ID of the User
     @constant phone {number} Phone Number of the User
     @constant password {password} Password of the User
     @constant repeatPassword {password} Repeat Password of the User
     @constant personalNumber {number} Personal number of the User

   */

  validate() {
    const {
      withoutBankID,
      formValid,
      orgNumber,
      firstName,
      lastName,
      email,
      phone,
      password,
      repeatPassword,
      personalNumber,
      isSelected,
      emailError,
      PersonalNumError,
      orgNumberError,
      isInvoiceWithThirdPartyChecked,
    } = this.state;

    let error = false;
    const passwordStrength = new taiPasswordStrength.PasswordStrength();
    const validation = passwordStrength.check(password);

    const doesntMatch = password === repeatPassword;
    const doesntMeetRequirements = validation
      && validation.charsets
      && (validation.charsets.symbol || validation.charsets.punctuation)
      && validation.charsets.lower
      && validation.charsets.upper
      && validation.charsets.number
      && validation.passwordLength >= 8;

    if (doesntMatch && doesntMeetRequirements) {
      formValid.password_isValid = true;
      formValid.repeatPassword_isValid = true;
    } else {
      formValid.password_isValid = false;
      formValid.repeatPassword_isValid = false;
    }

    if (validator.isEmail(email) && !emailError) {
      formValid.email_isValid = true;
    } else {
      formValid.email_isValid = false;
    }
    formValid.phoneNumber_isValid = validator.matches(phone.toString(),
      Constants.phoneNumberRegexPattern);
    if (
      validator.isLength(personalNumber.toString(), {
        min: Constants.PersonalNumberLength,
        max: Constants.PersonalNumberLength,
      })
      && !PersonalNumError
    ) {
      formValid.personalNo_isValid = true;
    } else {
      formValid.personalNo_isValid = false;
    }
    if (validator.isEmpty(firstName) && withoutBankID) {
      formValid.firstName_isValid = false;
    } else {
      formValid.firstName_isValid = true;
    }
    if (validator.isEmpty(lastName) && withoutBankID) {
      formValid.lastName_isValid = false;
    } else {
      formValid.lastName_isValid = true;
    }

    // Organisation number validation using Luhn algorithm
    if (
      (validator.isLength(orgNumber.toString(), {
        min: Constants.OrganisationNumberLength,
        max: Constants.OrganisationNumberLength,
      })
        && !orgNumberError)
      || isInvoiceWithThirdPartyChecked
    ) {
      const number = orgNumber.replace('-', '');
      formValid.orgNumber_isValid = Luhn.validate(number);
    } else {
      formValid.orgNumber_isValid = false;
    }
    if (!isSelected) {
      formValid.agreeTerms_isValid = false;
    } else {
      formValid.agreeTerms_isValid = true;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
    return { error, formValid };
  }

  render() {
    const {
      withoutBankID,
      isDisabled,
      isSelected,
      loading,
      showPwdDoesntMatch,
      showRequirementsDoesntMatch,
      fileData,
      PersonalNumError,
      orgNumberError,
      phoneNumError,
      emailError,
      orgNumber,
      formValid,
      firstName,
      lastName,
      email,
      phone,
      password,
      repeatPassword,
      personalNumber,
      isInvoiceWithThirdPartyChecked,
    } = this.state;

    let orgNumberErrorMessage = '';
    if (orgNumberError) {
      if (isInvoiceWithThirdPartyChecked) {
        orgNumberErrorMessage = Constants.language.error_personal_no_exists_message;
      } else {
        orgNumberErrorMessage = Constants.language.error_corporate_id_exists_message;
      }
    } else if (isInvoiceWithThirdPartyChecked) {
      orgNumberErrorMessage = Constants.language.error_personal_no_message;
    } else {
      orgNumberErrorMessage = Constants.language.error_corporate_id_message;
    }

    return (
      <>
        <FlexContainer>
          <SectionLeft height="1500px">
            {!withoutBankID && (
              <>
                {loading ? (
                  <Loader height="24px" marginLeft="70%" margin="24px" marginRight="24px" width="100px" />
                ) : (
                  <>
                    <LoginWrapper className="col-12 col-md-6 p-0">
                      <Button
                        type="update"
                        onClick={this.handleCustomerSignUp}
                        name={(
                          <FormattedMessage
                            id="gigger_sign_up_company"
                            defaultMessage="Sign up Companies"
                          />
                          )}
                      />
                    </LoginWrapper>
                  </>
                )}
              </>
            )}
            <>
              <LoginWrapper marginTop="24px">
                <>
                  {!withoutBankID && (
                    <>
                      {loading ? (
                        <Loader width="320px" />
                      ) : (
                        <Description>
                          <FormattedMessage
                            id="customer_sign_up_already_have_account"
                            defaultMessage="Already have an account? "
                          />
                          {' '}
                          <LoginLink onClick={this.handleLogin} role="button">
                            <Line>
                              {' '}
                              <FormattedMessage id="login_login" defaultMessage="Log in" />
                            </Line>
                          </LoginLink>
                        </Description>
                      )}
                    </>
                  )}
                  {loading ? (
                    <Loader height="60px" width="326px" />
                  ) : (
                    <Header>
                      <FormattedMessage
                        id="gigger_sign_up_header_message"
                        defaultMessage="Become a Gigger for free"
                        values={{ break: <br /> }}
                      />
                    </Header>
                  )}
                </>

                <Wrapper>
                  <Content>
                    {loading ? (
                      <Loader width="150px" />
                    ) : (
                      <SubHeader>
                        <FormattedMessage id="common_company_details" defaultMessage="Company details" />
                      </SubHeader>
                    )}
                    <Instruction className="row m-0 mb-3" onClick={this.toggleInvoiceWithThirdParty}>
                      <div className="col-auto  p-0 mt-1">
                        {loading ? (
                          <Loader height="20px" width="250px" />
                        ) : (
                          <CustomCheckBox
                            onClick={this.toggleInvoiceWithThirdParty}
                            isSelected={isInvoiceWithThirdPartyChecked}
                            label={(
                              <FormattedMessage
                                id="gigger_signup_invoice_with_third_party_check_label"
                                defaultMessage="I will invoice through third party company"
                              />
                            )}
                          />
                        )}
                      </div>
                    </Instruction>
                    {!isInvoiceWithThirdPartyChecked && loading && (
                      <Loader height="20px" width="136px" marginTop="10px" />
                    )}
                    {!isInvoiceWithThirdPartyChecked && (
                      <>
                        <Input>
                          {loading ? (
                            <Loader height="40px" width="346px" marginTop="10px" />
                          ) : (
                            <TextBox
                              type="numeric"
                              label={
                                isInvoiceWithThirdPartyChecked ? (
                                  <FormattedMessage id="common_personal_number_only" defaultMessage="Personal number" />
                                ) : (
                                  <FormattedMessage
                                    id="common_corporate_id_number"
                                    defaultMessage="Corporate ID number"
                                  />
                                )
                              }
                              name="orgNumber"
                              value={orgNumber}
                              onChange={orgNumberError ? this.handleErrorChange : this.handleChange}
                              onKeyPress={this.handleKeyPress}
                              showError
                              existErrorMessage={orgNumberErrorMessage}
                              existError={
                                orgNumberError
                                || (!formValid.orgNumber_isValid
                                  && orgNumber.length === Constants.OrganisationNumberLength)
                              }
                              placeholder="XXXXXX-YYYY"
                              errorMessage={
                                isInvoiceWithThirdPartyChecked ? (
                                  <FormattedMessage
                                    id="error_valid_personal_number"
                                    defaultMessage="Enter a valid personal number"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="error_corporate_id_message"
                                    defaultMessage="Enter a valid corporate ID number"
                                  />
                                )
                              }
                              rule={{
                                method: validator.isLength,
                                validWhen: true,
                                args: [
                                  {
                                    min: Constants.OrganisationNumberLength,
                                    max: Constants.OrganisationNumberLength,
                                  },
                                ],
                              }}
                            />
                          )}
                        </Input>
                        <Input>
                          {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                          {loading ? (
                            <Loader height="20px" width="346px" marginTop="10px" />
                          ) : (
                            <div className="row">
                              <Heading className="col-12">
                                <FormattedMessage
                                  id="common_upload_f_tax_certification"
                                  defaultMessage="Upload F-tax certification"
                                />
                              </Heading>
                            </div>
                          )}
                          {loading ? (
                            <Loader height="40px" width="346px" marginTop="10px" />
                          ) : (
                            <Document
                              files={fileData}
                              onChange={this.handleDocumentChange}
                              onRemove={this.handleRemoveDocument}
                              title={Constants.docsCategory[7]}
                              isSignupPage
                              allowedTypes={Constants.inputTypes.acceptFileTypes}
                            />
                          )}
                        </Input>
                      </>
                    )}
                  </Content>
                  <Content>
                    {loading ? (
                      <Loader width="150px" />
                    ) : (
                      <SubHeader>
                        <FormattedMessage id="common_account_details" defaultMessage="Account details" />
                      </SubHeader>
                    )}
                    {withoutBankID && (
                      <>
                        <Input>
                          {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                          {loading ? (
                            <Loader height="40px" width="346px" marginTop="10px" />
                          ) : (
                            <TextBox
                              type="text"
                              label={<FormattedMessage id="common_first_name" defaultMessage="First name" />}
                              name="firstName"
                              value={firstName}
                              onChange={this.handleChange}
                              errorMessage={
                                <FormattedMessage id="error_first_name_message" defaultMessage="Enter first name" />
                              }
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          )}
                        </Input>
                        <Input>
                          {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                          {loading ? (
                            <Loader height="40px" width="346px" marginTop="10px" />
                          ) : (
                            <TextBox
                              type="text"
                              label={<FormattedMessage id="common_last_name" defaultMessage="Last name" />}
                              name="lastName"
                              value={lastName}
                              onChange={this.handleChange}
                              errorMessage={
                                <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                              }
                              rule={{
                                method: validator.isEmpty,
                                validWhen: false,
                              }}
                            />
                          )}
                        </Input>
                      </>
                    )}
                    <Input>
                      {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="10px" />
                      ) : (
                        <TextBox
                          type="email"
                          label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                          name="email"
                          value={email}
                          onChange={emailError ? this.handleErrorChange : this.handleChange}
                          errorMessage={
                            <FormattedMessage id="error_email_message" defaultMessage="Enter a valid e-mail address" />
                          }
                          existErrorMessage={(
                            <FormattedMessage
                              id="error_email_exists_message"
                              defaultMessage="This e-mail address is already registered"
                            />
                          )}
                          existError={emailError}
                          rule={{
                            method: validator.isEmail,
                            validWhen: true,
                          }}
                        />
                      )}
                    </Input>
                    <Input>
                      {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="10px" />
                      ) : (
                        <TextBox
                          type="Text"
                          label={<FormattedMessage id="common_phone" defaultMessage="Phone" />}
                          name="phone"
                          value={phone}
                          onChange={phoneNumError ? this.handleErrorChange : this.handleChange}
                          onKeyPress={this.handleKeypress}
                          errorMessage={
                            <FormattedMessage id="error_phone_message" defaultMessage="Enter a valid phone number" />
                          }
                          existErrorMessage={(
                            <FormattedMessage
                              id="error_phone_number_exists_message"
                              defaultMessage="Enter a valid phone number"
                            />
                          )}
                          existError={phoneNumError}
                          disabletickMark={!formValid.phone}
                        />
                      )}
                    </Input>
                    <Input>
                      {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="10px" />
                      ) : (
                        <PasswordBox
                          label={<FormattedMessage id="common_password" defaultMessage="Password" />}
                          name="password"
                          value={password}
                          classname={showRequirementsDoesntMatch && 'border-red'}
                          onChange={this.handlePasswordChange}
                          onBlur={this.handlePasswordBlur}
                          errorMessage={
                            <FormattedMessage id="error_password_message" defaultMessage="Enter a valid password" />
                          }
                        />
                      )}
                      {showRequirementsDoesntMatch && (
                        <TextDiv>
                          <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                          <span>
                            <FormattedMessage
                              id="error_password_doesnt_meet_requirements"
                              defaultMessage="Password doesn't meet the requirements. Password must contain a minimum of 8 characters with at least one upper case, one lower case, one symbol and one number."
                            />
                          </span>
                        </TextDiv>
                      )}
                    </Input>
                    <Input>
                      {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="10px" />
                      ) : (
                        <PasswordBox
                          label={<FormattedMessage id="error_repeat_message" defaultMessage="Repeat password" />}
                          name="repeatPassword"
                          classname={showPwdDoesntMatch && 'border-red'}
                          value={repeatPassword}
                          onChange={this.handleRepeatPasswordChange}
                          onBlur={this.handleRepeatPwdBlur}
                          errorMessage={<FormattedMessage id="error_repeat_message" defaultMessage="Repeat password" />}
                        />
                      )}
                      {showPwdDoesntMatch && (
                        <TextDiv>
                          <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                          <span>
                            <FormattedMessage
                              id="error_password_doesnt_match"
                              defaultMessage="Passwords doesn't match"
                            />
                          </span>
                        </TextDiv>
                      )}
                    </Input>
                    <Input>
                      {loading && <Loader height="20px" width="136px" marginTop="10px" />}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="10px" />
                      ) : (
                        <TextBox
                          type="numeric"
                          label={<FormattedMessage id="common_personal_number" defaultMessage="Personal ID number" />}
                          placeholder="XXXXXXXX-YYYY"
                          name="personalNumber"
                          value={personalNumber}
                          onKeyPress={this.handleKeyPress}
                          onChange={PersonalNumError ? this.handleErrorChange : this.handleChange}
                          errorMessage={(
                            <FormattedMessage
                              id="error_personal_no_message"
                              defaultMessage="Enter a valid personal ID number"
                            />
                          )}
                          existErrorMessage={(
                            <FormattedMessage
                              id="error_personal_no_exists_message"
                              defaultMessage="Enter a valid personal ID number"
                            />
                          )}
                          existError={PersonalNumError}
                          rule={{
                            method: validator.isLength,
                            validWhen: true,
                            args: [
                              {
                                min: Constants.PersonalNumberLength,
                                max: Constants.PersonalNumberLength,
                              },
                            ],
                          }}
                        />
                      )}
                    </Input>
                  </Content>
                  <Instruction className="row m-0" onClick={this.handleAgreeTermsCheckbox}>
                    <div className="col-auto  p-0 mt-1">
                      {loading ? (
                        <Loader height="20px" width="20px" />
                      ) : (
                        <CustomCheckBox onClick={this.handleAgreeTermsCheckbox} isSelected={isSelected} label="" />
                      )}
                    </div>
                    <AgreeTerms className="col p-0">
                      {loading ? (
                        <Loader height="70px" width="320px" />
                      ) : (
                        <>
                          <div>
                            <FormattedMessage
                              id="common_agree_terms_and_conditions_message"
                              values={{
                                privacyLink: <PrivacyPolicy />,
                                termsAndConditionsLink: <TermsAndConditionsLink />,
                              }}
                              defaultMessage="I approve general terms and conditions and that my personal data is processed according to GigAssembly's privacy policy."
                            />
                          </div>
                        </>
                      )}
                    </AgreeTerms>
                  </Instruction>
                  {withoutBankID ? (
                    <>
                      {loading ? (
                        <Loader height="40px" width="46px" />
                      ) : (
                        <div className="mb-5">
                          <ButtonWrapper>
                            <Button
                              type="update"
                              name={<FormattedMessage id="gigger_sign_up" defaultMessage="Sign up" />}
                              disabled={isDisabled}
                              onClick={this.handleSignInGigger}
                            />
                          </ButtonWrapper>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <SignInButton>
                        {loading ? (
                          <Loader height="40px" width="346px" />
                        ) : (
                          <Button
                            type="signIn"
                            name={(
                              <FormattedMessage
                                id="customer_sign_up_approve_with_bankid"
                                defaultMessage="Approve with Mobile BankID"
                              />
                            )}
                            disabled={isDisabled}
                            onClick={this.handleSignInGigger}
                          />
                        )}
                      </SignInButton>
                      {loading ? (
                        <Loader height="20px" width="346px" marginTop="20px" />
                      ) : (
                        <TermsandConditions className="mt-4">
                          <FormattedMessage
                            id="customer_sign_up_dont_have_bankid"
                            defaultMessage="Don't have BankID?"
                          />
                          {' '}
                          <Link href onClick={this.handleRedirectToWithoutBankIdPage}>
                            <Line>
                              <FormattedMessage id="common_apply_here" defaultMessage="Apply here" />
                            </Line>
                          </Link>
                        </TermsandConditions>
                      )}
                      {loading ? (
                        <Loader height="40px" width="346px" marginTop="24px" />
                      ) : (
                        <TermsandConditions policy>
                          <FormattedMessage
                            id="terms_and_conditions_by_clicking_message"
                            defaultMessage="By clicking on button, you agree to our"
                          />
                          {' '}
                          <TermsAndConditionsLink />
                          {' '}
                          <FormattedMessage id="common_and" defaultMessage="and" />
                          {' '}
                          <PrivacyPolicy />
                        </TermsandConditions>
                      )}
                    </>
                  )}
                </Wrapper>
              </LoginWrapper>
            </>
          </SectionLeft>
          <SectionRight>
            {loading ? (
              <Loader height="1441px" />
            ) : (
              <>
                <ImageWrapper backgroundPosition="unset" backgroundImage={GiggerSignUpImage} backgroundSize="cover !important" />
              </>
            )}
          </SectionRight>
        </FlexContainer>
      </>
    );
  }
}

export default GiggerSignUp;
