import styled from 'styled-components';
import Colors from './Colors';

export const Section = styled.div``;
export const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
`;
export const MainContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #6B6887;
`;

export const SubContent = styled.div`
  max-width: 26.38888888888889vw;
  @media (max-width: 570px) {
    max-width: 66.66666666666666vw;
  }
  @media (max-width: 768) {
    max-width: 49.47916666666667vw;
  }
`;
export const Image = styled.img``;
export const Span = styled.span``;
export const Line = styled.u``;
export const List = styled.ul``;
export const ListItem = styled.li``;
export const Nav = styled.nav``;
export const FlexContainer = styled.div`
  display: flex;
`;
export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Col = styled.div`
  flex: ${({ width }) => (width ? `0 0 ${width}%` : '0 0 100%')};
  max-width: ${({ width }) => (width ? `${width}%` : ' 100%')};
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const AccordionAnimate = styled.div`
  display: ${({ block }) => (block ? 'block' : 'flex')};
  flex: 0 0 100%;
  transform: ${({ animate }) => (animate ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: top;
  height:  ${({ animate }) => (animate ? '100%' : '0')};
  visibility:  ${({ animate }) => (animate ? 'visible' : 'hidden')};
  position:  relative;
  z-index: 4;
  transition: all 500ms linear 50ms;
`;

export const Div = styled.div`
  width: ${({ width }) => (width || '')};
@media (min-width: 1024px) {
  width: ${({ customWidth }) => (customWidth)};
}`;
export const P = styled.p`
  color: ${Colors.White};
`;
