import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';

export const FlexContainer = styled.div``;
export const TabContainer = styled.div``;
export const TabName = styled.button`
  min-width: 93px;
  height: 38px;
  padding: 8px 24px;
  border: 1px solid #0e3132;
  background-color: ${Colors.White};
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
  border-left: ${({ left }) => (left === 0 ? '1px solid #0e3132' : 'none')};
  &:focus,
  &:active {
    background-color: ${Colors.Primary};
    color: ${Colors.White};
    outline: none;
    border: none;
  }
`;
