import React from 'react';

import {
  RadioWrapper, Radio, CheckBoxWrapper, CheckboxLabel,
} from './styles';

const RadioButton = ({
  isSelected, onClick, label, size = 20, color, fontFamily,
}) => (
  <CheckBoxWrapper onClick={onClick} type="radio">
    <RadioWrapper size={size} color={color}>
      <Radio checked={isSelected} size={size} />
    </RadioWrapper>
    <CheckboxLabel fontFamily={fontFamily}>{label}</CheckboxLabel>
  </CheckBoxWrapper>
);

export default RadioButton;
