import styled from 'styled-components';
import Font from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import { BackgroundImage, LoginBg } from '../../styles/Images';

export const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color:${({ bgColor }) => (bgColor || `${Colors.DarkWhite}`)};
  background-image:${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)}, ${({ rightbgImage }) => (`url("${rightbgImage}")` || `url("${LoginBg}")`)};
  background-repeat: repeat, no-repeat;
  background-position: left, right;
  background-size: cover, 43% 100%;
  @media (max-width: 768px) {
    background-image: ${({ bgImage }) => (bgImage || `url("${BackgroundImage}")`)}, none;
    justify-content: center;
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 368px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export const SectionLeft = styled.div`
  width: 59%;
  padding-left: calc( (100vw - 1125px) / 2);
  height: ${({ height }) => (height)};
@media (max-width: 1200px) {
  padding-left: calc( (100vw - 930px) / 2);
}
@media (max-width: 768px) {
  width: 100%;
  flex-direction: column-reverse;
  max-width: 100%;
  flex: 0 0 100%;
  padding-left: 0;
}
@media (max-width: 570px) {
  max-width: 100%;
  flex: 0 0 100%;
}
@media (max-width: 368px) {
  max-width: 100%;
  flex: 0 0 100%;
}
`;
export const SectionRight = styled.div`
  flex: 0 0 41%;
  max-width: 41%;
  height: inherit;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
    flex: 0 0 100%;
  }
  @media (max-width: 570px) {
    display: none;
  }
  @media (max-width: 368px) {
    display: none;
  }
`;
export const Image = styled.img`
  width: 100%;
  height: -webkit-fill-available;
`;
export const A = styled.a`
  font-family: ${Font.fontFamily.Archivo};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
  margin-top: 40px;
  text-align: ${(left) => (left ? 'left' : 'right')};
  flex: 1;
  margin-right: 24px;
  text-decoration: underline !important;
`;

export const LoginWrapper = styled.div`
  width: ${({ align }) => (align ? '70%' : '100%')};
  max-width:  ${({ maxWidth }) => (maxWidth || 'auto')};
  margin-top: ${({ align }) => (align ? '103px' : '40px')};
  text-align: ${({ align }) => (align ? 'center' : 'start')};
  align-self: center;
  @media (max-width: 768px) {
    width: 50%;
    align-self: center;
    margin: auto;
    margin-bottom: 5rem;
  }
  @media (max-width: 570px) {
    width: ${(props) => (props.auth ? '90%' : '92%')};
    max-width: 100%;
    align-self: center;
  }
  @media (max-width: 368px) {
    width: ${(props) => (props.auth ? '90%' : '92%')};
    align-self: center;
  }
`;
export const Title = styled.h1`
  font-family: ${Font.fontFamily.AvenirMedium};
  font-size: ${Font.fontSize.Size40}px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: ${Font.fontSize.Size32}px;
    font-family:${Font.fontFamily.AvenirBold};
  }
  @media (max-width: 570px) {
    font-size: ${Font.fontSize.Size32}px;
    font-family:${Font.fontFamily.AvenirBold};
  }
  @media (max-width: 368px) {
    font-size: ${Font.fontSize.Size32}px;
    font-family:${Font.fontFamily.AvenirBold};
  }
`;
export const SubTitle = styled.p`
  font-family: ${Font.fontFamily.AvenirBold};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Secondary};
  margin-bottom: 24px;
`;

export const Button = styled.button`
  border-radius: 2px;
  background-color: ${Colors.Primary};
  width: 100%;
  margin-top: 40px;
  height: 40px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  outline: none;
  border: none;
  &:disabled {
    opacity: 0.5;
  }
`;

export const ForgotPassword = styled(A)`
  text-align: center;
`;

export const SubTitleFailure = styled(SubTitle)`
  font-weight: normal !important;
  text-align: center;
  margin-bottom: 0px;
  font-family: ${Font.fontFamily.AvenirRegular};
`;

export const TitleFailure = styled(Title)`
  text-align: center;
  margin-bottom: 8px;
  font-size: 40px;
  font-weight: 500;
`;
export const Div = styled.div`
  margin-bottom: 40px;
`;
export const ErrorMessage = styled.h6`
  color: #f03738;
`;
export const LogoutButtonWrapper = styled.div`
  width: 130px;
`;
export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
