import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';

export const Span = styled.span`
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => (props.cancel ? '#0e3132' : '#ffffff')};
`;
export const ButtonName = styled(Span)`
  font-size: ${Font.fontSize.Size14}px;
  color: ${(props) => (props.cancel ? '#0e3132' : '#ffffff')};
`;
export const SigninButton = styled.button`
  width: 100%;
  background-color: ${Colors.Primary};
  color: ${Colors.White};
  border-radius: 2px;
  padding: 16px;
  min-width: 100%;
  outline: none;
  margin-top: 40px;
  &:disabled {
    opacity: 0.5;
    color: rgb(255, 255, 255, 0.5);
  }
`;
export const IconButton = styled.button`
  background-color: ${Colors.Primary};
  color: ${Colors.White};
  font-family: ${Font.fontFamily.AvenirRegular};
  border-radius: 2px;
  padding: 16px;
  min-width: 100%;
  outline: none;
  border: none;
  margin: 0;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
export const Whitebutton = styled.button`
width: 100%;
  border: none;
  padding: 8px 16px;
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  color: ${Colors.Primary};
  background-color: ${Colors.Transparent};
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
`;
export const Primarybutton = styled.button`
  width: 100%;
  background-color: ${(props) => (props.update ? '#0e3132' : props.open ? '#f9e3d6' : '#ffffff')};
  border-radius: 2px;
  padding: 8px 35px;
  color: ${Colors.Primary};
  cursor: pointer;
  font-family: ${Font.fontFamily.AvenirRegular};
  border: ${(props) => (props.open ? 'none' : '1px solid #0e3132')};
  &:active,
  &:focus,
  &:hover {
    border: ${(props) => (props.open ? 'none' : '1px solid #000000')};
    outline: none;
    opacity: 0.9;
    background-color: ${(props) => (props.update && props.id !== 'search' ? '#000000' : 'none')};
    color: ${Colors.Black};
  }
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;
export const MultiColorbutton = styled.button`
  background-color: ${(props) => (props.cancel
    ? '#eb5757'
    : props.approve
      ? '#27ae60'
      : props.open
        ? '#f9e3d6'
        : props.complete
          ? '#2d9cdb'
          : '#ffffff')};
  border-radius: 2px;
  text-align: center !important;
  color: ${Colors.Primary};
  font-family: ${Font.fontFamily.AvenirRegular};
  line-height: 1.5;
  width: 100%;
  height: 100%;
  border: none;
  cursor: text !important;
  &:active,
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;
