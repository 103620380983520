import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Card from '../../components/Card/Cards';
import Loader from '../../components/Loader/Loader';
import {
  FlexContainer, Container, SectionLeft, DisplayCards,
} from '../customer/styles';
import { HeadingWrapper, Name } from '../gigger/styles';
import Constants from '../../shared/constants';
import AccountService from '../../services/CustomerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import GiggerService from '../../services/GiggerService';

class MyAccount extends Component {
  authService = new AuthService(Config.apiBase);

  accountService = new AccountService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = { loggedIn: this.authService.isAuthenticated(), loading: false, userData: [] };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchDetails();
    }
  }

  /**
   * @async
   * @function handleLogOut Logout the account
   */
  handleLogOut = async () => {
    const { history } = this.props;
    try {
      const response = await this.giggerService.logOut();
      if (response.status === 204 || response.status === 200) {
        localStorage.clear();
        history.push({ pathname: Constants.routes.logIn.url });
      }
    } catch (e) {
      console.error(e);
    }
    localStorage.clear();
    history.push({ pathname: Constants.routes.logIn.url });
  };

  /**
   * Redirects to user details page
   */
  handleUserDetails = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.adminUserDetails.url });
  };

  /**
   * @async
   * @function fetchDetails Fetches details of the user
   */
  async fetchDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.accountService.getDetails();
      this.setState({ loading: false, userData: response.data });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, userData, loggedIn } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <>
        <FlexContainer>
          <Container>
            <HeadingWrapper className="row m-0">
              {loading ? (
                <Loader width="200px" height="32px" marginTop="24px" />
              ) : (
                <>
                  <div className="col p-0">
                    <Name>
                      <FormattedMessage id="common_hi" defaultMessage="Hi" />
                      {' '}
                      {userData.full_name ? userData.full_name : 'Admin'}
                      ,
                    </Name>
                  </div>
                </>
              )}
            </HeadingWrapper>
            <DisplayCards className="row m-0">
              <SectionLeft className="col-6 p-0">
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header="Admin Portal"
                    description={(
                      <FormattedMessage
                        id="admin_my_account_manage_users_giggers_companies"
                        defaultMessage="Manage users, giggers and companies"
                      />
                    )}
                  />
                )}
                {loading ? (
                  <Loader width="340px" height="52px" marginTop="24px" />
                ) : (
                  <Card
                    header={<FormattedMessage id="common_user_details" defaultMessage="User Details" />}
                    description={(
                      <FormattedMessage
                        id="common_edit_user_details_description"
                        defaultMessage="Edit my personal account settings"
                      />
                    )}
                    onClick={this.handleUserDetails}
                  />
                )}
              </SectionLeft>
            </DisplayCards>
          </Container>
        </FlexContainer>
      </>
    );
  }
}

export default MyAccount;
