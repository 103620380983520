import React from 'react';
import Loader from './Loader';

const ModalLoader = () => (
  <>
    <Loader width="72px" />
    <Loader width="300px" marginTop="24px" />
    <div className="row m-0">
      <Loader width="120px" marginTop="24px" marginRight="24px" />
      <Loader width="120px" marginTop="24px" marginRight="24px" />
    </div>
  </>
);
export default ModalLoader;
