import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '24px')};
  margin: ${(props) => (props.margin ? props.margin : 'intial')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : 'intial')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : 'intial')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 'intial')};
`;
const Loader = (props) => <Div className="skeletonLoader" {...props} />;

export default Loader;
