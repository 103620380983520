import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonWrapper } from '../styles';
import {
  TableHeader,
  OptionButton,
  TableData,
  Wrapper,
} from '../../users/styles';

/**
 *
 * @function ListViewHeader
 * @param {props} param0 props
 * @returns List view header
 *
 */

const ListViewHeader = ({ gigger = false, customer = false, admin = false }) => (
  <>
    <Wrapper className="row m-0">
      {!customer && (
      <TableData className="col ">
        <TableHeader>
          <FormattedMessage id="common_company" defaultMessage="Company" />
        </TableHeader>
      </TableData>
      )}

      <TableData className="col">
        <TableHeader>
          {customer ? <FormattedMessage id="common_user" defaultMessage="User" />
            : <FormattedMessage id="admin_ordered_by_details" defaultMessage="Ordered by" />}
        </TableHeader>
      </TableData>

      {!gigger && (
      <TableData className="col">
        <TableHeader>
          <FormattedMessage id="common_gigger" defaultMessage="Gigger" />
        </TableHeader>
      </TableData>
      )}
      <TableData className="col">
        <TableHeader>
          <FormattedMessage id="common_date" defaultMessage="Date" />
        </TableHeader>
      </TableData>

      <TableData className="col">
        <TableHeader>
          <FormattedMessage id="common_service" defaultMessage="Service" />
        </TableHeader>
      </TableData>

      {!admin && (
      <TableData className="col">
        <TableHeader>
          <FormattedMessage id="common_amount" defaultMessage="Amount" />
        </TableHeader>
      </TableData>
      )}

      {customer && (
      <TableData className="col">
        <TableHeader>
          <FormattedMessage id="common_order_id" defaultMessage="Order ID" />
        </TableHeader>
      </TableData>
      )}

      <TableData className="col-auto text-align">
        <ButtonWrapper>
          <TableHeader>
            <FormattedMessage id="common_status" defaultMessage="Status" />
          </TableHeader>
        </ButtonWrapper>
      </TableData>

      <TableData className="col-auto">
        <OptionButton />
      </TableData>
    </Wrapper>
  </>
);
export default ListViewHeader;
