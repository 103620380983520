import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import Header from '../../../components/Header/Header';
import Constants from '../../../shared/constants';
import { IconWrapper, Icon } from '../giggers/styles';
import ChangeLanguageModal from '../../../components/Modals/index';
import {
  FlexContainer,
  Container,
  UsersListWrapper,
  Border,
  TableHeader,
  TableValue,
  TableData,
  Wrapper,
  OptionButton,
} from './styles';
import LoadingCard from './LoadingCard';
import { Card, Span, Div } from '../../../components/Popover/Style';
import Modal from './Modal';
import Pagination from '../../../components/Pagination/Pagination';
import UserService from '../../../services/AdminServices/UserService';
import Config from '../../../config';
import AuthService from '../../../services/AuthService';
import { WhiteCard } from '../../gigger/styles';
import SearchInput from '../../../components/Input/SearchInput';
import { WhiteButton } from '../../customer/styles';
import { getFormattedCSVData } from '../../../shared/utils';
import { MainContext } from '../../../components/Toast/Toast';

class Users extends Component {
  authService = new AuthService(Config.apiBase);

  userService = new UserService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      loggedIn: this.authService.isAuthenticated(),
      openColumn: '',
      loading: false,
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      isAddUserModalOpen: false,
      data: [],
      userData: [],
      searchbar: true,
      meta: {
        previous: null,
        current: 0,
        next: 1,
        count: null,
        pageNumber: 1,
        pageSize: 15,
        pages: 1,
      },
      toastData: {},
      showToast: false,
      searchQuery: '',
      csvDownloadData: [],
      isLangModalOpen: false,
      csvExportFileds: ['full_name', 'role', 'created_at'],
    };
    this.filterOptions = [];
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchUsers();
    }
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.key !== prevProps.location.key) {
      this.fetchUsers();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Ignore clicks on the component itself
   * @param {Event} e
   * @listens Event
   */
  handleOutsideClick(e) {
    const { openColumn } = this.state;
    if (this.filterOptions[openColumn] && !this.filterOptions[openColumn].contains(e.target)) {
      if (this.filterOptions[openColumn]) this.setState({ openColumn: '' });
    }
  }

  /**
   * displays toast bar
   * @param {Object} data toast data
   */
  handleToast = (data) => {
    this.setState({ showToast: true, toastData: data }, this.fetchUsers);
  };

  handleToastClose = () => {
    this.setState({ showToast: false });
  }

  /**
   * Downloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.userService.getUsersList(queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.usersCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState(({ meta }) => ({
      meta: { ...meta, pageNumber },
    }), this.fetchUsers);
  };

  /**
   * handles search functionality
   * @param {String} value search text
   */
  handlesearchUsers=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchUsers(value);
    });
  }

  /**
   * handles search value change
   * @param {Event} event
   * @listens Event
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * handles display of options
   * @param {String} value row index
   * @param {Object} data row data
   */
  handleOptions = (value, data) => () => {
    const { openColumn } = this.state;
    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value), userData: data });
    }
  };

  handleDisplayOptions = () => {
    this.setState({ openColumn: '' });
  };

  /**
   * Focus on division
   */
  toFocusOnDiv=() => {
    document.getElementById('clickable-component').focus();
  }

  /**
   * Opens edit user modal
   */
  handleEditUser = () => {
    this.setState({ isEditModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens add user modal
   */
  handleAddUser = () => {
    this.setState({ isAddUserModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  /**
   * Closes all modals
   */
  handleModalClose = () => {
    this.setState({
      isEditModalOpen: false,
      isDeleteModalOpen: false,
      isAddUserModalOpen: false,
      isLangModalOpen: false,
    });
  };

  /**
   * Opens delete modal
   */
  handleDelete = () => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Fetches users
   */
  handleReload = () => {
    this.fetchUsers();
  };

  /**
   * Opens search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   * @async
   * @function fetchUsers Fetches users
   * @param {String} searchInput search text
   */
  async fetchUsers(searchInput) {
    const {
      meta: {
        pageNumber, pageSize,
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    try {
      const response = await this.userService.getUsersList(queryParams);
      const {
        per_page: perPage, current_page: currentPage, total: count, last_page: pages,
      } = response.meta;

      this.setState({
        data: response.data,
        loading: false,
        meta: {
          pageSize: perPage,
          pageNumber: currentPage,
          count,
          pages,
        },
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      openColumn,
      loggedIn,
      isEditModalOpen,
      isDeleteModalOpen,
      isAddUserModalOpen,
      userData,
      data,
      meta,
      loading,
      searchbar,
      searchQuery,
      showToast,
      toastData,
      csvDownloadData,
      isLangModalOpen,
    } = this.state;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <CSVLink data={csvDownloadData} filename="users.csv" className="d-none" ref={(r) => { this.usersCsv = r; }}>
          click
        </CSVLink>
        <Header
          name={<FormattedMessage id="common_user" defaultMessage="User" />}
          users
          handleEditUser={this.handleAddUser}
          openLangModal={this.handleLangModal}
        />

        <FlexContainer>
          <Container>
            <UsersListWrapper>
              <IconWrapper className="row m-0 pt-2">
                {searchbar ? (
                  <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
                ) : (
                  <div className="text-end col-auto p-0">
                    <SearchInput
                      onSearch={this.handlesearchUsers}
                      onChange={this.handleSearchOnChange}
                      value={searchQuery}
                    />
                  </div>
                )}
                <Icon
                  src={Constants.icons.ExportIcon}
                  alt="icon"
                  className={data && data.length > 0 ? 'mr-3' : 'disableOption mr-3'}
                  onClick={this.handleDownloadCsv}
                />
              </IconWrapper>
              {loading && (
                <UsersListWrapper>
                  <LoadingCard />
                </UsersListWrapper>
              )}
              {!loading && (
                <>
                  <Wrapper className="row m-0">
                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="common_user" defaultMessage="User" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="common_role" defaultMessage="Role" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col">
                      <TableHeader>
                        <FormattedMessage id="common_created" defaultMessage="Created" />
                      </TableHeader>
                    </TableData>

                    <TableData className="col-auto">
                      <OptionButton />
                    </TableData>
                  </Wrapper>
                  {data.length > 0 ? (
                    <>
                      {data.map((obj, index) => (
                        <>
                          <Border />
                          <Wrapper className="row m-0">
                            <TableData className="col text-truncate" title={obj.full_name}>
                              <TableValue>{obj.full_name}</TableValue>
                            </TableData>

                            <TableData className="col">
                              <TableValue>
                                {obj.role_name === Constants.role.Admin
                                  ? Constants.language.common_admin
                                  : Constants.language.common_cv_verification}
                              </TableValue>
                            </TableData>

                            <TableData className="col">
                              <TableValue>{moment(obj.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                            </TableData>

                            <TableData
                              className="col-auto"
                              ref={(filterOptionRef) => {
                                this.filterOptions[index] = filterOptionRef;
                              }}
                            >
                              <TableValue>
                                <WhiteButton onClick={this.handleOptions(index, obj)}>
                                  <img
                                    src={Constants.icons.ThreeDots}
                                    alt="icon"
                                    className="cursor-pointer"
                                  />
                                </WhiteButton>
                                {openColumn === index && (
                                <Card
                                  onBlur={this.handleDisplayOptions}
                                  tabIndex="-1"
                                  id="clickable-component"
                                  onLoad={this.toFocusOnDiv}
                                >
                                  <>
                                    <Div onClick={this.handleEditUser} role="button">
                                      <Span>
                                        <FormattedMessage id="common_edit_user" defaultMessage="Edit user" />
                                      </Span>
                                    </Div>
                                    <Border />
                                    <Div
                                      role="button"
                                      onClick={this.handleDelete}
                                      className={data.length === 1 ? 'disableOption' : ''}
                                    >
                                      <Span>
                                        <FormattedMessage id="common_delete" defaultMessage="Delete" />
                                      </Span>
                                    </Div>
                                  </>
                                </Card>
                                )}
                              </TableValue>
                            </TableData>
                          </Wrapper>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Border />
                      <WhiteCard className="my-3">
                        {searchQuery && searchQuery.length > 0 ? (
                          <FormattedMessage
                            id="common_no_search_results_found"
                            defaultMessage="No search results found"
                          />
                        ) : (
                          <FormattedMessage id="common_users_found" defaultMessage="No users found" />
                        )}
                      </WhiteCard>
                    </>
                  )}
                </>
              )}
            </UsersListWrapper>
            <a
              href
              data-toggle="modal"
              data-target="#AdminModal"
              data-backdrop="static"
              data-keyboard="false"
              ref={(openLangModal) => { this.openLangElement = openLangModal; }}
            />
            {isLangModalOpen && (
              <ChangeLanguageModal
                changeLanguage={isLangModalOpen}
                ModalClose={this.handleModalClose}
              />
            )}
            {!loading && meta.pages > 1 && (
              <UsersListWrapper style={{ 'background-color': 'transparent' }}>
                <Pagination cursor={meta} gotoPage={this.handleGotoPage} />
              </UsersListWrapper>
            )}
          </Container>
          <a
            href
            data-toggle="modal"
            data-target="#UserModal"
            data-backdrop="static"
            data-keyboard="false"
            ref={(openModal) => { this.openElement = openModal; }}
          />
          {isEditModalOpen && (
            <Modal
              editUser={isEditModalOpen}
              data={userData}
              ModalClose={this.handleModalClose}
              handleToast={this.handleToast}
            />
          )}
          {isAddUserModalOpen && (
            <Modal
              addUser={isAddUserModalOpen}
              handleToast={this.handleToast}
              ModalClose={this.handleModalClose}
            />
          )}
          {isDeleteModalOpen && (
            <Modal
              remove={isDeleteModalOpen}
              handleToast={this.handleToast}
              ModalClose={this.handleModalClose}
              userId={userData.id}
            />
          )}
        </FlexContainer>
      </MainContext.Provider>
    );
  }
}

export default withRouter(Users);
