import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import validator from 'validator';
import EyeNo from '../../assets/images/eyeNo.svg';
import Colors from '../../styles/Colors';
import Fonts from '../../styles/Fonts';
import Constants from '../../shared/constants';
import { A } from '../../pages/login/styles';
import { Image, Span } from '../../styles/CommonStyles';

const Div = styled.div`
font-family: ${Fonts.fontFamily.Archivo};
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #f03738;
  margin-top: 4px;
`;
const Section = styled.div`
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`;
const Label = styled.label`
  width: 100%;
  height: 21px;
  font-size: ${Fonts.fontSize.Size14}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.56px;
  color: ${Colors.Black};
  flex: 0 0 100%;
  max-width: 100%;
  font-family: ${Fonts.fontFamily.AvenirRegular};
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const InputBox = styled.input`
  width: 100%;
  height: 38px;
  padding: 8px;
  background-color: ${Colors.White};
  outline: 0;
  border: none;
  border-radius: 2px;
`;
const BorderBox = styled.div`
  height: 40px;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.White};
  margin-bottom: 30px;
`;
const Divison = styled.div``;

const taiPasswordStrength = require('tai-password-strength');

class PasswordBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showError: false,
      passwordHidden: true,
      rule: {
        method: validator.isEmpty,
        validWhen: false,
      },
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
    };
    this.textInput = React.createRef();
  }

  handleChange = (event) => {
    const { onChange } = this.props;
    const { rule } = this.state;
    let { isValid } = this.props;
    if (rule) {
      isValid = this.validate(event.target.value, rule);
    }
    this.setState({
      isValid,
      showPwdDoesntMatch: false,
      showRequirementsDoesntMatch: false,
    });
    if (onChange) {
      onChange(event);
    }
  };

  handlePasswordBlur = (event) => {
    let { RequirementsDoesntMatch } = false;
    const { value } = event.target;
    if (!validator.isEmpty(value)) {
      const passwordStrength = new taiPasswordStrength.PasswordStrength();
      const validation = passwordStrength.check(value);
      RequirementsDoesntMatch = validation
        && validation.charsets
        && (validation.charsets.symbol || validation.charsets.punctuation)
        && validation.charsets.lower
        && validation.charsets.upper
        && validation.charsets.number
        && validation.passwordLength >= 8;
      this.setState({ showRequirementsDoesntMatch: !RequirementsDoesntMatch });
    }
  };

  handleRepeatPwdBlur = (event) => {
    let doesntMatch = false;
    const { value } = event.target;
    const { password = '' } = this.props;
    if (!validator.isEmpty(password) && !validator.isEmpty(value)) {
      doesntMatch = password === value;
      this.setState({ showPwdDoesntMatch: !doesntMatch });
    }
  };

  handleBlur = (event) => {
    const { onChange, onBlur } = this.props;
    const { name, value = '' } = event.target;
    const showError = value.length === 0;
    if (onChange) {
      this.handleChange(event);
    }
    if (onBlur) {
      onBlur(event);
    } else if (name === Constants.NEW_PASSWORD) {
      this.handlePasswordBlur(event);
    } else if (name === Constants.REPEAT_PASSWORD) {
      this.handleRepeatPwdBlur(event);
    }
    this.setState({ showError });
  };

  handleFocus = (event) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  };

  focus = () => {
    this.textInput.current.focus();
  };

  toggleShowPassword = () => {
    const { passwordHidden } = this.state;
    this.setState({ passwordHidden: !passwordHidden }, () => { this.focus(); });
  };

  validate(value) {
    const { rule } = this.state;
    const args = rule.args || [];
    return rule.presence || !validator.isEmpty(value) || !validator.isEmail(value)
      ? rule.method(value, ...args) === rule.validWhen
      : true;
  }

  render() {
    const {
      rule,
      showError,
      isValid,
      passwordHidden,
      showRequirementsDoesntMatch,
      showPwdDoesntMatch,
    } = this.state;
    const {
      name,
      label,
      id,
      minLength,
      maxLength,
      disabled,
      value,
      placeholder = '',
      existError,
      existErrorMessage,
      onKeyPress,
      errorMessage,
    } = this.props;

    const displayError = (showError && !isValid)
    || existError || showPwdDoesntMatch || showRequirementsDoesntMatch;

    return (
      <Section>
        {label && (
          <LabelWrapper>
            <Label>{label || ''}</Label>
          </LabelWrapper>
        )}
        <BorderBox
          className={
            `${displayError && 'border-red'} row m-0 p-0`
          }
        >
          <Divison className="col p-0">
            <InputBox
              name={name}
              type={passwordHidden ? 'password' : 'text'}
              id={id}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              ref={this.textInput}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              minLength={minLength}
              maxLength={maxLength}
              onKeyPress={onKeyPress}
            />
          </Divison>
          <Divison className="col-auto mt-1">
            <A onClick={this.toggleShowPassword} href className="mr-0">
              {passwordHidden ? (
                <Image
                  src={EyeNo}
                  alt="hidePassword"
                  width="18px"
                  height="18px"
                  className="mt-1"
                />
              ) : (
                <Image
                  src={Constants.icons.EyeIcon}
                  alt="showPassword"
                  width="18px"
                  height="18px"
                  className="mt-1"
                />
              )}
            </A>
          </Divison>
        </BorderBox>
        {existError && rule && (
          <Div>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <span>{existErrorMessage}</span>
          </Div>
        )}
        {showError && !isValid && rule && (
          <Div>
            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
            <Span>
              <FormattedMessage
                id="error_password_validation_message"
                defaultMessage="Enter a valid password"
              />
            </Span>
          </Div>
        )}
        {!showError && (showPwdDoesntMatch || showRequirementsDoesntMatch)
        && (
        <Div>
          <Image src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
          <Span>
            {errorMessage}
          </Span>
        </Div>
        )}
      </Section>
    );
  }
}

export default PasswordBox;
