import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from '../Modals/styles';
import { Border } from '../../pages/customer/styles';
import {
  CloseButton,
  CrossMark,
} from './styles';
import Filter from './Filter';

class FilterInMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      jobTitle, categoryData, skillsData, onCancel, onApply, onClearFilters, total, systemsData,
      data,
    } = this.props;
    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-filter-modal p-0"
          id="FilterModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-large" role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card className="m-0 p-3">
                  <div className=" row m-0">
                    <CloseButton className="col-auto p-0" data-dismiss="modal" aria-label="Close">
                      <CrossMark>&times; </CrossMark>
                      <FormattedMessage id="common_close" defaultMessage="Close" />
                    </CloseButton>
                    <div className="col p-0 mr-5 text-center">
                      <FormattedMessage id="common_filter" defaultMessage="Filter" />
                    </div>
                  </div>
                </Card>
                <Border />
                <Card>
                  <Filter
                    jobTitle={jobTitle}
                    categoryData={categoryData}
                    skillsData={skillsData}
                    systemsData={systemsData}
                    onCancel={onCancel}
                    onApply={onApply}
                    onClearFilters={onClearFilters}
                    total={total}
                    mobileFilter
                    data={data}
                  />
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FilterInMobile;
