import React, { Component } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../../shared/constants';
import { IconWrapper, Icon } from '../styles';
import Modal from '../../../../components/Modals/index';
import { Card, Span, Div } from '../../../../components/Popover/Style';
import { OrdersListWrapper } from '../../orders/styles';
import GiggerService from '../../../../services/AdminServices/GiggersService';
import {
  Border, TableHeader, TableValue, TableData, Wrapper, OptionButton,
} from '../../users/styles';
import Config from '../../../../config';
import LoadingCard from '../../LoadingCard';
import { WhiteCard } from '../../../gigger/styles';
import FileService from '../../../../services/AdminServices/OrderService';
import SearchInput from '../../../../components/Input/SearchInput';
import ProfilePageService from '../../../../services/ProfilePageService';
import { WhiteButton } from '../../../customer/styles';
import { isCvVerificationUser } from '../../../../shared/utils';

class DocumentTab extends Component {
  giggerService = new GiggerService(Config.apiBase);

  fileService = new FileService(Config.apiBase);

  profilePageService = new ProfilePageService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      searchbar: true,
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
          pageNumber: 1,
          pageSize: 15,
        },
      },
      searchQuery: '',
      docsData: [],
      openColumn: '',
      isModalOpen: false,
      isDeleteModalOpen: false,
      loading: false,
      deleteDocId: '',

      csvExportFileds: ['file_name', 'category', 'current_user', 'created_at', '_id'],
    };
    this.filterOptions = [];
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    this.fetchDocuments();
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        cursor: { pageNumber },
      },
    } = this.state;
    if (prevState.meta && prevState.meta.cursor.pageNumber !== pageNumber) {
      this.fetchDocuments();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Ignores clicks on the component itself
   * @param {Event} e
   * @listens Event
   */
  handleOutsideClick(e) {
    const { openColumn } = this.state;
    if (this.filterOptions[openColumn] && !this.filterOptions[openColumn].contains(e.target)) {
      if (this.filterOptions[openColumn]) this.setState({ openColumn: '' });
    }
  }

  /**
   * Handles pagination
   * @param {Number} pageNumber page number
   */
  handleGotoPage = (pageNumber) => {
    this.setState({
      meta: {
        cursor: {
          pageNumber,
        },
      },
    });
  };

  /**
   * Handles search functionality
   * @param {String} value search text
   */
  handlesearchDocument=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchDocuments(value);
    });
  };

  /**
   * Handles Change in search value
   * @param {Event} event search value
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   * Dowloads data in the form of csv file
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { giggerId } = this.props;
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];
    try {
      const response = await this.giggerService.getGiggerDocs(giggerId, queryParams);
      this.setState({ loading: false });
      if (response) {
        this.docsCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Displays options
   * @param {Number} value row index
   * @param {Object} data row data
   */
  handleOptions = (value) => () => {
    const { openColumn } = this.state;
    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value) });
    }
  };

  /**
   * displays search bar
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  handleDisplayOptions = () => {
    this.setState({ openColumn: '' });
  };

  /**
   * Focus on pop-up element on clicking outside
   */
  toFocusOnDiv = () => {
    document.getElementById('clickable-component').focus();
  };

  /**
   * Opens modal
   */
  handleModalOpen = () => {
    this.setState({ isModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens delete document modal
   * @param {String} docId document id
   */
  handleDeleteModalOpen = (docId) => () => {
    this.setState({ isDeleteModalOpen: true, deleteDocId: docId }, () => {
      this.openElement.click();
    });
  };

  /**
   * Handles delete document
   */
  handleDeleteDocument = async () => {
    this.setState({ loading: true });
    const { giggerId } = this.props;
    const { deleteDocId, docsData } = this.state;
    await this.profilePageService.removeDoc({ id: deleteDocId });
    let docs = docsData.map((obj) => obj._id);
    docs = docs.filter((obj) => obj !== deleteDocId);
    const data = {
      docs,
    };
    try {
      const response = await this.giggerService.updateGigger(giggerId, data);
      if (response) {
        this.setState({ loading: false }, this.fetchDocuments);
      }
    } catch (e) {
      this.setState({ loading: false }, this.fetchDocuments);
    }
  };

  /**
   * Add new document functionality
   * @param {Object} document document data
   * @param {*} category category of document
   */
  handleAddDocument = async (document, category) => {
    this.setState({ loading: true });
    const uploadresponse = await this.profilePageService.uploadmediaFile(document, 'docs', category);
    const id = uploadresponse?.data?.data?._id;
    const { giggerId } = this.props;
    const { docsData } = this.state;
    const docs = docsData.filter((o) => o.category
    !== Constants.docsCategory[7]).map((obj) => obj._id);
    docs.push(id);
    const data = {
      docs,
    };
    try {
      const response = await this.giggerService.updateGigger(giggerId, data);
      if (response) {
        this.setState({ loading: false }, this.handleLoad);
      }
    } catch (e) {
      this.setState({ loading: false }, this.handleLoad);
    }
  };

  /**
   * Closes the modal
   */
  handleModalClose = () => {
    this.setState({ isModalOpen: false, isDeleteModalOpen: false });
  };

  /**
   * Loads data
   */
  handleLoad = () => {
    this.handleModalClose();
    this.fetchDocuments();
  };

  async fetchDocuments(searchInput) {
    const { giggerId } = this.props;
    const {
      meta: {
        cursor: { pageNumber },
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['search', searchQuery],
    ];
    try {
      const response = await this.giggerService.getGiggerDocs(giggerId, queryParams);
      this.setState({ loading: false, docsData: response?.data ?? [] });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      searchbar,

      docsData,
      openColumn,
      loading,
      isModalOpen,
      searchQuery,
      isDeleteModalOpen,
    } = this.state;
    return (
      <>
        <OrdersListWrapper>
          <IconWrapper className="row m-0">
            {searchbar ? (
              <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
            ) : (
              <div className="text-end col-auto p-0">
                <SearchInput
                  onSearch={this.handlesearchDocument}
                  onChange={this.handleSearchOnChange}
                  value={searchQuery}
                />
              </div>
            )}
            <TableValue className={`${!!isCvVerificationUser() && 'disableOption'} d-flex align-items-end`} role="button" onClick={this.handleModalOpen}>
              <Icon src={Constants.icons.Plus} alt="icon" onClick={this.handleModalOpen} />
              <u className="pl-2 pr-3">
                <FormattedMessage id="common_add_more" defaultMessage="Add more" />
              </u>
            </TableValue>
          </IconWrapper>
          {loading && (
            <OrdersListWrapper>
              <LoadingCard />
            </OrdersListWrapper>
          )}
          {!loading && (
            <>
              <Wrapper className="row m-0">
                <TableData className="col ">
                  <TableHeader>
                    <FormattedMessage id="common_document" defaultMessage="Document" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_type" defaultMessage="Type" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_uploaded_by" defaultMessage="Uploaded by" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_date" defaultMessage="Date" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_id" defaultMessage="ID" />
                  </TableHeader>
                </TableData>

                <TableData className="col-auto">
                  <OptionButton />
                </TableData>
              </Wrapper>
              {docsData.length > 0 ? (
                <>
                  {docsData.map((data, index) => (
                    <>
                      <Border />
                      <Wrapper className="row m-0">
                        <TableData className="col text-truncate" title={data.file_name}>
                          <TableValue>{data.file_name}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data.category}>
                          <TableValue>
                            {data.category === null || data.category === undefined ? '-' : data.category}
                          </TableValue>
                        </TableData>

                        <TableData className="col">
                          <TableValue>
                            {data.uploaded_by === null || data.uploaded_by === undefined ? '-' : data.uploaded_by}
                          </TableValue>
                        </TableData>

                        <TableData className="col">
                          <TableValue>{moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data._id}>
                          <TableValue>{data._id}</TableValue>
                        </TableData>

                        <TableData
                          className="col-auto"
                          ref={(filterOptionRef) => {
                            this.filterOptions[index] = filterOptionRef;
                          }}
                        >
                          <TableValue>
                            <WhiteButton onClick={this.handleOptions(index)}>
                              <img src={Constants.icons.ThreeDots} alt="icon" className="cursor-pointer" />
                            </WhiteButton>
                            {openColumn === index && (
                              <Card
                                onBlur={this.handleDisplayOptions}
                                tabIndex="-1"
                                id="clickable-component"
                                onLoad={this.toFocusOnDiv}
                              >
                                <>
                                  <Div onClick={this.handleDeleteModalOpen(data._id)} role="button">
                                    <Span>
                                      <FormattedMessage id="common_delete" defaultMessage="Delete" />
                                    </Span>
                                  </Div>
                                  <Border />
                                  <Div role="button">
                                    <a href={data.url} download={data.file_name} className="text-decoration-none" target="_blank" rel="noreferrer">
                                      <Span>
                                        <FormattedMessage id="common_download" defaultMessage="Download" />
                                      </Span>
                                    </a>
                                  </Div>
                                </>
                              </Card>
                            )}
                          </TableValue>
                        </TableData>
                      </Wrapper>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <>
                    <Border />
                    <WhiteCard className="my-3">
                      {searchQuery && searchQuery.length > 0 ? (
                        <FormattedMessage
                          id="common_no_search_results_found"
                          defaultMessage="No search results found"
                        />
                      ) : (
                        <FormattedMessage id="customer_details_ensurance_message" defaultMessage="No documents found" />
                      )}
                    </WhiteCard>
                  </>
                </>
              )}
            </>
          )}
          <a
            href
            data-toggle="modal"
            data-target="#AdminModal"
            data-backdrop="static"
            data-keyboard="false"
            ref={(openModal) => {
              this.openElement = openModal;
            }}
          />
          {isModalOpen && (
            <Modal
              addDocument={isModalOpen}
              ModalClose={this.handleModalClose}
              handleLoad={this.handleLoad}
              handleAddDocument={this.handleAddDocument}
            />
          )}
          {isDeleteModalOpen && (
            <Modal
              remove={isDeleteModalOpen}
              ModalClose={this.handleModalClose}
              handleLoad={this.handleLoad}
              handleDeleteDocument={this.handleDeleteDocument}
            />
          )}
        </OrdersListWrapper>
      </>
    );
  }
}

export default DocumentTab;
