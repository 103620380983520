import React, { Component } from 'react';
import Constants from '../../shared/constants';
import Toast, { MainContext } from '../../components/Toast/Toast';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import AuthService from '../../services/AuthService';
import { RelativeContainer } from '../../styles/CommonStyles';
import UserDetailsUI from '../../components/UserDetails/index';

class UserDetails extends Component {
  authService = new AuthService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      oldPasswordError: false,
      loading: false,
      loggedIn: this.authService.isAuthenticated(),
      showToast: false,
      emailError: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      userData: [],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) this.fetchUserDetails();
  }

  /**
   * Redirects to forgot password page
   */
  handleForgotPassword = () => {
    const { history } = this.props;
    history.push({ pathname: Constants.routes.forgotPassword.url });
  };

  /**
   * Logs out the account
   */
  handleLogOutAccount = () => {
    localStorage.clear();
    window.location.reload();
  };

  /**
   * @async
   * @function handleChangeMailButton Submitts the user details
   * @param {Object} data user data
   */
  handleChangeMailButton = async (data) => {
    this.setState({ loading: true, emailError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.giggerService.updateUserDetails(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_user_details_updated;
        this.setState({ loading: false, toastData, showToast: true });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ toastData, showToast: true, loading: false });
      }
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message === 'Email Id Already Exists') {
        this.setState({ emailError: true, showToast: false, loading: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   * Handles change password action
   * @param {Object} data all passwords
   */
  handleChangePasswordButton = async (data) => {
    this.setState({ loading: true, oldPasswordError: false });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.giggerService.changePassword(data);
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_password_updated;
        this.setState({
          loading: false,
          toastData,
          showToast: true,
        });
        this.handleLogOutAccount();
      }
    } catch (e) {
      if (e.response && e.response.data.error === "Old Password didn't match") {
        this.setState({ oldPasswordError: true, loading: false, showToast: false });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      }
    }
  };

  /**
   * @async
   * @function handleChangeAdressButton Submitts the user details
   * @param {Object} data user data
   */
   handleChangeAdressButton = async (data) => {
     this.setState({ loading: true, emailError: false });
     const toastData = {};
     toastData.delay = Constants.toast.LONG_DELAY;
     try {
       const response = await this.giggerService.updateCompanyDetails(data);
       if (response) {
         toastData.type = Constants.toast.SUCCESS;
         toastData.message = Constants.language.toast_user_details_updated;
         this.setState({ loading: false, toastData, showToast: true });
       } else {
         toastData.type = Constants.toast.ERROR;
         toastData.message = Constants.language.toast_try_after_time;
         this.setState({ toastData, showToast: true, loading: false });
       }
     } catch (e) {
       const { response: { data: { message = '' } = {} } = {} } = e;
       if (message === 'Email Id Already Exists') {
         this.setState({ emailError: true, showToast: false, loading: false });
       } else {
         toastData.type = Constants.toast.ERROR;
         toastData.message = Constants.language.toast_try_after_time;
         this.setState({ loading: false, toastData, showToast: true });
       }
     }
   };

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerMyAccount.url);
  };

  /**
   * Redirects to delete account page
   */
  handleDelete = () => {
    const { history } = this.props;
    history.push(Constants.routes.deleteAccount.url, { gigger: true });
  };

  /**
   * Closes the toast
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   * @async
   * @function fetchUserDetails Fetches user details
   */
  async fetchUserDetails() {
    this.setState({ loading: true });
    try {
      const response = await this.giggerService.userDetails();
      const { data: userData = {} } = response;
      this.setState({
        loading: false,
        userData,
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      oldPasswordError, loading, showToast, toastData, emailError, userData,
    } = this.state;

    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <RelativeContainer>
          {showToast && <Toast />}
          <UserDetailsUI
            data={userData}
            loading={loading}
            emailError={emailError}
            oldPasswordError={oldPasswordError}
            handleMyAccount={this.handleMyAccount}
            handleDelete={this.handleDelete}
            handleSubmitDetails={this.handleChangeMailButton}
            handleChangePassword={this.handleChangePasswordButton}
            handleForgotPassword={this.handleForgotPassword}
            handleAddressDetails={this.handleChangeAdressButton}
          />
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}

export default UserDetails;
