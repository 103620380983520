import ApiService from '../ApiService';

class CompanyService extends ApiService {
  endPoint = {
    companyList: 'admin/companies',
    company: 'admin/company',
    removeUser: 'admin/users/',
    companies: 'companies',
  };

  async getCompanyList(queryParam) {
    const response = await this.get(this.baseURL + this.endPoint.companyList
      + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getCompanyOrders(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.company}/${id}/orders${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getCompanyFeedback(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.company}/${id}/feedback${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getAccountDetails(id) {
    const response = await this.get(`${this.baseURL + this.endPoint.company}/${id}/details`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getUsers(id, queryParam) {
    const response = await this.get(`${this.baseURL + this.endPoint.company}/${id}/users${this.queryParamString(queryParam)}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getHiredGiggers(id, queryParam) {
    const response = await this.get(
      `${this.baseURL + this.endPoint.company}/${id}/hired-giggers${this.queryParamString(queryParam)}`,
    );
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async removeCompanyUser(id) {
    const response = await this.delete(`${this.baseURL + this.endPoint.company}/${id}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async approveCompany(id) {
    const response = await this.post(`${this.baseURL + this.endPoint.company}/${id}/approve`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateCompanyOwner(id, data) {
    const response = await this.put(`${this.baseURL + this.endPoint.company}/${id}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async deleteCompanyUser(id, data) {
    const response = await this.delete(`${this.baseURL + this.endPoint.company}/${id}`, data);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async updateCompany(id, payload) {
    return this.put(`${this.baseURL}${this.endPoint.companies}/${id}`, payload).then((response) => response.data);
  }
}
export default CompanyService;
