import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  Container,
  Div,
  NavContent,
  NavActive,
  HeaderPart,
  NavButton,
} from './styles';
import Toast, { MainContext } from '../../components/Toast/Toast';
import { RelativeContainer } from '../../styles/CommonStyles';
import Constants from '../../shared/constants';
import ProfilePageService from '../../services/ProfilePageService';
import Config from '../../config';
import AccountService from '../../services/AccountService';
import { getLocaleFromURL } from '../../shared/utils';
import ProfileForm from './ProfileForm';
import ProfileModel from '../../models/GiggerProfile';

class Profile extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  accountService = new AccountService(Config.apiBase);

  currentLanguage = getLocaleFromURL();

  constructor(props) {
    super(props);
    this.state = {
      categoryOptions: [],
      originalSkillOptions: [],
      originalSystemsOptions: [],
      jobTitleOptions: [],
      giggerData: [],
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      loading: false,
      areChangesSaved: true,
    };
    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidMount() {
    this.fetchGiggerProfile();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  /**
 * This method is used for both add and remove event
 * @param {Event} e
 */
  onUnload = (e) => {
    const message = 'Please click Update. Unsaved changes will be lost.';
    const { areChangesSaved } = this.state;
    if (!areChangesSaved) {
      (e || window.event).preventDefault(); // for Firefox
      (e || window.event).returnValue = message; // for Chrome
    }
  }

  /**
   * Redirects to my account page
   */
  handleMyAccount = () => {
    const { history } = this.props;
    history.push(Constants.routes.giggerMyAccount.url);
  };

  /**
   * @function isNewFilterAdded Checks whether any new filter is added or not.
   * @param {Object} data filter data
   * @returns boolean value
   */
  isNewFilterAdded = (data) => {
    const {
      categoryOptions, originalSkillOptions, jobTitleOptions, originalSystemsOptions,
    } = this.state;
    const {
      skills, systems, category, jobTitle,
    } = data;
    const skilldata = skills.split(',').map((skill) => skill.trim());
    const systemsdata = systems.split(',').map((skill) => skill.trim());
    const originalSkills = originalSkillOptions.map(({ label = '' }) => label.trim());
    const originalSystems = originalSystemsOptions.map(({ label = '' }) => label.trim());
    const sdata = skilldata.filter((skill) => originalSkills.includes(skill));
    const systemdata = systemsdata.filter((system) => originalSystems.includes(system));
    const jdata = jobTitleOptions.filter(({ label = '' }) => jobTitle === label);
    const cdata = categoryOptions.filter(({ label = '' }) => category === label);

    if (sdata.length !== skilldata.length || jdata.length === 0 || cdata.length === 0
      || systemdata.length !== systemsdata.length) {
      return true;
    }
    return false;
  };

  /**
   * Tracks changes in input field
   */
  handleChangesInInput=() => {
    this.setState({ areChangesSaved: false });
  }

  /**
   * @async
   * @function fetchGiggerProfile Fetches the users profile details and filters.
   */
  fetchGiggerProfile = async () => {
    this.setState({ loading: true });
    const { match = {} } = this.props;
    let filterList = await this.accountService.getAllFilter();
    filterList = filterList.data;
    try {
      const response = await this.profilePageService.getgiggerProfile();
      this.setState(
        {
          giggerData: response,
        },
      );
    } catch (e) {
      const { params = {} } = match;
      const { firstName = '', lastName = '' } = params;
      const giggerData = {};
      giggerData.firstName = firstName;
      giggerData.lastName = lastName;
      this.setState({ giggerData });
    }
    this.setState(
      {
        jobTitleOptions: filterList.jobtitle,
        categoryOptions: filterList.category,
        originalSkillOptions: filterList.skills.map((skill) => (
          { ...skill, status: Constants.status.APPROVED })),
        originalSystemsOptions: filterList.systems.map((system) => (
          { ...system, status: Constants.status.APPROVED })),
      },
    );
  };

  /**
 * Stops loader
 */
  stopLoader=() => {
    this.setState({ loading: false });
  }

  /**
   * @async
   * @function handleFinishProfile Submitts the profile.
   * @param {Object} data Collected data from the form
   * @param {String} giggerStatus status of the user
   */
  handleFinishProfile = async (data, giggerStatus) => {
    this.setState({ loading: true });
    const { history } = this.props;
    const { giggerData: { review = false } } = this.state;
    let response;
    let showUpdate = false;
    let updateProfilePopupOpen = false;

    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    showUpdate = this.isNewFilterAdded(data);

    if (giggerStatus === Constants.status.COMPLETED) {
      this.setState({ areChangesSaved: true });
    }
    this.profileModel = new ProfileModel({});
    try {
      response = await this.profilePageService.updateProfile(this.profileModel.toJson(data));
      if (response) this.fetchGiggerProfile();
      if (response && giggerStatus === Constants.status.COMPLETED) {
        if (!review) {
          this.setState({ loading: true });
          await this.profilePageService.sendToReview();
          updateProfilePopupOpen = true;
        } else {
          toastData.type = Constants.toast.SUCCESS;
          toastData.message = Constants.language.toast_profile_updated;
        }
        history.push({
          pathname: `/details/${data.firstName}_${data.lastName}-${data.id}`,
          data: toastData,
          showUpdate,
          updateProfilePopupOpen,
        });
      }
      if (response && giggerStatus === Constants.status.DRAFT) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_profile_updated;
      }
    } catch (e) {
      if (giggerStatus === Constants.status.DRAFT) {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ loading: false, toastData, showToast: true });
      } else this.setState({ loading: false });
    }

    this.setState({
      toastData, showToast: true, areChangesSaved: true,
    }, this.removeAllErrorMessages);
  };

  /**
   * Closes toast
   */
  handleToast = () => {
    this.setState({ showToast: false, toastData: {} });
  };

  render() {
    const {
      showToast,
      toastData,
      giggerData,
      areChangesSaved,
      loading,
    } = this.state;

    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToast }}>
        <RelativeContainer>
          {showToast && (
          <Toast />
          )}
          <Prompt
            when={!areChangesSaved}
            message={Constants.language.Error_changes_not_saved}
          />
          <FlexContainer>
            <Container bgColor="none">
              <HeaderPart>
                <Div>
                  <NavContent>
                    <NavButton onClick={this.handleMyAccount} role="button">
                      <FormattedMessage id="common_my_account" defaultMessage="My account" />
                    </NavButton>
                    {' > '}
                    <NavActive>
                      <FormattedMessage id="common_my_profile" defaultMessage="My profile" />
                    </NavActive>
                  </NavContent>
                </Div>
              </HeaderPart>
              <ProfileForm
                data={giggerData}
                loading={loading}
                areChangesSaved={this.handleChangesInInput}
                onSubmit={this.handleFinishProfile}
                stopLoader={this.stopLoader}
              />
            </Container>
          </FlexContainer>
        </RelativeContainer>
      </MainContext.Provider>
    );
  }
}
export default Profile;
