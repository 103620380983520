import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  ProfileViewContainer,
  DetailsLeft,
  DetailsRight,
  Row,
  UserName,
  EditProfileButton,
  ProfileImageWrapper,
  ProfileStatus,
  WelcomeText,
  JobTitle,
  JobPrice,
  SubHeader,
  WelcomeTextHeader,
  SideHeading,
  EducationTitle,
  ResumeBlock,
  ResumeContent,
  JuniorLevelTag,
  ExperienceLevelTag,
  MidLevelTag,
  ResumeContentCapitalize,
  ProfileData,
  ProfileImage,
  Button,
  A,
  ShowMoreLink,
} from './styles';
import Card from '../../components/Card/Cards';
import Constants from '../../shared/constants';
import Loader from '../../components/Loader/Loader';
import {
  Paperclip, Download,
} from '../../styles/Icons';
import CustomCalender from '../../components/CustomCalender/CustomCalender';
import ProfilePageService from '../../services/ProfilePageService';
import Config from '../../config';
import { DefaultProfile } from '../../styles/Images';
import Toast, { MainContext } from '../../components/Toast/Toast';
import AuthService from '../../services/AuthService';
import PersistentStorage from '../../shared/PersistentStorage.ts';
import { RelativeContainer, Div } from '../../styles/CommonStyles';
import { getLocaleFromURL, getLanguages } from '../../shared/utils';
import Updateprofilepopup from '../../components/Card/Updateprofilepopup';
import { Desktop, Mobile } from '../customer/styles';
import Fonts from '../../styles/Fonts';

class GiggerDetails extends Component {
  profilePageService = new ProfilePageService(Config.apiBase);

  authService = new AuthService(Config.apiBase);

  persistentStorage = new PersistentStorage();

  currentLanguage = getLocaleFromURL();

  constructor(props) {
    super(props);
    this.state = {
      review: false,
      giggerData: [],
      fullName: '',
      firstName: '',
      lastName: '',
      giggerId: '',
      jobTitle: '',
      category: '',
      summary: '',
      skills: [],
      systems: [],
      languages: '',
      yearsOfExperience: '',
      hourlyRate: 0,
      previousJobs: [],
      education: [],
      docs: [],
      desiredCities: [],
      work_availability_date: [],
      work_availability: '',
      email: '',
      loading: false,
      showToast: false,
      updateProfilePopupOpen: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      loggedIn: this.authService.isAuthenticated(),
      showProfileUpdateCard: false,
      isAccountApproved: JSON.parse(this.persistentStorage.getUserDetails()).status
       === Constants.status.APPROVED,
      languagesOptions: getLanguages(),
      showMoreJobs: false,
      showMoreEducation: false,
    };
  }

  componentDidMount = async () => {
    window.location.hash = 'top';
    const { location } = this.props;
    const { loggedIn } = this.state;
    const { showUpdate } = location;
    const { updateProfilePopupOpen } = location;
    let { isAccountApproved } = this.state;
    if (loggedIn) {
      this.fetchGiggerDetails();
      const userDetails = await this.authService.usersDetails();
      isAccountApproved = userDetails.status === Constants.status.APPROVED;
    }
    this.setState({
      showProfileUpdateCard: showUpdate,
      isAccountApproved,
      updateProfilePopupOpen,
    }, () => {
      this.updateProfileElement.click();
    });
    window.scrollTo(0, 0);
  };

  componentDidUpdate() {
    const { showMoreJobs, showMoreEducation } = this.state;
    if (!showMoreJobs && !showMoreEducation) {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Redirects to Profile page
   */
  handleEditProfile = () => {
    const { history } = this.props;
    const { firstName, lastName, giggerId } = this.state;
    history.push(`/profile/${firstName}-${lastName}-${giggerId}`);
  };

  /**
   * @async
   * @function handleSendtoReview Submitts profile for review.
   */
  handleSendtoReview = async () => {
    this.setState({ showToast: false, loading: true });
    const toastData = {};
    toastData.delay = Constants.toast.LONG_DELAY;
    try {
      const response = await this.profilePageService.sendToReview();
      if (response) {
        toastData.type = Constants.toast.SUCCESS;
        toastData.message = Constants.language.toast_profile_sent_for_review;
        this.setState({
          loading: false,
          toastData,
          showToast: true,
          review: response.data.review,
        });
      } else {
        toastData.type = Constants.toast.ERROR;
        toastData.message = Constants.language.toast_try_after_time;
        this.setState({ toastData, showToast: true, loading: false });
      }
    } catch (e) {
      toastData.type = Constants.toast.ERROR;
      toastData.message = Constants.language.toast_try_after_time;
      this.setState({ loading: false, toastData, showToast: true });
    }
  };

  /**
   * Closes toast
   */
  handleToastClose = () => {
    this.setState({ showToast: false });
  };

  /**
   * Parsing of languages
   */
  parseLanguages = (languages) => {
    const { languagesOptions } = this.state;
    const translatedlanguages = [];
    languagesOptions.map((obj) => {
      if (languages.includes(obj.value)) {
        translatedlanguages.push(obj.label);
      }
      return obj;
    });
    this.setState({ languages: translatedlanguages.join(', ') });
  };

  handleShowMoreJobs = () => {
    this.setState({ showMoreJobs: true });
  }

  handleShowMoreEducation = () => {
    this.setState({ showMoreEducation: true });
  }

  /*
  * Closes opened modals
  */
  handleModalClose = () => {
    this.setState({
      updateProfilePopupOpen: false,
    });
  };

  /**
  *
  * Loads data
  *
  */
     handleLoaddata = () => {
       this.handleModalClose();
       this.fetchAccountDetails();
     };

     /**
   * @async
   * @function fetchGiggerDetails Fetches gigger details
   */
     async fetchGiggerDetails() {
       const { location } = this.props;
       this.setState({ loading: true });
       const { data } = location;
       if (data !== null && data !== undefined) {
         this.setState({ toastData: data, showToast: true });
       }
       try {
         const response = await this.profilePageService.getgiggerProfile();
         const profileData = response;
         const { education = [], previousJobs = [] } = profileData;
         profileData.education = education
           .sort((a, b) => (a.start_date < b.start_date ? 1 : -1))
           .map((obj) => {
             const startYear = obj.start_date ? obj.start_date.toString().split('-')[0] : '';
             const endYear = obj.end_date ? obj.end_date.toString().split('-')[0] : '';
             return { ...obj, start_date: startYear, end_date: endYear };
           });
         this.setState(
           {
             giggerData: profileData,
             fullName: profileData.fullName,
             firstName: profileData.firstName || '',
             lastName: profileData.lastName || '',
             phone: profileData.phone,
             giggerId: profileData.id,
             jobTitle: profileData.jobTitle,
             category: profileData.category,
             summary: profileData.summary,
             skills: profileData.skills.map((skill) => skill.name).join(', '),
             systems: profileData.systems.map((system) => system.name).join(', '),
             yearsOfExperience: profileData.yearsOfExperience,
             hourlyRate: profileData.hourlyRate,
             previousJobs: previousJobs.sort((a, b) => (a.start_date < b.start_date ? 1 : -1)),
             education: profileData.education,
             docs: profileData.documents,
             desiredCities: profileData.desiredCities.join(', '),
             work_availability_date: profileData.workAvailabilityDate,
             work_availability: profileData.workAvailability,
             email: profileData.email,
             loading: false,
             review: profileData.review,
           },
           this.parseLanguages(profileData.languages),
         );
       } catch (e) {
         this.setState({ loading: false });
       }
     }

     render() {
       const {
         review,
         isAccountApproved,
         education,
         previousJobs,
         fullName,
         docs,
         work_availability_date: workAvailabilityDate,
         work_availability: workAvailability,
         giggerData,
         yearsOfExperience,
         loading,
         languages,
         showToast,
         toastData,
         jobTitle,
         hourlyRate,
         email,
         phone,
         summary,
         skills,
         systems,
         category,
         showProfileUpdateCard,
         desiredCities,
         updateProfilePopupOpen,
         showMoreJobs,
         showMoreEducation,
       } = this.state;
       const displaydocTypes = [Constants.docsCategory[0],
         Constants.docsCategory[1], Constants.docsCategory[2]];

       return (
         <MainContext.Provider value={{
           showToast,
           toastData,
           onToastClose: this.handleToastClose,
         }}
         >
           <RelativeContainer>
             {showToast && <Toast />}
             <FlexContainer>
               <ProfileViewContainer>
                 <Row>
                   <DetailsLeft>
                     <ProfileImageWrapper>
                       {loading && <Loader width="100%" height="200px" />}
                       {!loading && (
                       <ProfileImage>
                         {giggerData && giggerData.profilePic && giggerData.profilePic.url ? (
                           <div
                             className="image-crop-no-image"
                             style={{
                               backgroundImage: `url("${giggerData.profilePic.url}")`,
                               backgroundSize: 'cover !important',
                             }}
                           >
                             {!loading && !isAccountApproved && review && (
                             <ProfileStatus>
                               <FormattedMessage
                                 id="common_profile_under_review"
                                 defaultMessage="Profile under review"
                               />
                             </ProfileStatus>
                             )}
                             {!loading && isAccountApproved && (
                             <ProfileStatus>
                               <FormattedMessage id="common_profile_submitted" defaultMessage="Profile published" />
                             </ProfileStatus>
                             )}
                           </div>
                         ) : (
                           <div
                             className="image-crop-no-image"
                             style={{ backgroundImage: `url("${DefaultProfile}")`, backgroundSize: 'cover !important' }}
                           >
                             {!loading && !isAccountApproved && review && (
                             <ProfileStatus>
                               <FormattedMessage
                                 id="common_profile_under_review"
                                 defaultMessage="Profile under review"
                               />
                             </ProfileStatus>
                             )}
                             {!loading && isAccountApproved && (
                             <ProfileStatus>
                               <FormattedMessage id="common_profile_submitted" defaultMessage="Profile published" />
                             </ProfileStatus>
                             )}
                           </div>
                         )}
                       </ProfileImage>
                       )}
                     </ProfileImageWrapper>
                     {loading && <Loader width="100%" height="160px" />}
                     {!loading && review ? (
                       <>
                         <UserName>{fullName}</UserName>
                         <ResumeContent className="text-center mb-3 p-3">
                           {yearsOfExperience !== '' && (
                           <>
                             {yearsOfExperience === '0-3' ? (
                               <JuniorLevelTag>
                                 <FormattedMessage id="common_junior" defaultMessage="Junior" />
                               </JuniorLevelTag>
                             ) : yearsOfExperience === '4-10' ? (
                               <ExperienceLevelTag>
                                 <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                               </ExperienceLevelTag>
                             ) : (
                               <MidLevelTag>
                                 <FormattedMessage id="common_senior" defaultMessage="Senior" />
                               </MidLevelTag>
                             )}
                           </>
                           )}
                           {` ${yearsOfExperience} ${Constants.language.common_years} `}
                         </ResumeContent>
                         {!loading && isAccountApproved && showProfileUpdateCard && (
                         <Div className="mb-4">
                           <Card
                             reminder
                             pinkCard
                             removeDeleteButton
                             header={[
                               <WelcomeTextHeader>
                                 <FormattedMessage id="gigger_profile_published" defaultMessage="Profile published" />
                               </WelcomeTextHeader>,
                             ]}
                             description={[
                               <>
                                 <WelcomeText>
                                   <FormattedMessage
                                     id="gigger_publish_message"
                                     defaultMessage="We will review your profile and it will be updated within 24 hours."
                                   />
                                 </WelcomeText>
                               </>,
                             ]}
                           />
                         </Div>
                         )}
                         <EditProfileButton onClick={this.handleEditProfile} className="mt-0">
                           <FormattedMessage id="common_edit_profile" defaultMessage="Edit profile" />
                         </EditProfileButton>
                       </>
                     ) : (
                       !loading && (
                       <Card
                         reminder
                         pinkCard
                         removeDeleteButton
                         header={[
                           <WelcomeTextHeader>
                             <FormattedMessage
                               id="gigger_details_profile_ready"
                               defaultMessage="Ready to publish your profile?"
                             />
                           </WelcomeTextHeader>,
                         ]}
                         description={[
                           <>
                             <WelcomeText>
                               <FormattedMessage
                                 id="gigger_details_next_steps_info"
                                 defaultMessage="Your profile looks great. But before being visible in the platform, your profile will be reviewed by the GigAssembly team."
                               />
                             </WelcomeText>
                             <WelcomeText className="font-size-10 mt-3">
                               <FormattedMessage
                                 id="submit_for_review_message"
                                 defaultMessage="Vi vill göra dig uppmärksam på att vi inte får behandla känsliga personuppgifter med anledning av dataskyddsförordningen (GDPR), och vi ber dig därför att inte registrera sådana uppgifter på din profil. Känsliga uppgifter är till exempel sådana som gäller din hälsa, etniskt ursprung, politiska åsikter och sexuell läggning."
                               />
                             </WelcomeText>
                             <Button className="mt-4 mb-1" onClick={this.handleSendtoReview}>
                               <FormattedMessage
                                 id="gigger_details_submit_for_review"
                                 defaultMessage="Submit for review"
                               />
                             </Button>
                             <EditProfileButton className="mt-3" onClick={this.handleEditProfile}>
                               <FormattedMessage id="common_edit_profile" defaultMessage="Edit profile" />
                             </EditProfileButton>
                           </>,
                         ]}
                       />
                       )
                     )}
                   </DetailsLeft>
                   <DetailsRight>
                     {loading && <Loader width="75%" height="40px" />}
                     {loading && <Loader width="50%" height="24px" />}
                     {!loading && <JobTitle>{jobTitle}</JobTitle>}
                     {!loading && <JobPrice>{`${hourlyRate} kr/${Constants.language.common_hour}`}</JobPrice>}

                     <Card className="p-4 mb-4">
                       {loading ? (
                         loading && <Loader width="100%" height="160px" />
                       ) : (
                         <div className="my-4">
                           <Row>
                             <ProfileData className="col-xl-6 col-md-4 col-sm-12 col-xs-12">
                               <Row>
                                 <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-truncate">
                                   <SubHeader>
                                     <FormattedMessage id="common_email" defaultMessage="Email" />
                                   </SubHeader>
                                   <ResumeContent>{email}</ResumeContent>
                                 </div>
                               </Row>
                               <Row className="mt-4">
                                 <div className="col-xl-12 col-md-12 col-sm-12 col-xs-12 text-truncate">
                                   <SubHeader>
                                     <FormattedMessage id="common_phone" defaultMessage="Phone" />
                                   </SubHeader>
                                   <ResumeContent>{phone}</ResumeContent>
                                 </div>
                               </Row>
                             </ProfileData>
                             <ProfileData className="col-xl-6 col-md-4 col-sm-12 col-xs-12">
                               <div className="col">
                                 <SubHeader>
                                   <Paperclip width="20px" height="20px" className="mb-1 mr-1" />
                                   <FormattedMessage id="common_documents" defaultMessage="Documents" />
                                 </SubHeader>
                                 {docs
                              && docs.length > 0
                              && docs.map(
                                (obj) => obj
                                  && obj.file_name
                                  && displaydocTypes.includes(obj.category) && (
                                    <ResumeContent className="mb-1">
                                      <a
                                        href={obj.url}
                                        download={obj.file_name}
                                        className=" color-black"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <Download width="20px" height="20px" className="mb-1 mr-1" />
                                        {obj.file_name}
                                      </a>
                                    </ResumeContent>
                                ),
                              )}
                               </div>
                             </ProfileData>
                           </Row>
                         </div>
                       )}
                     </Card>

                     <Card>
                       <Row>
                         <div className="col mt-3">
                           <ResumeBlock>
                             {loading ? (
                               <Loader width="100%" height="50px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage id="common_category" defaultMessage="Category" />
                                 </SideHeading>
                                 <ResumeContentCapitalize>{category || ''}</ResumeContentCapitalize>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock>
                             {loading ? (
                               <Loader width="100%" height="160px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage id="common_summary" defaultMessage="Summary" />
                                 </SideHeading>
                                 <ResumeContent>{summary}</ResumeContent>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock>
                             {loading ? (
                               <Loader width="100%" height="50px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage id="common_systems" defaultMessage="Systems" />
                                 </SideHeading>
                                 <ResumeContent>{systems}</ResumeContent>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock>
                             {loading ? (
                               <Loader width="100%" height="50px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage id="common_skills" defaultMessage="Skills" />
                                 </SideHeading>
                                 <ResumeContent>{skills}</ResumeContent>
                               </>
                             )}
                           </ResumeBlock>

                           <ResumeBlock className="mb-3">
                             {loading ? (
                               <Loader width="100%" height="160px" />
                             ) : (
                               <>
                                 <SideHeading className="mb-3">
                                   <FormattedMessage id="common_previous_jobs" defaultMessage="Previous jobs" />
                                 </SideHeading>
                                 <Row className="line-dot-wrapper">
                                   {previousJobs && previousJobs.length ? (
                                     previousJobs
                                       .filter((item, index) => showMoreJobs || (!showMoreJobs
                                      && index < 3))
                                       .map((obj, index = 0) => (
                                         <>
                                           <Div className="lines d-flex col-12 p-0 pb-3 pb-lg-4" key={index}>
                                             <Div className="knob ml-4" />
                                             <Div className="text-right" customWidth="93px">
                                               <Desktop>
                                                 <ResumeContentCapitalize fontWeightNormal>
                                                   {obj.start_date && obj.end_date
                                                     ? `${obj.start_date} - ${obj.end_date}`
                                                     : obj.start_date ? obj.start_date : obj.end_date ? obj.end_date : ''}
                                                 </ResumeContentCapitalize>
                                               </Desktop>
                                             </Div>
                                             <Div className="d-block">
                                               <Mobile>
                                                 <ResumeContentCapitalize fontWeightNormal className="pl-4 mb-1">
                                                   {obj.start_date && obj.end_date
                                                     ? `${obj.start_date} - ${obj.end_date}`
                                                     : obj.start_date ? obj.start_date : obj.end_date ? obj.end_date : ''}
                                                 </ResumeContentCapitalize>
                                               </Mobile>
                                               {obj.title && (
                                               <Div className="d-inline-flex mb-1 mb-lg-2">
                                                 <EducationTitle fontSize={`${Fonts.fontSize.Size18}px`} semiBold className="ml-lg-2 pl-4">{obj.title}</EducationTitle>
                                               </Div>
                                               )}
                                               {obj.company_name && (
                                               <Div>
                                                 <ResumeContentCapitalize fontWeightNormal className="ml-lg-2 pl-4">{obj.company_name}</ResumeContentCapitalize>
                                               </Div>
                                               )}
                                             </Div>
                                             <Div className={index !== 2 && 'line ml-4'} />
                                             <Div className={showMoreJobs && index === 2 && 'line ml-4'} />
                                           </Div>
                                         </>
                                       ))
                                   ) : (
                                     <ResumeContent>-</ResumeContent>
                                   )}
                                   {previousJobs
                                    && previousJobs.length > 3 && !showMoreJobs && (
                                    <Div className="mt-4 mt-lg-0 pb-4">
                                      <A onClick={this.handleShowMoreJobs}>
                                        <ShowMoreLink fontSize={`${Fonts.fontSize.Size18}px`}>
                                          {`${Constants.language.label_show_more} (${(previousJobs.length - 3)})`}
                                        </ShowMoreLink>
                                      </A>
                                    </Div>
                                   )}
                                 </Row>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock className="mb-3">
                             {loading ? (
                               <Loader width="100%" height="160px" />
                             ) : (
                               <>
                                 <SideHeading className="mb-3">
                                   <FormattedMessage id="common_education" defaultMessage="Education" />
                                 </SideHeading>
                                 <Row className="line-dot-wrapper">
                                   {education && education.length ? (
                                     education.filter((item, index) => showMoreEducation
                                     || (!showMoreEducation && index < 3))
                                       .map((obj, index = 0) => (
                                         <Div className="lines d-flex col-12 p-0 pb-3 pb-lg-4" key={index}>
                                           <Div className="knob ml-4" />
                                           <Div className="text-right" customWidth="93px">
                                             <Desktop>
                                               <ResumeContentCapitalize fontWeightNormal>
                                                 {obj.start_date && obj.end_date
                                                   ? `${obj.start_date} - ${obj.end_date}`
                                                   : obj.start_date ? obj.start_date : obj.end_date ? obj.end_date : '' }
                                               </ResumeContentCapitalize>
                                             </Desktop>
                                           </Div>
                                           <Div className="d-block">
                                             <Mobile>
                                               <ResumeContentCapitalize fontWeightNormal className="pl-4 mb-1">
                                                 {obj.start_date && obj.end_date
                                                   ? `${obj.start_date} - ${obj.end_date}`
                                                   : obj.start_date ? obj.start_date : obj.end_date ? obj.end_date : '' }
                                               </ResumeContentCapitalize>
                                             </Mobile>
                                             {obj.institution_name && (
                                             <Div className="d-inline-flex mb-1 mb-lg-2">
                                               <EducationTitle fontSize={`${Fonts.fontSize.Size18}px`} semiBold className="ml-lg-2 pl-4">{obj.institution_name}</EducationTitle>
                                             </Div>
                                             )}
                                             {obj.degree && (
                                             <Div>
                                               <ResumeContentCapitalize fontWeightNormal className="ml-lg-2 pl-4">{obj.degree}</ResumeContentCapitalize>
                                             </Div>
                                             )}
                                           </Div>
                                           <Div className={index !== 2 && 'line ml-4'} />
                                           <Div className={showMoreEducation && index === 2 && 'line ml-4'} />

                                         </Div>
                                       ))
                                   ) : (
                                     <ResumeContent>-</ResumeContent>
                                   )}
                                   {education
                                    && education.length > 3 && !showMoreEducation && (
                                    <Div className="mt-4 mt-lg-0 pb-4">
                                      <A onClick={this.handleShowMoreEducation}>
                                        <ShowMoreLink fontSize={`${Fonts.fontSize.Size18}px`}>
                                          {`${Constants.language.label_show_more} (${(education.length - 3)})`}
                                        </ShowMoreLink>
                                      </A>
                                    </Div>
                                   )}
                                 </Row>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock>
                             <Row>
                               <div className="col-12 p-0">
                                 {loading ? (
                                   <Loader width="80%" height="50px" />
                                 ) : (
                                   <>
                                     <SideHeading>
                                       <FormattedMessage id="common_languages" defaultMessage="Languages" />
                                     </SideHeading>
                                     <ResumeContentCapitalize>{languages}</ResumeContentCapitalize>
                                   </>
                                 )}
                               </div>
                             </Row>
                           </ResumeBlock>

                           <ResumeBlock className="my-5 pt-1">
                             {loading ? (
                               <Loader width="100%" height="24px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage
                                     id="common_desired_city_for_work"
                                     defaultMessage="Desired city for work"
                                   />
                                 </SideHeading>
                                 <ResumeContent>
                                   <img
                                     src={Constants.icons.PinEmpty}
                                     alt="icon"
                                     width="14px"
                                     height="14px"
                                     className="mr-1 mb-1"
                                   />
                                   {desiredCities}
                                 </ResumeContent>
                               </>
                             )}
                           </ResumeBlock>
                           <ResumeBlock className="mb-3">
                             {loading ? (
                               <Loader width="100%" height="200px" />
                             ) : (
                               <>
                                 <SideHeading>
                                   <FormattedMessage id="common_work_availability" defaultMessage="Work availability" />
                                   :
                                 </SideHeading>
                                 <ResumeContentCapitalize>
                                   {Constants.profile[workAvailability]}
                                 </ResumeContentCapitalize>
                                 <CustomCalender dates={workAvailabilityDate} monthsToDisplay={3} />
                               </>
                             )}
                           </ResumeBlock>
                         </div>
                         {updateProfilePopupOpen && (
                         <Updateprofilepopup
                           updateProfilePopup={updateProfilePopupOpen}
                           ModalClose={this.handleModalClose}
                         />
                         )}
                         <a
                           href
                           data-backdrop="static"
                           data-keyboard="false"
                           data-toggle="modal"
                           data-target="#updateProfileModal"
                           ref={(updateModal) => {
                             this.updateProfileElement = updateModal;
                           }}
                         />
                       </Row>
                     </Card>
                   </DetailsRight>
                 </Row>
               </ProfileViewContainer>
             </FlexContainer>
           </RelativeContainer>
         </MainContext.Provider>
       );
     }
}
export default GiggerDetails;
