import React, { Component } from 'react';
import Constants from '../../shared/constants';
import AccountService from '../../services/AccountService';
import Config from '../../config';
import {
  DropDownOption, AutoSuggest, AutoSuggestWrapper, SectionTitle,
} from './styles';

class AutoSuggestInput extends Component {
  accountService = new AccountService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {

      lists: [],
      value: '',
    };
  }

  componentDidMount() {
    const { selectedData } = this.props;
    this.setState({
      selectedSuggestion: selectedData,
      suggestionCopy: selectedData,
    });
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.selectedData) {
      return ({
        selectedSuggestion: nextProps.selectedData,
        value: '',
      });
    }
    return null;
  }

  handleSuggestionSelected = (event, obj) => {
    const { onSuggestionSelected } = this.props;

    const { listsCopy } = this.state;

    if (onSuggestionSelected) {
      const filtered = listsCopy.filter((item) => item.number === obj.suggestion.id);
      onSuggestionSelected(obj.suggestion, { search: filtered });
    }
    this.setState({
      selectedSuggestion: obj.suggestion,
      suggestionCopy: obj.suggestion,
    });
  };

  handleChange = (event, { newValue }) => {
    const { onChange } = this.props;
    const { selectedSuggestion } = this.state;
    this.setState({
      value: newValue,
    });
    if (onChange) onChange(newValue, selectedSuggestion);
  };

  handleSuggestionsFetchRequested = async ({ value }) => {
    try {
      if (value.length > 1) {
        const $query = [['filter', { search: [value] }]];
        const response = await this.accountService.search($query);
        let { data } = response;

        data = data.reduce((res, current) => {
        // see if id is already in the output
          const result = res.find((e) => e.type === current.type);
          if (result) {
          // if the id is there update the plats with the new info
            result.data = [...result.data, {
              id: current.id,
              name: current.name,
              label: current.name,
            }];
            return res;
          }
          // if the id isn't there, add a new object.
          return [...res, {
            title: `${Constants.language.search_dropdown_type_in} ${Constants.translateType[current.type].toLowerCase()}`,
            type: current.type,
            data: [{
              id: current.id,
              name: current.name,
              label: current.type === 'secondary_job_title' ? current.secondary_job_title : current.name,
              type: current.type,
            }],

          }];
        }, []);
        this.setState({
          lists: data,
          listsCopy: data,
        });
      }
    } catch (e) {
      this.setState({
        lists: [],
        listsCopy: [],
      });
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  handleSuggestionsClearRequested = () => {
    this.setState({
      lists: [],
    });
  };

  onKeyDown=(e) => {
    const { search } = this.props;
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      search();
    }
  }

  renderSectionTitle = (section) => <SectionTitle>{section.title.replace(/_/g, ' ')}</SectionTitle>;

  getSectionSuggestions = (section) => section.data;

  render() {
    const {
      value, lists, suggestionCopy, selectedSuggestion,
    } = this.state;
    const { placeholder, wp } = this.props;
    const inputProps = {
      placeholder: placeholder || Constants.language.common_search,
      value: selectedSuggestion ? selectedSuggestion.label : value,
      onChange: this.handleChange,
      onKeyDown: this.onKeyDown,
      onBlur: () => {
        this.setState({ selectedSuggestion: suggestionCopy });
      },
    };
    return (
      <AutoSuggestWrapper searchBoxPadding wp={wp}>
        <AutoSuggest
          wp={wp}
          multiSection
          suggestions={lists}
          renderSectionTitle={this.renderSectionTitle}
          onSuggestionSelected={this.handleSuggestionSelected}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSectionSuggestions={this.getSectionSuggestions}
          getSuggestionValue={(option) => option.label}
          renderSuggestion={(suggestion) => (
            <DropDownOption
              role="button"
              className={
                `${suggestion.id === value ? 'font-weight-semibold' : ' '} cursor-pointer text-truncate`
              }
            >
              {suggestion.label}
            </DropDownOption>
          )}
          inputProps={inputProps}
        />
      </AutoSuggestWrapper>
    );
  }
}
export default AutoSuggestInput;
