import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getLocaleFromURL } from '../../shared/utils';
import Constants from '../../shared/constants';
import Config from '../../config';
import { Logo } from '../../styles/Images';
import { FacebookIcon, InstagramIcon, LinkedInIcon } from '../../styles/Icons';

class Footer extends Component {
  handleOnLoad = () => {
    const receiver = document.getElementById('receiver').contentWindow;
    const message = { isAuthenticated: !!localStorage.getItem('token') };
    receiver.postMessage(message, Config.wordpressUrl);
  };

  render() {
    let currentLanguage = getLocaleFromURL() || localStorage.getItem('language');

    if (!currentLanguage) {
      currentLanguage = Config.language;
      localStorage.setItem('language', currentLanguage);
    }

    return (
      <div id="gig-footer">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <img
              className="gig-logo gm-loaded gm-observing gm-observing-cb"
              src={Logo}
              loading="lazy"
              alt="logo"
            />
            <div className="d-none d-lg-block">
              <p className="address-headers mb-2 text-right">
                <FormattedMessage id="footer_follow_us" defaultMessage="Follow us" />
              </p>
              <div className="social-image d-inline-block">
                <div className="d-inline-block mr-3">
                  <a className="social-media facebook" target="_self" href="https://www.facebook.com/gigassembly" />
                </div>
                <div className="d-inline-block mr-3">
                  <a className="social-media linkedin" target="_self" href="https://www.linkedin.com/company/gigassembly" />
                </div>
                <div className="d-inline-block">
                  <a className="social-media instagram" target="_self" href="https://www.instagram.com/gigassembly/" />
                </div>
              </div>
            </div>
          </div>
          <div className="detail-section d-flex justify-content-between">
            <div className="col-lg-8 pt-4 pb-1 row final-part d-lg-flex">
              <div className="col-6 pl-0 pl-lg-3 col-lg-3 gig-address">
                <p className="address-header">
                  <FormattedMessage id="common_explore" defaultMessage="Explore" />
                </p>
                <a
                  href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].forCompanies}/`}
                >
                  <p className="address-headers">
                    <FormattedMessage id="common_for_companies" defaultMessage="For Companies" />
                  </p>
                </a>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].forGiggers}/`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_for_giggers" defaultMessage="For Consultants" />
                  </p>
                </a>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].commonskills}/`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_skills" defaultMessage="Skills" />
                  </p>
                </a>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].faq}`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_faq" defaultMessage="FAQ" />
                  </p>
                </a>
              </div>
              <div className="col-6 col-lg-3 gig-address">
                <p className="address-header">
                  <FormattedMessage id="common_company" defaultMessage="Company" />
                </p>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].aboutUs}/`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_about_us" defaultMessage="About us" />
                  </p>
                </a>
                <a href={Constants.toNovare}>
                  <p className="address-headers">
                    <FormattedMessage id="to_novare" defaultMessage="to Novare" />
                  </p>
                </a>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].blog}`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_blog" defaultMessage="Blog" />
                  </p>
                </a>
              </div>
              <div className="col-6 col-lg-3 pl-0 pl-lg-3 gig-address">
                <p className="address-header">
                  <FormattedMessage id="common_legally" defaultMessage="Legally" />
                </p>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].privacypolicy}`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_privacy_policy" defaultMessage="Privacy policy" />
                  </p>
                </a>
                <a href={`${Config.wordpressUrl}/${currentLanguage}/${Constants.wpURL[currentLanguage].termsAndConditions}`}>
                  <p className="address-headers">
                    <FormattedMessage id="common_terms_and_conditions" defaultMessage="terms and conditions" />
                  </p>
                </a>
              </div>
              <div className="col-6 col-lg-3">
                <p className="address-header mb-2">
                  <FormattedMessage id="common_contact_us" defaultMessage="contact us" />
                </p>
                <p className="text-lowercase">{Constants.footer.EMAILHELLO}</p>
                <p className="text-nowrap">{Constants.footer.PHONE}</p>
              </div>
            </div>
            <div className="d-none d-lg-block pt-4 text-right">
              <p className="address-headers">{Constants.footer.GIGASSEMBLYAB}</p>
              <p className="address-headers">{Constants.footer.ORGNUMBER}</p>
              <p className="address-headers pt-3">{Constants.footer.ADDRESS}</p>
              <p className="address-headers">{Constants.footer.CITY}</p>
            </div>
          </div>
          <div className="detail-section-mobile pl-0 col-auto d-flex justify-content-between d-lg-none pt-5">
            <div className="mt-3">
              <p className="address-headers">{Constants.footer.GIGASSEMBLYAB}</p>
              <p className="address-headers">{Constants.footer.ORGNUMBER}</p>

            </div>
            <div className="mt-3">
              <p className="address-headers">{Constants.footer.ADDRESS}</p>
              <p className="address-headers">{Constants.footer.CITY}</p>

            </div>
          </div>
          <div className="col-auto pl-0 pl-lg-3 d-block d-lg-none mt-3 pt-3 pt-lg-0">
            <p className="address-headers follow-us mb-2">
              <FormattedMessage id="footer_follow_us" defaultMessage="Follow us" />
            </p>
            <div className="social-image d-inline-block">
              <div className="d-inline-block mr-3">
                <a className="social-media" target="_self" href="https://www.facebook.com/gigassembly">
                  <FacebookIcon
                    loading="lazy"
                    className="gm-loaded gm-observing gm-observing-cb"
                    alt="icon"
                  />
                </a>
              </div>
              <div className="d-inline-block mr-3">
                <a className="social-media" target="_self" href="https://www.linkedin.com/company/gigassembly">
                  <LinkedInIcon
                    loading="lazy"
                    className="gm-loaded gm-observing gm-observing-cb"
                    alt="icon"
                  />
                </a>
              </div>
              <div className="d-inline-block">
                <a className="social-media" target="_self" href="https://www.instagram.com/gigassembly/">
                  <InstagramIcon
                    loading="lazy"
                    className="gm-loaded gm-observing gm-observing-cb"
                    alt="icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <iframe
          id="receiver"
          src={Config.wordpressUrl}
          className="d-none"
          onLoad={this.handleOnLoad}
          title="gigassembly-WP"
        />
      </div>
    );
  }
}

export default Footer;
