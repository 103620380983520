import React from 'react';
import {
  Span, IconButton, Whitebutton, Primarybutton, MultiColorbutton, ButtonName,
} from './styles';
import Constants from '../../shared/constants';

/**
 *
 * @function PrimaryButton
 * @param {*} param Props
 * @returns Primary background-colour button
 *
 */
function PrimaryButton({ props }) {
  const {
    onClick, name, disabled, id, className,
  } = props;
  return (
    <Primarybutton update onClick={onClick} disabled={disabled} id={id} className={className}>
      <Span>{name}</Span>
    </Primarybutton>
  );
}

/**
 *
 * @function WhiteButton
 * @param {*} param Props
 * @returns White background-colour button
 *
 */
function WhiteButton({ props }) {
  const {
    onClick, name, disabled, id, className,
  } = props;
  return (
    <Whitebutton onClick={onClick} disabled={disabled} id={id} className={className}>
      <Span cancel className={className}>{name}</Span>
    </Whitebutton>
  );
}

/**
 *
 * @function PinkButton
 * @param {*} param Props
 * @returns Pink background-colour button
 *
 */
function PinkButton({ props }) {
  const {
    onClick, name, disabled, id,
  } = props;
  return (
    <MultiColorbutton open onClick={onClick} disabled={disabled} id={id}>
      <ButtonName cancel>{name}</ButtonName>
    </MultiColorbutton>
  );
}

/**
 *
 * @function RedButton
 * @param {*} param Props
 * @returns Red background-colour button
 *
 */
function RedButton({ props }) {
  const {
    onClick, name, disabled, id,
  } = props;
  return (
    <MultiColorbutton cancel onClick={onClick} disabled={disabled} id={id}>
      <ButtonName>{name}</ButtonName>
    </MultiColorbutton>
  );
}

/**
 *
 * @function GreenButton
 * @param {*} param Props
 * @returns Green background-colour button
 *
 */
function GreenButton({ props }) {
  const {
    onClick, name, disabled, id,
  } = props;
  return (
    <MultiColorbutton approve onClick={onClick} disabled={disabled} id={id}>
      <ButtonName>{name}</ButtonName>
    </MultiColorbutton>
  );
}

/**
 *
 * @function BlueButton
 * @param {*} param Props
 * @returns Blue background-colour button
 *
 */
function BlueButton({ props }) {
  const {
    onClick, name, disabled, id,
  } = props;
  return (
    <MultiColorbutton complete onClick={onClick} disabled={disabled} id={id}>
      <ButtonName>{name}</ButtonName>
    </MultiColorbutton>
  );
}

/**
 *
 * @function CancelButton
 * @param {*} param Props
 * @returns White background-colour with primary color border button
 *
 */
function CancelButton({ props }) {
  const {
    onClick, name, disabled, id, className,
  } = props;

  return (
    <Primarybutton onClick={onClick} disabled={disabled} id={id} role="button" className={className}>
      <Span cancel>{name}</Span>
    </Primarybutton>
  );
}

/**
 *
 * @function SignInButton
 * @param {*} param Props
 * @returns Primary background-colour with Icon button
 *
 */
function SignInButton({ props }) {
  const {
    onClick, name, disabled, id,
  } = props;
  return (
    <IconButton onClick={onClick} disabled={disabled} id={id}>
      <img src={Constants.icons.LogoBankID} alt={Constants.icons.LogoBankID} />
      <Span>
        {' '}
        {name}
      </Span>
    </IconButton>
  );
}

function Button(props) {
  const {
    type,
  } = props;
  return type === 'cancel' ? (
    <CancelButton props={props} />
  ) : type === 'update' ? (
    <PrimaryButton props={props} />
  ) : type === 'open' ? (
    <PinkButton props={props} />
  ) : type === 'approve' ? (
    <GreenButton props={props} />
  ) : type === 'cancelled' ? (
    <RedButton props={props} />
  ) : type === 'pending' ? (
    <PinkButton props={props} />
  ) : type === 'complete' ? (
    <BlueButton props={props} />
  ) : type === 'signIn' ? (
    <SignInButton props={props} />
  ) : (
    <WhiteButton props={props} />
  );
}

export default Button;
